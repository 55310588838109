import React, {useEffect, useState} from "react";
import Icon from "../../atoms/Icon/Icon";
import {toLocaleString} from "../../../utils/helper";

const DettaglioEvento = ({data, closeAction}) => {
    const [datiEvento, setDatiEvento] = useState([])

    useEffect(() => {
        setDatiEvento([
            {label: 'Stato', value: data.signed ? 'Firmato' : (data.status === '{}' ? 'Da firmare' : 'In compilazione')},
            {label: 'Data firma', value: data.signed && data.signatureDate ? toLocaleString(data.signatureDate) : ''}
        ])
    },[data])

    return(
        <div>
            <div className="" aria-hidden="true" >
                <div className="flex items-center my-4">{
                    (closeAction) ? <Icon type='back' onClick={closeAction} /> : ''
                } &nbsp; {data?.title ?? data?.displayname ?? 'Document'}
                </div>
            </div>

            <div className="flex flex-col m-2">
                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-200">
                        <tbody>
                        {datiEvento.map((item, itemdx) => (
                            <tr key={itemdx} className={itemdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{item.label}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{item.value}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default DettaglioEvento;
