import {Fragment, useEffect, useRef, useState} from "react";
import { Dialog, Transition } from '@headlessui/react'
import {useTranslation} from "react-i18next";
import {classNames} from "../../../../utils/helper";
import SingleCheckbox from "../../../atoms/SingleCheckbox/SingleCheckbox";
import Button from "../../../atoms/Buttons/Button/Button";
import ErrorMessage from "../../../atoms/ErrorMessage/ErrorMessage";

const resetSelectRecipients = (recipients) => {
    let tempSelection = {}
    Object.keys(recipients).forEach(recId => tempSelection[recId]=false)
    return tempSelection
}

const ModalRecipientSelection = ({onConfirm = () => {}, open = false, setOpen = () => {}, recipients, hasSenderDataCollectionLinked=false}) => {
    const [selectedRecipients, setSelectedRecipients] = useState(resetSelectRecipients(recipients))
    const [selectSender, setSelectSender] = useState(false)
    const isRecipientsSelected = Object.keys(selectedRecipients).some(recId => selectedRecipients[recId])
    const {t} = useTranslation()
    const disableConfirm = (!Object.keys(selectedRecipients).some(key => selectedRecipients[key]) && !selectSender)

    useEffect(() => {
        setSelectSender(false)
        setSelectedRecipients(resetSelectRecipients(recipients))
    },[open])

    useEffect(() => {
        if(selectSender) setSelectedRecipients(resetSelectRecipients(recipients))
    },[selectSender])

    useEffect(() => {
        if(isRecipientsSelected) setSelectSender(false)
    },[selectedRecipients])

    const selectAllRecipients = () => {
        const allRecipientsSelected = {}
        Object.keys(recipients).forEach(id => allRecipientsSelected[id] = true)
        setSelectedRecipients(allRecipientsSelected)
    }

    const setValue = (id) => setSelectedRecipients({...selectedRecipients, [id]:!selectedRecipients[id]})

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={setOpen}>
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full">
                            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                <div className="sm:flex sm:items-start">
                                    <div className="mt-3 text-center sm:mt-0 sm:text-left">
                                        <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                                            {t('forms.whoFills')}
                                        </Dialog.Title>
                                        <div className="mt-2 mb-4">
                                            <p className="text-sm text-gray-700 text-sm leading-5 font-normal">
                                                {t('forms.whoFillsMessage')}
                                            </p>
                                        </div>
                                        <div className='flex flex-col space-y-3 sm:flex-row sm:space-y-0 sm:space-x-6'>
                                            <div className={classNames(selectSender ? 'bg-blue-100':'bg-gray-50','flex flex-col justify-center flex-1 border p-4 rounded cursor-pointer')} onClick={() => setSelectSender(!hasSenderDataCollectionLinked)}>
                                                <p className='text-sm leading-5 font-semibold'>{t('forms.whoIsSender')}</p>
                                                <p className='text-sm leading-5 font-normal'>{t('forms.you')}</p>
                                            </div>
                                            <div className={classNames(isRecipientsSelected ? 'bg-blue-100':'bg-gray-50','flex flex-col justify-center flex-1 border p-4 rounded cursor-pointer')} onClick={selectAllRecipients}>
                                                <p className='text-sm leading-5 font-semibold'>{t('forms.whoRecipients')}</p>
                                                {recipients && Object.values(recipients).map(rec => <div key={rec.id} className='flex items-center space-x-2'><SingleCheckbox label={`${rec.name}`} value={selectedRecipients[rec.id]} setValue={() => setValue(rec.id)} /></div>)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                <Button
                                    onClick={() => {
                                        onConfirm({...selectedRecipients, 'SENDER': selectSender});
                                        setOpen(false)
                                    }}
                                    disabled={disableConfirm}
                                    label='Conferma'
                                />
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default ModalRecipientSelection;
