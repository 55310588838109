import Button from "../../atoms/Buttons/Button/Button";
import confirmoLogo from '../../../assets/logos/confirmo-logo.svg'
import {classNames} from "../../../utils/helper";

const Footer = ({showLogo = false, type= 'simple', cancelLabel = 'Back', cancel, confirmLabel='Next', confirm, disableConfirm = false, hidePrevious=false}) => {
    return (
        <div className={classNames( type=== 'simple' || type=== 'confirm' ? 'bg-gray-50' : '','mx-auto px-6 py-4 lg:px-3 lg:py-3 flex justify-center flex-grow')}>
            <div className='flex justify-between items-center flex-grow max-w-7xl'>
                <div className="flex justify-center">
                    {showLogo && type === 'confirm' &&
                    <img
                        className="block h-8 w-auto"
                        src={confirmoLogo}
                        alt="Confirmo"
                    />
                    }
                </div>
                <div className="flex justify-center items-center space-x-4">
                    {!hidePrevious && <Button label={cancelLabel} variant='white' size='lg' onClick={cancel} />}
                    <Button label={confirmLabel} variant={`${type === 'confirm' ? 'green' : 'blue'}`} size='lg' onClick={confirm} disabled={disableConfirm} />
                </div>
            </div>
        </div>
    )
}
export default Footer;
