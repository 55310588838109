import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Button from "../../../../atoms/Buttons/Button/Button";
import ModalPinRequest from "../../../../molecules/Modals/ModalPinRequest/ModalPinRequest";
import {FULL} from "../../../../atoms/Buttons/sizes";
import {useTranslation} from "react-i18next";
import EventsList from "../../../../organisms/EventsList/EventsList";
import {
    CHECKBOX,
    DATE,
    ICON_BUTTON,
    ICON_BUTTON_LIST,
    SIGN
} from "../../../../atoms/SimpleTableElement/SimpleTable.types";
import {v4} from "uuid";
import {
    doRemoteMassiveSignatureThunk,
    getEventsMassiveSignature,
    getMassiveSignatureDetails, requestOtpFeqThunk, retrievePiPThunk
} from "../../../../../features/library/library.action";
import SingleCheckbox from "../../../../atoms/SingleCheckbox/SingleCheckbox";
import isEmpty from "lodash/isEmpty";
import {librarySelector, resetFirmaMassivaOtpPin} from "../../../../../features/library/library.slice";

const FirmaMassiva = ({eventsMassiveSignature, eventsCountMassiveSignature, closeDetail, detailOpened, loadingEvents, currentEventId, filters, setFilters, goToSign, viewEventDetail, downloadPDF}) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [openModalPinRequest, setOpenModalPinRequest] = useState(false)
    const [selected, setSelected] = useState({})
    const {pIp, otpFeqVisible, otpRequestPending} =  useSelector(librarySelector);

    const updateFirmaMassivaEvents = (filter) => dispatch(getEventsMassiveSignature(filter))
    const getFirmaMassivaEventDetails = async (eventId) => await dispatch(getMassiveSignatureDetails({id: eventId}))

    const resetPreAvvioModale = () => {
        dispatch(resetFirmaMassivaOtpPin());
    }


    useEffect(() => {
        if (!pIp) dispatch(retrievePiPThunk());
    },[])

    const massiveSignSelected = async (pin, otp, deviceId) => {
        const eventIds = Object.keys(selected).flatMap(id => selected[id] ? id : [])
        try {
            await dispatch(doRemoteMassiveSignatureThunk({eventIds, pin, otp, deviceId})).unwrap()
            updateFirmaMassivaEvents()
        }catch(e) {
            console.log(e)
        }
        setOpenModalPinRequest(false)
    }

    const requestOtp = (pin) => {
        dispatch(requestOtpFeqThunk({pin}))
    }

    const setSelectedElement = (id) => {
        setSelected({...selected, [id]: !selected?.[id]})
    }
    const onChangeIsAllSelected = (allSelected) => {
        let tempSelection= {}
        eventsMassiveSignature?.forEach(d => {
            tempSelection[d.id] = allSelected
        })
        setSelected(tempSelection)
    }
    const selectAll = <SingleCheckbox value={!isEmpty(selected) && Object.values(selected).every(value => value===true)} setValue={onChangeIsAllSelected} />

    useEffect(() => {
        onChangeIsAllSelected(false)
    },[eventsMassiveSignature])

    const firmaMassivaColumn = [
        {
            field: 'selectAll',
            label: selectAll,
            hidden: false,
            type: CHECKBOX,
            selected,
            setSelected: setSelectedElement,
            id: 'selectAll'
        },
        {field: 'displayname',label: t('tableColumns.sender'), isSortable: false, hidden: false, id: 'displayname'},
        {field: 'title', label: t('tableColumns.name'), isSortable: false, hidden: false, id: 'title'},
        {field: 'referenceDate', type: DATE, label: t('tableColumns.date'), isSortable: true, hidden: false, id: 'referenceDate'},
        {field: 'buttonsList', type: ICON_BUTTON_LIST, hidden: true, id: 'buttonsList', buttons: [
                {
                    field: 'flowStatus',
                    id: 'flowStatus',
                    label: t('tableColumns.status'),
                    hidden: true,
                    type: SIGN,
                    onClick: (d) =>  goToSign(d),
                },
                {
                    field: 'Visualizza',
                    id: 'Visualizza',
                    label: t('tableColumns.view'),
                    hidden: true,
                    type: ICON_BUTTON,
                    onClick: (d) => downloadPDF(d),
                    iconType: 'view',
                },
                {
                    field: 'Dettagli',
                    id: 'Dettagli',
                    label: t('tableColumns.details'),
                    hidden: true,
                    type: ICON_BUTTON,
                    onClick: (d) => viewEventDetail(d, getFirmaMassivaEventDetails),
                    iconType: 'details'
                }
            ]
        }
    ]

    return <>
        <EventsList
            data={eventsMassiveSignature} closeDetail={closeDetail}
            columns={firmaMassivaColumn} detailOpened={detailOpened}
            loading={loadingEvents} elementsCount={eventsCountMassiveSignature}
            updateEvents={updateFirmaMassivaEvents} currentEventId={currentEventId}
            filters={filters} setFilters={setFilters}
            sideComponent={<Button label='Firma selezionati' onClick={() => {
                resetPreAvvioModale()
                setOpenModalPinRequest(true)
            }} disabled={Object.values(selected).every(sel => !sel)} size={FULL} />}
        />
        {openModalPinRequest && <ModalPinRequest open={openModalPinRequest}
                                                 setOpen={setOpenModalPinRequest}
                                                 type={'FEQ'}
                                                 confirm={massiveSignSelected}
                                                 requestOtp={requestOtp}
                                                 otpFeqVisible={otpFeqVisible}
                                                 pendingRequest={otpRequestPending}

        />}
    </>
}

export default FirmaMassiva;
