import Input from "../../atoms/Inputs/Input/Input";
import Button from "../../atoms/Buttons/Button/Button";
import LinkedLabel from "../../atoms/LinkedLabel/LinkedLabel";
import confirmoLogo from '../../../assets/logos/confirmo-logo.svg'
import SingleCheckbox from "../../atoms/SingleCheckbox/SingleCheckbox";
import ErrorMessage from "../../atoms/ErrorMessage/ErrorMessage";
import {useTranslation} from "react-i18next";

const LoginForm = ({ username, password, setUsername, setPassword, verifyCredentials, loginError= null, loginErrorMessage, saveUsername = false, setSaveUsername=()=>{} }) => {
    const { t } = useTranslation();
    const onSubmit = (e) => {
        e.stopPropagation();
        e.preventDefault()
        verifyCredentials()
    }
    return(
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md flex flex-col">
            <img src={confirmoLogo} alt='Confirmo' className='mb-10 h-12'/>
            <div className='my-2'>
                {loginError ?
                    <ErrorMessage>{t(loginErrorMessage)}</ErrorMessage>
                    : <></>}
            </div>
            <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                <form onSubmit={onSubmit}>
                    <div className="space-y-6">
                        <Input
                            name='email'
                            label={t('email')}
                            placeholder='example@example.com'
                            value={username ?? ''}
                            setValue={setUsername}
                            autofocus={true}
                        />

                        <Input
                            name='password'
                            type='password'
                            label={t('password')}
                            value={password ?? ''}
                            setValue={setPassword}
                        />

                        <div className="flex items-center justify-between">
                            <SingleCheckbox label={t('login.rememberUsername')} value={saveUsername} setValue={setSaveUsername} />
                        </div>

                        <div className="h-10">
                            <Button
                                variant='blue'
                                label={t('login.login')}
                                size='full'
                                type='submit'
                                onClick={onSubmit}
                            />
                        </div>
                    </div>
                </form>
                <div className='flex justify-center items-center pt-8'>
                    <LinkedLabel link={'/ForgotPassword'}>{t('login.forgotPassword')}</LinkedLabel>
                </div>
            </div>
            <div className='flex justify-center items-center pt-8'>
                <LinkedLabel link={'/Registration'}>{t('login.signUp')}</LinkedLabel>
            </div>
        </div>
    )
}

export default LoginForm;
