import React from "react";
import {useDispatch} from "react-redux";
import {getWaitingEventDetailsThunk, getWaitingEvents} from "../../../../../features/library/library.action";
import EventsList from "../../../../organisms/EventsList/EventsList";
import {DATE, ICON_BUTTON, ICON_BUTTON_LIST, SIGN} from "../../../../atoms/SimpleTableElement/SimpleTable.types";
import {useTranslation} from "react-i18next";

const DocInAttesa = ({completed=false, waitingEvents, waitingEventsCount, closeDetail, detailOpened, loadingEvents, currentEventId,
                         filters, setFilters, flgIncludiCompletati, toggleCompletati, downloadPDF, viewEventDetail, goToSign}) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const updateWaitingEvents = (filter) => dispatch(getWaitingEvents(filter))
    const getWaitingEventsDetails = async (eventId) => await dispatch(getWaitingEventDetailsThunk({id: eventId}))
    const waitingEventsColumns = [
        {field: 'flowStatus', label: t('tableColumns.status'), hidden: true, type: 'status', id: 'flowStatus'},
        {field: 'displayname',label: t('tableColumns.sender'), isSortable: false, hidden: false, id: 'displayname'},
        {field: 'title', label: t('tableColumns.name'), isSortable: false, hidden: false, id: 'title'},
        {field: 'referenceDate', type: DATE, label: t('tableColumns.date'), isSortable: true, hidden: false, id: 'referenceDate'},
        {field: 'buttonsList', type: ICON_BUTTON_LIST, hidden: true, id: 'buttonsList', buttons: [
                {
                    field: 'flowStatus',
                    label: t('tableColumns.status'),
                    hidden: true,
                    type: SIGN,
                    onClick: (d) => goToSign(d), id: 'buttonFlowStatus'
                },
                {
                    field: 'Visualizza',
                    label: t('tableColumns.view'),
                    hidden: true,
                    type: ICON_BUTTON,
                    onClick: (d) => downloadPDF(d),
                    iconType: 'view', id: 'buttonView'
                },
                {
                    field: 'Dettagli',
                    label: t('tableColumns.details'),
                    hidden: true,
                    type: ICON_BUTTON,
                    onClick: (d) => viewEventDetail(d, getWaitingEventsDetails),
                    iconType: 'details', id: 'buttonDetails'
                }
            ]
        }
    ]

    return <EventsList
        data={waitingEvents} closeDetail={closeDetail}
        columns={waitingEventsColumns} detailOpened={detailOpened}
        loading={loadingEvents} elementsCount={waitingEventsCount}
        updateEvents={updateWaitingEvents} completed={completed} currentEventId={currentEventId}
        filters={filters} setFilters={setFilters} flgIncludiCompletati={flgIncludiCompletati} sideComponent={toggleCompletati}
    />
}

export default DocInAttesa;
