import PageWithStepsHeaderAndFooter from "../../Templates/PageWithStepsHeaderAndFooter/PageWithStepsHeaderAndFooter";
import Input from "../../../atoms/Inputs/Input/Input";
import Textarea from "../../../atoms/TextArea/Textarea";
import SingleCheckbox from "../../../atoms/SingleCheckbox/SingleCheckbox";
import SummarySidebar from "../../../molecules/SummarySidebar/SummarySidebar";
import React, {useEffect, useState} from "react";
import Select from "../../../atoms/Select/Select";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import MultiSelectWorkspacesSummary
    from "../../../atoms/Select/MultiSelectWorkspacesSummary/MultiSelectWorkspacesSummary";
import TabletSelector from "../../../molecules/TabletSelector/TabletSelector";

const Summary = ({next,previous, recipients = [], processList = [], informative= [], dataCollections = [] ,steps, messageBody, setMessageBody, messageSubject, setMessageSubject, reminderInterval, setReminderInterval,
                     expirationDate, setExpirationDate, confirmLabel, devices = [], selectedDevice, setSelectedDevice, filename, setRedirectLink, setHasGraphConsentToRecipient,
                     setNoEmailSent, setNoEmailSignedSent, availableWorkspaces, selectedWorkspaces, setSelectedWorkspaces, hasWorkspacesPermissions=false, setSenderReminderComplete, senderReminderComplete,
                     avoidLtArchive, setAvoidLtArchive, editAvoidLtArchiveChoice=false, isConcatenation=false, buildUrl}) => {
    const {t} = useTranslation()
    const navigate = useNavigate();
    const [sendReminders, setSendReminders]=useState(false);
    const graphUser = Object.values(recipients).find(rec => rec.signatureType === 'GRAPH')

    const intervalOptions = [
        {label: t('summary.reminders.everyday'), value: 1},
        {label: t('summary.reminders.2days'), value: 2},
        {label: t('summary.reminders.5days'), value: 5},
        {label: t('summary.reminders.week'), value: 7},
    ]

    useEffect(() => {
        setMessageSubject(messageSubject ?? `${t('summary.signDocument')} ${filename ?? ''}`)
    },[])

    useEffect(() => {
        if(!sendReminders) setReminderInterval(null)
        else setReminderInterval(intervalOptions[0].value)
    },[sendReminders])

    const goBackHome= () => {
        navigate('/home')
    }

    return(
        <PageWithStepsHeaderAndFooter background={false} footer={true} next={next} previous={previous} closeAction={goBackHome} steps={steps} confirmLabel={confirmLabel} buildUrl={buildUrl}>
            <div className='flex flex-3 justify-start items-stretch flex-grow mt-10 px-4 max-w-6xl w-2/3'>
                <div className='flex flex-col justify-start items-stretch flex-1 h-full'>
                    <div className='py-3'>
                        <h1 className='text-lg leading-7 font-medium text-gray-900'>{t('summary.title')}</h1>
                        <h3 className='leading-6 text-gray-500'>{t('summary.subtitle')}</h3>
                    </div>
                    <div className='py-3'>
                        <Input label={t('summary.mailSubject')} value={messageSubject} setValue={setMessageSubject} />
                    </div>
                    <div className='py-3'>
                        <Textarea label={t('summary.message')} value={messageBody} setValue={setMessageBody}/>
                    </div>
                    {!isConcatenation && <>
                        <div className='py-3'>
                            <SingleCheckbox label={t('summary.notifyOnComplete')} value={senderReminderComplete ?? false}
                                            setValue={setSenderReminderComplete}/>
                            <div className='flex items-center justify-start space-x-4'>
                                <SingleCheckbox label={t('summary.sendReminder')} value={sendReminders}
                                                setValue={setSendReminders}/>
                                <div className='w-auto'>
                                    <Select value={reminderInterval ?? ''} setValue={setReminderInterval}
                                            options={intervalOptions} disabled={!sendReminders}/>
                                </div>
                            </div>
                        </div>
                        <div className='py-3 w-full'>
                            <h1 className='text-lg leading-7 font-medium text-gray-900'>{t('options')}</h1>
                            <div className='flex items-center w-full'>
                                <p className='w-1/4 mr-4'>{t('summary.setExpiration')}</p>
                                <Input type='date' value={expirationDate} setValue={setExpirationDate}/>
                            </div>
                            {hasWorkspacesPermissions && availableWorkspaces &&
                                <MultiSelectWorkspacesSummary label='Condividi con i tuoi spazi di lavoro'
                                                              setSelected={setSelectedWorkspaces}
                                                              selected={availableWorkspaces?.filter(w => selectedWorkspaces?.includes(w.id))}
                                                              options={availableWorkspaces}/>}
                        </div>
                    </>}
                    <TabletSelector
                        devices={devices}
                        graphUser={graphUser}
                        selectedDevice={selectedDevice}
                        setSelectedDevice={setSelectedDevice}
                    />
                </div>
                <div className='flex flex-2 mx-4 mb-6 w-1/3'>
                    <SummarySidebar
                        recipients={recipients}
                        documentTitle={filename}
                        dataCollections={dataCollections}
                        informative={informative}
                        setRedirectLink={setRedirectLink}
                        setHasGraphConsentToRecipient={setHasGraphConsentToRecipient}
                        setNoEmailSent={setNoEmailSent}
                        setNoEmailSignedSent={setNoEmailSignedSent}
                        processList={processList}
                        avoidLtArchive={avoidLtArchive}
                        setAvoidLtArchive={setAvoidLtArchive}
                        editAvoidLtArchiveChoice={editAvoidLtArchiveChoice}
                        isConcatenation={isConcatenation}
                    />
                </div>
            </div>
        </PageWithStepsHeaderAndFooter>
    )
}
export default Summary;
