import {v4} from "uuid";
import {useState} from "react";
import {classNames} from "../../../utils/helper";

// PARAMS
// options = [
//            {
//              value,
//              label
//            },
//            ...
//           ]

const Select = ({options = [], value='', setValue, placeholder, label, disabled=false}) => {
    const [id]= useState(v4())
    return (
        <>
            <span>
                <label htmlFor={`location-${id}`} className="block text-sm font-medium leading-5 text-gray-700">
                    {label}
                </label>
                <select
                    id={`location-${id}`}
                    name={label}
                    className={classNames(disabled ? 'bg-gray-200 text-gray-600' : '', "mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 focus:outline-none focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md")}
                    value={value ?? ''}
                    onChange={e => setValue(e.target.value)}
                    disabled={disabled}
                    data-testid="select"
                >
                    {placeholder && <option key={`plh-${id}`} value={null} hidden>{placeholder}</option>}
                    {options.map((option, idx) => <option key={`opt${id}-${idx}`} className='text-black' value={option.value} hidden={!option.value}>{option.label}</option>)}
                </select>
            </span>

        </>
    )
}

export default Select;
