import BigLabeledButton from "../../atoms/BigLabeledButton/BigLabeledButton";
import {useTranslation} from "react-i18next";

const BigLabeledButtonSelector = ({ options, onChange }) => {
    const { t } = useTranslation();
    return (
        <div className="flex flex-col items-center w-full space-y-4">
            <h1 className='my-2 mx-0 text-2xl leading-8 font-medium text-center'>
                {t('login.selectEnvironment')}
            </h1>
            {options.map(option => (
                <BigLabeledButton
                    key={option.value}
                    label={option.label}
                    onClick={() => onChange(option.value)}
                />
            ))}
        </div>
    )
}
export default BigLabeledButtonSelector;
