import {v4} from "uuid";
import {useEffect, useState} from "react";

const prefix = {
    'IT': '+39',
    'FR': '+33',
    'UK': '+44'
}

const InputPhone = ({label, placeholder, setPhone}) => {
    const [selectedPrefix, setSelectedPrefix] = useState(prefix['IT'])
    const [value, setValue] = useState('')
    const id = v4();

    useEffect(() => {
        setPhone(selectedPrefix + value)
    },[value, selectedPrefix])

    return (
        <div className="my-1">
            <label htmlFor={`phone-${id}`} className="block text-sm font-medium text-gray-700">
                {label}
            </label>
            <div className="mt-1 relative rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 flex items-center">
                    <label htmlFor="country" className="sr-only">
                        Country
                    </label>
                    <select
                        id={`phone-country-${id}`}
                        name="phone-country"
                        className="focus:ring-blue-500 focus:border-blue-500 h-full py-0 pl-3 pr-7 border-transparent bg-transparent text-gray-500 sm:text-sm rounded-md"
                        value={selectedPrefix}
                        onChange={e => setSelectedPrefix(e.target.value)}
                    >
                        {Object.keys(prefix).map(code => <option key={prefix[code]} value={prefix[code]}>{code}</option>)}
                    </select>
                    <p className='sm:text-sm outline-none pl-1 py-2'>{selectedPrefix}</p>
                </div>
                <input
                    type="text"
                    name="phone"
                    id={`phone-${id}`}
                    className="focus:ring-blue-500 focus:border-blue-500 block w-full pl-24 sm:text-sm border-gray-300 rounded-md"
                    placeholder={placeholder}
                    value={value}
                    onChange={(e)=> setValue(e.target.value)}
                />
            </div>
        </div>
    )
}

export default InputPhone;
