import React, {useEffect, useState} from "react";
import FirmaMassiva from "../../pages/ManagePage/Sections/FirmaMassiva/FirmaMassiva";
import {closeEventDetail, librarySelector, openEventDetail} from "../../../features/library/library.slice";
import {useDispatch, useSelector} from "react-redux";
import {downloadEventPDF} from "../../../features/library/library.action";
import Toggle from "../../atoms/Toggle/Toggle";
import {useParams} from "react-router-dom";
import DocInviati from "../../pages/ManagePage/Sections/DocInviati/DocInviati";
import DocInAttesa from "../../pages/ManagePage/Sections/DocInAttesa/DocInAttesa";
import DocCanceled from "../../pages/ManagePage/Sections/DocCanceled/DocCanceled";
import DocOrganization from "../../pages/ManagePage/Sections/DocOrganization/DocOrganization";

const ManageBody = ({section, completed=false}) => {
    const {workspaceId} = useParams()
    const dispatch = useDispatch();
    const {sentEvents, sentEventsCount, eventsMassiveSignature, eventsCountMassiveSignature, waitingEvents, waitingEventsCount,
        canceledEvents, canceledEventsCount, organizationEvents, organizationEventsCount,
        listLoadedSentEvents, devices, loadingEvents, currentEventId, detailOpened} = useSelector(librarySelector)
    const initialFilters = {pagination: {currentPage: 1, offset: 0, limit:10}, txtFilter: '', sortField: 'referenceDate', descending: true, startDate: '', endDate: ''}
    const [filters, setFilters] = useState(initialFilters);
    const [flgIncludiCompletati, setFlgIncludiCompletati] = useState(completed)

    useEffect(() => {
        setFilters({...filters, pagination: initialFilters.pagination})
    },[flgIncludiCompletati])

    useEffect(() => {
        setFilters(initialFilters)
    },[section])

    const closeDetail = async () => dispatch(closeEventDetail())

    const downloadPDF = async (row) => await dispatch(downloadEventPDF({url: row?.downloadPdfUrl}));
    const goToSign = async (event) => {if (event?.signUrl) window.location.href = event.signUrl}

    const viewEventDetail = async (event, getEventDetails) => {
        if(!event?.detail) await getEventDetails(event.id)
        dispatch(openEventDetail({eventId: event.id}));
    }

    const toggleCompletati = <Toggle value={flgIncludiCompletati} setValue={setFlgIncludiCompletati} label='Includi completati'/>

    switch (section) {
        case 'sent':
            return <DocInviati completed={completed} currentEventId={currentEventId} initialFilters={initialFilters} filters={filters} setFilters={setFilters} flgIncludiCompletati={flgIncludiCompletati} setFlgIncludiCompletati={setFlgIncludiCompletati}
                               detailOpened={detailOpened} viewEventDetail={viewEventDetail} closeDetail={closeDetail} toggleCompletati={toggleCompletati}
                               loadingEvents={loadingEvents} sentEvents={sentEvents} sentEventsCount={sentEventsCount} devices={devices} listLoadedSentEvents={listLoadedSentEvents}
            />
        case 'received':
            return <DocInAttesa downloadPDF={downloadPDF} flgIncludiCompletati={flgIncludiCompletati} setFilters={setFilters} filters={filters} loadingEvents={loadingEvents} toggleCompletati={toggleCompletati} closeDetail={closeDetail}
                                viewEventDetail={viewEventDetail} detailOpened={detailOpened} currentEventId={currentEventId} completed={completed} waitingEvents={waitingEvents} waitingEventsCount={waitingEventsCount} goToSign={goToSign}
            />
        case 'massivesignature':
            return <FirmaMassiva goToSign={goToSign} flgIncludiCompletati={flgIncludiCompletati} setFlgIncludiCompletati={setFlgIncludiCompletati} eventsMassiveSignature={eventsMassiveSignature} loadingEvents={loadingEvents} currentEventId={currentEventId}
                                 detailOpened={detailOpened} viewEventDetail={viewEventDetail} closeDetail={closeDetail} filters={filters} setFilters={setFilters} eventsCountMassiveSignature={eventsCountMassiveSignature} downloadPDF={downloadPDF}/>
        case 'canceled':
            return <DocCanceled filters={filters} setFilters={setFilters} closeDetail={closeDetail} detailOpened={detailOpened} currentEventId={currentEventId} loadingEvents={loadingEvents} canceledEvents={canceledEvents} canceledEventsCount={canceledEventsCount}
                                viewEventDetail={viewEventDetail}/>
        case 'organization':
            return <DocOrganization organizationEvents={organizationEvents} organizationEventsCount={organizationEventsCount} loadingEvents={loadingEvents}
                                    downloadPDF={downloadPDF} viewEventDetail={viewEventDetail} currentEventId={currentEventId} detailOpened={detailOpened} closeDetail={closeDetail} filters={filters} setFilters={setFilters} workspaceId={workspaceId}/>
        default:
            return <h1>Pagina non disponibile</h1>

    }
}
export default ManageBody;
