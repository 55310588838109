import {useDispatch} from "react-redux";
import React from "react";
import {openNotificationDetail} from "../../../../features/userHome/userHome.slice";
import AvatarTable from "../../../molecules/Tables/AvatarTable/AvatarTable";
import {useTranslation} from "react-i18next";
import {toLocaleString} from "../../../../utils/helper";


const NotificationsList = ({data}) => {
    const {t} = useTranslation()
    const dispatch = useDispatch();
    // const {currentPerson} = useSelector(peopleSelector)
    //
    const viewNotificationDetail = async (item) => {
        dispatch(openNotificationDetail({item}));
    }

    const mapDataToFeeds = function(data) {
        return data.map(d => {
            const obj = { ...d };
            obj.displayName = d.sender.givenName + ' ' + d.sender.familyName;
            obj.date = toLocaleString(d.notificationDate);
            return obj;
        });
    };

    return (
        <div className="flex flex-col flex-1">
            <AvatarTable data={mapDataToFeeds(data)} actionColumn={{
                type: 'button',
                buttonLabel: t('tableColumns.view'),
                onclick: (d) => viewNotificationDetail(d)

            }}/>

            {/*<SimpleTable data={data} columns={columns}/>*/}
        </div>
    )
}

export default NotificationsList;
