import {useParams, useSearchParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {navigationSelector} from "../../../features/navigation/navigation.slice";
import React, {useEffect, useMemo, useState} from "react";
import VerticalNavigation from "../../molecules/VerticalNavigation/VerticalNavigation";
import LoadingSpinner from "../../atoms/LoadingSpinner/LoadingSpinner";
import SimplePageWithBackground from "../Templates/SimplePageWithBackground/SimplePageWithBackground";
import ManageBodyExternal from "../../organisms/ManageBody/ManageBodyExternal";
import {setLogoutRedirectUrl} from "../../../utils/logout-helper";

const ManagePageExternal = () => {
    const { section, token} = useParams();
    let [searchParams] = useSearchParams();
    const [isLoading, setIsLoading] = useState(true)
    const {manageSectionExternal} = useSelector(navigationSelector)

    const [completed, predefinedFilters] = useMemo(() => {
        const completed = searchParams.get('completed') === 'true'
        const txtFilter = searchParams.get('txtFilter')
        const startDate = searchParams.get('startDate')
        const endDate = searchParams.get('endDate')
        return [completed, {txtFilter, startDate, endDate}]
    }, [searchParams])

    //useEffect che prende token dalla url, lo salva in session storage e poi setta isLoading a false per far vedere la pagina
    useEffect(() => {
        setLogoutRedirectUrl('/external');
        if(token) {
            sessionStorage.setItem('token',`Bearer ${token}`)
        }
        setIsLoading(false)
    }, [token])

    if(isLoading)
        return <SimplePageWithBackground>
            <LoadingSpinner />
        </SimplePageWithBackground>
    if(sessionStorage.getItem('token') === null)
        return <SimplePageWithBackground>
            <div className='flex flex-col items-center justify-center h-full'>
                <div className='text-2xl font-bold'>Sessione scaduta o non valida</div>
            </div>
        </SimplePageWithBackground>
    else return(
        <div className='flex w-full justify-between py-6 pr-6'>
            <div className='divide-y-2'>
                <div className='pt-1 pb-2'>
                    <VerticalNavigation navigation={manageSectionExternal} section={section} baseurl='external' />
                </div>
            </div>

            <div className='flex w-full overflow-hidden'>
                <ManageBodyExternal section={section} completed={completed} predefinedFilters={predefinedFilters} />
            </div>
        </div>
    )
}

export default ManagePageExternal;
