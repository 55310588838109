import {createEntityAdapter, createSlice} from "@reduxjs/toolkit";
import {v4} from "uuid";
import {addRecipientsThunk, getRecipientsThunk, getSignatureTypesListThunk} from "./recipients.actions";
import omit from "lodash/omit";
import isUndefined from "lodash/isUndefined";

const colors = ['red','green','yellow','purple','indigo','pink']

const getColor = (idx) => {
    if(idx >= colors.length) return getColor(idx - colors.length)
    return colors[idx]
}

const isInformedPathLinkedToRecipient= (recipients) => {
    const checkAllInformativeLinked = {}
    Object.values(recipients).forEach(rec => Object.keys(rec.linkedInformative).forEach(linkedInfoId => {
            if (!checkAllInformativeLinked[linkedInfoId]) checkAllInformativeLinked[linkedInfoId] = rec.linkedInformative[linkedInfoId]
        }
    ))
    return !Object.values(checkAllInformativeLinked).some(el => el === false);
}

const isDataCollectionsLinkedToRecipient= (recipients, sender) => {
    const checkAllDataCollectionsLinked = {}
    Object.values(recipients).forEach(rec => Object.keys(rec.linkedDataCollections).forEach(linkedDCId => {
            checkAllDataCollectionsLinked[linkedDCId] = checkAllDataCollectionsLinked[linkedDCId] || rec.linkedDataCollections[linkedDCId] || sender.linkedDataCollections[linkedDCId]
        }
    ))
    return !Object.values(checkAllDataCollectionsLinked).some(el => el === false);
}

const recipientsAdapter = createEntityAdapter();

const initialState = recipientsAdapter.getInitialState({
    recipients: {},
    signatureTypesList: [],
    allInformativeLinked: false,
    allDataCollectionsLinked: false,
    sender: {linkedDataCollections: {}},
})

const recipientsSlice = createSlice({
        name: 'recipients',
        initialState,
        reducers: {
            addRecipient: (state, action) => {
                const id = v4();
                state.recipients[id] = {
                    id,
                    name: null,
                    actorType: action.payload.isUser ? 'USER' : 'ROLE',
                    progressNumber: Object.keys(state.recipients).length +1,
                    signatureType: null,
                    linkedInformative: {},
                    linkedDataCollections: {}
                }
            },
            setRecipient: (state, action) => {
                state.recipients[action.payload.id]= action.payload
            },
            setSendNoEmail: (state, action) => {
                state.recipients[action.payload.id] = {...state.recipients[action.payload.id], emailDetails: {...state.recipients[action.payload.id].emailDetails, noEmail: action.payload.value}}
            },
            setSendNoEmailSigned: (state, action) => {
                state.recipients[action.payload.id] = {...state.recipients[action.payload.id], emailDetails: {...state.recipients[action.payload.id].emailDetails, noEmailSigned: action.payload.value}}
            },
            setRedirectLink: (state, action) => {
                if(action?.payload?.id && state.recipients[action.payload.id])
                    state.recipients[action.payload.id].redirectLink = action.payload.redirectLink
            },
            setRecipientsList: (state, action) => {
                action.payload.forEach(rec => state.recipients[rec.id] = rec)
            },
            removeRecipient: (state, action) => {
                state.recipients = omit(state.recipients, action.payload)
            },
            linkRecipientToInformativa: (state, action) => {
                state.recipients[action.payload.recipientId].linkedInformative[action.payload.informativaId] = true;
                state.allInformativeLinked = isInformedPathLinkedToRecipient(state.recipients)
            },
            unlinkRecipientToInformativa: (state, action) => {
                state.recipients[action.payload.recipientId].linkedInformative[action.payload.informativaId] = false;
                state.allInformativeLinked = isInformedPathLinkedToRecipient(state.recipients)
            },
            linkRecipientToDataCollection: (state, action) => {
                if(!isUndefined(state.recipients[action.payload.recipientId].linkedDataCollections[action.payload.dataCollectionId]))
                    state.recipients[action.payload.recipientId].linkedDataCollections[action.payload.dataCollectionId] = true;
                state.allDataCollectionsLinked = isDataCollectionsLinkedToRecipient(state.recipients, state.sender)
            },
            unlinkRecipientToDataCollection: (state, action) => {
                if(!isUndefined(state.recipients[action.payload.recipientId].linkedDataCollections[action.payload.dataCollectionId]))
                    state.recipients[action.payload.recipientId].linkedDataCollections[action.payload.dataCollectionId] = false;
                state.allDataCollectionsLinked = isDataCollectionsLinkedToRecipient(state.recipients, state.sender)
            },
            deleteDataCollectionFromRecipients: (state,action) => {
                Object.values(state.recipients).forEach(rec => {
                    rec.linkedDataCollections = omit(rec.linkedDataCollections, action.payload)
                })
                state.sender.linkedDataCollections = omit(state.sender.linkedDataCollections, action.payload)
                state.allDataCollectionsLinked = isDataCollectionsLinkedToRecipient(state.recipients, state.sender)
            },
            resetState: () => {
                return initialState;
            },
            setDataCollectionSenderOwner: (state, action) => {
                state.sender.linkedDataCollections[action.payload.dataCollectionId] = action.payload.isSenderOwner
                Object.values(state.recipients).forEach(rec => rec.linkedDataCollections[action.payload.dataCollectionId] = false)
                state.allDataCollectionsLinked = isDataCollectionsLinkedToRecipient(state.recipients, state.sender)
            },
            addInformativaToRecipients: (state,action) => {
                Object.values(state.recipients).forEach(rec => {
                    rec.linkedInformative[action.payload.id] = action?.payload?.selectedRecipientsIds?.[rec.id] ?? false
                })
                state.allInformativeLinked = isInformedPathLinkedToRecipient(state.recipients)
            },
            deleteInformativaFromRecipients: (state,action) => {
                Object.values(state.recipients).forEach(rec => {
                    rec.linkedInformative = omit(rec.linkedInformative, action.payload)
                })
                state.allInformativeLinked = isInformedPathLinkedToRecipient(state.recipients)
            },
            checkLinkedInformative: (state) => {
                state.allInformativeLinked = isInformedPathLinkedToRecipient(state.recipients)
            },
            checkLinkedDataCollections: (state,action) => {
                if(state.recipients && action.payload) {
                    Object.values(state.recipients).forEach(rec => action.payload.forEach(formId => rec.linkedDataCollections[formId] = rec.linkedDataCollections[formId] ?? false))
                    action.payload.forEach(formId => state.sender.linkedDataCollections[formId] = state.sender.linkedDataCollections[formId] ?? false)
                }
                state.allDataCollectionsLinked = isDataCollectionsLinkedToRecipient(state.recipients, state.sender)
            }
        },
        extraReducers: (builder) => {
            builder
                .addCase(addRecipientsThunk.fulfilled, (state, action) => {
                    let recipients = {};
                    action.payload.recipients.forEach((rec) => {
                        recipients[rec.id] = { ...rec, color: getColor(Object.keys(recipients).length) };
                    });
                    state.recipients = recipients;
                    state.sender = action.payload.sender;
                })
                .addCase(getRecipientsThunk.fulfilled, (state, action) => {
                    action.payload.recipients.forEach((recipient) => {
                        state.recipients[recipient.id] = { ...state.recipients[recipient.id], ...recipient };
                    });
                    state.sender = action.payload.sender;
                })
                .addCase(getSignatureTypesListThunk.fulfilled, (state, action) => {
                    state.signatureTypesList = action.payload;
                });
        },
    }
)

export const recipientsSelector = (state) => state.recipients;

export const {
    resetState,
    addRecipient,
    setRecipient,
    setSendNoEmail,
    setSendNoEmailSigned,
    setRecipientsList,
    removeRecipient,
    linkRecipientToInformativa,
    unlinkRecipientToInformativa,
    linkRecipientToDataCollection,
    unlinkRecipientToDataCollection,
    setDataCollectionSenderOwner,
    deleteInformativaFromRecipients,
    addInformativaToRecipients,
    checkLinkedInformative,
    checkLinkedDataCollections,
    deleteDataCollectionFromRecipients,
    setRedirectLink
} = recipientsSlice.actions;

export default recipientsSlice.reducer
