import {useEffect, useRef} from 'react';
import {Player} from '@lordicon/react';

const oceanIcon = require('./../../../assets/lordicon/wired-outline-446-ocean.json')
const treeIcon = require('./../../../assets/lordicon/wired-outline-443-tree.json')
const cloudsIcon = require('./../../../assets/lordicon/wired-outline-801-two-clouds.json')
const recyclingIcon = require('./../../../assets/lordicon/wired-outline-1683-recycling.json')

const icons = {
    water: oceanIcon, //Ocean
    tree: treeIcon, //tree
    air: cloudsIcon, //two clouds
    waste: recyclingIcon, //recycling
}


const LordIcon = ({type}) => {
    const playerRef = useRef(null);

    useEffect(() => {
        playAnimation()
    }, [])

    const playAnimation = () => {
        playerRef.current?.playFromBeginning();
    }

    return (
        <div onMouseEnter={() => playerRef.current?.playFromBeginning()}>
            <Player
                ref={playerRef}
                icon={icons[type]}
                size={100}
            />
        </div>

    );
}

export default LordIcon;
