import React from 'react';
import {v4 as uuid} from 'uuid';
import Block from "./Block";
import fonts from "../../assets/Lists/fonts-list.json";
import {DropTarget} from 'react-drag-drop-container';
import {CustomView, isAndroid, isIOS} from 'react-device-detect';

const DropArea = ({children, draggedData, pageNumber, zoom, selectedBlockId = '', recipients = [], onSetBlock, onAddBlock, allBlocks=[], selectBlock}) => {
    const blocks= allBlocks ? allBlocks.filter(block => block.page === pageNumber) : null;

    const onDragOver = (e) => {
        e.preventDefault();
    };

    const onDrop = (e) => {
        e.stopPropagation();
        const [x, y, pageBounds] = getCoordiantes(e);
        const pageBoundsProp = {
            x: pageBounds.x / zoom,
            y: pageBounds.y / zoom
        }
        onAddBlock({
            x: x/zoom,
            y: y/zoom,
            width: draggedData.width/zoom,
            height: draggedData.height/zoom,
            pageNumber, id: uuid(),
            label: draggedData.label,
            fontFamily: fonts?.[0]?.value,
            fontSize: 10,
            page: pageNumber,
            fieldId: draggedData.fieldId,
            recipientId: draggedData.recipientId,
            pageBounds: pageBoundsProp
        });
    };

    const getCoordiantes = (e) => {
        let currentTargetRect = e.currentTarget.getBoundingClientRect();
        let coords = {
            x: e.clientX - draggedData.x - currentTargetRect.left,
            y: e.clientY - draggedData.y - currentTargetRect.top
        };
        let pageBounds = {
            x: currentTargetRect.right - currentTargetRect.left,
            y: currentTargetRect.bottom - currentTargetRect.top
        };
        coords.x = coords.x + draggedData.width > pageBounds.x ? pageBounds.x - draggedData.width : coords.x;
        coords.y = coords.y + draggedData.height > pageBounds.y ? pageBounds.y - draggedData.height : coords.y;
        coords.x = coords.x < 0 ? 0 : coords.x;
        coords.y = coords.y < 0 ? 0 : coords.y;
        return [coords.x, coords.y, pageBounds];
    };


    const onMobileDrop = (e) => {
        e.stopPropagation();
        const draggedData = e.dragData;
        const [x, y, pageBounds] = getMobileCoordiantes(e, zoom);
        const pageBoundsProp = {
            x: pageBounds.x / zoom,
            y: pageBounds.y / zoom
        }
        onAddBlock({
            x: x/zoom,
            y: y/zoom,
            width: draggedData.width/zoom,
            height: draggedData.height/zoom,
            pageNumber, id: uuid(),
            label: draggedData.label,
            fontFamily: fonts?.[0]?.value,
            fontSize: 10,
            page: pageNumber,
            fieldId: draggedData.fieldId,
            recipientId: draggedData.recipientId,
            pageBounds: pageBoundsProp
        });
    };

    const getMobileCoordiantes = (e) => {
        const MOBILE_DROP_OFFSET_X = 6;
        const MOBILE_DROP_OFFSET_Y = -4;

        const draggedData = e.dragData;
        let currentTargetRect = e.currentTarget.getBoundingClientRect();
        console.log(e.currentTarget);
        console.log(currentTargetRect);

        let coords = {
            x: e.x - e.dragData.leftOffset - currentTargetRect.left + MOBILE_DROP_OFFSET_X,
            y: e.y - e.dragData.topOffset - currentTargetRect.top + MOBILE_DROP_OFFSET_Y
        };
        let pageBounds = {
            x: currentTargetRect.right - currentTargetRect.left,
            y: currentTargetRect.bottom - currentTargetRect.top
        };
        //coords.x = coords.x + draggedData.width > pageBounds.x ? pageBounds.x - draggedData.width : coords.x;
        coords.y = coords.y + draggedData.height > pageBounds.y ? pageBounds.y - draggedData.height : coords.y;
        //coords.x = coords.x < 0 ? 0 : coords.x;
        coords.y = coords.y < 0 ? 0 : coords.y;
        return [coords.x, coords.y, pageBounds];
    };


    const onDragStop= (e, d, b) => {
        onSetBlock({...b, x: d.x/zoom, y: d.y/zoom});
    }

    const onResizeStop = (ref ,b) => {
        onSetBlock({...b, width: ref.offsetWidth/zoom, height: ref.offsetHeight/zoom});
    }

    return(
        <>
            <CustomView condition={!isAndroid && !isIOS}>
                <div style={{width: "fit-content", height: "fit-content", position: "relative"}}
                     onDrop={(e) => onDrop(e)}
                     onDragOver={(e) => onDragOver(e)}>
                    {children}
                    {blocks && blocks.map((block) => {
                        return <Block key={block?.id} onDragStop={onDragStop} onResizeStop={onResizeStop} block={block} zoom={zoom}
                                      selected={block.id === selectedBlockId} color={recipients?.[block.recipientId]?.color ?? 'blue'} onClick={selectBlock}/>
                    })}
                </div>
            </CustomView>
            <CustomView condition={isAndroid || isIOS}>
                <DropTarget onHit={onMobileDrop}>
                    <div style={{width: "fit-content", height: "fit-content", position: "relative", "z-index": 50}}
                         onDrop={(e) => onDrop(e)}
                         onDragOver={(e) => onDragOver(e)}>
                        {children}
                        {blocks && blocks.map((block) => {
                            return <Block key={block?.id} onDragStop={onDragStop} onResizeStop={onResizeStop} block={block} zoom={zoom}
                                          selected={block.id === selectedBlockId} color={recipients?.[block.recipientId]?.color ?? 'blue'} onClick={selectBlock}/>
                        })}
                    </div>
                </DropTarget>
            </CustomView>
        </>
    )

}

export default DropArea;
