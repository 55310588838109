import {Menu, Transition} from "@headlessui/react";
import {classNames} from "../../../../utils/helper";
import React, {Fragment} from "react";

const DropdownCircular = ({label, options = [], item, disabled = false}) => {
    return (
        <Menu as="div" className="relative inline-block text-left">
            {({ open }) => (
                <>
                    <div>
                        <Menu.Button
                            className={classNames(disabled ? 'text-gray-500 bg-white focus:ring-0 focus:outline-none cursor-default' : 'focus:ring-blue-500 bg-blue-600 text-white hover:bg-blue-700 focus:ring-offset-gray-100 focus:ring-2 focus:ring-offset-2',
                                "flex justify-center rounded-full border border-gray-300 shadow-sm px-4 py-2 text-sm font-medium focus:outline-none ")}>
                            {label}
                        </Menu.Button>
                    </div>
                    {open && !disabled &&
                        <Transition
                            show={open}
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <Menu.Items
                                static
                                className="origin-center absolute mt-2 w-full break-words rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10"
                            >
                                <div className="py-1">
                                    {options && options.map((opt, optIdx) => <Menu.Item key={optIdx}>
                                            {({active}) => (
                                                <div
                                                    onClick={() => (opt.onclick ? opt.onclick(item) : {})}
                                                    className={classNames(
                                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                        'block px-4 py-2 text-sm'
                                                    )}
                                                >
                                                <span className={classNames(
                                                    "cursor-pointer",
                                                    opt.labelVariant === 'warn' ? 'text-red-600' : ''
                                                )}>{opt.label}</span>
                                                </div>
                                            )}
                                        </Menu.Item>
                                    )}
                                </div>
                            </Menu.Items>
                        </Transition>
                    }
                </>
            )}
        </Menu>
    )
}

export default DropdownCircular;
