import PageWithHeader from "../Templates/PageWithHeader/PageWithHeader";
import React, {useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {
    addUsersToListThunk,
    countPeople,
    createNewUserListThunk,
    deleteUserListsThunk,
    getPeople,
    getPeopleByListIdThunk,
    getUserLists,
    openPersonDetail,
    peopleSelector,
    removeUsersFromListThunk
} from "../../../features/people/people.slice";
import PeopleList from "../../organisms/PeopleList/PeopleList";
import InputWithLeadingIcon from "../../atoms/Inputs/InputWithLeadingIcon/InputWithLeadingIcon";
import Paginator from "../../atoms/Paginator/Paginator";
import IconButton from "../../atoms/Buttons/IconButton/IconButton";
import {FULL} from "../../atoms/Buttons/sizes";
import {useTranslation} from "react-i18next";
import {MagnifyingGlassIcon, PlusIcon} from "@heroicons/react/20/solid";
import {userHomeSelector} from "../../../features/userHome/userHome.slice";
import Select from "../../atoms/Select/Select";
import VerticalNavigation from "../../molecules/VerticalNavigation/VerticalNavigation";
import {useNavigate, useParams} from "react-router-dom";
import ModalNewUserList from "../../molecules/Modals/ModalNewUserList/ModalNewUserList";
import isEmpty from "lodash/isEmpty";
import DropdownButton from "../../atoms/Dropdowns/DropdownButton/DropdownButton";
import Icon from "../../atoms/Icon/Icon";
import UsersAdvancedFilters from "../../molecules/UsersAdvancedFilters/UsersAdvancedFilters";

const UsersPage = () => {
    const {t} = useTranslation()
    const navigate = useNavigate();

    const {listId} = useParams()
    const dispatch = useDispatch();
    const {people, peopleCount, listLoaded, loadingPeople, userLists} = useSelector(peopleSelector)

    const { selectedTenantId, userOrganizations } = useSelector(userHomeSelector);
    const addBirthdateToUserTable = userOrganizations?.[selectedTenantId]?.addBirthdateToUserTable ?? false;

    const peopleRoles = userOrganizations?.[selectedTenantId]?.peopleRoles ?? [];
    const peopleRolesOptions = peopleRoles?.map(pr => {return {value: pr.name, label: pr.description}});

    const defaultRole = (peopleRoles && peopleRoles.length > 0) ? peopleRoles[0].name : 'USER';
    const initialFilter = {pagination: {currentPage: 1, offset: 0, limit:10}, txtFilter: '', role: defaultRole}
    const [filters, setFilters] = useState(initialFilter);

    const [openNewListModal, setOpenNewListModal] = useState(false);

    const [selectedContacts, setSelectedContacts] = useState({})
    const [selectedContactsEmails, setSelectedContactsEmails] = useState({})

    const list = useMemo(() =>
            ([{name: 'ALL', href: ''}, ...userLists.map(l => ({id: l.id, name: l.name, href: `${l.id}`, deletable: true, onClick: (e) => deleteList(e, l.id)}))]),
        [userLists, listId])

    useEffect(() => {
        dispatch(getUserLists());
    }, []);

    useEffect(() => {
        if(!listLoaded) updatePeople()
    },[listLoaded])

    useEffect(() => {
        const callbackTimer = setTimeout(updatePeople, 300);
        return () => clearTimeout(callbackTimer);
    }, [filters])

    useEffect(() => {
        setSelectedContacts({})
        setFilters(initialFilter)
    }, [listId])

    const setTxtFilter = (txtFilter) => setFilters({...filters, pagination: {currentPage: 1, offset: 0, limit:10}, txtFilter})
    const setFilterRole = (role) => setFilters({...filters, pagination: {currentPage: 1, offset: 0, limit:10}, role})

    const anyContactSelected = useMemo(() => (!isEmpty(selectedContacts) && Object.values(selectedContacts).some(v => v)), [selectedContacts])

    const selectContact = (id) => {
        setSelectedContacts({...selectedContacts, [id]: !selectedContacts[id]})
        setSelectedContactsEmails({...selectedContactsEmails, [id]: people.find(p => p.id === id)?.email})
    }

    const updatePeople = () => {
        if(listId)
            dispatch(getPeopleByListIdThunk({
                id: listId,
                filter: filters.txtFilter,
                offset: filters.pagination.offset,
                limit: filters.pagination.limit
            }))
        else {
            dispatch(getPeople(filters));
            dispatch(countPeople(filters))
        }
    }

    const newContact = async () => dispatch(openPersonDetail({}));

    const newList = async (name) => {
        await dispatch(createNewUserListThunk({name})).unwrap();
        setOpenNewListModal(false);
        dispatch(getUserLists());
    }

    const deleteList = async (e , id) => {
        e.preventDefault();
        e.stopPropagation();
        await dispatch(deleteUserListsThunk({id})).unwrap();
        await dispatch(getUserLists()).unwrap();

        if((listId && !userLists.some(l => l.id === listId)))
            navigate('/rubrica')
    }

    const addUsersToList = async (listId) => {
        await dispatch(addUsersToListThunk({
            listId,
            userIds: Object.keys(selectedContacts).filter(key => selectedContacts[key])
        })).unwrap();
        setSelectedContacts({})
    }

    const removeUsersFromList = async () => {
        await dispatch(removeUsersFromListThunk({
            listId,
            userIds: Object.keys(selectedContacts).filter(key => selectedContacts[key])
        })).unwrap();
        setSelectedContacts({})
        updatePeople()
    }

    const removeSingleUserFromList = async (userId) => {
        await dispatch(removeUsersFromListThunk({
            listId,
            userIds: [userId]
        })).unwrap();
        setSelectedContacts({})
        updatePeople()
    }

    return(
        <PageWithHeader>
            <div className='flex w-full justify-center my-8'>
                {!isEmpty(userLists) && <div className='pt-1 pb-2'>
                    <VerticalNavigation navigation={list} section={listId} baseurl='rubrica'/>
                </div>}
                <div className='flex flex-col flex-1 ml-4 '>
                    <div className='flex items-start mb-4 space-x-2' >
                        <div className='w-auto'>
                            {peopleRolesOptions && peopleRolesOptions.length > 1 ?
                                <Select value={filters.role} setValue={setFilterRole}
                                        options={peopleRolesOptions}/> : <></>
                            }
                        </div>
                        <div className='flex-grow'>
                            <InputWithLeadingIcon
                                value={filters.txtFilter}
                                setValue={setTxtFilter}
                                icon={<MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
                                placeholder={t('search.forNameEmail')}
                            />
                        </div>
                        <UsersAdvancedFilters />
                        <div className='my-1'>
                            {!anyContactSelected ?
                                <IconButton label={t('settings.newList')} size={FULL}
                                            icon={<Icon size='4' type='plus'/>}
                                            onClick={() => setOpenNewListModal(true)}/> :
                                listId ?
                                    <IconButton label={t('settings.removeFromList')} size={FULL}
                                                variant='red'
                                                icon={<Icon size='4' type='minus'/>}
                                                onClick={removeUsersFromList}/> :
                                    <DropdownButton label={t('settings.addToList')}
                                                    options={userLists.map(l => ({label: l.name, onClick: () => addUsersToList(l.id)}))}
                                                    size={FULL} />
                            }
                        </div>
                        <div className='my-1'>
                            <IconButton label={t('settings.newContact')} size={FULL} icon={<PlusIcon className='w-4 h-4'/>} onClick={newContact}/>
                        </div>
                    </div>

                    <div className='flex justify-center'>
                        <PeopleList data={people} updateData={updatePeople} loading={loadingPeople} filters={filters} setFilters={setFilters}
                                    currentPeopleRole={filters.role ?? 'USER'} addBirthdateToUserTable={addBirthdateToUserTable} selectedContacts={selectedContacts} setSelectedContacts={selectContact}
                                    isList={listId !== undefined} removeUsersFromList={removeSingleUserFromList}
                        />
                        <ModalNewUserList open={openNewListModal} setOpen={setOpenNewListModal} confirmLabel={t('confirm')} onConfirm={newList}/>
                    </div>
                    <div className='flex flex-col flex-1'>
                        <Paginator pageSize={10} currentPage={filters.pagination.currentPage} totalResults={peopleCount} onClick={(pagination) => setFilters({...filters, pagination})}/>
                    </div>
                </div>
            </div>
        </PageWithHeader>
    )
}

export default UsersPage;
