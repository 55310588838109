import {classNames} from "../../../utils/helper";

const variants = {
    base : 'focus:ring-blue-500 text-blue-600 border-gray-300',
    green : 'focus:ring-offset-0 ring-offset-0 focus:ring-0 text-green-600 border-gray-300',
    white: 'text-green-600 border-transparent focus:ring-offset-transparent ring-2 ring-white focus:ring-0 focus:ring-white',
    disabled: 'bg-gray-200 border-gray-300 focus:ring-offset-transparent ring-2 ring-white focus:ring-0 focus:ring-white'
}

const SingleCheckbox = ({label, value= false, setValue, variant = 'base', disabled = false}) =>  {
    return(
        <div className="relative flex items-center">
            <div className="flex items-center h-5">
                <input
                    name={label}
                    type="checkbox"
                    value={value}
                    checked={value}
                    onChange={() => {setValue(!value)}}
                    className={classNames(disabled ? variants['disabled']: variants[variant],  'h-4 w-4 rounded')}
                    disabled={disabled}
                    data-testid="checkbox"
                />
            </div>
            <div className="ml-3 text-sm">
                <label htmlFor={label} className="text-sm leading-5 font-normal">{label}</label>
            </div>
        </div>
    )
}
export default SingleCheckbox;
