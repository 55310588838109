import {createAsyncThunk} from "@reduxjs/toolkit";
import {
    addRecipientsCall,
    getRecipientsCall,
    getSignatureTypesListCall,
    linkRecipientToDataCollectionCall,
    linkRecipientToInformativaCall
} from "../../api/recipients";
import {toast} from "react-hot-toast";
import {Translation} from "react-i18next";
import Alert from "../../components/molecules/Alerts/Alert";
import {ERROR} from "../../components/molecules/Alerts/types";

export const getRecipientsThunk = createAsyncThunk(
    'recipients/getRecipientsThunk',
    async (props=null, {getState}) => {
        const { newDocument } = getState().documentalProcess;
        const response = await getRecipientsCall(newDocument.id);
        return response.data
    }
);

export const addRecipientsThunk = createAsyncThunk(
    'recipients/addRecipientsThunk',
    async ({recipients, isOrdered=false}, {getState}) => {
        const {newDocument} = getState().documentalProcess;
        const recipientsList = Object.values(recipients).map(rec => {
                const recipient = {
                    id: rec.id,
                    actorType: rec.actorType,
                    signatureType: rec.signatureType,
                    hasGraphConsent: rec?.hasGraphConsent,
                    graphConsentModelId: rec?.graphConsentModelId,
                    peopleRole: rec?.peopleRole,
                    isList: rec?.isList,
                    listId: rec?.listId,
                    listName: rec?.listName,
                    users: rec?.users ?? [],
                }
                if(rec.actorType === 'ROLE') recipient['name'] = rec.name
                else recipient['user'] = rec.user
                if(isOrdered) recipient['progressNumber'] = rec.progressNumber
                return recipient;
            }
        )

        try {
            const response = await addRecipientsCall(newDocument.id, recipientsList);
            return response.data
        }catch (e){
            toast.custom((tst) => (<Translation>{(t) => <Alert type={ERROR} message={e?.message ?? t('errorOccurred')} onClose={() => toast.dismiss(tst.id)}/>}</Translation>))
            throw e
        }
    }
);

export const getSignatureTypesListThunk = createAsyncThunk(
    'recipients/getSignatureTypesListThunk',
    async () => {
        const response = await getSignatureTypesListCall();
        return response.data
    }
);

export const setLinkedRecipientsToInformativeThunk = createAsyncThunk(
    'recipients/setLinkedRecipientsToInformativeThunk',
    async (props, {getState }) => {
        const {newDocument} = getState().documentalProcess;
        const {recipients} = getState().recipients;
        const data = Object.keys(recipients).map(key => {return{
            eventFlowTypeActorId: key,
            informedPaths: Object.keys(recipients[key].linkedInformative).filter(infoId => {
                return recipients[key].linkedInformative[infoId]
            })
        }})
        const response = await linkRecipientToInformativaCall(newDocument.id, data)
        return response.data

    }
);

export const setLinkedRecipientsToDataCollectionThunk = createAsyncThunk(
    'recipients/setLinkedRecipientsToDataCollectionThunk',
    async (props, {getState}) => {
        const {newDocument, dataCollections} = getState().documentalProcess;
        const {recipients} = getState().recipients;
        const data = Object.keys(recipients).map(key => {return{
            eventFlowTypeActorId: key,
            forms: Object.keys(recipients[key].linkedDataCollections).filter(dataCollectId => {
                return recipients[key].linkedDataCollections[dataCollectId]
            })
        }})
        const senderForms = []
        Object.keys(dataCollections).forEach(dcId => { if(dataCollections[dcId].isSenderOwner) senderForms.push(dcId)})
        data.push({
            eventFlowTypeActorId: 'SENDER',
            forms: senderForms
        })
        const response = await linkRecipientToDataCollectionCall(newDocument.id, data)
        return response.data
    }
);
