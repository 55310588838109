import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {deleteStepInformativaThunk} from "../../../features/documentalProcess/documentalProcess.actions";
import DraggableCard from "../../atoms/DraggableCard/DraggableCard";

const CardInformativa = ({step, informativaId, index, onCardClick}) => {
    const [tempTitle, setTempTitle] = useState(step.title);
    const dispatch = useDispatch();

    useEffect(() => {
        setTempTitle(step?.title)
    },[step])

    const onDelete = () => {
        onCardClick(null)
        dispatch(deleteStepInformativaThunk({stepId: step.id, informativaId} ))
    }

    return (
        <DraggableCard
            index={index}
            field={{caption: tempTitle, type:step.typeSymbol, name: step.id}}
            onDelete={onDelete}
            onCardClick={onCardClick}
        />
    )
}

export default CardInformativa;
