import SimplePage from "../Templates/SimplePage/SimplePage";
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import { authSelector} from "../../../features/auth/auth.slice";
import {useParams} from "react-router-dom";
import confirmoLogo from "../../../assets/logos/confirmo-logo.svg";
import ErrorMessage from "../../atoms/ErrorMessage/ErrorMessage";
import Alert from "../../molecules/Alerts/Alert";
import {SUCCESS} from "../../molecules/Alerts/types";
import LinkedLabel from "../../atoms/LinkedLabel/LinkedLabel";
import {activateUser} from "../../../features/auth/auth.actions";
import {useTranslation} from "react-i18next";

const ActivateUserPage = () => {
    const {activationToken} = useParams();
    const { t } = useTranslation();
    const { activateUserSuccess, activateUserError } = useSelector(authSelector)
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(activateUser({activationToken}));
    },[])

    return(
        <SimplePage>
            <div className='flex max-w-screen-2xl w-full h-screen justify-center items-center bg-fi-background bg-no-repeat bg-left-bottom bg-50% bg-scroll'>
                <div className="flex flex-grow justify-center">
                    <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md flex flex-col">
                        <img src={confirmoLogo} alt='Confirmo' className='mb-10 h-12'/>
                        <div className='my-2'>
                            {activateUserError ?
                                <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                                    <ErrorMessage>{t('activateUserPage.notValidLink')}</ErrorMessage>
                                    <div className='flex justify-center items-center pt-8'>
                                        <LinkedLabel link={'/login'}>{t('cancel')}</LinkedLabel>
                                    </div>
                                </div>
                                : (activateUserSuccess) ?
                                    <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                                        <Alert type={SUCCESS} message={t('activateUserPage.confirmMessage')} />
                                        <div className='flex justify-center items-center pt-8'>
                                            <LinkedLabel link={'/login'}>{t('proceed')}</LinkedLabel>
                                        </div>
                                    </div>
                                : <></>}
                        </div>
                    </div>
                </div>
            </div>
        </SimplePage>
    )
}
export default ActivateUserPage;
