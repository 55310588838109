import ModalGrayFooter from "../Templates/ModalGrayFooter";
import {Dialog} from "@headlessui/react";
import Select from "../../../atoms/Select/Select";
import React, {useEffect, useState} from "react";
import {ADMIN, OWNER, SIMPLE} from "../../../../features/userHome/ROLES";
import Input from "../../../atoms/Inputs/Input/Input";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {userHomeSelector} from "../../../../features/userHome/userHome.slice";

const ROLES = [{label: OWNER, value: OWNER},{label: ADMIN, value: ADMIN},{label: SIMPLE, value: SIMPLE}]

const ModalInviteMember = ({open, setOpen, onConfirm}) => {
    const {t} = useTranslation()
    const [email, setEmail] = useState('')
    const [selectedRole, setSelectedRole] = useState('')
    const confirm = () => {
        onConfirm(email, selectedRole)
        setOpen(false)
    }

    const { selectedTenantId, userOrganizations } = useSelector(userHomeSelector);
    const tenantRoles = userOrganizations?.[selectedTenantId]?.roles ?? ROLES

    useEffect(() => {
        setEmail('')
        setSelectedRole('')
    },[open])
    return(
        <ModalGrayFooter open={open} setOpen={setOpen} onConfirm={confirm} confirmLabel={t('manageusers.invite')} >
            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full ">
                <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                    {t('manageusers.inputEmail')}
                </Dialog.Title>
                <div className="mt-2">
                    <Input value={email} setValue={setEmail} label={t('email')}/>
                    <Select label={t('role')} options={tenantRoles} value={selectedRole} setValue={setSelectedRole} placeholder={t('manageusers.selectRole')}/>
                </div>
            </div>
        </ModalGrayFooter>
    )
}

export default ModalInviteMember;
