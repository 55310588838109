import {Dialog} from "@headlessui/react";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import ModalGrayFooter from "../Templates/ModalGrayFooter";
import Input from "../../../atoms/Inputs/Input/Input";
import FileUploadArea from "../../FileUploadArea/FileUploadArea";
import {useDispatch, useSelector} from "react-redux";
import {checkUploadEventFlowTypeThunk, uploadEventFlowTypeThunk} from "../../../../features/library/library.action";

const ModalGenerateProcess = ({open, setOpen, onSave, isSubmitting}) => {
    const {t} = useTranslation()

    const [uploadFile, setUploadFile] = useState(null)
    const dispatch = useDispatch();

    useEffect(() => {
        if (open) {
            setUploadFile(null);
        }
    },[open])

    const handleChangeFile = async (file) => {
        setUploadFile(file[0])
    }

    const onConfirm = () => {onSave(uploadFile)}

    return (
        <ModalGrayFooter open={open} setOpen={setOpen} onConfirm={onConfirm} confirmLabel={t('save')} saveDisabled={!uploadFile || isSubmitting}>
            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full ">
                <Dialog.Title as="h1" className="text-xl leading-6 font-medium mb-4">
                    {t('Genera processo con AI')}
                </Dialog.Title>
                {!uploadFile ?
                    <div className='space-y-4'>
                        <FileUploadArea onUpload={handleChangeFile} accept={{'application/zip': ['.pdf']}} />
                    </div> :
                    <div className='space-y-4'>
                        File caricato con successo
                    </div>}
            </div>
        </ModalGrayFooter>
    )
}

export default ModalGenerateProcess;
