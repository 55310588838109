import client from "./client";

export const listPeople = async (props) => {
    var url = '/api/people/?';
    if (props.pagination && props.pagination.offset) {
        url += 'offset=' + props.pagination.offset;
    } else {
        url += 'offset=0';
    }
    if (props.pagination && props.pagination.limit) {
        url += '&limit=' + props.pagination.limit;
    }
    if (props.txtFilter) {
        url += '&filter=' + props.txtFilter;
    }
    if (props.role) {
        url += '&role=' + props.role;
    }

    //TODO qui leggo i filtri impostati
    const advancedFilters = localStorage.getItem('usersAdvancedFilters');
    if (advancedFilters) {
        url += (url.indexOf('?') > 0 ? '&' : '?') + 'advancedFilters=' + advancedFilters;
    }

    return await client.get(url);
}

export const countListPeople = async (props) => {
    var url = '/api/people/count';
    if (props.txtFilter) {
        url += '?filter=' + props.txtFilter;
    }
    if (props.role) {
        url += ((props.txtFilter) ? '&' : '?') + 'role=' + props.role;
    }

    //TODO qui leggo i filtri impostati
    const advancedFilters = localStorage.getItem('usersAdvancedFilters');
    if (advancedFilters) {
        url += (url.indexOf('?') > 0 ? '&' : '?') + 'advancedFilters=' + advancedFilters;
    }

    return await client.get(url);
}

export const findPeopleById = async (props) => {
    var url = '/api/people/' + props.id;
    return await client.get(url);
}

export const getPeopleByListId = async (id, filter, offset, limit) => {
    const url = `/api/people/lists/${id}/users`;
    return await client.get(url, {params: {filter, offset, limit}});
}

export const doUpdatePersonData = async (props) => {
    return await client.post('/api/people/save', props);
}


export const listPersonActivity = async (props) => {
    var url = '/api/people/' + props.id + '/audit?';
    if (props.pagination && props.pagination.offset) {
        url += 'offset=' + props.pagination.offset;
    } else {
        url += 'offset=0';
    }
    if (props.pagination && props.pagination.limit) {
        url += '&limit=' + props.pagination.limit;
    }
    if (props.eventId) {
        url += '&eventId=' + props.eventId;
    }
    return await client.get(url);
}

export const countListPersonActivity = async (props) => {
    var url = '/api/people/' + props.id + '/audit/count';
    return await client.get(url);
}

export const listPersonEvents = async ({confirmoIdentityId, offset, limit, txtFilter, id}) => {
    return await client.get(`/api/people/${confirmoIdentityId}/events?${txtFilter ? `filter=${txtFilter}&` : ''}${offset ? `offset=${offset}&` : ''}${limit ? `limit=${limit}&` : ''}${id ? `id=${id}` : ''}`);
}

export const deletePerson = async (props) => {
    var url = '/api/people/delete/' + props.id;
    return await client.delete(url);
}

export const getGraphIdentificationCall = async (id) => {
    return await client.get(`/api/people/${id}/graph-identifications`);
}

export const getSubmittedFormByEventIdCall = async (eventId) => {
    return await client.get(`/api/people/eventsForms/${eventId}`);
}

export const getUserlists = async (filter, offset, limit) => {
    return await client.get(`/api/people/lists`, {params: {filter, offset, limit}})
}

export const createNewUserList = async (name) => {
    return await client.put(`/api/people/lists`, {name});
}

export const deleteUserList = async (id) => {
    return await client.delete(`/api/people/lists/${id}`);
}

export const addUsersToList = async (listId, userIds) => {
    return await client.post(`/api/people/lists/addUsers`, {listId, userIds});
}

export const removeUsersFromList = async (listId, userIds) => {
    return await client.post(`/api/people/lists/removeUsers`, {listId, userIds});
}
