import {v4} from "uuid";
import {classNames} from "../../../../utils/helper";
import {useTranslation} from "react-i18next";
import {useState} from "react";
import {DATE} from "../../../atoms/SimpleTableElement/SimpleTable.types";

const TableWithCheckbox = ({data=[], setSelected, count, offset, setOffset, limit, fields=[]}) => {
    const {t}=useTranslation();
    const [wait, setWait] = useState(false)

    const previousPage = () => {
        if(offset-limit >= 0) setOffset(offset-limit)
    }
    const nextPage = () => {
        if(offset+limit < count) setOffset(offset+limit)
    }

    const onClick = async (d) => {
        if(!wait) {
            setWait(true)
            await setSelected(d)
            setWait(false)
        }
    }

    return (
        <div className="flex flex-col">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <table className="min-w-full">
                        <thead>
                        <tr>
                            {fields.map(field =>
                                <th key={field.value}
                                    scope="col"
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                    {field.label}
                                </th>
                            )}
                        </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200 overflow-y-scroll">
                        {data.map((d) => {
                            return(
                                <tr key={v4()} onClick={() => onClick(d)} className='cursor-pointer hover:bg-gray-100 transition duration-100'>
                                    {fields.map((field,idx) => <td key={field.value} className={classNames(idx===0 && 'font-medium', "px-6 py-4 whitespace-nowrap text-sm text-gray-900")}>
                                        {d?.[field.value] ? (field.type === DATE ? new Date(d?.[field.value])?.toLocaleDateString('en-GB'): d?.[field.value]) : ''}</td>
                                    )}
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                    <div>
                        <nav
                            className="bg-white py-3 flex items-center justify-between border-t border-gray-200"
                            aria-label="Pagination"
                        >
                            <div className="hidden sm:block">
                                <p className="text-sm text-gray-700">
                                    {t("pagination.showing")} <span className="font-medium">{offset +1}</span> {t("pagination.to")} <span className="font-medium">{offset + limit <= count ? offset + limit : count}</span> {t("pagination.of")}{' '}
                                    <span className="font-medium">{count}</span> {t("pagination.results")}
                                </p>
                            </div>
                            <div className="flex-1 flex justify-between sm:justify-end">
                                    <span
                                        onClick={previousPage}
                                        className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 cursor-pointer"
                                    >
                                        {t('previous')}
                                    </span>
                                <span
                                    onClick={nextPage}
                                    className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 cursor-pointer"
                                >
                                        {t('next')}
                                    </span>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TableWithCheckbox;
