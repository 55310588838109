import React, {useMemo, useState} from "react";

import PageWithStepsHeaderAndFooter from "../Templates/PageWithStepsHeaderAndFooter/PageWithStepsHeaderAndFooter";
import FormSelectionActors from "../../organisms/FormSelectionActors/FormSelectionActors";

const SelectRecipients = ({process = [], isConcatenation = false, setRecipients, setSingleRecipient, next, previous, goBackHome, signatureTypesList,
                              steps, addRecipient, deleteRecipient, isEditable = false, loadingRecipients = false, graphTemplatesOptions = [], onClickCopy, buildUrl = () => { }
                          }) => {
    // Stato per gestire se i destinatari sono ordinati
    const [isOrdered, setIsOrdered] = useState(false);

    // Stato per tenere traccia dell'ID del processo attualmente selezionato
    const [selectedProcess, setSelectedProcess] = useState(process[0]?.id);

    // Calcola se ogni processo ha tutti i destinatari richiesti utilizzando useMemo per evitare ricalcoli inutili
    const filledRecipients = useMemo(() => {
        let filled = {};
        process.forEach((p) => {
            filled[p.id] = p.recipients?.every(
                (rec) =>
                    rec?.signatureType &&
                    ((!rec.isList && ((rec?.userid && (rec?.email || rec?.signatureType === 'GRAPH')) || (rec?.givenName && rec?.familyName && (rec?.email || rec?.signatureType === 'GRAPH')))) ||
                        (rec.isList && rec?.listId))
            );
        });
        return filled;
    }, [process]);

    // Determina se il pulsante "Avanti" dovrebbe essere disabilitato in base al fatto che ci siano destinatari incompleti per il processo selezionato
    const disableConfirm = useMemo(
        () => filledRecipients[selectedProcess] === false,
        [filledRecipients, selectedProcess]
    );

    // Gestisci l'evento di clic sul pulsante "Avanti"
    const onClickNext = () => {
        try {
            // Trova l'indice del processo selezionato nell'array dei processi
            const selectedProcessIndex = process.findIndex((p) => p.id === selectedProcess);

            // Verifica se tutti i destinatari sono completati per tutti i processi e se il processo selezionato è l'ultimo
            const isAllCompletedAndLastProcess =
                Object.values(filledRecipients).every((rec) => rec === true) && selectedProcessIndex === process.length - 1;

            if (isAllCompletedAndLastProcess || !isConcatenation) {
                // Se tutti i destinatari sono completati per tutti i processi o la concatenazione non è richiesta, passa al passo successivo
                next(isOrdered);
            } else {
                if (selectedProcessIndex < process.length - 1) {
                    // Se ci sono altri processi, passa al processo successivo
                    setSelectedProcess(process[selectedProcessIndex + 1]?.id);
                } else {
                    // Se non ci sono altri processi, trova il primo processo incompleto e selezionalo
                    const firstIncompleteProcessId = Object.keys(filledRecipients).find(
                        (id) => filledRecipients[id] === false
                    );
                    if (firstIncompleteProcessId) {
                        setSelectedProcess(Number(firstIncompleteProcessId));
                    } else {
                        // Se non viene trovato alcun processo incompleto, potrebbe esserci un problema, quindi registra un errore
                        throw new Error("Nessun processo incompleto trovato.");
                    }
                }
            }
        } catch (e) {
            // Gestisci eventuali errori che possono verificarsi durante il clic sul pulsante "Avanti"
            console.log(e);
        }
    };

    const onClickPrevious = () => {
        try {
            // Trova l'indice del processo selezionato nell'array dei processi
            const selectedProcessIndex = process.findIndex((p) => p.id === selectedProcess);

            if (selectedProcessIndex > 0) // Se non è il primo processo, torna al processo precedente
                setSelectedProcess(process[selectedProcessIndex - 1]?.id);

            else // Se è il primo processo torna alla schermata precedente
                previous();

        } catch (e) {
            console.log(e);
        }
    };


    return (
        <PageWithStepsHeaderAndFooter
            background={false}
            previous={onClickPrevious}
            next={onClickNext}
            closeAction={goBackHome}
            disableConfirm={disableConfirm}
            steps={steps}
            buildUrl={buildUrl}
        >
            <div className="flex flex-col max-w-4xl w-full h-full justify-center items-start">
                {/* Renderizza il componente FormSelectionActors */}
                <FormSelectionActors
                    signatureTypesList={signatureTypesList}
                    setRecipients={setRecipients}
                    setSingleRecipient={setSingleRecipient}
                    isEditable={isEditable}
                    addRecipient={addRecipient}
                    deleteRecipient={deleteRecipient}
                    isOrdered={isOrdered}
                    setIsOrdered={setIsOrdered}
                    loadingRecipients={loadingRecipients}
                    graphTemplatesOptions={graphTemplatesOptions}
                    process={process}
                    isConcatenation={isConcatenation}
                    onClickCopy={onClickCopy}
                    selectedProcess={selectedProcess}
                    setSelectedProcess={setSelectedProcess}
                    filledRecipients={filledRecipients}
                />
            </div>
        </PageWithStepsHeaderAndFooter>
    );
};

export default SelectRecipients;
