import Title from "../../atoms/Title/Title";
import {useTranslation} from "react-i18next";
import SingleCheckbox from "../../atoms/SingleCheckbox/SingleCheckbox";
import InputRecipient from "../../molecules/InputRecipient/InputRecipient/InputRecipient";
import Button from "../../atoms/Buttons/Button/Button";
import {FULL} from "../../atoms/Buttons/sizes";
import React, {useEffect, useMemo, useState} from "react";
import {DragDropContext, Droppable} from "react-beautiful-dnd";
import {reorderList} from "../../../utils/beautiful-dnd-helper";
import emptyStateIllustration from "../../../assets/illustrations/23_Illustrazione_Confirmo_Selezione-Destinatari.svg";
import errorIllustration from "../../../assets/illustrations/1_Illustrazione_Confirmo_Errore.svg";
import isEmpty from "lodash/isEmpty";
import LoadingSpinner from "../../atoms/LoadingSpinner/LoadingSpinner";
import ModalSetRecipientsOrder from "../../molecules/Modals/ModalSetRecipientsOrder/ModalSetRecipientsOrder";
import TabSwitcher from "../../atoms/TabSwitcher/TabSwitcher";
import {useSelector} from "react-redux";
import {authSelector} from "../../../features/auth/auth.slice";
import {userHomeSelector} from "../../../features/userHome/userHome.slice";

const FormSelectionActors = ({process, isConcatenation=false, setRecipients = ()=>{}, setSingleRecipient, addRecipient, deleteRecipient, signatureTypesList,
                                 isEditable = false, isOrdered, setIsOrdered, loadingRecipients = false, graphTemplatesOptions = [], onClickCopy, selectedProcess, setSelectedProcess, filledRecipients}) => {
    const {t} = useTranslation();
    const [open, setOpen] = useState(false)
    const {user} = useSelector(authSelector)
    const {userOrganizations, selectedTenantId} = useSelector(userHomeSelector)
    const selectedOrganization = userOrganizations?.[selectedTenantId]

    const senderData = {givenName: user?.givenName, familyName: user?.familyName, email: user?.email, id: selectedOrganization?.remoteUserId}

    const processShown = useMemo(() => {
        return process.find(p => p.id === selectedProcess)
    },[selectedProcess, process])

    useEffect(() => {
        if(!isConcatenation && isEmpty(process[0]?.recipients) && isEditable) {
            setOpen(true)
            newRecipient()
        }
    },[])

    const onDragEnd = (result) => {
        const orderedRecipientsList = reorderList(result, process[0]?.recipients.sort((item1, item2) => item1.progressNumber - item2.progressNumber))
        if (!result.destination || result.destination.index === result.source.index) {
            return;
        }
        if(orderedRecipientsList)
            setRecipients(orderedRecipientsList.map((rec,idx) => {
                return {...rec, progressNumber: idx+1}
            }))
    }

    const newRecipient = () => {
        addRecipient?.()
    }
    const AddRecipientButton = () => isEditable ?
        <Button onClick={newRecipient} label={t('recipients.addRecipient')} variant='blue' size={FULL}/> :
        <></>

    return(
        <div className='flex flex-col items-center flex-grow w-full h-full'>
            <div className='border-b w-full m-2'>
                <div className='flex justify-between items-center w-full'>
                    <Title>{t('recipients.title')}</Title>
                    {isEditable && <div className='flex items-center'>
                        <div className='m-2'>
                            <SingleCheckbox label={t('recipients.selectOrder')} value={isOrdered} setValue={setIsOrdered}/>
                        </div>
                        <div className='m-2'>
                            <AddRecipientButton />
                        </div>
                    </div>}
                </div>
                <div className='flex w-full space-x-1 pb-1 overflow-x-auto'>
                    {isConcatenation && process.map((p) => (<TabSwitcher label={p.name} selected={selectedProcess === p.id} onClick={() => setSelectedProcess(p.id)} checked={filledRecipients[p.id]}/>))}
                </div>
            </div>

            {loadingRecipients ?
                <div className='flex flex-col items-center justify-center h-full w-full p-4'>
                    <LoadingSpinner />
                </div>:
                !isEmpty(process[0]?.recipients) ?
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="list">
                            {provided => (
                                <div ref={provided.innerRef} {...provided.droppableProps} className='flex w-full flex-col items-center'>
                                    {<div key={processShown.id} className='flex flex-col w-full divide-y pb-4 pt-2'>
                                            {isConcatenation && <Title>{processShown.name}</Title>}
                                            {processShown.recipients
                                                .slice()
                                                .sort((item1, item2) => item1?.progressNumber - item2?.progressNumber)
                                                .map((rec, recIdx) => (
                                                        <InputRecipient
                                                            key={rec.id}
                                                            recipient={rec}
                                                            position={recIdx}
                                                            setRecipient={(rec) => setSingleRecipient(rec, processShown)}
                                                            isEditable={isEditable}
                                                            deleteRecipient={(rec) => deleteRecipient(rec, processShown)}
                                                            signatureTypesList={signatureTypesList}
                                                            isOrdered={isOrdered}
                                                            graphTemplatesOptions={graphTemplatesOptions}
                                                            onClickCopy={() => onClickCopy(rec, recIdx)}
                                                            isConcatenation={isConcatenation}
                                                            senderData={senderData}
                                                        />
                                                    )
                                                )}
                                        </div>
                                    }
                                    {provided.placeholder}
                                </div>)}
                        </Droppable>
                    </DragDropContext> :
                    <div className='flex flex-col items-center h-full w-full p-4'>
                        {isEditable ?
                            <>
                                <img src={emptyStateIllustration} alt='Empty State' className='max-w-xl w-full'/>
                                <h1 className='text-2xl leading-8 font-mediumtext-center '>{t('recipients.noRecipientsMessage')}</h1>
                                <div className='py-4'>
                                    <AddRecipientButton/>
                                </div>
                            </> :
                            <>
                                <img src={errorIllustration} alt='Empty State' className='max-w-xl w-full'/>
                                <h1 className='text-2xl leading-8 font-mediumtext-center '>{t('errorOccurred')}</h1>
                            </>
                        }
                    </div>
            }
            <ModalSetRecipientsOrder open={open} setOpen={setOpen} setIsOrdered={setIsOrdered} />
        </div>
    )
}

export default FormSelectionActors;
