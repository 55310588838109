import {Link} from "react-router-dom";

const LinkedLabel = ({children, link}) => {
    return(
        <p>
            <Link to={link} className="font-medium text-blue-600 hover:text-blue-500">
                {children}
            </Link>
        </p>
    )
}

export default LinkedLabel;
