import ModalGrayFooter from "../Templates/ModalGrayFooter";
import {useTranslation} from "react-i18next";

const ModalResetPlaceholders = ({open, onClose, onConfirm}) => {
    const {t} = useTranslation()
    return(
        <ModalGrayFooter open={open} setOpen={onClose} onConfirm={onConfirm} confirmLabel={t('delete')} cancelLabel={t('keep')} >
            {t('deletePlaceholders')}
        </ModalGrayFooter>
    )
}

export default ModalResetPlaceholders
