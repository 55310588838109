
import ProfileDropdown from "../ProfileDropdown/ProfileDropdown";
import {BellIcon} from "@heroicons/react/24/outline";
import {XMarkIcon} from "@heroicons/react/24/solid";

const HeaderWithTitle = ({title, closeAction}) => {

    return (
        <div className='flex justify-center shadow bg-white'>
            <div className="mx-auto px-4 sm:px-6 lg:px-8 max-w-screen-2xl w-full">
                <div className="flex justify-between items-center h-16">
                    <XMarkIcon data-testid='exit-icon' className="block h-6 w-6 cursor-pointer" aria-hidden="true" onClick={closeAction}/>
                    <div className="font-bold">{title}</div>
                    <div className="flex items-center">

                        <div className="flex-shrink-0 flex items-center">
                            <button className="bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                                <span className="sr-only">View notifications</span>
                                <BellIcon className="h-6 w-6" aria-hidden="true" />
                            </button>

                            <ProfileDropdown />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default HeaderWithTitle;
