import client from "./client";

export const createNewDocumentCall = async(name, description, languages, tags, isDocument) => {
    return await client.post(`/api/doc-api/event-flow-types`, {name, description, languages, tags, isDocument})
}

export const createConcatenatedProcess = async(eventFlowTypeIds) => {
    return await client.get(`/api/doc-api/concatenated-event-flow`, {params: {eventFlowTypeIds}})
}

export const startConcatenation = async(events, messageBody, messageSubject, reminderIntervalDays, expirationDate, selectedDevice, selectedWorkspaces) => {
    return await client.post(`/api/doc-api/startConcatenation`,
        {events, messageBody, messageSubject, reminderIntervalDays, expirationDate, selectedDevice, selectedWorkspaces})
}

export const updateProcessCall = async(processId, name, description, languages, published, tags) => {
    return await client.put(`/api/doc-api/event-flow/${processId}`, {name, description, languages, published, tags})
}

export const updateModelAsFavouriteCall = async(processId, isFavourite) => {
    return await client.post(`/api/doc-api/event-flow-types/${processId}/favourite/${isFavourite}`, {})
}

export const createInformativaCall = async(data) => {
    return await client.post(`/api/doc-api/informed-path/create`, data)
}

export const updateInformativaCall = async(informativaId, data) => {
    return await client.put(`/api/doc-api/informed-path/${informativaId}/update`, data)
}

export const getInformativaStepsTypesCall = async() => {
    return await client.get(`/api/doc-api/informed-path/step-types`)
}

export const getInformativaDetailsCall = async(id) => {
    return await client.get(`/api/doc-api/informed-path/${id}`)
}

export const addStepInformativaCall = async(id, data) => {
    return await client.post(`/api/doc-api/informed-path/${id}/add-detail`,data)
}

export const updateStepInformativaCall = async(id, data) => {
    return await client.put(`/api/doc-api/informed-path/step-detail/${id}`,data)
}

export const uploadStepPhotoCall = async (stepid, image) => {
    let data = new FormData();
    data.append('file', image);
    data.append('authorization', 'Bearer ' + sessionStorage.getItem('token'));
    try {
        return await client.post(`/api/doc-api/informed-path/step-content-upload/${stepid}`,
            data, {
                headers: {
                    'processData': false,
                }
            });
    }catch(e) {
        console.log('Error on Photo: ', e);
    }
}

export const deleteStepInformativaCall = async(id) => {
    return await client.delete(`/api/doc-api/informed-path/delete-detail/${id}`)
}

export const reorderStepInformativaCall = async(informativaId, orderedListIdSteps) => {
    return await client.post(`/api/doc-api/informed-path/reorder-step/${informativaId}`,orderedListIdSteps)
}

export const deleteInformativaCall = async(informativaId) => {
    return await client.delete(`/api/doc-api/informed-path/${informativaId}`)
}

export const getInformativeCall = async(processId) => {
    return await client.get(`/api/doc-api/event-flow/${processId}/informed-paths/`)
}

export const getDataCollectionsCall = async(processId) => {
    return await client.get(`/api/doc-api/event-flow/${processId}/forms/`)
}

export const createDataCollectFormCall = async(data) => {
    return await client.post(`/api/doc-api/form`, data)
}

export const getDataCollectFormDetailsCall = async(idDataCollect) => {
    return await client.get(`/api/doc-api/form/${idDataCollect}`)
}

export const updateDataCollectFormCall = async(idDataCollect, data) => {
    return await client.put(`/api/doc-api/form/${idDataCollect}`,data)
}

export const addFieldDataCollectFormCall = async(idDataCollect, data) => {
    return await client.post(`/api/doc-api/form/${idDataCollect}/add-field`,data)
}

export const updateFieldDataCollectFormCall = async(idDataCollect, data) => {
    return await client.put(`/api/doc-api/form/${idDataCollect}/update-field`,data)
}

export const deleteFieldDataCollectFormCall = async(idDataCollect, data) => {
    return await client.post(`/api/doc-api/form/${idDataCollect}/delete-field`,data)
}

export const reorderFieldsDataCollectFormCall = async(idDataCollect, data) => {
    return await client.post(`/api/doc-api/form/${idDataCollect}/reorder-fields`,data)
}

export const massiveSetDataCollectionRuleCall = async(idDataCollect, data) => {
    return await client.post(`/api/doc-api/form/${idDataCollect}/set-rules`, data)
}

export const getDocumentalProcessItemsCall = async(processId, recipientId) => {
    return await client.get(`/api/doc-api/event-flow/${processId}/actor-path/${recipientId}`)
}

export const reorderDocumentalProcessItemsCall = async(processId, recipientId, data) => {
    return await client.post(`/api/doc-api/event-flow/${processId}/actor-path/${recipientId}/reorder`, data)
}

export const getModelsDetailsCall = async(processId) => {
    return await client.get(`/api/doc-api/event-flow/${processId}`)
}

export const reorderSubmodelsCall = async(processId, submodelIds) => {
    return await client.post(`/api/doc-api/doc-model/reorder-submodels/${processId}`, submodelIds)
}

export const deleteSubmodelCall = async(processId, submodelId) => {
    return await client.post(`/api/doc-api/doc-model/delete-submodel/${processId}/${submodelId}`)
}

export const setModelSendModeCall = async(processId, sendMode) => {
    return await client.post(`/api/doc-api/doc-model/set-submodels-send-mode/${processId}/${sendMode}`)
}

export const addPdfInformedPathToDocumentThunkCall = async(processId, detailId) => {
    return await client.post(`/api/doc-api/doc-model/add-pdf-informed-path/${processId}/${detailId}`)
}



export const saveModelsCall = async(processId, data) => {
    return await client.post(`/api/doc-api/event-flow/${processId}/save-as-models`, data)
}

export const sendProcessCall = async(processId, data) => {
    return await client.post(`/api/doc-api/event-flow/${processId}/start`, data)
}

export const downloadPdfToDisplay = async (url) => {
    return await client.get(url, {
        responseType: 'arraybuffer',
    })
}
