import {Disclosure} from "@headlessui/react";
import {ChevronUpIcon} from "@heroicons/react/24/solid";

const DisclosureItem = ({head, children}) => {
    return (
        <Disclosure>
            {({ open }) => (
                <>
                    <Disclosure.Button className="flex justify-between items-center w-full py-4 text-sm font-medium text-left">
                        <div>{head}</div>
                        <ChevronUpIcon
                            className={`${
                                !open ? 'transform rotate-180' : ''
                            } w-5 h-5`}
                        />
                    </Disclosure.Button>
                    {!open && <Disclosure.Panel static className="pb-4">
                        {children}
                    </Disclosure.Panel>}
                </>
            )}
        </Disclosure>
    )
}

export default DisclosureItem;
