import PublicRoute from "./PublicRoute";
import Login from "../components/pages/Login/LoginPage";
import Registration from "../components/pages/Registration/RegistrationPage";
import ActivateUser from "../components/pages/ActivateUser/ActivateUserPage";
import ForgotPassword from "../components/pages/ForgotPassword/ForgotPasswordPage";
import ChangePassword from "../components/pages/ChangePassword/ChangePasswordPage";
import ResetPassword from "../components/pages/ResetPassword/ResetPasswordPage";
import PrivateRoute from "./PrivateRoute";
import Homepage from "../components/pages/Homepage/Homepage";
import DocumentPage from "../components/pages/DocumentPage/DocumentPage";
import NewProcessTemplate from "../components/pages/NewProcessTemplate/NewProcessTemplate";
import NewDocument from "../components/pages/NewDocument/NewDocument";
import SendProcess from "../components/pages/SendProcess/sendProcess";
import SelfSignDocument from "../components/pages/SelfSignDocument/SelfSignDocument";
import ManagePage from "../components/pages/ManagePage/ManagePage";
import SettingsPage from "../components/pages/Settings/SettingsPage";
import LibraryPage from "../components/pages/Library/LibraryPage";
import InformativaDetail from "../components/pages/Library/Sections/Informative/InformativaDetail/InformativaDetail";
import FormDetail from "../components/pages/Library/Sections/Forms/FormDetail/FormDetail";
import UsersPage from "../components/pages/UsersPage/UsersPage";
import NotificationsPage from "../components/pages/NotificationsPage/NotificationsPage";
import {Navigate, Route, Routes} from "react-router-dom";
import PageNotFound from "../components/pages/PageNotFound/PageNotFound";
import Invitations from "../components/pages/Invitations/Invitations";
import GraphConsentBuilder from "../components/pages/Settings/Sections/GraphConsentsList/GraphConsentBuilder";
import ArchievePage from "../components/pages/ArchievePage/ArchievePage";
import SendDraftDispatcher from "../components/pages/SendDraftDispatcher/SendDraftDispatcher";
import NewDraftDocument from "../components/pages/NewDraftDocument/NewDraftDocument";
import NewConcatenatedProcesses from "../components/pages/NewConcatenatedProcesses/NewConcatenatedProcesses";
import ManagePageExternal from "../components/pages/ManagePage/ManagePageExternal";

const RouterConfig = () => {
    return(
        <Routes>
            <Route path="/login" element={<PublicRoute><Login /></PublicRoute>} />
            <Route path="/registration" element={<PublicRoute><Registration /></PublicRoute>} />
            <Route path="/activateuser/:activationToken?" element={<PublicRoute><ActivateUser /></PublicRoute>} />
            <Route path="/forgotpassword" element={<PublicRoute><ForgotPassword /></PublicRoute>} />
            <Route path="/changepassword" element={<PrivateRoute><ChangePassword /></PrivateRoute>} />
            <Route exact path="/resetpassword/:resetPwdToken?" element={<PublicRoute><ResetPassword /></PublicRoute>} />
            <Route exact path="/home" element={<PrivateRoute><Homepage /></PrivateRoute>} />
            <Route exact path="/document/:section?" element={<PrivateRoute><DocumentPage /></PrivateRoute>} />
            <Route exact path="/newprocess/:step?/:id?/:elementId?" element={<PrivateRoute><NewProcessTemplate /></PrivateRoute>} />
            <Route exact path="/senddocument/:step?" element={<PrivateRoute><NewDocument /></PrivateRoute>} />
            <Route exact path="/sendconcatenatedprocess/:step?" element={<PrivateRoute><NewConcatenatedProcesses /></PrivateRoute>} />
            <Route exact path="/sendprocess/:processId/:step?" element={<PrivateRoute><SendProcess /></PrivateRoute>} />
            <Route exact path="/selfsign/:step?" element={<PrivateRoute><SelfSignDocument /></PrivateRoute>} />
            <Route exact path="/gestisci/:section?/:workspaceId?" element={<PrivateRoute><ManagePage /></PrivateRoute>} />
            <Route exact path="/settings/:section?" element={<PrivateRoute><SettingsPage /></PrivateRoute>} />
            <Route exact path="/libreria/:section?" element={<PrivateRoute><LibraryPage /></PrivateRoute>} />
            <Route exact path="/informativa/:id?" element={<PrivateRoute><InformativaDetail /></PrivateRoute>} />
            <Route exact path="/form/:id?" element={<PrivateRoute><FormDetail /></PrivateRoute>} />
            <Route exact path="/rubrica/:listId?" element={<PrivateRoute><UsersPage /></PrivateRoute>} />
            <Route exact path="/conservazione" element={<PrivateRoute><ArchievePage /></PrivateRoute>} />
            <Route exact path="/notifications" element={<PrivateRoute><NotificationsPage /></PrivateRoute>} />
            <Route exact path="/invitation/:accessLink?" element={<PublicRoute><Invitations /></PublicRoute>} />
            <Route exact path="/senddraftdoc/:draftSessionId?" element={<PublicRoute><SendDraftDispatcher /></PublicRoute>} />
            <Route exact path="/finalizedraft/:step?" element={<PrivateRoute><NewDraftDocument /></PrivateRoute>} />
            <Route exact path="/builder/:id?" element={<PrivateRoute><GraphConsentBuilder /></PrivateRoute>} />
            <Route path="/" element={<Navigate to="/login" />} />
            <Route path='/external/:section/:token?' element={<PublicRoute><ManagePageExternal /></PublicRoute>} />
            <Route path='/external/*' element={<PublicRoute><ManagePageExternal /></PublicRoute>} />
            <Route path="*" element={<PageNotFound />} />
        </Routes>
    )
}

export default RouterConfig;
