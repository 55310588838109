import PageWithStepsHeaderAndFooter from "../../Templates/PageWithStepsHeaderAndFooter/PageWithStepsHeaderAndFooter";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import Title from "../../../atoms/Title/Title";
import ActionButton from "../../../atoms/Buttons/ActionButtons/ActionButton";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
    deleteDocumentThunk,
    getDocumentDetailsThunk,
    getPlaceholdersThunk,
    previewDocumentThunk,
    setPlaceholdersThunk, setSubmodelsPlaceholdersThunk,
    uploadDocumentThunk
} from "../../../../features/documentBuilder/documentBuilder.actions";
import {
    addBlock,
    deleteBlock,
    documentBuilderSelector,
    resetBlocks,
    selectBlock,
    setBlock, updateCurrentSubmodelPlaceholders
} from "../../../../features/documentBuilder/documentBuilder.slice";
import {recipientsSelector} from "../../../../features/recipients/recipients.slice";
import isEmpty from "lodash/isEmpty";
import LoadingSpinner from "../../../atoms/LoadingSpinner/LoadingSpinner";
import DocumentBuilder from "../../DocumentBuilder/DocumentBuilder";
import {useTranslation} from "react-i18next";
import ModalResetPlaceholders from "../../../molecules/Modals/ModalResetPlaceholders/ModalResetPlaceholders";

const DocumentStep = ({next, previous, steps, buildUrl=()=>{}, isProcess=false}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {t} = useTranslation()
    const hiddenFileInput = useRef(null);
    const hiddenTemplateInput = useRef(null);
    const [selectedRecipient, setSelectedRecipient] = useState(null)
    const [selectedBlock, setSelectedBlock] = useState('')
    const [openModalResetPlaceholder, setOpenModalResetPlaceholder] = useState(false)
    const [file, setFile] = useState()
    const [isTemplate, setIsTemplate] = useState(false)
    const [recipientsOptions, setRecipientsOptions] = useState([])
    const [currentDownloadUrl, setCurrentDownloadUrl] = useState()

    const { recipients } = useSelector(recipientsSelector)
    const { documentInfo, fields, blocks, selectedBlockId, waitingFileUpload, submodels, submodelSendMode } = useSelector(documentBuilderSelector)

    const nextPage = useCallback(async () => {
        if (submodels?.length > 0) {
            await dispatch(setSubmodelsPlaceholdersThunk());
        } else {
            await dispatch(setPlaceholdersThunk());
        }
        next();
    }, [dispatch, next]);

    const previousPage = useCallback(async () => {
        if (submodels?.length > 0) {
            await dispatch(setSubmodelsPlaceholdersThunk());
        } else {
            await dispatch(setPlaceholdersThunk());
        }
        previous();
    }, [dispatch, previous]);

    useEffect(() => {
        if((!selectedRecipient && !isEmpty(Object.values(recipients))) || !Object.keys(recipients)?.some(recId => recId === selectedRecipient))
            setSelectedRecipient(Object.values(recipients)?.[0]?.id)
        const options = Object.values(recipients).map(r => {return {label: r.name, value: r.id }})
        if(!isEmpty(fields?.['SENDER'])) options.push({label: t('documentBuilder.sender'), value: 'SENDER' })
        setRecipientsOptions(options)
    },[recipients, fields])

    const recipientsFields = useMemo(() => {
        return fields?.[selectedRecipient];
    }, [selectedRecipient, fields]);

    useEffect(() => {
        if(documentInfo?.downloadUrl && documentInfo.downloadUrl !== currentDownloadUrl && !documentInfo.cached) {
            dispatch(getDocumentDetailsThunk())
            dispatch(getPlaceholdersThunk())
            setCurrentDownloadUrl(documentInfo.downloadUrl);
        }
    },[documentInfo?.downloadUrl])

    useEffect(() => {
        if(!isEmpty(blocks) && (selectedBlockId || selectedBlock))
            setSelectedBlock(selectedBlockId ? blocks.find(block => block.id === selectedBlockId) : null)
    },[selectedBlockId, blocks])

    const handleFileUploadClickButton = (template) => {
        setIsTemplate(template)
        if(template) hiddenTemplateInput.current.click()
        else hiddenFileInput.current.click()
    }

    const handleFileUpload = async (event, isTemplate, isSubmodels) => {
        const file = event.target.files[0];
        dispatch(uploadDocumentThunk({file, isTemplate, isSubmodels}))
    }

    const handleChangeFile = async (event, template, isSubmodels, submodelToUpdateId) => {
        if (!isEmpty(blocks) && (!isSubmodels || (isSubmodels && submodelToUpdateId))) {
            setFile(event.target.files[0])
            setIsTemplate(template)
            setOpenModalResetPlaceholder(true)
        } else {
            dispatch(uploadDocumentThunk({
                file: event.target.files[0],
                isTemplate: template,
                isSubmodels: isSubmodels,
                submodelToUpdateId: submodelToUpdateId
            }))
        }
    }

    const resetPlaceholders = async (reset = false) => {
        if (reset) {
            await dispatch(resetBlocks())
        }
        await dispatch(setPlaceholdersThunk());
        dispatch(uploadDocumentThunk({
            file,
            isTemplate,
            isSubmodels: submodels?.length > 0,
            submodelToUpdateId: (submodels?.length > 0 ? documentInfo?.id : null)
        }))
        setOpenModalResetPlaceholder(false)
    }

    const goBackHome= () => {
        navigate('/home')
    }

    const resetSelectedBlock = () => {
        setSelectedBlock(null)
        dispatch(selectBlock(null))
    }

    const onSetBlock = block => {
        if(block) dispatch(setBlock(block));
    }

    const onAddBlock = block => {
        if(block) dispatch(addBlock([block]));
    }

    const onSelectBlock= (block) => {
        if(block?.id) dispatch(selectBlock(block.id))
    }

    if(waitingFileUpload)
        return(
            <PageWithStepsHeaderAndFooter background={true} footer={false} closeAction={goBackHome} steps={steps} buildUrl={buildUrl}>
                <div className='flex flex-col justify-center max-w-sm'>
                    <LoadingSpinner />
                </div>
            </PageWithStepsHeaderAndFooter>
        )
    else if(!documentInfo?.downloadUrl)
        return(
            <PageWithStepsHeaderAndFooter background={true} footer={false} closeAction={goBackHome} steps={steps} buildUrl={buildUrl}>
                <div className='flex flex-col justify-center max-w-lg'>
                    <div className='flex flex-col items-center pb-16'>
                        <Title>{t('documentBuilder.document')}</Title>
                        <h3 className="text-lg leading-6 text-gray-500 text-justify max-w-xs">{t('documentBuilder.uploadDocumentMessage')}</h3>
                    </div>
                    <div className='flex flex-col'>
                        <input type="file" className='hidden' ref={hiddenFileInput} onChange={(e) => handleFileUpload(e, isTemplate, false)} accept={".docx,.odt,.pdf"}/>
                        <input type="file" className='hidden' ref={hiddenTemplateInput} onChange={(e) => handleFileUpload(e, isTemplate, false)} accept={".docx"}/>
                        <ActionButton title={t('documentBuilder.uploadDocumentTitle')} text={t('documentBuilder.uploadDocumentSubtitle')} premium={true} onClick={() => handleFileUploadClickButton(false)}/>
                        <ActionButton title={t('documentBuilder.uploadTemplateTitle')} text={t('documentBuilder.uploadTemplateSubtitle')} premium={true} onClick={() => handleFileUploadClickButton(true)}/>
                    </div>
                </div>
            </PageWithStepsHeaderAndFooter>
        )
    else
        return(
            <>
                <DocumentBuilder
                    processContextType={isProcess ? 'PROCESS' : 'DOCUMENT'}
                    nextPage={nextPage} goBackHome={goBackHome} previous={previousPage} steps={steps}
                    recipientsOptions={recipientsOptions} selectedBlock={selectedBlock} selectedBlockId={selectedBlockId} resetSelectedBlock={resetSelectedBlock}
                    recipients={recipients} recipientsFields={recipientsFields} fields={fields}
                    documentInfo={documentInfo} submodels={submodels} submodelSendMode={submodelSendMode} selectedRecipient={selectedRecipient} setSelectedRecipient={setSelectedRecipient}
                    deleteBlock={() => {
                        dispatch(deleteBlock(selectedBlock))
                        resetSelectedBlock()
                    }}
                    recipientBlocksColor={recipients?.[selectedRecipient]?.color}
                    handleAddSubmodel={(e) => handleChangeFile(e, false, true, null)}
                    handleAddSubmodelTemplate={(e) => handleChangeFile(e, true, true, null)}
                    handleChangeFile={(e) => handleChangeFile(e, documentInfo?.isTemplate ?? false, submodels?.length > 0 ?? false, (submodels?.length > 0 ? documentInfo?.id : null))}
                    deleteDocumentFile={() => dispatch(deleteDocumentThunk())}
                    previewDocumentFile={() => dispatch(previewDocumentThunk({submodelId: (submodels && submodels.length > 0) ? documentInfo?.id : null}))}
                    onSetBlock={onSetBlock}
                    allBlocks={blocks}
                    onAddBlock={onAddBlock} selectBlock={onSelectBlock}
                    buildUrl={buildUrl}
                />
                <ModalResetPlaceholders open={openModalResetPlaceholder} onClose={() => resetPlaceholders(false)} onConfirm={() => resetPlaceholders(true)}/>
            </>

        )
}

export default DocumentStep;
