import Badge from "../../atoms/Badges/Badge/Badge";
import Icon from "../../atoms/Icon/Icon";
import React from "react";
import SingleCheckbox from "../../atoms/SingleCheckbox/SingleCheckbox";
import {classNames} from "../../../utils/helper";

const Card = ({labels = [], title, text, isFavourite, onClick, setFavourite, isChecked, setIsChecked, concatenationEnabled = false}) => {
    const onClickFavourite = (e, isFavourite) => {
        e.stopPropagation();
        setFavourite(isFavourite)
    }

    return(
        <div className={classNames(isChecked ? 'bg-blue-600' : 'bg-white', 'col-span-1 flex flex-col text-center rounded-lg shadow divide-y divide-gray-200 w-72 m-4 cursor-pointer')} onClick={onClick} data-testid='card'>
            <div className="flex-1 flex flex-col p-6">
                <div className='flex justify-between'>
                    <div className='flex truncate -ml-1'>
                        {concatenationEnabled && <div onClick={(e) => {e.stopPropagation()}}>
                            <SingleCheckbox variant='green' value={isChecked} setValue={setIsChecked}/>
                        </div>}
                        {labels.map(label => <Badge key={label.id} label={label.name} color='blue'/>)}
                    </div>
                    <div className={classNames(isChecked ? 'text-white' : 'text-gray-900', 'flex items-center')}>{(isFavourite) ?
                        <Icon type='star-filled' onClick={(e) => onClickFavourite(e, false)} size='4'/> :
                        <Icon type='star-empty' onClick={(e) => onClickFavourite(e, true)} size='4' /> }
                    </div>
                </div>
                <h3 className={classNames(isChecked ? "text-white": "text-gray-900","mt-4 text-gray-900 text-sm leading-5 font-medium text-justify cursor-pointer")}>{title}</h3>
                <dl className="mt-1 flex-grow flex flex-col justify-between">
                    <dt className="sr-only">text</dt>
                    <dd className="text-gray-500 text-xs leading-4 font-normal text-justify">{text}</dd>
                </dl>
            </div>
        </div>
    )
}
export default Card;
