import DropdownMultiSelect from "../../atoms/Dropdowns/DropdownMultiSelect/DropdownMultiSelect";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {userHomeSelector} from "../../../features/userHome/userHome.slice";
import {getAvailableWorkspacesThunk} from "../../../features/userHome/userHome.actions";
import {
    addEventFlowActivityToWorkspaceThunk,
    removeEventFlowActivityFromWorkspaceThunk
} from "../../../features/library/library.action";
import {useTranslation} from "react-i18next";
import {WORKSPACES} from "../../../utils/permissions";
import isEmpty from "lodash/isEmpty";

const DropdownButtonWorkspaces = ({event}) => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const {availableWorkspaces, selectedTenantId, userOrganizations} = useSelector(userHomeSelector)
    const permissions = userOrganizations?.[selectedTenantId]?.permissions ?? {}

    const getAvailableWorkspaces = () => dispatch(getAvailableWorkspacesThunk())
    const addEventToWorkspace = (eventFlowActivityId, workspaceId) => dispatch(addEventFlowActivityToWorkspaceThunk({workspaceId, eventFlowActivityId}))
    const removeEventFromWorkspace = (eventFlowActivityId, workspaceId) => dispatch(removeEventFlowActivityFromWorkspaceThunk({workspaceId, eventFlowActivityId}))
    const onChangeWorkspace = (workspaceId) => {
        if(event?.eventFlowActivityId) {
            if (event?.workspaces[workspaceId]) removeEventFromWorkspace(event.eventFlowActivityId, workspaceId)
            else addEventToWorkspace(event.eventFlowActivityId, workspaceId)
        }
    }

    useEffect(() => {
        if(permissions?.[WORKSPACES] && isEmpty(availableWorkspaces))
            getAvailableWorkspaces()
    },[])

    return permissions?.[WORKSPACES] && !isEmpty(availableWorkspaces) ?
        <DropdownMultiSelect
            label={t('addToWorkspaces')}
            options={availableWorkspaces}
            selectedOptions={event?.workspaces?? {}}
            setSelectedOptions={onChangeWorkspace}
        /> :
        <></>

}

export default DropdownButtonWorkspaces;
