import ModalOnlyClose from "../Templates/ModalOnlyClose";

const ModalDisplayUsersInList = ({ listName='', users, open, setOpen }) => {
    return(
        <ModalOnlyClose open={open} onClose={() => setOpen(false)} setOpen={setOpen}>
            <div className='flex flex-col'>
                <h1 className='text-lg leading-7 font-medium text-gray-900'>Utenti nella lista {listName}</h1>
                <ul className="list-disc px-5">
                    {users.map(user => <li>{user.contactEmail}</li>)}
                </ul>
            </div>
        </ModalOnlyClose>
    )
}

export default ModalDisplayUsersInList
