import Input from "../../atoms/Inputs/Input/Input";
import Button from "../../atoms/Buttons/Button/Button";
import {XMarkIcon} from "@heroicons/react/24/solid";
import {useEffect, useState} from "react";
import SidebarInformedPathCustomFields from "./SidebarInformedPathCustomFields";
import {useTranslation} from "react-i18next";

const SidebarInformedPath = ({step, updateStep, informativaId, handleChangePhoto, errorVideo, imageLoading: isLoading, onClose, allSteps}) => {
    const [name, setName] = useState(step?.name)
    const [title, setTitle] = useState(step?.title)
    const [textBody, setTextBody] = useState(step?.textBody)
    const [questions, setQuestions] = useState(step?.quiz?.questions ?? [])
    const [wrongAnswerStepId, setWrongAnswerStepId] = useState(step?.quiz?.wrongAnswerStepId ?? null)
    const [videoLink, setVideoLink] = useState(step?.youtubeLink)
    const [iframe, setIframe] = useState(step?.iframe)
    const {t}=useTranslation()

    useEffect(() => {
        if(!step?.typeSymbol) onClose()
        setName(step?.name)
        setTitle(step?.title)
        setTextBody(step?.textBody)
        setQuestions(step?.quiz?.questions ?? [])
        setWrongAnswerStepId(step?.quiz?.wrongAnswerStepId ?? null)
        setVideoLink(step?.youtubeLink)
        setIframe(step?.iframe)
    },[step])

    const onClickSave = () => {
        switch (step?.typeSymbol){
            case 'message':
                updateStep({...step, name, title, textBody}, informativaId)
                break;
            case 'quiz':
                updateStep({...step, name, title, textBody, quiz: {questions, wrongAnswerStepId}}, informativaId)
                break;
            case 'youtube':
                updateStep({...step, name, title, youtubeLink: videoLink}, informativaId)
                break;
            case 'img':
            case 'pdf':
                updateStep({...step, name, title}, informativaId)
                break;
            default:
                break;
        }
    }

    return (
        <div className='flex flex-1 flex-col bg-gray-100 w-full border rounded-lg overflow-y-auto overflow-x-auto mt-0.5 mb-3 overflow-y-hidden' onMouseDown={e => e.stopPropagation()}>
            <div className='flex w-full justify-between py-4 px-6'>
                <div className='flex flex-col'>
                    <p className='text-xs leading-4 font-normal text-gray-600'>{t('properties')}</p>
                    <h1 className='text-base leading-6 font-medium text-gray-900 capitalize'>{step?.typeSymbol}</h1>
                </div>
                <div className='flex items-center'>
                    <XMarkIcon className='h-6 w-6 text-black cursor-pointer' onClick={onClose} />
                </div>
            </div>
            <div className='flex flex-col flex-1 px-6 py-4 bg-gray-50 space-y-4 overflow-y-auto'>
                <Input label={t('name')} value={name} setValue={setName} />
                <Input label={t('title')} value={title} setValue={setTitle} />
                <SidebarInformedPathCustomFields
                    handleChangePhoto={(e) => handleChangePhoto(e, step.id, informativaId)}
                    step={step}
                    type={step?.typeSymbol}
                    textBody={textBody}
                    setTextBody={setTextBody}
                    questions={questions}
                    setQuestions={setQuestions}
                    contentUrl={step?.contentUrl ?? null}
                    errorVideo={errorVideo}
                    videoLink={videoLink}
                    setVideoLink={setVideoLink}
                    iframe={iframe}
                    isLoading={isLoading}
                    wrongAnswerStepId={wrongAnswerStepId}
                    setWrongAnswerStepId={setWrongAnswerStepId}
                    allSteps={allSteps}
                />
            </div>
            <div className='flex items-center justify-end px-6 py-4'>
                <Button label={t('save')} size='lg' variant='blue' onClick={onClickSave} />
            </div>
        </div>
    )
}

export default SidebarInformedPath;
