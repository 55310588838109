import {XMarkIcon} from "@heroicons/react/24/solid";
import Button from "../../atoms/Buttons/Button/Button";

const Sidebar = ({children, onSave, onClose, title, subtitle}) => {
    return(
        <div className='flex flex-col bg-gray-100 w-full h-full border rounded-lg overflow-y-auto overflow-x-auto my-2' onMouseDown={e => e.stopPropagation()}>
            <div className='flex w-full justify-between py-4 px-6'>
                <div className='flex flex-col'>
                    <p className='text-xs leading-4 font-normal text-gray-600'>{subtitle}</p>
                    <h1 className='text-base leading-6 font-medium text-gray-900 capitalize'>{title}</h1>
                </div>
                <div className='flex items-center'>
                    <XMarkIcon className='h-6 w-6 text-black cursor-pointer' onClick={onClose} />
                </div>
            </div>
            <div className='px-6 flex-1 py-4 bg-gray-50 space-y-4'>
                {children}
            </div>
            {onSave && <div className='flex items-center justify-end px-6 py-4'>
                    <Button label='Salva' size='lg' variant='blue' onClick={onSave}/>
                </div>}
        </div>
    )

}

export default Sidebar;
