import {ChevronDownIcon} from "@heroicons/react/24/solid";
import {Menu, Transition} from '@headlessui/react'
import React, {Fragment, useEffect, useState} from "react";
import {classNames} from "../../../../utils/helper";
import {useDispatch, useSelector} from "react-redux";
import {setPreferredLanguageThunk} from "../../../../features/auth/auth.actions";
import {authSelector} from "../../../../features/auth/auth.slice";
import ItaFlag from '../../../../assets/flags/IT.svg'
import UKFlag from '../../../../assets/flags/GB.svg'
import ESFlag from '../../../../assets/flags/ES.svg'
import {getLanguagesThunk} from "../../../../features/userHome/userHome.actions";
import {userHomeSelector} from "../../../../features/userHome/userHome.slice";
import isEmpty from "lodash/isEmpty";

const mapLanguagesFlags = {
    'it': ItaFlag,
    'en': UKFlag,
    'es': ESFlag
}

const DropdownLanguage = ({border}) => {
    const { languages, selectedTenantId } = useSelector(userHomeSelector);
    const { language } = useSelector(authSelector);
    const dispatch = useDispatch()
    const [selectedLanguage, setSelectedLanguage] = useState(language)

    useEffect(() => {
        if(isEmpty(languages) && selectedTenantId) dispatch(getLanguagesThunk())
        else if(!selectedLanguage) setSelectedLanguage(languages?.[0]?.id)
    },[languages, selectedTenantId])

    useEffect(() => {
        if(language !== selectedLanguage) dispatch(setPreferredLanguageThunk({preferredLanguage: selectedLanguage}))
    },[selectedLanguage])

    return (
        <Menu as="div" className="relative inline-block text-left">
            {({ open }) => (
                <>
                    <div>
                        {/* eslint-disable-next-line react/jsx-no-undef */}
                        <Menu.Button className={classNames(border ? "border border-gray-300":"",
                            "inline-flex justify-center w-full rounded-md px-4 py-2 bg-white text-sm font-medium text-gray-700 focus:outline-none focus:ring-0 space-x-2")}>
                            <img src={mapLanguagesFlags[selectedLanguage]} alt='flag'/>
                            <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                        </Menu.Button>
                    </div>

                    <Transition
                        show={open}
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <Menu.Items
                            static
                            className="origin-top-right absolute right-1 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-20 focus:outline-none z-50 min-w-max"
                        >
                                {languages && languages.map((lang) => <Menu.Item key={lang.id}>
                                    {({ active }) => (
                                        <div onClick={() => setSelectedLanguage(lang.id)}
                                            className={classNames(
                                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                'group flex items-center p-2 text-sm cursor-pointer'
                                            )}
                                        >
                                            <img src={mapLanguagesFlags[lang.id]} alt={lang.id} className='w-8 mr-3'/>
                                            {lang.description}
                                        </div>
                                    )}
                                </Menu.Item>)}
                        </Menu.Items>
                    </Transition>
                </>
            )}
        </Menu>
    )
}

export default DropdownLanguage;
