import EventsList from "../../../../organisms/EventsList/EventsList";
import React, {useEffect, useState} from "react";
import {
    DATE,
    DROPDOWN_ICON,
    ICON_BUTTON,
    ICON_BUTTON_LIST, STATUS
} from "../../../../atoms/SimpleTableElement/SimpleTable.types";
import {
    deleteSentEventThunk,
    downloadEventPDF, getDevices,
    getSentEvents,
    getSentEventsDetailsThunk, sendToTabletThunk
} from "../../../../../features/library/library.action";
import {useDispatch} from "react-redux";
import isEmpty from "lodash/isEmpty";
import {useTranslation} from "react-i18next";

const DocInviati = ({sentEvents, sentEventsCount, closeDetail, detailOpened, loadingEvents, completed, currentEventId, filters, setFilters, flgIncludiCompletati, setFlgIncludiCompletati,
                        toggleCompletati, viewEventDetail, devices, listLoadedSentEvents, initialFilters}) => {
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [eventToDelete, setEventToDelete] = useState()
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const getSentEventsDetails = async (eventId) => await dispatch(getSentEventsDetailsThunk({id: eventId}))
    const updateSentEvents = (filter=initialFilters) => dispatch(getSentEvents(filter))
    const downloadPDF = async (row) => await dispatch(downloadEventPDF({url: row?.downloadPdfUrl}));
    const deleteSentEvent = async() => dispatch(deleteSentEventThunk({eventId: eventToDelete?.id}))

    const sendToDevice = (eventId, deviceId) => {
        if (eventId && deviceId) dispatch(sendToTabletThunk({eventId, deviceId}))
    }

    const isHiddenTablet = row => (!row.hasTablet || isEmpty(devices))
    const isHiddenDelete = row => (!row.id || row.flowStatus === 'COMPLETED')

    const onClickDeleteSentEvent = (data) => {
        setEventToDelete(data);
        setOpenDeleteModal(true);
    }

    const sentEventsColumns = [
        {field: 'flowstatus', label: t('tableColumns.status'), hidden: true, type: STATUS, id: 's1'},
        {field: 'displayname', label: t('tableColumns.name'), isSortable: false, hidden: false, id: 's2'},
        {field: 'title', label: t('tableColumns.title'), isSortable: false, hidden: false, id: 's3'},
        {field: 'referencedate', type: DATE, label: t('tableColumns.date'), isSortable: true, hidden: false, id: 's4'},
        {
            field: 'actions', type: ICON_BUTTON_LIST, id: 's5', hidden: true, buttons: [
                {
                    field: 'Apri su Tablet',
                    label: t('tableColumns.openTablet'),
                    hidden: true,
                    hideColumnContent: isHiddenTablet,
                    type: DROPDOWN_ICON,
                    dropdownOptions: devices.map(dev => {
                        return {
                            id: dev.id,
                            label: dev.label,
                            status: dev.currentStatus === 'IDLE' ? t('available') : dev.currentStatus === 'BUSY' ? t('busy') : t('unreachable')
                        }
                    }),
                    iconType: 'tablet',
                    onClick: (data, tablet) => sendToDevice(data?.eventGraphId, tablet?.id), id: 's6'
                },
                {
                    field: 'Visualizza',
                    label: t('tableColumns.view'),
                    hidden: true,
                    type: ICON_BUTTON,
                    onClick: (d) => downloadPDF(d),
                    iconType: 'view', id: 's7'
                },
                {
                    field: 'Dettagli',
                    label: t('tableColumns.details'),
                    hidden: true,
                    type: ICON_BUTTON,
                    onClick: (d) => viewEventDetail(d, getSentEventsDetails),
                    iconType: 'details', id: 's8'
                },
                {
                    field: 'Delete',
                    label: t('tableColumns.delete'),
                    hidden: true,
                    hideColumnContent: isHiddenDelete,
                    type: ICON_BUTTON,
                    onClick: (data) => onClickDeleteSentEvent(data),
                    iconType: 'delete', id: 's9'
                },
            ]
        }
    ]

    useEffect(() => {
        dispatch(getDevices());
        if(!listLoadedSentEvents) {
            updateSentEvents()
        }
    }, [listLoadedSentEvents])

    return <EventsList
        data={sentEvents} closeDetail={closeDetail}
        columns={sentEventsColumns} detailOpened={detailOpened}
        onDeleteEvent={deleteSentEvent} openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal} toDeleteRecordName={eventToDelete?.title}
        loading={loadingEvents} elementsCount={sentEventsCount}
        updateEvents={updateSentEvents} completed={completed} currentEventId={currentEventId}
        filters={filters} setFilters={setFilters}
        flgIncludiCompletati={flgIncludiCompletati} setFlgIncludiCompletati={setFlgIncludiCompletati}
        sideComponent={toggleCompletati}
        showResendEmail={true}
    />
}

export default DocInviati;
