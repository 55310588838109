import PageWithHeader from "../Templates/PageWithHeader/PageWithHeader";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Paginator from "../../atoms/Paginator/Paginator";
import {userHomeSelector} from "../../../features/userHome/userHome.slice";
import NotificationsList from "../../organisms/Notifications/NotificationsList/NotificationsList";
import {useTranslation} from "react-i18next";
import {getNotifications} from "../../../features/userHome/userHome.actions";

const NotificationsPage = () => {
    const dispatch = useDispatch();
    const {notifications, notificationsListLoaded, notificationsCount} = useSelector(userHomeSelector)
    const [pagination, setPagination] = useState({currentPage: 1, offset: 0, limit:10});
    const {t} = useTranslation()

    //caricamento iniziale
    useEffect(() => {
        if(!notificationsListLoaded) {
            dispatch(getNotifications({pagination}));
        }
    }, [notifications])

    useEffect(() => {
        dispatch(getNotifications({pagination}));
    }, [pagination])



    return(
        <PageWithHeader>
            <div className='flex w-full justify-center my-8'>
                <div className='flex flex-col flex-1 ml-4 '>
                    <div className='flex items-start mb-4 ' >
                        <div className='flex mx-4 mr-4 text-3xl'>
                            {t('notifications.title')}
                        </div>
                    </div>

                    <div className='flex justify-center'>
                        <NotificationsList data={notifications} />
                    </div>
                    <div className='flex flex-col flex-1'>
                        <Paginator pageSize={10} currentPage={pagination.currentPage} totalResults={notificationsCount} onClick={setPagination}/>
                    </div>
                </div>
            </div>
        </PageWithHeader>
    )
}

export default NotificationsPage;
