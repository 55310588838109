import {createAsyncThunk, createEntityAdapter, createSlice} from "@reduxjs/toolkit";
import isEmpty from "lodash/isEmpty";
import {
    startSelfSignedDocumentCall,
    uploadSelfSignedDocumentAndSignCadesCall,
    uploadSelfSignedDocumentCall
} from "../../api/selfSignDocument";
import {getPlaceholdersCall} from "../../api/documentBuilder";
import {setPlaceholdersThunk} from "../documentBuilder/documentBuilder.actions";
import {toast} from "react-hot-toast";
import Alert from "../../components/molecules/Alerts/Alert";
import {ERROR} from "../../components/molecules/Alerts/types";
import {Translation} from "react-i18next";

const selfSignDocument = createEntityAdapter();

const steps = [
    { id: '1', urlName: 'upload', name: 'steps.upload', href: 'upload', status: 'current' },
    { id: '2', urlName: 'document',  name: 'steps.document', href: 'document', status: 'upcoming' },
]

const initialState = selfSignDocument.getInitialState({
    steps,
    currentStepIdx: 0,
    loading: false,
    placeholders: {}
})

export const uploadSelfSignedDocumentThunk = createAsyncThunk(
    'selfSignDocument/uploadSelfSignedDocumentThunk',
    async ({file, signatureType, callback}, {dispatch }) => {
        const response = await uploadSelfSignedDocumentCall(file, signatureType);
        if(response.status === 200) {
            dispatch(nextStep())
            callback?.()
        }
        return response?.data
    }
);

export const uploadSelfSignedDocumentAndSignCadesThunk = createAsyncThunk(
    'selfSignDocument/uploadSelfSignedDocumentAndSignCadesThunk',
    async ({file, pin, otp, deviceId, callback}, {dispatch }) => {
        try {
            const response = await uploadSelfSignedDocumentAndSignCadesCall(file, pin, otp, deviceId);
            if(response.status === 200) {
                callback?.()
            }
            toast.custom((t) => (<Alert message='Documento firmato con successo! Riceverai per email la documentazione che hai appena firmato.' onClick={() => toast.dismiss(t.id)} /> ))
            return response?.data
        } catch (e){
            let errorMessage = 'Si è verificato un errore. Si prega di verificare la correttezza del PIN';
            if (e.message) {
                errorMessage = e.message;
            }
            toast.custom((tst) => (<Translation>{(t) => <Alert type={ERROR} message={errorMessage} onClose={() => toast.dismiss(tst.id)}/>}</Translation>))
            throw e
        }

    }
);

export const getPlaceholdersSelfSignThunk = createAsyncThunk(
    'selfSignDocument/getPlaceholdersSelfSignThunk',
    async (props, { getState }) => {
        const { document } = getState().selfSignDocument;
        const response = await getPlaceholdersCall(document.eventFlowTypeId);
        return response.data;
    }
);

export const startSelfSignedDocumentThunk = createAsyncThunk(
    'selfSignDocument/startSelfSignedDocumentThunk',
    async ({deviceId, callback}, {dispatch, getState }) => {
        const { document } = getState().selfSignDocument;
        try {
            const response = await startSelfSignedDocumentCall(document.eventFlowTypeId);
            if(response.status === 200) {
                await dispatch(setPlaceholdersThunk({document})).unwrap();
                callback(response.data?.redirectUrl, response.data?.eventId)
            }
            return response.data.redirectUrl;
        }catch (e){
            toast.custom((tst) => (<Translation>{(t) => <Alert type={ERROR} message={t('errorOccurred')} onClose={() => toast.dismiss(tst.id)}/>}</Translation>))
            throw e
        }
    }
);



const selfSignDocumentSlice = createSlice({
        name: 'selfSignDocument',
        initialState,
        reducers: {
            selfSignInit: () => {
                return initialState
            },
            nextStep: (state) => {
                if(state?.steps?.[state.currentStepIdx]?.status && state?.steps?.[state.currentStepIdx]?.status !== 'disabled') state.steps[state.currentStepIdx].status = 'complete';
                if(state?.steps?.[state.currentStepIdx+1]?.status && state?.steps?.[state.currentStepIdx+1]?.status !== 'disabled' && state?.steps?.[state.currentStepIdx+1]?.status !== 'complete') state.steps[state.currentStepIdx+1].status = 'current';
                if(state.currentStepIdx < (state.steps.length - 1)) state.currentStepIdx++
            },
            previousStep: (state) => {
                if (state.currentStepIdx > 0) {
                    if(state?.steps[state.currentStepIdx]?.status !== 'disabled' || state?.steps[state.currentStepIdx]?.status !== 'complete') state.steps[state.currentStepIdx].status = 'upcoming';
                    state.currentStepIdx--
                }
            },
            setCurrentStep:(state, action) => {
                state.steps.forEach(step => step.status = step.status === 'current' ? 'upcoming' : step.status)
                const idx = state.steps.findIndex(step => step.urlName === action.payload)
                if(!isEmpty(idx)) state.currentStepIdx = idx
                state.steps[idx].status = state.steps[idx].status === 'disabled' ? state.steps[idx].status : 'current';
            },
        },
        extraReducers: (builder) => {
            builder
                .addCase(uploadSelfSignedDocumentThunk.pending, (state) => {
                    state.loading = true;
                })
                .addCase(uploadSelfSignedDocumentThunk.fulfilled, (state, action) => {
                    state.document = action.payload;
                    state.loading = false;
                })
                .addCase(uploadSelfSignedDocumentThunk.rejected, (state, action) => {
                    state.loading = false;
                    console.log(action);
                })
                .addCase(getPlaceholdersSelfSignThunk.fulfilled, (state, action) => {
                    state.placeholders = action.payload;
                })
                .addCase(getPlaceholdersSelfSignThunk.rejected, (state, action) => {
                    console.log(action);
                })
                .addCase(startSelfSignedDocumentThunk.fulfilled, (state, action) => {
                    state.redirectUrl = action.payload;
                })
                .addCase(startSelfSignedDocumentThunk.rejected, (state, action) => {
                    console.log(action);
                });
        }
    }
)

export const selfSignDocumentSelector = (state) => state.selfSignDocument;

export const {nextStep, previousStep, selfSignInit} = selfSignDocumentSlice.actions;

export default selfSignDocumentSlice.reducer
