import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Input from "../../../../atoms/Inputs/Input/Input";
import Button from "../../../../atoms/Buttons/Button/Button";
import {LG} from "../../../../atoms/Buttons/sizes";
import {
    askEnable2FaThunk,
    disable2FaThunk,
    enable2FaThunk,
    getCurrentUser
} from "../../../../../features/auth/auth.actions";
import {authSelector} from "../../../../../features/auth/auth.slice";
import {useTranslation} from "react-i18next";
import GenericQuestionModal from "../../../../molecules/Modals/GenericModal/GenericQuestionModal";
import Toggle from "../../../../atoms/Toggle/Toggle";

const Manage2fa = () => {
    const dispatch = useDispatch();
    const {user} = useSelector(authSelector);
    const {t} = useTranslation()

    const [open2saModal, setOpen2saModal] = useState(false)
    const [enabled2fa, setEnabled2fa] = useState(user.has2fa ?? false)
    const [code, setCode] = useState('')


    useEffect(() => {
        dispatch(getCurrentUser({}))
    },[])

    const proceedEnable = () => {
        dispatch(enable2FaThunk({sessionId: user.askEnable2FaQrSessionId, code: code}));
    }


    const change2fa = (val) => {
        if (val) {
            dispatch(askEnable2FaThunk({}));
        } else {
            dispatch(disable2FaThunk({}));
        }
        setEnabled2fa(val);
    }

    return (
        <div className='border rounded-3xl p-8 m-4 flex flex-col items-center min-w-full'>
            <div className='w-full space-y-2'>
                <h1 className='text-lg leading-6 font-medium'>{t('manage2fa.mainTitle', 'Autenticazione a due fattori')}</h1>

                <div>
                    <span>{t('manage2fa.subTitle')}:</span>
                    <ol className="list-decimal ml-5 mb-20">
                        <li>{t('manage2fa.tip1')}</li>
                        <li>{t('manage2fa.tip2')}</li>
                        <li>{t('manage2fa.tip3')}</li>
                    </ol>
                </div>


                <Toggle value={enabled2fa}  setValue={() => setOpen2saModal(true)} label={enabled2fa ? t('manage2fa.removeTip') : t('manage2fa.addTip')}/>
                <GenericQuestionModal open={open2saModal} setOpen={setOpen2saModal}
                                      customMessage=' '
                                      onDelete={() => change2fa(!enabled2fa)} title={user.has2fa ? t('has2fa.removeTitle') : t('has2fa.addTitle')} confirmButtonLabel={t('proceed')}/>

                {(enabled2fa && !user.has2fa && user.askEnable2FaQrCode && user.askEnable2FaQrSessionId) && <>
                    <div>
                        <img src={user.askEnable2FaQrCode} alt='QR CODE' className='w-60 h-60'/>
                    </div>
                    <div>
                        <Input value={code} setValue={setCode} label={t('login.totp')}/>
                    </div>
                    <div>
                        <Button size={LG} onClick={proceedEnable} label={t('proceed')}></Button>
                    </div>
                </>}


            </div>
        </div>

    )
}

export default Manage2fa;
