// Thunk functions
import {createAsyncThunk} from "@reduxjs/toolkit";
import {
    activateUserRequest, askEnable2Fa,
    changePasswordCall,
    disable2Fa, doCheckDraftSessionId, enable2Fa,
    forgotPasswordRequest,
    loginCheck,
    registrationRequest,
    resetPasswordRequest,
    retrieveCurrentUser, retrieveFeqSignerProfile,
    setLimitUser,
    setPhoneCall,
    setPreferredLanguageCall, setUsersAdvancedFilters
} from "../../api/auth";
import {toast} from "react-hot-toast";
import Alert from "../../components/molecules/Alerts/Alert";
import {SUCCESS, WARNING} from "../../components/molecules/Alerts/types";
import {setI18nLanguage} from "../../utils/helper";
import {getUserOrganizations} from "../userHome/userHome.actions";

export const login = createAsyncThunk('auth/login', async (credentials, {dispatch}) => {
    const response = await loginCheck(credentials?.username?.trim() ?? '', credentials?.password?.trim() ?? '', credentials?.totp ?? '');
    if (response.status === 200) {
        if (response.data.token) {
            sessionStorage.setItem('token', 'Bearer ' + response.data.token)
        }
        if(credentials?.saveUsername) sessionStorage.setItem('confirmo-username', credentials.username)
        if (response?.data?.userProfile?.preferredLanguage) await setI18nLanguage(response.data.userProfile.preferredLanguage)
        if (response.data.token) {
            await dispatch(getUserOrganizations()).unwrap()
        }
    }
    return response.data
});

// Thunk functions
export const forgotPassword = createAsyncThunk('auth/forgotPassword', async (credentials) => {
    const response = await forgotPasswordRequest(credentials.username)
    toast.custom((t) => ( <Alert type={SUCCESS} message='Operazione effettuata. Riceverai una mail con le istruzioni per effettuare il reset della password' onClose={() => toast.dismiss(t.id)}/> ))
    return response.data
});

export const resetPassword = createAsyncThunk('auth/resetPassword', async (credentials) => {
    const response = await resetPasswordRequest(credentials.resetPwdToken, credentials.password, credentials.passwordConfirm);
    toast.custom((t) => ( <Alert type={SUCCESS} message='Operazione effettuata. Effettua ora la login con le nuove credenziali.' onClose={() => toast.dismiss(t.id)}/> ))
    return response.data
});

export const changePasswordThunk = createAsyncThunk('auth/changePasswordThunk', async ({currentPassword, newPassword, confirmPassword}) => {
    try {
        await changePasswordCall(currentPassword, newPassword, confirmPassword);
        toast.custom((t) => ( <Alert type={SUCCESS} message='Operazione effettuata. Effettua ora la login con le nuove credenziali.' onClose={() => toast.dismiss(t.id)}/> ))
    }catch (e){
        let errorMessage = e.toString();
        if (e.response && e.response.data && e.response.data.errorMessage) {
            errorMessage = e.response.data.errorMessage;
        }
        toast.custom(() => ( <Alert type={WARNING} message={errorMessage} /> ))
        throw e
    }
});

export const registration = createAsyncThunk('auth/registration', async (credentials) => {
    const response = await registrationRequest(credentials);
    return response.data
});

export const activateUser = createAsyncThunk('auth/activateUser', async (credentials) => {
    const response = await activateUserRequest(credentials);
    return response.data
});

export const getCurrentUser = createAsyncThunk('auth/me', async () => {
    const response = await retrieveCurrentUser()
    return response.data
});

export const checkDraftSessionId = createAsyncThunk('auth/checkDraftSessionId', async ({sessionId}) => {
    const response = await doCheckDraftSessionId(sessionId)
    return response.data
});

export const setPreferredLanguageThunk = createAsyncThunk(
    'auth/setPreferredLanguageThunk',
    async ({preferredLanguage}) => {
        await setPreferredLanguageCall(preferredLanguage);
        await setI18nLanguage(preferredLanguage)
        return preferredLanguage
    }
);

export const setUsersAdvancedFiltersThunk = createAsyncThunk(
    'auth/setUsersAdvancedFiltersThunk',
    async ({usersAdvancedFilters}) => {
        await setUsersAdvancedFilters(usersAdvancedFilters);
        return usersAdvancedFilters
    }
);

export const changePhoneThunk = createAsyncThunk(
    'auth/changePhoneThunk', async ({phone}) => {
        try {
            await setPhoneCall(phone);
            toast.custom((t) => ( <Alert type={SUCCESS} message='Operazione effettuata.' onClose={() => toast.dismiss(t.id)}/> ))
            return phone
        }catch (e){
            let errorMessage = e.toString();
            if (e.response && e.response.data && e.response.data.errorMessage) {
                errorMessage = e.response.data.errorMessage;
            }
            toast.custom(() => ( <Alert type={WARNING} message={errorMessage} /> ))
            throw e
        }
    }
);

export const setLimitUserThunk = createAsyncThunk(
    'auth/setLimitUserThunk', async ({limitUser}) => {
        const response = await setLimitUser(limitUser)
        console.log(response.data)
        return response.data
    }
);

export const retrieveSignerProfileThunk = createAsyncThunk(
    'auth/retrieveSignerProfileThunk', async ({}) => {
        const response = await retrieveFeqSignerProfile()
        console.log(response.data)
        return response.data
    }
);



export const askEnable2FaThunk = createAsyncThunk(
    'auth/askEnable2FaThunk', async () => {
        const response = await askEnable2Fa()
        console.log(response.data)
        return response.data
    }
);

export const enable2FaThunk = createAsyncThunk(
    'auth/enable2FaThunk', async ({sessionId, code}) => {
        const response = await enable2Fa({sessionId, code})
        return response.data
    }
)

export const disable2FaThunk = createAsyncThunk(
    'auth/disable2FaThunk', async () => {
        const response = await disable2Fa()
        return response.data
    }
)
