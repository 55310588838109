import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import {getOrganizationEvents, getOrganizationEventsDetails} from "../../../../../features/library/library.action";
import EventsList from "../../../../organisms/EventsList/EventsList";
import {v4} from "uuid";
import {DATE, ICON_BUTTON, ICON_BUTTON_LIST} from "../../../../atoms/SimpleTableElement/SimpleTable.types";
import {useTranslation} from "react-i18next";

const DocOrganization = ({organizationEvents, organizationEventsCount, closeDetail, detailOpened, currentEventId,
                             loadingEvents, filters, setFilters, workspaceId, viewEventDetail, downloadPDF}) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const updateOrganizationEvents = (filter) => {dispatch(getOrganizationEvents({...filter, workspaceId: workspaceId,}))}
    const getOrganizationEventDetails = async (eventId) => await dispatch(getOrganizationEventsDetails({id: eventId}))

    useEffect(() => {return updateOrganizationEvents()}, [workspaceId])

    const organizationColumns = [
        {field: 'flowstatus', label: t('tableColumns.status'), hidden: true, type: 'status', id: v4()},
        {field: 'displayname', label: t('tableColumns.name'), isSortable: false, hidden: false, id: v4()},
        {field: 'title', label: t('tableColumns.title'), isSortable: false, hidden: false, id: v4()},
        {field: 'referencedate', type: DATE, label: t('tableColumns.date'), isSortable: true, hidden: false, id: v4()},
        {
            field: 'actions', type: ICON_BUTTON_LIST, id: v4(), hidden: true, buttons: [
                {
                    field: 'Visualizza',
                    label: t('tableColumns.view'),
                    hidden: true,
                    type: ICON_BUTTON,
                    onClick: (d) => downloadPDF(d),
                    iconType: 'view', id: v4()
                },
                {
                    field: 'Dettagli',
                    label: t('tableColumns.details'),
                    hidden: true,
                    type: ICON_BUTTON,
                    onClick: (d) => viewEventDetail(d, getOrganizationEventDetails),
                    iconType: 'details', id: v4()
                }
            ]
        }
    ]
    return <EventsList
        data={organizationEvents} closeDetail={closeDetail}
        columns={organizationColumns} detailOpened={detailOpened}
        loading={loadingEvents} elementsCount={organizationEventsCount}
        updateEvents={updateOrganizationEvents} currentEventId={currentEventId}
        filters={filters} setFilters={setFilters}
    />
}

export default DocOrganization;
