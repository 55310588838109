import {useDispatch, useSelector} from "react-redux";
import React from "react";
import {closePersonDetail, peopleSelector} from "../../../features/people/people.slice";
import ModalContactDetail from "../../molecules/Modals/ModalContactDetail/ModalContactDetail";
import ModalContactNew from "../../molecules/Modals/ModalContactNew/ModalContactNew";

const PeopleDetailView = ({currentPeopleRole, readonlyFields=[]}) => {
    const dispatch = useDispatch();
    const {currentPerson, detailOpened} = useSelector(peopleSelector)
    const closeDetail = () => dispatch(closePersonDetail());

    return(
        currentPerson.id ?
            <ModalContactDetail
                data={currentPerson}
                open={detailOpened}
                setOpen={closeDetail}
                currentPeopleRole={currentPeopleRole}
                readonlyFields={readonlyFields}
            /> :
            <ModalContactNew
                data={currentPerson}
                open={detailOpened}
                setOpen={closeDetail}
                currentPeopleRole={currentPeopleRole}
            />
    )
}

export default PeopleDetailView;
