import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Input from "../../../../atoms/Inputs/Input/Input";
import Button from "../../../../atoms/Buttons/Button/Button";
import {LG} from "../../../../atoms/Buttons/sizes";
import {
    changePasswordThunk,
    changePhoneThunk,
    getCurrentUser,
    setLimitUserThunk
} from "../../../../../features/auth/auth.actions";
import {authSelector} from "../../../../../features/auth/auth.slice";
import {useTranslation} from "react-i18next";
import SingleCheckbox from "../../../../atoms/SingleCheckbox/SingleCheckbox";
import GenericQuestionModal from "../../../../molecules/Modals/GenericModal/GenericQuestionModal";

const PersonalData = () => {
    const dispatch = useDispatch();
    const {user} = useSelector(authSelector);
    const {t} = useTranslation()

    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmoPassword] = useState('');
    const [phone, setPhone] = useState(user.phoneNumber)
    const [openLimitUserModal, setOpenLimitUserModal] = useState(false)

    useEffect(() => {
        dispatch(getCurrentUser({}))
    },[])

    const changePasswordRequest = async () => {
        dispatch(changePasswordThunk({currentPassword, newPassword, confirmPassword}));
    }

    const changePhone = async () => {
        dispatch(changePhoneThunk({phone}));
    }

    const changeIsLimited = (limitUser) => {
        dispatch(setLimitUserThunk({limitUser}))
    }

    return (
        <div className='border rounded-3xl p-8 m-4 flex flex-col items-center min-w-full'>
            <div className='flex space-x-12 mx-4'>
                <img className="h-100 w-100 rounded-full" src={user.avatar} alt="" />
                <div className='flex flex-col justify-center space-y-2'>
                    <div className='flex space-x-2'>
                        <h1 className='text-lg leading-6 font-medium'>{t('name')}:</h1>
                        <h1 className='text-base leading-6 font-normal capitalize'>{user.name.toLowerCase()}</h1>
                    </div>
                    <div className='flex space-x-2'>
                        <h1 className='text-lg leading-6 font-medium'>{t('email')}:</h1>
                        <h1 className='text-base leading-6 font-normal'>{user.email}</h1>
                    </div>
                    <div className='flex space-x-2'>
                        <h1 className='text-lg leading-6 font-medium'>{t('taxCode')}:</h1>
                        <h1 className='text-base leading-6 font-normal'>{user.taxCode}</h1>
                    </div>
                </div>
            </div>
            <div className='flex justify-between w-full space-x-8'>
                <form className="space-y-3 py-4 w-full max-w-md">
                    <h1 className='text-lg leading-6 font-medium'>Numero di telefono</h1>
                    <Input
                        name='Telefono'
                        type='text'
                        label='Telefono'
                        value={phone}
                        setValue={setPhone}
                    />
                    <div className='h-10 my-1'>
                        <Button
                            variant='blue'
                            label='Imposta Telefono'
                            size={LG}
                            onClick={changePhone}
                        />
                    </div>
                </form>
                <form className="space-y-2 py-4 w-full max-w-md">
                    <h1 className='text-lg leading-6 font-medium'>{t('registration.resetPassword')}</h1>
                    <div className='grid grid-rows-3 gap-x-4'>
                        <Input
                            name='old-password'
                            type='password'
                            label={t('passwordOld')}
                            value={currentPassword}
                            setValue={setCurrentPassword}
                        />
                        <Input
                            name='password'
                            type='password'
                            label={t('password')}
                            value={newPassword}
                            setValue={setNewPassword}
                        />
                        <Input
                            name='passwordConfirm'
                            type='password'
                            label={t('confirmPassword')}
                            value={confirmPassword}
                            setValue={setConfirmoPassword}
                        />
                    </div>
                    <div className='h-10 my-1'>
                        <Button
                            variant='blue'
                            label={t('setPassword')}
                            size={LG}
                            onClick={changePasswordRequest}
                        />
                    </div>
                </form>
            </div>
            <div className='w-full space-y-2'>
                <h1 className='text-lg leading-6 font-medium'>{t('limitUser.mainTitle', 'Limitazione dei dati')}</h1>
                <SingleCheckbox label={t('limitUser.intro', 'Esercita il diritto di Limitazione dei dati')} value={user.isLimited} setValue={() => setOpenLimitUserModal(true)}/>
                <GenericQuestionModal open={openLimitUserModal} setOpen={setOpenLimitUserModal}
                                      customMessage={user.isLimited ? t('limitUser.removeMessage', 'Vuoi ritirare il consenso alla limitazione dei dati?') : t('limitUser.limitMessage', 'Attenzione così facendo gli altri utenti non potranno vedere i tuoi dati e alcune funzionalità di Confirmo ti saranno limitate')}
                                      onDelete={() => changeIsLimited(!user?.isLimited)} title={user.isLimited ? t('limitUser.removeTitle', 'Vuoi ritirare il consenso alla limitazione dei dati?') : t('limitUser.limitTitle', 'Vuoi esercitare il diritto di limitazione dei dati?')} confirmButtonLabel={t('proceed')}/>
            </div>
        </div>

    )
}

export default PersonalData;
