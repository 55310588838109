import React from "react";
import {DragDropContainer} from 'react-drag-drop-container';
import {v4 as uuid} from "uuid";
import {CustomView, isAndroid, isIOS} from 'react-device-detect';

const Draggable = ({children, dragObject, onDragStart, onDragEnd}) => {
    const id = uuid();

    const onDragStarting = (e) => {
        // Get the block coordinates
        let currentTargetRect = e.currentTarget.getBoundingClientRect();
        // Find the offset of the mouse from those coordinates.
        const offset = {
            x: e.clientX - currentTargetRect.left,
            y: e.clientY - currentTargetRect.top,
            rect: currentTargetRect
        };

        // Pass the drag data
        onDragStart({
            label: dragObject.label,
            fieldId: dragObject.fieldId,
            x: offset.x,
            y: offset.y,
            width: currentTargetRect.width,
            height: currentTargetRect.height,
            recipientId: dragObject.referredId
        });
    };

    const onDragEnding = (e) => {
        e.stopPropagation();
        onDragEnd();
    };

    const onMobileDragEnd = (dragData) => {
        dragData.isDragging = false;
    }

    const onMobileDrag = (dragData, currentTarget, x, y) => {

        // Get the block coordinates
        let realCurrentTarget = document.getElementById(dragData.elementId);
        let currentTargetRect = realCurrentTarget.parentElement.getBoundingClientRect();
        let currentTargetRectInternal = realCurrentTarget.getBoundingClientRect();
        // Find the offset of the mouse from those coordinates.
        dragData.x = x;
        dragData.y = y;

        if (!dragData.isDragging) {
            console.log(currentTarget);
            console.log(realCurrentTarget);
            dragData.width = currentTargetRect.width;
            dragData.height = currentTargetRect.height;
            dragData.widthInternal = currentTargetRectInternal.width;
            dragData.heightInternal = currentTargetRectInternal.height;
            dragData.leftOffset = dragData.x - document.getElementById(dragData.elementId).getBoundingClientRect().x;
            dragData.topOffset = dragData.y - document.getElementById(dragData.elementId).getBoundingClientRect().y;
            console.log('=====> ' + dragData.height + ' ' + dragData.heightInternal)
        }

        dragData.isDragging = true;
    }

    return (
        <>
            <CustomView condition={isAndroid || isIOS}>
                <DragDropContainer
                    dragClone={true}
                    onDragEnd={onMobileDragEnd}
                    onDrag={onMobileDrag}
                    dragData={{
                        label: dragObject.label,
                        fieldId: dragObject.fieldId,
                        recipientId: dragObject.referredId,
                        isDragging: false,
                        elementId: 'dd-' + id
                    }}
                >
                    <span id={`dd-${id}`}>{children}</span>
                </DragDropContainer>
            </CustomView>
            <CustomView condition={!isAndroid && !isIOS}>
                <div
                    className="flex"
                    draggable={true}
                    onDragStart={onDragStarting}
                    onDragEnd={onDragEnding}
                >
                    {children}
                </div>
            </CustomView>
        </>

    );
};

export default Draggable;
