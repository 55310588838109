import client from "./client";

export const getGraphConsentCall = async (onlyActive) => {
    return await client.get(`/api/doc-models/graph-consents`, {params: {onlyActive}})
}

export const getGraphConsentModelCall = async (modelId) => {
    return await client.get(`/api/doc-models/${modelId}`)
}

export const deleteGraphConsentModelCall = async (modelId) => {
    return await client.delete(`/api/doc-models/${modelId}`)
}

export const getModelPlaceholdersCall = async (modelId) => {
    return await client.get(`/api/doc-models/available-placeholders/${modelId}`)
}

export const getAvailableGraphConsentCall = async () => {
    return await client.get(`/api/doc-models/graph-consents-available`)
}

export const uploadModelCall = async (file, isTemplate=false) => {
    let data = new FormData();
    data.append('file', file);
    data.append('isTemplate', isTemplate);
    return await client.post(`/api/doc-models/upload`, data);
}

export const setPlaceholderGraphModelCall = async (modelId, data) => {
    return await client.post(`/api/doc-models/${modelId}/set-placeholders`,data)
}

export const updateGraphConsentDetailsCall = async (modelId, title, description) => {
    return await client.put(`/api/doc-models/${modelId}`, {name: title, description})
}
