import {createEntityAdapter, createSlice} from "@reduxjs/toolkit";
import {v4} from "uuid";
import {
    addDataCollectFormFieldThunk,
    addDataCollectFormThunk,
    addInformativaStepThunk,
    addInformativaThunk,
    createUnlinkedDataCollectionThunk,
    createUnlinkedInformativa,
    deleteDataCollectFormFieldThunk,
    deleteInformativaThunk,
    deleteStepInformativaThunk,
    downloadPdfToDisplayThunk,
    getDataCollectFormDetailsThunk,
    getDataCollectionsThunk,
    getDocumentalProcessItemsThunk,
    getInformativaDetailsThunk,
    getInformativeThunk,
    getModelsDetailsThunk,
    getProcessDetailsThunk,
    getStepsTypes,
    massiveSetDataCollectionRuleThunk,
    reorderDataCollectFormFieldsThunk,
    reorderStepsInformativaThunk,
    saveModelsThunk,
    sendDocumentThunk,
    setOwnerDataCollectionThunk,
    updateDataCollectFormFieldThunk,
    updateDataCollectFormThunk,
    updateInformativaThunk,
    updateStepInformativaThunk,
    uploadStepInformativaPhotoThunk,
    upsertProcessThunk
} from "./documentalProcess.actions";
import {displayErrorAlert} from "../../utils/helper";
import omit from "lodash/omit";

const documentalProcess = createEntityAdapter();

const documentalProcessSteps = [
    { id: '0', urlName: 'intro',  name: 'steps.intro', href: '/newprocess/intro', status: 'disabled', disabled: true },
    { id: '12', urlName: 'onboarding',  name: 'steps.onboarding', href: '/newprocess/onboarding', status: 'disabled', disabled: true },
    { id: '1', urlName: 'recipients', name: 'steps.recipients', href: '/newprocess/recipients', status: 'current' },
    { id: '2', urlName: 'informativa',  name: 'steps.informedPaths', href: '/newprocess/informativa', status: 'upcoming' },
    { id: '3', urlName: 'datacollect',  name: 'steps.forms', href: '/newprocess/datacollect', status: 'upcoming' },
    { id: '4', urlName: 'orderinformativa',  name: 'steps.reorder', href: '/newprocess/orderinformativa', status: 'upcoming' },
    { id: '5', urlName: 'document',  name: 'steps.document', href: '/newprocess/document', status: 'upcoming' },
    { id: '6', urlName: 'summary',  name: 'steps.summary', href: '/newprocess/summary', status: 'upcoming' },
    { id: '7', urlName: 'end',  name: 'steps.end', href: '/newprocess/end', status: 'disabled', disabled: true },
]

const signDocumentSteps = [
    { id: '1', urlName: 'recipients', name: 'steps.recipients', href: '/senddocument/recipients', status: 'current' },
    { id: '2', urlName: 'document',  name: 'steps.document', href: '/senddocument/document', status: 'upcoming' },
    { id: '3', urlName: 'summary',  name: 'steps.summary', href: '/senddocument/summary', status: 'upcoming' },
    { id: '4', urlName: 'end',  name: 'steps.end', href: '/senddocument/end', status: 'disabled', disabled: true },
]

const draftDocumentSteps = [
    { id: '1', urlName: 'document',  name: 'steps.document', href: '/finalizedraft/document', status: 'current' },
    { id: '2', urlName: 'end',  name: 'steps.end', href: '/finalizedraft/end', status: 'disabled', disabled: true },
]

const initialState = documentalProcess.getInitialState({
    sections: [
        { name: 'document.models', href: 'models'},
        { name: 'document.favorites', href: 'favorites'},
        { name: 'document.latests', href: 'latest'},
    ],
    informative: {},
    dataCollections: {},
    stepsTypes: [],
    errorVideo: false,
    imageLoading: false,
    documentalProcessItems: {},
    shownReorderElements: [],
    updateInformativeSuccess: false,
    updateInformativeError: false,
    updateFormSuccess: false,
    updateFormError: false,
    updateModelAsFavouriteCallDone: false,
    steps: documentalProcessSteps,
    selectedDevice: parseInt(localStorage.getItem('selectedDevice')) ?? '',
    isLoading: false,
    loadingReorderItems: false,
    waitingSend: false,
    isDocumentSent: false,
    selectedWorkspaces: [],
    senderReminderComplete: false,
    avoidLtArchive: false,
    pdfFile: null
})

const documentalProcessSlice = createSlice({
    name: 'documentalProcess',
    initialState,
    reducers: {
        setInformative: (state, action) => {
            state.informative = action.payload;
        },
        addDataCollectionRule: (state, action) => {
            state.dataCollections[action.payload.dataCollectId].template.rules.push( {id: v4(), targetField: "", comparison: "", value: "", dependentField: "", action: ""})
        },
        setDataCollectionRule: (state, action) => {
            state.dataCollections[action.payload.dataCollectId].template.rules = state.dataCollections[action.payload.dataCollectId].template.rules.map(rule => action.payload.rule.id === rule.id ? action.payload.rule : rule)
        },
        deleteDataCollectionRule: (state, action) => {
            state.dataCollections[action.payload.dataCollectId].template.rules = state.dataCollections[action.payload.dataCollectId].template.rules.filter(rule => action.payload.rule.id !== rule.id)
        },
        setReorderElements: (state, action) => {
            state.shownReorderElements = action.payload
        },
        resetState: () => {
            return initialState;
        },
        reorderDocumentalProcessItems: (state, action) => {
            state.documentalProcessItems[action.payload.selectedUserId]= action.payload.items
        },
        nextPage: (state, action) => {
            state.steps = state.steps.map(step => {
                    if(step.urlName === action.payload.step)
                        step.status = 'complete'
                    return step
                }
            )
        },
        setMessageBody: (state, action) => {
            state.messageBody = action.payload
        },
        setMessageSubject: (state, action) => {
            state.messageSubject = action.payload
        },
        setReminderIntervalDays: (state, action) => {
            state.reminderIntervalDays = action.payload
        },
        setAvoidLtArchive: (state, action) => {
            state.avoidLtArchive = action.payload
        },
        setSenderReminderComplete: (state, action) => {
            state.senderReminderComplete = action.payload
        },
        setExpirationDate: (state, action) => {
            state.expirationDate = action.payload
        },
        setStepsByProcessType: (state, action) => {
            state.steps = action.payload.type === 'senddocument' ? signDocumentSteps : ((action.payload.type === 'senddocumentdraft') ? draftDocumentSteps : documentalProcessSteps)
            if(action.payload.completed)
                state.steps = state.steps.map(step => ({...step, status: step.disabled ? step.status : 'complete'}))
        },
        setDocumentSelectedDevice: (state, action) => {
            state.selectedDevice = action.payload
            localStorage.setItem('selectedDevice', action.payload)
        },
        deleteDataCollection: (state, action) => {
            state.dataCollections = omit(state.dataCollections, action.payload.dataCollectId);
        },
        selectWorkspace: (state, action) => {
            state.selectedWorkspaces = action.payload.map(w => w.id)
        },
        setSections: (state, action) => {
            state.sections = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(upsertProcessThunk.fulfilled, (state, action) => {
                state.newDocument = action.payload;
            })
            .addCase(getProcessDetailsThunk.fulfilled, (state, action) => {
                state.newDocument = action.payload;
                state.selectedWorkspaces = action.payload.workspaces ?? []
                state.senderReminderComplete = action.payload.senderReminderComplete ?? false
                state.avoidLtArchive = action.payload.avoidLtArchive ?? false

                if(action?.payload?.messageSubject) state.messageSubject = action.payload.messageSubject
                if(action?.payload?.messageBody) state.messageBody = action.payload.messageBody
            })
            .addCase(getProcessDetailsThunk.rejected, () => {
                window.history.back();
            })
            .addCase(addInformativaThunk.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(addInformativaThunk.fulfilled, (state, action) => {
                state.informative[action.payload.id] = action.payload;
                state.informative[action.payload.id].details = action.payload.details ? action.payload.details : []
                state.isLoading = false
            })
            .addCase(addInformativaThunk.rejected, (state, action) => {
                console.log(action)
                state.isLoading = false
            })
            .addCase(createUnlinkedInformativa.fulfilled, (state, action) => {
                state.informative= { [action.payload.id]: action.payload }
                state.informative[action.payload.id].details = []
            })
            .addCase(deleteInformativaThunk.fulfilled, (state, action) => {
                state.informative = omit(state.informative, action.payload);
            })
            .addCase(deleteInformativaThunk.rejected, (state, action) => {
                console.log(action)
            })
            .addCase(getStepsTypes.fulfilled, (state, action) => {
                state.stepsTypes = action.payload
            })
            .addCase(updateInformativaThunk.pending, (state) => {
                state.updateInformativeSuccess =  false;
                state.updateInformativeError =  false;
            })
            .addCase(updateInformativaThunk.fulfilled, (state, action) => {
                state.informative[action.payload.id] = {
                    ...state.informative[action.payload.id],
                    ...action.payload
                }
                state.updateInformativeSuccess =  true;
                state.updateInformativeError =  false;
            })
            .addCase(updateInformativaThunk.rejected, (state) => {
                state.updateInformativeSuccess =  false;
                state.updateInformativeError =  true;
            })
            .addCase(addInformativaStepThunk.fulfilled, (state, action) => {
                state.informative[action.payload.id].details.push(action.payload.data)
            })
            .addCase(updateStepInformativaThunk.fulfilled, (state, action) => {
                state.informative[action.payload.id].details = state.informative[action.payload.id].details.map(step => step.id === action.payload.stepId ? action.payload.data : step)
            })
            .addCase(updateStepInformativaThunk.pending, state => {
                state.errorVideo = false;
            })
            .addCase(updateStepInformativaThunk.rejected, state => {
                state.errorVideo = true;
            })
            .addCase(uploadStepInformativaPhotoThunk.pending, state => {
                state.imageLoading = true;
            })
            .addCase(uploadStepInformativaPhotoThunk.fulfilled, state => {
                state.imageLoading = false;
            })
            .addCase(uploadStepInformativaPhotoThunk.rejected, state => {
                state.imageLoading = false;
            })
            .addCase(deleteStepInformativaThunk.fulfilled, (state, action) => {
                state.informative[action.payload.informativaId].details = state.informative[action.payload.informativaId].details.filter(
                    det => det.id !== action.payload.stepId
                );
            })
            .addCase(getInformativaDetailsThunk.fulfilled, (state, action) => {
                state.informative[action.payload.id] = action.payload;
            })
            .addCase(reorderStepsInformativaThunk.fulfilled, (state, action) => {
                if (action.payload?.id) state.informative[action.payload.id] = action.payload;
            })
            .addCase(getInformativeThunk.fulfilled, (state, action) => {
                const informedPaths = {};
                action.payload.forEach(info => {
                    informedPaths[info.id] = info;
                });
                state.informative = informedPaths;
            })
            .addCase(getDataCollectionsThunk.pending, state => {
                state.isLoading = true;
            })
            .addCase(getDataCollectionsThunk.fulfilled, (state, action) => {
                const dataCollections = {};
                action.payload.forEach(dc => {
                    dataCollections[dc.id] = dc;
                    dataCollections[dc.id].isSenderOwner = dc.actors.some(act => act.id === "SENDER");
                });
                state.dataCollections = dataCollections;
                state.isLoading = false;
            })
            .addCase(getDataCollectionsThunk.rejected, state => {
                state.isLoading = false;
            })
            .addCase(getInformativeThunk.rejected, (state, action) => {
                console.log(action);
            })
            .addCase(getDataCollectFormDetailsThunk.fulfilled, (state, action) => {
                state.dataCollections[action.payload.id] = action.payload;
            })
            .addCase(addDataCollectFormThunk.pending, state => {
                state.isLoading = true;
            })
            .addCase(addDataCollectFormThunk.fulfilled, (state, action) => {
                state.dataCollections[action.payload.id] = action.payload;
                state.isLoading = false;
            })
            .addCase(addDataCollectFormThunk.rejected, (state, action) => {
                console.log(action);
                state.isLoading = false;
            })
            .addCase(createUnlinkedDataCollectionThunk.fulfilled, (state, action) => {
                state.dataCollections[action.payload.id] = action.payload;
            })
            .addCase(updateDataCollectFormThunk.pending, state => {
                state.updateFormSuccess = false;
                state.updateFormError = false;
            })
            .addCase(updateDataCollectFormThunk.fulfilled, (state, action) => {
                state.updateFormSuccess = true;
                state.updateFormError = false;
                state.dataCollections[action.payload.id] = { ...state.dataCollections[action.payload.id], ...action.payload };
            })
            .addCase(updateDataCollectFormThunk.rejected, state => {
                state.updateFormSuccess = false;
                state.updateFormError = true;
            })
            .addCase(addDataCollectFormFieldThunk.fulfilled, (state, action) => {
                state.dataCollections[action.payload.id] = {
                    ...state.dataCollections[action.payload.id],
                    template: { ...state.dataCollections[action.payload.id].template, items: action.payload.items }
                };
            })
            .addCase(addDataCollectFormFieldThunk.rejected, (state, action) => {
                console.log(action);
            })
            .addCase(updateDataCollectFormFieldThunk.fulfilled, (state, action) => {
                state.dataCollections[action.payload.id] = {
                    ...state.dataCollections[action.payload.id],
                    template: { ...state.dataCollections[action.payload.id].template, items: action.payload.items }
                };
            })
            .addCase(deleteDataCollectFormFieldThunk.fulfilled, (state, action) => {
                state.dataCollections[action.payload.id] = {
                    ...state.dataCollections[action.payload.id],
                    template: { ...state.dataCollections[action.payload.id].template, items: action.payload.items }
                };
            })
            .addCase(reorderDataCollectFormFieldsThunk.fulfilled, (state, action) => {
                state.dataCollections[action.payload.id] = {
                    ...state.dataCollections[action.payload.id],
                    template: { ...state.dataCollections[action.payload.id].template, items: action.payload.items }
                };
            })
            .addCase(massiveSetDataCollectionRuleThunk.fulfilled, (state, action) => {
                if (state?.dataCollections?.[action.payload.id]?.template?.rules)
                    state.dataCollections[action.payload.id].template.rules = action.payload.rules;
            })
            .addCase(getDocumentalProcessItemsThunk.pending, state => {
                state.loadingReorderItems = true;
            })
            .addCase(getDocumentalProcessItemsThunk.fulfilled, (state, action) => {
                state.documentalProcessItems = { ...state.documentalProcessItems, [action.payload.userId]: action.payload.data };
                state.loadingReorderItems = false;
            })
            .addCase(getDocumentalProcessItemsThunk.rejected, state => {
                state.documentalProcessItems = {};
                state.loadingReorderItems = false;
            })
            .addCase(setOwnerDataCollectionThunk.fulfilled, (state, action) => {
                state.dataCollections[action.payload.dataCollectId].isSenderOwner = action.payload.isSenderOwner;
            })
            .addCase(saveModelsThunk.rejected, (state, action) => {
                displayErrorAlert(action?.error?.message);
            })
            .addCase(getModelsDetailsThunk.fulfilled, (state, action) => {
                state.models = action.payload;
            })
            .addCase(sendDocumentThunk.pending, state => {
                state.waitingSend = true;
                state.isDocumentSent = false;
            })
            .addCase(sendDocumentThunk.fulfilled, state => {
                state.waitingSend = false;
                state.isDocumentSent = true;
            })
            .addCase(sendDocumentThunk.rejected, (state, action) => {
                state.waitingSend = false;
                state.isDocumentSent = false;
                displayErrorAlert(action?.error?.message);
            })
            .addCase(downloadPdfToDisplayThunk.fulfilled, (state, action) => {
                state.pdfFile = action.payload;
            })
            .addCase(downloadPdfToDisplayThunk.rejected, state => {
                state.pdfFile = null;
            });
    }
});

export const documentalProcessSelector = (state) => state.documentalProcess;

export const {
    resetState,
    addDataCollectionRule,
    setDataCollectionRule,
    deleteDataCollectionRule,
    reorderDocumentalProcessItems,
    nextPage,
    setExpirationDate,
    setReminderIntervalDays,
    setMessageBody,
    setMessageSubject,
    setStepsByProcessType,
    setDocumentSelectedDevice,
    deleteDataCollection,
    selectWorkspace,
    setAvoidLtArchive,
    setSenderReminderComplete,
    setSections
} = documentalProcessSlice.actions;

export default documentalProcessSlice.reducer
