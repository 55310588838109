import React, {useEffect, useState} from "react";
import FirmaMassiva from "../../pages/ManagePage/Sections/FirmaMassiva/FirmaMassiva";
import {closeEventDetail, librarySelector, openEventDetail} from "../../../features/library/library.slice";
import {useDispatch, useSelector} from "react-redux";
import {downloadEventPDF} from "../../../features/library/library.action";
import Toggle from "../../atoms/Toggle/Toggle";
import DocInAttesa from "../../pages/ManagePage/Sections/DocInAttesa/DocInAttesa";
import {Navigate} from "react-router-dom";

const ManageBodyExternal = ({section, completed=false, predefinedFilters={}}) => {
    const dispatch = useDispatch();
    const {eventsMassiveSignature, eventsCountMassiveSignature, waitingEvents, waitingEventsCount, loadingEvents, currentEventId, detailOpened} = useSelector(librarySelector)
    const initialFilters = {pagination: {currentPage: 1, offset: 0, limit:10}, txtFilter: '', sortField: 'referenceDate', descending: true, startDate: null, endDate: null, ...predefinedFilters}
    const [filters, setFilters] = useState(initialFilters)
    const [flgIncludiCompletati, setFlgIncludiCompletati] = useState(completed)

    useEffect(() => {
        setFilters({...filters, pagination: initialFilters.pagination})
    },[flgIncludiCompletati])

    useEffect(() => {
        setFilters(initialFilters)
    },[section])

    const closeDetail = async () => dispatch(closeEventDetail())

    const downloadPDF = async (row) => await dispatch(downloadEventPDF({url: row?.downloadPdfUrl}));
    const goToSign = async (event) => {
        if (event?.signUrl) {
            // Ottieni il baseUrl corrente
            const currentBaseUrl = window.location.origin;
            // Combina il baseUrl con la parte relativa di event?.signUrl
            const fullUrl = `${currentBaseUrl}${event?.signUrl}`;
            // Apri la finestra con la nuova URL
            window.open(fullUrl);
        }
    }

    const viewEventDetail = async (event, getEventDetails) => {
        if(!event?.detail) await getEventDetails(event.id)
        dispatch(openEventDetail({eventId: event.id}));
    }

    const toggleCompletati = <Toggle value={flgIncludiCompletati} setValue={setFlgIncludiCompletati} label='Includi completati'/>

    switch (section) {
        case 'received':
            return <DocInAttesa downloadPDF={downloadPDF} flgIncludiCompletati={flgIncludiCompletati} setFilters={setFilters} filters={filters} loadingEvents={loadingEvents} toggleCompletati={toggleCompletati} closeDetail={closeDetail}
                                viewEventDetail={viewEventDetail} detailOpened={detailOpened} currentEventId={currentEventId} completed={completed} waitingEvents={waitingEvents} waitingEventsCount={waitingEventsCount} goToSign={goToSign}
            />
        case 'massivesignature':
            return <FirmaMassiva goToSign={goToSign} flgIncludiCompletati={flgIncludiCompletati} setFlgIncludiCompletati={setFlgIncludiCompletati} eventsMassiveSignature={eventsMassiveSignature} loadingEvents={loadingEvents} currentEventId={currentEventId}
                                 detailOpened={detailOpened} viewEventDetail={viewEventDetail} closeDetail={closeDetail} filters={filters} setFilters={setFilters} eventsCountMassiveSignature={eventsCountMassiveSignature} downloadPDF={downloadPDF}/>
        default:
            return <Navigate to='/external/received/' />

    }
}
export default ManageBodyExternal;
