import {Fragment, useState} from "react";
import {Dialog, Transition} from "@headlessui/react";
import Button from "../../../atoms/Buttons/Button/Button";
import {useTranslation} from "react-i18next";
import Input from "../../../atoms/Inputs/Input/Input";
import {FULL} from "../../../atoms/Buttons/sizes";
import isEmpty from "lodash/isEmpty";

const UpdateDeviceModal = ({open, setOpen, onConfirm, initialValue}) => {
    const {t} = useTranslation()
    const [deviceName, setDeviceName] = useState(initialValue)
    const disabled = isEmpty(deviceName)

    const onClick = () => {
        if(!disabled) {
            onConfirm(deviceName)
            setOpen(false)
        }
    }

    return(
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" open={open} onClose={setOpen}>
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className={`inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all my-16 sm:align-middle w-full sm:max-w-sm`}>
                            <div className="p-6 space-y-6">
                                <h1 className="text-xl font-bold leading-6 text-gray-900">{t('settings.deviceName')}</h1>
                                <div className='w-full flex-col flex justify-center items-stretch'>
                                    <Input value={deviceName} setValue={setDeviceName}/>
                                </div>
                                <div className='flex gap-x-3'>
                                    <Button variant='blue' label='OK' size={FULL} onClick={onClick} disabled={disabled} />
                                </div>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default UpdateDeviceModal;
