//Processi doc
import {createAsyncThunk} from "@reduxjs/toolkit";
import {
    addDeviceCall,
    addEventFlowActivityToWorkspaceCall, callGetRemoteSignatureUser, checkUploadEventFlowTypeCall,
    copyEventFlowType,
    copyForm,
    copyInformedPath,
    countListDevices,
    countListEventFlowTypes,
    countListForms,
    countListInformedPaths,
    countListModels,
    deleteDeviceCall,
    deleteEventFlowType,
    deleteForm,
    deleteInformedPath,
    deleteSentEventCall,
    doChangeFeqPin,
    doEnableSignature,
    doRemoteMassiveSignatureCall,
    downloadArchieveZip,
    downloadPDF, downloadPDFSummary, generateEventFlowTypeCall,
    getWaitingEventDetailsCall,
    listArchieve,
    listCanceledEvents,
    listDevices,
    listEventFlowTypes,
    listEventsMassiveSignature,
    listForms,
    listInformedPaths,
    listModels,
    listOrganizationEvents,
    listOrganizationEventsDetails,
    listOtherUserSignatures,
    listSentEvents,
    listSentEventsDetails,
    listUserSignatures,
    listWaitingEvents,
    removeEventFlowActivityFromWorkspaceCall, requestOtpFeqCall,
    resendEmailFromEventCall, restoreCanceledEventCall, retrievePiPCall,
    sendToTabletCall,
    updateDeviceCall,
    updateProcessPublished,
    uploadEventFlowTypeCall
} from "../../api/library";

export const getEventFlowTypes = createAsyncThunk('library/getEventFlowTypes', async (props) => {
    const response = await listEventFlowTypes(props);
    return response.data
});

export const countEventFlowTypes = createAsyncThunk('library/countEventFlowTypes', async (props) => {
    const response = await countListEventFlowTypes(props);
    return response.data
});

export const delEventFlowType = createAsyncThunk('library/delEventFlowType', async ({id}) => {
    const response = await deleteEventFlowType(id);
    return response.data
});

export const copyEventFlowTypeThunk = createAsyncThunk('library/copyEventFlowTypeThunk', async ({id}) => {
    const response = await copyEventFlowType(id);
    return response.data
});

export const copyInformedPathThunk = createAsyncThunk('library/copyInformedPathThunk', async ({id}) => {
    const response = await copyInformedPath(id);
    return response.data
});

export const copyFormThunk = createAsyncThunk('library/copyFormThunk', async ({id}) => {
    const response = await copyForm(id);
    return response.data
});

export const updateProcessPublishedThunk = createAsyncThunk('library/updateProcessPublishedThunk', async (props) => {
    const response = await updateProcessPublished(props);
    return response.data
});

export const uploadEventFlowTypeThunk = createAsyncThunk(
    'library/uploadEventFlowTypeThunk',
    async ({file, name}) => {
        const response = await uploadEventFlowTypeCall(file, name);
        return response.data
    }
);

export const generateEventFlowTypeThunk = createAsyncThunk(
    'library/generateEventFlowTypeThunk',
    async ({file, name}) => {
        const response = await generateEventFlowTypeCall(file, name);
        return response.data
    }
);

export const checkUploadEventFlowTypeThunk = createAsyncThunk(
    'library/checkUploadEventFlowTypeThunk',
    async ({file}) => {
        const response = await checkUploadEventFlowTypeCall(file);
        return response.data
    }
);


//Informative
export const getInformedPaths = createAsyncThunk('library/getInformedPaths', async (props) => {
    const response = await listInformedPaths(props);
    return response.data
});

export const countInformedPaths = createAsyncThunk('library/countInformedPaths', async (props) => {
    const response = await countListInformedPaths(props);
    return response.data
});

export const delInformedPath = createAsyncThunk('library/delInformedPath', async (props) => {
    const response = await deleteInformedPath(props);
    return response.data
});

//Form
export const getForms = createAsyncThunk('library/getForms', async (props) => {
    const response = await listForms(props);
    return response.data
});

export const countForms = createAsyncThunk('library/countForms', async (props) => {
    const response = await countListForms(props);
    return response.data
});

export const delForm = createAsyncThunk('library/delForm', async (props) => {
    const response = await deleteForm(props);
    return response.data
});

//Modelli
export const getModels = createAsyncThunk('library/getModels', async (props) => {
    const response = await listModels(props);
    return response.data
});

export const countModels = createAsyncThunk('library/countModels', async (props) => {
    const response = await countListModels(props);
    return response.data
});

//Doc inviati
export const getSentEvents = createAsyncThunk('library/getSentEvents', async (props) => {
    const response = await listSentEvents(props);
    return response.data
});

export const getArchieve = createAsyncThunk('library/getArchieve', async (props) => {
    const response = await listArchieve(props);
    return response.data
});

export const downloadArchieve = createAsyncThunk('library/downloadArchieve', async ({documentId}) => {
    const response = await downloadArchieveZip(documentId);
    return response.data
});

export const getSentEventsDetailsThunk = createAsyncThunk('library/getSentEventsDetails', async ({id}) => {
    const response = await listSentEventsDetails(id);
    return {data: response.data, id}
});

export const getCanceledEventsDetailsThunk = createAsyncThunk('library/getCanceledEventsDetailsThunk', async ({id}) => {
    const response = await listSentEventsDetails(id);
    return {data: response.data, id}
});

export const getOrganizationEventsDetails = createAsyncThunk('library/getOrganizationEventsDetails', async ({id}) => {
    const response = await listOrganizationEventsDetails(id);
    return {data: response.data, id}
});

//Doc Organization
export const getOrganizationEvents = createAsyncThunk('library/getOrganizationEvents', async (props) => {
    const response = await listOrganizationEvents(props);
    return response.data
});

export const getCanceledEventsDetails = createAsyncThunk('library/getCanceledEventsDetails', async ({id}) => {
    const response = await listSentEventsDetails(id);
    return {data: response.data, id}
});

//Doc canceled
export const getCanceledEvents = createAsyncThunk('library/getCanceledEvents', async (props) => {
    const response = await listCanceledEvents(props);
    return response.data
});

export const deleteSentEventThunk = createAsyncThunk('library/deleteSentEventThunk', async ({eventId}) => {
    if(eventId) {
        const response = await deleteSentEventCall(eventId);
        return response.data
    }
});

export const restoreDeletedEventThunk = createAsyncThunk('library/restoreDeletedEventThunk', async ({eventFlowActivityId, sendEmail}) => {
    if(eventFlowActivityId) await restoreCanceledEventCall(eventFlowActivityId, sendEmail);
});

//Doc in attesa
export const getWaitingEvents = createAsyncThunk('library/getWaitingEvents', async (props) => {
    const response = await listWaitingEvents(props);
    return response.data
});

export const getWaitingEventDetailsThunk = createAsyncThunk('library/getWaitingEventDetails', async ({id}) => {
    const response = await getWaitingEventDetailsCall(id);
    return {data: response.data, id}
});

//Firma massiva
export const getEventsMassiveSignature = createAsyncThunk('library/getEventsMassiveSignature', async (props) => {
    const response = await listEventsMassiveSignature(props);
    return response.data
});

export const getMassiveSignatureDetails = createAsyncThunk('library/getMassiveSignatureDetails', async ({id}) => {
    const response = await getWaitingEventDetailsCall(id);
    return {data: response.data, id}
});

export const downloadEventPDF = createAsyncThunk('library/downloadEventPDF', async ({url}) => {
    const response = await downloadPDF(url);
    return response.data
});

export const downloadEventPDFSummary = createAsyncThunk('library/downloadEventPDFSummary', async ({url}) => {
    const response = await downloadPDFSummary(url);
    return response.data
});


export const downloadOrganizationEventPDF = createAsyncThunk('library/downloadEventPDF', async ({id}, {getState, dispatch}) => {
    await dispatch(getOrganizationEventsDetails({id}))
    const selectedEvent = getState()?.library?.organizationEvents?.find(e => e.id === id)
    const response = await downloadPDF(selectedEvent?.downloadPdfUrl);
    return response.data
});

//tablet (devices)
export const getDevices = createAsyncThunk('library/getDevices', async (props) => {
    const response = await listDevices(props?.offset ?? 0, props?.limit, props?.txtFilter);
    return response.data
});

export const countDevices = createAsyncThunk('library/countDevices', async (props) => {
    const response = await countListDevices(props);
    return response.data
});

//signatures
export const getUserSignatures = createAsyncThunk('library/getUserSignatures', async (props) => {
    const response = await listUserSignatures(props);
    return response.data
});

export const getOtherUserSignatures = createAsyncThunk('library/getOtherUserSignatures', async (props) => {
    const response = await listOtherUserSignatures(props);
    return response.data
});

export const enableSignature = createAsyncThunk('library/enableSignature', async (props) => {
    const response = await doEnableSignature(props);
    return response.data
});

export const getRemoteSignatureUser = createAsyncThunk('library/getRemoteSignatureUser', async (props) => {
    const response = await callGetRemoteSignatureUser(props);
    return response.data
});

export const changeFeqPin = createAsyncThunk('library/changeFeqPin', async (props) => {
    const response = await doChangeFeqPin(props);
    return response.data
});

export const sendToTabletThunk = createAsyncThunk('library/sendToTabletThunk', async ({eventId, deviceId}) => {
    const response = await sendToTabletCall(eventId, deviceId);
    return response.data
});

export const doRemoteMassiveSignatureThunk = createAsyncThunk('library/doRemoteMassiveSignatureThunk', async ({eventIds, pin, otp, deviceId},  { dispatch, getState }) => {
    const {pIp} = getState().library;
    const response = await doRemoteMassiveSignatureCall(eventIds, pin, pIp, otp, deviceId);
    return response.data
});

export const requestOtpFeqThunk = createAsyncThunk('library/requestOtpFeqThunk', async ({eventIds, pin},  { dispatch, getState }) => {
    const response = await requestOtpFeqCall(pin);
    return response.data
});


export const addDeviceThunk = createAsyncThunk('library/addDeviceThunk', async ({label}) => {
    const response = await addDeviceCall(label);
    const blob = new Blob(
        [response.data],
        { type: response.headers['content-type'] }
    )
    return URL.createObjectURL(blob)
});

export const deleteDeviceThunk = createAsyncThunk('library/deleteDeviceThunk', async ({deviceId}, { dispatch}) => {
    const response = await deleteDeviceCall(deviceId);
    if(response.status < 300)
        dispatch(getDevices())
});

export const updateDeviceThunk = createAsyncThunk('library/updateDeviceThunk', async ({deviceId, label}) => {
    const response = await updateDeviceCall(deviceId, label);
    return response.data
});

export const addEventFlowActivityToWorkspaceThunk = createAsyncThunk(
    'library/addEventActivityToWorkspaceThunk',
    async ({workspaceId, eventFlowActivityId}) => {
        await addEventFlowActivityToWorkspaceCall(workspaceId, eventFlowActivityId);
        return {workspaceId, eventFlowActivityId}
    }
);

export const removeEventFlowActivityFromWorkspaceThunk = createAsyncThunk(
    'library/removeEventFlowActivityFromWorkspaceThunk',
    async ({workspaceId, eventFlowActivityId}) => {
        await removeEventFlowActivityFromWorkspaceCall(workspaceId, eventFlowActivityId);
        return({workspaceId, eventFlowActivityId})
    }
);

export const resendEmailFromEventThunk = createAsyncThunk(
    'library/resendEmailFromEventThunk',
    async ({eventId}) => {
        const response = await resendEmailFromEventCall(eventId);
        return response.data
    }
);

export const retrievePiPThunk = createAsyncThunk(
    'library/retrievePiPThunk',
    async(props, { dispatch, getState }) => {
        try{
            const response = await retrievePiPCall()
            return response.data
        }catch (e){
            console.error('Error retrievePiPThunk:', e);
        }
    }
)
