import Input from "../../../atoms/Inputs/Input/Input";
import {useEffect, useState} from "react";
import "./InputProgressNumber.css"


const InputProgressNumber = ({progressNumber, setProgressNumber}) => {
    const [tempProgressNumber, setTempProgressNumber] = useState(parseInt(progressNumber))

    useEffect(() => {
        setTempProgressNumber(parseInt(progressNumber))
    },[progressNumber])


    return <Input type='number' value={tempProgressNumber} setValue={setTempProgressNumber} onBlur={() => setProgressNumber(parseInt(tempProgressNumber))} />

}
export default InputProgressNumber;
