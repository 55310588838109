import {useDispatch} from "react-redux";
import React from "react";
import SimpleTable from "../../molecules/Tables/SimpleTable/SimpleTable";
import {useTranslation} from "react-i18next";
import {
    ARCHIEVE_STATUS,
    ICON_BUTTON,
    ICON_BUTTON_LIST,
    TIMESTAMP
} from "../../atoms/SimpleTableElement/SimpleTable.types";
import {downloadArchieve} from "../../../features/library/library.action";


const ArchieveList = ({data, loading, filters, setFilters}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const callDownloadArchieve = (documentId) => {
        dispatch(downloadArchieve({documentId}));
    }
    const isHiddenDownload = row => (!row.status || row.status !== 'PROCESSED')


    const columns = [
        {field: 'documentId', label: 'Doc ID', hidden: false},
        {field: 'personRef', label: t('archieve.refPerson'), hidden: false},
        {field: 'documentSize', label: 'Size', hidden: false},
        {field: 'status', type: ARCHIEVE_STATUS, label: 'Status', hidden: false},
        {field: 'dateIns', type: TIMESTAMP, label: t('archieve.dateIns'), hidden: false},
        {field: 'dateElab', type: TIMESTAMP, label: t('archieve.dateElab'), hidden: false},
        {
            field: 'actions', type: ICON_BUTTON_LIST, hidden: true, buttons: [
                {
                    field: 'Download',
                    id: 'Download',
                    label: t('tableColumns.download'),
                    hidden: true,
                    hideColumnContent: (isHiddenDownload),
                    type: ICON_BUTTON,
                    onClick: (d) => callDownloadArchieve(d.documentId),
                    iconType: 'download'
                },
            ]
        }
    ]


    return (
        <div className="flex flex-col flex-1">
            <SimpleTable data={data} columns={columns} loading={loading} filters={filters} setFilters={setFilters}/>
        </div>
    )
}

export default ArchieveList;
