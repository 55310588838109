import React from "react";
import isObject from "lodash/isObject";
import Icon from "../../atoms/Icon/Icon";

const DettaglioDatiImmessiEvento = ({data, closeAction}) => {
    return(
        <div>
            <div className="" aria-hidden="true" >
                <div className="flex items-center my-4">{
                    (closeAction) ? <Icon type='back' onClick={closeAction}/> : ''} &nbsp; {data?.title ?? 'Document'}
                </div>
            </div>
            <div className="m-2">
                <ul className="divide-y divide-gray-200">
                    {data?.form?.map((f, idx) => {
                            const value = isObject(f.value) ? Object.keys(f.value).map(k => `${k}: ${f.value[k] ? 'V' : 'F'}`).join(', ') : f.value
                            return (
                                <li key={f.label + idx} className="flex py-2">
                                    <div className="ml-3">
                                        <p className="text-sm font-medium text-gray-900">{f.label}</p>
                                        <p className="text-sm text-gray-500">{value}</p>
                                    </div>
                                </li>
                            )
                        }) ??
                        <p className="font-medium my-4">Nessun dato inserito</p>}
                </ul>
            </div>
        </div>
    )
}

export default DettaglioDatiImmessiEvento;
