import {v4 as uuid} from 'uuid'

const InputWithLeadingIcon = ({label, placeholder, icon, value = '', setValue}) => {
    const id = uuid()
    return (
        <div className='w-full'>
            {label && <label htmlFor={`input-${id}`} className="block text-sm font-medium text-gray-700">
                {label}
            </label>}
            <form>
                <div className="mt-1 relative rounded-md shadow-sm w-full">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        {icon}
                    </div>
                    <input
                        onChange={(e)=> setValue(e.target.value)}
                        type="text"
                        id={`input-${id}`}
                        name={`input-${id}`}
                        className="focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md w-full"
                        placeholder={placeholder}
                        autoComplete={`input-${id}`}
                        value={value}
                    />
                </div>
            </form>

        </div>
    )
}
export default InputWithLeadingIcon;
