import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import DevicesList from "../../../../organisms/DevicesList/DevicesList";
import ListMenu from "../../../../molecules/ListMenu/ListMenu";
import {FULL} from "../../../../atoms/Buttons/sizes";
import {PlusIcon} from "@heroicons/react/24/solid";
import IconButton from "../../../../atoms/Buttons/IconButton/IconButton";
import AddDeviceModal from "../../../../molecules/Modals/AddDeviceModal/AddDeviceModal";
import {useTranslation} from "react-i18next";
import GenericQuestionModal from "../../../../molecules/Modals/GenericModal/GenericQuestionModal";
import UpdateDeviceModal from "../../../../molecules/Modals/UpdateDeviceModal/UpdateDeviceModal";
import {librarySelector, setQrCode} from "../../../../../features/library/library.slice";
import {
    addDeviceThunk,
    countDevices,
    deleteDeviceThunk,
    getDevices, updateDeviceThunk
} from "../../../../../features/library/library.action";
import SignatureBalanceList from "../../../../organisms/SignatureBalanceList/SignatureBalanceList";
import {getSignatureTypesListThunk} from "../../../../../features/recipients/recipients.actions";
import {recipientsSelector} from "../../../../../features/recipients/recipients.slice";

const SignatureBalance = () => {
    const dispatch = useDispatch();
    const {t} = useTranslation()
    const {signatureTypesList} = useSelector(recipientsSelector)


    useEffect(() => {
        const callbackTimer = setTimeout(updateEvents, 300);
        return () => clearTimeout(callbackTimer);
    }, [])

    const updateEvents = () => {
        dispatch(getSignatureTypesListThunk())
    }

    return(
        <div className="flex flex-col flex-1 my-2">
            <SignatureBalanceList data={(signatureTypesList || [])}  />
        </div>
    )
}

export default SignatureBalance;
