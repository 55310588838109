import PageWithHeader from "../Templates/PageWithHeader/PageWithHeader";
import VerticalNavigation from "../../molecules/VerticalNavigation/VerticalNavigation";
import {Navigate, useLocation, useParams} from "react-router-dom";
import React, {useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {navigationSelector, setSelectedTabName} from "../../../features/navigation/navigation.slice";
import ManageBody from "../../organisms/ManageBody/ManageBody";
import {userHomeSelector} from "../../../features/userHome/userHome.slice";
import {getAvailableWorkspacesThunk} from "../../../features/userHome/userHome.actions";
import isEmpty from "lodash/isEmpty";
import {WORKSPACES} from "../../../utils/permissions";

const ManagePage = () => {
    const {section, workspaceId} = useParams()
    const dispatch = useDispatch();
    const {manageSections} = useSelector(navigationSelector)
    const {availableWorkspaces, userOrganizations, selectedTenantId} = useSelector(userHomeSelector)
    const permissions = userOrganizations?.[selectedTenantId]?.permissions ?? {}
    const { search } = useLocation();
    const queryUrlParams = useMemo(() => new URLSearchParams(search), [search]);
    const [orgSection, setOrgSection] = useState([])
    const getAvailableWorkspaces = () => dispatch(getAvailableWorkspacesThunk())

    useEffect(() => {
        dispatch(setSelectedTabName('Gestisci'))
        getAvailableWorkspaces()
    },[])

    useEffect(() => {
        if(permissions?.[WORKSPACES])
            setOrgSection(availableWorkspaces?.map(w => {
                return {name: w?.name, href: `organization/${w?.id}`}
            }) ?? [])
    },[availableWorkspaces])

    if(!section)
        return <Navigate to='/gestisci/received' />
    else return(
        <PageWithHeader>
            <div className='flex w-full justify-between py-6'>
                <div className='divide-y-2'>
                    <div className='pt-1 pb-2'>
                        <VerticalNavigation navigation={manageSections} section={section} baseurl='gestisci' />
                    </div>
                    {!isEmpty(orgSection) && permissions?.[WORKSPACES] && <div className='py-2'>
                        <VerticalNavigation navigation={orgSection}
                                            section={`${section}${workspaceId ? '/' + workspaceId : ''}`}
                                            baseurl='gestisci'/>
                    </div>}
                </div>

                <div className='flex w-full overflow-hidden'>
                    <ManageBody section={section} completed={queryUrlParams.get('completed')} />
                </div>

            </div>
        </PageWithHeader>
    )
}

export default ManagePage;
