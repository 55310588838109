import Input from "../../../atoms/Inputs/Input/Input";
import {Dialog, Transition} from "@headlessui/react";
import {Fragment, useEffect, useState} from "react";
import Button from "../../../atoms/Buttons/Button/Button";
import {useDispatch, useSelector} from "react-redux";
import {authSelector} from "../../../../features/auth/auth.slice";
import {ExclamationCircleIcon} from "@heroicons/react/24/outline";
import {t} from "i18next";
import {retrieveSignerProfileThunk} from "../../../../features/auth/auth.actions";
import Select from "../../../atoms/Select/Select";

const ModalPinRequest = ({open, setOpen, type, confirm, otpFeqVisible = false, pendingRequest = false, requestOtp, feqHint='', otpRequestEnabled=true}) => {
    const [pin, setPin] = useState('')
    const [otp, setOtp] = useState('')
    const [deviceId, setDeviceId] = useState('')
    const {user, remoteSignerProfile} = useSelector(authSelector)
    const dispatch = useDispatch();

    useEffect(() => {
        if (!remoteSignerProfile && type === 'FEQ') {
            dispatch(retrieveSignerProfileThunk({}));
        }
    },[])

    switch (type){
        /*case 'FEA_ONE_SHOT':
            return <Modal
                open={open}
                setOpen={setOpen}
                title='Codice OTP'
                subtitle={`Per confermare la tua identità inserisci il codice che hai ricevuto ${mobilePhone ? 'al numero che finisce per '+mobilePhone.substring(mobilePhone.length-4, mobilePhone.length) : ''}`}
                footer={false}
                size='small'
            >
                <Input label='Inserisci OTP' value={pin} setValue={setPin} />
                <p className='font-medium text-blue-600 hover:text-blue-500 cursor-pointer' onClick={resendOtp}>Non hai ricevuto il codice? Reinvia il codice</p>
                <div className='flex flex-col flex-col-reverse sm:flex-row gap-x-3 gap-y-3 pt-3'>
                    <Button variant='white' label='Annulla' size='full' onClick={() => setOpen(false)}/>
                    <Button variant='blue' label='Conferma' size='full' onClick={() => dispatch(SignDocumentFeaConfirm({eventId, otpValue: pin}))}/>
                </div>
            </Modal>*/
        case 'FEQ':
            return <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" open={open} onClose={setOpen}>
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &#8203;
                    </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <div className={`inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all my-16 sm:align-middle w-full sm:max-w-sm`}>
                                <div className="p-6 space-y-6">
                                    <h1 className="font-bold leading-6 text-gray-900">{feqHint || 'Inserisci il tuo Codice Pin per firmare i documenti'}</h1>
                                    {/*{subtitle && <p className="text-sm leading-5 font-normal text-gray-500">{subtitle}</p>}*/}
                                    <Input type='password' value={pin} setValue={setPin} autoComplete='off' />
                                    {otpRequestEnabled && user?.hasOtpFeq && remoteSignerProfile?.hasDeviceId && remoteSignerProfile?.devices?.length > 0 &&
                                        <div className={'mt-4'}>
                                            <Select label='Dispositivo su cui ricevere OTP' value={deviceId} setValue={setDeviceId}
                                                    options={(remoteSignerProfile?.devices || []).map(d => ({label: (d.description ?? '') + ' [' + d.deviceId + ']', value: d.deviceId}))} />
                                        </div>
                                    }
                                    {pendingRequest ?
                                        <p className='text-yellow-500 flex items-center'><ExclamationCircleIcon className='w-5 h-5 mr-2'/>{t('feq.waitOtp')}</p>:
                                        <></>
                                    }
                                    {otpFeqVisible && <Input type='text' label={'OTP'} value={otp} setValue={setOtp} autoComplete='off' />}
                                    <div className='flex gap-x-3'>
                                        <Button variant='white' label='Annulla' size='full' onClick={() => setOpen(false)}/>
                                        {/*<Button variant='blue' label='Conferma' size='full' onClick={() => confirm(pin)}/>*/}
                                        {(!otpRequestEnabled || !user?.hasOtpFeq || otpFeqVisible) && <Button variant='blue' label='Conferma' size='full' onClick={() => confirm(pin, otp, deviceId)}/>}
                                        {otpRequestEnabled && (user?.hasOtpFeq && !otpFeqVisible) && <Button variant='blue' disabled={pendingRequest} label='Richiedi OTP' size='full' onClick={() => requestOtp(pin)}/>}
                                    </div>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>

        default:
            return <div>Signature Type: {type}</div>
    }
}

export default ModalPinRequest;
