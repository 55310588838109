import {Navigate, useNavigate, useParams} from "react-router-dom";
import PageWithStepsHeaderAndFooter from "../Templates/PageWithStepsHeaderAndFooter/PageWithStepsHeaderAndFooter";
import Informativa from "../ProcessSteps/Informativa/Informativa";
import DataCollect from "../ProcessSteps/DataCollect/DataCollect";
import ReorderElements from "../ProcessSteps/ReorderElements/ReorderElements";
import DocumentStep from "../ProcessSteps/DocumentStep/DocumentStep";
import Summary from "../ProcessSteps/Summary/Summary";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {
    getProcessDetailsThunk,
    saveModelsThunk,
    upsertProcessThunk
} from "../../../features/documentalProcess/documentalProcess.actions";
import {
    documentalProcessSelector,
    nextPage,
    resetState as resetDocumentalProcess,
    selectWorkspace,
    setMessageBody,
    setMessageSubject,
    setReminderIntervalDays,
    setSenderReminderComplete,
    setAvoidLtArchive,
    setStepsByProcessType
} from "../../../features/documentalProcess/documentalProcess.slice";
import {
    documentBuilderSelector,
    resetState as resetDocumentBuilder
} from "../../../features/documentBuilder/documentBuilder.slice";
import {
    recipientsSelector,
    resetState as resetRecipients,
    setRedirectLink,
    setSendNoEmail,
    setSendNoEmailSigned
} from "../../../features/recipients/recipients.slice";
import {addRecipientsThunk, getRecipientsThunk} from "../../../features/recipients/recipients.actions";
import PremiumMessage from "../../organisms/PremiumMessage/PremiumMessage";
import isEmpty from "lodash/isEmpty";
import Intro from "../ProcessSteps/Intro/Intro";
import Onboarding from "../ProcessSteps/Onboarding/Onboarding";
import RecipientsPage from "../ProcessSteps/RecipientsPage/RecipientsPage";
import {getDocumentDetailsThunk, getPlaceholdersThunk} from "../../../features/documentBuilder/documentBuilder.actions";
import success from "../../../assets/illustrations/2_Illustrazione_Confirmo_Successo.svg";
import confirmoLogo from "../../../assets/logos/confirmo-logo.svg";
import Button from "../../atoms/Buttons/Button/Button";
import {FULL} from "../../atoms/Buttons/sizes";
import {useTranslation} from "react-i18next";
import {
    addTagThunk,
    deleteTagThunk,
    getAvailableWorkspacesThunk,
    getTags
} from "../../../features/userHome/userHome.actions";
import {getGraphConsentsThunk} from "../../../features/docModels/docModels.actions";
import {docModelsSelector} from "../../../features/docModels/docModels.slice";
import {userHomeSelector} from "../../../features/userHome/userHome.slice";
import {WORKSPACES} from "../../../utils/permissions";

const data = [
    {
        subtitle: 'recipients.title',
        text: 'recipients.subtitle'
    },
    {
        subtitle: 'informedPath.title',
        text: 'informedPath.subtitle'
    },
    {
        subtitle: 'forms.title',
        text: 'forms.subtitle'
    },{
        subtitle: 'reorder.title',
        text: 'reorder.introMessage'
    },{
        subtitle: 'document.title',
        text: 'document.subtitle'
    }
]

const NewProcessTemplate = () => {
    const {step, id, elementId} = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {t} = useTranslation()
    let homeUrl = '/home'

    const { newDocument, informative, dataCollections, stepsTypes, errorVideo, imageLoading, steps, messageBody, messageSubject, reminderIntervalDays, isLoading, selectedWorkspaces, senderReminderComplete, avoidLtArchive } = useSelector(documentalProcessSelector)
    const { recipients, sender, signatureTypesList } = useSelector(recipientsSelector);
    const { documentInfo } = useSelector(documentBuilderSelector)
    const { graphConsents } = useSelector(docModelsSelector)
    const {availableWorkspaces, userOrganizations, selectedTenantId} = useSelector(userHomeSelector)
    const permissions = userOrganizations?.[selectedTenantId]?.permissions ?? {}

    const [orderedRecipients, setOrderedRecipients] = useState(recipients)
    const [loadingInfo, setLoadingInfo] = useState(false)

    const processId = newDocument?.id ?? id
    const buildUrl = (baseUrl) => `${baseUrl}${processId ? '/'+processId : ''}`

    useEffect(() => {
        setOrderedRecipients(recipients)
    },[recipients])

    useEffect(() => {
        getInfo()
    }, [])

    const getAvailableWorkspaces = () => dispatch(getAvailableWorkspacesThunk())
    const getInfo = async () => {
        setLoadingInfo(true)
        try {
            dispatch(getGraphConsentsThunk({onlyActive: true}))
            getAvailableWorkspaces()
            if(id) {
                homeUrl = '/libreria/eventflows'
                dispatch(setStepsByProcessType({completed:true}))
                await dispatch(getProcessDetailsThunk({id})).unwrap()
                await dispatch(getRecipientsThunk()).unwrap()
                await dispatch(getDocumentDetailsThunk()).unwrap()
                await dispatch(getPlaceholdersThunk()).unwrap()
                await dispatch(getTags())
            }
        }finally{
            setLoadingInfo(false)
        }

    }

    const createDocument = async (name, description, published, tags) => {
        const selectedTags = tags?.map(tag => {return {id: tag.id}})
        await dispatch(upsertProcessThunk({name, description, published, tags: selectedTags, processId})).unwrap()
        next();
    }

    const confirmRecipients = async (isOrdered = false) => {
        await dispatch(addRecipientsThunk({recipients: orderedRecipients, isOrdered})).unwrap()
        next();
    }

    const saveModels = async () => {
        await dispatch(saveModelsThunk({messageSubject: messageSubject, messageBody: messageBody, workspaces: selectedWorkspaces})).unwrap()
        next();
    }

    const goBackHome= () => navigate(homeUrl)

    const next = () => {
        const actualId = steps.findIndex(s => s.urlName === step);
        if (actualId < steps.length - 1) {
            navigate(buildUrl(steps[actualId + 1].href))
            dispatch(nextPage({step}))
        }
    }

    const previous = () => {
        const actualId = steps.findIndex(s => s.urlName === step);
        if (actualId > 0) navigate(buildUrl(steps[actualId - 1].href))
        else navigate('/home')
    }

    const insertTag = (tag) => dispatch(addTagThunk({tag}))
    const deleteTag = (tag) => dispatch(deleteTagThunk({tag}))

    const setNoEmailSent = (id, noEmail) => dispatch(setSendNoEmail({id, value: noEmail}))
    const setNoEmailSignedSent = (id, noEmailSigned) => dispatch(setSendNoEmailSigned({id, value: noEmailSigned}))

    const onSetRedirectLink = (recipientId, redirectLink) => dispatch(setRedirectLink({id: recipientId, redirectLink}))
    const setSelectedWorkspaces = (workspace) => dispatch(selectWorkspace(workspace))

    switch (step) {
        case 'intro':
            return (
                <Intro
                    initialName={newDocument?.name ?? ''}
                    initialDescription={newDocument?.description ?? ''}
                    initialPublished={newDocument?.published ?? ''}
                    identifier={newDocument?.identifier ?? ''}
                    initialTags={newDocument?.tags ?? []}
                    next={createDocument}
                    previous={previous}
                    goBackHome={goBackHome}
                    insertTag={insertTag}
                    deleteTag={deleteTag}
                    hasDelete={true}
                    loadingInfo={loadingInfo}
                />
            )
        case 'onboarding':
            return (
                <Onboarding goBackHome={goBackHome} next={next} previous={previous} data={data} buildUrl={buildUrl}/>
            )
        case 'recipients':
            return(
                <RecipientsPage
                    goBackHome={goBackHome}
                    signatureTypesList={signatureTypesList}
                    next={confirmRecipients}
                    disableConfirm={isEmpty(orderedRecipients) || Object.values(orderedRecipients).some(rec => isEmpty(rec.name) || isEmpty(rec.signatureType))}
                    previous={previous}
                    steps={steps}
                    orderedRecipients={orderedRecipients}
                    setOrderedRecipients={setOrderedRecipients}
                    graphTemplatesOptions={graphConsents?.map(gc=>({value: gc.id, label: gc.name})) ?? []}
                    buildUrl={buildUrl}
                />
            )
        case 'informativaintro':
            return(
                <PageWithStepsHeaderAndFooter background={true} footer={false} steps={steps} buildUrl={buildUrl}>
                    <PremiumMessage
                        next={() => navigate('/newprocess/informativa')}
                        title={t('informedPath.title')}
                        message={t('informedPath.introMessage')}
                    />
                </PageWithStepsHeaderAndFooter>
            )
        case 'informativa':
            return (
                <Informativa
                    infoId={elementId}
                    informative={informative}
                    stepsTypes={stepsTypes}
                    recipients={recipients}
                    next={next}
                    previous={previous}
                    errorVideo={errorVideo}
                    imageLoading={imageLoading}
                    steps={steps}
                    isLoading={isLoading}
                    buildUrl={buildUrl}
                />
            )
        case 'datacollectintro':
            return(
                <PageWithStepsHeaderAndFooter background={true} footer={false} closeAction={goBackHome} steps={steps} buildUrl={buildUrl}>
                    <PremiumMessage
                        next={() => navigate('/newprocess/datacollect')}
                        title={t('forms.title')}
                        message={t('forms.introMessage')}
                    />
                </PageWithStepsHeaderAndFooter>
            )
        case 'datacollect':
            return (
                <DataCollect
                    dataCollectId={elementId}
                    dataCollections={dataCollections}
                    recipients={recipients}
                    sender={sender}
                    next={next}
                    previous={previous}
                    steps={steps}
                    isLoading={isLoading}
                    buildUrl={buildUrl}
                />
            )
        case 'orderinformativa':
            return (
                <ReorderElements
                    next={next}
                    previous={previous}
                    steps={steps}
                    buildUrl={buildUrl}
                />
            )
        case 'document':
            return (
                <DocumentStep
                    next={next}
                    previous={previous}
                    steps={steps}
                    buildUrl={buildUrl}
                    isProcess={true}
                />
            )
        case 'summary':
            return(
                <Summary
                    next={saveModels}
                    previous={previous}
                    recipients={recipients}
                    informative={Object.values(informative).map(info => {return{...info, url:`/newprocess/informativa/${newDocument?.id}/${info.id}`}})}
                    dataCollections={Object.values(dataCollections).map(form => {return{...form, url:`/newprocess/datacollect/${newDocument?.id}/${form.id}`}})}
                    steps={steps}
                    messageBody={messageBody}
                    messageSubject={messageSubject}
                    reminderInterval={reminderIntervalDays}
                    setMessageBody={(value) => dispatch(setMessageBody(value))}
                    setMessageSubject={(value) => dispatch(setMessageSubject(value))}
                    setReminderInterval={(value) => dispatch(setReminderIntervalDays(value))}
                    filename={documentInfo.filename}
                    setRedirectLink={onSetRedirectLink}
                    setNoEmailSent={setNoEmailSent}
                    setNoEmailSignedSent={setNoEmailSignedSent}
                    availableWorkspaces={availableWorkspaces}
                    selectedWorkspaces={selectedWorkspaces}
                    setSelectedWorkspaces={setSelectedWorkspaces}
                    hasWorkspacesPermissions={permissions[WORKSPACES]}
                    setSenderReminderComplete={(value) => dispatch(setSenderReminderComplete(value))}
                    senderReminderComplete={senderReminderComplete}
                    setAvoidLtArchive={(value) => dispatch(setAvoidLtArchive(value))}
                    avoidLtArchive={avoidLtArchive}
                    editAvoidLtArchiveChoice={true}
                    buildUrl={buildUrl}
                />
            )
        case 'end':
            return(
                <div className='flex w-full h-screen flex-col items-center bg-background bg-no-repeat bg-right-top bg-50% bg-scroll'>
                    <div className='flex flex-col justify-center max-w-xl flex-1 my-10'>
                        <img alt='success' src={success}/>
                        <div className='flex items-center mb-3 justify-center'>
                            <h1 className='text-2xl leading-8 font-medium pr-1'>{t('end.confirmoThanks')}</h1>
                            <img src={confirmoLogo} alt='confirmo' className='h-8 mb-1 ml-1' />
                        </div>
                        <div className='my-3'>
                            <h3 className='text-lg leading-7 font-medium text-center'>{t('end.processCreated')}</h3>
                            <p className='text-base leading-6 font-normal text-gray-500'>{t('end.processCreatedSubtitle')}</p>
                        </div>
                        <div className='my-3 mx-6'>
                            <Button label={t('backHome')} size={FULL} onClick={() => navigate('/home')}/>
                        </div>
                    </div>
                </div>
            )
        default:
            dispatch(resetDocumentalProcess())
            dispatch(resetDocumentBuilder())
            dispatch(resetRecipients())
            return <Navigate to='/newprocess/intro' />

    }
}
export default NewProcessTemplate;
