import Input from "../../../atoms/Inputs/Input/Input";
import Textarea from "../../../atoms/TextArea/Textarea";
import SelectWithBadge from "../../../molecules/AdvancedSelect/SelectWithBadge/SelectWithBadge";
import PageWithTitleAndFooter from "../../Templates/PageWithTitleAndFooter/PageWithTitleAndFooter";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import isEmpty from "lodash/isEmpty";
import LoadingSpinner from "../../../atoms/LoadingSpinner/LoadingSpinner";
import Select from "../../../atoms/Select/Select";

const Intro = ({next, previous, goBackHome, initialName, initialDescription, initialPublished, identifier, initialTags=[], insertTag, deleteTag, loadingInfo=false}) => {
    const {t} = useTranslation()
    const [name, setName] = useState(initialName);
    const [published, setPublished] = useState(initialPublished);
    const [description, setDescription] = useState(initialDescription);

    const publishedOptions = [
        { label: t('documentalProcess.intro.publishedYes'), value: 'Y' },
        { label: t('documentalProcess.intro.publishedNo'), value: 'N' },
    ]

    /*const [languages, setLanguages] = useState('');*/
    const [tags, setTags] = useState(initialTags);

    useEffect(() => {
        setName(initialName)
    },[initialName])

    useEffect(() => {
        setDescription(initialDescription)
    },[initialDescription])

    useEffect(() => {
        if(!isEmpty(initialTags) && isEmpty(tags)) setTags(initialTags)
    },[initialTags])

    useEffect(() => {
        setPublished(initialPublished)
    },[initialPublished])

    return (
        <PageWithTitleAndFooter title={t('documentalProcess.intro.title')} footer={true} next={async () => await next(name, description,/* languages,*/published, tags)} previous={previous} closeAction={goBackHome} disableConfirm={isEmpty(name) || loadingInfo}>
            <div className='flex flex-col w-3/12 justify-center items-center'>
                {loadingInfo ?
                    <LoadingSpinner /> :
                    <form className='flex flex-col flex-1 max-w-lg w-full'>
                        <Input label={t('title')} placeholder={t('documentalProcess.intro.giveTitle')} value={name}
                               setValue={setName}/>
                        <Textarea label={t('description')} value={description} setValue={setDescription}/>

                        {identifier ? <div className="mt-1">
                            <Select value={published} label={t('documentalProcess.intro.published')} setValue={setPublished} options={publishedOptions}/>
                        </div> : <></>}

                        <SelectWithBadge label={t('labels')} selectedTags={tags} setSelectedTags={setTags}
                                         insertTag={insertTag} deleteTag={deleteTag} hasDelete={true}/>
                        {/*<Select
                            label={t('availableLang')}
                            placeholder={t('documentalProcess.intro.selectLanguage')}
                            options={[{value: 'it', label: 'Italiano'}, {value: 'en', label: 'English'}]}
                            value={languages}
                            setValue={setLanguages}
                        />*/}
                        {identifier ? <div className='block mt-12 pt-2 pb-1 text-xs text-gray-500 italic'>ID: {identifier}</div> : <></>}
                    </form>}
            </div>
        </PageWithTitleAndFooter>
    )
}

export default Intro;
