import {ChevronLeftIcon, ChevronRightIcon} from '@heroicons/react/24/solid'
import {useEffect, useState} from "react";
import {classNames} from "../../../utils/helper";
import {useTranslation} from "react-i18next";

export default function Paginator({pageSize, totalResults, currentPage, onClick}) {
    const {t} = useTranslation();
    const totalPages = Math.ceil(totalResults / pageSize);
    const [pages, setPages] = useState([])
    const[startIndex, setStartIndex] = useState(1)
    const[endIndex, setEndIndex] = useState(pageSize)

    useEffect(() => {
        setPages(updatePages())
        setStartIndex(((currentPage - 1) * pageSize) + 1)
        const tempEndIndex = ((currentPage - 1) * pageSize) + pageSize
        setEndIndex(tempEndIndex <= totalResults ? tempEndIndex : totalResults)
    },[pageSize, totalResults, currentPage, onClick])

    const updatePages = () => {
        const tempPages = []
        if (totalPages > 6) {
            tempPages.push(1);
            if (currentPage > 2 && currentPage < (totalPages - 2)) {
                tempPages.push('...');
                let lower = currentPage === 3 ? currentPage : currentPage - 1;
                let higher = currentPage === (totalPages - 2) ? currentPage : currentPage + 1;
                if (currentPage === 3) {
                    higher = currentPage + 2;
                }
                for (let i = lower - 1; i < higher; i++) {
                    tempPages.push(i+1);
                }
                tempPages.push('...');
            } else {
                for (let i = 1; i < 3; i++) {
                    tempPages.push(i+1);
                }
                tempPages.push('...');
                for (let j = totalPages - 3; j < totalPages - 1; j++) {
                    tempPages.push(j+1);
                }
            }
            tempPages.push(totalPages);
        } else {
            for (let i = 0; i < totalPages; i++) {
                tempPages.push(i+1);
            }
        }
        return tempPages;
    }

    if (!currentPage) currentPage = 1;

    function onPageChange(newPage) {
        if (newPage > totalPages || newPage <= 0 || newPage === currentPage) {
            return;
        }
        currentPage = newPage;
        let offset = (newPage - 1) * pageSize;
        if (offset < 0) offset = 0;
        if (onClick) {
            onClick({offset: offset, limit: pageSize, currentPage: currentPage});
        }
    }

    return (
        <div className="bg-white px-2 py-3 flex items-center justify-between border-t border-gray-200 z-0">
            <div className="flex-1 flex justify-between sm:hidden">
                <div onClick={() => onPageChange(currentPage - 1)}
                     className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                >
                    {t('previous ')}
                </div>
                <div onClick={() => onPageChange(currentPage + 1)}
                     className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                >
                    {t('next')}
                </div>
            </div>
            <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                <div>
                    {totalResults > 0 && <p className="text-sm text-gray-700">
                        {
                            t('library.resultsLabel',
                            { start: startIndex , end: endIndex , total: totalResults }
                            )
                        }
                    </p>}
                </div>
                <div>
                    <nav className="relative z-0 inline-flex rounded-md shadow-sm" aria-label="Pagination">
                        <div onClick={() => onPageChange(currentPage - 1)}
                             className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 cursor-pointer"
                        >
                            <span className="sr-only">{t('previous ')}</span>
                            <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                        </div>
                        {pages.map((page,idx) =>
                            (page === '...') ?
                                <span key={page+idx} className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 cursor-default">
                                ...
                                </span>
                                :
                                <div key={page+idx} onClick={() => onPageChange(page)}
                                     aria-current="page"
                                     className={classNames(page === currentPage ? 'z-0 bg-blue-50 border-blue-500 text-blue-600 ' : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50',
                                         'relative inline-flex items-center px-4 py-2 border text-sm font-medium cursor-pointer')}
                                >
                                    {page}
                                </div>
                        )}

                        <div onClick={() => onPageChange(currentPage + 1)}
                             className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 cursor-pointer"
                        >
                            <span className="sr-only">{t('next')}</span>
                            <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    )
}
