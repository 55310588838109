import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {
    downloadEventPDF,
    getCanceledEvents,
    getCanceledEventsDetailsThunk,
    restoreDeletedEventThunk
} from "../../../../../features/library/library.action";
import EventsList from "../../../../organisms/EventsList/EventsList";
import {useTranslation} from "react-i18next";
import {DATE, ICON_BUTTON, ICON_BUTTON_LIST} from "../../../../atoms/SimpleTableElement/SimpleTable.types";

const DocCanceled = ({canceledEvents, canceledEventsCount, closeDetail, detailOpened, loadingEvents, currentEventId, filters, setFilters, viewEventDetail}) => {
    const dispatch = useDispatch();
    const [openModal, setOpenModal] = useState(false)
    const [eventToRestore, setEventToRestore] = useState()
    const [sendEmail, setSendEmail] = useState(false)
    const { t } = useTranslation();
    const updateCanceledEvents = (filter) => dispatch(getCanceledEvents(filter))
    const getCanceledEventsDetails = async (eventId) => {
        await dispatch(getCanceledEventsDetailsThunk({id: eventId}))
    }

    const restoreDeletedEvent = async () => {
        await dispatch(restoreDeletedEventThunk({eventFlowActivityId: eventToRestore?.id, sendEmail: sendEmail})).unwrap()
        await updateCanceledEvents(filters)
    }

    const downloadPDF = async (row) => await dispatch(downloadEventPDF({url: row?.downloadPdfUrl}));

    const onClickRestoreEvent = (data) => {
        setEventToRestore(data);
        setOpenModal(true);
    }

    const canceledEventsColumns = [
        {field: 'flowstatus', label: t('tableColumns.status'), hidden: true, type: 'status', id: 'flowstatus'},
        {field: 'displayname', label: t('tableColumns.name'), isSortable:false, hidden: false, id: 'displayname'},
        {field: 'title', label: t('tableColumns.title'), isSortable:false, hidden: false, id: 'title'},
        {field: 'referencedate', type: DATE, label: t('tableColumns.date'), isSortable: true, hidden: false, id: 'referencedate'},
        {
            field: 'actions', type: ICON_BUTTON_LIST, id: 'actions', hidden: true, buttons: [
                {
                    field: 'Dettagli',
                    label: t('tableColumns.details'),
                    hidden: true,
                    type: ICON_BUTTON,
                    onClick: (d) => viewEventDetail(d, getCanceledEventsDetails),
                    iconType: 'details', id: 'Dettagli'
                },
                {
                    field: 'Visualizza',
                    label: t('tableColumns.view'),
                    hidden: true,
                    type: ICON_BUTTON,
                    onClick: (d) => downloadPDF(d),
                    iconType: 'view', id: 'Visualizza'
                },
                {
                    field: 'Ripristina',
                    label: t('tableColumns.restore'),
                    hidden: true,
                    type: ICON_BUTTON,
                    onClick: onClickRestoreEvent,
                    iconType: 'restore',
                    id: 'Ripristina'
                }
            ]
        }
    ]

    return <EventsList
        data={canceledEvents} closeDetail={closeDetail}
        columns={canceledEventsColumns} detailOpened={detailOpened}
        loading={loadingEvents} elementsCount={canceledEventsCount}
        updateEvents={updateCanceledEvents} currentEventId={currentEventId}
        filters={filters} setFilters={setFilters}
        openDeleteModal={openModal} setOpenDeleteModal={setOpenModal} onDeleteEvent={restoreDeletedEvent}
        confirmModalButtonLabel={t('library.restoreEvent')} customMessageModal={`${t('library.restoreMessage',{documentName: eventToRestore?.title})}`}
        sendEmail={sendEmail} setSendEmail={setSendEmail} askSendRestoreEmail={true}
    />
}

export default DocCanceled;
