import ErrorMessage from "../../../atoms/ErrorMessage/ErrorMessage";
import Input from "../../../atoms/Inputs/Input/Input";
import htmr from "htmr";
import {useTranslation} from "react-i18next";

const Video = ({errorVideo, iframe, videoLink, setVideoLink}) => {
    const {t}=useTranslation()
    return(
        <>
            <Input value={videoLink} setValue={setVideoLink} label={t('video.links')} />
            {errorVideo && <ErrorMessage>{t('video.insertLink')}</ErrorMessage>}
            {iframe && <div className='w-full'>{htmr(iframe)}</div>}
        </>
    )
}

export default Video;
