import {classNames} from "../../../utils/helper";
import Badge from "../Badges/Badge/Badge";
import {Draggable} from "react-beautiful-dnd";
import {ArrowsUpDownIcon, XMarkIcon} from "@heroicons/react/24/solid";

const DraggableCard = ({index, field, onDelete, children, onCardClick}) => {

    const onClick = () => {
        onCardClick(field.name)
    }

    const onClickDelete = (e) => {
        e.preventDefault();
        e.stopPropagation();
        onDelete();
    }

    return (
        <Draggable draggableId={`draggable-${field.name}`} index={index}>
            {(provided) => <div ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          className={classNames('py-2')}>
                <div className='w-full flex flex-col bg-white shadow rounded-lg px-2 py-5 space-x-4' data-testid='clickable-div' onClick={onClick}>
                    <div className='flex justify-between items-center w-full px-4'>
                        <div className='flex items-center space-x-4 truncate'>
                            <div {...provided.dragHandleProps}>
                                <ArrowsUpDownIcon className='h-5 w-5'/>
                            </div>
                            <div className='flex items-center space-x-2 truncate'>
                                <h1 className='text-sm leading-5 font-medium truncate'>{field.caption}</h1>
                                <Badge color='blue' label={field.type}/>
                            </div>
                        </div>
                        <div className='flex'>
                            <XMarkIcon className='text-red-600 h-6 w-6 ml-3 cursor-pointer' onClick={onClickDelete} data-testid='delete-icon'/>
                        </div>
                    </div>
                    <div className='flex flex-col max-w-sm'>
                        {children}
                    </div>
                </div>
                {provided.placeholder}
            </div>
            }
        </Draggable>
    )
}

export default DraggableCard;
