import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {librarySelector, resetEnableSignature} from "../../../../../../features/library/library.slice";
import Button from "../../../../../atoms/Buttons/Button/Button";
import LoadingSpinner from "../../../../../atoms/LoadingSpinner/LoadingSpinner";
import {enableSignature} from "../../../../../../features/library/library.action";
import {userHomeSelector} from "../../../../../../features/userHome/userHome.slice";
import Input from "../../../../../atoms/Inputs/Input/Input";
import {t} from "i18next";
import {MinusCircleIcon, PlusIcon} from "@heroicons/react/24/solid";
import {toast} from "react-hot-toast";
import {Translation} from "react-i18next";
import Alert from "../../../../../molecules/Alerts/Alert";
import {ERROR} from "../../../../../molecules/Alerts/types";
import ModalPinRequest from "../../../../../molecules/Modals/ModalPinRequest/ModalPinRequest";

const EnableFEQ = ({backAction}) => {

    const dispatch = useDispatch();
    const {enableSignatureParams} = useSelector(librarySelector);
    const [enabling, setEnablig] = useState(false);

    const { selectedTenantId, userOrganizations } = useSelector(userHomeSelector);
    const feqProvider = userOrganizations?.[selectedTenantId]?.defaultFeqProvider ?? 'INTESA';
    const [signatureEnableData, setSignatureEnableData] = useState({ provider: feqProvider, devices: [{description: '', deviceId: ''}] });
    const [openModalPinRequest, setOpenModalPinRequest] = useState(false)

    const callEnableSignature = async () => {
        setEnablig(true);
        setOpenModalPinRequest(false);
        if (feqProvider === 'LAZIOCREA' && signatureEnableData.username?.toUpperCase().startsWith('RHI')) {
            if (signatureEnableData.devices.length === 0) {
                //messaggio di errore con toast
                toast.custom((tst) => (<Translation>{(t) => <Alert type={ERROR} message={t('feq.missingDevice')} onClose={() => toast.dismiss(tst.id)}/>}</Translation>));
                setEnablig(false);
                return;
            }
            //controllo che per tutti i device il deviceId sia valorizzato
            if (signatureEnableData.devices.some((device) => device.deviceId === '')) {
                //messaggio di errore con toast
                toast.custom((tst) => (<Translation>{(t) => <Alert type={ERROR} message={t('feq.missingDeviceId')} onClose={() => toast.dismiss(tst.id)}/>}</Translation>));
                setEnablig(false);
                return;
            }
            //controllo che per tutti i device il deviceId sia numerico
            if (signatureEnableData.devices.some((device) => isNaN(device.deviceId))) {
                //messaggio di errore con toast
                toast.custom((tst) => (<Translation>{(t) => <Alert type={ERROR} message={t('feq.invalidDeviceId')} onClose={() => toast.dismiss(tst.id)}/>}</Translation>));
                setEnablig(false);
                return;
            }
        }
        await dispatch(enableSignature({code: 'FEQ', data: signatureEnableData}))
        setEnablig(false);
    }

    const addDevice= () => {
        setSignatureEnableData({...signatureEnableData, devices: [...signatureEnableData.devices, {description: '', deviceId: ''}]})
    }

    const setDevice = (idx, caption) => {
        setSignatureEnableData({...signatureEnableData, devices: signatureEnableData.devices.map((q,qid)=> {
            if(qid === idx) return {description: caption,deviceId:q.deviceId}
            else return q
            })
        });
    }

    const setDeviceValue = (idx, value) => {
        setSignatureEnableData({...signatureEnableData, devices: signatureEnableData.devices.map((q,qid)=> {
                if(qid === idx) return {description: q.description, deviceId: value}
                else return q
            })
        });
    }


    const deleteDevice = (e, deleteId) => {
        e.preventDefault();
        e.stopPropagation();
        setSignatureEnableData({...signatureEnableData, devices: signatureEnableData.devices.filter((q, qId) => qId !== deleteId)})
    }

    useEffect(() => {
        if(enableSignatureParams) {
            dispatch(resetEnableSignature({}));
            window.location.href = enableSignatureParams.link;
        }
    }, [enableSignatureParams])

    return(
        <div className="relative h-full text-sm max-w-prose mx-auto" aria-hidden="true">
            {enabling ?
                <LoadingSpinner></LoadingSpinner> :

                <>
                {(!feqProvider || feqProvider == '' || feqProvider === 'INTESA') && <>
                    <div>Firma Elettronica Qualificata</div>
                    <div className="mt-6 prose text-gray-700 mx-auto">
                        <p>
                            Nelle prossime schermate le verrà chiesto di controllare e completare i suoi dati personali.
                            Le chiediamo di porre attenzione alla correttezza delle informazioni inserite.
                        </p>
                        <br/>
                        <h2>Seguirà una breve sessione di videoriconoscimento con operatore. Le raccomandiamo pertanto:</h2>
                        <ul className="list-disc ml-6">
                            <li>di attivare le casse audio o collegare le cuffie</li>
                            <li>di assicurarsi che il suo microfono sia acceso</li>
                            <li>di assicurarsi che il suo dispositivo sia dotato di webcam</li>
                        </ul>
                        <br/>
                        <h2>Dovrà inoltre tenere a portata di mano:</h2>
                        <ul className="list-disc ml-6">
                            <li>un documento di identità valido;</li>
                            <li>un numero di telefono cellulare;</li>
                            <li>un indirizzo email da poter consultare.</li>
                        </ul>
                        <br/>
                        <h2>Per ulteriori informazioni la invitiamo a consultare:</h2>
                        <ul className="list-disc ml-6">
                            <li>il&nbsp;
                                <span className="text-blue-900 underline">
                                <a href="https://e-trustcom.intesa.it/DOCS/mo_INTESA.pdf" target="_blank"
                                   rel="noopener noreferrer">Manuale Operativo</a>
                            </span>
                            </li>
                            <li>l'<span className="text-blue-900 underline"><a
                                href="https://e-trustcom.intesa.it/DOCS/INTQS_TSP-INFORMATIVA.pdf " target="_blank"
                                rel="noopener noreferrer">Informativa Privacy</a></span></li>
                        </ul>
                    </div>
                    <div className="w-full grid px-4 items-end">
                        <div className="grid grid-cols-1 md:grid-cols-4 gap-4 items-end px-4 pt-4 pb-2">
                            <Button variant="white" onClick={backAction} label="Annulla"></Button>
                            <Button onClick={callEnableSignature} disabled={enabling} label="Procedi"></Button>
                        </div>
                    </div>
                </>}
                {feqProvider === 'UANATACA' && <>
                    <div>Firma Elettronica Qualificata</div>
                    <div className="mt-6 prose text-gray-700 mx-auto">
                        {t('feq.enrollWithCredentials')}
                    </div>
                    <div className="w-full grid px-4 items-end">
                        <div className="mt-2">
                            <Input type='text' value={signatureEnableData.username} setValue={(val) => setSignatureEnableData({
                                ...signatureEnableData, username: val
                            })} label={'Username (Alias)'}/>
                        </div>
                        <div className="mt-2">
                            <Input type='password' value={signatureEnableData.password} setValue={(val) => setSignatureEnableData({
                                ...signatureEnableData, password: val
                            })} label={'Password'}/>
                        </div>
                    </div>


                    <div className="w-full grid px-4 items-end">
                        <div className="grid grid-cols-1 md:grid-cols-4 gap-4 items-end px-4 pt-4 pb-2">
                        <Button variant="white" onClick={backAction} label={t('cancel')}></Button>
                            <Button onClick={callEnableSignature} disabled={enabling} label={t('proceed')}></Button>
                        </div>
                    </div>
                </>}
                {feqProvider === 'LAZIOCREA' && <>
                    <div>Firma Elettronica Qualificata</div>
                    <div className="mt-6 prose text-gray-700 mx-auto">
                        {t('feq.enrollWithCredentials')}
                    </div>
                    <div className="w-full grid px-4 items-end">
                        <div className="mt-2">
                            <Input type='text' value={signatureEnableData.username}
                                   setValue={(val) => setSignatureEnableData({
                                       ...signatureEnableData, username: val
                                   })} label={'Username (Alias)'}/>
                        </div>
                        {/*<div className="mt-2">*/}
                        {/*    <Input type='password' value={signatureEnableData.password}*/}
                        {/*           setValue={(val) => setSignatureEnableData({*/}
                        {/*               ...signatureEnableData, password: val*/}
                        {/*           })} label={'Password'}/>*/}
                        {/*</div>*/}
                        {signatureEnableData.username?.toUpperCase().startsWith('RHI') && <div className="mt-2">
                            <div className='flex flex-col'>
                                <p className='text-sm leading-5 font-medium py-2'>{t('feq.devices')}</p>
                                {signatureEnableData?.devices && signatureEnableData?.devices.map((device, key) => (
                                    <div className='flex items-center space-x-2' key={`device-${key}`}>
                                        <div className='flex-2'>
                                            <Input value={device?.deviceId ?? ''}
                                                   setValue={(value) => setDeviceValue(key, value)}
                                                   placeholder={t('feq.deviceIdHint')}/>
                                        </div> : <></>
                                        <div className='flex-1'>
                                            <Input value={device?.description ?? ''}
                                                   setValue={(value) => setDevice(key, value)}
                                                   placeholder={t('feq.descriptionHin')}/>
                                        </div>
                                        <div className='justify-self-center'>
                                            {key !== 0 &&
                                                <MinusCircleIcon className='text-red-600 h-6 cursor-pointer'
                                                                 onClick={(e) => deleteDevice(e, key)}/>}
                                        </div>
                                    </div>
                                ))}
                                <button className='text-blue-600 flex flex-1 justify-center mt-4'
                                        onClick={() => addDevice()}><PlusIcon
                                    className='h-5'/>{t('feq.addDevice')}</button>
                            </div>
                        </div>}
                    </div>
                    {openModalPinRequest && <ModalPinRequest open={openModalPinRequest}
                                                             setOpen={setOpenModalPinRequest}
                                                             type={'FEQ'}
                                                             confirm={(pin) => {
                                                                 setSignatureEnableData({...signatureEnableData, password: pin})
                                                                 callEnableSignature()
                                                             }}
                                                             requestOtp={false}
                                                             otpFeqVisible={false}
                                                             pendingRequest={false}
                                                             otpRequestEnabled={false}
                                                             feqHint={'Inserisci il tuo Codice Pin per validare i dati. Il PIN non verrà memorizzato.'}
                    />}

                    <div className="w-full grid px-4 items-end">
                        <div className="grid grid-cols-1 md:grid-cols-4 gap-4 items-end px-4 pt-4 pb-2">
                            <Button variant="white" onClick={backAction} label={t('cancel')}></Button>
                            <Button onClick={() => {
                                if (feqProvider === 'LAZIOCREA') {
                                    setOpenModalPinRequest(true)
                                } else {
                                    callEnableSignature()
                                }
                            }} disabled={enabling} label={t('proceed')}></Button>
                        </div>
                    </div>
                </>}
                </>
            }

        </div>
    )
}

export default EnableFEQ;
