const SimplePageWithBackground = ({children}) => {
    return (
        <>
            <div className='flex flex-col h-screen w-screen items-stretch bg-white bg-background bg-no-repeat bg-right-top bg-contain md:bg-50% bg-scroll'>
                <div className='flex justify-center items-center px-6 sm:items-center flex-grow'>
                    {children}
                </div>
            </div>
        </>
    )
}

export default SimplePageWithBackground;
