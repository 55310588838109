import Button from "../../../../atoms/Buttons/Button/Button";
import {XL} from "../../../../atoms/Buttons/sizes";
import SimpleTable from "../../../../molecules/Tables/SimpleTable/SimpleTable";
import ModalUpsertWorkspace from "../../../../molecules/Modals/ModalNewWorkspace/ModalUpsertWorkspace";
import React, {useEffect, useState} from "react";
import {v4} from "uuid";
import isEmpty from "lodash/isEmpty";
import {DATE, ICON_BUTTON, ICON_BUTTON_LIST} from "../../../../atoms/SimpleTableElement/SimpleTable.types";
import {
    createNewWorkspaceThunk,
    deleteWorkspaceThunk,
    getAllWorkspacesInfoThunk,
    updateWorkspaceNameThunk
} from "../../../../../features/userHome/userHome.actions";
import {useDispatch, useSelector} from "react-redux";
import {userHomeSelector} from "../../../../../features/userHome/userHome.slice";
import {useTranslation} from "react-i18next";
import GenericQuestionModal from "../../../../molecules/Modals/GenericModal/GenericQuestionModal";

const Workspaces = () => {
    const dispatch = useDispatch()
    const {t} = useTranslation()
    const {allWorkspacesInfo} = useSelector(userHomeSelector)
    const [openNewWorkspace, setOpenNewWorkspace] = useState(false)
    const [openUpdateWorkspace, setOpenUpdateWorkspace] = useState(false)
    const [selectedWorkspaceIdToUpdate, setSelectedWorkspaceIdToUpdate] = useState()
    const [selectedWorkspaceIdToDelete, setSelectedWorkspaceIdToDelete] = useState()
    const [selectedWorkspaceToDelete, setSelectedWorkspaceToDelete] = useState()
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const deleteWorkspace = (id) => dispatch(deleteWorkspaceThunk({id}))
    const updateWorkspaceName = (workspaceId, name) => dispatch(updateWorkspaceNameThunk({workspaceId, name}))
    const columns = [
        {field: 'name', label: 'tableColumns.name', hidden: false, id: v4()},
        {field: 'createdAt', label: 'tableColumns.date', type: DATE, hidden: false, id: v4()},
        {field: 'actions', type: ICON_BUTTON_LIST, hidden: true, id: v4(), buttons: [
                {
                    field: 'Edit',
                    label: 'tableColumns.edit',
                    hidden: true,
                    type: ICON_BUTTON,
                    onClick: (row) => {
                        setSelectedWorkspaceIdToUpdate(row.id)
                        setOpenUpdateWorkspace(true)
                    },
                    iconType: 'modify',
                    id: v4()
                },
                {
                    field: 'Elimina',
                    label: 'tableColumns.elimina',
                    hidden: true,
                    type: ICON_BUTTON,
                    onClick: (data) => {
                        setSelectedWorkspaceIdToDelete(data.id);
                        setOpenDeleteModal(true);
                    },
                    iconType: 'delete',
                    id: v4()
                },
            ]
        }
    ]

    useEffect(() =>{
        dispatch(getAllWorkspacesInfoThunk())
    },[])
    useEffect(() => {
        setSelectedWorkspaceToDelete(
            allWorkspacesInfo.find(w => w.id === selectedWorkspaceIdToDelete)
        )
    },[selectedWorkspaceIdToDelete, allWorkspacesInfo])

    const onCreateNewWorkspace = (name) => dispatch(createNewWorkspaceThunk({name}))

    return(
        <>
            <div className="px-2 sm:px-3 lg:px-4">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h1 className="text-xl font-semibold text-gray-900">{t('workspaces')}</h1>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none space-x-2">
                        <Button label={t('newworkspace')} size={XL} onClick={() => setOpenNewWorkspace(true)}/>
                    </div>
                </div>
                <div className="mt-2 flex flex-col">
                    <div className="sm:-mx-3 lg:-mx-4">
                        <div className="inline-block min-w-full align-middle md:px-3 lg:px-4">
                            <SimpleTable columns={columns} data={allWorkspacesInfo} />
                        </div>
                    </div>
                </div>
            </div>
            <ModalUpsertWorkspace open={openNewWorkspace} setOpen={setOpenNewWorkspace} onConfirm={onCreateNewWorkspace} />
            <ModalUpsertWorkspace open={openUpdateWorkspace} setOpen={setOpenUpdateWorkspace} onConfirm={(name) => updateWorkspaceName(selectedWorkspaceIdToUpdate, name)} initialName={allWorkspacesInfo?.find(w => w.id === selectedWorkspaceIdToUpdate)?.name} confirmLabel='Modifica' />
            <GenericQuestionModal title={t('deleteWorkspaceMessage', {name: selectedWorkspaceToDelete?.name})}
                                  customMessage={!isEmpty(selectedWorkspaceToDelete?.users) ? t('deleteWorkspaceCaption', {usersList: selectedWorkspaceToDelete?.users?.map(u => u.displayName).join(', ')}) : ' '}
                                  open={openDeleteModal} setOpen={setOpenDeleteModal}
                                  onDelete={() => deleteWorkspace(selectedWorkspaceIdToDelete)} />
            </>
    )
}

export default Workspaces;
