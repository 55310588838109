import client from "./client";

export const getRecipientsCall = async(processId) => {
    //return await client.get(`/api/doc-api/event-flow/${processId}/actors`)
    return await client.get(`/api/doc-api/event-flow/${processId}/actors-gui`)
}

export const addRecipientsCall = async(processId, recipientsList) => {
    return await client.post(`/api/doc-api/event-flow-actors-gui/${processId}`, recipientsList)
}

export const linkRecipientToInformativaCall = async(processId, data) => {
    return await client.post(`/api/doc-api/event-flow-actors/${processId}/actors-informed-paths`, data)
}

export const linkRecipientToDataCollectionCall = async(processId, data) => {
    return await client.post(`/api/doc-api/event-flow-actors/${processId}/actors-forms`, data)
}

export const getSignatureTypesListCall = async() => {
    return await client.get(`/api/doc-api/signatureTypes`)
}
