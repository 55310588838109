import SingleCheckbox from "../../../atoms/SingleCheckbox/SingleCheckbox";
import Input from "../../../atoms/Inputs/Input/Input";
import Select from "../../../atoms/Select/Select";
import RadioGroup from "../../../atoms/RadioGroups/RadioGroup";
import InputPhone from "../../../atoms/Inputs/InputPhone/InputPhone";
import Textarea from "../../../atoms/TextArea/Textarea";
import {useEffect, useMemo} from "react";

const SenderFormItem = ({item, values= null, setValues, disabledField= false, hiddenField = false}) => {

    useEffect(() => {
        if(disabledField) setValues()
    },[disabledField])

    const maxLength = useMemo(
        () => item?.options?.['max-length'],
        [item.options])

    // Se l'item è nascosto, non renderizzare nulla
    if (hiddenField) {
        return null;
    }

    switch(item.type){
        case 'checklist':
            return <div>
                <label className="block text-sm font-medium text-gray-700">{item.caption}</label>
                {item?.choices?.map(choice => <SingleCheckbox
                    key={choice.value}
                    label={choice.caption}
                    value={values?.[choice.value] ?? false}
                    setValue={(value) => setValues({...values, [choice.value]: value})}
                    disabled={disabledField}
                />)}
            </div>
        case 'number':
            return <div>
                <Input type='Number' label={item.caption} value={values ?? ''} setValue={setValues} disabled={disabledField} maxLength={maxLength}/>
            </div>
        case 'select':
            return <div>
                <Select label={item.caption} placeholder=' ' options={item.choices.map(opt => {return{label: opt.caption, value: opt.value}})} value={values ?? ''} setValue={setValues} disabled={disabledField}/>
            </div>
        case 'radio':
            return <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">{item.caption}</label>
                <RadioGroup options={item.choices.map(opt => {return{name: opt.caption, value: opt.value}})} value={values ?? ''} setValue={setValues}/>
            </div>
        case 'label':
        case 'caption':
            return <label className="block text-sm font-medium text-gray-700">{item.caption}</label>
        case 'text':
            if(item.options && item.options['validation-mask'] === 'PHONE_REGEX')
                return<InputPhone label={item.caption} phone={values ?? ''} setPhone={setValues}/>
            return <Input label={item.caption} type={item.type} value={values ?? ''} setValue={setValues} disabled={disabledField} maxLength={maxLength}/>
        case 'textarea':
            //return <Input type={step.type} value={value} setValue={setValue} />
            return <Textarea label={item.caption} value={values ?? ''} setValue={setValues} disabled={disabledField} maxLength={maxLength} />
        case 'date':
            return <Input label={item.caption} type={item.type} value={values ?? ''} setValue={setValues} disabled={disabledField}/>
        default:
            return <div></div>
    }
}

export default SenderFormItem;
