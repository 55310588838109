import React from "react";
import {Rnd} from "react-rnd";
import DraggableBadge from "../atoms/Badges/DraggableBadges/DraggableBadge";

const Block = ({block, onResizeStop, onDragStop, zoom, selected, color = 'blue', onClick}) => {

    return(
        <>
            <Rnd
                key={block.id}
                position={{
                    x: block.x*zoom,
                    y: block.y*zoom
                }}
                size={{
                    width: block.width*zoom,
                    height: block.height*zoom
                }}
                enableResizing={{right: true, bottom: true, bottomRight: true}}
                onDragStop={(e, d) => onDragStop(e, d, block)}
                onResizeStop={(e, direction, ref) => onResizeStop(ref, block)}
                bounds="parent"
                style={{zIndex: 10}}
            >
                <DraggableBadge label={block.label} onClick={() => onClick(block)} selected={selected} color={color} fontSize={block?.fontSize} fontFamily={block?.fontFamily}/>
            </Rnd>
        </>
    );
}

export default Block;
