import React, {useState} from "react";
import HeaderWithTitle from "../../../molecules/Headers/HeaderWithTitle/HeaderWithTitle";
import Footer from "../../../molecules/Footer/Footer";
import {classNames} from "../../../../utils/helper";
import {useTranslation} from "react-i18next";

const PageWithTitleAndFooter = ({children, title, footer = false, next, previous, closeAction, disableConfirm = false, hasBackground = true, confirmLabel, cancelLabel}) => {
    const {t} = useTranslation()
    const [waitNext, setWaitNext] = useState(false)

    const confirm = async () => {
        setWaitNext(true)
        await next()
        setWaitNext(false)
    }
    return(
        <div className={classNames(hasBackground ? 'bg-background bg-no-repeat bg-right-top bg-50% bg-scroll' : '' ,
            'absolute top-0 left-0 right-0 bottom-0 flex flex-col items-stretch bg-white overflow-hidden')}>
            <div className='sticky top-0 z-10'>
                <HeaderWithTitle title={title} closeAction={closeAction}/>
            </div>

            <div className='flex flex-1 justify-center items-center flex-grow overflow-auto'>
                {children}
            </div>
            {footer && <div className='sticky bottom-0 z-10 flex justify-center items-end w-full'>
                <Footer type='confirm' confirm={confirm} cancel={previous} disableConfirm={disableConfirm || waitNext} confirmLabel={confirmLabel ?? t('next')} cancelLabel={cancelLabel ?? t('actions.cancel')}/>
            </div> }
        </div>
    )
}

export default PageWithTitleAndFooter;
