import React, {useEffect, useRef, useState} from "react";
import {DragDropContext, Droppable} from "react-beautiful-dnd";
import BackgroundInformativa from "../../atoms/BackgroundInformativa/BackgroundInformativa";
import DataCollectionField from "../../molecules/DataCollectionField/DataCollectionField";
import FormInputElement from "../../molecules/FormInputElement/FormInputElement";
import RoundButtons from "../../atoms/Buttons/RoundButtons/RoundButtons";
import DropdownCircular from "../../atoms/Dropdowns/DropdownCircular/DropdownCircular";
import isEmpty from "lodash/isEmpty";
import DraggableCard from "../../atoms/DraggableCard/DraggableCard";
import {reorderList} from "../../../utils/beautiful-dnd-helper";
import {useTranslation} from "react-i18next";

const ListDataCollectionsFields = ({fieldTypesFormatted, fields = [], rules = [], onDeleteFormField, reorderFormFields, showFields, onCardClick, createRule, setRule, deleteRule, disabled=false}) => {
    const [fieldsList, setFieldsList] = useState([])
    const ref = useRef();
    const {t} = useTranslation()

    useEffect(() => {
        const onBodyClick = (event) => {
            if (ref['current'].contains(event.target)) return;
            //onCardClick(null)
        };
        document.body.addEventListener('mousedown', onBodyClick);
        return () => {
            document.body.removeEventListener('mousedown', onBodyClick);
            //onCardClick(null)
        };
    }, []);

    useEffect(() => {
        setFieldsList(fields ?? [])
    },[fields])

    const addRule = () => {
        createRule({ targetField: "", dependentField: "", comparison: "", value: "", action: ""})
    }

    const fieldsOptions = !isEmpty(fieldsList) ? fieldsList.map(field => {return {label: field.caption, value: field.name}}) : []

    const onDragEnd = (result) => {
        const orderedStepsList = reorderList(result, fieldsList)
        if(orderedStepsList) {
            setFieldsList(orderedStepsList);
            reorderFormFields(orderedStepsList)
        }
    }

    return(
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="list">
                {provided => (
                    <BackgroundInformativa >
                        <div className='mb-3' >
                            {showFields ?
                                <DropdownCircular
                                    label={t('addElement')}
                                    options={fieldTypesFormatted}
                                    disabled={disabled}
                                /> :
                                <RoundButtons
                                    onClick={addRule}
                                    label={t('addElement')}
                                    variant='blue'
                                    disabled={disabled}
                                />
                            }
                        </div>
                        <div ref={provided.innerRef} className='w-full' {...provided.droppableProps}>
                            <div ref={ref}>
                                {showFields ?<>
                                        {fieldsList && fieldsList.map((field, index) => (
                                            <div className='w-full' key={field.name}>
                                                <DraggableCard
                                                    index={index}
                                                    field={field}
                                                    onDelete={() => onDeleteFormField(field)}
                                                    onCardClick={onCardClick}
                                                >
                                                    <DataCollectionField
                                                        field={field}
                                                        key={field.name}/>
                                                </DraggableCard>
                                            </div>
                                        ))}
                                        {provided.placeholder}
                                    </>:
                                    <div className='space-y-4'>
                                        {rules && rules.map(rule => (
                                            <FormInputElement
                                                key={rule.id}
                                                rule={rule}
                                                fieldOptions={fieldsOptions}
                                                onDelete={deleteRule}
                                                setRule={setRule}
                                            />
                                        ))}
                                    </div>
                                }
                            </div>
                        </div>
                    </BackgroundInformativa>
                )}
            </Droppable>
        </DragDropContext>
    )
}

export default ListDataCollectionsFields;
