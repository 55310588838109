import axios from "axios";
import {logoutUser} from "../utils/logout-helper";

export const getHeaders = () => {
    const token = sessionStorage.getItem('token')
    const language = sessionStorage.getItem('language')
    const tenantId = JSON.parse(sessionStorage.getItem('selectedTenantId'))
    return {
        'Authorization': token,
        'confirmo-locale': language,
        'confirmo-tenant': tenantId ?? ''
    }
}


/**
 * Create a new Axios client instance
 * @see https://github.com/mzabriskie/axios#creating-an-instance
 */
const getClient = () => {

    const options = {
        baseURL: process.env.REACT_APP_ENDPOINT_BE,
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
        }
    };
    const headers = getHeaders()
    if (headers['Authorization']) {
        options.headers = headers
    }

    const client = axios.create(options);

    const responseSuccessHandler = response => {
        return response;
    };
    client.interceptors.response.use(
        response => responseSuccessHandler(response),
        error => logoutUser(error)
    );

    return client;
};

/**
 * Estrae il messaggio di errore e lo ritorna
 * @param error
 */
const handleErrorMessage = function(error) {
    if (error?.response?.data) {
        const message = error.response.data.errorMessage || error.response.data.message;
        if (message) {
            throw new Error(message);
        }
    }
    throw error;
}

/**
 * Base HTTP Client
 */
export default {
    // Provide request methods with the default base_url
    get(url, conf = {}) {
        return getClient().get(url, conf)
            .then(response => Promise.resolve(response))
            .catch(err => { handleErrorMessage(err); });
    },

    delete(url, conf = {}) {
        return getClient().delete(url, conf)
            .then(response => Promise.resolve(response))
            .catch(err => { handleErrorMessage(err); });
    },

    head(url, conf = {}) {
        return getClient().head(url, conf)
            .then(response => Promise.resolve(response))
            .catch(err => { handleErrorMessage(err); });
    },

    options(url, conf = {}) {
        return getClient().options(url, conf)
            .then(response => Promise.resolve(response))
            .catch(err => { handleErrorMessage(err); });
    },
    post(url, data = {}, conf = {}) {
        return getClient().post(url, data, conf)
            .then(response => Promise.resolve(response))
            .catch(err => { handleErrorMessage(err); });

    },

    put(url, data = {}, conf = {}) {
        return getClient().put(url, data, conf)
            .then(response => Promise.resolve(response))
            .catch(err => { handleErrorMessage(err); });
    },

    patch(url, data = {}, conf = {}) {
        return getClient().patch(url, data, conf)
            .then(response => Promise.resolve(response))
            .catch(err => { handleErrorMessage(err); });
    },

    downloadFile(url, success) {
        const xhr = new XMLHttpRequest();
        let finalUrl = url;
        if (!url.startsWith('http:') && !url.startsWith('https:')) {
            finalUrl = process.env.REACT_APP_ENDPOINT_BE + url;
        }
        xhr.open('GET', finalUrl, true);
        const headers = getHeaders();
        Object.keys(headers).forEach(headerKey => xhr.setRequestHeader(headerKey, headers[headerKey]))
        xhr.responseType = "blob";
        xhr.onreadystatechange = function() {
            if (xhr.readyState == 4) {
                if (success)
                    success(xhr.response, xhr);
            }
        };
        xhr.send(null);
    },


    downloadFilePost(url, body, success) {
        const xhr = new XMLHttpRequest();
        let finalUrl = url;
        if (!url.startsWith('http:') && !url.startsWith('https:')) {
            finalUrl = process.env.REACT_APP_ENDPOINT_BE + url;
        }
        xhr.open('POST', finalUrl, true);
        const headers = getHeaders();
        Object.keys(headers).forEach(headerKey => xhr.setRequestHeader(headerKey, headers[headerKey]))
        xhr.setRequestHeader("Content-Type", "application/json; charset=utf-8");
        xhr.responseType = "blob";
        xhr.onreadystatechange = function() {
            if (xhr.readyState == 4) {
                if (success)
                    success(xhr.response);
            }
        };
        xhr.send(body);
    }


};
