import FormInputRecipients from "../../../organisms/FormInputRecipients/FormInputRecipients";
import PageWithStepsHeaderAndFooter from "../../Templates/PageWithStepsHeaderAndFooter/PageWithStepsHeaderAndFooter";
import React, {useEffect, useState} from "react";
import isEmpty from "lodash/isEmpty";
import cloneDeep from "lodash/cloneDeep";

const RecipientsPage = ({next, previous, goBackHome, disableConfirm, signatureTypesList, orderedRecipients, setOrderedRecipients, steps, graphTemplatesOptions=[], buildUrl=()=>{}}) => {
    const [isOrdered, setIsOrdered] = useState(Object.values(orderedRecipients).some(rec => rec.progressNumber !== Object.values(orderedRecipients)[0].progressNumber))

    useEffect(() => {
    if(!isEmpty(orderedRecipients)) {
        const tempRecipients = cloneDeep(orderedRecipients)
        for (const rId in tempRecipients)
            if (isOrdered && tempRecipients[rId].progressNumber !== 1 && tempRecipients[rId].signatureType === 'GRAPH') {
                tempRecipients[rId].signatureType = ''
                tempRecipients[rId].hasGraphConsent = false
                tempRecipients[rId].graphConsentModelId = ''
            }
        setOrderedRecipients(tempRecipients)
    }
    },[isOrdered])

    return(
        <PageWithStepsHeaderAndFooter background={false} previous={previous} next={() => next(isOrdered)} closeAction={goBackHome} disableConfirm={disableConfirm} steps={steps} buildUrl={buildUrl}>
            <div className='flex flex-col max-w-4xl w-full h-full justify-center items-start'>
                <FormInputRecipients
                    signatureTypesList={signatureTypesList}
                    orderedRecipients={orderedRecipients}
                    setOrderedRecipients={setOrderedRecipients}
                    isOrdered={isOrdered}
                    setIsOrdered={setIsOrdered}
                    graphTemplatesOptions={graphTemplatesOptions}
                />
            </div>
        </PageWithStepsHeaderAndFooter>
    )
}

export default RecipientsPage;
