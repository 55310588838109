import Button from "../../../atoms/Buttons/Button/Button";
import Dropdown from "../../../atoms/Dropdowns/Dropdown/Dropdown";
import React from "react";
import {classNames} from "../../../../utils/helper";
import {MagnifyingGlassIcon} from "@heroicons/react/20/solid";
import InputWithLeadingIcon from "../../../atoms/Inputs/InputWithLeadingIcon/InputWithLeadingIcon";
import {useTranslation} from "react-i18next";

const FeedsTable = ({data, actionColumn, txtFilter, setTxtfilter}) => {
    const {t} = useTranslation()
    return (
        <div className="flex flex-col space-y-4 mt-2">
            <InputWithLeadingIcon
                value={txtFilter}
                setValue={setTxtfilter}
                icon={<MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
                placeholder={t('search.forNameEmail')}
            />
            <ul className="-mb-8">
                {data.map((event, eventIdx) => (
                    <li key={event.id}>
                        <div className="relative pb-8">
                            {eventIdx !== data.length - 1 ? (
                                <span className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
                                      aria-hidden="true"/>
                            ) : null}
                            <div className="relative flex space-x-3">
                                <div>
                                    <span
                                        className={classNames(
                                            event.iconBackground,
                                            'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white'
                                        )}
                                    >
                                        <event.icon className="h-5 w-5 text-white" aria-hidden="true"/>
                                    </span>
                                </div>
                                <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                                    <div>
                                        <p className="text-sm text-gray-500">
                                            {event.content}{''}
                                            <div className="font-medium text-gray-900">
                                                <div dangerouslySetInnerHTML={{__html: event.target}} />
                                            </div>
                                        </p>
                                    </div>
                                    <div className="text-right text-sm whitespace-nowrap text-gray-500">
                                        <div className="flex items-center">
                                            <span>{event.datetime}</span>
                                            {(actionColumn && actionColumn.type === 'dropdown')
                                                ?
                                                <Dropdown variant='light' label={actionColumn.buttonLabel}  icon={actionColumn.icon} options={actionColumn.dropdownOptions} item={event} />
                                                :
                                                (actionColumn && actionColumn.type === 'button')
                                                    ?
                                                    <Button variant='light' label={actionColumn.buttonLabel}  icon={actionColumn.icon}
                                                            onClick={() => actionColumn.onclick(event)}  item={event} />
                                                    :
                                                    ''}
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default FeedsTable;

