import SimpleTable from "../../molecules/Tables/SimpleTable/SimpleTable";
import GenericQuestionModal from "../../molecules/Modals/GenericModal/GenericQuestionModal";
import React, {useEffect, useMemo, useState} from "react";
import InputWithLeadingIcon from "../../atoms/Inputs/InputWithLeadingIcon/InputWithLeadingIcon";
import Paginator from "../../atoms/Paginator/Paginator";
import IconButton from "../../atoms/Buttons/IconButton/IconButton";
import {FULL} from "../../atoms/Buttons/sizes";
import {useTranslation} from "react-i18next";
import {
    DROPDOWN_ICON,
    ICON_BUTTON,
    ICON_BUTTON_LIST,
    PUBLISHING_STATUS,
    TAGS
} from "../../atoms/SimpleTableElement/SimpleTable.types";
import {MagnifyingGlassIcon, PlusIcon} from "@heroicons/react/20/solid";
import ModalConfirmLibraryCopy from "../../molecules/Modals/ModalConfirmLibraryCopy/ModalConfirmLibraryCopy";
import ModalEditPublish from "../../molecules/Modals/ModalEditPublish/ModalEditPublish";
import {generateEventFlowTypeThunk, uploadEventFlowTypeThunk} from "../../../features/library/library.action";
import ModalImportProcess from "../../molecules/Modals/ModalImportProcess/ModalImportProcess";
import ButtonWithOptionsDropdown from "../../atoms/Dropdowns/ButtonWithOptionsDropdown/ButtonWithOptionsDropdown";
import {checkPermissions} from "../../../utils/helper";
import {IMPORT_EXPORT_PROCESS} from "../../../utils/permissions";
import {useDispatch, useSelector} from "react-redux";
import {userHomeSelector} from "../../../features/userHome/userHome.slice";
import {BeakerIcon} from "@heroicons/react/24/outline";
import ModalGenerateProcess from "../../molecules/Modals/ModalGenerateProcess/ModalGenerateProcess";
import {
    librarySelector,
    resetGeneratedProcessId,
    resetImportProcessName
} from "../../../features/library/library.slice";
import {useNavigate} from "react-router-dom";

const LibraryBodyTable = ({data, dataCount, onRedirect, onDelete, onCopy, getData, getDataCount, section, onClickNewButton, loading=false,
                              openCopyModal, setOpenCopyModal, onConfirmCopyModal, onProcessPublish, customColumnActions=[],
                              customColumnDropdownActions=[], listLoaded=true}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [open, setOpen] = useState(false)
    const [selected, setSelected] = useState(null)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [isSubmittingGeneration, setIsSubmittingGeneration] = useState(false)
    const defaultFilter = {pagination: {currentPage: 1, offset: 0, limit:10}, txtFilter: '', sortField: null, descending: false}
    const [filters, setFilters] = useState(defaultFilter);
    const [newButtonLabel, setNewButtonLabel] = useState('');
    const [deleteQuestion, setDeleteQuestion] = useState('');
    const [openEditPublish, setOpenEditPublish] = useState(false);
    const [openImportProcess, setOpenImportProcess] = useState(false);
    const [generatingProcess, setGeneratingProcess] = useState(false);
    const {selectedTenantId, userOrganizations} = useSelector(userHomeSelector)
    const {generatedProcessId} = useSelector(librarySelector)
    const onClickImportEventFlow = () => setOpenImportProcess(true);
    const onClickGenerate = () => setGeneratingProcess(true);
    const isImportPermitted = useMemo(() => checkPermissions(userOrganizations?.[selectedTenantId]?.permissions, IMPORT_EXPORT_PROCESS) && section === 'eventflows', [userOrganizations, selectedTenantId, section])
    const importOptions = useMemo(() => (
        isImportPermitted ?
            [{label: t('library.importexport.title'), onclick: onClickImportEventFlow}] :
            []
    ), [isImportPermitted, t])

    const setTxtFilter = (txtFilter) => setFilters({...filters, pagination: {currentPage: 1, offset: 0, limit:10}, txtFilter})
    const updateData = (filters) => {
        getData(filters);
        getDataCount(filters.txtFilter)
    }

    useEffect(() => {
        if(!listLoaded) updateData(filters);
    }, [listLoaded])


    useEffect(() => {
        if(generatedProcessId) {
            navigate(`/newprocess/intro/${generatedProcessId}`);
            dispatch(resetGeneratedProcessId());
        };
    }, [generatedProcessId])


    useEffect(() => {
        setFilters(defaultFilter)
        updateData(defaultFilter)
        switch(section){
            case 'eventflows':
                setNewButtonLabel('library.newProcess')
                setDeleteQuestion('library.deleteProcess')
                break
            case 'informative':
                setNewButtonLabel('library.newInformedPath')
                setDeleteQuestion('library.deleteInformedPath')
                break
            case 'formsTemplate':
                setNewButtonLabel('library.newForm')
                setDeleteQuestion('library.deleteForm')
                break
            default:
                setNewButtonLabel('')
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[section])

    useEffect(() => {
        let updateDataTimeout = setTimeout(() => updateData(filters), 300);
        return () => clearTimeout(updateDataTimeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[filters])

    const onSaveImport = async (file, name) => {
        setIsSubmitting(true);
        try{
            await dispatch(uploadEventFlowTypeThunk({file, name})).unwrap();
            updateData(filters);
        }catch(e){
            console.log(e.message);
        }
        setOpenImportProcess(false);
        setIsSubmitting(false);
    }

    const onGenerateProcess = async (file) => {
        setIsSubmittingGeneration(true);
        try{
            await dispatch(generateEventFlowTypeThunk({file})).unwrap();
            //updateData(filters);
        }catch(e){
            console.log(e.message);
        }
        setGeneratingProcess(false);
        setIsSubmittingGeneration(false);
    }
    const setOpenSelect = (data) => {
        setSelected(data);
        setOpen(true);
    }

    const onClickCopyEventFlow = async (data) => {
        await onCopy(data.id)
    }

    const onConfirmCopyEventFlow = async () => {
        await onConfirmCopyModal()
        updateData(filters);
    }

    const editPublish = (data) => {
        setSelected(data);
        setOpenEditPublish(true);
    }

    const savePublishingStatus = async (published) => {
        await onProcessPublish(selected.id, published);
        updateData(filters);
    }

    const columns = [
        {field: 'name', label: t('tableColumns.name'), isSortable: true, hidden: false, id: 'name'},
        {field: 'description', label: t('tableColumns.description'), isSortable: true, hidden: false, id: 'description'},
        {field: 'tags', label: t('tableColumns.tags'), hidden: false, type: TAGS, id: 'tags'},
        {
            field: 'actions', type: ICON_BUTTON_LIST, hidden: true, buttons: [
                {
                    field: 'Modifica',
                    label: t('tableColumns.view'),
                    hidden: true,
                    type: ICON_BUTTON,
                    onClick: onRedirect,
                    iconType: 'modify',
                    id: 'Modifica'
                },
                ...customColumnActions,
                {
                    field: 'Operazioni',
                    id: 'Operazioni',
                    label: t('columns.more'),
                    hidden: true,
                    type: DROPDOWN_ICON,
                    onClick: (data, opt) => {
                        if (opt.id === 'DEL') setOpenSelect(data);
                        else if (opt.id === 'COPY') onClickCopyEventFlow(data);
                        else if (customColumnDropdownActions) {
                            const action = customColumnDropdownActions.find(act => act.id === opt.id);
                            action?.onClick?.(data);
                        }
                    },
                    dropdownOptions: [
                        {id: 'COPY', label: t('tableColumns.copy'), iconType: 'duplicate'},
                        ...customColumnDropdownActions,
                        {id: 'DEL', label: t('tableColumns.delete'), iconType: 'delete'},
                    ]
                },
            ], id: 'actions'
        }
    ];
    if (section === 'eventflows') {
        columns.unshift({field: 'published', type: PUBLISHING_STATUS, label: t('library.status'), hidden: false, id: 'published', onClick: (data) => editPublish(data)});
    }

    return(
        <div className='flex flex-col flex-1'>
            <div className='flex flex-grow space-x-2'>
                <div className='w-3/4'>
                    <InputWithLeadingIcon
                        value={filters.txtFilter}
                        setValue={setTxtFilter}
                        icon={<MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
                        placeholder='Cerca per nome o descrizione'
                    />
                </div>
                <div className='w-1/4 my-1'>
                    <div className='flex flex-row'>
                        {isImportPermitted ?
                            <ButtonWithOptionsDropdown label={t(newButtonLabel)} onClick={onClickNewButton} options={importOptions}/> :
                            <IconButton label={t(newButtonLabel)} size={FULL} icon={<PlusIcon className='w-4 h-4'/>} onClick={onClickNewButton}/>
                        }
                    </div>
                </div>
            </div>
            {/*{*/}
            {/*    isImportPermitted && <div className='flex flex-row justify-center'>*/}
            {/*        <IconButton label={'Genera con AI'}  icon={<BeakerIcon className='w-4 h-4'/>} onClick={onClickGenerate}/>*/}
            {/*    </div>*/}
            {/*}*/}
            <div className='flex justify-center'>
                <div className="flex flex-col flex-1">
                    <SimpleTable data={data} columns={columns} section={section} loading={loading} filters={filters} setFilters={setFilters}/>
                    <GenericQuestionModal title={deleteQuestion} recordName={selected?.name} open={open} setOpen={setOpen} onDelete={async () => {
                        await onDelete(selected.id)
                        updateData(filters);
                    }}/>
                    <ModalConfirmLibraryCopy open={openCopyModal} setOpen={setOpenCopyModal} onConfirm={onConfirmCopyEventFlow}/>
                </div>
            </div>
            <div className='flex flex-col flex-1'>
                <Paginator pageSize={10} currentPage={filters.pagination.currentPage} totalResults={dataCount} onClick={(pagination) => setFilters({...filters, pagination})}/>
            </div>
            <ModalEditPublish open={openEditPublish} setOpen={setOpenEditPublish}
                              eventFlowType={selected} onConfirm={savePublishingStatus}
            />
            <ModalImportProcess open={openImportProcess} setOpen={setOpenImportProcess} onSave={onSaveImport} isSubmitting={isSubmitting} />
            <ModalGenerateProcess open={generatingProcess} setOpen={setGeneratingProcess} onSave={onGenerateProcess} isSubmitting={isSubmittingGeneration} />
        </div>
    )
}

export default LibraryBodyTable;
