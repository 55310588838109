import React, {useRef} from "react";
import RoundButtons from "../../../atoms/Buttons/RoundButtons/RoundButtons";
import LoadingSpinner from "../../../atoms/LoadingSpinner/LoadingSpinner";
import {useTranslation} from "react-i18next";

const PDFUpload = ({ fileName, contentUrl, handleUploadPDF, pdfLoading = false }) => {
    const hiddenPDFInputRef = useRef();
    const { t } = useTranslation();

    const handlePDFUploadClickButton = () => {
        hiddenPDFInputRef.current.click();
    };

    const openPdf = () => { window.open(contentUrl, "_blank") }

    return (
        <>
                <div className="flex justify-between">
                    {fileName ?
                        <p onClick={openPdf} className='text-blue-600 underline'>{fileName}</p> :
                        <p >{t('pdf.noFile')}</p>
                    }
                    <div>
                        <RoundButtons
                            label={t("steps.upload")}
                            onClick={handlePDFUploadClickButton}
                        />
                    </div>
                    <input
                        type="file"
                        className="hidden"
                        ref={hiddenPDFInputRef}
                        onChange={handleUploadPDF}
                        accept=".pdf"
                    />
                    {pdfLoading && <LoadingSpinner />}
                </div>
        </>
    );
};

export default PDFUpload;
