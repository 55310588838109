import {classNames} from "../../../utils/helper";
import {useTranslation} from "react-i18next";
import {CheckIcon} from "@heroicons/react/24/solid";

const VerticalNavigationMultipleSelection = ({items=[], selected=[], onClickItem=()=>{}}) => {
    const { t } = useTranslation();
    return (
        <nav className="space-y-1 w-72 mx-2" aria-label="Sidebar">
            {items.map(item => {
                const current = selected.includes(item.id)
                return (
                    <div
                        key={item.id}
                        onClick={() => onClickItem(item.id)}
                        className={classNames(
                            current ? 'bg-blue-50 text-blue-600 hover:bg-blue-100' : 'text-gray-600 hover:bg-gray-50',
                            'flex items-center justify-between px-3 py-2 text-sm font-medium rounded-md cursor-pointer'
                        )}
                        aria-current={current ? 'page' : undefined}
                    >
                        <span className="truncate">{t(item.name)}</span>
                        {current && <CheckIcon className='h-5 w-5 text-blue-600'/>}
                    </div>
                )
            })}
        </nav>
    )
}

export default VerticalNavigationMultipleSelection;
