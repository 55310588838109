import Input from "../../atoms/Inputs/Input/Input";
import Button from "../../atoms/Buttons/Button/Button";
import {XMarkIcon} from "@heroicons/react/24/solid";
import {useEffect, useState} from "react";
import SidebarDataCollectionCustomFields from "./SidebarDataCollectionCustomFields";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {userHomeSelector} from "../../../features/userHome/userHome.slice";

const SidebarDataCollection = ({field, updateField, onClose}) => {
    const [name, setName] = useState(field?.name)
    const [alias, setAlias] = useState(field?.alias)
    const [caption, setCaption] = useState(field?.caption)
    const [options, setOptions] = useState(field?.options ?? {})
    const [choices, setChoices] = useState(field?.choices ?? [])

    const { selectedTenantId, userOrganizations } = useSelector(userHomeSelector);
    const enableFormFieldAlias = userOrganizations?.[selectedTenantId]?.enableFormFieldAlias ?? false;
    const enableDefaultOnFormFields = userOrganizations?.[selectedTenantId]?.enableDefaultOnFormFields ?? false;

    const {t}= useTranslation()

    useEffect(() => {
        setName(field?.name)
        setAlias(field?.alias)
        setCaption(field?.caption)
        setOptions(field?.options)
        setChoices(field?.choices)
    },[field])

    return (
        <div className='flex flex-1 flex-col bg-gray-100 w-full h-full border rounded-lg overflow-y-auto overflow-x-auto mt-1' onMouseDown={e => e.stopPropagation()}>
            <div className='flex flex-1 w-full justify-between py-4 px-6'>
                <div className='flex flex-col'>
                    <p className='text-xs leading-4 font-normal text-gray-600'>{t('properties')}</p>
                    <h1 className='text-base leading-6 font-medium text-gray-900 capitalize'>{field?.type}</h1>
                </div>
                <div className='flex items-center'>
                    <XMarkIcon className='h-6 w-6 text-black cursor-pointer' onClick={onClose} />
                </div>
            </div>
            <div className='px-6 py-4 bg-gray-50 space-y-4'>
                <Input label={t('name')} value={name} setValue={setName} disabled />
                {
                    enableFormFieldAlias ? <Input label={t('library.identifier')} value={alias} setValue={setAlias} /> : <></>
                }
                <Input label={t('caption')} value={caption} setValue={setCaption} />
                <SidebarDataCollectionCustomFields type={field?.type} options={options} setOptions={setOptions} choices={choices} setChoices={setChoices} enableFormFieldAlias={enableFormFieldAlias} enableDefaultOnFormFields={enableDefaultOnFormFields}/>
            </div>
            <div className='flex items-center justify-end px-6 py-4'>
                <Button label={t('save')} size='lg' variant='blue' onClick={() => updateField({...field, name, caption, options, choices, alias}, onClose)} />
            </div>
        </div>
    )
}

export default SidebarDataCollection;
