import client from "./client";
import {encrypt} from "../utils/helper";

export const uploadSelfSignedDocumentCall = async (file, signatureType) => {
    let data = new FormData();
    data.append('file', file);
    data.append('signatureType', signatureType);
    try {
        return await client.post(`/api/doc-api/doc-model/upload-self-signed-document`,
            data, {
                headers: {
                    'processData': false,
                }
            });
    }catch(e) {
        console.log('Error on Document upload: ', e);
    }
}

export const uploadSelfSignedDocumentAndSignCadesCall = async (file, pin, otp, deviceId) => {
    let data = new FormData();
    data.append('file', file);
    data.append('pin', 'ENC:' + encrypt(pin));
    data.append('otp', otp ?? '');
    data.append('deviceId', deviceId ?? '');
    try {
        return await client.post(`/api/doc-api/doc-model/upload-self-signed-document-and-sign-cades`,
            data, {
                headers: {
                    'processData': false,
                }
            });
    }catch(e) {
        console.log('Error on Document upload: ', e);
        if (e?.message) {
            const message = e.message;
            if (message) {
                throw new Error(message);
            }
        }
    }
}

export const startSelfSignedDocumentCall = async(processId) => {
    return await client.post(`/api/doc-api/event-flow/${processId}/start-self-signed-document`, {})
}
