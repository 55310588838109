import {createAsyncThunk} from "@reduxjs/toolkit";
import {
    addFieldDataCollectFormCall,
    addStepInformativaCall,
    createDataCollectFormCall,
    createInformativaCall,
    createNewDocumentCall,
    deleteFieldDataCollectFormCall,
    deleteInformativaCall,
    deleteStepInformativaCall, downloadPdfToDisplay,
    getDataCollectFormDetailsCall,
    getDataCollectionsCall,
    getDocumentalProcessItemsCall,
    getInformativaDetailsCall,
    getInformativaStepsTypesCall,
    getInformativeCall,
    getModelsDetailsCall,
    massiveSetDataCollectionRuleCall,
    reorderDocumentalProcessItemsCall,
    reorderFieldsDataCollectFormCall,
    reorderStepInformativaCall, reorderSubmodelsCall,
    saveModelsCall,
    sendProcessCall,
    updateDataCollectFormCall,
    updateFieldDataCollectFormCall,
    updateInformativaCall,
    updateProcessCall,
    updateStepInformativaCall,
    uploadStepPhotoCall
} from "../../api/documentalProcess";
import {
    addInformativaToRecipients,
    deleteInformativaFromRecipients,
    linkRecipientToDataCollection,
    setDataCollectionSenderOwner,
    unlinkRecipientToDataCollection
} from "../recipients/recipients.slice";
import {toast} from "react-hot-toast";
import Alert from "../../components/molecules/Alerts/Alert";
import {SUCCESS} from "../../components/molecules/Alerts/types";
import {getSummaryDetailsCall} from "../../api/summary";
import {addBlock} from "../documentBuilder/documentBuilder.slice";
import {isValidHttpUrl} from "../../utils/helper";

export const upsertProcessThunk = createAsyncThunk(
    'documentalProcess/upsertProcessThunk',
    async ({name, description, languages, published, tags, processId, isDocument}) => {
        let response;
        if(!processId) response = await createNewDocumentCall(name, description, languages, tags, isDocument);
        else response = await updateProcessCall(processId, name, description, languages, published, tags, isDocument);
        return response.data;
    }
);

export const getProcessDetailsThunk = createAsyncThunk(
    'documentalProcess/getProcessDetailsThunk',
    async ({id}, { dispatch }) => {
        const response = await getSummaryDetailsCall(id);
        if(response.status === 200 && response?.data?.model?.placeholders) dispatch(addBlock(response?.data?.model?.placeholders))
        return response.data
    }
);

export const addInformativaThunk = createAsyncThunk(
    'documentalProcess/addInformativaThunk',
    async ({name, description, tags, selectedRecipientsIds, id}, { dispatch}) => {
        const response = await createInformativaCall({name, description, tags, id});
        if(response.status < 300) dispatch(addInformativaToRecipients({id: response.data.id, selectedRecipientsIds}))
        return response.data
    }
);

export const createUnlinkedInformativa = createAsyncThunk(
    'documentalProcess/createUnlinkedInformativa',
    async ({setInformativaId}) => {
        const response = await createInformativaCall({name:'Nuova Informativa'});
        if(response.status === 200) setInformativaId(response.data.id)
        return response.data
    }
);

export const updateInformativaThunk = createAsyncThunk(
    'documentalProcess/updateInformativaThunk',
    async ({informativaId, data, alertUser}) => {
        const response = await updateInformativaCall(informativaId, {...data, hidden: false});
        if(alertUser) toast.custom((t) => ( <Alert type={SUCCESS} message='Operazione effettuata con successo' onClose={() => toast.dismiss(t.id)}/> ))
        return response.data
    }
);

export const deleteInformativaThunk = createAsyncThunk(
    'documentalProcess/deleteInformativaThunk',
    async ({informativaId}, { dispatch}) => {
        const response = await deleteInformativaCall(informativaId);
        if(response.status === 200) dispatch(deleteInformativaFromRecipients(informativaId))
        return informativaId
    }
);

export const getStepsTypes = createAsyncThunk(
    'documentalProcess/getStepsTypes',
    async () => {
        const response = await getInformativaStepsTypesCall();
        return response.data
    }
);

export const getInformativaDetailsThunk = createAsyncThunk(
    'documentalProcess/getInformativaDetailsThunk',
    async ({id}) => {
        const response = await getInformativaDetailsCall(id);
        return response.data
    }
);

export const addInformativaStepThunk = createAsyncThunk(
    'documentalProcess/addInformativaStepThunk',
    async ({id, name, weight, detailTypeId}, {dispatch}) => {
        try{
            const response = await addStepInformativaCall(id, {name, weight, detailTypeId});
            return {id, data: response.data}
        }catch(e){
            console.log('documentalProcess/addInformativaStepThunk ',{id})
            dispatch(getInformativaDetailsThunk({id}))
            return {id}
        }
    }
);

export const updateStepInformativaThunk = createAsyncThunk(
    'documentalProcess/updateStepInformativaThunk',
    async ({data, informativaId}) => {
        const response = await updateStepInformativaCall(data.id, data);
        return {id: informativaId, stepId: response.data.id, data: response.data}
    }
);

export const deleteStepInformativaThunk = createAsyncThunk(
    'documentalProcess/deleteStepInformativaThunk',
    async ({stepId, informativaId}) => {
        await deleteStepInformativaCall(stepId);
        return {informativaId, stepId}
    }
);

export const uploadStepInformativaPhotoThunk = createAsyncThunk(
    'documentalProcess/uploadStepInformativaPhotoThunk',
    async ({stepId, image, informativaId}, {dispatch}) => {
        await uploadStepPhotoCall(stepId, image);
        //return {id: informativaId, stepId, data: response.data}
        dispatch(getInformativaDetailsThunk({id: informativaId}))

    }
);

export const reorderStepsInformativaThunk = createAsyncThunk(
    'documentalProcess/reorderStepsInformativaThunk',
    async ({informativaId, orderedStepsList}, {dispatch}) => {
        try{
            const orderedListIdSteps = orderedStepsList?.map(step => step.id)
            const response = await reorderStepInformativaCall(informativaId, orderedListIdSteps);
            return response.data
        }catch(e){
            console.log(e)
            console.log('documentalProcess/reorderStepsInformativaThunk ', informativaId)
            dispatch(getInformativaDetailsThunk({id: informativaId}))
        }
    }
);

export const getInformativeThunk = createAsyncThunk(
    'documentalProcess/getInformativeThunk',
    async (props,{getState}) => {
        const {newDocument} = getState().documentalProcess;
        const response = await getInformativeCall(newDocument.id);
        return response.data
    }
);

export const getDataCollectionsThunk = createAsyncThunk(
    'documentalProcess/getDataCollectionsThunk',
    async (props,{getState}) => {
        const {newDocument} = getState().documentalProcess;
        const response = await getDataCollectionsCall(newDocument.id);
        return response.data
    }
);

export const addDataCollectFormThunk = createAsyncThunk(
    'documentalProcess/addDataCollectFormThunk',
    async ({name, description, id, items = [], tags, isSenderOwner, selectedRecipients}, {dispatch}) => {
        const response = await createDataCollectFormCall({name, description, tags, id, template: {items}});
        dispatch(setDataCollectionSenderOwner({dataCollectionId: response.data.id, isSenderOwner}))
        if(response?.data?.id && selectedRecipients) {
            Object.keys(selectedRecipients).forEach(key => {
                if(key!== 'SENDER') {
                    if (selectedRecipients[key])
                        dispatch(linkRecipientToDataCollection({
                            recipientId: key,
                            dataCollectionId: response.data.id
                        }))
                    else
                        dispatch(unlinkRecipientToDataCollection({
                            recipientId: key,
                            dataCollectionId: response.data.id
                        }))
                }
            })
        }
        return {...response.data, isSenderOwner}
    }
);

export const getDataCollectFormDetailsThunk = createAsyncThunk(
    'documentalProcess/getDataCollectFormDetailsThunk',
    async ({dataCollectId}) => {
        const response = await getDataCollectFormDetailsCall(dataCollectId);
        return response.data
    }
);

export const createUnlinkedDataCollectionThunk = createAsyncThunk(
    'documentalProcess/createUnlinkedDataCollectionThunk',
    async ({setDataCollectionId}) => {
        const response = await createDataCollectFormCall({name:'Nuova Form', template: {items:[]}});
        if(response.status === 200) setDataCollectionId(response.data.id)
        return response.data
    }
);

export const updateDataCollectFormThunk = createAsyncThunk(
    'documentalProcess/updateDataCollectFormThunk',
    async ({dataCollectId, name, description, tags, alertUser, alias},{getState}) => {
        const form = getState().documentalProcess.dataCollections[dataCollectId]
        const response = await updateDataCollectFormCall(dataCollectId,{id: dataCollectId, name, tags, description, alias, lang:'', template: form.template, hidden: false});
        if(alertUser) toast.custom((t) => ( <Alert type={SUCCESS} message='Operazione effettuata con successo' onClose={() => toast.dismiss(t.id)}/> ))
        return response.data
    }
);

export const addDataCollectFormFieldThunk = createAsyncThunk(
    'documentalProcess/addDataCollectFormFieldThunk',
    async ({dataCollectId, name, type}) => {
        const response = await addFieldDataCollectFormCall(dataCollectId, {type, caption: name});
        return {id: dataCollectId, items: response.data}
    }
);

export const updateDataCollectFormFieldThunk = createAsyncThunk(
    'documentalProcess/updateDataCollectFormFieldThunk',
    async ({dataCollectId, item}) => {
        const response = await updateFieldDataCollectFormCall(dataCollectId, item);
        return {id: dataCollectId, items: response.data}
    }
);

export const deleteDataCollectFormFieldThunk = createAsyncThunk(
    'documentalProcess/deleteDataCollectFormFieldThunk',
    async ({dataCollectId, item}) => {
        const response = await deleteFieldDataCollectFormCall(dataCollectId, item);
        return {id: dataCollectId, items: response.data}
    }
);

export const reorderDataCollectFormFieldsThunk = createAsyncThunk(
    'documentalProcess/reorderDataCollectFormFieldsThunk',
    async ({dataCollectId, orderedItemsIdsList}) => {
        const response = await reorderFieldsDataCollectFormCall(dataCollectId, orderedItemsIdsList);
        return {id: dataCollectId, items: response.data}
    }
);

export const massiveSetDataCollectionRuleThunk = createAsyncThunk(
    'documentalProcess/massiveSetDataCollectionRuleThunk',
    async ({dataCollectId},{getState}) => {
        const {rules} = getState().documentalProcess?.dataCollections?.[dataCollectId]?.template
        if(rules) {
            const response = await massiveSetDataCollectionRuleCall(dataCollectId, rules);
            return {id: dataCollectId, rules: response.data}
        }
    }
);

export const getDocumentalProcessItemsThunk = createAsyncThunk(
    'documentalProcess/getDocumentalProcessItemsThunk',
    async ({recipientId},{getState}) => {
        const { newDocument } = getState().documentalProcess;
        const response = await getDocumentalProcessItemsCall(newDocument.id, recipientId);
        return {userId: recipientId, data: response.data}
    }
);

export const reorderDocumentalProcessItemsThunk = createAsyncThunk(
    'documentalProcess/reorderDocumentalProcessItemsThunk',
    async (props,{getState}) => {
        const { newDocument, documentalProcessItems } = getState().documentalProcess;
        Object.keys(documentalProcessItems).forEach(userId => {
            const data = documentalProcessItems[userId].map(el => {
                if (el?.orderElementId !== 'SENDER') {
                    return el?.orderElementId;
                } else {
                    return el?.id;
                }

            }) ?? []
            reorderDocumentalProcessItemsCall(newDocument.id, userId, data);
        })
    }
);

export const setOwnerDataCollectionThunk = createAsyncThunk(
    'documentalProcess/setOwnerDataCollectionThunk',
    async ({dataCollectId, isSenderOwner, selectedRecipients}, {dispatch}) => {
        try{
            dispatch(setDataCollectionSenderOwner({dataCollectionId: dataCollectId, isSenderOwner}))
            if(dataCollectId && selectedRecipients) {
                Object.keys(selectedRecipients).forEach(key => {
                    if(key!== 'SENDER') {
                        if (selectedRecipients[key])
                            dispatch(linkRecipientToDataCollection({
                                recipientId: key,
                                dataCollectionId: dataCollectId
                            }))
                        else
                            dispatch(unlinkRecipientToDataCollection({
                                recipientId: key,
                                dataCollectionId: dataCollectId
                            }))
                    }
                })
            }
            return {dataCollectId, isSenderOwner}
        }catch(e) {
            console.log(e)
        }
    }
);

export const saveModelsThunk = createAsyncThunk(
    'documentalProcess/saveModelsThunk',
    async ({messageSubject, messageBody, workspaces},{getState}) => {
        const { newDocument, senderReminderComplete, avoidLtArchive} = getState().documentalProcess;
        const {recipients} = getState().recipients;
        const redirectLinks={}
        for (const id in recipients) if (recipients.hasOwnProperty(id)) {
            redirectLinks[id] = ''
            if (recipients[id].redirectLink) {
                if (isValidHttpUrl(recipients[id].redirectLink))
                    redirectLinks[id] = recipients[id].redirectLink
                else throw new Error('summary.ErrorRedirectLink')
            }
        }

        const recipientsEmail = {}
        for (const id in recipients) {
            recipientsEmail[id] = {noEmail: recipients[id]?.emailDetails?.noEmail ?? false, noEmailSigned: recipients[id]?.emailDetails?.noEmailSigned ?? false, privateMessage: recipients[id]?.emailDetails?.privateMessage}
        }

        const response = await saveModelsCall(newDocument.id, {saveAll: true, messageSubject, messageBody, workspaces, redirectLinks, recipients: recipientsEmail, senderReminderComplete, avoidLtArchive});
        return response.data
    }
);

export const getModelsDetailsThunk = createAsyncThunk(
    'documentalProcess/getModelsDetailsThunk',
    async (props,{getState}) => {
        const { newDocument } = getState().documentalProcess;
        const response = await getModelsDetailsCall(newDocument.id);
        return response.data
    }
);

export const sendDocumentThunk = createAsyncThunk(
    'documentalProcess/sendDocumentThunk',
    async (props,{getState}) => {
        const { newDocument, messageSubject, messageBody, privateMessages, expirationDate, reminderIntervalDays, selectedDevice, selectedWorkspaces, senderReminderComplete, avoidLtArchive} = getState().documentalProcess;
        const {recipients} = getState().recipients;
        const actors=[]
        for (const id in recipients) if (recipients.hasOwnProperty(id)) {
            const actor = {
                roleId: id,
                graphConsentModelId: recipients?.[id]?.graphConsentModelId,
                hasGraphConsent: recipients?.[id]?.hasGraphConsent
            }
            if(recipients[id].redirectLink) {
                if (isValidHttpUrl(recipients[id].redirectLink))
                    actor.redirectLink = recipients[id].redirectLink
                else throw new Error('summary.ErrorRedirectLink')
            }
            actors.push(actor)
        }

        const recipientsEmail = {}
        for (const id in recipients) {
            recipientsEmail[id] = {noEmail: recipients[id]?.emailDetails?.noEmail ?? false, noEmailSigned: recipients[id]?.emailDetails?.noEmailSigned ?? false, privateMessage: recipients[id]?.emailDetails?.privateMessage}
        }

        const data = {
            messageSubject,
            privateMessages,
            mailBody: messageBody,
            expirationDate,
            reminderIntervalDays,
            deviceId: selectedDevice,
            recipients: recipientsEmail,
            actors,
            selectedWorkspaces,
            senderReminderComplete,
            avoidLtArchive
        }
        const response = await sendProcessCall(newDocument.id, data);
        if (response.status === 200 && response.data.redirectUrl) {
            window.location.href = response.data.redirectUrl
        }
        return response.data
    }
);

export const downloadPdfToDisplayThunk = createAsyncThunk(
    'documentalProcess/downloadPdfToDisplayThunk',
    async({url}) => {
        try {
            const response = await downloadPdfToDisplay(url);

            const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
            return URL.createObjectURL(pdfBlob);
        } catch (error) {
            console.error('Error downloading PDF:', error);
        }
    }
)



