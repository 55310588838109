import Input from "../../atoms/Inputs/Input/Input";
import SingleCheckbox from "../../atoms/SingleCheckbox/SingleCheckbox";
import Button from "../../atoms/Buttons/Button/Button";
import LinkedLabel from "../../atoms/LinkedLabel/LinkedLabel";
import {useTranslation} from "react-i18next";
import htmr from "htmr";

const RegistrationForm = ({onRegister, emailDisabled=false, formValues, setFormValues}) => {
    const {t} = useTranslation()

    const setFormValue = (field, value) => setFormValues({...formValues, [field]: value})

    return(
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form className="space-y-6">
                <Input
                    name='firstname'
                    label={t('name')}
                    type='text'
                    value={formValues?.['givenName'] ?? ''}
                    setValue={(value) => setFormValue('givenName', value)}
                    autofocus
                />
                <Input
                    name='lastname'
                    label={t('surname')}
                    type='text'
                    value={formValues?.['familyName'] ?? ''}
                    setValue={(value) => setFormValue('familyName', value)}
                    autoComplete='off'
                />
                <Input
                    name='email'
                    label={t('email')}
                    value={formValues?.['email'] ?? ''}
                    setValue={(value) => setFormValue('email', value)}
                    disabled={emailDisabled}
                    autoComplete='off'
                />
                <Input
                    name='newPassword'
                    type='password'
                    label={t('password')}
                    value={formValues?.['newPassword'] ?? ''}
                    setValue={(value) => setFormValue('newPassword', value)}
                    autoComplete='new-password'
                />
                <Input
                    name='confirmPassword'
                    type='password'
                    label={t('confirmPassword')}
                    value={formValues?.['confirmPassword'] ?? ''}
                    setValue={(value) => setFormValue('confirmPassword', value)}
                    autoComplete='new-password'
                />
                <div className="mt-3">
                    <SingleCheckbox
                        value={formValues?.['acceptTerms'] ?? false}
                        setValue={(value) => setFormValue('acceptTerms', value)}
                        label={htmr(t('registration.checkboxTerms'))}
                    />
                </div>
                <div className="mt-3">
                    <SingleCheckbox
                        value={formValues?.['acceptMarketing'] ?? false}
                        setValue={(value) => setFormValue('acceptMarketing', value)}
                        label={t('registration.marketingConsent')}
                    />
                </div>
                <div className="h-10">
                    <Button
                        variant='blue'
                        label={t('registration.proceed')}
                        size='full'
                        onClick={onRegister}
                    />
                </div>
            </form>
            <div className='flex justify-center items-center pt-8'>
                <LinkedLabel link={'/login'}>{t('cancel')}</LinkedLabel>
            </div>
        </div>
    )
}

export default RegistrationForm;
