import React from "react";
import SimpleTable from "../../molecules/Tables/SimpleTable/SimpleTable";
import {ARCHIEVE_STATUS, ICON_BUTTON, REMAINING_SIGNATURES} from "../../atoms/SimpleTableElement/SimpleTable.types";

const SignatureBalanceList = ({data}) => {
    const columns = [
        {field: 'description', label: 'tableColumns.description', hidden: false},
        {field: 'sent', label: 'tableColumns.sent', hidden: false},
        {field: 'remaining', label: 'tableColumns.remaining', hidden: false, type: REMAINING_SIGNATURES},
    ]


    return (
        <div className="flex flex-col flex-1">
            <SimpleTable data={data} columns={columns} />
        </div>
    )
}

export default SignatureBalanceList;
