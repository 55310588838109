import {Menu, Transition} from "@headlessui/react";
import {Fragment, useEffect} from "react";
import {classNames, openSupportPage} from "../../../../utils/helper";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {authSelector, logout, resetState as resetAuth} from "../../../../features/auth/auth.slice";
import {resetState as resetDocumentalProcess} from "../../../../features/documentalProcess/documentalProcess.slice";
import {resetState as resetDocumentalBuilder} from "../../../../features/documentBuilder/documentBuilder.slice";
import {resetLibrary} from "../../../../features/library/library.slice";
import {resetState as navigationReset} from "../../../../features/navigation/navigation.slice";
import {resetState as peopleReset} from "../../../../features/people/people.slice";
import {resetState as recipientsReset} from "../../../../features/recipients/recipients.slice";
import {resetState as homeReset} from "../../../../features/userHome/userHome.slice";
import {getCurrentUser} from "../../../../features/auth/auth.actions";
import {useTranslation} from "react-i18next";

const ProfileDropdown = () => {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const { user } = useSelector(authSelector)

    useEffect(() => {
        if(!user.id) {
            dispatch(getCurrentUser());
        }
    }, [user])

    const signout = async () => {
        dispatch(resetAuth());
        dispatch(resetDocumentalProcess());
        dispatch(resetDocumentalBuilder());
        dispatch(resetLibrary());
        dispatch(navigationReset());
        dispatch(peopleReset());
        dispatch(recipientsReset());
        dispatch(homeReset());
        sessionStorage.clear();
        dispatch(logout({}))
    }

    return (
        <Menu as="div" className="ml-3 relative">
            {({ open }) => (
                <>
                    <div>
                        <Menu.Button className="bg-white rounded-full block text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                            <span className="sr-only">Open user menu</span>
                            <img
                                className="h-8 w-8 rounded-full"
                                src={user.avatar}
                                alt=""
                            />
                        </Menu.Button>
                    </div>
                    <Transition
                        show={open}
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <Menu.Items
                            static
                            className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg z-30 py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                        >
                            <Menu.Item>
                                {({ active }) => (
                                    <Link
                                        to="/Settings/PersonalData"
                                        className={classNames(
                                            active ? 'bg-gray-100' : '',
                                            'block px-4 py-2 text-sm text-gray-700'
                                        )}
                                    >
                                        {t('settings.title')}
                                    </Link>
                                )}
                            </Menu.Item>
                            <Menu.Item>
                                {({ active }) => (
                                    <div
                                        onClick={openSupportPage}
                                        className={classNames(
                                            active ? 'bg-gray-100' : '',
                                            'block px-4 py-2 text-sm text-gray-700 cursor-pointer'
                                        )}
                                    >
                                        {t('support')}
                                    </div>
                                )}
                            </Menu.Item>
                            <Menu.Item>
                                {({ active }) => (
                                    <Link
                                        onClick={signout}
                                        to="/login"
                                        className={classNames(
                                            active ? 'bg-gray-100' : '',
                                            'block px-4 py-2 text-sm text-gray-700'
                                        )}
                                    >
                                        {t('exit')}
                                    </Link>
                                )}
                            </Menu.Item>

                            <Menu.Item>
                                {() => (
                                    <p
                                        className='block px-4 pt-2 pb-1 text-xs text-gray-500 italic'
                                    >{t('version',{version: '22.09.0018'})}</p>
                                )}
                            </Menu.Item>
                        </Menu.Items>
                    </Transition>
                </>
            )}
        </Menu>
    )
}

export default ProfileDropdown;
