import SimpleStackedList from "../../../molecules/StackedLists/SimpleStackedList/SimpleStackedList";
import PageWithTitleAndFooter from "../../Templates/PageWithTitleAndFooter/PageWithTitleAndFooter";
import React from "react";
import {useTranslation} from "react-i18next";

const Onboarding = ({next, previous, goBackHome, data}) => {
    const {t} = useTranslation();
    return(
        <PageWithTitleAndFooter title={t('documentalProcess.onboarding.title')} footer={true} next={next} previous={previous} closeAction={goBackHome}>
            <div className='flex flex-col w-3/12 justify-center items-center'>
                <SimpleStackedList
                    title={t('documentalProcess.onboarding.subtitle')}
                    data={data}/>
            </div>
        </PageWithTitleAndFooter>
    )
}

export default Onboarding
