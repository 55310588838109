import NavbarWithTenants from "../../../molecules/Headers/NavbarWithTenants/NavbarWithTenants";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {userHomeSelector} from "../../../../features/userHome/userHome.slice";
import Banner from "../../../molecules/Banners/Banner";
import isEmpty from "lodash/isEmpty";
import {getLanguagesThunk} from "../../../../features/userHome/userHome.actions";


const PageWithHeader = ({children}) => {
    const { userOrganizations, selectedTenantId, maintenanceMode } = useSelector(userHomeSelector);
    const banners = userOrganizations?.[selectedTenantId]?.banners ?? [];

    return(
        <div className="flex flex-col h-screen overflow-hidden bg-white">
            {
                !isEmpty(banners) && banners.map(banner => <Banner key={banner.id} id={banner.id} type={banner.type} message={banner.message} active={banner.active} closable={banner.closable}/>)
            }
            <NavbarWithTenants
                tenants={Object.values(userOrganizations).map(org => {return {value: org.id, label: org.description}})}
            />
            <div className='w-screen h-full flex justify-center py-2 overflow-auto'>
                {!maintenanceMode && <div className="flex w-full flex-col items-start flex-grow max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
                    {children}
                </div>}
                {maintenanceMode && <div className='flex flex-col items-center justify-center w-full h-full'>
                    <h1 className='text text-2xl font-bold mb-3'>Manutenzione in corso</h1>
                    <p>Stiamo effettuando degli aggiornamenti per migliorare il nostro servizio. Torneremo online a
                        breve. Grazie per la vostra pazienza!</p>
                    <hr className='w-1/2 my-4'/>
                    <h1 className='text text-2xl font-bold mb-3 mt-6'>Maintenance in progress</h1>
                    <p>We are making updates to improve our service. We will be back online shortly. Thanks for your patience!</p>
                </div>}
            </div>
        </div>
    )
}

export default PageWithHeader;
