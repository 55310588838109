import {createEntityAdapter, createSlice} from "@reduxjs/toolkit";
import {
    deleteGraphConsentModelThunk,
    getGraphConsentModelThunk,
    getGraphConsentsThunk,
    getModelPlaceholdersThunk,
    uploadModelThunk
} from "./docModels.actions";

const docModelsAdapter = createEntityAdapter()
const initialState = docModelsAdapter.getInitialState({
    graphConsentModelInfo: {},
    placeholders: [],
    selectedBlockId: null,

    loading: false,
    waitingGraphConsents: false,
    graphConsentsCount: 0,
    graphConsents: []
})

const docModelsSlice = createSlice({
    name: 'docModels',
    initialState,
    reducers: {
        addBlock: (state, action) => {
            state.graphConsentModelInfo.placeholders.push(...action.payload)
        },
        setBlock: (state, action) => {
            state.graphConsentModelInfo.placeholders = [...state.graphConsentModelInfo.placeholders.map((block) => {
                return block.id === action.payload.id ? {...action.payload} : block
            })]
        },
        deleteBlock: (state, action) => {
            state.graphConsentModelInfo.placeholders= state.graphConsentModelInfo.placeholders.filter(block => block.id !== action.payload.id)
        },
        selectBlock: (state, action) => {
            state.selectedBlockId = action.payload
        },
        resetGraphConsentModelInfo: (state) => {
            state.graphConsentModelInfo = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getGraphConsentsThunk.pending, (state) => {
                state.waitingGraphConsents = true
                state.graphConsents = []
                state.graphConsentsCount = null
            })
            .addCase(getGraphConsentsThunk.fulfilled, (state, action) => {
                state.waitingGraphConsents = false
                state.graphConsents = action.payload.models ?? []
                state.graphConsentsCount = action.payload.count ?? 0
            })
            .addCase(getGraphConsentsThunk.rejected, (state) => {
                state.waitingGraphConsents = false
                state.graphConsentsCount = 0
            })
            // Update for other actions in the similar way
            .addCase(deleteGraphConsentModelThunk.fulfilled, (state, action) => {
                state.graphConsents = state.graphConsents.filter(gc => gc.id !== action.payload)
            })
            .addCase(getGraphConsentModelThunk.pending, (state) => {
                state.graphConsentModelInfo = null;
            })
            .addCase(getGraphConsentModelThunk.fulfilled, (state, action) => {
                state.graphConsentModelInfo = action.payload
            })
            .addCase(uploadModelThunk.pending, (state) => {
                state.graphConsentModelInfo = null
                state.loading = true
            })
            .addCase(uploadModelThunk.fulfilled, (state, action) => {
                state.graphConsentModelInfo = action.payload
                state.loading = false
            })
            .addCase(uploadModelThunk.rejected, (state) => {
                state.loading = false
            })
            .addCase(getModelPlaceholdersThunk.fulfilled, (state, action) => {
                state.placeholders = action.payload
            })
    }
})

export const docModelsSelector = (state) => state.docModels;

export const {addBlock, setBlock, selectBlock, deleteBlock, resetGraphConsentModelInfo} = docModelsSlice.actions;

export default docModelsSlice.reducer;

