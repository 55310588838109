import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import Input from "../../../../atoms/Inputs/Input/Input";
import Textarea from "../../../../atoms/TextArea/Textarea";
import SelectWithBadge from "../../../AdvancedSelect/SelectWithBadge/SelectWithBadge";
import SingleCheckbox from "../../../../atoms/SingleCheckbox/SingleCheckbox";
import {classNames} from "../../../../../utils/helper";
import ModalNewPathItem from "../ModalNewPathItem";
import ErrorMessage from "../../../../atoms/ErrorMessage/ErrorMessage";

const ModalNewForm = ({open, setOpen, recipients, onCreate = () => {}, hasSenderDataCollectionLinked=false, insertTag=() => {}, deleteTag=() => {}}) => {
    const {t} = useTranslation()
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [tags, setTags] = useState();
    const [selectedRecipients, setSelectedRecipients] = useState({})
    const [selectSender, setSelectSender] = useState(false)
    const isRecipientsSelected = Object.keys(selectedRecipients).some(recId => selectedRecipients[recId])
    const disableConfirm = (!Object.keys(selectedRecipients).some(key => selectedRecipients[key]) && !selectSender) || !name

    useEffect(() => {
        if(selectSender) setSelectedRecipients({})
    },[selectSender])

    useEffect(() => {
        if(isRecipientsSelected) setSelectSender(false)
    },[selectedRecipients])

    const selectAllRecipients = () => {
        const allRecipientsSelected = {}
        Object.keys(recipients).forEach(id => allRecipientsSelected[id] = true)
        setSelectedRecipients(allRecipientsSelected)
    }

    const onConfirm = () => {
        onCreate(name, description, tags, {...selectedRecipients, 'SENDER': selectSender})
    }

    const setValue = (id) => setSelectedRecipients({...selectedRecipients, [id]:!selectedRecipients[id]})
    return(
        <ModalNewPathItem onConfirm={onConfirm} setOpen={setOpen} open={open} disableConfirm={disableConfirm} title='Nuova Scheda di raccolta dati'>
            <div className='flex justify-center divide-x w-full flex-grow'>
                <div className='w-full pr-4'>
                    <h1 className="text-lg leading-6 font-medium mb-2">{t('informations')}</h1>
                    <Input label={t('title')} placeholder={t('form.giveTitle')} value={name} setValue={setName}/>
                    <Textarea label={t('description')} value={description} setValue={setDescription}/>
                    <SelectWithBadge label={t('labels')} selectedTags={tags} setSelectedTags={setTags} insertTag={insertTag} deleteTag={deleteTag} hasDelete={true}/>
                </div>
                <div className='w-full pl-4'>
                    <h1 className="text-lg leading-6 font-medium mb-2">{t('forms.whoFills')}</h1>
                    <h1 className="text-sm leading-4 font-normal text-gray-500 mb-2">{t('forms.whoFillsMessage')}</h1>
                    <div className='space-y-2 select-none'>
                        <div className='flex flex-col space-y-2'>
                            <div className={classNames(isRecipientsSelected ? 'bg-blue-100':'bg-gray-50','flex flex-col justify-center flex-1 border p-4 rounded cursor-pointer')} onClick={selectAllRecipients}>
                                <p className='text-sm leading-5 font-semibold'>{t('forms.recipients')}</p>
                                {recipients && Object.values(recipients).map(rec => <div key={rec.id} className='flex items-center space-x-2'><SingleCheckbox label={`${rec.name}`} value={selectedRecipients[rec.id]} setValue={() => setValue(rec.id)} /></div>)}
                            </div>
                            <p className='text-center'>{t('or')}</p>
                            <div className={classNames(selectSender ? 'bg-blue-100' : 'bg-gray-50','flex flex-col justify-center flex-1 border p-4 rounded cursor-pointer')} onClick={() => setSelectSender(!hasSenderDataCollectionLinked)}>
                                <p className='text-sm leading-5 font-semibold'>{t('forms.sender')}</p>
                                <p className='text-sm leading-5 font-normal'>{t('forms.you')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ModalNewPathItem>
    )
}

export default ModalNewForm;
