import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import DevicesList from "../../../../organisms/DevicesList/DevicesList";
import ListMenu from "../../../../molecules/ListMenu/ListMenu";
import {FULL} from "../../../../atoms/Buttons/sizes";
import {PlusIcon} from "@heroicons/react/24/solid";
import IconButton from "../../../../atoms/Buttons/IconButton/IconButton";
import AddDeviceModal from "../../../../molecules/Modals/AddDeviceModal/AddDeviceModal";
import {useTranslation} from "react-i18next";
import GenericQuestionModal from "../../../../molecules/Modals/GenericModal/GenericQuestionModal";
import UpdateDeviceModal from "../../../../molecules/Modals/UpdateDeviceModal/UpdateDeviceModal";
import {librarySelector, setQrCode} from "../../../../../features/library/library.slice";
import {
    addDeviceThunk,
    countDevices,
    deleteDeviceThunk,
    getDevices, updateDeviceThunk
} from "../../../../../features/library/library.action";

const Devices = () => {
    const dispatch = useDispatch();
    const {t} = useTranslation()
    const {devices, devicesCount, qrCode, listDevicesLoaded} = useSelector(librarySelector)
    const [filters, setFilters] = useState({pagination: {currentPage: 1, offset: 0, limit:10}, txtFilter: ''});
    const [openModalAdd, setOpenModalAdd] = useState(false);
    const [openModalUpdate, setOpenModalUpdate] = useState(false);
    const [openModalDelete, setOpenModalDelete] = useState(false);
    const [selectedDeviceToDelete, setSelectedDeviceToDelete] = useState()
    const [selectedDeviceToUpdate, setSelectedDeviceToUpdate] = useState()

    useEffect(() => {
        dispatch(setQrCode(null))
        if(!openModalAdd || !openModalDelete || !listDevicesLoaded) updateEvents();
    },[openModalAdd, openModalDelete, listDevicesLoaded])

    const generateQrCode = (deviceName) => {
        dispatch(addDeviceThunk({label: deviceName}))
    }

    useEffect(() => {
        const callbackTimer = setTimeout(updateEvents, 300);
        return () => clearTimeout(callbackTimer);
    }, [filters])

    const updateEvents = () => {
        dispatch(getDevices({txtFilter: filters.txtFilter, offset: filters?.pagination?.offset, limit: filters?.pagination?.limit}))
        dispatch(countDevices({txtFilter: filters.txtFilter}))
    }

    const selectDeleteDevice = (data) => {
        setSelectedDeviceToDelete(data)
        setOpenModalDelete(true)
    }

    const deleteDevice = async (data) => {
        await dispatch(deleteDeviceThunk({deviceId: data?.['id']}))
        updateEvents()
    }

    const selectUpdateDevice = (data) => {
        setSelectedDeviceToUpdate(data)
        setOpenModalUpdate(true)
    }

    const updateDevice = async (label) => {
        dispatch(updateDeviceThunk({deviceId: selectedDeviceToUpdate?.['id'], label}))
    }

    const addDeviceButton = <IconButton label={t('settings.addDevice')} size={FULL} icon={<PlusIcon className='w-4 h-4'/>} onClick={() => setOpenModalAdd(true)}/>

    return(
        <ListMenu
            elementsCount={devicesCount}
            filters={filters}
            setFilters={setFilters}
            sideComponent={addDeviceButton}
        >
            <div className="flex flex-col flex-1 my-2">
                <DevicesList data={devices.map(dev => {return{...dev, identifier: dev.id}})} deleteDevice={selectDeleteDevice} updateDevice={selectUpdateDevice} filters={filters} setFilters={setFilters}/>
            </div>
            {openModalAdd && <AddDeviceModal open={openModalAdd} setOpen={setOpenModalAdd} qrCode={qrCode} generateQrCode={generateQrCode}/>}
            {openModalDelete && <GenericQuestionModal title={t('settings.deleteDeviceMessage')} recordName={selectedDeviceToDelete?.label ?? ''} open={openModalDelete} setOpen={setOpenModalDelete} onDelete={() => deleteDevice(selectedDeviceToDelete)}/>}
            {openModalUpdate && <UpdateDeviceModal open={openModalUpdate} setOpen={setOpenModalUpdate} onConfirm={(label) => updateDevice(label)} initialValue={selectedDeviceToUpdate.label}/>}
        </ListMenu>
    )
}

export default Devices;
