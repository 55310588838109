import {classNames} from "../../../utils/helper";

const UserSelectionButton = ({selected= false, label, onClick=()=>{} }) => {
    return(
        <button
            onClick={onClick}
            className={classNames(selected ? 'bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 shadow-sm text-white' : 'bg-white hover:bg-gray-100',
                "w-full flex p-3 border border-transparent rounded-md text-base leading-6 font-normal" +
                " focus:outline-none focus:ring-2 focus:ring-offset-2 ")
            }>
            {label}
        </button>
    )
}

export default UserSelectionButton;
