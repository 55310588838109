import Slideover from "../../atoms/Slideover/Slideover";
import React, {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import Icon from "../../atoms/Icon/Icon";
import {useDispatch, useSelector} from "react-redux";
import {getPersonActivity, peopleSelector} from "../../../features/people/people.slice";
import FeedsTable from "../../molecules/Tables/FeedsTable/FeedsTable";
import {CheckIcon} from "@heroicons/react/24/solid";
import isEmpty from "lodash/isEmpty";
import DropdownButtonWorkspaces from "../../molecules/DropdownButtonWorkspaces/DropdownButtonWorkspaces";
import {checkPermissions, toLocaleString} from "../../../utils/helper";
import {
    downloadEventPDF,
    downloadEventPDFSummary,
    resendEmailFromEventThunk
} from "../../../features/library/library.action";
import ModalGrayFooter from "../../molecules/Modals/Templates/ModalGrayFooter";
import {DOWNLOAD_PROCESS_SUMMARY, IMPORT_EXPORT_PROCESS, START_DRAFT_PROCESS} from "../../../utils/permissions";
import {userHomeSelector} from "../../../features/userHome/userHome.slice";
import IconButton from "../../atoms/Buttons/IconButton/IconButton";
import {ArrowDownTrayIcon} from "@heroicons/react/24/outline";

const ManageDetails = ({open, setOpen, event={}, showResendEmail=false}) => {
    const eventDetails = event?.detail
    const { t } = useTranslation();
    const dispatch = useDispatch()
    const [activitiesName, setActivitiesName] = useState('')
    const [openResendEmailModal, setOpenResendEmailModal] = useState(false);
    const [selectedEventId, setSelectedEventId] = useState()
    const {selectedTenantId, userOrganizations} = useSelector(userHomeSelector)
    const permissions = userOrganizations?.[selectedTenantId]?.permissions ?? {}
    const isSummaryDownloadPermitted = useMemo(() => checkPermissions(permissions, DOWNLOAD_PROCESS_SUMMARY), [permissions])

    const {personActivity} = useSelector(peopleSelector)

    const [showOperations, setShowOperations] = useState(false)

    useEffect(() => {
        setShowOperations(false)
    },[open])

    const showActivities = async (userId, eventId, name) => {
        setActivitiesName(name)
        await dispatch(getPersonActivity({id:userId, eventId})) //TODO_AUDIT_TABLET
        setShowOperations(!showOperations)
    }

    const onOpenResendEmailModal = (eventId) => {
        setSelectedEventId(eventId)
        setOpenResendEmailModal(true)
    }

    const completeAuditWithExtraData = (d) => {
        if(d.auditType === 'OPEN_ON_TABLET' && d.extradata) {
            try {
                const obj = JSON.parse(d.extradata)
                if (obj?.device?.label) {
                    return ' - ' + obj.device.label
                }
            } catch (e) {}
            return ''
        }
        return ''
    }

    var mapDataToFeeds = function(data) {
        return data.map((d) => {
            var date = toLocaleString(d.auditDate)
            return {
                id: d.id,
                content: t('userAuditTypes.' + d.auditType) + completeAuditWithExtraData(d),
                date: date,
                datetime: date,
                icon: CheckIcon,
                iconBackground: 'bg-gray-400'
            }
        });
    }

    const resendEmail = () => {
        dispatch(resendEmailFromEventThunk({eventId: selectedEventId}))
        setSelectedEventId()
        setOpenResendEmailModal(false)
        setOpen(false)
    }

    const downloadPDFSummary = async (event) => {
        if (event?.eventFlowActivityId) {
            await dispatch(downloadEventPDFSummary({url: '/api/manage/download-pdf-summary-backoffice/' + event?.eventFlowActivityId}));
        }
    }


    return(
        <Slideover open={open} setOpen={setOpen} title='Dettagli' button={<DropdownButtonWorkspaces event={event} />} buttonSummary={
            (isSummaryDownloadPermitted && event?.flowstatus === 'COMPLETED') ? <IconButton  icon={<ArrowDownTrayIcon className="h-6 w-6" aria-hidden="true" />} variant={'secondary'} label={t('manage.downloadSummary')} onClick={() => downloadPDFSummary(event)}></IconButton> :<></>
        }>
            {!showOperations ?
                <div className="h-full space-y-6" aria-hidden="true">
                    {eventDetails?.signers?.map((item) => {
                        return (
                            <div key={item.id}>
                                <div className='flex justify-between items-center'>
                                    <h3 className="font-medium text-gray-900 mb-2">{(item.givenName && item.familyName) ? (item.givenName + ' ' + item.familyName) : ''}</h3>
                                    <div className='my-3 flex space-x-2'>
                                        {showResendEmail && !item?.event?.signed && <Icon type='sendEmail' onClick={() => onOpenResendEmailModal(item.eventId)} title={t('manageusers.sendInvitationAgain')}/>}
                                        <Icon type='details' onClick={() => showActivities(item.userId, item.eventId, (item.givenName && item.familyName) ? (item.givenName + ' ' + item.familyName) : '')} title='Info' />
                                    </div>
                                </div>
                                <dl className="border-t border-b border-gray-200 divide-y divide-gray-200">
                                    <div className="py-3 flex justify-between text-sm font-medium space-x-4">
                                        <dt className="text-gray-500">{t('email')}</dt>
                                        <dd className="text-gray-900 text-right">{item.contactEmail}</dd>
                                    </div>
                                    <div className="py-3 flex justify-between text-sm font-medium space-x-4">
                                        <dt className="text-gray-500">{t('library.status')}</dt>
                                        <dd className="text-gray-900 text-right">{item.event.signed ? t('library.signed') : t('library.toBeSigned')}</dd>
                                    </div>
                                    <div className="py-3 flex justify-between text-sm font-medium space-x-4">
                                        <dt className="text-gray-500">{t('tableColumns.date')}</dt>
                                        <dd className="text-gray-900 text-right">{item?.event?.event_date ? toLocaleString(item.event.event_date) : ''}</dd>
                                    </div>
                                    <div className="py-3 flex justify-between text-sm font-medium space-x-4">
                                        <dt className="text-gray-500">{t('library.signDate')}</dt>
                                        <dd className="text-gray-900 text-right">{item.event.signed && item.event.signature_date ? toLocaleString(item.event.signature_date) : ''}</dd>
                                    </div>
                                    <div className="py-3 flex justify-between text-sm font-medium space-x-4">
                                        <dt className="text-gray-500">{t('recipients.signType')}</dt>
                                        <dd className="text-gray-900 text-right">{item.signatureType}</dd>
                                    </div>
                                </dl>
                            </div>
                        )
                    })}
                    <ModalGrayFooter open={openResendEmailModal} setOpen={setOpenResendEmailModal} onConfirm={() => resendEmail(event.id)} confirmLabel={t('summary.send')} cancelLabel={t('actions.cancel')} >
                        <p className='text-lg leading-6 font-medium text-gray-900'>{t('manageusers.confirmSendInvitationAgain', 'Confermi di voler inviare nuovamente la mail?')}</p>
                    </ModalGrayFooter>
                </div> :
                <>
                    <div className='flex space-x-1'>
                        <Icon type='back' onClick={() => setShowOperations(!showOperations)} />
                        <h3 className="font-medium text-gray-900 mb-2">{activitiesName}</h3>
                    </div>
                    {isEmpty(personActivity) ?
                        <>No info</> :
                        <FeedsTable data={mapDataToFeeds(personActivity)}/>}
                </>
            }
        </Slideover>
    )
}
export default ManageDetails;
