import {useNavigate, useParams} from "react-router-dom";
import {
    documentalProcessSelector,
    nextPage,
    setStepsByProcessType
} from "../../../features/documentalProcess/documentalProcess.slice";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect} from "react";
import {getRecipientsThunk, getSignatureTypesListThunk} from "../../../features/recipients/recipients.actions";
import {getProcessDetailsThunk} from "../../../features/documentalProcess/documentalProcess.actions";
import EndSendProcess from "../SendProcess/EndSendProcess/EndSendProcess";
import documentSentIllustration
    from '../../../assets/illustrations/4_Illustrazione_Confirmo_Documento-Inviato-Successo.svg'
import {useTranslation} from "react-i18next";
import {getDevices} from "../../../features/library/library.action";
import {getGraphConsentsThunk} from "../../../features/docModels/docModels.actions";
import {getAvailableWorkspacesThunk} from "../../../features/userHome/userHome.actions";
import {authSelector} from "../../../features/auth/auth.slice";
import {getDocumentDetailsThunk} from "../../../features/documentBuilder/documentBuilder.actions";
import NewDraftDocumentStep from "../ProcessSteps/NewDraftDocumentStep/NewDraftDocumentStep";

const NewDraftDocument = () => {
    const {t} = useTranslation()
    const {step} = useParams();
    const navigate = useNavigate()
    const dispatch = useDispatch();

    const {steps, waitingSend, isDocumentSent} = useSelector(documentalProcessSelector)
    const { draftSessionIdData } = useSelector(authSelector)

    const getAvailableWorkspaces = () => dispatch(getAvailableWorkspacesThunk())

    const buildUrl = (stepName) => `/finalizedraft/${stepName}`

    useEffect(() => {
        const loadData = async () => {
            dispatch(setStepsByProcessType({type:'senddocumentdraft'}))
            //dispatch(upsertProcessThunk({name: 'Invio documento', isDocument: true}))
            await dispatch(getProcessDetailsThunk({id: draftSessionIdData.eventParams.eventFlowTypeId}))
            await dispatch(getDocumentDetailsThunk({id: draftSessionIdData.eventParams.eventFlowTypeId}))
            dispatch(getSignatureTypesListThunk())
            dispatch(getDevices())
            dispatch(getRecipientsThunk({}))
            dispatch(getGraphConsentsThunk({onlyActive: true}))
            getAvailableWorkspaces()
        };

        // then call it here
        loadData();
    }, [])

    const next = () => {
        const actualId = steps.findIndex(s => s.urlName === step);
        if (actualId < steps.length - 1) {
            navigate(buildUrl(steps[actualId + 1].urlName))
            dispatch(nextPage({step: step}))
        }
    }

    switch (step) {
        case 'document':
            return (
                <NewDraftDocumentStep
                    next={next}
                    confirmLabel={t('summary.send')}
                    steps={steps}
                    header={false}
                    buildUrl={buildUrl}
                />
            )
        default:
            return (
                <EndSendProcess illustration={documentSentIllustration} isDocumentSent={isDocumentSent} isLoading={waitingSend} showGoHomeButton={false}/>
            )
    }
}

export default NewDraftDocument;
