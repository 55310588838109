import Button from "../Buttons/Button/Button";
import React from "react";
import Icon from "../Icon/Icon";
import DropdownIcon from "../Dropdowns/DropdownIcon/DropdownIcon";
import SingleCheckbox from "../SingleCheckbox/SingleCheckbox";
import Badge from "../Badges/Badge/Badge";
import {
    ARCHIEVE_STATUS,
    BUTTON,
    CHECK,
    CHECKBOX,
    DATE,
    DROPDOWN,
    DROPDOWN_ICON,
    FAVOURITE,
    ICON_BUTTON,
    ICON_BUTTON_LIST,
    SIGN,
    STATUS,
    TAGS, TRANSLATELABEL, TIMESTAMP, PUBLISHING_STATUS, REMAINING_SIGNATURES
} from "./SimpleTable.types";
import DropdownSelect from "../Dropdowns/DropdownSelect/DropdownSelect";
import {useTranslation} from "react-i18next";
import CardSignature from "../../molecules/CardSignature/CardSignature";

const SimpleTableElement = ({element, data}) => {
    const {t} = useTranslation()

    const onClickFavourite = (e) => {
        e.stopPropagation();
        e.preventDefault();
        element?.onClick?.(data.id, !data.isFavourite)
    }

    if(element?.hideColumnContent?.(data)) return <></>
    if(element?.type) {
        switch (element.type) {
            case BUTTON:
                return <Button
                    variant={element.buttonVariant}
                    label={element.buttonLabel}
                    size='full'
                    onClick={() => element.onClick(data)}
                />;
            case DROPDOWN:
                return <DropdownSelect options={element.dropdownOptions} item={data} selectedValue={element.dropdownOptions.find(opt => opt.label === data[element.field])} setSelectedValue={(value) => element.setSelected(data, value)}/>
            case DROPDOWN_ICON:
                return <DropdownIcon title={element.field} iconType={element.iconType} options={element.dropdownOptions} onClick={(opt) => element?.onClick(data, opt)} />
            case ICON_BUTTON:
                return <div title={element.label} className='flex justify-center'><Icon type={element.iconType} onClick={() => element?.onClick?.(data)} /></div>
            case FAVOURITE:
                return <Icon type={data.isFavourite ? 'star-filled' : 'star-empty'} onClick={onClickFavourite} size='4' />
            case SIGN:
                return (data[element.field]==='TO_SIGN' || data[element.field]==='SIGNING') ?<div title={t('table.sign')} className='flex justify-center'><Icon type='sign' color='blue' onClick={() => element?.onClick?.(data)}/></div> : <></>
            case PUBLISHING_STATUS:
                return <div title={element.label} className='flex justify-center' title={data[element.field]==='Y' ? t('documentalProcess.intro.publishedYes') : t('documentalProcess.intro.publishedNo')} ><Icon type={data[element.field]==='Y' ? 'eye' : 'eye-slash'} color={data[element.field]==='Y' ? 'green' : 'yellow'} onClick={() => element?.onClick?.(data)} /></div>
            case ARCHIEVE_STATUS:
                switch(data[element.field]){
                    case 'UPLOADED': return <div title={t('table.uploaded')} className='flex justify-center'><Icon type='clock' color='blue'/></div>
                    case 'PROCESSED': return <div title={t('table.processed')} className='flex justify-center'><Icon type='check' color='green'/></div>
                    default: return <></>
                }
            case REMAINING_SIGNATURES:
                switch(data[element.field]){
                    case -1: return <div  className="bg-green-100 text-green-800 inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0">
                            {t('unlimited')}
                        </div>
                    default: {
                        if (data[element.field] > 5) {
                            return <span className='text-green-600'>{data[element.field]}</span>
                        } else if (data[element.field] === 0) {
                            return <span className='text-red-600'>{data[element.field]}</span>
                        } else {
                            return <span className='text-yellow-600'>{data[element.field]}</span>
                        }
                    }
                }
            case STATUS:
                switch(data[element.field]){
                    case 'WAITING':
                    case 'TO_SIGN':
                    case 'SIGNING':
                        return <div title={t('table.waiting')}><Icon type='clock' color='yellow'/></div>
                    case 'COMPLETED': return <div title={t('table.complete')}><Icon type='check' color='green'/></div>
                    case 'CANCELED': return <div title={t('table.canceled')}><Icon type='x-mark' color='red'/></div>
                    default: return <></>
                }
            case CHECKBOX:
                return (<div onClick={(e) => e.stopPropagation()}>
                    <SingleCheckbox value={element?.selected?.[data.id] ?? false} setValue={() => element.setSelected(data.id)} />
                </div> )
            case ICON_BUTTON_LIST:
                return <div className='flex space-x-2 justify-end'>{element.buttons.map(
                    button => <SimpleTableElement key={button.id} element={button} data={data} />)}</div>
            case TAGS:
                return <div className='flex truncate max-w-xs'>
                    {data[element.field] && data[element.field].map(tag => <div key={tag.id} className='m-0.5'><Badge label={tag.name} color='blue' /></div>)}
                </div>
            case DATE:
                return data?.[element.field] ? new Date(data?.[element.field])?.toLocaleDateString('en-GB'): ''
            case TIMESTAMP:
                return data?.[element.field] ? (new Date(data?.[element.field])?.toLocaleDateString('en-GB') + ' ' + new Date(data?.[element.field])?.toLocaleTimeString('en-GB')) : ''
            case CHECK:
                return data?.[element.field] ?
                    <div className='flex justify-center w-fit'><Icon type='check' color='green'/></div> :
                    <div className='flex justify-center w-fit'><Icon type='x-mark' color='red'/></div>
            case TRANSLATELABEL:
                return t((element.traslatePrefix ?? '') + (data[element.field] ?? '')) ?? ''
            default:
                return <p className='break-all'>{data[element.field] ?? ''}</p>
        }
    }else {
        return <p className='break-all'>{data[element.field] ?? ''}</p>
    }
}

export default SimpleTableElement;
