import SimpleTable from "../../molecules/Tables/SimpleTable/SimpleTable";
import ManageDetails from "../ManageDetails/ManageDetails";
import GenericQuestionModal from "../../molecules/Modals/GenericModal/GenericQuestionModal";
import React, {useEffect, useMemo} from "react";
import {useTranslation} from "react-i18next";
import ListMenu from "../../molecules/ListMenu/ListMenu";

const EventsList = ({data, columns, detailOpened=false, closeDetail, openDeleteModal=false,
                        setOpenDeleteModal=()=>{}, onDeleteEvent=()=>{}, toDeleteRecordName, loading=false, elementsCount=0,
                        updateEvents=()=>{}, currentEventId, sideComponent, filters, setFilters, flgIncludiCompletati, showResendEmail, confirmModalButtonLabel,
                        customMessageModal, sendEmail, setSendEmail, askSendRestoreEmail}) => {
    const { t } = useTranslation();

    useEffect(() => {
        const callbackTimer = setTimeout(() => updateEvents({txtFilter: filters.txtFilter, pagination: filters.pagination, sortField: filters.sortField, descending: filters.descending, flgIncludiCompletati, startDate: filters.startDate, endDate: filters.endDate}), 300);
        return () => clearTimeout(callbackTimer);
    }, [filters, flgIncludiCompletati])

    const selectedEvent = useMemo(() => currentEventId && data?.find(d => d.id === currentEventId), [currentEventId, data])

    return (
        <ListMenu
            elementsCount={elementsCount}
            sideComponent={sideComponent}
            filters={filters}
            setFilters={setFilters}
            isDateFilterActive={true}
        >
            <div className="flex flex-col flex-1">
                <SimpleTable data={data} columns={columns} filters={filters} setFilters={setFilters} loading={loading}/>
                <ManageDetails open={detailOpened} setOpen={closeDetail} event={selectedEvent} showResendEmail={showResendEmail}/>
                {openDeleteModal && <GenericQuestionModal
                    open={openDeleteModal}
                    setOpen={setOpenDeleteModal}
                    onDelete={onDeleteEvent}
                    title={confirmModalButtonLabel ?? t('library.deleteEvent')}
                    recordName={toDeleteRecordName}
                    confirmButtonLabel={confirmModalButtonLabel}
                    customMessage={customMessageModal}
                    showFlag={askSendRestoreEmail}
                    sendEmail={sendEmail}
                    setSendEmail={setSendEmail}
                />}
            </div>
        </ListMenu>
    )
}

export default EventsList;
