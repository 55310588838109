import React, {useEffect, useMemo, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {
    closePersonDetail,
    countPeople,
    getPeople, getUserLists,
    openPersonDetail,
    peopleSelector
} from "../../../../features/people/people.slice";
import GenericTableModal from "../GenericModal/GenericTableModal";
import {useTranslation} from "react-i18next";
import {userHomeSelector} from "../../../../features/userHome/userHome.slice";
import ModalContactNew from "../ModalContactNew/ModalContactNew";
import {DATE} from "../../../atoms/SimpleTableElement/SimpleTable.types";
import UsersAdvancedFilters from "../../UsersAdvancedFilters/UsersAdvancedFilters";

const ModalContacts = ({open = false, setOpen, onSelectUser, onSelectList, roleFilter, isConcatenation}) => {
    const dispatch = useDispatch()
    const {t} = useTranslation();
    const {people, peopleCount, detailOpened, userLists, userListsCount} = useSelector(peopleSelector)
    const [searchTerm, setSearchTerm] = useState('')
    const [ts, setTs] = useState(Date.now())
    const [offset, setOffset] = useState(0)
    const [limit] = useState(10)
    const [role, setRole] = useState(roleFilter ?? 'USER')
    const [isListsSelected, setIsListsSelected] = useState(false)
    const closeDetail = () => dispatch(closePersonDetail());

    const { selectedTenantId, userOrganizations } = useSelector(userHomeSelector);

    const peopleRolesOptions = useMemo(() => {
        const peopleRoles = userOrganizations?.[selectedTenantId]?.peopleRoles ?? [];
        const options = peopleRoles?.map(pr => {return {value: pr.name, label: pr.description}});
        return roleFilter ? options.filter(opt => opt.value === roleFilter) : options;
    }, [userOrganizations, selectedTenantId, roleFilter]);

    const columns = useMemo(() => {
        const nameColumn = {label: t('name'), value: 'name'}
        const emailColumn = {label: t('email'), value: 'email'}
        const birthdayColumn = {label: t('general.birthdate'), value: 'birthDate', type: DATE}
        return isListsSelected ?
            [nameColumn] :
            userOrganizations?.[selectedTenantId]?.addBirthdateToUserTable ?
                [nameColumn, emailColumn, birthdayColumn] :
                [nameColumn, emailColumn]
    },[isListsSelected, t, selectedTenantId])

    const getPeoplePaginated = () => {
        dispatch(getPeople({txtFilter: searchTerm, pagination: {offset, limit}, role}))
        dispatch(countPeople({txtFilter: searchTerm, role}));
    }

    const getLists = () => dispatch(getUserLists({filter: searchTerm, offset, limit}))

    useEffect(() => {
        if(open) {
            const callbackTimer = isListsSelected ?
                setTimeout(getLists, 300) :
                setTimeout(getPeoplePaginated, 300)
            return () => clearTimeout(callbackTimer);
        }
    },[offset, limit, searchTerm, open, role, isListsSelected, ts])

    const selectClose = (recipient) => {
        if(isListsSelected) onSelectList(recipient, role)
        else onSelectUser(recipient, role);
        setOpen(false);
        closeDetail();
    }

    return (
        <>
            {
                detailOpened ? <ModalContactNew
                        open={detailOpened}
                        setOpen={closeDetail}
                        currentPeopleRole={roleFilter}
                        onSavePersonSuccess={(userData) => {
                            selectClose(userData)
                        }}
                    /> :
                    <GenericTableModal
                        data={isListsSelected ? userLists : people}
                        setOpen={setOpen}
                        open={open}
                        selectClose={selectClose}
                        limit={limit}
                        setOffset={setOffset}
                        offset={offset}
                        count={isListsSelected ? userListsCount : peopleCount}
                        searchTerm={searchTerm}
                        setSearchTerm={setSearchTerm}
                        prefilterValue={role}
                        setPrefilterValue={setRole}
                        preFilterOptions={peopleRolesOptions}
                        searchPlaceholder={t('search.forNameEmail')}
                        fields={columns}
                        title={t('rubrica')}
                        onClickTitle={() => setIsListsSelected(false)}
                        secondTitle={!isConcatenation ? t('lists') : null}
                        onClickSecondTitle={() => setIsListsSelected(true)}
                        isSecondTitleSelected={isListsSelected}
                        onClickGeneralButton={() => dispatch(openPersonDetail({}))}
                        generalButtonLabel={t('settings.newContact')}
                        advancedFiltesComponent={<UsersAdvancedFilters ts={ts} setTs={setTs}/>}
                    />
            }
        </>
    )
}

export default ModalContacts;
