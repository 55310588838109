import React, {useEffect, useState} from "react";
import SimpleTableElement from "../../../atoms/SimpleTableElement/SimpleTableElement";
import {useTranslation} from "react-i18next";
import isString from "lodash/isString";
import {ChevronDownIcon, ChevronUpIcon} from "@heroicons/react/24/solid";
import LoadingSpinner from "../../../atoms/LoadingSpinner/LoadingSpinner";

const SimpleTable = ({columns, data, onClick, section=null, loading = false, filters={}, setFilters}) => {
    const {t} = useTranslation()
    const [selectedColumn, setSelectedColumn] = useState(filters?.sortField ?? '')
    const [isDescending, setIsDescending] = useState(filters?.descending ?? true)

    useEffect(() => {
        setSelectedColumn(filters?.sortField ?? '')
        setIsDescending(filters?.descending ?? true)
    },[section])

    const setColumnOrder = (id) => {
        if(id) {
            const order = id === selectedColumn ? !isDescending : true
            setIsDescending(order)
            setSelectedColumn(id)
            sort(id, order)
        }
    }

    const sort = (field, descending = false) => {
        setFilters({...filters, sortField: field, descending})
    }

    if(loading)
        return <LoadingSpinner/>
    else return (
        <div className="flex flex-col flex-1 -my-2 sm:-mx-6 lg:-mx-8 py-2 align-middle sm:px-6 lg:px-8">
            <table className='w-full'>
                <thead>
                <tr>
                    {columns.map(col => <th
                        scope="col"
                        className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-default max-w-xs text-clip"
                        onClick={() => {
                            if (!col.hidden && col.isSortable) setColumnOrder(col.field)
                        }}
                        key={col.field}
                    >
                        {!col.hidden && (isString(col.label) ?
                            (<div
                                className='flex items-center cursor-pointer'>{t(col.label)} {selectedColumn === col.field && (isDescending ?
                                <ChevronDownIcon className='w-4 h-4'/> :
                                <ChevronUpIcon className='w-4 h-4'/>)}</div>)
                            : col.label)}
                    </th>)}
                </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                {data?.map(
                    (d) => {
                        const dataKey = d.identifier ?? d.id
                        return <tr key={d.identifier ?? d.id} onClick={() => onClick?.(d.id)}
                            className={`${onClick ? 'cursor-pointer hover:bg-gray-100 ' : ''}transition duration-100 w-full`}>
                            {columns.map(
                                col => <td className={`px-2 py-4 text-sm text-gray-700 break-words w-fit max-w-[14%]`} key={col.field+dataKey}>
                                        <SimpleTableElement data={d} element={col}/>
                                    </td>
                            )}
                        </tr>
                    }
                )}
                </tbody>
            </table>
        </div>
    )
}

export default SimpleTable;
