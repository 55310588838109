import SimplePage from "../Templates/SimplePage/SimplePage";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {authSelector} from "../../../features/auth/auth.slice";
import {changePasswordThunk} from "../../../features/auth/auth.actions";
import Input from "../../atoms/Inputs/Input/Input";
import Button from "../../atoms/Buttons/Button/Button";
import {LG} from "../../atoms/Buttons/sizes";
import {useTranslation} from "react-i18next";

const ChangePasswordPage = () => {
    const dispatch = useDispatch();
    const {user} = useSelector(authSelector);
    const {t} = useTranslation()

    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmoPassword] = useState('');

    const changePasswordRequest = async () => {
        dispatch(changePasswordThunk({currentPassword, newPassword, confirmPassword}));
    }

    useEffect(() => {
        if (!user.changePasswordNeeded) {
            window.location.replace('/home')
        }
    },[user.changePasswordNeeded])

    return(
        <SimplePage>
            <div className='flex max-w-screen-2xl w-full h-screen justify-center items-center bg-fi-background bg-no-repeat bg-left-bottom bg-50% bg-scroll'>
                <div className="flex flex-grow justify-center">
                    <form className="space-y-2 py-4 w-full max-w-md">
                        <h1 className='text-lg leading-6 font-medium'>{t('login.passwordExpired')}</h1>
                        <div className='grid grid-rows-3 gap-x-4'>
                            <Input
                                name='old-password'
                                type='password'
                                label={t('passwordOld')}
                                value={currentPassword}
                                setValue={setCurrentPassword}
                            />
                            <Input
                                name='password'
                                type='password'
                                label={t('password')}
                                value={newPassword}
                                setValue={setNewPassword}
                            />
                            <Input
                                name='passwordConfirm'
                                type='password'
                                label={t('confirmPassword')}
                                value={confirmPassword}
                                setValue={setConfirmoPassword}
                            />
                        </div>
                        <div className='h-10 my-1'>
                            <Button
                                variant='blue'
                                label={t('setPassword')}
                                size={LG}
                                onClick={changePasswordRequest}
                            />
                        </div>
                    </form>
                </div>
            </div>
        </SimplePage>
    )
}
export default ChangePasswordPage;
