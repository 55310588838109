import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {peopleSelector} from "../../../../features/people/people.slice";
import TabsWithUnderline from "../../Tabs/TabsWithUnderline/TabsWithUnderline";
import PersonDetailDatiPrincipali
    from "../../../organisms/PeopleDetailView/PersonDetailDatiPrincipali/PersonDetailDatiPrincipali";
import PersonDetailStoricoAttivita
    from "../../../organisms/PeopleDetailView/PersonDetailStoricoAttivita/PersonDetailStoricoAttivita";
import PersonDetailEventi from "../../../organisms/PeopleDetailView/PersonDetailEventi/PersonDetailEventi";
import {librarySelector} from "../../../../features/library/library.slice";
import {getUserSignatures} from "../../../../features/library/library.action";
import ModalLayout from "../ModalLayout/ModalLayout";
import GraphAgreementList from "../../../organisms/PeopleDetailView/GraphAgreementList/GraphAgreementList";
import {useTranslation} from "react-i18next";

const ModalContactDetail = ({open = false, setOpen, currentPeopleRole, readonlyFields=[]}) => {
    const {t} = useTranslation()
    const [currentTab, setCurrentTab] = useState('dati-principali')
    const {signatures, listLoadedSignatures} = useSelector(librarySelector)
    const onClickTab = (tab) => setCurrentTab(tab)
    const tabs = [
        { name: t('contact.main-data'), id: 'dati-principali', onClick: () => onClickTab('dati-principali'), current: currentTab === 'dati-principali' },
        { name: t('contact.audit-trail'), id: 'storico-attivita', onClick: () => onClickTab('storico-attivita'), current: currentTab === 'storico-attivita' },
        { name: t('contact.event-agreement'), id: 'eventi', onClick: () => onClickTab('eventi'), current: currentTab === 'eventi' },
        { name: t('contact.graph-agreements'), id: 'graph-agreement', onClick: () => onClickTab('graph-agreement'), current: currentTab === 'graph-agreement' },
    ]

    const {currentPerson} = useSelector(peopleSelector)
    const dispatch = useDispatch();

    useEffect(() => {
        if(!listLoadedSignatures) {
            dispatch(getUserSignatures({}));
        }
    }, [signatures])

    return (
        <ModalLayout open={open} setOpen={setOpen}>
            <div className="px-4 pt-5 pb-4 sm:px-8 sm:pt-6" >
                {currentPerson.givenName} {currentPerson.familyName}
                <TabsWithUnderline tabs={tabs} />
                <ContactTabs currentTab={currentTab} currentPerson={currentPerson} setOpen={setOpen} currentPeopleRole={currentPeopleRole} readonlyFields={readonlyFields}/>
            </div>
        </ModalLayout>
    )
}

const ContactTabs = ({currentTab, currentPerson, setOpen, currentPeopleRole, readonlyFields}) => {
    switch (currentTab) {
        case 'dati-principali':
            return <PersonDetailDatiPrincipali data={currentPerson} setOpen={setOpen} currentPeopleRole={currentPeopleRole} readonlyFields={readonlyFields}/>
        case 'storico-attivita':
            return <PersonDetailStoricoAttivita/>
        case 'eventi':
            return <PersonDetailEventi data={currentPerson} />
        case 'graph-agreement':
            return <GraphAgreementList data={currentPerson} />
        default:
            return currentTab
    }
}

export default ModalContactDetail;
