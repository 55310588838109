import {classNames} from "../../../../utils/helper";
import {ChevronDownIcon} from "@heroicons/react/24/solid";
import {Menu, Transition} from '@headlessui/react'
import React, {Fragment} from "react";

const Dropdown = ({label, options = [], item, icon, title, variant = 'base'}) => {
    return (
        <Menu as="div"  className="relative inline-block text-left">
            {({ open }) => (
                <>
                    <div title={title} >
                        <Menu.Button
                            className={classNames(
                                "flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500",
                                variant === 'blue' ? 'bg-blue-600 text-white hover:bg-blue-700' : (
                                    variant === 'light' ? 'border-none shadow-none bg-white text-gray-700 hover:bg-gray-50 focus:ring-0 focus:outline-none' : 'bg-white text-gray-700 hover:bg-gray-50')
                            )}>
                            <p className='truncate'>{label}</p>
                            {
                                (icon) ?
                                    <div className='mr-0.5'>{icon}</div> : <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                            }
                        </Menu.Button>
                    </div>

                    <Transition
                        show={open}
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <Menu.Items
                            static
                            className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10"
                        >
                            <div className="py-1">
                                {options?.map(opt => <Menu.Item key={opt.label}>
                                    {({ active }) => (
                                        <div
                                            onClick={() => (opt.onclick ? opt.onclick(item) : {})}
                                            className={classNames(
                                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                'block px-4 py-2 text-sm'
                                            )}
                                        >
                                            <span className={classNames(
                                                "cursor-pointer",
                                                opt.labelVariant === 'warn' ? 'text-red-600' : ''
                                            )}>{opt.label}</span>
                                        </div>
                                    )}
                                </Menu.Item>
                                )}
                            </div>
                        </Menu.Items>
                    </Transition>
                </>
            )}
        </Menu>
    )
}

export default Dropdown;
