import {createEntityAdapter, createSlice} from "@reduxjs/toolkit";
import {
    MANAGE,
    READ_ARCHIEVE,
    READ_DEVICES,
    READ_LIBRARY,
    READ_RUBRICA, SIGNATURE_BALANCE,
    USERS_MANAGE,
    WORKSPACES
} from "../../utils/permissions";

const navigationAdapter = createEntityAdapter();

const initialState = navigationAdapter.getInitialState({
    tabs: [
        {name: 'Home'},
        {name: 'Gestisci', show: MANAGE},
        {name: 'Libreria', show: READ_LIBRARY},
        {name: 'Rubrica', show: READ_RUBRICA},
        {name: 'Conservazione', show: READ_ARCHIEVE},
    ],
    manageSections: [
        { name: 'verticalMenu.sent', href: 'sent'},
        { name: 'verticalMenu.received', href: 'received'},
        { name: 'verticalMenu.massiveSing', href: 'massivesignature'},
        { name: 'verticalMenu.canceled', href: 'canceled'},
    ],
    manageSectionExternal: [
        { name: 'verticalMenu.received', href: 'received'},
        { name: 'verticalMenu.massiveSing', href: 'massivesignature'},
    ],
    librarySections: [
        { name: 'verticalMenu.documentalProcesses', href: 'eventflows'},
        { name: 'verticalMenu.informedPaths', href: 'informative'},
        { name: 'verticalMenu.forms', href: 'formsTemplate'},
    ],
    settingsSections: [
        { name: 'verticalMenu.personalData', href: 'PersonalData'},
        { name: 'verticalMenu.signatures', href: 'Signature'},
        { name: 'verticalMenu.devices', href: 'Devices', show: READ_DEVICES},
        { name: 'verticalMenu.signatureBalance', href: 'SignatureBalance', show: SIGNATURE_BALANCE},
        { name: 'verticalMenu.ManageUser', href: 'ManageUser', show: USERS_MANAGE},
        { name: 'workspaces', href: 'workspaces', show: [USERS_MANAGE, WORKSPACES]},
        { name: 'verticalMenu.graphconsent', href: 'graphconsent', show: READ_LIBRARY},
        { name: 'verticalMenu.manage2fa', href: 'Manage2fa'},
        /*{ name: 'verticalMenu.plan', href: 'Billing'},*/
    ]
})

const navigationSlice = createSlice({
        name: 'navigation',
        initialState,
        reducers: {
            setSelectedTabName: (state, action) => {
                state.selectedTabName = action.payload;
            },
            resetState: () => {
                return initialState;
            },
        }
    }
)

export const {resetState, setSelectedTabName} = navigationSlice.actions;

export const navigationSelector = (state) => state.navigation;

export default navigationSlice.reducer
