import {toast} from "react-hot-toast";
import Alert from "../components/molecules/Alerts/Alert";
import {WARNING} from "../components/molecules/Alerts/types";

let logoutRedirectUrl = '/login';

export function setLogoutRedirectUrl(url) {
    logoutRedirectUrl = url;
}

export const logoutUser = error => {
    if (error.response.status === 401) {
        sessionStorage.clear();
        window.location.replace(logoutRedirectUrl)
        toast.remove();
        toast.custom((t) => ( <Alert type={WARNING} message='Sei stato disconnesso. Prova a effettuare di nuovo il Login' onClose={() => toast.dismiss(t.id)}/> ))
    }
    return Promise.reject(error);
}
