/* This example requires Tailwind CSS v2.0+ */
import React, {Fragment, useEffect, useState} from 'react'
import {Popover, Transition} from '@headlessui/react'
import {openNotificationDetail, userHomeSelector} from "../../../../features/userHome/userHome.slice";
import {useDispatch, useSelector} from "react-redux";
import NotificationDetailView from "../../../organisms/Notifications/NotificationDetailView/NotificationDetailView";
import {Link} from "react-router-dom";
import {getLastNotificationsList} from "../../../../features/userHome/userHome.actions";
import {BellIcon} from "@heroicons/react/24/outline";
import {toLocaleString} from "../../../../utils/helper";


const callsToAction = [
    { name: 'Mostra tutte', href: '/Notifications' },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const ModalLastNotifications = () => {
    const dispatch = useDispatch();

    const [notificationsLoaded, setNotificationsLoaded] = useState('');

    const { selectedTenantId, lastNotifications, currentNotification } = useSelector(userHomeSelector);


    useEffect(() => {
        if (!notificationsLoaded && selectedTenantId) {
            dispatch(getLastNotificationsList())
            setNotificationsLoaded(true);
        }
    },[selectedTenantId, notificationsLoaded])

    const openNotification = async (item) => {
        dispatch(openNotificationDetail({item}));
    }

    return (
        <Popover className="relative">
            {({ open }) => (
                <>
                    <Popover.Button
                        className={classNames(
                            open ? 'text-gray-900' : 'text-gray-500',
                            'group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                        )}
                    >

                        <span className="sr-only">View notifications</span>
                        <BellIcon className="h-6 w-6" aria-hidden="true" />
                        {currentNotification ? <NotificationDetailView /> : ''}
                    </Popover.Button>

                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                    >
                        <Popover.Panel className="absolute z-50 left-full transform -translate-x-full mt-3 px-2 w-screen max-w-md sm:px-0">
                            <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden z-50 bg-white">
                                <div className="relative grid gap-6 bg-white px-5 sm:gap-8 mt-8 z-50">
                                    Notifiche
                                </div>

                                <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 text-sm">
                                    {(lastNotifications || []).map((item) => (
                                        <div
                                            key={item.id}
                                            //to={item.href}
                                            onClick={() => openNotification(item)}
                                            className="-m-3 flex items-start rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
                                        >
                                            <div className="ml-4">
                                                <p className="mt-1 text-sm text-gray-500">{toLocaleString(item.notificationDate)}</p>
                                                <p className="text-sm font-medium text-gray-900">{item.title}</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>

                                <div className="px-5 py-5 bg-gray-50 space-y-6 sm:flex sm:space-y-0 sm:space-x-10 sm:px-8">
                                    {callsToAction.map((item) => (
                                        <div key={item.name} className="flow-root">
                                            <Link
                                                to={item.href}
                                                className="-m-3 p-3 flex items-center rounded-md text-base font-medium text-gray-900 hover:bg-gray-100 transition ease-in-out duration-150"
                                            >
                                                <span className="ml-3 text-sm">{item.name}</span>
                                            </Link>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </Popover.Panel>
                    </Transition>
                </>
            )}
        </Popover>
    )
}

export default ModalLastNotifications;
