import {useTranslation} from "react-i18next";
import {useEffect} from "react";
import {userHomeSelector} from "../../../features/userHome/userHome.slice";
import {authSelector} from "../../../features/auth/auth.slice";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {getCurrentUser} from "../../../features/auth/auth.actions";
import {getHomeActions} from "../../../features/userHome/userHome.actions";

const CardHeading = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { user } = useSelector(authSelector)
    const { homeActions } = useSelector(userHomeSelector)

    useEffect(() => {
        if(!user.id) {
            dispatch(getCurrentUser({}));
        }
    }, [user])
    useEffect(() => {
        if(!homeActions || homeActions.length === 0) {
            dispatch(getHomeActions({}));
        }
    }, [homeActions])


    function decodeLinkByHomeAction(homeAction) {
        if (homeAction.label === 'TO_SIGN') {
            return '/gestisci/received'
        } else if(homeAction.label === 'SIGNED') {
            return '/gestisci/received?completed=true';
        }
        return '#';
    }

    return (
        <div className="rounded-lg bg-white overflow-hidden shadow-lg border w-full ">
            <h2 className="sr-only" id="profile-overview-title">
                Profile Overview
            </h2>
            <div className="bg-gray-50 p-10">
                <div className="sm:flex sm:items-center sm:justify-between">
                    <div className="sm:flex sm:space-x-5">
                        <div className="flex-shrink-0">
                            <img className="mx-auto h-20 w-20 rounded-full" src={user.avatar} alt="" />
                        </div>
                        <div className="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
                            <p className="text-lg font-medium text-gray-600">{t('hello.label')}</p>
                            <p className="text-xl font-bold text-gray-900 sm:text-3xl">{user.name}</p>
                            <p className="text-sm font-medium text-gray-600">{user.role}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="border-t border-gray-200 bg-gray-50 grid grid-cols-1 divide-y divide-gray-200 sm:grid-cols-3 sm:divide-y-0 sm:divide-x">
                {homeActions.map((homeAction) => (
                    <div key={homeAction.label} className="px-6 py-5 text-sm font-medium text-center">
                        <Link data-testid='link-href' to={decodeLinkByHomeAction(homeAction)}><span className="text-gray-900">{homeAction.value}</span> <span className="text-gray-600">{t('dashboard_action_' + homeAction.label)}</span></Link>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default CardHeading;
