import Modal from "./Modal";
import React, {useRef, useState} from "react";
import Button from "../../../atoms/Buttons/Button/Button";
import {useTranslation} from "react-i18next";
import {WHITE} from "../../../atoms/Buttons/Button/variants";

const ModalGrayFooter = ({children, open, setOpen, confirmLabel, cancelLabel, onConfirm, saveDisabled=false}) => {
    const cancelButtonRef = useRef()
    const {t} = useTranslation()
    const [isWaiting, setIsWaiting] = useState(false)

    const onClickConfirm = async () => {
        try{
            setIsWaiting(true)
            await onConfirm()
        }finally{
            setIsWaiting(false)
        }
    }

    const footer = <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
        <div className='space-x-2'>
            <Button
                onClick={() => setOpen(false)}
                label={cancelLabel ?? t('actions.cancel')}
                variant={WHITE}
                disabled={isWaiting}
            />
            <Button label={confirmLabel} onClick={onClickConfirm} disabled={isWaiting || saveDisabled}/>
        </div>
    </div>

    return(
        <Modal open={open} setOpen={setOpen} cancelButtonRef={cancelButtonRef} footer={footer}>
            {children}
        </Modal>
    )
}

export default ModalGrayFooter;
