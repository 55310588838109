import {useNavigate} from "react-router-dom";
import PageWithStepsHeaderAndFooter from "../../Templates/PageWithStepsHeaderAndFooter/PageWithStepsHeaderAndFooter";
import TabSwitcher from "../../../atoms/TabSwitcher/TabSwitcher";
import Dropdown from "../../../atoms/Dropdowns/Dropdown/Dropdown";
import InformativaInfo from "../../../molecules/InformativaInfo/InformativaInfo";
import React, {useEffect, useState} from "react";
import TabsWithUnderline from "../../../molecules/Tabs/TabsWithUnderline/TabsWithUnderline";
import {useDispatch, useSelector} from "react-redux";
import {
    addDataCollectFormFieldThunk,
    addDataCollectFormThunk,
    deleteDataCollectFormFieldThunk,
    getDataCollectionsThunk,
    massiveSetDataCollectionRuleThunk,
    reorderDataCollectFormFieldsThunk,
    setOwnerDataCollectionThunk,
    updateDataCollectFormFieldThunk,
    updateDataCollectFormThunk,
} from "../../../../features/documentalProcess/documentalProcess.actions";
import isEmpty from "lodash/isEmpty";
import ListDataCollectionsFields from "../../../organisms/ListDataCollectionsFields/ListDataCollectionsFields";
import RecipientsDataCollection from "../../../molecules/RecipientsDataCollection/RecipientsDataCollection";
import SidebarDataCollection from "../../../molecules/SidebarDataCollection/SidebarDataCollection";
import {
    addDataCollectionRule,
    deleteDataCollection,
    deleteDataCollectionRule,
    setDataCollectionRule
} from "../../../../features/documentalProcess/documentalProcess.slice";
import {setLinkedRecipientsToDataCollectionThunk} from "../../../../features/recipients/recipients.actions";
import ModalRecipientSelection from "../../../molecules/Modals/ModalRecipientSelection/ModalRecipientSelection";
import {
    checkLinkedDataCollections,
    deleteDataCollectionFromRecipients,
    recipientsSelector
} from "../../../../features/recipients/recipients.slice";
import {librarySelector} from "../../../../features/library/library.slice";
import ModalSelectPathItemTemplates
    from "../../../molecules/Modals/ModalSelectPathItemTemplates/ModalSelectPathItemTemplates";
import EmptyStateIllustration from '../../../../assets/illustrations/6_Illustrazione_Confirmo_Empty-State.svg'
import {useTranslation} from "react-i18next";
import ModalNewForm from "../../../molecules/Modals/ModalNewPathItem/ModalNewForm/ModalNewForm";
import {toast} from "react-hot-toast";
import Alert from "../../../molecules/Alerts/Alert";
import {ERROR} from "../../../molecules/Alerts/types";
import {addTagThunk, deleteTagThunk} from "../../../../features/userHome/userHome.actions";
import {countForms, getForms} from "../../../../features/library/library.action";
import GenericQuestionModal from "../../../molecules/Modals/GenericModal/GenericQuestionModal";
import {deleteForm} from "../../../../api/library";
import {userHomeSelector} from "../../../../features/userHome/userHome.slice";

const DataCollect = ({dataCollectId = '', dataCollections, recipients, sender, next, previous, steps, isLoading=false, buildUrl=()=>{}}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {t} = useTranslation()
    const {allDataCollectionsLinked} = useSelector(recipientsSelector)
    const {forms, formsCount} = useSelector(librarySelector)
    const [openModalNewForm, setOpenModalNewForm] = useState(false)
    const [openModalDeleteForm, setOpenModalDeleteForm] = useState(false);
    const [openModalTemplateSelection, setOpenModalTemplateSelection] = useState(false)
    const [openModalRecipientsSelection, setOpenModalRecipientsSelection] = useState(false)
    const [selectedFormId, setSelectedFormId] = useState(dataCollectId);
    const [selectedCardId, setSelectedCardId] = useState();
    const [formToDelete, setFormToDelete] = useState();
    const [dataCollectionName, setDataCollectionName] = useState('')
    const [dataCollectionAlias, setDataCollectionAlias] = useState('')
    const [dataCollectionDescription, setDataCollectionDescription] = useState('')
    const [selectedCard, setSelectedCard] = useState();
    const [showFields, setShowFields] = useState(true)
    const [currentTab, setCurrentTab] = useState('form-fields')
    const [fields, setFields] = useState(dataCollections[selectedFormId]?.template?.items ?? [])
    const [rules, setRules] = useState(dataCollections[selectedFormId]?.template?.rules ?? [])
    const isSenderOwner = sender?.linkedDataCollections[selectedFormId]
    const [selectedTemplate, setSelectedTemplate] = useState()
    const [tags, setTags] = useState([]);

    const { selectedTenantId, userOrganizations } = useSelector(userHomeSelector);
    const enableFormAlias = userOrganizations?.[selectedTenantId]?.enableFormAlias ?? false;

    const tabs = [
        { name: t('forms.formFields'), id: 'form-fields', onClick: () => {
                setCurrentTab('form-fields')
                setShowFields(true)
            }, current: currentTab === 'form-fields' },
        { name: t('forms.rules'), id: 'form-rules', onClick: () => {
                setCurrentTab('form-rules')
                setShowFields(false)
            }, current: currentTab === 'form-rules' },
    ]

    const dataCollectionsTypes = [
        {label: t('forms.fields.checklist'), type: 'checklist' },
        {label: t('forms.fields.date'), type: 'date' },
        {label: t('forms.fields.caption'), type: 'label'},
        {label: t('forms.fields.number'), type: 'number' },
        {label: t('forms.fields.radio'), type: 'radio' },
        {label: t('forms.fields.select'), type: 'select' },
        {label: t('forms.fields.text'), type: 'text' },
        {label: t('forms.fields.textarea'), type: 'textarea' },
    ]

    const AddFormButton = ({onClickNewForm, onClickFormTemplate}) => <Dropdown label={t('forms.addForm')} variant='blue' options={[{label: t('forms.newForm'), onclick: onClickNewForm}, {label: t('fromTemplate'), onclick: onClickFormTemplate}]} />

    useEffect(() =>{
        const init = async () => {
            await dispatch(getDataCollectionsThunk({}))
            dispatch(checkLinkedDataCollections(Object.keys(dataCollections)))
        }
        init();
    },[])

    useEffect(() => {
        const selectedDataCollection = dataCollections[selectedFormId]
        setDataCollectionName(selectedDataCollection?.name ?? '')
        setDataCollectionDescription(selectedDataCollection?.description ?? '')
        setDataCollectionAlias(selectedDataCollection?.alias ?? '')
        setFields(dataCollections[selectedFormId]?.template?.items ?? [])
        setRules(dataCollections[selectedFormId]?.template?.rules ?? [])
        setTags(dataCollections[selectedFormId]?.tags)
    },[selectedFormId])

    useEffect(() => {
        setFields(dataCollections[selectedFormId]?.template?.items ?? [])
        setRules(dataCollections[selectedFormId]?.template?.rules ?? [])
    },[dataCollections])

    useEffect(() => {
        if((!isEmpty(dataCollections) && (isEmpty(selectedFormId))) || !Object.keys(dataCollections).find(id => id === selectedFormId)) {
            setSelectedFormId(Object.keys(dataCollections)[0])
        }
    },[selectedFormId, dataCollections])

    useEffect(() => {
        if(Object.keys(fields).length !== 0 && selectedCardId)
            setSelectedCard(fields.find(card => card.name === selectedCardId));
        else
            setSelectedCard(null);
    }, [selectedCardId]);

    useEffect(() => {
        if(!openModalNewForm) setSelectedTemplate(null)
    },[openModalNewForm])

    useEffect(() => {
        const callbackTimer = setTimeout(updateDataCollectionInfo, 2000);
        return () => clearTimeout(callbackTimer);
    },[dataCollectionName, dataCollectionDescription, tags])

    const stepsTypesFormatted = dataCollectionsTypes ?
        dataCollectionsTypes.map(dataCollect => {
            return {
                label: dataCollect.label,
                onclick: () => dispatch(addDataCollectFormFieldThunk({dataCollectId: selectedFormId, type: dataCollect.type, name: dataCollect.label}))
            }
        }) : []

    const addNewDataCollectionTemplate = (selectedRecipients) => {
        dispatch(addDataCollectFormThunk({isSenderOwner: selectedRecipients['SENDER'], id: selectedTemplate, selectedRecipients}))
    }

    const addNewDataCollectionForm = (name, description, tags, selectedRecipients) => {
        dispatch(addDataCollectFormThunk({isSenderOwner: selectedRecipients['SENDER'], name, description, tags, selectedRecipients}))
    }

    const updateFormField = async (item, onClose = () => {}) => {
        if(item.type === 'checklist' || item.type === 'radio' || item.type === 'select') {
            let errorMessage;
            if (isEmpty(item.choices)) errorMessage='Inserisci almeno una scelta'
            else if(item.choices.some(choice => isEmpty(choice.caption))) errorMessage='Non puoi inserire scelte senza valore'
            else {
                const choiceList = item.choices.map(c => c.value)
                if (new Set(choiceList).size !== choiceList.length) {
                    errorMessage = 'Non puoi inserire scelte duplicate'
                }
            }

            if(errorMessage){
                toast.custom((t) => (<Alert type={ERROR} message={errorMessage} onClose={() => toast.dismiss(t.id)}/>))
                return;
            }
        }
        await dispatch(updateDataCollectFormFieldThunk({dataCollectId: selectedFormId, item})).unwrap()
        onClose()
    }

    const onDeleteFormField= (item) => {
        if(selectedCardId === item.name) setSelectedCardId()
        dispatch(deleteDataCollectFormFieldThunk({dataCollectId: selectedFormId, item}))
    }

    const reorderFormFields = (orderedFormFieldsList) => {
        const orderedItemsIdsList = orderedFormFieldsList.map(field => field.name)
        dispatch(reorderDataCollectFormFieldsThunk({dataCollectId: selectedFormId, orderedItemsIdsList}))
    }

    const createRule = () => {
        dispatch(addDataCollectionRule({dataCollectId: selectedFormId}))
    }

    const setRule = (rule) => {
        dispatch(setDataCollectionRule({dataCollectId: selectedFormId, rule}))
    }

    const deleteRule = (rule) => {
        dispatch(deleteDataCollectionRule({dataCollectId: selectedFormId, rule}))
    }

    const updateDataCollection = async () => {
        await dispatch(setLinkedRecipientsToDataCollectionThunk({}))
        Object.keys(dataCollections).forEach(async dataCollectId => await dispatch(massiveSetDataCollectionRuleThunk({dataCollectId})))
        const actualDataCollection = dataCollections[selectedFormId]
        if(actualDataCollection && (actualDataCollection.name !== dataCollectionName || actualDataCollection.description !== dataCollectionDescription || actualDataCollection.alias !== dataCollectionAlias || actualDataCollection.tags !== tags)) {
            await dispatch(updateDataCollectFormThunk({dataCollectId: selectedFormId, name: dataCollectionName, description: dataCollectionDescription, alias: dataCollectionAlias, tags}))
        }
    }

    const nextStep = async () => {
        await updateDataCollection();
        next()
    }

    const previousStep = () => {
        updateDataCollection();
        previous()
    }

    const updateDataCollectionInfo = () => {
        dispatch(updateDataCollectFormThunk({dataCollectId: selectedFormId, name: dataCollectionName, description: dataCollectionDescription, tags}))
    }

    const selectDataCollection = (id) => {
        updateDataCollection();
        setSelectedFormId(id);
    }
    const goBackHome= () => {
        navigate('/home')
    }

    const changeDataCollectionOwner = (selectedRecipients) => {
        dispatch(setOwnerDataCollectionThunk({dataCollectId: selectedFormId, isSenderOwner:  selectedRecipients['SENDER'], selectedRecipients}))
    }

    const onClickNewDataCollection = () => {
        setOpenModalNewForm(true)
    }

    const getFormsTemplatesPaginated = (searchTerm, currentPage, offset, limit) => {
        dispatch(getForms({txtFilter: searchTerm, pagination: {currentPage, offset, limit}}));
        dispatch(countForms({txtFilter: searchTerm}));
    }

    const onSelectTemplate = async (template) => {
        await setSelectedTemplate(template);
        await setOpenModalRecipientsSelection(true);
    }

    const insertTag = (tag) => dispatch(addTagThunk({tag}))
    const deleteTag = (tag) => dispatch(deleteTagThunk({tag}))

    const hasSenderDataCollectionLinked= Object.values(sender?.linkedDataCollections)?.some(linkeddc => linkeddc)

    const switchTab = (formId) => {
        setSelectedCardId(null)
        selectDataCollection(formId)
    }



    const onDelete = (id) => {
        if (id) {
            dispatch(deleteDataCollection({dataCollectId: id}))
            dispatch(deleteDataCollectionFromRecipients(id))
        }
        setFormToDelete(null);
        setOpenModalDeleteForm(false);
    }

    const askDeleteForm = (form) => {
        setFormToDelete(form);
        setOpenModalDeleteForm(true);
    }

    return (
        <PageWithStepsHeaderAndFooter background={false} footer={true} next={nextStep} previous={previousStep} closeAction={goBackHome} disableConfirm={!allDataCollectionsLinked || isLoading} steps={steps} buildUrl={buildUrl}>
            <div className='flex max-w-7xl w-full h-full pt-8 items-start space-x-8'>
                {selectedFormId ?
                    <>
                        <div className='flex flex-col w-2/3 h-full items-start overflow-hidden'>
                            <div className='flex w-full justify-start items-start space-x-3 my-3 overflow-x-auto overflow-y-hidden'>
                                {dataCollections && Object.values(dataCollections).map(form => <TabSwitcher
                                    key={form.id} label={form.name} selected={form.id === selectedFormId}
                                    onClick={() => switchTab(form.id)}
                                    onDelete={() => askDeleteForm(form)} />)}
                            </div>
                            <TabsWithUnderline tabs={tabs}/>
                            <ListDataCollectionsFields
                                fieldTypesFormatted={stepsTypesFormatted}
                                fields={fields}
                                rules={rules}
                                onDeleteFormField={onDeleteFormField}
                                reorderFormFields={reorderFormFields}
                                showFields={showFields}
                                onCardClick={setSelectedCardId}
                                createRule={createRule}
                                setRule={setRule}
                                deleteRule={deleteRule}
                                disabled={!selectedFormId}
                            />
                        </div>
                        <div className='flex flex-col w-1/3 justify-end'>
                            <div className='flex justify-end items-center my-3'>
                                <AddFormButton onClickNewForm={onClickNewDataCollection}
                                               onClickFormTemplate={() => setOpenModalTemplateSelection(true)}/>
                            </div>
                            {selectedCardId ?
                                <div className='mt-12'>
                                    <SidebarDataCollection
                                        field={selectedCard}
                                        updateField={updateFormField}
                                        onClose={() => setSelectedCardId(null)}
                                    />
                                </div> :
                                <>
                                    <div className='flex flex-1 flex-col w-full h-full mt-3 border-b'>
                                        <RecipientsDataCollection
                                            recipients={recipients}
                                            selectedDataCollection={selectedFormId}
                                            isSenderOwner={isSenderOwner}
                                            changeDataCollectionOwner={changeDataCollectionOwner}
                                            disabled={!selectedFormId}
                                            hasSenderDataCollectionLinked={hasSenderDataCollectionLinked}
                                        />
                                    </div>
                                    <div className='flex flex-1 flex-col w-full h-full'>
                                        <InformativaInfo
                                            name={dataCollectionName}
                                            setName={setDataCollectionName}
                                            description={dataCollectionDescription}
                                            setDescription={setDataCollectionDescription}
                                            hasAlias={enableFormAlias}
                                            alias={dataCollectionAlias}
                                            setAlias={setDataCollectionAlias}
                                            tags={tags}
                                            setTags={setTags}
                                            disabled={!selectedFormId}
                                            insertTag={insertTag}
                                            deleteTag={deleteTag}
                                            hasDelete={true}
                                        />
                                    </div>
                                </>
                            }
                        </div>
                        {openModalDeleteForm && <GenericQuestionModal title={t('forms.deleteConfirmMessage')} recordName={formToDelete?.name ?? ''} open={openModalDeleteForm} setOpen={setOpenModalDeleteForm} onDelete={() => onDelete(formToDelete?.id)}/>}
                    </> :
                    <div className='flex flex-col items-center justify-center w-full h-full px-8 pb-36'>
                        <img src={EmptyStateIllustration} className='max-w-xl w-full h-full' alt='empty state'/>
                        <h1 className='text-2xl leading-8 font-medium pr-1 text-center mb-4'>{t('forms.emptyState')}</h1>
                        <AddFormButton onClickNewForm={onClickNewDataCollection} onClickFormTemplate={() => setOpenModalTemplateSelection(true)} />
                    </div>
                }
            </div>
            {openModalNewForm && <ModalNewForm
                open={openModalNewForm}
                setOpen={setOpenModalNewForm}
                recipients={recipients}
                onCreate={addNewDataCollectionForm}
                // hasSenderDataCollectionLinked={hasSenderDataCollectionLinked}
                insertTag={insertTag}
                deleteTag={deleteTag}
                hasDelete={true}
            />}
            <ModalRecipientSelection
                open={openModalRecipientsSelection}
                setOpen={setOpenModalRecipientsSelection}
                onConfirm={addNewDataCollectionTemplate}
                recipients={recipients}
                // hasSenderDataCollectionLinked={hasSenderDataCollectionLinked}
            />
            {openModalTemplateSelection && <ModalSelectPathItemTemplates
                open={openModalTemplateSelection}
                setOpen={setOpenModalTemplateSelection}
                onSelect={onSelectTemplate}
                getElementsPaginated={getFormsTemplatesPaginated}
                elements={forms}
                countElements={formsCount}
            />}
        </PageWithStepsHeaderAndFooter>
    )
}

export default DataCollect;
