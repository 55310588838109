/* This example requires Tailwind CSS v2.0+ */
import {Switch} from '@headlessui/react'
import {classNames} from "../../../utils/helper";

export default function Toggle({label, value= false, setValue}) {
    return (
        <div className="relative flex items-center">
            <div className="flex items-center h-5">
                <Switch
                    checked={value}
                    onChange={() => {setValue(!value)}}
                    className={classNames(
                        value ? 'bg-blue-600' : 'bg-gray-200',
                        'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
                    )}
                >
                    <span className="sr-only">enable</span>
                    <span
                        aria-hidden="true"
                        className={classNames(
                            value ? 'translate-x-5' : 'translate-x-0',
                            'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                        )}
                    />
                </Switch>
            </div>
            <div className="ml-3 text-sm">
                <label htmlFor={label} className="text-sm leading-5 font-normal" dangerouslySetInnerHTML={{ __html: label }}>
                </label>
            </div>
        </div>
    )
}
