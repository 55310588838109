import Input from "../../atoms/Inputs/Input/Input";
import SingleCheckbox from "../../atoms/SingleCheckbox/SingleCheckbox";
import {MinusCircleIcon, PlusIcon} from "@heroicons/react/24/solid";
import {useEffect} from "react";
import isEmpty from "lodash/isEmpty";
import {useTranslation} from "react-i18next";

const SidebarDataCollectionCustomFields = ({type, options, setOptions, choices=[], setChoices, enableFormFieldAlias= false, enableDefaultOnFormFields=false}) => {
    const {t}= useTranslation();

    const addChoice= () => {
        setChoices([...choices, {caption: ``, value: ``}])
    }

    const setChoice = (idx, caption) => {
        if (enableFormFieldAlias) {
            setChoices(choices.map((q,qid)=> {
                if(qid === idx) return {caption: caption,value:q.value}
                else return q
            }))
        } else {
            setChoices(choices.map((q,qid)=> {
                if(qid === idx) return {caption,value:caption}
                else return q
            }))
        }
    }

    const setChoiceValue = (idx, value) => {
        setChoices(choices.map((q,qid)=> {
            if(qid === idx) return {caption: q.caption, value: value}
            else return q
        }))
    }

    const deleteChoice = (e, deleteId) => {
        e.preventDefault();
        e.stopPropagation();
        setChoices(choices.filter((q, qId) => qId !== deleteId))
    }

    switch (type){
        case 'number':
            return(
                <>
                    <Placeholder options={options} setOptions={setOptions}/>
                    <MaxLength options={options} setOptions={setOptions}/>
                    <Required options={options} setOptions={setOptions}/>
                    <DefaultText options={options} setOptions={setOptions} enableDefaultOnFormFields={enableDefaultOnFormFields}/>
                </>
            )
        case 'text':
            return(
                <>
                    <Placeholder options={options} setOptions={setOptions}/>
                    <MaxLength options={options} setOptions={setOptions}/>
                    <Required options={options} setOptions={setOptions}/>
                    <DefaultText options={options} setOptions={setOptions} enableDefaultOnFormFields={enableDefaultOnFormFields}/>
                </>
            )
        case 'date':
            return(
                <>
                    <Placeholder options={options} setOptions={setOptions}/>
                    <MaxLength options={options} setOptions={setOptions}/>
                    <Required options={options} setOptions={setOptions}/>
                    <DefaultText options={options} setOptions={setOptions} enableDefaultOnFormFields={enableDefaultOnFormFields}/>
                </>
            )
        case 'checklist':
            return(
                <>
                    <SingleCheckbox label={t('forms.allRequired')} value={options?.allRequired} setValue={(value) => setOptions({...options, allRequired: value})} />
                    <Required options={options} setOptions={setOptions}/>
                    <DefaultText options={options} setOptions={setOptions} enableDefaultOnFormFields={enableDefaultOnFormFields}/>
                    <Choices choices={choices} setChoice={setChoice} setChoiceValue={setChoiceValue} addChoice={addChoice} deleteChoice={deleteChoice} enableFormFieldAlias={enableFormFieldAlias}/>
                </>
            )
        case 'select':
        case 'radio':
            return(
                <>
                    <Required options={options} setOptions={setOptions} />
                    <DefaultText options={options} setOptions={setOptions} enableDefaultOnFormFields={enableDefaultOnFormFields}/>
                    <Choices choices={choices} setChoice={setChoice} setChoiceValue={setChoiceValue} addChoice={addChoice} deleteChoice={deleteChoice} enableFormFieldAlias={enableFormFieldAlias}/>
                </>
            )
        case 'textarea':
            return(
                <>
                    <Placeholder options={options} setOptions={setOptions}/>
                    <MaxLength options={options} setOptions={setOptions}/>
                    <Required options={options} setOptions={setOptions}/>
                    <DefaultText options={options} setOptions={setOptions} enableDefaultOnFormFields={enableDefaultOnFormFields}/>
                </>
            )
        default:
            return <></>
    }
}

const Required = ({options, setOptions}) => {
    const {t}= useTranslation();
    return <SingleCheckbox label={t('forms.required')} value={options?.required}
                    setValue={(value) => setOptions({...options, required: value})}/>
}
const DefaultText = ({options, setOptions, enableDefaultOnFormFields}) => {
    const {t} = useTranslation();

    return (enableDefaultOnFormFields && !options?.required) ? <Input label={t('forms.defaultText')} type='text' value={options?.['default-text']}
                  setValue={(value) => setOptions({...options, ['default-text']: value})}/> : <></>

}
const MaxLength = ({options, setOptions}) => {
    const {t} = useTranslation();
    return <Input label={t('forms.maxLength')} type='number' value={options?.['max-length']}
                  setValue={(value) => setOptions({...options, ['max-length']: value})}/>
}
const Placeholder = ({options, setOptions}) => {
    const {t} = useTranslation();
    return <Input label={t('forms.placeholder')} value={options?.placeholder ?? ''}
                  setValue={(value) => setOptions({...options, placeholder: value})}/>
}
const Choices = ({choices, setChoice, setChoiceValue, addChoice, deleteChoice, enableFormFieldAlias}) => {
    const {t} = useTranslation();
    useEffect(() => {
        if(isEmpty(choices)) addChoice()
    },[])
    return (
        <div className='flex flex-col'>
            <p className='text-sm leading-5 font-medium py-2'>{t('forms.choices')}</p>
            {choices && choices.map((choice, choiceId) => (
                <div className='flex items-center space-x-2' key={`choice-${choiceId}`}>
                    {/*<div className='flex justify-between items-center flex-1 space-x-2'>*/}
                    {enableFormFieldAlias ?
                        <div className='flex-1'>
                            <Input value={choice?.value ?? ''} setValue={(value) => setChoiceValue(choiceId, value)} placeholder={t('forms.valueHint')}/>
                        </div> : <></>
                    }
                    <div className='flex-1'>
                        <Input value={choice?.caption ?? ''} setValue={(value) => setChoice(choiceId, value)} placeholder={t('forms.optionHint')}/>
                    </div>
                    <div className='justify-self-center'>
                        {choiceId !== 0 && <MinusCircleIcon className='text-red-600 h-6 cursor-pointer'
                                                            onClick={(e) => deleteChoice(e, choiceId)}/>}
                    </div>
                </div>
            ))}
            <button className='text-blue-600 flex flex-1 justify-center mt-4' onClick={() => addChoice()}><PlusIcon
                className='h-5'/>{t('forms.addChoices')}</button>
        </div>
    )
}

export default SidebarDataCollectionCustomFields;
