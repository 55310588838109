import i18n from "i18next";
import {toast} from "react-hot-toast";
import {Translation} from "react-i18next";
import Alert from "../components/molecules/Alerts/Alert";
import {ERROR, SUCCESS} from "../components/molecules/Alerts/types";
import {LINK_SUPPORT} from "./keys";
import isArray from "lodash/isArray";
import isString from "lodash/isString";
import {pki, util} from "node-forge";
import {v4} from "uuid";

export function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export const mapRecipient = (rec) => {
    return{
        id: rec?.id,
        actorType: rec?.actorType,
        userid: rec?.user?.id,
        givenName: rec?.user?.givenName,
        familyName:rec?.user?.familyName,
        email: rec?.user?.email,
        peopleRole: rec?.peopleRole,
        signatureType: rec?.signatureType,
        progressNumber: rec?.progressNumber,
        hasGraphConsent: rec.hasGraphConsent ?? false,
        graphConsentModelId: rec.graphConsentModelId,
        lastGraphConsentGivenDate: rec?.lastGraphConsentGivenDate
    }
}

export const unmapRecipient = (rec) => {
    const recipient = {
        id: rec?.id,
        actorType: rec?.actorType,
        isList: rec?.isList,
        peopleRole: rec?.peopleRole,
        signatureType: rec?.signatureType,
        progressNumber: rec?.progressNumber,
        hasGraphConsent: rec.hasGraphConsent ?? false,
        graphConsentModelId: rec.graphConsentModelId,
        users: rec?.users ?? [],
    }
    if(rec?.isList) {
        recipient.listId = rec?.listId
        recipient.listName = rec?.listName
    } else {
        recipient.user = {
            id: rec?.userid,
            givenName: rec?.givenName,
            familyName: rec?.familyName,
            email: rec?.email,
        }
        recipient.lastGraphConsentGivenDate = rec?.lastGraphConsentGivenDate
    }
    return recipient
}

export function formatDate(date) {
    if (!date) {
        return date;
    }

    let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;
    if (year.toString().length < 4) {
        let missing = 4 - year.toString().length;
        for (var i = 0; i < missing; i++ ) {
            year = '0' + year;
        }
    }

    return [year, month, day].join('-');
}

export const encrypt = (password) => {
    const publicKey = pki.publicKeyFromPem(process.env.REACT_APP_PUBLIC_KEY);
    const encrypted = publicKey.encrypt(password, 'RSA-OAEP');
    return util.encode64(encrypted);
}

export const isValidHttpUrl = (value) => {
    const expression = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&/=]*)/
    const regex = new RegExp(expression);
    return (isString(value)) && value.match(regex)
}

export const displayErrorAlert = (message='errorOccurred') => {
    toast.custom((tst) => (
        <Translation>
            {(t) => <Alert type={ERROR} message={t(message)} onClose={() => toast.dismiss(tst.id)}/>}
        </Translation>)
    )
}

export const displaySuccessAlert = (message='OK') => {
    toast.custom((tst) => (
        <Translation>{(t) =>
            <Alert type={SUCCESS} message={t(message)} onClose={() => toast.dismiss(tst.id)}/>}
        </Translation>)
    )
}

export const openSupportPage = () => {
    window.open(LINK_SUPPORT, "_blank");
}

export const setI18nLanguage = async (language) => await i18n.changeLanguage(language)

const checkSinglePermissions = (availablePermissions, required) => {
    return availablePermissions?.[required] ?? false
}

export const checkPermissions = (availablePermissions, required) => {
    if(isArray(required))
        return required.every(r => checkSinglePermissions(availablePermissions, r))

    else if(isString(required))
        return checkSinglePermissions(availablePermissions, required)

    else
        return false
}

export const toLocaleString = (date) => {
    const dateFormatted = new Date(date)
    return dateFormatted.toLocaleString()
}


/**
 * Estrae il nome del file dall'header Content-Disposition di una risposta.
 *
 * @param {string} contentDisposition - L'header Content-Disposition della risposta.
 * @returns {string} - Il nome del file estratto o una stringa vuota se non viene trovato.
 */
export const getFilenameFromContentDisposition = (contentDisposition) => {
    const match = contentDisposition && contentDisposition.match(/filename="?([^"]+)"?/);
    return match ? match[1] : "";
}



