import PageWithStepsHeaderAndFooter from "../../Templates/PageWithStepsHeaderAndFooter/PageWithStepsHeaderAndFooter";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
    deleteDocumentThunk,
    getDocumentDetailsThunk,
    getPlaceholdersThunk,
    previewDocumentThunk,
    setPlaceholdersThunk,
    uploadDocumentThunk
} from "../../../../features/documentBuilder/documentBuilder.actions";
import {
    addBlock,
    deleteBlock,
    documentBuilderSelector,
    selectBlock,
    setBlock
} from "../../../../features/documentBuilder/documentBuilder.slice";
import {recipientsSelector} from "../../../../features/recipients/recipients.slice";
import isEmpty from "lodash/isEmpty";
import LoadingSpinner from "../../../atoms/LoadingSpinner/LoadingSpinner";
import DocumentBuilder from "../../DocumentBuilder/DocumentBuilder";
import {useTranslation} from "react-i18next";
import {sendDocumentThunk} from "../../../../features/documentalProcess/documentalProcess.actions";

const NewDraftDocumentStep = ({next, previous, steps, confirmLabel, buildUrl}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {t} = useTranslation()
    const [selectedRecipient, setSelectedRecipient] = useState(null)
    const [recipientsFields, setRecipientsFields] = useState(null)
    const [selectedBlock, setSelectedBlock] = useState('')
    const [recipientsOptions, setRecipientsOptions] = useState([])

    const { recipients } = useSelector(recipientsSelector)
    const { documentInfo, fields, blocks, selectedBlockId, waitingFileUpload, submodels } = useSelector(documentBuilderSelector)

    const nextPage = async () => {
        await dispatch(setPlaceholdersThunk())
        await dispatch(sendDocumentThunk()).unwrap()
        next();
    }

    useEffect(() => {
        if((!selectedRecipient && !isEmpty(Object.values(recipients))) || !Object.keys(recipients)?.some(recId => recId === selectedRecipient))
            setSelectedRecipient(Object.values(recipients)?.[0]?.id)
        const options = Object.values(recipients).map(r => {return {label: r.name, value: r.id }})
        if(!isEmpty(fields?.['SENDER'])) options.push({label: t('documentBuilder.sender'), value: 'SENDER' })
        setRecipientsOptions(options)
    },[recipients, fields])

    useEffect(() => {
        setRecipientsFields(fields?.[selectedRecipient])
    },[selectedRecipient, fields])

    useEffect(() => {
        if(documentInfo?.downloadUrl && !documentInfo.cached) {
            dispatch(getDocumentDetailsThunk())
            dispatch(getPlaceholdersThunk())
        }
    },[documentInfo?.downloadUrl])

    useEffect(() => {
        console.log('submodels', submodels)
    },[submodels])


    useEffect(() => {
        if(!isEmpty(blocks) && (selectedBlockId || selectedBlock))
            setSelectedBlock(selectedBlockId ? blocks.find(block => block.id === selectedBlockId) : null)
    },[selectedBlockId, blocks])

    const handleChangeFile = (event, isTemplate, isSubmodels) => {
        const file = event.target.files[0];
        dispatch(uploadDocumentThunk({file, isTemplate, isSubmodels}))
    }

    const goBackHome= () => {
        navigate('/home')
    }

    const resetSelectedBlock = () => {
        setSelectedBlock(null)
        dispatch(selectBlock(null))
    }

    const onSetBlock = block => {
        if(block) dispatch(setBlock(block));
    }

    const onAddBlock = block => {
        if(block) dispatch(addBlock([block]));
    }

    const onSelectBlock= (block) => {
        if(block?.id) dispatch(selectBlock(block.id))
    }

    if(waitingFileUpload || !documentInfo?.downloadUrl) {
        return (
            <PageWithStepsHeaderAndFooter background={true} footer={false} closeAction={goBackHome} steps={steps}
                                          header={false} buildUrl={buildUrl}>
                <div className='flex flex-col justify-center max-w-sm'>
                    <LoadingSpinner/>
                </div>
            </PageWithStepsHeaderAndFooter>
        )
    } else {
        return (
            <DocumentBuilder
                nextPage={nextPage} goBackHome={goBackHome} previous={() => {
                previous()
            }} steps={steps}
                recipientsOptions={recipientsOptions} selectedBlock={selectedBlock} selectedBlockId={selectedBlockId}
                resetSelectedBlock={resetSelectedBlock}
                recipients={recipients} recipientsFields={recipientsFields} fields={fields}
                documentInfo={documentInfo}
                submodels={submodels}
                selectedRecipient={selectedRecipient}
                setSelectedRecipient={setSelectedRecipient}
                deleteBlock={() => {
                    dispatch(deleteBlock(selectedBlock))
                    resetSelectedBlock()
                }}
                confirmLabel={confirmLabel}
                recipientBlocksColor={recipients?.[selectedRecipient]?.color}
                handleChangeFile={(e) => handleChangeFile(e, documentInfo?.isTemplate ?? false, submodels?.length > 0 ?? false)}
                deleteDocumentFile={() => dispatch(deleteDocumentThunk())}
                previewDocumentFile={() => dispatch(previewDocumentThunk())}
                onSetBlock={onSetBlock}
                processContextType={'NONE'}
                allBlocks={blocks}
                onAddBlock={onAddBlock} selectBlock={onSelectBlock}
                header={false}
                hidePrevious={true}
                buildUrl={buildUrl}
            />
        )
    }
}

export default NewDraftDocumentStep;
