import {Dialog, Transition} from "@headlessui/react";
import React, {Fragment} from "react";
import InputWithLeadingIcon from "../../../atoms/Inputs/InputWithLeadingIcon/InputWithLeadingIcon";
import TableWithCheckbox from "../../Tables/TableWithCheckbox/TableWithCheckbox";
import {useTranslation} from "react-i18next";
import {MagnifyingGlassIcon} from "@heroicons/react/24/solid";
import Select from "../../../atoms/Select/Select";
import Button from "../../../atoms/Buttons/Button/Button";
import {classNames} from "../../../../utils/helper";
import UsersAdvancedFilters from "../../UsersAdvancedFilters/UsersAdvancedFilters";

const GenericTableModal = ({open, setOpen, searchTerm, setSearchTerm, prefilterValue, setPrefilterValue, preFilterOptions, data, selectClose, count, offset, setOffset, limit, fields, title='', secondTitle, onClickTitle, onClickSecondTitle, onClickGeneralButton, isSecondTitleSelected=false, generalButtonLabel, searchPlaceholder = '', advancedFiltesComponent}) => {
    const {t} = useTranslation()
    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" static className="fixed z-10 inset-0 overflow-y-auto" open={open} onClose={setOpen}>
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className="inline-block w-full align-bottom bg-white rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-top sm:max-w-4xl sm:w-full">
                            <div className="px-4 pt-5 pb-4 sm:px-6 sm:pt-6" >
                                <div className='sticky top-0 z-10 flex flex-col w-full bg-white'>
                                    <div className="pb-5 flex justify-between items-center">
                                        <div className='flex divide-x-2'>
                                            <h3 className={classNames(!secondTitle || !isSecondTitleSelected ? 'text-gray-900': 'text-gray-400', onClickTitle && 'cursor-pointer', 'text-lg leading-6 font-medium pr-2')}
                                                onClick={onClickTitle}>
                                                {title}</h3>
                                            {secondTitle && <h3 className={classNames(isSecondTitleSelected ? 'text-gray-900': 'text-gray-400', onClickSecondTitle && 'cursor-pointer', 'text-lg leading-6 font-medium pl-2')}
                                                                onClick={onClickSecondTitle}>
                                                {secondTitle}</h3>}
                                        </div>

                                        {onClickGeneralButton && <Button label={generalButtonLabel} size='xl' onClick={onClickGeneralButton}/>}
                                    </div>
                                    <div className='flex items-start mb-4 space-x-2' >
                                        {(preFilterOptions?.length > 1) ?
                                            <div className='w-auto'>
                                                <Select value={prefilterValue} setValue={setPrefilterValue}
                                                    options={preFilterOptions}/>
                                            </div>
                                        : <></>
                                        }
                                        <div className='flex-grow'>
                                            <InputWithLeadingIcon placeholder={searchPlaceholder ?? ''} setValue={setSearchTerm} value={searchTerm} icon={<MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}/>
                                        </div>
                                        {advancedFiltesComponent ? advancedFiltesComponent : <></>}
                                    </div>
                                </div>
                                <TableWithCheckbox
                                    data={data}
                                    setSelected={selectClose}
                                    count={count}
                                    offset={offset}
                                    setOffset={setOffset}
                                    limit={limit}
                                    fields={fields}
                                />
                            </div>

                        </div>

                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default GenericTableModal;
