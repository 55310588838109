import {DocumentTextIcon} from "@heroicons/react/24/outline";
import Input from "../../atoms/Inputs/Input/Input";
import Textarea from "../../atoms/TextArea/Textarea";
import SelectWithBadge from "../AdvancedSelect/SelectWithBadge/SelectWithBadge";
import {useTranslation} from "react-i18next";
import DisclosureItem from "../../atoms/Disclosure/DisclosureItem";

const InformativaInfo = ({name, setName, alias, setAlias, description, setDescription, tags, setTags, disabled = false, insertTag, deleteTag, hasAlias=false}) => {
    const {t} = useTranslation();
    return (
        <div>
            <DisclosureItem
                head={<div className='flex items-center'>
                    <DocumentTextIcon className='h-5 w-5' />
                    <h3 className='px-2 text-sm leading-5 font-medium'>{t('informations')}</h3>
                </div>} >
                <div className='space-y-2'>
                    <div className='flex flex-col justify-center items-stretch'>
                        <form className='flex flex-col space-y-2'>
                            <Input label={t('title')} placeholder={t('informedPath.giveTitle')} value={name} setValue={setName} disabled={disabled}/>
                            {hasAlias && <Input label={t('alias')} placeholder={t('informedPath.giveAlias')} value={alias} setValue={setAlias} disabled={disabled}/>}

                            <Textarea label={t('description')} value={description} setValue={setDescription} disabled={disabled}/>
                            <SelectWithBadge label={t('labels')} setSelectedTags={setTags} selectedTags={tags} disabled={disabled} insertTag={insertTag} deleteTag={deleteTag} hasDelete={true}/>
                        </form>
                    </div>
                </div>
            </DisclosureItem>
        </div>
    )
}

export default InformativaInfo;
