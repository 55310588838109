import {createAsyncThunk, createEntityAdapter, createSlice} from "@reduxjs/toolkit";
import {
    createConcatenatedProcess,
    getModelsDetailsCall,
    sendProcessCall,
    startConcatenation
} from "../../api/documentalProcess";
import {displayErrorAlert, displaySuccessAlert, isValidHttpUrl} from "../../utils/helper";

const sendProcess = createEntityAdapter();

const defaultSteps = [
    { id: '1', urlName: 'recipients', name: 'steps.recipients', href: 'recipients', status: 'current' },
    { id: '2', urlName: 'summary',  name: 'steps.summary', href: 'summary', status: 'upcoming' },
    { id: '3', urlName: 'end',  name: 'steps.end', href: 'end', status: 'disabled', disabled: true },
]

const concatenationSteps = [
    { id: '1', urlName: 'recipients', name: 'steps.recipients', href: 'recipients', status: 'current' },
    { id: '2', urlName: 'order',  name: 'steps.reorder', href: 'order', status: 'upcoming' },
    { id: '3', urlName: 'summary',  name: 'steps.summary', href: 'summary', status: 'upcoming' },
    { id: '4', urlName: 'end',  name: 'steps.end', href: 'end', status: 'disabled', disabled: true },
]

const initialState = sendProcess.getInitialState({
    steps: defaultSteps,
    users: {},
    currentStepIdx: 0,
    messageBody: '',
    messageSubject: '',
    reminderIntervalDays: null,
    expirationDate: null,
    selectedDevice: parseInt(localStorage.getItem('selectedDevice')) ?? '',
    waitingSend: false,
    loadingRecipients: false,
    isDocumentSent: false,
    selectedWorkspaces: []
})

export const getSendProcessDetailsThunk = createAsyncThunk(
    'sendProcess/getSendProcessDetailsThunk',
    async ({id}) => {
        const response = await getModelsDetailsCall(id);
        return response.data
    }
);

export const sendProcessThunk = createAsyncThunk(
    'sendProcess/sendProcessThunk',
    async ({senderFormData}, { getState }) => {
        const { process, messageSubject, messageBody, privateMessages, expirationDate, reminderIntervalDays, users, selectedDevice, selectedWorkspaces} = getState().sendProcess;

        const recipientsEmail = {}
        for (const id in users) {
            recipientsEmail[id] = {
                noEmail: users[id]?.emailDetails?.noEmail ?? false,
                noEmailSigned: users[id]?.emailDetails?.noEmailSigned ?? false,
                privateMessage: users[id]?.emailDetails?.privateMessage
            }
        }

        const data = {
            messageSubject,
            mailBody: messageBody,
            privateMessages,
            expirationDate,
            reminderIntervalDays,
            deviceId: selectedDevice,
            actors: Object.values(users).map(usr => {
                if(usr.redirectLink && !isValidHttpUrl(usr.redirectLink))
                    throw new Error('summary.ErrorRedirectLink')
                return{
                    roleId: usr.id,
                    role: usr.role,
                    user: usr.user,
                    redirectLink: usr.redirectLink,
                    graphConsentModelId: usr?.graphConsentModelId,
                    hasGraphConsent: usr?.hasGraphConsent,
                    isList: usr.isList,
                    listId: usr.listId,
                    listName: usr.listName,
                }
            }),
            recipients: recipientsEmail,
            selectedWorkspaces,
            senderReminderComplete: process?.senderReminderComplete ?? false,
            avoidLtArchive: process?.avoidLtArchive ?? false
        }
        if(senderFormData) data['senderFormData'] = senderFormData

        const response = await sendProcessCall(process.id, data);
        return response.data
    }
);

export const startConcatenationThunk = createAsyncThunk(
    'documentalProcess/startConcatenationThunk',
    async ({senderFormData},{getState}) => {
        const {concatenatedProcess, messageBody, messageSubject, reminderIntervalDays, expirationDate, selectedDevice, selectedWorkspaces} = getState().sendProcess;
        const events = concatenatedProcess.map(p => {
            const recipients = {}
            for(const rec of p.recipients) {
                recipients[rec.id] = {
                    id: rec.userId,
                    email: rec.email,
                    givenName: rec.givenName,
                    familyName: rec.familyName,
                    signatureType: rec.signatureType
                }
            }
            const recipientSenderFormData = {}
            //ciclo sui sender forms:
            for (const senderForm of p.senderForms) {
                const aId = senderForm?.id;
                if (aId) {
                    const answers = senderFormData?.[aId];
                    if (answers) {
                        recipientSenderFormData[aId] = answers;
                        //copio anche tutet le chiavi con i valori in recipentSenderFormData
                        for (const key in answers) {
                            recipientSenderFormData[key] = answers[key];
                        }
                    }
                }
            }
            //const senderFormId = p?.senderForms?.[0]?.id
            //const senderFormAnswers = senderFormId ? senderFormData?.[senderFormId] : null
            return ({eventFlowTypeId: p.id, recipients, senderFormData: recipientSenderFormData, order: p.order})
        })
        const response = await startConcatenation(events, messageBody, messageSubject, reminderIntervalDays, expirationDate, selectedDevice, selectedWorkspaces);
        return response.data;
    }
);

export const createConcatenatedProcessThunk = createAsyncThunk(
    'documentalProcess/createConcatenatedProcessThunk',
    async ({eventFlowTypeIds}) => {
        const response = await createConcatenatedProcess(eventFlowTypeIds.toString());
        return response.data;
    }
);

const sendProcessSlice = createSlice({
        name: 'sendProcess',
        initialState,
        reducers: {
            setRecipient: (state, action) => {
                if(action?.payload?.id) {
                    state.users[action.payload.id] = action.payload
                }
            },
            setRecipientHasGraphConsent: (state, action) => {
                if(state?.users?.[action?.payload?.id]) {
                    state.users[action.payload.id].hasGraphConsent = action.payload.hasGraphConsent
                }
            },
            setSendNoEmail: (state, action) => {
                if(state?.users?.[action?.payload?.id]) {
                    state.users[action.payload.id].emailDetails = {...state.users[action.payload.id].emailDetails, noEmail: action.payload.value}
                }
            },
            setSendNoEmailSigned: (state, action) => {
                if(state?.users?.[action?.payload?.id]) {
                    state.users[action.payload.id].emailDetails = {...state.users[action.payload.id].emailDetails, noEmailSigned: action.payload.value}
                }
            },
            setRedirectLink: (state, action) => {
                if(action?.payload?.id && state.users[action.payload.id])
                    state.users[action.payload.id].redirectLink = action.payload.redirectLink
            },
            nextStep: (state) => {
                if(state?.steps[state.currentStepIdx]?.status !== 'disabled') state.steps[state.currentStepIdx].status = 'complete';
                state.steps[state.currentStepIdx+1].status = state.steps[state.currentStepIdx+1].status === 'disabled' ? state.steps[state.currentStepIdx+1].status : 'current';
                if(state.currentStepIdx < (state.steps.length - 1)) state.currentStepIdx++
            },
            previousStep: (state) => {
                if (state.currentStepIdx > 0) state.currentStepIdx--
            },
            setCurrentStep:(state, action) => {
                state.steps.forEach(step => step.status = step.status === 'current' ? 'upcoming' : step.status)
                const idx = state.steps.findIndex(step => step.urlName === action.payload)
                state.currentStepIdx = idx
                state.steps[idx].status = state.steps[idx].status === 'disabled' ? state.steps[idx].status : 'current';
            },
            setMessageBody: (state, action) => {
                state.messageBody = action.payload
            },
            setMessageSubject: (state, action) => {
                state.messageSubject = action.payload
            },
            setReminderIntervalDays: (state, action) => {
                state.reminderIntervalDays = action.payload
            },
            setExpirationDate: (state, action) => {
                state.expirationDate = action.payload
            },
            setProcessSelectedDevice: (state, action) => {
                state.selectedDevice = action.payload
                localStorage.setItem('selectedDevice', action.payload)
            },
            selectWorkspace: (state, action) => {
                state.selectedWorkspaces = action.payload.map(w => w.id)
            },
            setSenderReminderComplete: (state, action) => {
                state.process.senderReminderComplete = action.payload
            },
            setAvoidLtArchive: (state, action) => {
                state.process.avoidLtArchive = action.payload
            },
            setConcatenatedProcessRecipient: (state, action) => {
                const actor = state.concatenatedProcess
                    .find(p => p.id === action.payload.processId)?.recipients
                    ?.find(a => a.id === action.payload.actorId)

                if(actor) {
                    actor.userId = action.payload.userId
                    actor.email = action.payload.email
                    actor.givenName = action.payload.givenName
                    actor.familyName = action.payload.familyName
                }
            },
            copyConcatenatedProcessRecipient: (state, action) => {
                if((action.payload.index || action.payload.index === 0) && (action.payload.userId || action.payload.email || action.payload.givenName || action.payload.familyName) ) {
                    state.concatenatedProcess.forEach(p => {
                        const recipient = p.recipients[action.payload.index]
                        if (recipient) {
                            recipient.userId = action.payload.userId
                            recipient.email = action.payload.email
                            recipient.givenName = action.payload.givenName
                            recipient.familyName = action.payload.familyName
                        }
                    })
                    displaySuccessAlert('recipients.confirmCopy')
                }
            },
            setStepsByProcessType: (state, action) => {
                state.steps = action.payload?.isConcatenation ? concatenationSteps : defaultSteps;
                state.currentStepIdx = 0;
            },
            setOrderedConcatenatedProcess: (state, action) => {
                try{
                    state.concatenatedProcess = action.payload?.map((p,idx) => ({...p, order: idx}))
                }catch(e) {
                    console.log(e)
                }

            },
            resetState: () => {
                return initialState;
            },
        },
        extraReducers: (builder) => {
            builder
                .addCase(getSendProcessDetailsThunk.pending, (state) => {
                return { ...initialState, loadingRecipients: true };
            })
            .addCase(getSendProcessDetailsThunk.fulfilled, (state, action) => {
                state.process = action.payload;
                if (action?.payload?.messageSubject)
                    state.messageSubject = action.payload.messageSubject;
                if (action?.payload?.messageBody)
                    state.messageBody = action.payload.messageBody;
                const attributes = JSON.parse(action.payload.attributes);
                state.users = {};
                state.process.actors.forEach((act) => (state.users[act.id] = {
                    id: act.id,
                    progressNumber: act.progressNumber,
                    signatureType: act.signatureType,
                    peopleRole: act.peopleRole,
                    role: act.name,
                    user: {},
                    redirectLink: act.redirectLink,
                    hasGraphConsent: act.hasGraphConsent,
                    graphConsentModelId: act.graphConsentModelId,
                    emailDetails: {
                        noEmail: !attributes[act.id]?.sendEmail ?? false,
                        noEmailSigned: attributes[act.id]?.noEmailSigned ?? false,
                    },
                }));
                state.loadingRecipients = false;
                state.selectedWorkspaces = action.payload.workspaces ?? [];
            })
            .addCase(getSendProcessDetailsThunk.rejected, (state) => {
                state.loadingRecipients = false;
            })
            .addCase(sendProcessThunk.pending, (state) => {
                state.waitingSend = true;
                state.isDocumentSent = false;
            })
            .addCase(sendProcessThunk.fulfilled, (state) => {
                state.waitingSend = false;
                state.isDocumentSent = true;
            })
            .addCase(sendProcessThunk.rejected, (state, action) => {
                state.waitingSend = false;
                state.isDocumentSent = false;
                displayErrorAlert(action?.error?.message);
            })
            .addCase(startConcatenationThunk.pending, (state) => {
                state.waitingSend = true;
                state.isDocumentSent = false;
            })
            .addCase(startConcatenationThunk.fulfilled, (state) => {
                state.waitingSend = false;
                state.isDocumentSent = true;
            })
            .addCase(startConcatenationThunk.rejected, (state, action) => {
                state.waitingSend = false;
                state.isDocumentSent = false;
                displayErrorAlert(action?.error?.message);
            })
            .addCase(createConcatenatedProcessThunk.fulfilled, (state, action) => {
                state.concatenatedProcess = action.payload;
            })
            .addCase(createConcatenatedProcessThunk.rejected, (state, action) => {
                console.log(action);
            });
    }
})

export const sendProcessSelector = (state) => state.sendProcess;

export const {setRecipient, setConcatenatedProcessRecipient,
    setOrderedConcatenatedProcess, setSendNoEmail,
    setSendNoEmailSigned, nextStep,
    previousStep, setMessageBody,
    setMessageSubject, setReminderIntervalDays,
    setExpirationDate, setCurrentStep,
    setProcessSelectedDevice, setRedirectLink,
    setRecipientHasGraphConsent, selectWorkspace,
    setSenderReminderComplete, copyConcatenatedProcessRecipient,
    setAvoidLtArchive,
    setStepsByProcessType, resetState} = sendProcessSlice.actions;

export default sendProcessSlice.reducer
