import SimplePage from "../Templates/SimplePage/SimplePage";
import LoginForm from "../../organisms/Login/LoginForm";
import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {authSelector} from "../../../features/auth/auth.slice";
import {login} from "../../../features/auth/auth.actions";
import Login2FaForm from "../../organisms/Login2fa/Login2FaForm";

const LoginPage = () => {
    const [username, setUsername] = useState(sessionStorage.getItem('confirmo-username')??'');
    const [password, setPassword] = useState('');
    const [totp, setTotp] = useState('');

    const {askTotp} = useSelector(authSelector);

    const [saveUsername, setSaveUsername] = useState(false)
    const {loginError, loginErrorMessage} = useSelector(authSelector)
    const dispatch = useDispatch();

    const verifyCredentials = async () => {
        if(username && password) dispatch(login({username, password, saveUsername, totp}));
    }

    return(
        <SimplePage>
            <div className='flex max-w-screen-2xl w-full h-screen justify-center items-center bg-fi-background bg-no-repeat bg-left-bottom bg-50% bg-scroll'>
                <div className="flex flex-grow justify-center">
                    {!askTotp && <LoginForm
                        username={username}
                        setUsername={setUsername}
                        password={password}
                        setPassword={setPassword}
                        loginError={loginError}
                        loginErrorMessage={loginErrorMessage}
                        verifyCredentials={verifyCredentials}
                        saveUsername={saveUsername}
                        setSaveUsername={setSaveUsername}
                    />}
                    {askTotp &&
                    <Login2FaForm
                        totp={totp}
                        setTotp={setTotp}
                        loginError={loginError}
                        loginErrorMessage={loginErrorMessage}
                        verifyCredentials={verifyCredentials}
                        saveUsername={saveUsername}
                        setSaveUsername={setSaveUsername}
                    />}
                </div>
            </div>
        </SimplePage>
    )
}
export default LoginPage;
