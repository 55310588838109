import confirmoLogo from "../../../../assets/logos/confirmo-logo.svg";
import React from "react";
import Button from "../../../atoms/Buttons/Button/Button";
import {FULL} from "../../../atoms/Buttons/sizes";
import {useNavigate} from "react-router-dom";
import success from '../../../../assets/illustrations/2_Illustrazione_Confirmo_Successo.svg'
import waitingSendIllustration from "../../../../assets/illustrations/211118_confirmo_documento-inviato-animazione.gif";
import {useTranslation} from "react-i18next";
import errorIllustration from "../../../../assets/illustrations/1_Illustrazione_Confirmo_Errore.svg";

const EndSendProcess = ({isLoading=false, isDocumentSent=true, showGoHomeButton=true, customMessage}) => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    if(isLoading) return(
        <div className='flex w-full h-screen flex-col items-center bg-background bg-no-repeat bg-right-top bg-50% bg-scroll'>
            <div className='flex flex-col justify-center items-center max-w-md w-full flex-1 my-10'>
                <img alt='waiting' src={waitingSendIllustration}/>
                <div className='flex items-end'>
                    <h1 className='text-2xl leading-8 font-medium'>{t('summary.sendingDocument')}</h1>
                    <p className='animate-bounce animation-delay-1 text-2xl leading-8 font-medium'>.</p>
                    <p className='animate-bounce animation-delay-2 text-2xl leading-8 font-medium'>.</p>
                    <p className='animate-bounce animation-delay-3 text-2xl leading-8 font-medium'>.</p>
                </div>
            </div>
        </div>
    )
    if(isDocumentSent) return(
        <div className='flex w-full h-screen flex-col items-center bg-background bg-no-repeat bg-right-top bg-50% bg-scroll'>
            <div className='flex flex-col justify-center max-w-xl flex-1 my-10'>
                <img alt='success' src={success}/>
                <div className='flex items-center mb-3'>
                    <h1 className='text-2xl leading-8 font-medium pr-1'>{t('end.confirmoThanks')}</h1>
                    <img src={confirmoLogo} alt='confirmo' className='h-8' />
                </div>
                <div className='my-3'>
                    <h3 className='text-lg leading-7 font-medium'>{t('end.documentSent')}</h3>
                    <p className='text-base leading-6 font-normal text-gray-500'>{customMessage || t('end.waitComplete')}</p>
                </div>
                {showGoHomeButton && <div className='my-3'>
                    <Button label={t('backHome')} size={FULL} onClick={() => navigate('/home')}/>
                </div>}
            </div>
        </div>
    )
    else return(
        <div className='flex w-full h-screen flex-col items-center bg-background bg-no-repeat bg-right-top bg-50% bg-scroll'>
            <div className='flex flex-col justify-center max-w-xl flex-1 my-10'>
                <img src={errorIllustration} alt='Empty State' className='max-w-xl w-full'/>
                <h1 className='text-2xl leading-8 font-medium text-center'>{t('errorOccurred')}</h1>
                {showGoHomeButton && <div className='my-3'>
                    <Button label='Torna alla Home' size={FULL} onClick={() => navigate('/home')}/>
                </div>}
            </div>
        </div>
    )
}

export default EndSendProcess;
