import {BackspaceIcon, Bars2Icon, UsersIcon, XMarkIcon, UserIcon,BoltIcon} from "@heroicons/react/24/solid";
import React, {useEffect, useState} from "react";
import Input from "../../../atoms/Inputs/Input/Input";
import ModalContacts from "../../Modals/ModalContacts/ModalContacts";
import {classNames} from "../../../../utils/helper";
import {Draggable} from "react-beautiful-dnd";
import isEmpty from "lodash/isEmpty";
import SelectSignatureType from "../../../atoms/Select/SelectSignatureType/SelectSignatureType";
import {useTranslation} from "react-i18next";
import InputProgressNumber from "../InputRecipientRole/InputProgressNumber";
import GraphConsent from "../GraphConsent/GraphConsent";
import Icon from "../../../atoms/Icon/Icon";
import IconButton from "../../../atoms/Buttons/IconButton/IconButton";
import PeopleDetailView from "../../../organisms/PeopleDetailView/PeopleDetailView";
import {useDispatch, useSelector} from "react-redux";
import {getPersonById, openPersonDetail, peopleSelector, closePersonDetail} from "../../../../features/people/people.slice";

const InputRecipient = ({recipient, setRecipient, deleteRecipient, position, signatureTypesList = [], isEditable = false, isOrdered, graphTemplatesOptions, onClickCopy, isConcatenation, senderData}) => {
    const dispatch = useDispatch();

    const [open, setOpen] = useState(false)
    const [openDetail, setOpenDetail] = useState(false)

    const {currentPerson} = useSelector(peopleSelector)

    const [signatureTypesOptions,setSignatureTypesOptions] = useState([])
    const {t} = useTranslation();
    useEffect(() => {
        if(!isEmpty(signatureTypesList)) {
            const newSignatureTypesOptions = (!isOrdered || recipient?.progressNumber === 1) ? signatureTypesList : signatureTypesList.filter(opt => opt.id !== 'GRAPH')
            if (!newSignatureTypesOptions.map(opt => opt.id).includes(recipient.signatureType))
                setRecipient({...recipient, signatureType: '', hasGraphConsent: false, graphConsentModelId: ''})

            setSignatureTypesOptions(newSignatureTypesOptions)
        }
    },[signatureTypesList, recipient.progressNumber, isOrdered])

    useEffect(() => {
        if (!currentPerson || open) {
            setOpenDetail(false);
        }
    },[currentPerson])

    const onSelect = (selected, role, fields={}) => {
        setRecipient({
            ...recipient,
            id: recipient?.id,
            role: recipient.role,
            actorType: recipient?.actorType,
            signatureType: recipient?.signatureType,
            progressNumber: recipient?.progressNumber,
            peopleRole: role ?? 'USER',
            ...fields
        })
    }

    const onSelectRecipient = (selected, role) => onSelect(selected, role, {
        isList: false,
        userid:selected.id,
        givenName:selected.givenName,
        familyName: selected.familyName,
        email: selected.email,
        lastGraphConsentGivenDate: selected?.lastGraphConsentGivenDate,
    })

    const onSelectList = (selected, role) => {
        onSelect(selected, role, {
            isList: true,
            listId: selected.id,
            listName: selected.name,
            users: selected?.users ?? [],
        })
    }

    const openPersonDetailModal = async (id) => {
        setOpen(false)
        setOpenDetail(true)
        const person = await dispatch(getPersonById({id}));
        dispatch(openPersonDetail({person: person.payload}));
    }

    return(
        <>
            <Draggable draggableId={`draggable-${recipient.id}`} index={position} isDragDisabled={!isEditable}>
                {(provided) =>
                    <div ref={provided.innerRef} {...provided.draggableProps} key={recipient.id} className="mx-auto bg-gray-50 flex flex-col shadow-md rounded-md my-4 w-full">
                        {!isEditable && recipient?.role && <h1 className="block text-lg font-bold text-gray-700 px-6 pt-4">{recipient.role}
                            <span className={'ml-3'}>
                                {!recipient.isList && !isEditable && recipient?.userid && (
                                    <button onClick={() => openPersonDetailModal(recipient?.userid)} className='focus:outline-none h-4 w-4 p-0.5' data-testid={'PencilIcon'} title={t('Dettaglio')}>
                                        <Icon type='modify' size={'4'}/>
                                    </button>
                                )}
                            </span>
                        </h1>}
                        <div  className="flex justify-between" >
                            {isEditable &&
                                <div className={classNames(!isOrdered ? 'invisible' : '', "flex items-center px-4")}>
                                    <div {...provided.dragHandleProps}>
                                        <Bars2Icon className="h-8 w-8 m-2"/>
                                    </div>
                                    <div className="flex flex-col items-center justify-center space-y-2 p-2 ml-4">
                                        <div
                                            className="w-10 flex items-center justify-center">
                                            <InputProgressNumber progressNumber={recipient.progressNumber} setProgressNumber={(value) => setRecipient({...recipient, progressNumber: value})} />
                                        </div>
                                    </div>
                                </div>
                            }

                            <div className='flex w-full items-center'>
                                <div className="grid px-2 w-full">
                                    {recipient.isList ?
                                        <>
                                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 items-center px-4">
                                                <div className="col-span-1 w-full">
                                                    <Input label={'Nome Lista'} value={recipient?.listName ?? ''}
                                                           setValue={(value) => setRecipient({
                                                               ...recipient,
                                                               givenName: value
                                                           })}
                                                           disabled={true}/>
                                                </div>
                                                <div className="col-span-1 w-full">
                                                    {isEditable ? <SelectSignatureType label={t('recipients.signType')}
                                                                                       value={recipient.signatureType ?? ''}
                                                                                       setValue={(value) => setRecipient({
                                                                                           ...recipient,
                                                                                           signatureType: value
                                                                                       })}
                                                                                       options={signatureTypesOptions}
                                                                                       placeholder={t('select')}/> :
                                                        <Input label={t('recipients.signType')}
                                                               value={recipient.signatureType ?? ''} disabled={true}/>}
                                                </div>
                                            </div>
                                        </>:
                                        <>
                                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 items-center px-4 py-2">
                                                <div className="col-span-1 w-full">
                                                    <Input label={t('name')} value={recipient?.givenName ?? ''}
                                                           setValue={(value) => setRecipient({
                                                               ...recipient,
                                                               givenName: value
                                                           })}
                                                           disabled={recipient?.userid || recipient?.userId}/>
                                                </div>
                                                <div className="col-span-1">
                                                    <Input label={t('surname')} value={recipient?.familyName ?? ''}
                                                           setValue={(value) => setRecipient({
                                                               ...recipient,
                                                               familyName: value
                                                           })}
                                                           disabled={recipient?.userid || recipient?.userId}/>
                                                </div>
                                            </div>
                                            <div className="grid grid-cols-1 md:grid-cols-2 items-center gap-4 px-4 pb-4">
                                                <div className="col-span-1 w-full">
                                                    <Input label={t('email')} type='email' value={recipient?.email ?? ''}
                                                           setValue={(value) => setRecipient({...recipient, email: value})}
                                                           disabled={recipient?.userid || recipient?.userId}/>
                                                </div>
                                                <div className="col-span-1 w-full">
                                                    {isEditable ? <SelectSignatureType label={t('recipients.signType')}
                                                                                       value={recipient.signatureType ?? ''}
                                                                                       setValue={(value) => setRecipient({
                                                                                           ...recipient,
                                                                                           signatureType: value
                                                                                       })}
                                                                                       options={signatureTypesOptions}
                                                                                       placeholder={t('select')}/> :
                                                        <Input label={t('recipients.signType')}
                                                               value={recipient.signatureType ?? ''} disabled={true}/>}
                                                </div>
                                            </div>
                                        </>}
                                    {recipient.signatureType === 'GRAPH' &&
                                        <GraphConsent
                                            isConsentChecked={recipient.hasGraphConsent}
                                            setConsent={(hasGraphConsent, graphConsentModelId) => setRecipient({
                                                ...recipient,
                                                hasGraphConsent,
                                                graphConsentModelId
                                            })}
                                            templatesOptions={graphTemplatesOptions}
                                            templateChoice={recipient?.graphConsentModelId}
                                            dateLastConsent={recipient?.lastGraphConsentGivenDate}
                                        />
                                    }
                                </div>
                            </div>
                            <div className="flex flex-col items-center pr-8 h-full justify-center space-y-4 py-4" >
                                {isConcatenation && <button onClick={() => onClickCopy()} className='focus:outline-none'
                                                            data-testid={'CopyUserIcon'} title={t('recipients.copy')}>
                                    <Icon type='barsArrowDown'/>
                                </button>}
                                <button onClick={() => setOpen(true)} className='focus:outline-none' data-testid={'UsersIcon'} title={t('rubrica')}>
                                    <UsersIcon className="h-6 w-6"/>
                                </button>
                                <button onClick={() => onSelectRecipient(senderData)} className='focus:outline-none' data-testid={'UsersIcon'} title={t('recipients.autoFill')}>
                                    <Icon type='userCircle'/>
                                </button>
                                {isEditable ?
                                    <button onClick={() => deleteRecipient(recipient)} className='focus:outline-none' title={t('delete')}>
                                        <XMarkIcon className="text-red-600 h-5 w-5"/>
                                    </button>:
                                    <button onClick={() => deleteRecipient(recipient)} className='focus:outline-none' title={t('recipients.clean')}>
                                        <BackspaceIcon className="text-black h-6 w-6"/>
                                    </button>
                                }
                            </div>
                        </div>


                    </div>}
            </Draggable>
            {(openDetail && currentPerson) ? <PeopleDetailView currentPeopleRole={recipient?.peopleRole} readonlyFields={['givenName', 'familyName', 'contactEmail']}/> : ''}
            {!openDetail && open && <ModalContacts
                open={open}
                setOpen={setOpen}
                roleFilter={recipient?.peopleRole}
                onSelectUser={onSelectRecipient}
                onSelectList={onSelectList}
                isConcatenation={isConcatenation}
            />}
        </>
    )
}

export default InputRecipient;
