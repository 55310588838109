import {Menu, Transition} from "@headlessui/react";
import React, {Fragment} from "react";
import {classNames} from "../../../../utils/helper";
import Icon from "../../Icon/Icon";

const DropdownIcon = ({iconType, options, title, onClick}) => {
    return <Menu as="div" className="inline-block text-left">
        <div title={title}>
            <Menu.Button className="rounded-full flex items-center focus:outline-none">
                <span className="sr-only">Open options</span>
                <Icon type={iconType} />
            </Menu.Button>
        </div>

        <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
        >
            <Menu.Items className="absolute mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 cursor-default focus:outline-none z-10">
                    {options.map((opt,optIdx) => (
                        <Menu.Item key={optIdx}>
                            {({ active }) => (
                                <div onClick={() => onClick(opt)} className={classNames(active ? 'bg-gray-100 text-gray-900 cursor-pointer' : 'text-gray-700', 'flex justify-between space-x-4 px-4 py-2')}>
                                    <p
                                        className='text-sm whitespace-nowrap flex flex-row'
                                    >
                                        {opt.iconType && <Icon type={opt.iconType} />}
                                        {opt.label}
                                    </p>
                                    <p className='text-sm text-gray-400 whitespace-nowrap'>{opt.status}</p>
                                </div>
                            )}
                        </Menu.Item>
                    ))}
            </Menu.Items>
        </Transition>
    </Menu>
}
export default DropdownIcon;
