import {useSelector} from "react-redux";
import {authSelector} from "../features/auth/auth.slice";
import {Navigate} from "react-router-dom";
import isEmpty from "lodash/isEmpty";

const PublicRoute = ({ children, ...rest }) => {
    const { isLoggedIn, user, draftSessionIdChecked } = useSelector(authSelector);

    if(isLoggedIn && !isEmpty(sessionStorage.getItem("token"))){

        if(user?.changePasswordNeeded === true)
            return <Navigate to="/ChangePassword" state={{ from: rest.location }} />;

        else if(draftSessionIdChecked === true)
            return <Navigate to="/finalizedraft/document" state={{ from: rest.location }} />;

        else return <Navigate to="/home" state={{ from: rest.location }} />;

    } else return children;
};

export default PublicRoute;
