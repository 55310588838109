import {useDispatch, useSelector} from "react-redux";
import React, {useMemo, useState} from "react";
import {peopleSelector, updatePersonData} from "../../../../features/people/people.slice";
import Input from "../../../atoms/Inputs/Input/Input";
import Button from "../../../atoms/Buttons/Button/Button";
import ListaProvince from '../../../../assets/Lists/ListaProvince.json'
import ListaStati from '../../../../assets/Lists/ListaStati.json'
import Select from "../../../atoms/Select/Select";
import {useTranslation} from "react-i18next";
import {XL} from "../../../atoms/Buttons/sizes";
import {formatDate} from "../../../../utils/helper";
import {userHomeSelector} from "../../../../features/userHome/userHome.slice";
import ContactAttributesItem from "../../../molecules/Modals/ModalContactDetail/ContactAttributesItem";

const PersonDetailDatiPrincipali = ({setOpen=()=>{}, currentPeopleRole, onSavePersonSuccess=()=>{}, readonlyFields=[]}) => {
    const dispatch = useDispatch();
    const {t} = useTranslation()

    const {currentPerson} = useSelector(peopleSelector)

    const optionsIdDoc = [
        {value: ' ', label: ' '},
        {value: 'CI', label: t('general.typeDoc_CI')},
        {value: 'DL', label: t('general.typeDoc_DL')},
        {value: 'PP', label: t('general.typeDoc_PP')}
    ];

    const { selectedTenantId, userOrganizations } = useSelector(userHomeSelector);
    const {enableUserExternalId, enableIdDocNumber, peopleRoles}= useMemo(() => ({
        peopleRoles: userOrganizations?.[selectedTenantId]?.peopleRoles ?? [],
        enableIdDocNumber: userOrganizations?.[selectedTenantId]?.enableIdDocNumber ?? false,
        enableUserExternalId: userOrganizations?.[selectedTenantId]?.enableUserExternalId ?? false
    }), [userOrganizations, selectedTenantId]);

    const userAttributes = useMemo(() => {
        const filteredRoles = peopleRoles?.filter(pr => pr.name === currentPeopleRole) ?? [];
        return filteredRoles.length > 0 ? filteredRoles[0].userAttributes ?? [] : [];
    }, [peopleRoles, currentPeopleRole]);

    const [givenName, setGivenName] = useState(currentPerson?.givenName)
    const [familyName, setFamilyName] = useState(currentPerson?.familyName)
    const [contactEmail, setContactEmail] = useState(currentPerson?.contactEmail)
    const [taxCode, setTaxCode] = useState(currentPerson?.taxCode)
    const [contactPhone, setContactPhone] = useState(currentPerson?.contactPhone)

    const [birthDate, setBirthDate] = useState(currentPerson?.birthDate)
    const [address, setAddress] = useState(currentPerson?.address)
    const [residencePlace, setResidencePlace] = useState(currentPerson?.residencePlace)

    const [birthPlace, setBirthPlace] = useState(currentPerson?.birthPlace)
    const [birthState, setBirthState] = useState(currentPerson?.birthState)
    const [birthCountry, setBirthCountry] = useState(currentPerson?.birthCountry)

    const [externalID, setExternalID] = useState(currentPerson?.externalID)

    //doc identita
    const [docIdType, setDocIdType] = useState(currentPerson?.docIdType)
    const [docIdNumber, setDocIdNumber] = useState(currentPerson?.docIdNumber)
    const [docIdExpireAt, setDocIdExpireAt] = useState(currentPerson?.docIdExpireAt)
    const [docIdReleasedAt, setDocIdReleasedAt] = useState(currentPerson?.docIdReleasedAt)

    //attributi dinamici
    const [attributes, setAttributes] = useState(currentPerson?.attributes ?? {})

    const id = currentPerson?.id;

    // Memorizza le liste delle province e degli stati usando useMemo
    const listaProvince = useMemo(() => [...ListaProvince, { id: '', value: '' }], []);
    const listaStati = useMemo(() => [...ListaStati, { id: '', value: '' }], []);

    const savePerson = async () => {
        dispatch(updatePersonData({
            data: {
                id,
                givenName: givenName?.trim(),
                familyName: familyName?.trim(),
                contactEmail: contactEmail?.trim(),
                taxCode: taxCode?.trim(),
                birthPlace: birthPlace?.trim(),
                birthState: birthState?.trim(),
                birthCountry: birthCountry?.trim(),
                birthDate,
                address: address?.trim(),
                residencePlace: residencePlace?.trim(),
                contactPhone: contactPhone?.trim(),
                externalID,
                docIdType,
                docIdNumber,
                docIdExpireAt,
                docIdReleasedAt,
                attributes,
                role: currentPeopleRole
            },
            onSuccess: (userData) => {
                setOpen(false)
                onSavePersonSuccess(userData)
            }
        }));
    }

    return (
        <div>
            <div className="grid grid-cols-2 gap-4 items-center px-4 pt-2 w-full">

                <Input
                    label={t('name')}
                    value={givenName}
                    setValue={setGivenName}
                    disabled={readonlyFields && readonlyFields.includes('givenName')}
                />
                <Input
                    label={t('surname')}
                    value={familyName}
                    setValue={setFamilyName}
                    disabled={readonlyFields && readonlyFields.includes('familyName')}
                />
                <Input
                    label={t('email')}
                    value={contactEmail}
                    setValue={setContactEmail}
                    disabled={readonlyFields && readonlyFields.includes('contactEmail')}
                />
                <Input
                    label={t('contactPhone')}
                    value={contactPhone}
                    setValue={setContactPhone}
                />
                <Input
                    label={t('taxCode')}
                    value={taxCode}
                    setValue={setTaxCode}
                />
                <Input
                    label={t('general.address')}
                    value={address}
                    setValue={setAddress}
                />
                <Input
                    label={t('general.residencePlace')}
                    value={residencePlace}
                    setValue={setResidencePlace}
                />
                <Input
                    label={t('general.birthplace')}
                    value={birthPlace}
                    setValue={setBirthPlace}
                />
                <div>
                    <Select label={t('general.birthstate')}
                            value={birthCountry}
                            placeholder=''
                            setValue={setBirthCountry}
                            options={listaStati}/>
                </div>
                <div>
                    <Select label={t('general.birthcountry')}
                            value={birthState}
                            placeholder=''
                            setValue={setBirthState}
                            options={listaProvince}
                    />
                </div>

                <Input
                    label={t('general.birthdate')}
                    value={formatDate(birthDate)}
                    setValue={setBirthDate}
                    type='date'
                />

                {enableUserExternalId ?
                    <Input
                        label={t('general.userExternalID')}
                        value={externalID}
                        setValue={setExternalID}
                    /> : <></>
                }
            </div>

            {enableIdDocNumber ?
                <div className="grid grid-cols-4 gap-4 items-center px-4 pt-2 w-full">

                    <Select options={optionsIdDoc} value={docIdType} setValue={setDocIdType} label={t('general.docIdType')}/>

                    <Input
                        label={t('general.docIdNumber')}
                        value={docIdNumber}
                        setValue={setDocIdNumber}
                    />

                    <Input
                        label={t('general.docIdReleasedAt')}
                        type='date'
                        value={formatDate(docIdReleasedAt)}
                        setValue={setDocIdReleasedAt}
                    />

                    <Input
                        label={t('general.docIdExpireAt')}
                        type='date'
                        value={formatDate(docIdExpireAt)}
                        setValue={setDocIdExpireAt}
                    />

                </div> :<></>}

            <div className="grid grid-cols-2 gap-4 items-center px-4 pt-2 w-full">
                {userAttributes.map(item => <ContactAttributesItem key={item.name} item={item} values={attributes[item.name]} setValues={(value) => setAttributes({...attributes, [item.name]: value})} />)}
            </div>

            <div className="flex px-4 pt-4 justify-end">
                <Button onClick={savePerson} size={XL} label={t('save')}/>
            </div>
        </div>

    )
}

export default PersonDetailDatiPrincipali;
