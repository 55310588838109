import UserSelectionList from "../../molecules/UserSelectionList/UserSelectionList";
import ReorderElementsBoard from "../../molecules/ReorderElementsBoard/ReorderElementsBoard";
import ReorderDetails from "../../molecules/ReorderDetails/ReorderDetails";

const ReorderSection = ({users=[], onSelectUserId, selectedUserId, details, detailsTitle, selectedItemId, setSelectedItemId, stepsList, setStepsList, loading=false}) => {
    return(
        <div className='flex space-x-8'>
            <div className='flex w-1/4 flex-1 flex-grow'>
                <UserSelectionList users={users} onClick={onSelectUserId} selectedUserId={selectedUserId}/>
            </div>
            <div className='flex w-1/2 flex-2'>
                <ReorderElementsBoard selectedItemId={selectedItemId} setSelectedItemId={setSelectedItemId} stepsList={stepsList} setStepsList={setStepsList} loading={loading}/>
            </div>
            <div className='flex w-1/4 flex-1'>
                <ReorderDetails title={detailsTitle} elements={details} />
            </div>
        </div>
    )
}

export default ReorderSection;
