import {CheckCircleIcon, ExclamationTriangleIcon, XMarkIcon, InformationCircleIcon} from "@heroicons/react/24/solid";
import {SUCCESS} from "./types";
import {classNames} from "../../../utils/helper";
import {useState} from "react";
import {closeNotificationDetail, hideBanner} from "../../../features/userHome/userHome.slice";
import {useDispatch} from "react-redux";

const backgroundStyle = {
    SUCCESS: 'bg-green-50',
    WARNING: 'bg-yellow-50',
    ERROR: 'bg-red-200',
    NEUTRAL: 'bg-gray-50'
}
const iconsStyle = {
    SUCCESS: <CheckCircleIcon className='text-green-400 h-5 w-5' aria-hidden="true"/>,
    WARNING: <ExclamationTriangleIcon className='text-yellow-400 h-5 w-5' aria-hidden="true"/>,
    ERROR: <ExclamationTriangleIcon className='text-red-600 h-5 w-5' aria-hidden="true"/>,
    NEUTRAL: <InformationCircleIcon className='text-blue-700 h-5 w-5' aria-hidden="true"/>
}
const textStyle = {
    SUCCESS: 'text-green-800',
    WARNING: 'text-yellow-700',
    ERROR: 'text-red-600',
    NEUTRAL: 'text-blue-700'
}

const Banner = ({type = SUCCESS, message='', active = true, id, closable=false}) => {
    const dispatch = useDispatch();

    const hideBannerFromPage = (id) => {
        console.log('Hiding banner with id:', id);
        dispatch(hideBanner({id}));
    }
    return(
        active ? <><div className={classNames(backgroundStyle[type], 'flex items-center gap-x-6 px-6 py-2.5 sm:px-3.5 sm:before:flex-1')}>
            <p className={classNames(textStyle[type], 'flex justify-center w-full text-sm leading-6')}>
                {iconsStyle[type]}
                {message}
            </p>
            {closable  ? <div className="flex flex-1 justify-end">
                <button onClick={() => hideBannerFromPage(id)} type="button" className="-m-3 p-3 focus-visible:outline-offset-[-4px]">
                    <span className="sr-only">Hide</span>
                    <XMarkIcon className={classNames(textStyle[type],'h-5 w-5')} aria-hidden="true" />
                </button>
            </div> : <></>}
        </div></> : <></>
    );

}

export default Banner
