import PeopleDetailView from "../PeopleDetailView/PeopleDetailView";
import {useDispatch, useSelector} from "react-redux";
import {delPerson, openPersonDetail, peopleSelector} from "../../../features/people/people.slice";
import React, {useMemo, useState} from "react";
import SimpleTable from "../../molecules/Tables/SimpleTable/SimpleTable";
import GenericQuestionModal from "../../molecules/Modals/GenericModal/GenericQuestionModal";
import {useTranslation} from "react-i18next";
import {CHECKBOX, DATE, ICON_BUTTON, ICON_BUTTON_LIST} from "../../atoms/SimpleTableElement/SimpleTable.types";

const PeopleList = ({data, updateData, loading, filters, setFilters, currentPeopleRole, addBirthdateToUserTable, selectedContacts, setSelectedContacts, isList=false, removeUsersFromList}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const {currentPerson} = useSelector(peopleSelector)

    const [open, setOpen] = useState(false)
    const [selected, setSelected] = useState(null)

    const deleteItem = async () => {
        isList ?
            removeUsersFromList(selected?.id):
            await dispatch(delPerson({id: selected?.id}));
    }

    const setOpenSelect = (opening) => {
        if(!opening) setSelected(null)
        setOpen(opening)
    }

    const columns = useMemo(() => {
        const viewPersonDetail = async (person) => {
            dispatch(openPersonDetail({person}));
        }
        const birthdateColumn = {field: 'birthDate', label: t('general.birthdate'), hidden: false, type: DATE}
        const actionsColumn = {
            field: 'actions', type: ICON_BUTTON_LIST, hidden: true, buttons: [
                {
                    field: 'Visualizza',
                    label: t('tableColumns.view'),
                    hidden: true,
                    type: ICON_BUTTON,
                    onClick: (d) => viewPersonDetail(d),
                    iconType: 'details'
                },
                {
                    field: 'Elimina',
                    label: t('tableColumns.delete'),
                    hidden: true,
                    type: ICON_BUTTON,
                    onClick: (data) => {
                        setSelected(data);
                        setOpen(true);
                    },
                    iconType: 'delete'
                },
            ]
        }

        const columns = [
            {field: 'select', label: t('tableColumns.select'), type: CHECKBOX, hidden: true, id: 1, selected: selectedContacts, setSelected: setSelectedContacts},
            {field: 'name', label: t('tableColumns.name'), hidden: false},
            {field: 'email', label: t('tableColumns.email'), hidden: false}
        ]
        if(addBirthdateToUserTable) columns.push(birthdateColumn)
        columns.push(actionsColumn)
        return columns
    }, [addBirthdateToUserTable, dispatch, t, selectedContacts])

    return (
        <div className="flex flex-col flex-1">
            <SimpleTable data={data} columns={columns} loading={loading} filters={filters} setFilters={setFilters}/>
            {(currentPerson) ? <PeopleDetailView currentPeopleRole={currentPeopleRole}/> : ''}
            <GenericQuestionModal title={t(isList ? 'tableColumns.deleteContactFromList' : 'tableColumns.deleteContact')}
                                  customMessage={t('tableColumns.deleteContactFromListMessage', {name: selected?.name ?? ''})}
                                  recordName={selected?.name ?? ''} open={open} setOpen={setOpenSelect}  onDelete={async () => {
                await deleteItem()
                updateData();
            }}/>
        </div>
    )
}

export default PeopleList;
