import ModalGrayFooter from "../Templates/ModalGrayFooter";
import {Dialog} from "@headlessui/react";
import React, {useState} from "react";
import Input from "../../../atoms/Inputs/Input/Input";
import {Translation, useTranslation} from "react-i18next";
import {toast} from "react-hot-toast";
import Alert from "../../Alerts/Alert";
import {ERROR} from "../../Alerts/types";

const ModalChangeFeqPin = ({open, setOpen, onConfirm}) => {
    const {t} = useTranslation()
    const [oldPin, setOldPin] = useState('')
    const [newPin, setNewPin] = useState('')
    const [newPinConfirm, setNewPinConfirm] = useState('')

    const confirm = () => {
        if (!newPin || !oldPin || !newPinConfirm) {
            toast.custom((tst) => (<Translation>{(t) => <Alert type={ERROR} message={t('allFieldsMandatory')} onClose={() => toast.dismiss(tst.id)}/>}</Translation>))
            return;
        }
        if (newPin!== newPinConfirm) {
            toast.custom((tst) => (<Translation>{(t) => <Alert type={ERROR} message={t('newPinDiffersNewPinConfirm')} onClose={() => toast.dismiss(tst.id)}/>}</Translation>))
            return;
        }

        onConfirm({newPin, oldPin, newPinConfirm})
        setOpen(false)
    }

/*    useEffect(() => {
        setName(initialName)
    },[open])*/

    return(
        <ModalGrayFooter open={open} setOpen={setOpen} onConfirm={confirm} confirmLabel={'Procedi'} >
            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full ">
                <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                    {t('changeFeqPIN')}
                </Dialog.Title>
                <div className="mt-2">
                    <Input type='password' value={oldPin} setValue={setOldPin} label={t('currentPIN')} minLength={8} />
                </div>
                <div className="mt-2">
                    <Input type='password' value={newPin} setValue={setNewPin} label={t('newPIN')} minLength={8} />
                </div>
                <div className="mt-2">
                    <Input type='password' value={newPinConfirm} setValue={setNewPinConfirm} label={t('confirmNewPIN')} minLength={8} />
                </div>
            </div>
        </ModalGrayFooter>
    )
}

export default ModalChangeFeqPin;
