import {classNames} from "../../../../utils/helper";
import {Menu, Transition} from '@headlessui/react'
import React, {Fragment, useMemo} from "react";
import Icon from "../../Icon/Icon";
import isEmpty from "lodash/isEmpty";

const ButtonWithOptionsDropdown = ({label, options = [], item, title, onClick}) => {
    const showOptions = useMemo(() => !isEmpty(options), [options])
    return (
        <Menu as="div"  className="relative inline-block text-left w-full">
            {({ open }) => (
                <>
                    <div title={title} className={classNames("flex justify-between h-full w-full rounded-md border border-gray-300 shadow-sm text-sm font-medium focus:outline-none " +
                        "focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500 bg-blue-600 text-white hover:bg-blue-700", showOptions ? "justify-center" : "justify-between")} >
                        <button className={classNames("px-4 py-2 w-full", showOptions ? "text-left" : "text-center")} onClick={onClick}>
                            {label}
                        </button>
                        {showOptions && <Menu.Button className="p-2">
                            <Icon size={5} aria-hidden="true" />
                        </Menu.Button>}
                    </div>

                    <Transition
                        show={open}
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <Menu.Items
                            static
                            className="origin-top-right absolute right-0 mt-1 rounded-md shadow-lg w-full bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10"
                        >
                            <div className="py-1">
                                {options?.map(opt => <Menu.Item key={opt.label}>
                                    {({ active }) => (
                                        <div
                                            onClick={() => (opt.onclick ? opt.onclick(item) : {})}
                                            className={classNames(
                                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                'block px-4 py-2 text-sm'
                                            )}
                                        >
                                            <span className={classNames(
                                                "cursor-pointer",
                                                opt.labelVariant === 'warn' ? 'text-red-600' : ''
                                            )}>{opt.label}</span>
                                        </div>
                                    )}
                                </Menu.Item>
                                )}
                            </div>
                        </Menu.Items>
                    </Transition>
                </>
            )}
        </Menu>
    )
}

export default ButtonWithOptionsDropdown;
