const colors = {
    gray: 'bg-gray-100 text-gray-800',
    red: 'bg-red-100 text-red-800',
    yellow: 'bg-yellow-100 text-yellow-800',
    green: 'bg-green-100 text-green-800',
    blue: 'bg-blue-100 text-blue-600',
    indigo: 'bg-indigo-100 text-indigo-800',
    purple: 'bg-purple-100 text-purple-800 hover:bg-purple-200 focus:bg-purple-500 focus:text-white focus:outline-none',
    pink: 'bg-pink-100 text-pink-800'
}

const Badge = ({label, color = 'purple', onClick}) => {
    return (
      <div onClick={onClick} className={`flex items-center truncate px-2.5 py-1 rounded-full text-xs font-medium min-w-min h-full ${colors[color]}`}>
          {label}
      </div>
    )
}

export default Badge;
