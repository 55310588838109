import {createEntityAdapter, createSlice} from "@reduxjs/toolkit";
import {
    addTagThunk,
    changeRoleThunk,
    changeSelectedTenantThunk,
    checkAccessLinkThunk,
    createNewWorkspaceThunk, deleteTagThunk,
    getAllWorkspacesInfoThunk,
    getAvailableWorkspacesThunk,
    getEcoStatsThunk,
    getHomeActions,
    getLanguagesThunk,
    getLastNotificationsList,
    getMembersThunk,
    getModelsList,
    getNotifications,
    getTags,
    getUserOrganizations,
    inviteMemberByEmailThunk,
    joinUserToWorkspaceThunk,
    registerUserWithAccessLinkThunk,
    removeMemberThunk,
    removeUserFromWorkspaceThunk, retrieveOrganizationDetailsThunk,
    sendInvitationAgainThunk,
    updateModelAsFavouriteThunk
} from "./userHome.actions";
import {toast} from "react-hot-toast";
import Alert from "../../components/molecules/Alerts/Alert";
import {ERROR, SUCCESS} from "../../components/molecules/Alerts/types";
import {Translation} from "react-i18next";
import React from "react";

const userHomeAdapter = createEntityAdapter()

const setFavourite = (state, index, isFavourite) => {
    const idx = state.models?.findIndex(mod => mod.id === index)
    if ((idx===0 || idx) && state?.models?.[idx] && (isFavourite === true || isFavourite === false))
        state.models[idx].isFavourite = isFavourite;
}

const initialState = userHomeAdapter.getInitialState({
    userOrganizations: [],
    homeActions: [],
    tags: [],

    lastNotifications: [],
    notificationDetailOpened: false,
    currentNotification: null,
    notifications: [],
    notificationsListLoaded: false,
    notificationsCount: 0,

    widgetToRefresh: {},
    models: [],
    languages: [],
    selectedTenantId: null,
    ecoStats: {},

    loadingInvitation: false,
    errorInvitation: false,
    errorMessageInvitation: null,
    needRegistration: true,

    maintenanceMode: false,

    loadingMembers: false,
    listMembersLoaded: false,
    listDevicesLoaded: false,
    errorMembers: false,
    errorMessageMembers:null,
    members:[],
    membersCount:0,
    availableWorkspaces: [],
    allWorkspacesInfo: []
})

const userHomeSlice = createSlice({
    name: 'userHome',
    initialState,
    reducers: {
        resetMembersList: (state) => {
            state.listMembersLoaded= false
            state.listDevicesLoaded= false
        },
        openNotificationDetail: (state, action) => {
            state.currentNotification = action.payload.item
            state.notificationDetailOpened = true
        },
        closeNotificationDetail: (state, ) => {
            state.currentNotification = null
            state.notificationDetailOpened = false
        },
        resetState: () => {
            return initialState;
        },
        hideBanner: (state, action) => {
            Object.values(state.userOrganizations).forEach(org => Object.values(org.banners  ?? []).forEach(banner => {
                    if (banner.id === action.payload.id) {
                        banner.active = false;
                    }
                }
            ))
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getUserOrganizations.fulfilled, (state, action) => {
                state.userOrganizations = action.payload;
                if (Object.keys(state.userOrganizations).length === 1) {
                    const tenantId = Object.keys(state.userOrganizations)?.[0];
                    state.selectedTenantId = tenantId;
                    sessionStorage.setItem('selectedTenantId', tenantId);
                }
            })
            .addCase(getUserOrganizations.rejected, (state) => {
                state.userOrganizations = {};
            })
            .addCase(getHomeActions.fulfilled, (state, action) => {
                state.homeActions = [];
                Object.keys(action.payload).forEach((key) => {
                    state.homeActions.push({
                        label: key,
                        value: action.payload[key],
                    });
                });
            })
            .addCase(getHomeActions.rejected, (state) => {
                state.homeActions = [];
            })
            .addCase(getLastNotificationsList.fulfilled, (state, action) => {
                state.lastNotifications = action.payload.data ?? [];
                state.maintenanceMode = action.payload.maintenanceMode ?? false;
            })
            .addCase(getLastNotificationsList.rejected, (state) => {
                state.lastNotifications = [];
            })
            .addCase(getNotifications.fulfilled, (state, action) => {
                state.notifications = action.payload.data ?? [];
                state.notificationsCount = action.payload.count ?? 0;
                state.maintenanceMode = action.payload.maintenanceMode ?? false;
                state.notificationsListLoaded = true;
            })
            .addCase(getNotifications.rejected, (state) => {
                state.notifications = [];
                state.notificationsCount = 0;
                state.notificationsListLoaded = true;
            })
            .addCase(getModelsList.fulfilled, (state, action) => {
                state.models = action.payload;
            })
            .addCase(getTags.fulfilled, (state, action) => {
                state.tags = action.payload;
            })
            .addCase(addTagThunk.fulfilled, (state, action) => {
                state.tags = action.payload;
            })
            .addCase(deleteTagThunk.fulfilled, (state, action) => {
                state.tags = action.payload;
                toast.custom((t) => ( <Alert type={SUCCESS} message='Operazione effettuata' onClose={() => toast.dismiss(t.id)}/> ))
            })
            .addCase(deleteTagThunk.rejected, (state, action) => {
                toast.custom((tst) => (<Translation>{(t) => <Alert type={ERROR} message={t('tags.errorDeleting')} onClose={() => toast.dismiss(tst.id)}/>}</Translation>));
            })
            .addCase(getLanguagesThunk.fulfilled, (state, action) => {
                state.languages = action.payload;
            })
            .addCase(updateModelAsFavouriteThunk.pending, (state, action) => {
                setFavourite(state, action.meta.arg.processId, action.meta.arg.isFavourite);
            })
            .addCase(updateModelAsFavouriteThunk.fulfilled, (state, action) => {
                setFavourite(state, action.payload.processId, action.payload.isFavourite);
            })
            .addCase(updateModelAsFavouriteThunk.rejected, (state, action) => {
                setFavourite(state, action.meta.arg.processId, !action.meta.arg.isFavourite);
            })
            .addCase(getEcoStatsThunk.fulfilled, (state, action) => {
                state.ecoStats = action.payload;
            })
            .addCase(checkAccessLinkThunk.pending, (state) => {
                state.loadingInvitation = true;
                state.errorInvitation = false;
                state.errorMessageInvitation = null;
                state.needRegistration = true;
                state.invitationEmail = '';
            })
            .addCase(checkAccessLinkThunk.fulfilled, (state, action) => {
                state.needRegistration = action.payload.needRegistration;
                state.loadingInvitation = false;
                state.invitationEmail = action.payload.email;
            })
            .addCase(checkAccessLinkThunk.rejected, (state, action) => {
                state.errorInvitation = true;
                state.errorMessageInvitation = action.error.message;
                state.loadingInvitation = false;
            })
            .addCase(registerUserWithAccessLinkThunk.pending, (state) => {
                state.loadingInvitation = true;
                state.errorInvitation = false;
                state.errorMessageInvitation = null;
            })
            .addCase(registerUserWithAccessLinkThunk.fulfilled, (state) => {
                state.needRegistration = false;
                state.loadingInvitation = false;
            })
            .addCase(registerUserWithAccessLinkThunk.rejected, (state, action) => {
                state.errorInvitation = true;
                state.errorMessageInvitation = action.error.message;
                state.loadingInvitation = false;
            })
            .addCase(getMembersThunk.pending, (state) => {
                state.loadingMembers = true;
                state.errorMembers = false;
                state.errorMessageMembers = null;
            })
            .addCase(getMembersThunk.fulfilled, (state, action) => {
                state.members = action.payload.members;
                state.membersCount = action.payload.count;
                state.loadingMembers = false;
                state.listMembersLoaded = true;
            })
            .addCase(getMembersThunk.rejected, (state, action) => {
                state.errorMembers = true;
                state.errorMessageMembers = action.error.message;
                state.loadingMembers = false;
                toast.custom((tst) => (<Alert type={ERROR} message={action.error.message} onClose={() => toast.dismiss(tst.id)} />));
            })
            .addCase(removeMemberThunk.fulfilled, (state) => {
                state.listMembersLoaded = false;
            })
            .addCase(changeRoleThunk.fulfilled, (state) => {
                state.listMembersLoaded = false;
            })
            .addCase(inviteMemberByEmailThunk.fulfilled, (state) => {
                state.listMembersLoaded = false;
            })
            .addCase(removeMemberThunk.rejected, (state, action) => {
                toast.custom((tst) => (<Alert type={ERROR} message={action.error.message} onClose={() => toast.dismiss(tst.id)} />));
            })
            .addCase(changeRoleThunk.rejected, (state, action) => {
                toast.custom((tst) => (<Alert type={ERROR} message={action.error.message} onClose={() => toast.dismiss(tst.id)} />));
            })
            .addCase(inviteMemberByEmailThunk.rejected, (state, action) => {
                toast.custom((tst) => (<Alert type={ERROR} message={action.error.message} onClose={() => toast.dismiss(tst.id)} />));
            })
            .addCase(sendInvitationAgainThunk.fulfilled, () => {
                toast.custom((tst) => (<Translation>{(t) => <Alert type={SUCCESS} message={t('manageusers.alertInvitationSentAgainSuccess')} onClose={() => toast.dismiss(tst.id)} />}</Translation>));
            })
            .addCase(sendInvitationAgainThunk.rejected, () => {
                toast.custom((tst) => (<Translation>{(t) => <Alert type={ERROR} message={t('manageusers.alertInvitationSentAgainError')} onClose={() => toast.dismiss(tst.id)} />}</Translation>));
            })
            .addCase(getAllWorkspacesInfoThunk.pending, (state) => {
                state.allWorkspacesInfo = [];
            })
            .addCase(getAllWorkspacesInfoThunk.fulfilled, (state, action) => {
                state.allWorkspacesInfo = action.payload;
            })
            .addCase(getAvailableWorkspacesThunk.pending, (state) => {
                state.availableWorkspaces = [];
            })
            .addCase(getAvailableWorkspacesThunk.fulfilled, (state, action) => {
                state.availableWorkspaces = action.payload;
            })
            .addCase(createNewWorkspaceThunk.fulfilled, () => {
                toast.custom((tst) => (<Translation>{(t) => <Alert type={SUCCESS} message={t('alertAfterCreateNewWorkspace')} onClose={() => toast.dismiss(tst.id)} />}</Translation>));
            })
            .addCase(joinUserToWorkspaceThunk.fulfilled, (state, action) => {
                state.allWorkspacesInfo = action.payload;
            })
            .addCase(removeUserFromWorkspaceThunk.fulfilled, (state, action) => {
                state.allWorkspacesInfo = action.payload;
            })
            .addCase(changeSelectedTenantThunk.fulfilled, (state, action) => {
                const selectedTenantId = action.payload.selectedTenantId;
                state.selectedTenantId = selectedTenantId;
                try {
                    sessionStorage.setItem('selectedTenantId', selectedTenantId);
                    sessionStorage.setItem('token', 'Bearer ' + action.payload.token);
                } catch (e) {
                    console.log(e);
                } finally {
                    //window.location.reload()
                }
            })
            .addCase(retrieveOrganizationDetailsThunk.fulfilled, (state, action) => {
                state.maintenanceMode = action.payload.maintenanceMode;
            });

    },
})

// Action creators are generated for each case reducer function
export const { hideBanner, resetState, resetMembersList, openNotificationDetail, closeNotificationDetail } = userHomeSlice.actions;

export const userHomeSelector = (state) => state.userHome;

export default userHomeSlice.reducer;
