import {classNames} from "../../../utils/helper";
import Icon from "../Icon/Icon";

const TabSwitcher = ({label, selected=false, onClick, onDelete, checked=false}) => {

    const onClickDelete = (e) => {
        e.preventDefault();
        e.stopPropagation();
        onDelete();
    }

    return(
        <button
            onClick={onClick}
            title={label}
            className={classNames(
                selected ? 'bg-blue-600 text-white hover:bg-blue-700' : 'text-gray-500 bg-white hover:bg-gray-100',
                "w-36 flex flex-nowrap items-center justify-between px-3 py-2 border border-transparent text-sm font-medium rounded-md outline-none focus:outline-none")}
        >
            <p className='truncate'>{label}</p>
            {onDelete && <div className='ml-3'>
                <Icon type='x-mark' color={selected ? 'white' : 'red'} size='4' onClick={onClickDelete} />
            </div>}
            {checked && <Icon type='checkCirce' color='green' size='5'/>}
        </button>)
}

export default TabSwitcher;
