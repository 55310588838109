import client from "./client";
import {encrypt} from "../utils/helper";
import FileSaver from "file-saver";

export const listUserOrganizations = async () => {
    return await client.get('/api/organizations');
}

export const retrieveHomeActions = async () => {
    return await client.get('/api/home/actions');
}

export const retrieveLastNotifications = async () => {
    return await client.get(`/api/notifications?offset=0&limit=5`);
}

export const listNotifications = async (props) => {
    var url = '/api/notifications/?';
    if (props.pagination && props.pagination.offset) {
        url += 'offset=' + props.pagination.offset;
    } else {
        url += 'offset=0';
    }
    if (props.pagination && props.pagination.limit) {
        url += '&limit=' + props.pagination.limit;
    }
    return await client.get(url);
}


export const getModels = async (type = 'ALL', tags = []) => {
    return await client.get(`/api/doc-api/event-flow-types/${type}`,{params:{tags: tags.join()}})
}

export const getTagsList = async () => {
    return await client.get(`/api/doc-api/tags`);
}

export const addTagCall = async (tag) => {
    return await client.post(`/api/doc-api/tags`,{name: tag});
}

export const deleteTagCall = async (tag) => {
    return await client.delete(`/api/doc-api/tags/${tag?.id}`);
}



export const getLanguages = async () => {
    return await client.get(`/api/doc-api/languages`);
}

export const getEcoStatsCall = async () => {
    return await client.get(`/api/home/eco-stats`);
}

export const checkAccessLinkCall = async (accessLink) => {
    return await client.get('/api/usermanage/invitations/'+accessLink)
}

export const registerUserWithAccessLinkCall = async (accessLink, signupData) => {
    return await client.post('/api/usermanage/invitations/signup/'+accessLink, {...signupData, newPassword: encrypt(signupData.newPassword), confirmPassword: encrypt(signupData.confirmPassword)})
}

export const downloadMembersCall = async () => {
    client.downloadFile(`/api/usermanage/download-members`, function(blob) {
        FileSaver.saveAs(blob, "export_" + new Date().getTime() + ".xlsx");
    });
}

export const getMembersCall = async (props) => {
    let url = '/api/usermanage';
    if (props) {
        url += '?';
        if (props.pagination && props.pagination.offset) {
            url += 'offset=' + props.pagination.offset;
        } else {
            url += 'offset=0';
        }
        if (props.pagination && props.pagination.limit) {
            url += '&limit=' + props.pagination.limit;
        }
        if (props.txtFilter) {
            url += '&filter=' + props.txtFilter;
        }
        if(props.sortField)
            url += '&sortField=' + props.sortField
        if(props.descending)
            url += '&desc=' + props.descending;
    }
    return await client.get(url)
}

export const removeMemberCall = async (id, isInvitation) => {
    return await client.post('/api/usermanage/removeMember', {id, isInvitation})
}

export const changeRoleCall = async (id, role) => {
    return await client.post('/api/usermanage/changerole', {id, role})
}

export const inviteMemberByEmailCall = async (email, role) => {
    return await client.post('/api/usermanage/invitations', {email, role})
}

export const sendInvitationAgain = async (id) => {
    return await client.post(`/api/usermanage/invitations/${id}`)
}

export const getAllWorkspacesInfoCall = async () => {
    return await client.get('/api/workspaces/all')
}

export const getAvailableWorkspacesCall = async () => {
    return await client.get('/api/users-profile/workspaces')
}

export const createNewWorkspaceCall = async (name) => {
    return await client.put('/api/workspaces', {name})
}

export const deleteWorkspaceCall = async (id) => {
    return await client.delete(`/api/workspaces/${id}`)
}

export const joinUserToWorkspaceCall = async (userId, workspaceId) => {
    return await client.post('/api/workspaces/joinUser', {userId, workspaceId})
}

export const removeUserFromWorkspaceCall = async (userId, workspaceId) => {
    return await client.post('/api/workspaces/removeUser', {userId, workspaceId})
}

export const updateWorkspaceNameCall = async (workspaceId, name) => {
    return await client.post(`/api/workspaces/${workspaceId}`, {name})
}

export const changeSelectedTenantCall = async (userOrganizationRoleId) => {
    return await client.post(`/api/users-profile/changeSelectedOrganization`, {userOrganizationRoleId})
}

export const retrieveOrganizationDetailsCall = async (userOrganizationRoleId) => {
    return await client.post(`/api/users-profile/retrieveOrganizationDetails`, {userOrganizationRoleId})
}



