import {v4} from "uuid";
import {useTranslation} from "react-i18next";

const SimpleStackedList = ({title, data}) => {
    const { t } = useTranslation();
    return (
        <div className='flex flex-col'>
            <h1 className='text-2xl leading-8 font-medium'>{title}</h1>
            <ul className="divide-y divide-gray-200 py-4">
                {data.map((row) => {
                    const id = v4();
                    return <li key={id} className="py-4 flex">
                        <div className="">
                            <p className="leading-6 font-medium">{t(row.subtitle)}</p>
                            <p className="text-sm text-gray-700">{t(row.text)}</p>
                        </div>
                    </li>
                })}
            </ul>
        </div>
    )
}

export default SimpleStackedList;
