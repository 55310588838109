import PageWithStepsHeaderAndFooter from "../../Templates/PageWithStepsHeaderAndFooter/PageWithStepsHeaderAndFooter";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getRecipientsThunk} from "../../../../features/recipients/recipients.actions";
import {recipientsSelector} from "../../../../features/recipients/recipients.slice";
import {
    getDocumentalProcessItemsThunk,
    reorderDocumentalProcessItemsThunk
} from "../../../../features/documentalProcess/documentalProcess.actions";
import {
    documentalProcessSelector,
    reorderDocumentalProcessItems
} from "../../../../features/documentalProcess/documentalProcess.slice";
import ReorderSection from "../../../organisms/ReorderSection/ReorderSection";
import {t} from "i18next";

const ReorderElements = ({next, previous, steps, buildUrl=()=>{}}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {recipients} = useSelector(recipientsSelector)
    const { documentalProcessItems, loadingReorderItems } = useSelector(documentalProcessSelector)
    const [selectedUserId, setSelectedUserId] = useState();
    const [selectedItemId, setSelectedItemId] = useState();
    const [users, setUsers] = useState([])
    const [previewItems, setPreviewItems] = useState([]);
    const titleSelected = documentalProcessItems[selectedUserId]?.find(el => el.id === selectedItemId)?.name ?? ''

    useEffect(() => {
        dispatch(getRecipientsThunk({}))
    },[])

    useEffect(() => {
        const usersMap = Object.values(recipients).map((rec) => {return {name: rec.name, id: rec.id}})
        usersMap.push({name: t('documentBuilder.sender'), id: 'SENDER'}); //dev-314: caso multiform-mttente
        setUsers(usersMap)
        setSelectedUserId(usersMap?.[0]?.id)
        users.forEach(user => dispatch(getDocumentalProcessItemsThunk({recipientId: user.id})))
    },[recipients])

    useEffect(() => {
        const foundItem = documentalProcessItems[selectedUserId]?.find(item => item.id === selectedItemId)
        setPreviewItems(foundItem?.itemType === 'INFORMED_PATH' ? foundItem.details.map(item => {return {id: item.id, name: item.name, type: item.typeSymbol}}) :
            foundItem?.itemType === 'FORM' ? foundItem.template.items.map(item => {return {id: item.name, name: item.caption, type: item.type}}) : []
        )
    },[selectedItemId])

    const setShownReorderElements = (items) => {
        if(selectedUserId) dispatch(reorderDocumentalProcessItems({selectedUserId, items}))
    }

    const goBackHome= () => {
        navigate('/home')
    }

    const onClickNext = async () => {
        await dispatch(reorderDocumentalProcessItemsThunk());
        next()
    }

    const onClickBack = async () => {
        await dispatch(reorderDocumentalProcessItemsThunk());
        previous()
    }

    return(
        <PageWithStepsHeaderAndFooter background={false} footer={true} next={onClickNext} previous={onClickBack} closeAction={goBackHome} steps={steps} buildUrl={buildUrl}>
            <div className='flex flex-col max-w-7xl w-full h-full p-8'>
                <ReorderSection
                    users={users}
                    onSelectUserId={(userId) => {
                        setSelectedUserId(userId);
                        setSelectedItemId(null)
                    }}
                    selectedUserId={selectedUserId}
                    selectedItemId={selectedItemId}
                    setSelectedItemId={setSelectedItemId}
                    detailsTitle={titleSelected}
                    details={previewItems}
                    stepsList={documentalProcessItems[selectedUserId]}
                    setStepsList={setShownReorderElements}
                    loading={loadingReorderItems}
                />
            </div>
        </PageWithStepsHeaderAndFooter>
    )
}
export default ReorderElements;
