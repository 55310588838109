import client from "./client";
import {encrypt} from "../utils/helper";

export const loginCheck = async (username, password, totp) => {
    const data = {username, password: encrypt(password)}
    if (totp)  data.totp = totp
    return await client.post('/api/users-profile/login', data);
}

export const forgotPasswordRequest = async (username) => {
    return await client.post('/api/users-profile/reset-password-request?email=' + username, {});
}

export const resetPasswordRequest = async (resetPwdToken, newPassword, confirmPassword) => {
    return await client.post('/api/users-profile/reset-password', {resetPwdToken, newPassword: encrypt( newPassword), confirmPassword: encrypt( confirmPassword)});
}

export const changePasswordCall = async (currentPassword, newPassword, confirmPassword) => {
    return await client.post('/api/users-profile/change-password', {currentPassword: encrypt(currentPassword), newPassword: encrypt(newPassword), confirmPassword: encrypt(confirmPassword)});
}

export const registrationRequest = async (credentials) => {
    return await client.post('/api/users-profile/signup', {...credentials, newPassword: encrypt(credentials.newPassword), confirmPassword: encrypt(credentials.confirmPassword)});
}

export const activateUserRequest = async (credentials) => {
    return await client.post('/api/users-profile/activate-account/' + credentials.activationToken, {});
}

export const retrieveCurrentUser = async () => {
    return await client.get('/api/users-profile/me');
}

export const doCheckDraftSessionId = async (sessionId) => {
    return await client.post('/api/signature/accessByDraftSessionId/' + sessionId, {});
}

export const setPreferredLanguageCall = async (preferredLanguage) => {
    return await client.put(`/api/users-profile/update-preferred-language`, {preferredLanguage});
}

export const setUsersAdvancedFilters = async (usersAdvancedFilters) => {
    return await client.put(`/api/users-profile/update-users-advanced-filters`, {usersAdvancedFilters});
}

export const setPhoneCall = async (phoneNumber) => {
    return await client.put(`/api/users-profile/update-attributes`, {phoneNumber});
}

export const setLimitUser = async (limitUser) => {
    return await client.post(`/api/users-profile/limit`, {limitUser})
}

export const retrieveFeqSignerProfile = async () => {
    return await client.get(`/api/signature/retrieve-feq-profile`)
}


export const askEnable2Fa = async (limitUser) => {
    return await client.post(`/api/users-profile/askEnable2fa`, {})
}

export const enable2Fa = async ({sessionId, code}) => {
    return await client.post(`/api/users-profile/enable2fa`, {sessionId, code})
}

export const disable2Fa = async (limitUser) => {
    return await client.post(`/api/users-profile/disable2fa`, {})
}
