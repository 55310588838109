import Title from "../../atoms/Title/Title";
import {useTranslation} from "react-i18next";
import SingleCheckbox from "../../atoms/SingleCheckbox/SingleCheckbox";
import {useDispatch} from "react-redux";
import React, {useEffect, useState} from "react";
import {getSignatureTypesListThunk} from "../../../features/recipients/recipients.actions";
import InputRecipientRole from "../../molecules/InputRecipient/InputRecipientRole/InputRecipientRole";
import Button from "../../atoms/Buttons/Button/Button";
import {FULL} from "../../atoms/Buttons/sizes";
import {DragDropContext, Droppable} from "react-beautiful-dnd";
import {v4} from "uuid";
import {reorderList} from "../../../utils/beautiful-dnd-helper";
import emptyStateIllustration from '../../../assets/illustrations/23_Illustrazione_Confirmo_Selezione-Destinatari.svg'
import isEmpty from "lodash/isEmpty";
import ModalSetRecipientsOrder from "../../molecules/Modals/ModalSetRecipientsOrder/ModalSetRecipientsOrder";

const FormInputRecipients = ({orderedRecipients, setOrderedRecipients, signatureTypesList, isOrdered, setIsOrdered, graphTemplatesOptions=[]}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false)

    useEffect(() => {
        dispatch(getSignatureTypesListThunk())
        if(isEmpty(orderedRecipients)) {
            setOpen(true)
            newRecipient()
        }
    },[])

    const newRecipient = () => {
        const id = v4();
        setOrderedRecipients({...orderedRecipients, [id]: {
                id,
                name: '',
                actorType: 'ROLE',
                progressNumber: Object.keys(orderedRecipients).length +1,
                signatureType: '',
            }})
    }

    const removeRecipient= (removed) => {
        let tempRecipients = {}
        Object.values(orderedRecipients).forEach(oRec => {
            if(oRec.id !== removed.id) tempRecipients[oRec.id] = {...oRec, progressNumber: oRec.progressNumber > removed.progressNumber ? oRec.progressNumber-1 : oRec.progressNumber}
        })
        setOrderedRecipients(tempRecipients)
    }

    const onDragEnd = (result) => {
        const orderedRecipientsList = reorderList(result, Object.values(orderedRecipients).sort((item1, item2) => item1.progressNumber - item2.progressNumber))
        if (!result.destination || result.destination.index === result.source.index) {
            return;
        }

        if(orderedRecipientsList) {
            let tempRecipients = {};
            orderedRecipientsList.forEach((rec, idx) => {
                tempRecipients[rec.id] = {...rec, progressNumber: idx +1}
            })
            setOrderedRecipients(tempRecipients)
        }
    }

    const AddRecipientButton = () => <Button onClick={newRecipient} label={t('recipients.addRecipient')} variant='blue' size={FULL}/>

    return(
        <div className='flex flex-col flex-grow w-full h-full'>
            <div className='flex justify-between items-center w-full m-2 border-b'>
                <Title>{t('recipients.title')}</Title>
                <div className='flex items-center'>
                    <div className='m-2'>
                        <SingleCheckbox label={t('recipients.selectOrder')} value={isOrdered} setValue={setIsOrdered}/>
                    </div>
                    <div className='m-2'>
                        <AddRecipientButton />
                    </div>

                </div>
            </div>

            {!isEmpty(orderedRecipients) ?
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="list">
                        {provided =>(
                            <div ref={provided.innerRef} {...provided.droppableProps} className='flex w-full flex-col h-full overflow-y-auto'>
                                {orderedRecipients && Object.values(orderedRecipients).sort((item1, item2) => item1.progressNumber - item2.progressNumber).map((rec, recIdx) => (
                                    <InputRecipientRole
                                        key={rec.id}
                                        recipient={rec}
                                        signatureTypesList={signatureTypesList}
                                        index={recIdx}
                                        setRecipient={(value) => setOrderedRecipients({...orderedRecipients, [rec.id]: value})}
                                        removeRecipient={removeRecipient}
                                        isOrdered={isOrdered}
                                        graphTemplatesOptions={graphTemplatesOptions}
                                        showGraphSignature={!isOrdered || rec?.progressNumber === 1}
                                    />
                                ))}
                                {provided.placeholder}
                            </div>
                        )
                        }
                    </Droppable>
                </DragDropContext>:
                <div className='flex flex-col items-center h-full w-full p-4'>
                    <img src={emptyStateIllustration} alt='Empty State' className='max-w-xl w-full' />
                    <h1 className='text-2xl leading-8 font-mediumtext-center '>{t('recipients.emptyMessage')}</h1>
                    <div className='py-4'>
                        <AddRecipientButton />
                    </div>
                </div>
            }
            <ModalSetRecipientsOrder open={open} setOpen={setOpen} setIsOrdered={setIsOrdered} />
        </div>
    )
}

export default FormInputRecipients;
