import RoundButtons from "../../atoms/Buttons/RoundButtons/RoundButtons";
import RadioGroup from "../../atoms/RadioGroups/RadioGroup";
import React, {useMemo} from "react";
import isEmpty from "lodash/isEmpty";
import {useTranslation} from "react-i18next";

const TabletSelector = ({title, devices, graphUser, selectedDevice, setSelectedDevice}) => {
    const {t} = useTranslation();

    const devicesOptions = useMemo(() => {
        return devices?.map(dev => {return {
            name: dev.label,
            value: dev.id,
            description: dev.currentStatus === 'IDLE' ?
                t('available') :
                dev.currentStatus === 'BUSY' ?
                    t('busy') :
                    t('unreachable')}})
    }, [devices])

    if(!isEmpty(devices))
        return (
            <div className='py-3'>
                <div className='flex justify-between'>
                    <div>
                        <h1 className='text-lg leading-7 font-medium text-gray-900'>{t(title || 'summary.graphometricSign')}</h1>
                        {graphUser?.name && <p className='text-sm leading-5 font-normal text-gray-500'>{t('summary.intendedFor',{user: graphUser?.name})}</p>}
                    </div>
                    <div className='flex items-center'>
                        <RoundButtons label={t('uncheck')} onClick={() => setSelectedDevice(null)}/>
                    </div>
                </div>
                <div className='my-4'>
                    <RadioGroup options={devicesOptions} value={selectedDevice ?? ''}
                                setValue={setSelectedDevice}/>
                </div>
            </div>
        )
}

export default TabletSelector;
