import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {
    getSendProcessDetailsThunk,
    nextStep,
    previousStep,
    selectWorkspace,
    sendProcessSelector,
    sendProcessThunk,
    setCurrentStep,
    setExpirationDate,
    setMessageBody,
    setMessageSubject,
    setProcessSelectedDevice,
    setRecipient,
    setRecipientHasGraphConsent,
    setRedirectLink,
    setReminderIntervalDays,
    setSenderReminderComplete,
    setAvoidLtArchive,
    setSendNoEmail,
    setSendNoEmailSigned,
    setStepsByProcessType
} from "../../../features/sendProcess/sendProcess.slice";
import {useNavigate, useParams} from "react-router-dom";
import SelectRecipients from "./SelectRecipients";
import Summary from "../ProcessSteps/Summary/Summary";
import EndSendProcess from "./EndSendProcess/EndSendProcess";
import isEmpty from "lodash/isEmpty";
import {librarySelector} from "../../../features/library/library.slice";
import SenderFormModal from "../../molecules/Modals/SenderFormModal/SenderFormModal";
import {documentBuilderSelector} from "../../../features/documentBuilder/documentBuilder.slice";
import {useTranslation} from "react-i18next";
import {displayErrorAlert, isValidHttpUrl} from "../../../utils/helper";
import {getDevices} from "../../../features/library/library.action";
import {getGraphConsentsThunk} from "../../../features/docModels/docModels.actions";
import {docModelsSelector} from "../../../features/docModels/docModels.slice";
import {getAvailableWorkspacesThunk} from "../../../features/userHome/userHome.actions";
import {userHomeSelector} from "../../../features/userHome/userHome.slice";
import {WORKSPACES} from "../../../utils/permissions";

const SendProcess = () => {
    const {t} = useTranslation()
    const {processId, step} = useParams();
    const dispatch = useDispatch();
    const {process, users: sendProcessUsers, steps, messageBody, messageSubject, reminderIntervalDays, expirationDate, selectedDevice, waitingSend, isDocumentSent, loadingRecipients, selectedWorkspaces} = useSelector(sendProcessSelector)
    const {devices} = useSelector(librarySelector)
    const {graphConsents} = useSelector(docModelsSelector)
    const {documentInfo} = useSelector(documentBuilderSelector)
    const {availableWorkspaces, userOrganizations, selectedTenantId} = useSelector(userHomeSelector)
    const permissions = userOrganizations?.[selectedTenantId]?.permissions ?? {}
    const navigate = useNavigate()

    const recipients = Object.values(sendProcessUsers).map(act => {
        const recipient ={
            id: act.id,
            signatureType: act.signatureType,
            redirectLink: act.redirectLink,
            isList: act.isList ?? false
        }
        if(act.isList) {
            recipient.listId = act.listId
            recipient.listName = act.listName
        } else {
            recipient.name= act?.user.givenName + ' ' + act.user.familyName
            recipient.email= act.user.email
        }
        return recipient
    })

    const [openModal, setOpenModal] = useState(false)
    const [answers, setAnswers] = useState({})
    const [senderFormIndex, setSenderFormIndex] = useState(0)

    const getAvailableWorkspaces = () => dispatch(getAvailableWorkspacesThunk())
    const setSelectedWorkspaces = (workspace) => dispatch(selectWorkspace(workspace))

    useEffect(() => {
        dispatch(setStepsByProcessType())
        dispatch(getDevices())
        dispatch(getSendProcessDetailsThunk({id:processId}))
        dispatch(getGraphConsentsThunk({onlyActive: true}))
        dispatch(setCurrentStep(step))
        getAvailableWorkspaces()
        if(step !== 'recipients') navigate(`/sendprocess/${processId}/recipients`, {replace: true})
    },[])

    const mapSetRecipient = ({id, role, userid, givenName='', familyName='', email='', signatureType, lastGraphConsentGivenDate, hasGraphConsent, graphConsentModelId, isList, listId, listName, users}) => {
        const rec = sendProcessUsers?.[id]
        if(rec) {
            const recipient = {...rec,
                id,
                role,
                signatureType,
                lastGraphConsentGivenDate,
                hasGraphConsent,
                graphConsentModelId,
                isList
            }
            if(isList) {
                recipient.listId = listId
                recipient.listName = listName
                recipient.users = users ?? []
                recipient.user = null
            }else{
                recipient.listId = null
                recipient.listName = null
                recipient.user = {id: userid, givenName, familyName, email}
            }
            dispatch(setRecipient(recipient))
        }
    }

    const goBackHome = () => {
        navigate('/document/models')
    }

    const mapRecipients = [{
        recipients: !isEmpty(sendProcessUsers) ?
            Object.values(sendProcessUsers).map(user => {
                return {
                    id: user?.id,
                    role: user?.role,
                    userid: user?.user?.id,
                    givenName: user?.user?.givenName,
                    familyName: user?.user?.familyName,
                    email: user?.user?.email,
                    signatureType: user?.signatureType,
                    peopleRole: user?.peopleRole,
                    redirectLink: user?.redirectLink,
                    hasGraphConsent: user?.hasGraphConsent,
                    graphConsentModelId: user?.graphConsentModelId,
                    lastGraphConsentGivenDate: user?.lastGraphConsentGivenDate,
                    emailDetails: user?.emailDetails,
                    progressNumber: user?.progressNumber,
                    actorType: user?.actorType,
                    isList: user?.isList,
                    listId: user?.listId,
                    listName: user?.listName,
                    users: user?.users ?? []
                }
            }) : []
    }]

    const senderForms = process?.forms?.filter(form => form.orderElementId === 'SENDER')

    const checkRedirectLinks = () => {
        let isRedirectLinkValid = true
        Object.values(recipients).forEach(rec => {
            if(rec?.redirectLink && !isValidHttpUrl(rec.redirectLink))
                isRedirectLinkValid = false
        })
        return isRedirectLinkValid;
    }

    const onClickSend = async () => {
        if(!checkRedirectLinks()) displayErrorAlert('summary.ErrorRedirectLink')
        else if(process?.hasSenderForms) setOpenModal(true)
        else await sendProcess()

    }

    const onClickPrevious = async () => {
        if(senderFormIndex > 0) setSenderFormIndex(senderFormIndex - 1)
        else setOpenModal(false)
    }

    const onConfirmSenderForm = async () => {
        if(senderFormIndex < senderForms.length - 1) setSenderFormIndex(senderFormIndex + 1)
        else await sendProcess()
    }

    const sendProcess = async () => {
        await dispatch(sendProcessThunk({senderFormData: answers})).unwrap()
        setOpenModal(false)
        dispatch(nextStep())
        navigate(`/sendprocess/${processId}/end`)
    }

    const listDevices = Object.values(sendProcessUsers).some(user => user.signatureType === 'GRAPH') ? devices : []

    const setNoEmailSent = (id, noEmail) => dispatch(setSendNoEmail({id, value: noEmail}))
    const setNoEmailSignedSent = (id, noEmailSigned) => dispatch(setSendNoEmailSigned({id, value: noEmailSigned}))

    const setHasGraphConsentToRecipient = (id, hasGraphConsent) => dispatch(setRecipientHasGraphConsent({id, hasGraphConsent}))
    const onSetSenderReminderComplete = (senderReminderComplete) => dispatch(setSenderReminderComplete(senderReminderComplete))
    const onSetAvoidLtArchive  = (onSetAvoidLtArchive) => dispatch(setAvoidLtArchive(onSetAvoidLtArchive))

    const buildUrl = (stepName) => `/sendprocess/${processId}/${stepName}`

    switch (step){
        case 'recipients':
            return(
                <SelectRecipients
                    process={mapRecipients}
                    setSingleRecipient={mapSetRecipient}
                    goBackHome={goBackHome}
                    setRecipients={() => {}}
                    next={() => {
                        dispatch(nextStep())
                        navigate(buildUrl('summary'))
                    }}
                    deleteRecipient={(recipient) => mapSetRecipient({...recipient, givenName: '', familyName: '', email: '', userid: ''})}
                    steps={steps}
                    loadingRecipients={loadingRecipients}
                    graphTemplatesOptions={graphConsents?.map(gc=>({value: gc.id, label: gc.name})) ?? []}
                    buildUrl={buildUrl}
                />
            )
        case 'summary':
            return(<>
                    <Summary
                        next={onClickSend}
                        previous={() => {
                            dispatch(previousStep())
                            navigate(buildUrl('recipients'))
                        }}
                        steps={steps}
                        recipients={Object.values(sendProcessUsers).map(act => {
                            return {
                                ...act,
                                name: !act?.isList ?
                                    act?.user?.givenName + ' ' + act?.user?.familyName :
                                    act.listName,
                                email: act?.user?.email,
                            }
                        })}
                        informative={process?.informedPaths.map(info => {
                            return {...info, url: null}
                        })}
                        dataCollections={process?.forms.map(form => {
                            return {...form, url: null}
                        })}
                        messageBody={messageBody}
                        messageSubject={messageSubject}
                        reminderInterval={reminderIntervalDays}
                        expirationDate={expirationDate}
                        setMessageBody={(value) => dispatch(setMessageBody(value))}
                        setMessageSubject={(value) => dispatch(setMessageSubject(value))}
                        setReminderInterval={(value) => dispatch(setReminderIntervalDays(value))}
                        setExpirationDate={(value) => dispatch(setExpirationDate(value))}
                        confirmLabel={t('summary.send')}
                        devices={listDevices}
                        selectedDevice={selectedDevice}
                        setSelectedDevice={(value) => dispatch(setProcessSelectedDevice(value))}
                        filename={documentInfo?.filename}
                        setRedirectLink={(id, redirectLink) => dispatch(setRedirectLink({id, redirectLink}))}
                        setHasGraphConsentToRecipient={setHasGraphConsentToRecipient}
                        setNoEmailSent={setNoEmailSent}
                        setNoEmailSignedSent={setNoEmailSignedSent}
                        availableWorkspaces={availableWorkspaces}
                        selectedWorkspaces={selectedWorkspaces}
                        setSelectedWorkspaces={setSelectedWorkspaces}
                        hasWorkspacesPermissions={permissions[WORKSPACES]}
                        senderReminderComplete={process.senderReminderComplete}
                        avoidLtArchive={process.avoidLtArchive}
                        editAvoidLtArchiveChoice={false}
                        setSenderReminderComplete={onSetSenderReminderComplete}
                        setAvoidLtArchive={onSetAvoidLtArchive}
                        buildUrl={buildUrl}
                    />
                    <SenderFormModal
                        open={openModal}
                        setOpen={(value) => {
                            setOpenModal(value)
                            setSenderFormIndex(0)
                        }}
                        title={t('send.senderFormTitle')}
                        items={senderForms?.[senderFormIndex]?.template?.items ?? []}
                        rules={senderForms?.[senderFormIndex]?.template?.rules ?? []}
                        answers={answers?.[senderForms[senderFormIndex]?.id] ?? []}
                        setAnswers={(value) => {
                            if (senderForms[senderFormIndex]?.id) setAnswers({
                                ...answers,
                                ...value, // Espandi le chiavi e i valori di `value` direttamente in `answers`
                                [senderForms[senderFormIndex]?.id]: value
                            })
                        }}
                        onSend={onConfirmSenderForm}
                        onClickPrevious={onClickPrevious}
                        stepHasNext={senderFormIndex < senderForms.length - 1}
                        stepHasPrevious={senderFormIndex > 0}
                        stepIndicator={senderForms.length > 1 ? `Step ${senderFormIndex + 1} di ${senderForms.length}` : ''}
                    />
                </>
            )
        case 'end':
            return(
                <EndSendProcess
                    isLoading={waitingSend}
                    isDocumentSent={isDocumentSent}
                />
            )
        default:
            return <div><p>DEFAULT</p></div>
    }
}

export default SendProcess;
