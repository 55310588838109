import React, {Fragment, useEffect, useState} from 'react'
import {Listbox, Transition} from '@headlessui/react'
import {CheckIcon, PlusCircleIcon, XMarkIcon} from '@heroicons/react/24/solid'
import {classNames} from "../../../../utils/helper";
import Badge from "../../../atoms/Badges/Badge/Badge";
import {useSelector} from "react-redux";
import {userHomeSelector} from "../../../../features/userHome/userHome.slice";
import {useTranslation} from "react-i18next";
import GenericQuestionModal from "../../Modals/GenericModal/GenericQuestionModal";

const SelectWithBadge = ({placeholder, label, selectedTags = [], setSelectedTags, disabled = false, insertTag=()=>{}, deleteTag=()=>{}, hasDelete = false}) => {
    const {t} = useTranslation()
    const [showOptions, setShowOptions] = useState(false)
    const [value, setValue] = useState('')
    const [newTagInserted, setNewTagInserted] = useState(null)

    const [tagToDelete, setTagToDelete] = useState(null)
    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false)


    const {tags} = useSelector(userHomeSelector)

    useEffect(() => {
        let newTag;
        if(newTagInserted) {
            newTag = tags.find((el) => el.name === newTagInserted)
            if(newTag) handleSelect(newTag)
        }
        setNewTagInserted(null)
    },[tags])

    function isSelected(value) {
        return selectedTags?.some((el) => el.id === value.id);
    }

    function handleSelect(value) {
        if (!isSelected(value)) {
            const selectedTagsUpdated = [
                ...selectedTags,
                tags.find((el) => el.id === value.id)
            ];
            setSelectedTags(selectedTagsUpdated);
        } else {
            handleDeselect(value);
        }
        setValue('')
    }

    function handleDeselect(value) {
        const selectedTagsUpdated = selectedTags.filter((el) => el.id !== value.id) ?? [];
        setSelectedTags(selectedTagsUpdated);
    }

    const addTag = (tag) => {
        setNewTagInserted(tag)
        insertTag(tag)
    }

    const askDeletionConfirm = (tag) => {
        setTagToDelete(tag);
        setDeleteConfirmOpen(true);
    }

    const disableAddTag = !value || tags?.some(tag => tag.name === value)

    return (
        <Listbox value={selectedTags}
                 onChange={(value) => handleSelect(value)}>
            {() => (
                <>
                    <Listbox.Label  className="block text-sm font-medium text-gray-700">{label}</Listbox.Label>
                    <div className="mt-1 relative">
                        <div className="relative w-full bg-white text-left cursor-default focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                            <input
                                type='text'
                                onFocus={() => setShowOptions(true)}
                                onBlur={() => setShowOptions(false)}
                                value={value} onChange={(e) => setValue(e.target.value)}
                                name="input"
                                className={classNames(disabled? 'bg-gray-200 text-gray-600' : '',"block w-full sm:text-sm border border-gray-300 rounded-md")}
                                placeholder={placeholder}
                                autoComplete="off"
                                disabled={disabled}
                            />
                            <div className='flex flex-wrap my-1 -mx-0.5'>
                                {selectedTags && selectedTags.map(tag => <div key={tag.id} className='m-0.5'><Badge label={tag.name} color='blue' /></div>)}
                            </div>
                        </div>

                        <Transition
                            show={showOptions}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Listbox.Options className="absolute z-10 w-full bg-white shadow-lg max-h-56 rounded-md text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                <div className={classNames(disableAddTag ? 'bg-gray-100 text-gray-500':'hover:bg-blue-600 hover:text-white text-gray-900 cursor-pointer',
                                    'flex border-b select-none relative py-2 pl-8 pr-4')} onClick={()=> addTag(value)}>
                                    <div className={'absolute inset-y-0 left-0 flex items-center pl-1.5'}>
                                        <PlusCircleIcon className='h-5 w-5' />
                                    </div>
                                    <p className='font-medium block truncate'>{t('addTag')}</p>
                                </div>
                                {tags?.filter(tag => tag.name.toLowerCase().includes(value.toLowerCase())).map((tag) => (
                                    <Listbox.Option
                                        key={tag.id}
                                        className='cursor-pointer select-none relative py-2 pl-8 pr-4 hover:bg-blue-600 hover:text-white text-gray-900'
                                        value={tag}
                                    >
                                        {() => {
                                            const selected = selectedTags && selectedTags.find(sp => sp.id === tag.id)
                                            return(
                                                <>
                                                    <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                                        {tag.name}
                                                    </span>
                                                    {selected ? (
                                                        <span className='absolute inset-y-0 left-0 flex items-center pl-1.5'>
                                                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                        </span>
                                                    ) : null}
                                                    {(hasDelete && !selected) ? (
                                                        <span className='absolute inset-y-0 right-2 flex items-center pl-1.5' onClick={(event) => {
                                                            event.stopPropagation();
                                                            askDeletionConfirm(tag)
                                                        }}>
                                                            <XMarkIcon className="text-red-600 h-5 w-5" aria-hidden="true"/>
                                                        </span>
                                                    ) : null}
                                                </>
                                            )}}
                                    </Listbox.Option>
                                ))}
                            </Listbox.Options>
                        </Transition>
                    </div>
                    <GenericQuestionModal title={t('tags.deleteConfirm')}
                                          customMessage={t('tags.deleteConfirmMessage', {name: tagToDelete?.name ?? ''})}
                                          recordName={tagToDelete?.name ?? ''} open={deleteConfirmOpen} setOpen={setDeleteConfirmOpen}  onDelete={() => deleteTag(tagToDelete)}/>
                </>
            )}
        </Listbox>
    )
}

export default SelectWithBadge;
