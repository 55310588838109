import Title from "../../../atoms/Title/Title";
import ActionButton from "../../../atoms/Buttons/ActionButtons/ActionButton";
import PageWithStepsHeaderAndFooter from "../../Templates/PageWithStepsHeaderAndFooter/PageWithStepsHeaderAndFooter";
import React, {useEffect, useRef, useState} from "react";
import {DocumentIcon} from "@heroicons/react/24/outline";
import {XMarkIcon} from "@heroicons/react/24/solid";
import LoadingSpinner from "../../../atoms/LoadingSpinner/LoadingSpinner";
import SelectSignatureType from "../../../atoms/Select/SelectSignatureType/SelectSignatureType";
import {useTranslation} from "react-i18next";
import RadioGroup from "../../../atoms/RadioGroups/RadioGroup";

const DocumentUpload = ({signatureTypesList, goToDocumentPage, loading, steps, selfSignInit= () => {}, buildUrl=()=>{}, file,setFile, signatureType, setSignatureType, signatureFormat, setSignatureFormat}) => {
    const hiddenFileInput = useRef();
    const {t} = useTranslation()
    const [forceCades, setForceCades] = useState(false)

    useEffect(() => {
        selfSignInit()
    },[])

    const handleFileUploadClickButton = () => {
        hiddenFileInput.current.click();
    }

    return(
        <PageWithStepsHeaderAndFooter background={true} footer={true} next={goToDocumentPage} disableConfirm={!file || !signatureType} steps={steps} buildUrl={buildUrl}>
            <div className='flex flex-col justify-center max-w-md h-full'>
                <div className='flex flex-col items-center pb-8'>
                    <Title>{t('documentUpload.title')}</Title>
                    <h3 className="text-lg text-gray-500 leading-6 text-justify">{t('documentUpload.message')}</h3>
                </div>
                {loading ? <LoadingSpinner />:
                    <div className='flex flex-col'>
                        <SelectSignatureType label={t('recipients.signType')} value={signatureType} placeholder={t('select')}
                                             setValue={setSignatureType} options={signatureTypesList}/>
                        <input type="file" className='hidden' ref={hiddenFileInput}
                               onChange={(e) => {
                                   const aFile = e.target.files[0];
                                   setFile(aFile)
                                   if (aFile?.name) {
                                       const fileName = aFile.name.toLowerCase();
                                       if (fileName.endsWith('.doc') || fileName.endsWith('.txt') || fileName.endsWith('.xls') || fileName.endsWith('.xlsx')
                                           || fileName.endsWith('.ods') || fileName.endsWith('.jpg') || fileName.endsWith('.jpeg')
                                           || fileName.endsWith('.png') || fileName.endsWith('.bmp') || fileName.endsWith('.tiff') || fileName.endsWith('.zip')) {
                                           setSignatureFormat('CADES');
                                           setForceCades(true);
                                       } else {
                                           setForceCades(false);
                                       }
                                   }
                               }} accept={signatureType === 'FEQ' ? '.pdf, .doc, .docx, .odt, .txt, .xls, .xlsx, .ods, .jpg, .jpeg, .png, .bmp, .tiff, .zip' : '.docx,.odt,.pdf'}/>
                        {!file ? <ActionButton title={t('documentUpload.uploadFile')} text={t('documentUpload.pcUpload')} premium={true}
                                               onClick={handleFileUploadClickButton}/> :
                            <>
                                <div
                                    className='flex flex-grow items-center justify-between mt-3 p-6 rounded-lg max-w-md text-base font-medium bg-white border shadow'>
                                    <DocumentIcon className='flex h-full max-h-10 mr-5'/>
                                    <div className='flex flex-1 flex-grow'>{file?.name}</div>
                                    <XMarkIcon className='flex text-red-600 h-full max-h-6 cursor-pointer' onClick={() => {
                                        hiddenFileInput.current.value = ''
                                        setFile(null);
                                    }}/>
                                </div>
                                {(signatureType === 'FEQ' && !forceCades) ?
                                    <div className='flex flex-col mt-5'>
                                        {t('signature.chooseFeqType')}:
                                        <RadioGroup
                                            disabled={false}
                                            options={[
                                            {name: t('signature.padesOption'), value: 'PADES'},
                                            {name: t('signature.cadesOption'), value: 'CADES'},
                                        ]} value={signatureFormat ?? 'PADES'} setValue={setSignatureFormat}/>
                                    </div> :
                                    <>{signatureType === 'FEQ' && forceCades && <div className='flex flex-col items-end'>
                                        {t('signature.willUseCades')}
                                    </div>}
                                </>}
                            </>}
                    </div>
                }
            </div>
        </PageWithStepsHeaderAndFooter>
    )

}
export default DocumentUpload;
