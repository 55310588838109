const BigLabeledButton = ({ label, onClick }) => (
    <button
        type="button"
        className="inline-flex items-center justify-center px-6 py-3 space-x-3 border border-gray-300
         shadow-sm text-base font-medium rounded-md text-gray-700 bg-white w-full text-md
         hover:bg-gray-50 focus:outline-none focus:bg-blue-600 focus:text-white
         max-w-md"
        onClick={onClick}
    >
        <h1 className='capitalize'>{label}</h1>
    </button>
);

export default BigLabeledButton;
