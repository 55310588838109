import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import FeedsTable from "../../../molecules/Tables/FeedsTable/FeedsTable";
import {DocumentTextIcon} from "@heroicons/react/24/solid";
import Paginator from "../../../atoms/Paginator/Paginator";
import {EllipsisHorizontalIcon} from "@heroicons/react/24/outline";
import DettaglioEvento from "../../DettaglioEvento/DettaglioEvento";
import DettaglioDatiImmessiEvento from "../../DettaglioDatiImmessiEvento/DettaglioDatiImmessiEvento";
import {
    getPersonEvents,
    getSubmittedFormByEventIdThunk,
    peopleSelector
} from "../../../../features/people/people.slice";
import {downloadEventPDF} from "../../../../features/library/library.action";
import {toLocaleString} from "../../../../utils/helper";

const PersonDetailEventi = () => {
    const dispatch = useDispatch();

    const {personEvents, personEventsCount, currentPerson} = useSelector(peopleSelector)
    const initialFilters = {pagination: {currentPage: 1, offset: 0, limit:10}, txtFilter: ''}
    const [filters, setFilters] = useState(initialFilters);
    const [mode, setMode] = useState('events');
    const [currentEvento, setCurrentEvento] = useState(null);
    const [currentEventId, setCurrentEventId] = useState(null);

    const updatePersonEvents = (currentPerson, filters) => dispatch(getPersonEvents({
        confirmoIdentityId: currentPerson?.confirmoIdentityID,
        offset: filters.pagination.offset,
        limit: filters.pagination.limit,
        txtFilter: filters.txtFilter,
        id: currentPerson?.id
    }));

    useEffect(() => {
        let updateDataTimeout = setTimeout(() => updatePersonEvents(currentPerson, filters), 300);
        return () => clearTimeout(updateDataTimeout);
    },[filters])

    const downloadPDF = async (row) => {
        dispatch(downloadEventPDF({url: row?.downloadPdfUrl}));
    }

    const showDettaglioEvento = async (row) => {
        const eventDetail = personEvents.find(ev => ev.id === row.id);
        setCurrentEvento(eventDetail);
        setMode('detail');
    }

    const showDatiEvento = async (row) => {
        dispatch(getSubmittedFormByEventIdThunk({eventId: row.eventid}))
        setCurrentEventId(row.id);
        setMode('data');
    }

    const showEventi = async () => {
        setCurrentEvento(null);
        setMode('events');
    }


    var mapDataToFeeds = function(data=[]) {
        return data?.map(d => ({
            id: d.id,
            eventFlowId: d.eventFlowId,
            content: d.title ?? 'Document',
            date: toLocaleString(d.signatureDate),
            datetime: toLocaleString(d.createdAt),
            downloadPdfUrl: d.downloadPdfUrl,
            icon: DocumentTextIcon,
            iconBackground: 'bg-gray-400',
            eventid: d.eventid
        }))

    }
    const actionColumn = {
        type: 'dropdown',
        buttonLabel: '',
        icon: <EllipsisHorizontalIcon className='w-4 h-4' />,
        dropdownOptions: [{
            label: 'Dettaglio evento',
            onclick: (d) => showDettaglioEvento(d),
        }, {
            label: 'Dati immessi',
            onclick: (d) => showDatiEvento(d),
        }, {
            label: 'Scarica documento firmato',
            onclick: (d) => downloadPDF(d),
        }]
    }



    return(
        <div className="h-full" aria-hidden="true" >
            {(mode === 'events') ?
                <div>
                    <FeedsTable
                        data={mapDataToFeeds(personEvents)}
                        actionColumn={actionColumn}
                        txtFilter={filters.txtFilter}
                        setTxtfilter={(value) => setFilters({...filters, txtFilter: value})}
                    />
                    <div className='flex flex-col flex-1'>
                        <Paginator pageSize={10} currentPage={filters.pagination.currentPage} totalResults={personEventsCount} onClick={(value) => setFilters({...filters, pagination: value})}/>
                    </div>
                </div> :
                (mode === 'detail') ?
                    <DettaglioEvento data={currentEvento} closeAction={showEventi} /> :
                    <DettaglioDatiImmessiEvento data={personEvents?.find(e => e.id === currentEventId)} closeAction={showEventi} />
            }

        </div>
    )
}

export default PersonDetailEventi;
