import {CheckIcon} from '@heroicons/react/24/solid'
import {ChevronRightIcon} from "@heroicons/react/24/outline";
import {Link, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";

const StepsPanels = ({steps = [], buildUrl=(url)=>{}}) => {
    const {t} = useTranslation()
    const {step} = useParams();
    const currentStep=step

    return (
        <nav aria-label="Progress">
            <ol className="divide-gray-300 flex divide-y-0">
                {steps.map((step,stepIdx) => (
                    <li key={step.name} className="relative md:flex-1 md:flex m-0">
                        {(step.status === 'disabled' || step.disabled === true) ? (
                            <div className='hidden' />
                        ): step.href.split('/').pop() === currentStep ? (
                            <div  className="px-2 py-4 flex items-center text-sm font-medium" aria-current="step">
                                <span className="flex-shrink-0 w-7 h-7 flex items-center justify-center border-2 border-white rounded-full">
                                    <span className="text-white cursor-default">{step.id}</span>
                                </span>
                                <span className="ml-2 text-sm font-medium text-white truncate cursor-default">{t(step.name)}</span>
                            </div>
                        ) : step.status === 'complete' ? (
                            <Link to={buildUrl(step.href)} className="group flex items-center w-full">
                                <span className="px-2 py-4 flex items-center text-sm font-medium">
                                    <span className="flex-shrink-0 w-7 h-7 flex items-center justify-center bg-white rounded-full group-hover:bg-opacity-80">
                                        <CheckIcon className="w-5 h-5 text-green-600 group-hover:text-opacity-80" aria-hidden="true" />
                                    </span>
                                    <span className="ml-2 text-sm font-medium text-white group-hover:text-opacity-80 truncate">{t(step.name)}</span>
                                </span>
                            </Link>
                        ) : (
                            <div className="group flex items-center">
                                <span className="px-2 py-4 flex items-center text-sm font-medium">
                                    <span className="flex-shrink-0 w-7 h-7 flex items-center justify-center border-2 border-white border-opacity-25 rounded-full group-hover:border-opacity-50">
                                        <span className="text-white text-opacity-25 group-hover:text-opacity-50 cursor-default">{step.id}</span>
                                    </span>
                                    <span className="ml-2 text-sm font-medium text-white text-opacity-25 group-hover:text-opacity-50 truncate cursor-default">{t(step.name)}</span>
                                </span>
                            </div>
                        )}
                        {!(step.status === 'disabled' || step.disabled === true) && steps.slice(stepIdx +1).some(step => (!(step.status === 'disabled') || !step.disabled)) ? (
                            <>
                                <div className="hidden md:flex md:w-5 text-white" aria-hidden="true">
                                    <ChevronRightIcon />
                                </div>
                            </>
                        ) : null}
                    </li>
                ))}
            </ol>
        </nav>
    )
}
export default StepsPanels;
