import {XMarkIcon} from '@heroicons/react/24/outline'
import StepsPanels from "../../StepsPanel/StepsPanels";
import ProfileDropdown from "../ProfileDropdown/ProfileDropdown";
import {useNavigate} from "react-router-dom";

const StepHeader = ({steps, closeAction = null, buildUrl=()=>{}}) => {
    const navigate = useNavigate()

    const onClose = () => {
        if(closeAction)
            closeAction()
        else
            navigate('/home')
    }

    return (
        <>
            <div className="mx-auto flex justify-start px-4 sm:px-6 lg:px-8 bg-blue-600">
                <div className="flex resize-none justify-between items-center flex-grow h-16">
                    <div className="flex flex-shrink-0 h-10 w-10 items-center justify-center bg-white bg-opacity-20 rounded-full">
                        <XMarkIcon className="block h-5 w-5 text-white cursor-pointer" aria-hidden="true" onClick={onClose}/>
                    </div>
                    <div>
                        <StepsPanels steps={steps} buildUrl={buildUrl} />
                    </div>
                    <div className='flex flex-shrink-0'>
                        <ProfileDropdown />
                    </div>
                </div>
            </div>
        </>
    )
}
export default StepHeader;
