import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {checkAccessLinkThunk, registerUserWithAccessLinkThunk} from "../../../features/userHome/userHome.actions";
import React, {useEffect, useState} from "react";
import {userHomeSelector} from "../../../features/userHome/userHome.slice";
import LoadingSpinner from "../../atoms/LoadingSpinner/LoadingSpinner";
import SimplePageWithBackground from "../Templates/SimplePageWithBackground/SimplePageWithBackground";
import SuccessPage from "../SuccessPage/SuccessPage";
import RegistrationForm from "../../organisms/RegistrationForm/RegistrationForm";
import confirmoLogo from "../../../assets/logos/confirmo-logo.svg";
import ErrorMessage from "../../atoms/ErrorMessage/ErrorMessage";

const Invitations = () => {
    const dispatch = useDispatch();
    const {t} = useTranslation()
    const {accessLink} = useParams()
    const {needRegistration, loadingInvitation, errorInvitation, errorMessageInvitation, invitationEmail} = useSelector(userHomeSelector)

    const [formValues, setFormValues] = useState({
        givenName: '',
        familyName: '',
        email: '',
        newPassword: '',
        confirmPassword: '',
        acceptTerms: false,
        acceptMarketing: false
    })

    useEffect(() => setFormValues({...formValues, email: invitationEmail}), [invitationEmail])

    useEffect(() => {
        if(accessLink)
            dispatch(checkAccessLinkThunk({accessLink}))
    },[])

    const registerUserWithAccessLink = () => {
        dispatch(registerUserWithAccessLinkThunk({accessLink, signupData: formValues}))
    }

    if(loadingInvitation)
        return <SimplePageWithBackground>
            <LoadingSpinner />
        </SimplePageWithBackground>
    else if(needRegistration)
        return <SimplePageWithBackground>
            <div className='flex flex-col'>
                <div className='flex items-center mb-3 justify-center'>
                    <h1 className='text-2xl leading-8 font-medium pr-1'>{t('invitations.register')}</h1>
                    <img src={confirmoLogo} alt='confirmo' className='h-8 mb-1 ml-1' />
                </div>
                <div className='my-2'>
                    {errorInvitation ?
                        <ErrorMessage>{errorMessageInvitation}</ErrorMessage>
                        : <></>}
                </div>
                <RegistrationForm
                    emailDisabled={true}
                    onRegister={registerUserWithAccessLink}
                    formValues={formValues}
                    setFormValues={setFormValues} />
            </div>
        </SimplePageWithBackground>
    else return <SuccessPage title={t('invitations.confirm')} />
}

export default Invitations;
