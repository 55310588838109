const DatePicker = ({startDate, setStartDate, endDate, setEndDate}) => {
    return(<div className="flex items-center justify-center mt-1">
            <label htmlFor="start-date" className="sr-only">
                Start Date
            </label>
            <input
                type="date"
                name="start-date"
                id="start-date"
                className="relative block w-full rounded-none rounded-l-md border-0 bg-transparent py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                placeholder="Start date"
                value={startDate ?? ''}
                onChange={(e) => setStartDate(e.target.value)}
            />
            <label htmlFor="end-date" className="sr-only">
                End Date
            </label>
            <input
                type="date"
                name="end-date"
                id="end-date"
                className="relative block w-full rounded-none rounded-r-md border-0 bg-transparent py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                placeholder="End date"
                value={endDate ?? ''}
                onChange={(e) => setEndDate(e.target.value)}
            />
    </div>)
}

export default DatePicker;
