import React from "react";
import Draggable from "./Draggable";
import DraggableBadge from "../atoms/Badges/DraggableBadges/DraggableBadge";
import fonts from '../../assets/Lists/fonts-list.json'
const DraggableBlocks = ({ setDragData, blocks=[], color = 'blue' }) => {
  const onDragStart = (dragData) => setDragData(dragData);
  const onDragEnd = () => setDragData(null);

  return (
      <div className="flex flex-wrap -mx-1">
        {blocks.map((b) => (
            <div className='m-1' key={b.fieldId}>
              <Draggable
                  dragObject={b}
                  onDragStart={(dragData) => onDragStart(dragData)}
                  onDragEnd={() => onDragEnd()}
              >
                <DraggableBadge label={b.label} color={color} fontFamily={fonts?.[0]?.value} />
              </Draggable>
            </div>
        ))}
      </div>
  );
};

export default DraggableBlocks;
