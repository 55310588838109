import {Dialog, Transition} from "@headlessui/react";
import React, {Fragment, useEffect, useRef, useState} from "react";
import SenderFormItem from "./SenderFormItem";
import Button from "../../../atoms/Buttons/Button/Button";
import {checkDisableRules, validate} from "./senderFormModal_utils";
import {useTranslation} from "react-i18next";
import {ArrowPathIcon} from "@heroicons/react/24/solid";

const SenderFormModal = ({open, setOpen, title='', items= [], rules = [], onSend, onClickPrevious, answers, setAnswers, stepHasPrevious = false, stepHasNext = false, stepIndicator = ''}) => {
    const modalRef = useRef(null)
    const [disabled, setDisabled] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const [isWaitingSend, setIsWaitingSend] = useState(false)
    const {t} = useTranslation()

    useEffect(() => {
        setAnswers({})
    },[open])

    useEffect(() => {
        setIsValid(validate(items, disabled, answers))
    },[disabled])

    useEffect(() => {
        setDisabled(checkDisableRules(rules, items, answers))
        setIsValid(validate(items, disabled, answers))
    },[answers])

    const onClickSend = async () => {
        setIsWaitingSend(true)
        try {
            await onSend()
        }finally {
            setIsWaitingSend(false)
        }
    }
    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" static className="fixed z-10 inset-0 overflow-y-auto" open={open} onClose={() => {}} initialFocus={modalRef}>
                <div className="flex items-center justify-center min-h-screen text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-700 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    {/* Elemento invisibile per centrare il contenuto */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                &#8203;
            </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className="inline-block align-bottom bg-white rounded-lg text-left shadow-xl transform transition-all w-full max-w-5xl h-[90vh] mb-8">
                            <div className="bg-white h-full flex flex-col">
                                {/* Header con titolo, step e icona di chiusura */}
                                <div className="flex justify-between items-center bg-gray-100 px-6 py-4 border-b">
                                    <div>
                                        <Dialog.Title as="h3" className="text-xl font-bold text-gray-900">
                                            {title}
                                        </Dialog.Title>
                                        {/* Indicatore dello step */}
                                        {stepIndicator && (
                                            <p className="text-sm text-gray-600 mt-3">
                                                {stepIndicator}
                                            </p>
                                        )}
                                    </div>
                                    <button
                                        type="button"
                                        className="text-gray-500 hover:text-gray-700"
                                        onClick={() => setOpen(false)}
                                    >
                                        <span className="sr-only">Chiudi</span>
                                        &times; {/* Icona di chiusura a "X" */}
                                    </button>
                                </div>

                                {/* Contenuto della form */}
                                <div className="flex-grow overflow-y-auto px-6 py-5" ref={modalRef}>
                                    <div className='grid grid-cols-1 gap-4'>
                                        {items.map(item => (
                                            <SenderFormItem key={item.name} item={item} values={answers[item.name]}
                                                            setValues={(value) => setAnswers({
                                                                ...answers,
                                                                [item.name]: value
                                                            })}
                                                            disabledField={disabled?.[item.name] ?? false}
                                                            hiddenField={disabled?.[item.name] ?? false}
                                            />
                                        ))}
                                    </div>
                                </div>

                                {/* Footer con i bottoni */}
                                <div className="bg-gray-50 px-4 py-3 space-x-4 flex justify-end">
                                    <Button
                                        variant="white"
                                        label={stepHasPrevious ? t('previous') : t('close')}
                                        onClick={onClickPrevious}
                                    />
                                    <Button
                                        variant="green"
                                        disabled={!isValid || isWaitingSend}
                                        onClick={onClickSend}
                                        label={stepHasNext ? t('next') : (isWaitingSend ?
                                            <div className="flex items-center space-x-2">
                                                <ArrowPathIcon className="animate-spin h-5 w-5 text-white"/>
                                                <span>{t('summary.sendingDocument')}</span>
                                            </div> : t('summary.send'))}
                                    />
                                </div>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>


    )
}

export default SenderFormModal;
