import {useTranslation} from "react-i18next";
import ModalGrayFooter from "../Templates/ModalGrayFooter";
import {Dialog} from "@headlessui/react";
import React from "react";

const ModalConfirmLibraryCopy = ({open, setOpen, onConfirm}) => {
    const {t} = useTranslation()
    return(
        <ModalGrayFooter open={open} setOpen={setOpen} onConfirm={onConfirm} confirmLabel={t('confirm')} >
            <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                {t('library.copyMessage')}
            </Dialog.Title>
        </ModalGrayFooter>
    )
}

export default ModalConfirmLibraryCopy;
