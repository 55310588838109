import {
    AdjustmentsHorizontalIcon,
    ArrowDownTrayIcon,
    ArrowUpTrayIcon,
    CheckBadgeIcon,
    CheckIcon,
    ChevronLeftIcon,
    ClockIcon,
    DeviceTabletIcon, DocumentCheckIcon,
    DocumentTextIcon,
    EllipsisHorizontalIcon,
    EnvelopeIcon,
    ExclamationCircleIcon,
    EyeIcon,
    EyeSlashIcon,
    InformationCircleIcon,
    MinusCircleIcon,
    MinusIcon,
    PencilIcon,
    PencilSquareIcon,
    PlusCircleIcon,
    Square2StackIcon,
    StarIcon as StarEmpty,
    TrashIcon,
    XCircleIcon,
    XMarkIcon
} from "@heroicons/react/24/outline";
import React from "react";
import {classNames} from "../../../utils/helper";
import {
    ArrowPathIcon,
    BarsArrowDownIcon, BoltIcon,
    CheckCircleIcon,
    PlusIcon,
    StarIcon as StarFilledIcon,
    UserCircleIcon
} from "@heroicons/react/24/solid";

const colors = {
    'yellow': 'text-yellow-400',
    'green': 'text-green-400',
    'blue': 'text-blue-400',
    'red': 'text-red-500',
    'white': 'text-white',
    'gray': 'text-gray-500',
}

const sizes = {
    '4' : 'w-4 h-4',
    '6' : 'w-6 h-6',
    '5' : 'w-5 h-5',
    '10' : 'w-10 h-10',
}

const Icon = ({type, onClick, color, size = '6', title}) => {
    const role = 'icon'
    const cssClass = classNames(
        (color && colors?.[color]) ? colors[color] : '',
        onClick ? 'cursor-pointer' : '',
        sizes[size]
    )
    switch(type){
        case 'view':
            return <DocumentTextIcon className={cssClass} onClick={onClick} data-testid={role} title={title}/>
        case 'details':
            return <InformationCircleIcon className={cssClass} onClick={onClick} data-testid={role} title={title}/>
        case 'sign':
            return <PencilSquareIcon className={cssClass} onClick={onClick} data-testid={role} title={title}/>
        case 'modify':
            return <PencilIcon className={cssClass} onClick={onClick} data-testid={role} title={title}/>
        case 'delete':
            return <TrashIcon className={cssClass} onClick={onClick} data-testid={role} title={title}/>
        case 'bolt':
            return <BoltIcon className={cssClass} onClick={onClick} data-testid={role} title={title}/>
        case 'tablet':
            return <DeviceTabletIcon className={cssClass} onClick={onClick} data-testid={role} title={title}/>
        case 'check':
            return <CheckIcon className={cssClass} onClick={onClick} data-testid={role} title={title}/>
        case 'x-mark':
            return <XMarkIcon className={cssClass} onClick={onClick} data-testid={role} title={title}/>
        case 'clock':
            return <ClockIcon className={cssClass} onClick={onClick} data-testid={role} title={title}/>
        case 'star-filled':
            return <StarFilledIcon className={cssClass} onClick={onClick} data-testid={role} title={title}/>
        case 'document-check':
            return <DocumentCheckIcon className={cssClass} onClick={onClick} data-testid={role} title={title}/>
        case 'star-empty':
            return <StarEmpty className={cssClass} onClick={onClick} data-testid={role} title={title}/>
        case 'back':
            return <ChevronLeftIcon className={cssClass} onClick={onClick} data-testid={role} title={title}/>
        case 'duplicate':
            return <Square2StackIcon className={cssClass} onClick={onClick} data-testid={role} title={title}/>
        case 'plus':
            return <PlusIcon className={cssClass} onClick={onClick} data-testid={role} title={title}/>
        case 'minus':
            return <MinusIcon className={cssClass} onClick={onClick} data-testid={role} title={title}/>
        case 'plus-circle':
            return <PlusCircleIcon className={cssClass} onClick={onClick} data-testid={role} title={title}/>
        case 'minus-circle':
            return <MinusCircleIcon className={cssClass} onClick={onClick} data-testid={role} title={title}/>
        case 'exclamation':
            return <ExclamationCircleIcon className={cssClass} onClick={onClick} data-testid={role} title={title}/>
        case 'download':
            return <ArrowDownTrayIcon className={cssClass} onClick={onClick} data-testid={role} title={title}/>
        case 'sendEmail':
            return <EnvelopeIcon className={cssClass} onClick={onClick} data-testid={role} title={title}/>
        case 'barsArrowDown':
            return <BarsArrowDownIcon className={cssClass} onClick={onClick} data-testid={role} title={title}/>
        case 'checkCirce':
            return <CheckCircleIcon className={cssClass} onClick={onClick} data-testid={role} title={title}/>
        case 'x-circle':
            return <XCircleIcon className={cssClass} onClick={onClick} data-testid={role} title={title}/>
        case 'check-badge':
            return <CheckBadgeIcon className={cssClass} onClick={onClick} data-testid={role} title={title}/>
        case 'eye':
            return <EyeIcon className={cssClass} onClick={onClick} data-testid={role} title={title}/>
        case 'eye-slash':
            return <EyeSlashIcon className={cssClass} onClick={onClick} data-testid={role} title={title}/>
        case 'minus-small':
            return <MinusIcon className={cssClass} onClick={onClick} data-testid={role} title={title}/>
        case 'userCircle':
            return <UserCircleIcon className={cssClass} onClick={onClick} data-testid={role} title={title}/>
        case 'upload':
            return <ArrowUpTrayIcon className={cssClass} onClick={onClick} data-testid={role} title={title}/>
        case 'restore':
            return <ArrowPathIcon className={cssClass} onClick={onClick} data-testid={role} title={title}/>
        case 'adjustments':
            return <AdjustmentsHorizontalIcon className={cssClass} onClick={onClick} data-testid={role} title={title}/>
        default:
            return <EllipsisHorizontalIcon className={cssClass} onClick={onClick} data-testid={role} title={title}/>
    }
}

export default Icon
