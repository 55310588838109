import {classNames} from "../../../../utils/helper";
import {useTranslation} from "react-i18next";
import isNumber from "lodash/isNumber";
import LordIcon from "../../../atoms/LordIcon/LordIcon";

const envData = {
    water: { bgColor: 'bg-confirmo-dark-blue'}, //Ocean
    tree: { bgColor: 'bg-confirmo-green'}, //tree
    air: { bgColor: 'bg-confirmo-blue'}, //two clouds
    waste: { bgColor: 'bg-confirmo-red'}, //recycling
}

const EnvironmentStats = ({ecoStats={}}) => {
    const { t } = useTranslation();
    return (
        <div className='flex flex-grow flex-col'>
            <h2 className="text-gray-500 text-xs font-medium uppercase tracking-wide">{t('env.headingMessage')}</h2>
            <ul className="mt-3 grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-2 lg:grid-cols-4">
                {Object.keys(ecoStats)?.map(key =>
                    <li key={key} className="col-span-1 flex flex-grow shadow-sm rounded-md">
                        <div
                            className={classNames(envData[key].bgColor,
                                'flex-shrink-0 flex items-center justify-center text-white text-sm font-medium rounded-l-md border-t border-l border-b border-gray-200'
                            )}
                        >
                            <LordIcon type={key} />
                        </div>
                        <div className="flex-1 flex items-center justify-center border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate">
                            <div className="flex-1 px-4 py-2 text-sm truncate">
                                <p className="text-gray-900 font-medium">
                                    {isNumber(ecoStats[key].data) ? (ecoStats[key].data).toFixed(2) : ecoStats[key].data}
                                </p>
                                <p className="text-gray-500">{ecoStats[key].measure}</p>
                            </div>
                        </div>
                    </li>
                )}
            </ul>
        </div>
    )
}

export default EnvironmentStats
