const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

export const reorderList = (result, listToBeReordered) => {
    if (!result.destination) {
        return;
    }

    if (result.destination.index === result.source.index) {
        return;
    }

    const orderedList = reorder(
        listToBeReordered,
        result.source.index,
        result.destination.index
    );

    return orderedList;
}
