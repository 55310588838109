import React from "react";
import Devices from "../../pages/Settings/Sections/Devices/Devices";
import Signatures from "../../pages/Settings/Sections/Signatures/Signatures";
import PersonalData from "../../pages/Settings/Sections/PersonalData/PersonalData";
import ManageUsers from "../../pages/Settings/Sections/ManageUsers/ManageUsers";
import Workspaces from "../../pages/Settings/Sections/Workspaces/Workspaces";
import GraphConsentsList from "../../pages/Settings/Sections/GraphConsentsList/GraphConsentsList";
import Manage2fa from "../../pages/Settings/Sections/2fa/Manage2fa";
import SignatureBalance from "../../pages/Settings/Sections/SignatureBalance/SignatureBalance";

const SettingsBody = ({section}) => {
    switch (section) {
        case 'Privacy':
            return <h1>Sezione Privacy</h1>
        case 'Signature':
            return <Signatures />
        case 'SignatureBalance':
            return <SignatureBalance/>
        case 'Billing':
            return <h1>Piano e fatturazione</h1>
        case 'Devices':
            return <Devices/>
        case 'PersonalData':
            return <PersonalData />
        case 'ManageUser':
            return <ManageUsers />
        case 'workspaces':
            return <Workspaces />
        case 'graphconsent':
            return <GraphConsentsList />
        case 'Manage2fa':
            return <Manage2fa />
        default:
            return <h1>Sezione non disponibile</h1>
    }
}

export default SettingsBody;
