import {v4 as uuid} from 'uuid'
import {classNames, isValidHttpUrl} from "../../../../utils/helper";
import Icon from "../../Icon/Icon";
import {useEffect, useState} from "react";

const InputUrl = ({label, placeholder, value = '', setValue, autofocus = false, onBlur = () => {}, disabled=false, autoComplete='on', min, max}) => {
    const id = uuid();
    const [isValidInput, setIsValidInput] = useState(true)

    useEffect(() => {
        const isValid = !value || isValidHttpUrl(value)
        setIsValidInput(isValid)
    },[value])

    return (
        <div className="my-1">
            <label htmlFor={`input-${id}`} className="block text-sm font-medium text-gray-700">
                {label}
            </label>
            <div className="relative mt-1 rounded-md shadow-sm">
                <input
                    type='url'
                    name={`input-${id}`}
                    id={`input-${id}`}
                    value={value}
                    onChange={(e)=> setValue(e.target.value)}
                    className={classNames(disabled && 'bg-gray-200 text-gray-600',
                        isValidInput ? 'border-gray-300 focus:ring-blue-500 focus:border-blue-500' : 'border-red-300 focus:border-red-500 focus:ring-red-500',
                        "shadow-sm block w-full sm:text-sm  rounded-md")}
                    placeholder={placeholder}
                    autoFocus={autofocus}
                    onBlur={onBlur}
                    disabled={disabled}
                    autoComplete={autoComplete}
                    min={min}
                    max={max}
                />
                {!isValidInput && <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
                      aria-hidden="true">
                    <Icon type='exclamation' size={5} color='red'/>
                </div>}
            </div>
        </div>
    )
}

export default InputUrl;
