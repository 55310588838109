import React, {useCallback} from 'react'
import {useDropzone} from 'react-dropzone'
import Icon from "../../atoms/Icon/Icon";
import {useTranslation} from "react-i18next";

const FileUploadArea = ({onUpload, accept={}}) => {
    const {t} = useTranslation()
    const onDrop = useCallback(acceptedFiles => {
        onUpload(acceptedFiles)
    }, [onUpload])
    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop, accept})

    return (
        <div {...getRootProps()} >
            <input {...getInputProps()} />
            {
                    <div className='flex flex-col items-center justify-center bg-blue-100 rounded-2xl py-10 cursor-pointer space-y-4'>
                        <Icon type='upload' size='10'/>
                        <p className='text-center'>{isDragActive ? t('library.importexport.dropFiles') : t('library.importexport.clickOrDropFiles') }</p>
                    </div>

            }
        </div>
    )
}

export default FileUploadArea;
