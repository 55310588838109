import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import InformativaInfo from "../../../../../molecules/InformativaInfo/InformativaInfo";
import {useDispatch, useSelector} from "react-redux";
import ListInformative from "../../../../../organisms/ListInformative/ListInformative";
import {
    documentalProcessSelector,
    resetState
} from "../../../../../../features/documentalProcess/documentalProcess.slice";
import ErrorMessage from "../../../../../atoms/ErrorMessage/ErrorMessage";
import SidebarInformedPath from "../../../../../molecules/SidebardInformedPath/SidebarInformedPath";
import PageWithTitleAndFooter from "../../../../Templates/PageWithTitleAndFooter/PageWithTitleAndFooter";
import {useTranslation} from "react-i18next";
import {
    addInformativaStepThunk,
    createUnlinkedInformativa, getInformativaDetailsThunk,
    getStepsTypes,
    updateInformativaThunk,
    updateStepInformativaThunk,
    uploadStepInformativaPhotoThunk
} from "../../../../../../features/documentalProcess/documentalProcess.actions";
import {addTagThunk, deleteTagThunk} from "../../../../../../features/userHome/userHome.actions";

const InformativaDetail = ({errorVideo, imageLoading}) => {
    const dispatch = useDispatch();
    const {id} = useParams();
    const {t} = useTranslation()

    const [informativaId, setInformativaId] = useState(id)
    const [informativaName, setInformativaName] = useState('')
    const [informativaDescription, setInformativaDescription] = useState('')
    const {stepsTypes, informative, updateInformativeError} = useSelector(documentalProcessSelector)
    const [tags, setTags] = useState([]);
    const [selectedCardId, setSelectedCardId] = useState(null)

    useEffect(() => {
        dispatch(resetState())
        if (id) dispatch(getInformativaDetailsThunk({id:informativaId}))
        else dispatch(createUnlinkedInformativa({setInformativaId}))
        dispatch(getStepsTypes())
    },[id])

    useEffect(() => {
        if (informative[informativaId]) {
            setInformativaName(informative[informativaId].name);
            setInformativaDescription(informative[informativaId].description);
            setTags(informative[informativaId].tags)
        }
    },[informative, informativaId])

    const updateInformativa = () => {
        dispatch(updateInformativaThunk({
            informativaId: informativaId,
            data:{
                name: informativaName ? informativaName : '',
                description: informativaDescription ? informativaDescription : '',
                tags
            },
            alertUser: true
        }))
    }

    const updateStepInformativa = (data, informativaId) => {
        dispatch(updateStepInformativaThunk({data, informativaId}))
    }


    const handleChangePhoto = (event, stepId, informativaId) => {
        const image = event.target.files[0];
        dispatch(uploadStepInformativaPhotoThunk({stepId, image, informativaId}))
    }

    const stepsTypesFormatted = stepsTypes ?
        stepsTypes.map(step => {
            return {
                label: step.description,
                onclick: () => dispatch(addInformativaStepThunk({id: informativaId, name: step.description, detailTypeId: step.id}))
            }
        }) :
        []


    const goBack= () => {
        window.history.back()
    }

    const insertTag = (tag) => dispatch(addTagThunk({tag}))
    const deleteTag = (tag) => dispatch(deleteTagThunk({tag}))

    return (
        <PageWithTitleAndFooter title={t('informedPath.detail')} closeAction={goBack} footer={true} hasBackground={false} confirmLabel='Salva' next={updateInformativa} previous={goBack}>
            <div className='flex justify-center max-w-7xl w-full h-full pt-8 items-start space-x-8'>
                <div className='flex justify-center flex-col w-2/3 h-full items-start'>
                    <ListInformative informativa={informative[informativaId]} stepsTypesFormatted={stepsTypesFormatted} selectedInfoId={informativaId} onCardClick={setSelectedCardId}/>
                </div>
                <div className='flex flex-col w-1/3 justify-end'>
                    <div className='my-2'>
                        {updateInformativeError ?
                            <ErrorMessage>{t('errorOccurred')}</ErrorMessage>
                            : <></>}
                    </div>

                    {/*<div className='flex justify-end items-center my-3'>*/}
                    {/*    <DropdownLanguage />*/}
                    {/*</div>*/}

                    <div className='flex justify-center flex-1 flex-col w-full h-full'>
                        {selectedCardId ?
                            <SidebarInformedPath
                                step={informative[informativaId].details.find(step => step.id === selectedCardId)}
                                updateStep={updateStepInformativa}
                                informativaId={informativaId}
                                handleChangePhoto={handleChangePhoto}
                                errorVideo={errorVideo}
                                imageLoading={imageLoading}
                                onClose={() => setSelectedCardId(null)}
                                allSteps={informative[informativaId].details}
                            /> :
                            <>
                                <InformativaInfo
                                    name={informativaName}
                                    setName={setInformativaName}
                                    description={informativaDescription}
                                    setDescription={setInformativaDescription}
                                    tags={tags}
                                    setTags={setTags}
                                    insertTag={insertTag}
                                    deleteTag={deleteTag}
                                    hasDelete={true}
                                />
                            </>
                        }


                    </div>
                </div>
            </div>
        </PageWithTitleAndFooter>
    )
}

export default InformativaDetail;
