import DocumentBuilder from "../../../DocumentBuilder/DocumentBuilder";
import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {
    getGraphConsentModelThunk,
    getModelPlaceholdersThunk,
    setPlaceholderGraphModelThunk, updateGraphConsentDetailsThunk,
    uploadModelThunk
} from "../../../../../features/docModels/docModels.actions";
import {
    addBlock,
    deleteBlock,
    docModelsSelector,
    resetGraphConsentModelInfo,
    selectBlock,
    setBlock
} from "../../../../../features/docModels/docModels.slice";
import isEmpty from "lodash/isEmpty";
import PageWithStepsHeaderAndFooter from "../../../Templates/PageWithStepsHeaderAndFooter/PageWithStepsHeaderAndFooter";
import Title from "../../../../atoms/Title/Title";
import {useTranslation} from "react-i18next";
import LoadingSpinner from "../../../../atoms/LoadingSpinner/LoadingSpinner";
import ActionButton from "../../../../atoms/Buttons/ActionButtons/ActionButton";

const GraphConsentBuilder = () => {
    const {t} = useTranslation()
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const {id} = useParams()
    const { graphConsentModelInfo, placeholders, selectedBlockId, loading } = useSelector(docModelsSelector)
    const hiddenFileInput = useRef(null);
    const hiddenTemplateInput = useRef(null);
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    let isTemplate = false;

    const handleFileUploadClickButton = (template) => {
        isTemplate = template
        if(template) hiddenTemplateInput.current.click()
        else hiddenFileInput.current.click()
    }

    const handleChangeFile = async (event) => {
        const file = event.target.files[0];
        await dispatch(uploadModelThunk({file, isTemplate}))
    }

    const [selectedBlock, setSelectedBlock] = useState('')

    const resetSelectedBlock = () => {
        setSelectedBlock(null)
        dispatch(selectBlock(null))
    }

    useEffect(() => {
        if(isEmpty(id)) dispatch(resetGraphConsentModelInfo())
        else dispatch(getGraphConsentModelThunk({modelId: id}))
    },[])

    useEffect(() => {
        setTitle(graphConsentModelInfo?.name ?? '')
        setDescription(graphConsentModelInfo?.description ?? '')
    },[graphConsentModelInfo?.name, graphConsentModelInfo?.description])

    useEffect(() => {
        if(graphConsentModelInfo?.id) dispatch(getModelPlaceholdersThunk({modelId: graphConsentModelInfo.id}))
    },[graphConsentModelInfo])

    useEffect(() => {
        if(!isEmpty(graphConsentModelInfo?.placeholders) && (selectedBlockId || selectedBlock))
            setSelectedBlock(selectedBlockId ? graphConsentModelInfo?.placeholders?.find(block => block.id === selectedBlockId) : null)
    },[selectedBlockId, graphConsentModelInfo?.placeholders])

    const next = async () => {
        await dispatch(setPlaceholderGraphModelThunk({modelId: graphConsentModelInfo?.id ?? id}))
        await dispatch(updateGraphConsentDetailsThunk({modelId: graphConsentModelInfo?.id ?? id, title, description}))
        navigate(-1)
    }

    const onSetBlock = block => {
        dispatch(setBlock(block));
    }

    const onAddBlock = block => {
        dispatch(addBlock([block]));
    }

    const onSelectBlock= (block) => {
        dispatch(selectBlock(block?.id))
    }

    if(isEmpty(graphConsentModelInfo))
        return(
            <PageWithStepsHeaderAndFooter background={true} footer={false} closeAction={() => navigate('/Settings/graphconsent')}>
                <div className='flex flex-col justify-center max-w-lg'>
                    <div className='flex flex-col items-center pb-16'>
                        <Title>{t('documentBuilder.document')}</Title>
                        <h3 className="text-lg leading-6 text-gray-500 leading-6 text-justify max-w-xs">{t('graphConsent.uploadDocument')}</h3>
                    </div>
                    <div className='flex flex-col'>
                        {loading ?
                            <LoadingSpinner /> :
                            <>
                                <input type="file" className='hidden' ref={hiddenFileInput} onChange={(e) => handleChangeFile(e)} accept={".docx,.odt,.pdf"}/>
                                <input type="file" className='hidden' ref={hiddenTemplateInput} onChange={(e) => handleChangeFile(e)} accept={".docx"}/>
                                <ActionButton title={t('documentBuilder.uploadDocumentTitle')} text={t('documentBuilder.uploadDocumentSubtitle')} premium={true} onClick={() => handleFileUploadClickButton(false)}/>
                                <ActionButton title={t('documentBuilder.uploadTemplateTitle')} text={t('documentBuilder.uploadTemplateSubtitle')} premium={true} onClick={() => handleFileUploadClickButton(true)}/>
                            </>
                        }
                    </div>

                </div>
            </PageWithStepsHeaderAndFooter>
        )
    return <DocumentBuilder
        documentInfo={graphConsentModelInfo} recipientsFields={[].concat(...Object.values(placeholders))}
        selectedBlock={selectedBlock} selectedBlockId={selectedBlockId} resetSelectedBlock={resetSelectedBlock}
        deleteBlock={() => {
            dispatch(deleteBlock(selectedBlock))
            resetSelectedBlock()
        }}
        nextPage={next}
        previous={()=>navigate(-1)} goBackHome={() => navigate('/Settings/graphconsent')}
        onSetBlock={onSetBlock}
        allBlocks={graphConsentModelInfo?.placeholders ?? []}
        onAddBlock={onAddBlock}
        selectBlock={onSelectBlock}
        showInfoMenu={true}
        title={title} setTitle={setTitle}
        description={description} setDescription={setDescription}
    />
}

export default GraphConsentBuilder;
