import {Navigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {authSelector} from "../features/auth/auth.slice";

const PrivateRoute = ({children}) => {
    const {isLoggedIn} = useSelector(authSelector)
    if (!isLoggedIn) return <Navigate to='/login'/>
    else return children
}

export default PrivateRoute;
