import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {librarySelector} from "../../../../../features/library/library.slice";
import CardSignature from "../../../../molecules/CardSignature/CardSignature";
import EnableFEQ from "./EnableFEQ/EnableFEQ";
import EnableFEA from "./EnableFEA/EnableFEA";
import {getUserSignatures} from "../../../../../features/library/library.action";
import {userHomeSelector} from "../../../../../features/userHome/userHome.slice";

const Signatures = () => {
    const dispatch = useDispatch();
    const {signatures} = useSelector(librarySelector)
    const [listLoadedSignatures, setLoadedSignatures] = useState();
    const [currentWidget, setCurrentWidget] = useState();
    const { selectedTenantId, userOrganizations } = useSelector(userHomeSelector);
    const hideFeaSettings = userOrganizations?.[selectedTenantId]?.hideFeaSettings ?? false;
    const hideFeqSettings = userOrganizations?.[selectedTenantId]?.hideFeqSettings ?? false;

    //cari9camento iniziale
    useEffect(() => {
        if(!listLoadedSignatures) {
            dispatch(getUserSignatures({}));
            setLoadedSignatures(true);
        }
    }, [signatures, dispatch, listLoadedSignatures])

    const showEnablePage = (code) => {
        setCurrentWidget(code);
        //dispatch(enableSignature({code}))
    }

    const backPage = () => {
        setCurrentWidget(null);
    }


    return(
        <div className="grid grid-cols-1 gap-4">
            {
                (currentWidget === 'FEQ') ?
                    <EnableFEQ backAction={backPage}></EnableFEQ> :
                    (currentWidget === 'FEA_ONE_SHOT') ?
                        <EnableFEA backAction={backPage}></EnableFEA> :
                        <div className="w-full">
                            {signatures.map(item => ((item.code === 'FEQ' && !hideFeqSettings) || (item.code === 'FEA_ONE_SHOT' && !hideFeaSettings)) ? <CardSignature
                                    key={item.code}
                                    item={item}
                                    showCounters={true}
                                    allowAdd={true}
                                    enableAction={showEnablePage}
                                    currentWidget={currentWidget}
                                    setCurrentWidget={setCurrentWidget}
                                /> : <></>
                            )}
                        </div>
            }
        </div>
    )
}

export default Signatures;
