import {classNames} from "../../../utils/helper";
import {useTranslation} from "react-i18next";

const HomeActionsButtons = ({primary=false, illustration, title, description, action, onRedirect= () => {}}) => {
    const { t } = useTranslation();
    return(
        <div onClick={onRedirect} className={classNames(primary ? 'bg-blue-500 text-white': 'bg-gray-50','shadow relative flex flex-1 rounded-xl border border-opacity-80 w-full overflow-hidden min-h-full min-w-min cursor-pointer')}>
            <div className='flex flex-col flex-1 flex-grow justify-between h-full p-4 overflow-visible space-y-4 md:space-y-20 z-10'>
                <div>
                    <h1 className='flex text-base leading-6 font-semibold'>{t(title)}</h1>
                    <p className={classNames(primary ? 'text-opacity-80' : 'text-gray-500', 'text-sm leading-5 font-normal')}>{t(description)}</p>
                </div>
                <p className='text-base leading-5 font-medium'>{t(action)} ⟶</p>
            </div>
            <div className='overflow-hidden'>
                <img alt='action' className='absolute h-48 -bottom-14 -right-6 overflow-hidden opacity-50 md:opacity-100' src={illustration}/>
            </div>
        </div>

    )
}

export default HomeActionsButtons;
