import {classNames} from "../../../../utils/helper";


const IconsButtonGroups = ({buttons, selected}) => {
    return (
        <span className="relative z-0 inline-flex shadow-sm rounded-md">
            {buttons.map((button, buttonIdx) => (
                <button
                    key={buttonIdx}
                    type="button"
                    onClick={button.onClick}
                    className={classNames(
                        buttonIdx === 0 ?'rounded-l-md' : '-ml-px ',
                        buttonIdx === buttons.length -1 ? '-ml-px rounded-r-md' : '',
                        button?.label === selected ? 'bg-blue-600' : 'bg-white',
                        'relative inline-flex items-center px-2 py-2 border border-gray-300 text-sm font-medium text-gray-500 focus:z-10 focus:outline-none focus:ring-0'
                    )}>
                    <span className="sr-only">{button.label}</span>
                    <div className={classNames(
                        "h-5 w-5",
                        button?.label === selected ? 'text-white' : '',
                    )}>
                        {button.icon}
                    </div>
                </button>
            ))}
        </span>
    )
}

export default IconsButtonGroups;
