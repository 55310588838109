import SingleCheckbox from "../../../atoms/SingleCheckbox/SingleCheckbox";
import Select from "../../../atoms/Select/Select";
import {useTranslation} from "react-i18next";
import isEmpty from "lodash/isEmpty";

const GraphConsent = ({isConsentChecked, setConsent, templatesOptions, templateChoice= null, dateLastConsent}) => {
    const {t} = useTranslation();
    const onSetIsConsentChecked = async (isChecked) => {
        const template = templatesOptions?.[0]?.value;//isChecked ? templatesOptions?.[0]?.value ?? null : null
        setConsent(isChecked, template)
    }

    return (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 items-center px-4 pb-4">
            {!isEmpty(templatesOptions) ?
                <>
                    <div className="col-span-1 w-full space-y-2">
                        <SingleCheckbox
                            label={t('recipients.collect-consent', {date: dateLastConsent ? new Date(dateLastConsent).toLocaleDateString() : '-'})}
                            value={isConsentChecked} setValue={(value) => onSetIsConsentChecked(value)}/>
                    </div>
                    <div className="col-span-1 w-full">
                        <Select
                            label={t('recipients.template')}
                            options={templatesOptions}
                            value={templateChoice}
                            setValue={(value) => setConsent(isConsentChecked, value)}
                            placeholder=' '
                            disabled={!isConsentChecked}
                        />
                    </div>
                </>:
                <div className='md:col-span-2'><p className='text-sm leading-5 font-normal'>{t('recipients.noConsentMessage')}</p></div>
            }
        </div>
    )

}

export default GraphConsent;
