import {v4 as uuid} from "uuid";
import React from "react";
import {classNames} from "../../../../utils/helper";

const variants = {
    blue: `border-transparent text-white bg-blue-600 hover:bg-blue-700 px-4 py-2 focus:ring-offset-gray-100`,
    white: `bg-white hover:bg-gray-50 text-gray-700 py-0.5 px-2`,
    disabled: 'px-4 py-2 text-gray-500 bg-white focus:ring-0 focus:outline-none cursor-default',
}

const RoundButtons = ({onClick, label, disabled = false, variant = 'white'}) => {
    const id = uuid();
    return (
        <button
            id={id}
            type="button"
            className={classNames(disabled ? variants['disabled']: variants[variant],'flex items-center border border-gray-300 font-medium rounded-full shadow-sm text-sm leading-5 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2  text-xs')}
            onClick={onClick}
            disabled={disabled}
        >
            {label}
        </button>
    )
}

export default RoundButtons;
