import React, {Suspense} from 'react';
import {createRoot} from 'react-dom/client';
import './index.css';
import {Provider} from "react-redux";
import store from "./store";
import {BrowserRouter} from "react-router-dom";
import './i18n_locize'
import LoadingSpinner from "./components/atoms/LoadingSpinner/LoadingSpinner";
import RouterConfig from "./navigation/RouterConfig";
import {Toaster} from "react-hot-toast";

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <Suspense fallback={<LoadingSpinner />}>
        <Provider store={store} >
            <Toaster />
            <BrowserRouter>
                <RouterConfig />
            </BrowserRouter>
        </Provider>
    </Suspense>
);
