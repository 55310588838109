/* This example requires Tailwind CSS v2.0+ */
import React, {Fragment} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import {useSelector} from "react-redux";
import {XMarkIcon} from "@heroicons/react/24/outline";
import {userHomeSelector} from "../../../../features/userHome/userHome.slice";
import {toLocaleString} from "../../../../utils/helper";

const ModalNotificationDetail = ({open = false, setOpen}) => {
    const { currentNotification } = useSelector(userHomeSelector);

    const onClose = () => {
        setOpen(false);
    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" static className="fixed z-10 inset-0 overflow-y-auto" open={open} onClose={setOpen}>
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className="inline-block w-full align-bottom bg-white rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-top sm:max-w-4xl sm:w-full">
                            <div className="" aria-hidden="true" >
                                <div className="flex items-center m-4">
                                    <XMarkIcon aria-hidden="true" className="cursor-pointer h-5 w-5 inline mr-5" onClick={onClose}/>
                                </div>
                            </div>

                            <div className="mr-10 pr-40">
                                <div className="px-4 pt-5 pb-2 sm:px-6 sm:pt-4 text-sm text-gray-500" >
                                    <cite>{toLocaleString(currentNotification.notificationDate)}</cite>
                                </div>
                                <div className="px-4 pt-2 pb-2 sm:px-6 sm:pt-4 text-sm" >
                                    <b>Mittente:</b> {currentNotification.sender.givenName} {currentNotification.sender.familyName}
                                </div>
                                <div className="px-4 pt-2 pb-4 sm:px-6 sm:pt-4 text-sm" >
                                    <b>Oggetto:</b> {currentNotification.title}
                                </div>
                                <div className="px-4 pt-2 pb-4 sm:px-6 sm:pt-4" dangerouslySetInnerHTML={{ __html: currentNotification.text }}>
                                </div>
                                <button className="h-0 w-0 overflow-hidden"/>
                            </div>


                        </div>

                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default ModalNotificationDetail;
