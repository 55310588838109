import React from "react";
import HeaderWithTitle from "../../../molecules/Headers/HeaderWithTitle/HeaderWithTitle";

const PageWithTitleHeader = ({children, title, closeAction}) => {

    return(
        <div className="flex flex-col h-screen overflow-hidden bg-white">

            <HeaderWithTitle title={title} closeAction={closeAction}/>

            <div className='w-screen flex justify-center py-2 overflow-auto'>
                <div className="flex flex-col items-stretch flex-grow max-w-screen-2xl mx-auto px-4 sm:px-6 lg:px-8">
                    {children}
                </div>
            </div>
        </div>
    )
}

export default PageWithTitleHeader;
