import {v4 as uuid} from "uuid";
import {classNames} from "../../../utils/helper";

const Textarea = ({label, value, setValue, placeholder, disabled=false}) => {
    const id = uuid();
    return(
        <div className='w-full'>
            <label htmlFor={`input-${id}`} className="block text-sm font-medium text-gray-700">
                {label}
            </label>
            <div className="mt-1">
                <textarea
                    name={`input-${id}`}
                    id={`input-${id}`}
                    value={value}
                    onChange={(e)=> setValue(e.target.value)}
                    rows={5}
                    className={classNames(disabled? 'bg-gray-200 text-gray-600' : '',"p-1 shadow-sm block w-full focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300 border rounded-md")}
                    placeholder={placeholder}
                    disabled={disabled}
                />
                {/*<p className='text-sm leading-5 font-normal text-gray-500 py-2'>Max 500 caratteri</p>*/}
            </div>
        </div>
    )
}

export default Textarea;
