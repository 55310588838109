import React from "react";
import SimpleTable from "../../molecules/Tables/SimpleTable/SimpleTable";
import {ICON_BUTTON} from "../../atoms/SimpleTableElement/SimpleTable.types";

const DevicesList = ({data, deleteDevice, updateDevice, filters, setFilters}) => {
    const columns = [
        {field: 'id', label: 'tableColumns.id', hidden: false},
        {field: 'label', label: 'tableColumns.label', hidden: false},
        {field: 'device_id', label: 'tableColumns.device', hidden: false},
        {field: 'currentStatus', label: 'tableColumns.status', hidden: false},
        {
            field: 'Elimina',
            label: 'tableColumns.elimina',
            hidden: true,
            type: ICON_BUTTON,
            onClick: deleteDevice,
            iconType: 'delete'
        },
        {
            field: 'Update',
            label: 'tableColumns.update',
            hidden: true,
            type: 'icon-button',
            onClick: updateDevice,
            iconType: 'modify'
        }
    ]


    return (
        <div className="flex flex-col flex-1">
            <SimpleTable data={data} columns={columns} filters={filters} setFilters={setFilters}/>
        </div>
    )
}

export default DevicesList;
