import SimplePage from "../Templates/SimplePage/SimplePage";
import ForgotPasswordForm from "../../organisms/ForgotPassword/ForgotPasswordForm";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {forgotPassword} from "../../../features/auth/auth.actions";
import {authSelector, clearForgotPasswordError} from "../../../features/auth/auth.slice";

const ForgotPasswordPage = () => {
    const [username, setUsername] = useState('');
    const { forgotPasswordError, forgotPasswordErrorMessage } = useSelector(authSelector)
    const dispatch = useDispatch();

    const resetPasswordRequest = async () => {
        dispatch(forgotPassword({username}));
    }

    useEffect(() => {
        dispatch(clearForgotPasswordError())
    },[])

    return(
        <SimplePage>
            <div className='flex max-w-screen-2xl w-full h-screen justify-center items-center bg-fi-background bg-no-repeat bg-left-bottom bg-50% bg-scroll'>
                <div className="flex flex-grow justify-center">
                    <ForgotPasswordForm
                        username={username}
                        setUsername={setUsername}
                        forgotPasswordError={forgotPasswordError}
                        forgotPasswordErrorMessage={forgotPasswordErrorMessage}
                        resetPasswordRequest={resetPasswordRequest}
                    />
                </div>
            </div>
        </SimplePage>
    )
}
export default ForgotPasswordPage;
