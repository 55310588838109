import React, {Fragment} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import {useSelector} from "react-redux";
import {peopleSelector} from "../../../../features/people/people.slice";
import PersonDetailDatiPrincipali
    from "../../../organisms/PeopleDetailView/PersonDetailDatiPrincipali/PersonDetailDatiPrincipali";

const ModalContactNew = ({open = false, setOpen, currentPeopleRole, onSavePersonSuccess=()=>{}}) => {

    const {currentPerson} = useSelector(peopleSelector)

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" static className="fixed z-10 inset-0 overflow-y-auto" open={open} onClose={setOpen}>
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className="inline-block w-full align-bottom bg-white rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-top sm:max-w-4xl sm:w-full">
                            <div className="px-4 pt-5 pb-4 sm:px-6 sm:pt-6" >
                                <PersonDetailDatiPrincipali data={currentPerson} currentPeopleRole={currentPeopleRole} onSavePersonSuccess={onSavePersonSuccess} setOpen={setOpen}/>
                            </div>
                            <button className="opacity-0 absolute bottom-0"></button>
                        </div>

                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default ModalContactNew;
