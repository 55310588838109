import React, { useMemo, useRef } from "react";
import RoundButtons from "../../../atoms/Buttons/RoundButtons/RoundButtons";
import LoadingSpinner from "../../../atoms/LoadingSpinner/LoadingSpinner";
import { useTranslation } from "react-i18next";

const Image = ({ contentUrl, handleChangePhoto, imageLoading = false }) => {
    const hiddenPhotoInputRef = useRef();
    const { t } = useTranslation();

    const handlePhotoUploadClickButton = () => {
        hiddenPhotoInputRef.current.click();
    };

    const imageTag = useMemo(() => <img src={contentUrl} alt="step" />, [contentUrl]);

    return (
        <>
            <label className="block text-sm font-medium text-gray-700">
                {t("image.title")}
            </label>
            {contentUrl ? (imageTag) : (
                <div className="flex justify-between">
                    <div>
                        <RoundButtons
                            label={t("image.upload")}
                            onClick={handlePhotoUploadClickButton}
                        />
                    </div>
                    <input
                        type="file"
                        className="hidden"
                        ref={hiddenPhotoInputRef}
                        onChange={handleChangePhoto}
                    />
                    {imageLoading && <LoadingSpinner />}
                </div>
            )}
        </>
    );
};

export default Image;
