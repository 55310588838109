import BigLabeledButtonSelector from "../../molecules/BigLabeledButtonSelector/BigLabeledButtonSelector";
import SimplePage from "../Templates/SimplePage/SimplePage";
import {useDispatch, useSelector} from "react-redux";
import {userHomeSelector} from "../../../features/userHome/userHome.slice";
import {useEffect, useMemo} from "react";
import {useNavigate} from "react-router-dom";
import {
    getUserOrganizations,
    changeSelectedTenantThunk,
    retrieveOrganizationDetailsThunk
} from "../../../features/userHome/userHome.actions";
import isEmpty from "lodash/isEmpty";
import {getDevices} from "../../../features/library/library.action";

const OrganizationSelector = () => {
    const { userOrganizations } = useSelector(userHomeSelector);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const organizationsOptions = useMemo(() =>
            Object.values(userOrganizations).map(org => ({value: org?.id, label: org?.description})),
        [userOrganizations])

    const onClick = async (organizationId) => {
        await dispatch(changeSelectedTenantThunk({userOrganizationRoleId: organizationId})).unwrap();
        await dispatch(retrieveOrganizationDetailsThunk({userOrganizationRoleId: organizationId})).unwrap();
        dispatch(getDevices());
        navigate('/home')
    }

    useEffect(() => {
        if(isEmpty(userOrganizations))
            dispatch(getUserOrganizations())
    }, [userOrganizations]);

    return (
        <SimplePage>
            <div className='flex max-w-screen-2xl w-full h-screen justify-center items-center bg-fi-background bg-no-repeat bg-left-bottom bg-50% bg-scroll'>
                <BigLabeledButtonSelector options={organizationsOptions} onChange={onClick} />
            </div>
        </SimplePage>
    )
}

export default OrganizationSelector;
