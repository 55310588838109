import {createAsyncThunk, createEntityAdapter, createSlice} from "@reduxjs/toolkit";
import {
    addUsersToList,
    countListPeople,
    countListPersonActivity, createNewUserList,
    deletePerson, deleteUserList,
    doUpdatePersonData, findPeopleById,
    getGraphIdentificationCall, getPeopleByListId,
    getSubmittedFormByEventIdCall,
    getUserlists,
    listPeople,
    listPersonActivity,
    listPersonEvents, removeUsersFromList
} from "../../api/people";
import {toast} from "react-hot-toast";
import Alert from "../../components/molecules/Alerts/Alert";
import {ERROR, SUCCESS} from "../../components/molecules/Alerts/types";
import {Translation} from 'react-i18next';

const peopleAdapter = createEntityAdapter()

const initialState = peopleAdapter.getInitialState({
    people: [],
    detailOpened: false,
    listLoaded: false,
    currentPerson: null,
    personActivity: [],
    personActivityCount: 0,
    personEvents: [],
    personEventsCount: 0,
    graphAgreements: {},
    userLists: [],
    userListsCount: 0
})

//rubrica
export const getPeople = createAsyncThunk('people/getPeople', async (props) => {
    const response = await listPeople(props);
    return response.data
});

export const getPersonById = createAsyncThunk('people/getPersonById', async (props) => {
    const response = await findPeopleById(props);
    return response.data
});


export const getPeopleByListIdThunk = createAsyncThunk('people/getPeopleByListIdThunk', async ({id, filter, offset, limit}) => {
    const response = await getPeopleByListId(id, filter, offset, limit);
    return response.data
});

export const countPeople = createAsyncThunk('people/countPeople', async (props) => {
    const response = await countListPeople(props);
    return response.data
});

//attività utente
export const getPersonActivity = createAsyncThunk('people/getPersonActivity', async ({id, eventId}) => {
    //if(!eventId) return []
    const response = await listPersonActivity({id, eventId});
    return response.data
});

export const countPersonActivity = createAsyncThunk('people/countPersonActivity', async (props) => {
    const response = await countListPersonActivity(props);
    return response.data
});

//aggiornamento rubrica
export const updatePersonData = createAsyncThunk('people/updatePersonData', async ({data, onSuccess} ) => {
    const response = await doUpdatePersonData(data);
    onSuccess?.(response.data)
    return response.data
});

//eventi di una persona
export const getPersonEvents = createAsyncThunk('people/getPersonEvents', async ({confirmoIdentityId, offset, limit, txtFilter, id} ) => {
    const response = await listPersonEvents({confirmoIdentityId, offset, limit, txtFilter, id});
    return response.data
});

export const delPerson = createAsyncThunk('people/delPerson', async (props ) => {
    const response = await deletePerson(props);
    return response.data
});

export const getGraphIdentificationThunk = createAsyncThunk(
    'people/getGraphIdentificationThunk', async ({id} ) => {
        const response = await getGraphIdentificationCall(id);
        return {id, data: response.data}
    });

export const getSubmittedFormByEventIdThunk = createAsyncThunk('people/getSubmittedFormByEventIdThunk', async ({eventId} ) => {
    const response = await getSubmittedFormByEventIdCall(eventId);
    return {eventId, data: response.data}
});

export const getUserLists = createAsyncThunk('people/getUserLists', async (props) => {
    const response = await getUserlists(props?.filter, props?.offset, props?.limit);
    return response.data
});

export const createNewUserListThunk = createAsyncThunk('people/createNewUserListThunk', async ({name}) => {
    const response = await createNewUserList(name);
    return response.data
});

export const deleteUserListsThunk = createAsyncThunk('people/deleteUserListsThunk', async ({id}) => {
    const response = await deleteUserList(id);
    return response.data
});

export const addUsersToListThunk = createAsyncThunk('people/addUsersToListThunk', async ({listId, userIds}) => {
    const response = await addUsersToList(listId, userIds);
    return response.data
});

export const removeUsersFromListThunk = createAsyncThunk('people/removeUsersFromListThunk', async ({listId, userIds}) => {
    const response = await removeUsersFromList(listId, userIds);
    return response.data
});

const peopleSlice = createSlice({
    name: 'people',
    initialState,
    reducers: {
        openPersonDetail: (state, action) => {
            state.currentPerson = action.payload.person || {};
            state.detailOpened = true
        },
        closePersonDetail: (state) => {
            state.currentPerson = null
            state.detailOpened = false
        },
        resetPeopleList: (state) => {
            state.listLoaded = false
            state.people = [];
        },
        forceReloadPeopleList: (state) => {
            state.listLoaded = false
        },
        resetState: () => {
            return initialState;
        },

    },
    extraReducers: (builder) => {
        builder
            .addCase(getPeople.pending, (state) => {
                state.loadingPeople = true;
            })
            .addCase(getPeople.fulfilled, (state, action) => {
                state.people = action.payload;
                state.listLoaded = true;
                state.loadingPeople = false;
            })
            .addCase(getPeople.rejected, (state) => {
                state.people = [];
                state.listLoaded = true;
                state.loadingPeople = false;
            })
            .addCase(getPersonById.rejected, (state) => {
                state.currentPerson = null;
                //state.detailOpened = false
            })
            .addCase(getPersonById.fulfilled, (state, action) => {
                state.currentPerson = action.payload || {};
                //state.detailOpened = true
            })
            .addCase(getPeopleByListIdThunk.pending, (state) => {
                state.loadingPeople = true;
                state.people = [];
            })
            .addCase(getPeopleByListIdThunk.fulfilled, (state, action) => {
                state.people = action.payload.people;
                state.peopleCount = action.payload.count;
                state.listLoaded = true;
                state.loadingPeople = false;
            })
            .addCase(getPeopleByListIdThunk.rejected, (state) => {
                state.people = [];
                state.peopleCount = 0;
                state.listLoaded = true;
                state.loadingPeople = false;
            })
            .addCase(countPeople.fulfilled, (state, action) => {
                state.peopleCount = action.payload;
            })
            .addCase(countPeople.rejected, (state) => {
                state.peopleCount = 0;
            })
            .addCase(getPersonActivity.pending, (state, action) => {
                console.log(action);
                state.personActivity = [];
            })
            .addCase(getPersonActivity.fulfilled, (state, action) => {
                state.personActivity = action.payload;
                state.listLoaded = true;
            })
            .addCase(getPersonActivity.rejected, (state) => {
                state.personActivity = [];
                state.listLoaded = true;
            })
            .addCase(countPersonActivity.fulfilled, (state, action) => {
                state.personActivityCount = action.payload;
            })
            .addCase(countPersonActivity.rejected, (state) => {
                state.personActivityCount = 0;
            })
            .addCase(updatePersonData.fulfilled, (state, action) => {
                console.log(action);
                toast.custom((tst) => (<Translation>{(t) => <Alert type={SUCCESS} message={t('operationOk')} onClose={() => toast.dismiss(tst.id)}/>}</Translation>));
                var isInsert = state.currentPerson && !state.currentPerson.id;
                if (isInsert) {
                    state.currentPerson = {};
                }
                state.listLoaded = false;
            })
            .addCase(updatePersonData.rejected, (state, action) => {
                toast.custom((tst) => (<Translation>{(t) => <Alert type={ERROR} message={action.error?.message ?? t('errorOccurred')} onClose={() => toast.dismiss(tst.id)}/>}</Translation>));
            })
            .addCase(delPerson.fulfilled, (state) => {
                state.listLoaded = false;
            })
            .addCase(getGraphIdentificationThunk.fulfilled, (state, action) => {
                if (action.payload.id) state.graphAgreements[action.payload.id] = action.payload.data;
            })
            .addCase(getPersonEvents.pending, (state) => {
                state.personEvents = [];
                state.personEventsCount = 0;
                state.listLoaded = true;
            })
            .addCase(getPersonEvents.fulfilled, (state, action) => {
                state.personEvents = action.payload.events;
                state.personEventsCount = action.payload.count;
                state.listLoaded = true;
            })
            .addCase(getPersonEvents.rejected, (state) => {
                state.personEvents = [];
                state.personEventsCount = 0;
                state.listLoaded = true;
            })
            .addCase(getSubmittedFormByEventIdThunk.fulfilled, (state, action) => {
                state.personEvents = state.personEvents.map((e) => e.eventid === action.payload.eventId ? ({ ...e, form: action.payload.data }) : e);
            })
            .addCase(getUserLists.pending, (state) => {
                state.userLists = [];
            })
            .addCase(getUserLists.fulfilled, (state, action) => {
                state.userLists = action.payload.userLists;
                state.userListsCount = action.payload.count;
            });
    }
})

// Action creators are generated for each case reducer function
export const { resetState, openPersonDetail, closePersonDetail, resetPeopleList, forceReloadPeopleList } = peopleSlice.actions;

export const peopleSelector = (state) => state.people;

export default peopleSlice.reducer;
