import PageWithTitleHeader from "../Templates/PageWithTitleHeader/PageWithTitleHeader";
import VerticalNavigation from "../../molecules/VerticalNavigation/VerticalNavigation";
import React, {useEffect, useMemo, useState} from "react";
import {Navigate, useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {documentalProcessSelector, setSections} from "../../../features/documentalProcess/documentalProcess.slice";
import InputWithLeadingIcon from "../../atoms/Inputs/InputWithLeadingIcon/InputWithLeadingIcon";
import IconButton from "../../atoms/Buttons/IconButton/IconButton";
import IconsButtonGroups from "../../atoms/Buttons/ButtonGroups/IconsButtonGroups";
import CardsList from "../../organisms/CardsList/CardsList";
import SimpleTable from "../../molecules/Tables/SimpleTable/SimpleTable";
import {userHomeSelector} from "../../../features/userHome/userHome.slice";
import isEmpty from "lodash/isEmpty";
import emptyState from '../../../assets/illustrations/6_Illustrazione_Confirmo_Empty-State.svg'
import VerticalNavigationMultipleSelection
    from "../../molecules/VerticalNavigationMultipleSelection/VerticalNavigationMultipleSelection";
import {useTranslation} from "react-i18next";
import {CHECKBOX, FAVOURITE} from "../../atoms/SimpleTableElement/SimpleTable.types";
import {getModelsList, getTags, updateModelAsFavouriteThunk} from "../../../features/userHome/userHome.actions";
import {Bars3Icon, MagnifyingGlassIcon, PlusIcon, Squares2X2Icon} from "@heroicons/react/24/solid";
import {CONCATENATION, SHOWFEQINFO, START_DRAFT_PROCESS, WRITE_LIBRARY} from "../../../utils/permissions";
import Button from "../../atoms/Buttons/Button/Button";
import {createConcatenatedProcessThunk, setStepsByProcessType} from "../../../features/sendProcess/sendProcess.slice";
import {checkPermissions} from "../../../utils/helper";

const DocumentPage = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const {section} = useParams();
    const {sections, updateModelAsFavouriteCallDone} = useSelector(documentalProcessSelector)
    const [showGrid, setShowGrid] = useState(true);
    const {models, selectedTenantId, tags, userOrganizations} = useSelector(userHomeSelector)
    const dispatch = useDispatch();
    const [selectedTags, setSelectedTags] = useState([]);
    const [modelsFiltered, setModelsFiltered] = useState(models);
    const [textFilterModels, setTextFilterModels] = useState('');
    const permissions = userOrganizations?.[selectedTenantId]?.permissions ?? {}
    const [selectedCards, setSelectedCards] = useState({});
    const hasStartDraftProcess = useMemo(() => checkPermissions(permissions, START_DRAFT_PROCESS), [permissions])

    useEffect(() => {
        if (hasStartDraftProcess) {
            const present = sections.some(s => s.href === 'drafts');
            if (!present) {
                dispatch(setSections([...sections, { name: 'document.drafts', href: 'drafts'}]))
            }
        }
    },[])

    useEffect(() => {
        setModelsFiltered(models.filter(m => m.name.toLowerCase().includes(textFilterModels.toLowerCase()) || m.description.toLowerCase().includes(textFilterModels.toLowerCase())))
    },[textFilterModels, models])

    const getModels = (type = 'ALL') => dispatch(getModelsList({type, tags: selectedTags}));

    let columns = [
        {field: 'select', label: t('tableColumns.select'), type: CHECKBOX, hidden: true, id: 1, selected: selectedCards, setSelected: (id) => setSelectedCards({...selectedCards, [id]: !selectedCards[id]})},
        {field: 'name', label: t('tableColumns.sender'), hidden: false, id: 2},
        {field: 'description', label: t('tableColumns.description'), hidden: false, id: 3},
        {field: 'labels', label: t('tableColumns.labels'), hidden: false, id: 4},
        {
            field: 'Favourite',
            label: t('tableColumns.favourite'),
            hidden: true,
            type: FAVOURITE,
            onClick: (processId, isFavourite) => {
                if (processId) {
                    dispatch(updateModelAsFavouriteThunk({processId, isFavourite}));
                }
            },
            id: 5
        },
    ]
    if(!permissions[CONCATENATION]) columns = columns.filter(c=> c.field !== 'select')

    const loadSection = () => {
        switch (section) {
            case 'favorites' :
                getModels('FAV')
                break;
            case 'latest':
                getModels('REC')
                break;
            case 'drafts':
                getModels('DRAFT')
                break;
            default:
                getModels('ALL')
                break;
        }
    }

    useEffect(() => {
        dispatch(getTags())
    }, [selectedTenantId])

    useEffect(() => {
        loadSection();
    }, [section, selectedTags])


    useEffect(() => {
        if (updateModelAsFavouriteCallDone) {
            loadSection();
        }
    }, [updateModelAsFavouriteCallDone])


    const backHome = () => navigate('/home')

    const onClick = (cardId) => {
        if(Object.values(selectedCards).some(value => value)) setSelectedCards({...selectedCards, [cardId]: !selectedCards[cardId]})
        else navigate(`/sendprocess/${cardId}/recipients`)
    }

    const setFavourite = (processId, isFavourite) => {
        if (processId) dispatch(updateModelAsFavouriteThunk({processId, isFavourite}));
    }

    const onClickTag = (tag) => {
        if (selectedTags.includes(tag)) setSelectedTags(selectedTags.filter(s => s !== tag))
        else setSelectedTags([...selectedTags, tag])
    }

    const sendSelectedEnabled = permissions[CONCATENATION] && Object.values(selectedCards).includes(true)

    const concatenateProcesses = async () => {
        if(sendSelectedEnabled) {
            const selected = Object.keys(selectedCards).filter(k => selectedCards[k])
            await dispatch(createConcatenatedProcessThunk({eventFlowTypeIds: selected})).unwrap()
            dispatch(setStepsByProcessType({isConcatenation: true}))
            navigate(`/sendconcatenatedprocess/recipients`)
        }
    }

    if (!section)
        return <Navigate to='/document/models'/>
    if (section === 'models' && isEmpty(models) && isEmpty(selectedTags)) return (
        <PageWithTitleHeader title={t('documentPage.title')} closeAction={backHome}>
            <div className='flex w-full h-screen flex-col items-center'>
                <div className='flex flex-col justify-center items-center max-w-sm flex-1 my-10'>
                    <img src={emptyState} alt='empty state'/>
                    <h1 className='text-2xl leading-8 font-medium pr-1'>{t('documentPage.noModelAvailableTitle')}</h1>
                    <p className='text-base leading-6 font-normal text-gray-500 text-center'>{t('documentPage.noModelAvailableMessage')}</p>
                    <div className='my-3'>
                        <IconButton size='lg' label={t('createNew')} icon={<PlusIcon className='w-5 h-5'/>}
                                    onClick={() => navigate('/newprocess/intro')}/>
                    </div>
                </div>
            </div>
        </PageWithTitleHeader>
    )
    else return (
        <PageWithTitleHeader title={t('documentPage.title')} closeAction={backHome}>
            <div className='flex py-10'>
                <div>
                    <VerticalNavigation navigation={sections} section={section} baseurl='document'/>
                    <h3 className='text-xs leading-4 font-semibold tracking-wider uppercase text-gray-500 mt-8 mb-2 mx-2 px-3'>{t('documentPage.tags')}</h3>
                    <VerticalNavigationMultipleSelection items={tags} selected={selectedTags}
                                                         onClickItem={onClickTag}/>
                </div>
                <div className='flex flex-col flex-1 ml-4'>
                    <div className='flex items-start mb-4 space-x-2'>
                        <div className='flex flex-grow -mt-1'>
                            <InputWithLeadingIcon
                                icon={<MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>}
                                placeholder={t('documentPage.searchModel')}
                                value={textFilterModels}
                                setValue={setTextFilterModels}
                            />
                        </div>

                        <div className='relative flex'>
                            {sendSelectedEnabled && <span className="animate-ping absolute inline-flex w-3 h-3 right-0 top-0 rounded-full bg-green-400 opacity-75"></span>}
                            <Button label='Invia selezionati' variant={sendSelectedEnabled ?'green' : 'disabled'} size='lg' onClick={concatenateProcesses}/>
                        </div>

                        {permissions[WRITE_LIBRARY] ?
                            <div className=''>
                                <IconButton size='lg' label={t('createNew')} icon={<PlusIcon className='w-5 h-5'/>}
                                            onClick={() => navigate('/newprocess/intro')}/>
                            </div> : <></> }

                        <div className=''>
                            <IconsButtonGroups buttons={[
                                {
                                    label: 'List',
                                    icon: <Bars3Icon/>,
                                    onClick: () => setShowGrid(false)
                                },
                                {
                                    label: 'Grid',
                                    icon: <Squares2X2Icon/>,
                                    onClick: () => setShowGrid(true)
                                }
                            ]} selected={showGrid ? 'Grid' : 'List'}/>
                        </div>
                    </div>
                    <div className='flex justify-between'>
                        <h1 className='text-xl leading-7 font-semibold py-1'>{t('documentPage.subtitle')}</h1>
                    </div>
                    {showGrid ?
                        <div className='-ml-4 overflow-auto'>
                            <CardsList
                                cards={modelsFiltered}
                                onClick={onClick}
                                setFavourite={setFavourite}
                                selectedCards={selectedCards}
                                setSelectedCards={setSelectedCards}
                                concatenationEnabled={permissions[CONCATENATION]}
                            />
                        </div> :
                        <div className='flex flex-1'>
                            <SimpleTable
                                data={modelsFiltered.map(mod => ({...mod, identifier: mod.id}))}
                                columns={columns}
                                onClick={onClick}
                                setFavourite={setFavourite}
                            />
                        </div>
                    }
                </div>
            </div>
        </PageWithTitleHeader>
    )
}
export default DocumentPage;
