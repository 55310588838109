import React, {useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getSignatureTypesListThunk} from "../../../features/recipients/recipients.actions";
import {recipientsSelector} from "../../../features/recipients/recipients.slice";
import DocumentUpload from "./DocumentUpload/DocumentUpload";
import LoadingSpinner from "../../atoms/LoadingSpinner/LoadingSpinner";
import {useNavigate, useParams} from "react-router-dom";
import {
    getPlaceholdersSelfSignThunk,
    selfSignDocumentSelector,
    selfSignInit,
    startSelfSignedDocumentThunk, uploadSelfSignedDocumentAndSignCadesThunk,
    uploadSelfSignedDocumentThunk
} from "../../../features/selfSignDocument/selfSignDocument.slice";
import DocumentBuilder from "../DocumentBuilder/DocumentBuilder";
import {
    addBlock,
    deleteBlock,
    documentBuilderSelector,
    resetBlocks,
    selectBlock,
    setBlock
} from "../../../features/documentBuilder/documentBuilder.slice";
import isEmpty from "lodash/isEmpty";
import ModalResetPlaceholders from "../../molecules/Modals/ModalResetPlaceholders/ModalResetPlaceholders";
import {
    doRemoteMassiveSignatureThunk,
    getDevices,
    requestOtpFeqThunk,
    sendToTabletThunk
} from "../../../features/library/library.action";
import {librarySelector} from "../../../features/library/library.slice";
import EndSendProcess from "../SendProcess/EndSendProcess/EndSendProcess";
import documentSentIllustration
    from "../../../assets/illustrations/4_Illustrazione_Confirmo_Documento-Inviato-Successo.svg";
import ModalPinRequest from "../../molecules/Modals/ModalPinRequest/ModalPinRequest";
import Modal from "../../molecules/Modals/Templates/Modal";

const SelfSignDocument = () => {
    const {step} = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {signatureTypesList} = useSelector(recipientsSelector)
    const {loading, steps, document, placeholders} = useSelector(selfSignDocumentSelector)
    const { blocks, selectedBlockId } = useSelector(documentBuilderSelector)
    const {devices} = useSelector(librarySelector)

    const [selectedBlock, setSelectedBlock] = useState('')
    const [signatureType, setSignatureType] = useState('')
    const [signatureFormat, setSignatureFormat] = useState('PADES')
    const [file, setFile] = useState()
    const [openModalResetPlaceholder, setOpenModalResetPlaceholder] = useState(false)
    const [loadingCades, setLoadingCades] = useState(false)
    const [selectedDevice, setSelectedDevice] = useState(null)
    const isGraphSignature = useMemo(() => signatureType === 'GRAPH', [signatureType])

    const [openModalPinRequest, setOpenModalPinRequest] = useState(false)
    const [selected, setSelected] = useState({})
    const {pIp, otpFeqVisible, otpRequestPending} =  useSelector(librarySelector);


    const buildUrl = (stepName) => `/selfsign/${stepName}`

    useEffect(() => {
        dispatch(getSignatureTypesListThunk())
        dispatch(getDevices())
        if(!step) navigate(buildUrl('upload'), {replace: true})
    },[])

    useEffect(() => {
        if(step === 'document' && document?.id) dispatch(getPlaceholdersSelfSignThunk())
    },[step, document])

    useEffect(() => {
        if(!isEmpty(blocks) && (selectedBlockId || selectedBlock))
            setSelectedBlock(selectedBlockId ? blocks.find(block => block.id === selectedBlockId) : null)
    },[selectedBlockId, blocks])

    const goToDocumentPage = () => {
        if (file && signatureType === 'FEQ' && signatureFormat === 'CADES') {
            //chiedo direttamente il PIN
            setOpenModalPinRequest(true)
        } else {
            //proseguo come prima
            const callback= () => navigate(buildUrl('document'))
            dispatch(uploadSelfSignedDocumentThunk({file, signatureType: signatureType, callback}))
        }
    }

    const doCadesSignature = async (pin, otp, deviceId) => {
        const callback= () => navigate('/home')
        try {
            setLoadingCades(true)
            await dispatch(uploadSelfSignedDocumentAndSignCadesThunk({file, pin, otp, deviceId, callback}))
            setLoadingCades(false)
        }catch(e) {
            console.log(e)
            setLoadingCades(false)
        }
        setOpenModalPinRequest(false)
    }

    const requestOtp = (pin) => {
        dispatch(requestOtpFeqThunk({pin}))
    }

    const resetSelectedBlock = () => {
        setSelectedBlock(null)
        dispatch(selectBlock(null))
    }

    const pageRedirect = (link, eventId) => {
        if(isGraphSignature) {
            sendToDevice(eventId, selectedDevice)
            navigate(buildUrl('end'))
        }
        else window.location.href = link
    }

    const onSetBlock = block => {
        if(block) dispatch(setBlock(block));
    }

    const onAddBlock = block => {
        if(block) dispatch(addBlock([block]));
    }

    const onSelectBlock= (block) => {
        if(block?.id) dispatch(selectBlock(block.id))
    }

    const handleChangeFile = (event) => {
        if(!isEmpty(blocks)) {
            setFile(event.target.files[0])
            setOpenModalResetPlaceholder(true)
        }
        else dispatch(uploadSelfSignedDocumentThunk({file: event.target.files[0], signatureType}))
    }

    const resetPlaceholders = (reset=false) => {
        if(reset) dispatch(resetBlocks())
        dispatch(uploadSelfSignedDocumentThunk({file, signatureType}))
        setOpenModalResetPlaceholder(false)
    }

    const sendToDevice = (eventId, deviceId) => {
        if (eventId && deviceId) dispatch(sendToTabletThunk({eventId, deviceId}))
    }

    const onClickNextInDocument = async ()=>{
        await dispatch(startSelfSignedDocumentThunk({
            callback: pageRedirect
        }))
    }

    switch(step){
        case 'upload':
            return <><DocumentUpload
                signatureTypesList={signatureTypesList}
                goToDocumentPage={goToDocumentPage}
                goBackHome={() => navigate('/home')}
                loading={loading} steps={steps}
                selfSignInit={() => dispatch(selfSignInit())}
                buildUrl={buildUrl}
                file={file}
                setFile={setFile}
                signatureType={signatureType}
                setSignatureType={setSignatureType}
                signatureFormat={signatureFormat}
                setSignatureFormat={setSignatureFormat}
            />
                {loadingCades && <Modal open={loadingCades} setOpen={() => {}}><LoadingSpinner /></Modal>}
                {openModalPinRequest && <ModalPinRequest open={openModalPinRequest}
                                                         setOpen={setOpenModalPinRequest}
                                                         type={'FEQ'}
                                                         confirm={doCadesSignature}
                                                         requestOtp={requestOtp}
                                                         otpFeqVisible={otpFeqVisible}
                                                         pendingRequest={otpRequestPending}

                />}</>
        case 'document':
            if(!signatureType || !file) {
                navigate(buildUrl('upload'))
                return (<LoadingSpinner/>)
            }else return(
                <>
                    <DocumentBuilder
                        processContextType={'SELF_SIGN'}
                        documentInfo={document} steps={steps} recipientsFields={[].concat(...Object.values(placeholders))}
                        selectedBlock={selectedBlock} selectedBlockId={selectedBlockId} resetSelectedBlock={resetSelectedBlock}
                        deleteBlock={() => {
                            dispatch(deleteBlock(selectedBlock))
                            resetSelectedBlock()
                        }}
                        handleChangeFile={(e) => handleChangeFile(e)}
                        nextPage={onClickNextInDocument}
                        previous={()=>navigate(-1)} goBackHome={() => navigate('/home')}
                        onAddBlock={onAddBlock} onSetBlock={onSetBlock} selectBlock={onSelectBlock} allBlocks={blocks}
                        buildUrl={buildUrl}
                        showTabletChoice={isGraphSignature}
                        listDevices={devices}
                        selectedDevice={selectedDevice}
                        setSelectedDevice={setSelectedDevice}
                    />
                    <ModalResetPlaceholders open={openModalResetPlaceholder} onClose={() => resetPlaceholders(false)} onConfirm={() => resetPlaceholders(true)}/>
                </>
            )
        case 'end':
            return <EndSendProcess illustration={documentSentIllustration} customMessage='Puoi firmare il documento sul tablet'/>
        default:
            navigate(buildUrl('upload'))
            return(<LoadingSpinner />)

    }
}

export default SelfSignDocument;
