import PageWithHeader from "../Templates/PageWithHeader/PageWithHeader";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import Paginator from "../../atoms/Paginator/Paginator";
import {useTranslation} from "react-i18next";
import {librarySelector} from "../../../features/library/library.slice";
import {getArchieve} from "../../../features/library/library.action";
import ArchieveList from "../../organisms/ArchieveList/ArchieveList";
import Input from "../../atoms/Inputs/Input/Input";
import IconButton from "../../atoms/Buttons/IconButton/IconButton";
import {MagnifyingGlassIcon} from "@heroicons/react/24/solid";

const ArchievePage = () => {
    const {t} = useTranslation()
    const dispatch = useDispatch();
    const {archieveList, archieveCount, listArchieveLoaded, loadingArchieve} = useSelector(librarySelector)
    const [filters, setFilters] = useState({pagination: {currentPage: 1, offset: 0, limit:10}, docId: '', refPerson: ''});
    const [docId, setDocId] = useState('');
    const [refPerson, setRefPerson] = useState('');


    useEffect(() => {
        if(!listArchieveLoaded) updateArchieve()
    },[listArchieveLoaded])

    useEffect(() => {
        const callbackTimer = setTimeout(updateArchieve, 300);
        return () => clearTimeout(callbackTimer);
    }, [filters])

    const updateArchieve = () => {
        dispatch(getArchieve(filters));
    }


    return(
        <PageWithHeader>
            <div className='flex w-full justify-center my-8'>
                <div className='flex flex-col flex-1 ml-4 '>
                    <div className='flex items-start mb-4 space-x-2' >
                        <div className='flex'>
                            <div>
                                <Input
                                    value={docId}
                                    setValue={setDocId}
                                    placeholder={'Doc ID'}
                                />
                            </div>
                            <div className='ml-3'>
                                <Input
                                    value={refPerson}
                                    setValue={setRefPerson}
                                    placeholder={t('archieve.refPerson')}
                                />
                            </div>
                            <div className='ml-3'>
                                <IconButton size='lg' label={t('archieve.search')} icon={<MagnifyingGlassIcon className='w-5 h-5'/>} variant='white'
                                            onClick={() => setFilters({...filters, pagination: {currentPage: 1, offset: 0, limit:10}, docId, refPerson})}/>
                            </div>
                        </div>
                    </div>

                    <div className='flex justify-center'>
                        <ArchieveList data={archieveList} updateData={updateArchieve} loading={loadingArchieve} filters={filters} setFilters={setFilters} />
                    </div>
                    <div className='flex flex-col flex-1'>
                        <Paginator pageSize={10} currentPage={filters.pagination.currentPage} totalResults={archieveCount} onClick={(pagination) => setFilters({...filters, pagination})}/>
                    </div>
                </div>
            </div>
        </PageWithHeader>
    )
}

export default ArchievePage;
