import Input from "../../atoms/Inputs/Input/Input";
import Button from "../../atoms/Buttons/Button/Button";
import confirmoLogo from '../../../assets/logos/confirmo-logo.svg'
import ErrorMessage from "../../atoms/ErrorMessage/ErrorMessage";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {resetTotp} from "../../../features/auth/auth.slice";

const Login2FaForm = ({totp, setTotp, verifyCredentials, loginError= null, loginErrorMessage}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const onSubmit = (e) => {
        e.stopPropagation();
        e.preventDefault()
        verifyCredentials()
    }

    const goBackToLogin = () => {
        dispatch(resetTotp())
    }

    return(
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md flex flex-col">
            <img src={confirmoLogo} alt='Confirmo' className='mb-10 h-12'/>
            <div className='my-2'>
                {loginError ?
                    <ErrorMessage>{loginErrorMessage}</ErrorMessage>
                    : <></>}
            </div>
            <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                <form onSubmit={onSubmit}>
                    <div className="space-y-6">
                        <Input
                            name='totp'
                            label={t('login.totp')}
                            placeholder=''
                            value={totp ?? ''}
                            setValue={setTotp}
                            autofocus={true}
                        />

                        <div className="h-10">
                            <Button
                                variant='blue'
                                label={t('proceed')}
                                size='full'
                                type='submit'
                                disabled={!totp}
                                onClick={onSubmit}
                            />
                        </div>
                    </div>
                </form>
                <div className='flex justify-center items-center pt-8'>
                    <Button
                        variant='white'
                        label={t('actions.cancel')}
                        size='full'
                        type='submit'
                        onClick={goBackToLogin}
                    />
                </div>
            </div>

        </div>
    )
}

export default Login2FaForm;
