import React from "react";

const SimplePage = ({children}) => {

    return(
        <div className='flex w-screen h-screen justify-center items-center'>
                {children}
        </div>
    )
}

export default SimplePage;
