import {ExclamationTriangleIcon} from "@heroicons/react/24/solid";

const ErrorMessage = ({children}) => {
    if(children)
        return(
            <div className='flex items-center bg-red-50 rounded p-1 flex-grow'>
                <div><ExclamationTriangleIcon className='w-5 text-red-600 mx-1' /></div>
                <p className='text-red-600 mx-1'>{children}</p>
            </div>
        )
    else
        return null
}

export default ErrorMessage;
