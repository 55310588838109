import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {countPersonActivity, getPersonActivity, peopleSelector} from "../../../../features/people/people.slice";

import FeedsTable from "../../../molecules/Tables/FeedsTable/FeedsTable";
import {CheckIcon} from "@heroicons/react/24/solid";
import Paginator from "../../../atoms/Paginator/Paginator";
import {useTranslation} from "react-i18next";
import {toLocaleString} from "../../../../utils/helper";

const PersonDetailStoricoAttivita = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const {personActivity, personActivityCount, currentPerson} = useSelector(peopleSelector)
    const [pagination, setPagination] = useState({currentPage: 1, offset: 0, limit:10});
    const id = currentPerson.id;

    useEffect(() => {
        dispatch(getPersonActivity({id, pagination}));
        dispatch(countPersonActivity({id}));
    }, [pagination])

    const completeAuditWithExtraData = (d) => {
        if(d.auditType === 'OPEN_ON_TABLET' && d.extradata) {
            try {
                const obj = JSON.parse(d.extradata)
                if (obj?.device?.label) {
                    return ' - ' + obj.device.label
                }
            } catch (e) {}
            return ''
        }
        return ''
    }

    const completeWithProcessName = (d) => {
        if (d.event?.eventFlow?.name) {
            return d.event.eventFlow.name + ' - ';
        }
        return '';
    }

    const mapDataToFeeds = function(data) {
        return data.map(d => {
            const date = toLocaleString(d.auditDate);
            return {
                id: d.id,
                content: completeWithProcessName(d) + t('userAuditTypes.' + d.auditType) + completeAuditWithExtraData(d),
                date: date,
                datetime: date,
                icon: CheckIcon,
                iconBackground: 'bg-gray-400'
            };
        });
    };

    return(
        <div className="h-full m-10" aria-hidden="true" >
            <FeedsTable data={mapDataToFeeds(personActivity)}/>
            <div className='flex flex-col flex-1'>
                <Paginator pageSize={10} currentPage={pagination.currentPage} totalResults={personActivityCount} onClick={setPagination}/>
            </div>
        </div>
    )
}

export default PersonDetailStoricoAttivita;
