import {checkPermissions, classNames} from "../../../utils/helper";
import {useTranslation} from "react-i18next";
import { Disclosure } from '@headlessui/react'
import {Link} from "react-router-dom";
import Icon from "../../atoms/Icon/Icon";

const VerticalNavigation = ({navigation, section='', baseurl, permissions={}}) => {
    const { t } = useTranslation();

    return (
        <nav className="space-y-1 w-72 mx-2" aria-label="Sidebar">
            {navigation.flatMap(item => {
                const current = item.href === section;
                if(!item.show || checkPermissions(permissions, item.show))
                    return (
                        !item.children ? <Link
                                key={item.name}
                                to={`/${baseurl}${item?.href ? '/'+ item.href: ''}`}
                                className={classNames(
                                    current ? 'bg-blue-50 text-blue-600 hover:bg-blue-100' : 'text-gray-600 hover:bg-gray-50',
                                    'flex items-center justify-between px-3 py-2 text-sm font-medium rounded-md'
                                )}
                                aria-current={current ? 'page' : undefined}
                            >
                                <span className="truncate">{t(item.name)}</span>
                                {item.deletable && <Icon size='4' type='delete' onClick={(e) => item?.onClick(e, item.id)}/>}
                            </Link> :
                            <Disclosure as="div" key={item.name} className="space-y-1">
                                {({ open }) => (
                                    <>
                                        <Disclosure.Button
                                            className={classNames(
                                                current ? 'bg-blue-50 text-gray-600 hover:bg-blue-100' : 'text-gray-600 hover:bg-gray-50',
                                                'flex justify-between w-full px-3 py-2 text-sm font-medium rounded-md'
                                            )}
                                        >
                                            <span>{t(item.name)}</span>
                                            <svg
                                                className={classNames(
                                                    (open || current) ? 'text-gray-400 rotate-90' : 'text-gray-300',
                                                    'ml-3 flex-shrink-0 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150'
                                                )}
                                                viewBox="0 0 20 20"
                                                aria-hidden="true"
                                            >
                                                <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
                                            </svg>
                                        </Disclosure.Button>
                                        {(open || current) && <Disclosure.Panel className="space-y-1" static>
                                            {item.children.map((subItem) => (
                                                <Link
                                                    key={subItem.name}
                                                    to={`/${baseurl}/${item.href}`}
                                                    className={classNames(
                                                        current ? 'bg-blue-50 text-blue-600 hover:bg-blue-100' : 'text-gray-600 hover:text-gray-900 hover:bg-gray-50',
                                                        'group w-full flex items-center pl-11 pr-2 py-2 text-sm font-medium text-gray-600 rounded-md'
                                                    )}
                                                    aria-current={current ? 'page' : undefined}
                                                >
                                                    {subItem.name}
                                                </Link>
                                            ))}
                                        </Disclosure.Panel>}
                                    </>
                                )}
                            </Disclosure>
                    )
                else return []
            })}
        </nav>
    )
}

export default VerticalNavigation;
