import SimpleTable from "../../../molecules/Tables/SimpleTable/SimpleTable";
import {v4} from "uuid";
import {CHECK, DATE, DROPDOWN_ICON, TRANSLATELABEL,} from "../../../atoms/SimpleTableElement/SimpleTable.types";
import React, {useEffect} from "react";
import {getGraphIdentificationThunk, peopleSelector} from "../../../../features/people/people.slice";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {downloadPDF} from "../../../../api/library";
import emptyState from '../../../../assets/illustrations/6_Illustrazione_Confirmo_Empty-State.svg'
import isEmpty from "lodash/isEmpty";
import {userHomeSelector} from "../../../../features/userHome/userHome.slice";

const GraphAgreementList = () => {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const {graphAgreements, currentPerson} = useSelector(peopleSelector)
    const { selectedTenantId, userOrganizations } = useSelector(userHomeSelector);
    const enableIdDocNumber = userOrganizations?.[selectedTenantId]?.enableIdDocNumber ?? false;
    const columns = [
        {field: 'created', type: DATE, label: t('tableColumns.date'), isSortable: true, hidden: false, id: v4()},
        {field: 'consentGrafometria',label: t('contact.graph-agreements'), type: CHECK, isSortable: false, hidden: false, id: v4()},
        {field: 'consentPersonalData',label: t('contact.personalData-agreement'), type: CHECK, isSortable: false, hidden: false, id: v4()},
        {field: 'consentConfirmo',label: t('contact.confirmo-agreement'), type: CHECK, isSortable: false, hidden: false, id: v4()},
        {field: 'documents', label: 'documents', type: DROPDOWN_ICON, isSortable: false, hidden: true, id: v4(),
            dropdownOptions: [
                {id: 'urlConsent', label: t('contact.urlAgreement')},
                {id: 'urlDocIdFront', label: t('contact.urlDocIdFront')},
                {id: 'urlDocIdBack', label: t('contact.urlDocIdBack')}
            ],
            onClick: (data, option) => {
                if(option?.id && data[option?.id]) downloadPDF(data[option.id])
            }
        },
    ]

    if (enableIdDocNumber) {
        columns.splice(4, 0, {field: 'docIdExpireAt',label: t('general.docIdExpireAt'), type: DATE, isSortable: false, hidden: false, id: v4()});
        columns.splice(4, 0, {field: 'docIdReleasedAt',label: t('general.docIdReleasedAt'), type: DATE, isSortable: false, hidden: false, id: v4()});
        columns.splice(4, 0, {field: 'docIdNumber',label: t('general.docIdNumber'), isSortable: false, hidden: false, id: v4()});
        columns.splice(4, 0, {field: 'docIdType',label: t('general.docIdType'), traslatePrefix: 'general.typeDoc_', type: TRANSLATELABEL, isSortable: false, hidden: false, id: v4()});
    }


    useEffect(() => {
        if(currentPerson?.id) dispatch(getGraphIdentificationThunk({id: currentPerson.id}))
    },[currentPerson])

    if(isEmpty(graphAgreements[currentPerson?.id]))
        return (
            <div className='w-full flex justify-center'>
            <div className='flex flex-col justify-center items-center max-w-sm flex-1 my-10'>
                <img src={emptyState} alt='empty state'/>
                <h1 className='text-2xl leading-8 font-medium pr-1'>{t('nothingToSee')}</h1>
                </div>
            </div>
        )
    return(
        <SimpleTable columns={columns} loading={false} data={graphAgreements[currentPerson?.id]} />
    )
}

export default GraphAgreementList;
