import PageWithStepsHeaderAndFooter from "../../Templates/PageWithStepsHeaderAndFooter/PageWithStepsHeaderAndFooter";
import TabSwitcher from "../../../atoms/TabSwitcher/TabSwitcher";
import React, {useEffect, useState} from "react";
import RecipientsInformativa from "../../../molecules/RecipientsInformativa/RecipientsInformativa";
import InformativaInfo from "../../../molecules/InformativaInfo/InformativaInfo";
import Dropdown from "../../../atoms/Dropdowns/Dropdown/Dropdown";
import {useDispatch, useSelector} from "react-redux";
import {
    addInformativaStepThunk,
    addInformativaThunk,
    deleteInformativaThunk,
    getInformativeThunk,
    getStepsTypes,
    updateInformativaThunk,
    updateStepInformativaThunk,
    uploadStepInformativaPhotoThunk
} from "../../../../features/documentalProcess/documentalProcess.actions";
import isEmpty from "lodash/isEmpty";
import ListInformative from "../../../organisms/ListInformative/ListInformative";
import {setLinkedRecipientsToInformativeThunk} from "../../../../features/recipients/recipients.actions";
import {checkLinkedInformative, recipientsSelector} from "../../../../features/recipients/recipients.slice";
import SidebarInformedPath from "../../../molecules/SidebardInformedPath/SidebarInformedPath";
import {useNavigate} from "react-router-dom";
import ModalSelectPathItemTemplates
    from "../../../molecules/Modals/ModalSelectPathItemTemplates/ModalSelectPathItemTemplates";
import {librarySelector} from "../../../../features/library/library.slice";
import EmptyStateIllustration from "../../../../assets/illustrations/6_Illustrazione_Confirmo_Empty-State.svg";
import {useTranslation} from "react-i18next";
import ModalNewInformedPath from "../../../molecules/Modals/ModalNewPathItem/ModalNewInformedPath/ModalNewInformedPath";
import {addTagThunk, deleteTagThunk} from "../../../../features/userHome/userHome.actions";
import {countInformedPaths, getInformedPaths} from "../../../../features/library/library.action";

const Informativa = ({infoId = '', informative, stepsTypes, recipients, previous, next, errorVideo, imageLoading, steps, isLoading = false, buildUrl=()=>{}}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [selectedInfoId, setSelectedInfoId] = useState(infoId);
    const [informativaName, setInformativaName] = useState('')
    const [informativaDescription, setInformativaDescription] = useState('')
    const {allInformativeLinked} = useSelector(recipientsSelector)
    const {informedPaths, informedPathsCount} = useSelector(librarySelector)
    const [tags, setTags] = useState([]);
    const [selectedCardId, setSelectedCardId] = useState(null)
    const [openModal, setOpenModal] = useState(false)
    const [openNewInfo, setOpenNewInfo] = useState(false)
    const {t} = useTranslation()

    const AddInformativaButton = ({addTemplate}) => <Dropdown label={t('informedPath.add')} variant='blue' options={[{label: t('library.newInformedPath'), onclick: () => setOpenNewInfo(true)}, {label: t('fromTemplate'), onclick: addTemplate}]} />

    useEffect(() => {
        const init = async () => {
            dispatch(getStepsTypes())
            await dispatch(getInformativeThunk({}))
            dispatch(checkLinkedInformative())
            //if(isEmpty(informative)) addInformativa();
        }
        init();
        return () => dispatch(setLinkedRecipientsToInformativeThunk({}))
    },[])

    useEffect(() => {
        if(Object.keys(informative).some(id => id === infoId)) setSelectedInfoId(infoId)
    },[infoId])

    useEffect(() => {
        if((!isEmpty(informative) && (isEmpty(selectedInfoId))) || !Object.keys(informative).find(id => id === selectedInfoId)) {
            setSelectedInfoId(Object.keys(informative)[0])
        }
    },[informative, selectedInfoId])

    useEffect(() => {
        const newInformativa = !isEmpty(selectedInfoId) ? informative[selectedInfoId] : null;
        if(newInformativa) {
            setInformativaName(newInformativa.name ?? '')
            setInformativaDescription(newInformativa.description ?? '')
            setTags(newInformativa?.tags ?? [])
        }
    },[selectedInfoId])

    useEffect(() => {
        const callbackTimer = setTimeout(updateInformativa, 2000);
        return () => clearTimeout(callbackTimer);
    },[informativaName, informativaDescription, tags])

    const updateInformativa = async () => {
        const actualInfo = informative[selectedInfoId]
        if(actualInfo && (actualInfo.name !== informativaName || actualInfo.description !== informativaDescription || actualInfo.tags !== tags)) {
            await dispatch(updateInformativaThunk({
                informativaId: selectedInfoId,
                data: {
                    name: informativaName ? informativaName : '',
                    description: informativaDescription ? informativaDescription : '',
                    tags
                }
            }))
        }
    }

    const nextStep = async () => {
        await updateInformativa();
        next()
    }

    const previousStep = async () => {
        await updateInformativa();
        previous()
    }

    const selectInformativa = (id) => {
        updateInformativa()
        setSelectedInfoId(id);
    }

    const stepsTypesFormatted = stepsTypes ?
        stepsTypes.map(step => {
            return {
                label: step.description,
                onclick: () => dispatch(addInformativaStepThunk({id: selectedInfoId, name: step.description, detailTypeId: step.id}))
            }
        }) :
        []

    const addNewInformativa = (name, description, tags, selectedIds) => {
        dispatch(addInformativaThunk({name, description, tags, selectedRecipientsIds: selectedIds}))
    }

    const addInformativa = async (id) => {
        await dispatch(addInformativaThunk({id}))
    }

    const selectedInformativa = informative?.[selectedInfoId] ?? null;

    const updateStepInformativa = (data, informativaId) => {
        dispatch(updateStepInformativaThunk({data, informativaId}))
    }

    const handleChangePhoto = (event, stepId, informativaId) => {
        const image = event.target.files[0];
        dispatch(uploadStepInformativaPhotoThunk({stepId, image, informativaId}))
    }

    const getInformativeTemplatesPaginated = (searchTerm, currentPage, offset, limit) => {
        dispatch(getInformedPaths({txtFilter: searchTerm, pagination: {currentPage, offset, limit}}));
        dispatch(countInformedPaths({txtFilter: searchTerm}));
    }

    const goBackHome= () => {
        navigate('/home')
    }

    const switchTab = (infoId) => {
        setSelectedCardId(null)
        selectInformativa(infoId)
    }

    const insertTag = (tag) => dispatch(addTagThunk({tag}))
    const deleteTag = (tag) => dispatch(deleteTagThunk({tag}))

    return (
        <PageWithStepsHeaderAndFooter background={false} footer={true} next={nextStep} previous={previousStep} disableConfirm={!allInformativeLinked || isLoading} steps={steps} closeAction={goBackHome} buildUrl={buildUrl}>
            <div className='flex max-w-7xl w-full h-full pt-8 items-start space-x-8'>
                {selectedInfoId ?
                    <>
                        <div className='flex flex-col w-2/3 h-full items-start overflow-hidden'>
                            <div className='flex w-full justify-start items-start my-3 space-x-3 h-10 overflow-x-auto overflow-y-hidden'>
                                {informative && Object.values(informative).map(info => <TabSwitcher key={info.id}
                                                                                                    label={info.name}
                                                                                                    selected={info.id === selectedInfoId}
                                                                                                    onClick={() => switchTab(info.id)}
                                                                                                    onDelete={() => dispatch(deleteInformativaThunk({informativaId: info.id}))}/>)}
                            </div>
                            <ListInformative informativa={selectedInformativa} stepsTypesFormatted={stepsTypesFormatted}
                                             selectedInfoId={selectedInfoId} onCardClick={setSelectedCardId}
                                             disabled={!selectedInfoId}/>
                        </div>
                        <div className='flex flex-col w-1/3 max-h-full'>
                            <div className='flex justify-end items-center my-3'>
                                <AddInformativaButton addNewInformativa={() => setOpenNewInfo(true)} addTemplate={() => setOpenModal(true)}/>
                            </div>
                            {selectedCardId && selectedInformativa ?
                                <SidebarInformedPath
                                    step={selectedInformativa.details.find(step => step.id === selectedCardId)}
                                    updateStep={updateStepInformativa}
                                    informativaId={selectedInfoId}
                                    handleChangePhoto={handleChangePhoto}
                                    errorVideo={errorVideo}
                                    isLoading={imageLoading}
                                    onClose={() => setSelectedCardId(null)}
                                    allSteps={selectedInformativa.details}

                                /> :
                                <>
                                    <div className='flex flex-col w-full mt-3 border-b'>
                                        <RecipientsInformativa recipients={recipients}
                                                               selectedInformativa={selectedInfoId}
                                                               disabled={!selectedInfoId}/>
                                    </div>
                                    <div className='flex flex-1 flex-col w-full h-full'>
                                        <InformativaInfo
                                            name={informativaName}
                                            setName={setInformativaName}
                                            description={informativaDescription}
                                            setDescription={setInformativaDescription}
                                            tags={tags}
                                            setTags={setTags}
                                            disabled={!selectedInfoId}
                                            insertTag={insertTag}
                                            deleteTag={deleteTag}
                                            hasDelete={true}
                                        />
                                    </div>
                                </>
                            }
                        </div>
                    </> :
                    <div className='flex flex-col items-center justify-center w-full h-full px-8 pb-36'>
                        <img src={EmptyStateIllustration} className='max-w-xl w-full h-full' alt='empty state'/>
                        <h1 className='text-2xl leading-8 font-medium pr-1 text-center mb-4'>{t('informedPath.emptyMessage')}</h1>
                        <AddInformativaButton addNewInformativa={addInformativa} addTemplate={() => setOpenModal(true)}/>
                    </div>
                }
            </div>
            {openNewInfo && <ModalNewInformedPath open={openNewInfo} setOpen={setOpenNewInfo} recipients={recipients}
                                   onCreate={addNewInformativa} insertTag={insertTag} deleteTag={deleteTag} hasDelete={true}/>}
            <ModalSelectPathItemTemplates
                open={openModal}
                setOpen={setOpenModal}
                onSelect={addInformativa}
                getElementsPaginated={getInformativeTemplatesPaginated}
                elements={informedPaths}
                countElements={informedPathsCount}
            />
        </PageWithStepsHeaderAndFooter>
    )
}

export default Informativa;
