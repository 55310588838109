import {Dialog} from "@headlessui/react";
import React, {useEffect, useState} from "react";
import Select from "../../../atoms/Select/Select";
import {ADMIN, OWNER, SIMPLE} from "../../../../features/userHome/ROLES";
import {useTranslation} from "react-i18next";
import Toggle from "../../../atoms/Toggle/Toggle";
import ModalOnlyClose from "../Templates/ModalOnlyClose";
import {useSelector} from "react-redux";
import {userHomeSelector} from "../../../../features/userHome/userHome.slice";

const ROLES = [{label: OWNER, value: OWNER},{label: ADMIN, value: ADMIN},{label: SIMPLE, value: SIMPLE}]

const ModalEditRole = ({open, setOpen, onConfirm, selectedMember, allWorkspacesInfo, onChangeWorkspace, hasWorkspacePermission}) => {
    const [selectedRole, setSelectedRole] = useState()
    const {t} = useTranslation()

    const canChangeRole = selectedMember && !selectedMember?.isBlocked

    const { selectedTenantId, userOrganizations } = useSelector(userHomeSelector);
    const tenantRoles = userOrganizations?.[selectedTenantId]?.roles ?? ROLES

    useEffect(() => {
        if(tenantRoles.map(r => r.value).includes(selectedMember?.role))
            setSelectedRole(selectedMember?.role)
        else setSelectedRole()
    },[open])

    const changeRole = () => {
        if(canChangeRole) onConfirm(selectedRole)
        setOpen(false)
    }

    return (
        <ModalOnlyClose open={open} setOpen={setOpen} onClose={changeRole}>
            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full ">
                <Dialog.Title as="h1" className="text-xl leading-6 font-medium mb-4">
                    {selectedMember?.name}
                </Dialog.Title>
                <div className='space-y-4'>
                    {
                        selectedMember?.remoteSignerId ?
                            <dd className="text-gray-500 text-xs leading-4 font-normal text-justify">ID FEQ: {selectedMember?.remoteSignerId}</dd> : <></>
                    }
                    {canChangeRole && <div className='mb-2'>
                        <h1 className="text-lg leading-6 font-medium mb-2">
                            {t('manageusers.selectRole')}
                        </h1>

                        <div className="mt-2">
                            <Select options={tenantRoles} value={selectedRole} setValue={setSelectedRole}
                                    placeholder={t('manageusers.selectRole')}/>
                        </div>
                    </div>}

                    {hasWorkspacePermission &&
                        <div className="mt-2 space-y-2">
                            <h1 className="text-lg leading-6 font-medium mb-2">{t('manageusers.selectWorkspaces')}</h1>
                            {allWorkspacesInfo?.map(ws => {
                                return (<div className='grid grid-cols-2 space-y-0.5' key={ws?.id}>
                                    <p className="text-sm leading-5 font-normal">{ws?.name}</p>
                                    <Toggle value={ws?.users?.map(u => u.id)?.includes(selectedMember?.userId)} key={ws?.id}
                                            setValue={(value) => {
                                                onChangeWorkspace(value, ws?.id)
                                            }}/>
                                </div>)
                            })}
                        </div>
                    }
                </div>
            </div>
        </ModalOnlyClose>
    )
}

export default ModalEditRole;
