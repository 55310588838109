import PageWithHeader from "../Templates/PageWithHeader/PageWithHeader";
import VerticalNavigation from "../../molecules/VerticalNavigation/VerticalNavigation";
import {Navigate, useParams} from "react-router-dom";
import React from "react";
import {useSelector} from "react-redux";
import {navigationSelector} from "../../../features/navigation/navigation.slice";
import LibraryBody from "../../organisms/LibraryBody/LibraryBody";

const LibraryPage = () => {
    const {section} = useParams()
    const {librarySections} = useSelector(navigationSelector)

    if(!section)
        return <Navigate to='/Libreria/eventflows' />
    else return(
        <PageWithHeader>
            <div className='flex w-full justify-between py-6'>
                <div className='mt-1'>
                    <VerticalNavigation navigation={librarySections} section={section} baseurl='libreria' />
                </div>
                <div className='flex w-full'>
                    <LibraryBody section={section}  />
                </div>
            </div>
        </PageWithHeader>
    )
}

export default LibraryPage;
