import {Dialog} from "@headlessui/react";
import React, {useEffect, useState} from "react";
import Select from "../../../atoms/Select/Select";
import {ADMIN, OWNER, SIMPLE} from "../../../../features/userHome/ROLES";
import {useTranslation} from "react-i18next";
import Toggle from "../../../atoms/Toggle/Toggle";
import ModalOnlyClose from "../Templates/ModalOnlyClose";
import {useSelector} from "react-redux";
import {userHomeSelector} from "../../../../features/userHome/userHome.slice";
import ModalGrayFooter from "../Templates/ModalGrayFooter";

const ModalEditPublish = ({open, setOpen, onConfirm, eventFlowType}) => {
    const [published, setPublished] = useState(eventFlowType?.published)
    const {t} = useTranslation()

    const publishedOptions = [
        { label: t('documentalProcess.intro.publishedYes'), value: 'Y' },
        { label: t('documentalProcess.intro.publishedNo'), value: 'N' },
    ]

    useEffect(() => {
        setPublished(eventFlowType?.published);
    },[open])

    const onSave = () => {
        setOpen(false);
        onConfirm(published);
    }

    return (
        <ModalGrayFooter open={open} setOpen={setOpen} onConfirm={onSave} confirmLabel={t('save')} >
            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full ">
                <Dialog.Title as="h1" className="text-xl leading-6 font-medium mb-4">
                    {eventFlowType?.name}
                </Dialog.Title>
                <div className='space-y-4'>
                    <Select value={published} label={t('documentalProcess.intro.published')} setValue={setPublished} options={publishedOptions}/>
                </div>
            </div>
        </ModalGrayFooter>
    )
}

export default ModalEditPublish;
