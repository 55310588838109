import {Disclosure} from '@headlessui/react'
import {XMarkIcon} from '@heroicons/react/24/outline'
import {classNames} from "../../../../utils/helper";
import confirmoLogo from '../../../../assets/logos/confirmo-logo.svg';
import Select from "../../../atoms/Select/Select";
import ProfileDropdown from "../ProfileDropdown/ProfileDropdown";
import MobileMenu from "../MobileMenu/MobileMenu";
import {useTranslation} from "react-i18next";
import {navigationSelector, setSelectedTabName} from "../../../../features/navigation/navigation.slice";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect} from "react";
import {resetMembersList, userHomeSelector} from "../../../../features/userHome/userHome.slice";
import {resetPeopleList} from "../../../../features/people/people.slice";
import DropdownLanguage from "../../../atoms/Dropdowns/DropdownLanguage/DropdownLanguage";
import {librarySelector, resetLibrary} from "../../../../features/library/library.slice";
import ModalLastNotifications from "../../Modals/ModalLastNotifications/ModalLastNotifications";
import {authSelector} from "../../../../features/auth/auth.slice";
import {getCurrentUser} from "../../../../features/auth/auth.actions";
import {Bars3Icon} from "@heroicons/react/20/solid";
import {
    changeSelectedTenantThunk,
    retrieveOrganizationDetailsThunk
} from "../../../../features/userHome/userHome.actions";
import {getDevices, getRemoteSignatureUser} from "../../../../features/library/library.action";


const NavbarWithTenants = ({tenants}) => {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const { selectedTabName, tabs } = useSelector(navigationSelector);
    const { user } = useSelector(authSelector)
    const { selectedTenantId, userOrganizations } = useSelector(userHomeSelector);
    const { remoteSignatureUser } = useSelector(librarySelector);
    const permissions = userOrganizations?.[selectedTenantId]?.permissions ?? {}

    const filteredTabs = tabs.filter(t => (!t.show || permissions[t.show]))

    useEffect(() => {
        if(!user.id) {
            dispatch(getCurrentUser());
        }

        if (user && !remoteSignatureUser && user.remoteSignerId) {
            dispatch(getRemoteSignatureUser({idUtenteFirma: user.remoteSignerId}));
        }
    }, [user])

    const selectMenuOption = (selectedValue) => {
        navigate(`/${selectedValue}`);
        dispatch(setSelectedTabName(selectedValue))
    }

    const setSelectedTenantId = async (value) => {
        //dispatch(changeSelectedTenant({value}));
        await dispatch(changeSelectedTenantThunk({userOrganizationRoleId: value})).unwrap();
        await dispatch(retrieveOrganizationDetailsThunk({userOrganizationRoleId: value})).unwrap();
        dispatch(resetPeopleList({}));
        dispatch(resetLibrary({}));
        dispatch(resetMembersList())
        dispatch(getDevices());
    }

    const {t} = useTranslation();

    return (
        <Disclosure as="nav" className="bg-white shadow">
            {({ open }) => (
                <>
                    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                        <div className="flex justify-between h-16">
                            <div className="flex">
                                <div className="-ml-2 mr-2 flex items-center md:hidden">
                                    {/* Mobile menu button */}
                                    <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500">
                                        <span className="sr-only">Open main menu</span>
                                        {open ? (
                                            <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                                        ) : (
                                            <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                                        )}
                                    </Disclosure.Button>
                                </div>
                                <div className="flex-shrink-0 flex items-center cursor-pointer" onClick={() => navigate('/home')}>
                                    <img
                                        className="block h-8 w-auto"
                                        src={confirmoLogo}
                                        alt="Confirmo"
                                    />
                                </div>
                                <div className="hidden md:ml-6 md:flex md:space-x-8">
                                    {/* Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}

                                    {filteredTabs.map(
                                        tab => <div
                                            key={tab.name}
                                            onClick={() => selectMenuOption(tab.name)}
                                            className={classNames(tab.name === selectedTabName ?
                                                    'border-blue-500 text-gray-900' :
                                                    'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                                                `inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium cursor-pointer`)}
                                        >
                                            {t(`navbar.${tab.name}`)}
                                        </div>)}

                                </div>
                            </div>
                            <div className="flex items-center">
                                <div className="flex-shrink-0 px-4">
                                    <Select
                                        options={tenants}
                                        value={selectedTenantId ? selectedTenantId : (tenants.length > 0 ? tenants[0].id : null)}
                                        setValue={setSelectedTenantId}
                                    />
                                </div>
                                <DropdownLanguage />
                                <div className="hidden md:ml-4 md:flex-shrink-0 md:flex md:items-center">
                                    <ModalLastNotifications />
                                    <ProfileDropdown />
                                </div>
                            </div>
                        </div>
                    </div>

                    <MobileMenu tabs={filteredTabs} selectMenuOption={selectMenuOption} selectedTabName={selectedTabName} user={user}/>

                </>
            )}
        </Disclosure>
    )
}
export default NavbarWithTenants;
