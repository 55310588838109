import {classNames} from "../../../../utils/helper";

const colors = {
    gray: 'bg-gray-100 border-gray-200 text-gray-800 hover:bg-gray-200 focus:bg-gray-500 focus:outline-none',
    red: 'bg-red-100 border-red-200 text-red-800 hover:bg-red-200 focus:bg-red-500 focus:outline-none',
    yellow: 'bg-yellow-100 border-yellow-200 text-yellow-800 hover:bg-yellow-200 focus:bg-yellow-500 focus:outline-none',
    green: 'bg-green-100 border-green-200 text-green-800 hover:bg-green-200 focus:bg-green-500 focus:outline-none',
    blue: 'bg-blue-100 border-blue-200 text-blue-900 hover:bg-blue-200 focus:bg-blue-500 focus:outline-none',
    indigo: 'bg-indigo-100 border-indigo-200 text-indigo-800 hover:bg-indigo-200 focus:bg-indigo-500 focus:outline-none',
    purple: 'bg-purple-100 border-purple-200 text-purple-800 hover:bg-purple-200 focus:bg-purple-500 focus:outline-none',
    pink: 'bg-pink-100 border-pink-200 text-pink-800 hover:bg-pink-200 focus:bg-pink-500 focus:outline-none',
}

const colorsSelected = {
    gray: 'bg-gray-400 hover:bg-gray-400 border-gray-500',
    red: 'bg-red-400 hover:bg-red-400 border-red-500',
    yellow: 'bg-yellow-400 hover:bg-yellow-400 border-yellow-500',
    green: 'bg-green-400 hover:bg-green-400 border-green-500',
    blue: 'bg-blue-400 hover:bg-blue-400 border-blue-500',
    indigo: 'bg-indigo-400 hover:bg-indigo-400 border-indigo-500',
    purple: 'bg-purple-400 hover:bg-purple-400 border-purple-500',
    pink: 'bg-pink-400 hover:bg-pink-400 border-pink-500'
}

const DraggableBadge = ({label, color = 'blue', onClick, selected=false, fontSize, fontFamily}) => {
    const fontSizeStyle = fontSize>=6 && fontSize<=60 ? fontSize : 10
    return (
        <div onMouseDown={onClick} onTouchStart={onClick} className={classNames(selected ? `${colorsSelected[color]}` : '', `flex justify-center items-center px-2.5 py-0.5 rounded-full min-w-0 w-full font-medium h-full bg-opacity-80 border hover:bg-opacity-80 ${colors[color]} z-50`)}>
            <p className='truncate' style={{'fontSize': `${fontSizeStyle}px`, 'fontFamily': fontFamily}}>{label}</p>
        </div>
    )
}

export default DraggableBadge;
