import UserSelectionButton from "../../atoms/UserSelectionButton/UserSelectionButton";
import {useTranslation} from "react-i18next";

const UserSelectionList = ({users = [], onClick=()=>{}, selectedUserId }) => {
    const {t} = useTranslation();
    return(
        <div className='flex flex-col flex-grow'>
            <h1 className='text-lg leading-6 font-medium mb-4'>{t('reorder.users')}</h1>
            <div className='space-y-2'>
                {users.map(user => <UserSelectionButton key={user.id} label={user.name} selected={user.id === selectedUserId} onClick={() => onClick(user.id)}/>)}
            </div>
        </div>
    )
}

export default UserSelectionList;
