import RoundButtons from "../../atoms/Buttons/RoundButtons/RoundButtons";
import DisclosureItem from "../../atoms/Disclosure/DisclosureItem";
import isEmpty from "lodash/isEmpty";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import SingleCheckbox from "../../atoms/SingleCheckbox/SingleCheckbox";
import Icon from "../../atoms/Icon/Icon";
import {useEffect, useMemo, useState} from "react";
import InputUrl from "../../atoms/Inputs/InputUrl/InputUrl";
import ModalDisplayUsersInList from "../Modals/ModalDisplayUsersInList/ModalDisplayUsersInList";
import {useSelector} from "react-redux";
import {userHomeSelector} from "../../../features/userHome/userHome.slice";

const SummarySidebar = ({recipients, documentTitle, processList, dataCollections, informative, setRedirectLink, setHasGraphConsentToRecipient, setNoEmailSent, setNoEmailSignedSent, avoidLtArchive, setAvoidLtArchive, editAvoidLtArchiveChoice, isConcatenation=false}) => {
    const navigate = useNavigate()
    const {t} = useTranslation()
    const [showInputRedirect, setShowInputRedirect] = useState(Object.values(recipients).some(rec => !isEmpty(rec.redirectLink)))
    const [openUsersInListModal, setOpenUsersInListModal] = useState(false)

    const { selectedTenantId, userOrganizations } = useSelector(userHomeSelector);
    const showNoEmailFlagsForRemoteSignature = userOrganizations?.[selectedTenantId]?.showNoEmailFlagsForRemoteSignature ?? false;
    const enableAvoidLtArchive = userOrganizations?.[selectedTenantId]?.enableAvoidLtArchive ?? false;

    const informativeFileredGraphKyc = useMemo(() => {
        return informative?.filter(info => info?.id !== 'graph-kyc')
    },[informative])

    useEffect(() => {
        if(!showInputRedirect) Object.values(recipients).forEach(rec => setRedirectLink(rec.id, null))
    },[showInputRedirect])

    return(
        <div className='flex flex-col flex-grow h-full bg-gray-50 px-6 py-4 overflow-y-auto'>
            <div className='divide-y'>
                {!isEmpty(recipients) && <div>
                    <DisclosureItem
                        head={<h1 className='text-lg leading-6 font-medium text-gray-900'>{t('summary.recipients')}</h1>}
                    >
                        {Object.values(recipients).map((rec,idx) => {
                            return(
                                <div className='bg-white rounded-lg my-1 p-4 divide-y space-y-4' key={rec.id}>
                                    <div className='flex items-center w-full'>
                                    <span
                                        className="w-6 h-6 mr-4 flex items-center justify-center border-2 rounded-full">
                                        <span className='text-xs leading-4 font-normal text-gray-500'>{idx + 1}</span>
                                    </span>
                                        <div className='w-full'>
                                            <div className='flex justify-between items-center w-full'>
                                                <p className='text-sm leading-5'>{rec.name}</p>
                                                {!isEmpty(rec?.users) && <>
                                                    <Icon type='details' size={5} color='gray' onClick={() => setOpenUsersInListModal(true)}/>
                                                    <ModalDisplayUsersInList users={rec?.users} listName={rec.listName} open={openUsersInListModal} setOpen={setOpenUsersInListModal}/>
                                                </>}
                                            </div>
                                            <p className='text-gray-500 text-sm leading-4'>{rec.email}</p>
                                            {(rec?.signatureType === 'GRAPH' || showNoEmailFlagsForRemoteSignature) &&
                                                <div className='py-1'>
                                                    <SingleCheckbox value={rec?.emailDetails?.noEmail ?? false}
                                                                    setValue={(value) => setNoEmailSent(rec.id, value)}
                                                                    label={t('summary.noEmail')}/>
                                                </div>}
                                            {(rec?.signatureType === 'GRAPH' || showNoEmailFlagsForRemoteSignature) &&
                                                <div className='py-1'>
                                                    <SingleCheckbox value={rec?.emailDetails?.noEmailSigned ?? false}
                                                                    setValue={(value) => setNoEmailSignedSent(rec.id, value)}
                                                                    label={t('summary.noEmailSigned')}/>
                                                </div>}
                                            {(rec?.graphConsentModelId && rec?.signatureType === 'GRAPH') ? <>
                                                <p className='text-gray-500 text-sm leading-4'>{t('summary.last-identification')} {rec?.lastIdentificationDate ?? '-'}</p>
                                                <SingleCheckbox value={rec?.hasGraphConsent ?? false}
                                                                setValue={(value) => setHasGraphConsentToRecipient(rec.id, value)}
                                                                label={t('summary.send-consent')}/>
                                            </> : <></>}

                                        </div>
                                    </div>
                                    {showInputRedirect &&
                                        <div className='flex space-x-2 align-bottom pt-4'>
                                            <label
                                                className="flex-2 text-sm font-medium text-gray-700 self-center w-fit">
                                                {t('summary.redirectLink')}
                                            </label>
                                            <div className='w-full flex-1'>
                                                <InputUrl value={rec?.redirectLink ?? ''}
                                                          setValue={(value) => setRedirectLink(rec.id, value)}/>
                                            </div>
                                        </div>
                                    }
                                </div>
                            )
                        })}
                        <div className='flex justify-end mt-2 mr-2'>
                            <div className='flex justify-end items-center space-x-1 cursor-pointer w-fit text-gray-400' onClick={() => setShowInputRedirect(!showInputRedirect)}>
                                {showInputRedirect ?
                                    <>
                                        <Icon type='minus-circle' size={6}/>
                                        <p className='text-sm'>{t('summary.cancelRedirectLink')}</p>
                                    </> :
                                    <>
                                        <Icon type='plus-circle' size={6}/>
                                        <p className='text-sm'>{t('summary.addRedirectLink')}</p>
                                    </>

                                }
                            </div>
                        </div>
                    </DisclosureItem>
                </div>}
                {(enableAvoidLtArchive && !isConcatenation && editAvoidLtArchiveChoice) && <div>
                    <DisclosureItem
                        head={<h1 className='leading-6 font-medium text-gray-900'>{t('summary.longTermArchive')}</h1>}>
                        <div className='flex justify-between items-center py-1'>
                            <SingleCheckbox value={avoidLtArchive ?? false}
                                            setValue={setAvoidLtArchive}
                                            label={t('summary.avoidTermArchive')}/>
                        </div>
                    </DisclosureItem>
                </div>}
                <div>
                    {!isEmpty(processList) && <>
                        <h1 className='leading-6 font-medium text-gray-900 pb-4'>{t('Processi')}</h1>
                        <div className='flex flex-col space-y-2'>
                            {processList.map(p => <p className='text-sm leading-5'>{p}</p>)}
                        </div>
                    </>}
                </div>
                {documentTitle && <div>
                    <DisclosureItem
                        head={<h1 className='leading-6 font-medium text-gray-900'>{t('summary.documents')}</h1>}>
                        <div className='flex justify-between items-center py-1'>
                            <p className='text-sm leading-5'>{documentTitle}</p>
                        </div>
                    </DisclosureItem>
                </div>}
                {!isEmpty(dataCollections) && <div>
                    <DisclosureItem
                        head={<h1 className='leading-6 font-medium text-gray-900'>{t('summary.forms')}</h1>} >
                        {Object.values(dataCollections).map(data => (
                            <div className='flex justify-between items-center py-1' key={data?.id}>
                                <p className='text-sm leading-5'>{data.name}</p>
                                {data.url && <RoundButtons label={t('modify')} onClick={() => navigate(data.url)}/>}
                            </div>
                        ))}
                    </DisclosureItem>
                </div>}
                {!isEmpty(informativeFileredGraphKyc) && <div>
                    <DisclosureItem
                        head={<h1 className='leading-6 font-medium text-gray-900'>{t('summary.informedPaths')}</h1>} >
                        {Object.values(informativeFileredGraphKyc).map(i => (
                            <div className='flex justify-between items-center py-1' key={i?.id}>
                                <p className='text-sm leading-5'>{i.name}</p>
                                {i.url && <RoundButtons label={t('modify')} onClick={() => navigate(i.url)}/>}
                            </div>
                        ))}
                    </DisclosureItem>
                </div>}
            </div>

        </div>
    )
}

export default SummarySidebar;
