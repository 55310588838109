import {configureStore} from "@reduxjs/toolkit";
import navigationSlice from "./features/navigation/navigation.slice";
import authSlice from "./features/auth/auth.slice";
import userHomeSlice from "./features/userHome/userHome.slice";
import documentalProcessSlice from "./features/documentalProcess/documentalProcess.slice";
import peopleSlice from "./features/people/people.slice";
import documentBuilderSlice from "./features/documentBuilder/documentBuilder.slice"
import librarySlice from "./features/library/library.slice";
import recipientsSlice from "./features/recipients/recipients.slice";
import summarySlice from "./features/summary/summary.slice";
import sendProcessSlice from "./features/sendProcess/sendProcess.slice";
import selfSignDocumentSlice from "./features/selfSignDocument/selfSignDocument.slice";
import docModelsSlice from "./features/docModels/docModels.slice";

const saveState = (state) => {
    try {
        // Convert the state to a JSON string
        const serialisedState = JSON.stringify(state);

        // Save the serialised state to localStorage against the key 'app_state'
        window.sessionStorage.setItem('app_state', serialisedState);
    } catch (err) {
        console.log(err)
    }
};

/**
 * This function checks if the app state is saved in localStorage
 */
const loadState = () => {
    try {
        // Load the data saved in localStorage, against the key 'app_state'
        const serialisedState = window.sessionStorage.getItem('app_state');

        // Passing undefined to createStore will result in our app getting the default state
        // If no data is saved, return undefined
        if (!serialisedState) return undefined;

        // De-serialise the saved state, and return it.
        return JSON.parse(serialisedState);
    } catch (err) {
        // Return undefined if localStorage is not available,
        // or data could not be de-serialised,
        // or there was some other error
        return undefined;
    }
};

const store = configureStore({
    reducer: {
        auth: authSlice,
        userHome: userHomeSlice,
        navigation: navigationSlice,
        documentalProcess: documentalProcessSlice,
        people: peopleSlice,
        library: librarySlice,
        documentBuilder: documentBuilderSlice,
        recipients: recipientsSlice,
        summary: summarySlice,
        sendProcess: sendProcessSlice,
        selfSignDocument: selfSignDocumentSlice,
        docModels: docModelsSlice
    },
    devTools: process.env.NODE_ENV !== 'production',
    preloadedState: loadState()
});

store.subscribe(() => {
    saveState(store.getState());
});

export default store;
