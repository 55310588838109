import {v4} from "uuid";
import Button from "../../atoms/Buttons/Button/Button";
import {useTranslation} from "react-i18next";
import React, {useState} from "react";
import ModalChangeFeqPin from "../Modals/ModalChangeFeqPin/ModalChangeFeqPin";
import {changeFeqPin} from "../../../features/library/library.action";
import {useDispatch, useSelector} from "react-redux";
import {librarySelector} from "../../../features/library/library.slice";
import {toLocaleString} from "../../../utils/helper";

const CardSignature = ({item, allowAdd= false, showCounters = false, enableAction, currentWidget, setCurrentWidget}) => {
    const id = v4();
    const {t} = useTranslation()
    const dispatch = useDispatch();

    const { remoteSignatureUser } = useSelector(librarySelector);
    const expireDate = remoteSignatureUser && remoteSignatureUser.expireDate
        ? new Date(remoteSignatureUser.expireDate)
        : null;

    const today = new Date();
    const feqInScadenza = expireDate &&
        (expireDate - today) / (1000 * 60 * 60 * 24) <= 30;

    const [openChangeFeqPin, setOpenChangeFeqPin] = useState(false)

    const showChangePin = () => {
        setOpenChangeFeqPin(true);
    }
    const onPinChange = (item) => {
        dispatch(changeFeqPin(item))
    }


    return(
        <div
            key={id}
            className="col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200 w-full m-4"
        >
            <div className="flex-1 flex flex-col p-6">
                <h3 className="mt-4 text-gray-900 text-sm leading-5 font-medium text-justify ">{item.description}
                    <span className="ml-10">{item.enabled ?
                        <div  className="bg-green-100 text-green-800 inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0">
                            {t('activate')}
                        </div> : <></>
                    }
                    </span>
                </h3>
                <dl className="mt-1 flex-grow flex flex-col justify-between">
                    {showCounters ? <dd className="text-gray-500 text-xs leading-4 font-normal text-justify">{t('settings.signMade',{doneSign:item.signaturesDone})}</dd> : <></>}
                    {(item.enabled && item.code === 'FEQ' && item.alias) ? <dd className="text-gray-500 text-xs leading-4 font-normal text-justify">ID: {item.alias}</dd> : <></> }
                    {(item.enabled && item.code === 'FEQ' && item.hasOtp) ? <dd className="text-gray-500 text-xs leading-4 font-normal text-justify">OTP: {item.hasOtp ? 'Attivo' : 'Non attivo'}</dd> : <></> }
                    { item.enabled && item.code === 'FEQ' && remoteSignatureUser && remoteSignatureUser.expireDate ? <dd className="text-gray-500 text-xs leading-4 font-normal text-justify">Scadenza: {toLocaleString(remoteSignatureUser.expireDate)}</dd> : <></> }
                </dl>
                <div className='flex justify-between'>
                    <div className="mt-8" >
                        {item.enabled || !allowAdd ? <></> : <Button label={t('enable')} size='sm' variant='blue' onClick={()=>enableAction(item.code)} />}
                        {(item.enabled && item.code === 'FEQ') ?
                            <>
                                {(item.provider !== 'LAZIOCREA') ? <Button label={t('changeFeqPIN')} size='sm' variant='white' onClick={()=>showChangePin()} /> : <></>}
                                {(item.provider === 'UANATACA' ||  item.provider === 'LAZIOCREA') ? <>&nbsp;<Button label={'Reinserisci dati'} size='sm' variant='white' onClick={()=>{setCurrentWidget('FEQ')}} /></> : <></>}
                                {(item.provider === 'INTESA' && feqInScadenza) ? <>&nbsp;<Button label={'Procedi al rinnovo'} size='sm' variant='white' onClick={()=>{setCurrentWidget('FEQ')}} /></> : <></>}

                            </>
                            : <></>
                        }
                    </div>
                </div>
            </div>
            <ModalChangeFeqPin open={openChangeFeqPin} setOpen={setOpenChangeFeqPin} onConfirm={onPinChange} />
        </div>
    )
}

export default CardSignature;
