import {createAsyncThunk} from "@reduxjs/toolkit";
import {
    deleteGraphConsentModelCall,
    getAvailableGraphConsentCall,
    getGraphConsentCall,
    getGraphConsentModelCall,
    getModelPlaceholdersCall,
    setPlaceholderGraphModelCall,
    updateGraphConsentDetailsCall,
    uploadModelCall
} from "../../api/docModels";

export const getGraphConsentsThunk = createAsyncThunk(
    'docModels/getGraphConsentsThunk',
    async ({onlyActive = true}) => {
        const response = await getGraphConsentCall(onlyActive ?? true);
        return response.data
    }
);

export const getGraphConsentModelThunk = createAsyncThunk(
    'docModels/getGraphConsentModelThunk',
    async ({modelId}) => {
        const response = await getGraphConsentModelCall(modelId);
        return response.data
    }
);

export const deleteGraphConsentModelThunk = createAsyncThunk(
    'docModels/deleteGraphConsentModelThunk',
    async ({modelId}) => {
        const response = await deleteGraphConsentModelCall(modelId);
        if(response.data === true) return modelId
    }
);

export const getModelPlaceholdersThunk = createAsyncThunk(
    'docModels/getModelPlaceholdersThunk',
    async ({modelId}) => {
        const response = await getModelPlaceholdersCall(modelId);
        return response.data
    }
);

export const getAvailableGraphConsentThunk = createAsyncThunk(
    'docModels/getAvailableGraphConsentThunk',
    async () => {
        const response = await getAvailableGraphConsentCall();
        return response.data
    }
);

export const uploadModelThunk = createAsyncThunk(
    'docModels/uploadModelThunk',
    async ({file, isTemplate=false}) => {
        const response = await uploadModelCall(file, isTemplate);
        return response.data
    }
);

export const setPlaceholderGraphModelThunk = createAsyncThunk(
    'docModels/setPlaceholderGraphModelThunk',
    async ({modelId, data}, {getState}) => {
        const {placeholders} = getState().docModels.graphConsentModelInfo;
        const response = await setPlaceholderGraphModelCall(modelId, placeholders);
        return response.data
    }
);

export const updateGraphConsentDetailsThunk = createAsyncThunk(
    'docModels/updateGraphConsentDetailsThunk',
    async ({modelId, title, description}) => {
        const response = await updateGraphConsentDetailsCall(modelId, title, description);
        return response.data
    }
);
