import {Fragment} from 'react'
import {Listbox, Transition} from '@headlessui/react'
import {classNames} from "../../../../utils/helper";

const DropdownMultiSelect = ({label, options = [], selectedOptions = [], setSelectedOptions}) => {
    return (
        <Listbox value={[]} multiple>
            <div className="relative mt-1 z-10">
                <Listbox.Button className="relative w-full cursor-pointer rounded-lg bg-blue-500 text-white py-2 px-3 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                    <span className="block truncate">{label}</span>
                </Listbox.Button>
                <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <Listbox.Options className="absolute bg-white mt-1 max-h-60 w-max right-0 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {options.map((opt) => {
                            const selected = selectedOptions[opt.id] ?? false
                            return(
                                <Listbox.Option
                                    key={opt.id}
                                    value={opt.id}
                                    onClick={() => setSelectedOptions(opt.id)}
                                    className={({ active }) => classNames(
                                        active ? 'bg-blue-100 text-blue-900' : 'text-gray-900',
                                        selected ? 'font-medium' : 'font-normal',
                                        'relative select-none py-2 pl-10 pr-4 cursor-pointer')}
                                >
                                    <>
                                        <span className='block truncate cursor-pointer'>{opt.name}</span>
                                        <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                                            {/*selected ?*/
                                                <span
                                                    className={classNames(
                                                        selected ? 'bg-green-400' : 'bg-gray-200',
                                                        'inline-block h-2 w-2 flex-shrink-0 rounded-full'
                                                    )}
                                                    aria-hidden="true"
                                                />
                                            }
                                        </span>
                                    </>
                                </Listbox.Option>
                            )})}
                    </Listbox.Options>
                </Transition>
            </div>
        </Listbox>
    )
}

export default DropdownMultiSelect;
