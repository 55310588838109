import {MinusCircleIcon, PlusIcon} from "@heroicons/react/24/solid";
import {useEffect, useState} from "react";
import Input from "../../../atoms/Inputs/Input/Input";
import SingleCheckbox from "../../../atoms/SingleCheckbox/SingleCheckbox";
import {useTranslation} from "react-i18next";
import Select from "../../../atoms/Select/Select";

const Quiz = ({questions, setQuestions, wrongAnswerStepId, setWrongAnswerStepId, steps}) => {
    const {t}=useTranslation()
    const addQuestion= () => {
        setQuestions([...questions, {body: '', correct: true}])
    }

    const setQuestion = (idx, body, correct) => {
        setQuestions(questions.map((q,qid)=> {
            if(qid === idx) return {body,correct}
            else return q
        }))
    }

    let stepsOption = steps
        .filter(s => s.typeSymbol !== 'quiz')
        .map(s => ({
            value: s.id,
            label: s.title || s.name
        }));
    stepsOption = [{value: ' ', label: ' '}].concat(stepsOption);

    const deleteQuestion= (e, deleteId) => {
        e.preventDefault();
        e.stopPropagation();
        setQuestions(questions.filter((q, qId) => qId !== deleteId))
    }
    return(
        <div className='flex flex-col'>
            <div className='mb-2'>
                <div className='grid grid-cols-11 gap-x-2 pt-2'>
                    <div className='col-span-6'>
                        <label  className="block text-sm font-medium text-gray-700">{t('quiz.choices')}</label>
                    </div>
                    <div className='col-span-4'>
                        <label  className="block text-sm font-medium text-gray-700">{t('quiz.correct')}</label>
                    </div>
                </div>
                {questions && questions.map((question, questionId) => <Answer key={`Answer-${questionId}`} question={question} setQuestion={setQuestion} questionId={questionId} deleteQuestion={deleteQuestion} />)}
            </div>
            <button className='text-blue-600 flex items-center self-center' onClick={() => addQuestion()}><PlusIcon className='h-5'/>{t('quiz.addAnswer')}</button>
            <div className="col-span-4 mt-8">
                <Select label={'Se risposta sbagliata vai a'} placeholder={'Se risposta sbagliata vai a'} value={wrongAnswerStepId} setValue={setWrongAnswerStepId} options={stepsOption}/>
            </div>
        </div>
    )
}



const Answer = ({question, questionId, setQuestion, deleteQuestion}) => {
    const {t}=useTranslation()

    const [answerBody, setAnswerBody] = useState(question.body)
    const [correct, setCorrect] = useState(question.correct)

    const setQuestionBody = (value) => {
        setAnswerBody(value);
        setQuestion(questionId, value, correct)
    }

    const setQuestionCorrect = (value) => {
        setCorrect(value);
        setQuestion(questionId, answerBody, value)
    }

    useEffect(() => {
        setAnswerBody(question.body)
        setCorrect(question.correct)
    },[question.body, question.correct])

    return(
        <div className='grid grid-cols-11 gap-x-2 items-center'>
            <div className='col-span-6'>
                <Input value={answerBody} placeholder={t('quiz.fillAnswer')} setValue={setQuestionBody} onC/>
            </div>
            <div className='flex col-span-4 justify-center flex-grow'>
                <SingleCheckbox value={correct} setValue={setQuestionCorrect}/>
            </div>
            <div className='col-span-1 justify-self-center'>
                <MinusCircleIcon className='text-red-600 h-6 cursor-pointer'
                                 onClick={(e) => deleteQuestion(e, questionId)}/>
            </div>
        </div>
    )
}

export default Quiz;
