import SimplePage from "../Templates/SimplePage/SimplePage";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {authSelector, clearResetPasswordError} from "../../../features/auth/auth.slice";
import {useNavigate, useParams} from "react-router-dom";
import ResetPasswordForm from "../../organisms/ResetPassword/ResetPasswordForm";
import {resetPassword} from "../../../features/auth/auth.actions";

const ResetPasswordPage = () => {
    const navigate = useNavigate();
    const {resetPwdToken} = useParams();
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const { resetPasswordSuccess, resetPasswordError, resetPasswordErrorMessage } = useSelector(authSelector)
    const dispatch = useDispatch();

    const resetPasswordRequest = async () => {
        dispatch(resetPassword({password, passwordConfirm, resetPwdToken}));
    }

    useEffect(() => {
        dispatch(clearResetPasswordError())
    },[])

    useEffect(() => {
        if(resetPasswordSuccess) {
            navigate('/login')
        }
    }, [resetPasswordSuccess])

    return(
        <SimplePage>
            <div className='flex max-w-screen-2xl w-full h-screen justify-center items-center bg-fi-background bg-no-repeat bg-left-bottom bg-50% bg-scroll'>
                <div className="flex flex-grow justify-center">

                    <ResetPasswordForm
                        password={password}
                        setPassword={setPassword}
                        passwordConfirm={passwordConfirm}
                        setPasswordConfirm={setPasswordConfirm}
                        resetPasswordError={resetPasswordError}
                        resetPasswordErrorMessage={resetPasswordErrorMessage}
                        resetPasswordRequest={resetPasswordRequest}
                    />
                </div>
            </div>
        </SimplePage>
    )
}
export default ResetPasswordPage;
