import {ArrowRightIcon} from "@heroicons/react/24/solid";
import Badge from "../../Badges/Badge/Badge";
import {classNames} from "../../../../utils/helper";

const ActionButton = ({title, text, premium = false, onClick= ()=> {}}) => {
    return(
        <button
            className={classNames(
            premium ? 'bg-white border shadow' : 'bg-gray-50',
            'flex flex-grow items-center justify-between mt-3 p-6 rounded-lg max-w-md text-base font-medium hover:bg-gray-50'
            )}
            onClick={onClick}
        >
            <div className='flex flex-col items-start justify-start'>
                {!premium && <div className='pb-3'>
                    <Badge label='Funzionalità premium' color='yellow'/>
                </div>}
                <p className={classNames(!premium ? 'text-gray-500' : '', 'text-lg leading-7 font-medium')}>{title}</p>
                <p className={classNames(!premium ? 'text-gray-400' : 'text-gray-500', 'text-sm leading-5 font-normal')}>{text}</p>
            </div>
            {premium && <ArrowRightIcon className='h-5 w-5 ml-2' />}
        </button>
    )
}

export default ActionButton;
