import SimpleTable from "../../../../molecules/Tables/SimpleTable/SimpleTable";
import {
    DATE,
    ICON_BUTTON,
    ICON_BUTTON_LIST,
    TRANSLATELABEL
} from "../../../../atoms/SimpleTableElement/SimpleTable.types";
import React, {useEffect, useMemo, useState} from "react";
import ModalDeleteMember from "../../../../molecules/Modals/ModalSelectUserRole/ModalDeleteMember";
import {useDispatch, useSelector} from "react-redux";
import {userHomeSelector} from "../../../../../features/userHome/userHome.slice";
import {
    changeRoleThunk,
    getAllWorkspacesInfoThunk,
    getMembersThunk,
    inviteMemberByEmailThunk,
    joinUserToWorkspaceThunk,
    removeMemberThunk,
    removeUserFromWorkspaceThunk,
    sendInvitationAgainThunk,
    downloadMembersThunk,
} from "../../../../../features/userHome/userHome.actions";
import ModalEditRole from "../../../../molecules/Modals/ModalEditRole/ModalEditRole";
import Button from "../../../../atoms/Buttons/Button/Button";
import {FULL} from "../../../../atoms/Buttons/sizes";
import ModalInviteMember from "../../../../molecules/Modals/ModalInviteMember/ModalInviteMember";
import {useTranslation} from "react-i18next";
import Paginator from "../../../../atoms/Paginator/Paginator";
import InputWithLeadingIcon from "../../../../atoms/Inputs/InputWithLeadingIcon/InputWithLeadingIcon";
import {MagnifyingGlassIcon} from "@heroicons/react/24/solid";
import {checkPermissions} from "../../../../../utils/helper";
import {SHOWFEQINFO, WORKSPACES} from "../../../../../utils/permissions";
import ModalFeqDetails from "../../../../molecules/Modals/ModalFeqDetails/ModalFeqDetails";
import {ArrowDownTrayIcon} from "@heroicons/react/24/outline";
import IconButton from "../../../../atoms/Buttons/IconButton/IconButton";

const initialFilters = {pagination: {currentPage: 1, offset: 0, limit:10}, txtFilter: '', sortField: 'referenceDate', descending: true}

const ManageUsers = () => {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const [openEditRoles, setOpenEditRoles] = useState(false)
    const [openFeqDetails, setOpenFeqDetails] = useState(false)
    const [openRemoveMember, setOpenRemoveMember] = useState(false)
    const [openInviteMember, setOpenInviteMember] = useState(false)
    const [selectedMember, setSelectedMember] = useState()
    const [filters, setFilters] = useState(initialFilters);

    const {members, membersCount, allWorkspacesInfo, listMembersLoaded} = useSelector(userHomeSelector)
    const { selectedTenantId, userOrganizations } = useSelector(userHomeSelector);

    const permissions = useMemo(() => userOrganizations?.[selectedTenantId]?.permissions ?? {}, [userOrganizations, selectedTenantId])
    const hasWorkspacePermission = useMemo(() => checkPermissions(permissions, WORKSPACES), [permissions])
    const hasShowFeqInfoPermission = useMemo(() => checkPermissions(permissions, SHOWFEQINFO), [permissions])

    useEffect(() => {
        getWorkspaces()
    },[])

    useEffect(() => {
        if (!listMembersLoaded) {
            getMembers()
        }
    }, [listMembersLoaded])

    useEffect(() => {
        getMembers()
    }, [filters])

    useEffect(() => {
        if(!openRemoveMember && !openEditRoles) setSelectedMember()
    },[openRemoveMember, openEditRoles])

    const setTxtFilter = (txtFilter) => setFilters({...filters, pagination: {currentPage: 1, offset: 0, limit:10}, txtFilter})
    const getMembers = () => dispatch(getMembersThunk({txtFilter: filters.txtFilter, pagination: filters.pagination, sortField: filters.sortField, descending: filters.descending}))
    const getWorkspaces = () => dispatch(getAllWorkspacesInfoThunk())
    const onRemoveUser = () => dispatch(removeMemberThunk({id: selectedMember.id, isInvitation: selectedMember.isInvitation}))
    const onChangeRole = (role) => {
        if(role !== selectedMember?.role) dispatch(changeRoleThunk({id: selectedMember.id, role}))
    }
    const onInviteMember = (email, role) => dispatch(inviteMemberByEmailThunk({email, role}))
    const sendInvitationEmailAgain = (data) => dispatch(sendInvitationAgainThunk({id: data.id}))
    const joinUserToWorkspace = (enabled, workspaceId) => {
        if(enabled) dispatch(joinUserToWorkspaceThunk({userId: selectedMember.userId, workspaceId}))
        else dispatch(removeUserFromWorkspaceThunk({userId: selectedMember.userId, workspaceId}))
    }

    const onOpenRemoveMember = (member) => {
        setSelectedMember(member)
        setOpenRemoveMember(true)
    }

    const onOpenEditRole = (member) => {
        setSelectedMember(member)
        setOpenEditRoles(true)
    }

    const onOpenFeqDetails = (member) => {
        setSelectedMember(member)
        setOpenFeqDetails(true)
    }

    const handleExportToExcel = () => {
        dispatch(downloadMembersThunk({}))
    };

    let columns = [
        {field: 'name', label: 'tableColumns.name', hidden: false, isSortable: true, id: 'name'},
        {field: 'email', label: 'tableColumns.email', hidden: false, isSortable: true, id: 'email'},
        {field: 'roleDecoded', label: 'tableColumns.role', hidden: false, isSortable: true, id: 'roleDecoded'},
        {field: 'date', label: 'tableColumns.date', type: DATE, hidden: false, isSortable: true, id: 'date'},
        {field: 'status', label: 'Stato cert.', hidden: !hasShowFeqInfoPermission, isSortable: true, id: 'status', type: TRANSLATELABEL},
        {field: 'expireDate', label: 'Scadenza', type: DATE, hidden: !hasShowFeqInfoPermission, isSortable: true, id: 'expireDate'},
        {field: 'actions', type: ICON_BUTTON_LIST, hidden: true, id: 'actions', buttons: [
                {
                    field: t('manageusers.sendInvitationAgain'),
                    label: 'tableColumns.edit',
                    hidden: true,
                    type: ICON_BUTTON,
                    onClick: (data) => sendInvitationEmailAgain(data),
                    hideColumnContent: (member) => !member.isInvitation,
                    iconType: 'sendEmail',
                    id: 'SendEmail'
                },
                {
                    field: t('manageusers.showcertdetals'),
                    label: 'tableColumns.edit',
                    hidden: true,
                    type: ICON_BUTTON,
                    onClick: (data) => onOpenFeqDetails(data),
                    hideColumnContent: (member) => !hasShowFeqInfoPermission || member.isInvitation || !member.remoteSignerId,
                    iconType: 'document-check',
                    id: 'SendEmail'
                },
                {
                    field: 'Edit',
                    label: 'tableColumns.edit',
                    hidden: true,
                    type: ICON_BUTTON,
                    onClick: (data) => onOpenEditRole(data),
                    hideColumnContent: (member) => !((member && !member?.isBlocked) || hasWorkspacePermission),
                    iconType: 'modify',
                    id: 'Edit'
                },
                {
                    field: 'Elimina',
                    label: 'tableColumns.elimina',
                    hidden: true,
                    type: ICON_BUTTON,
                    onClick: (data) => onOpenRemoveMember(data),
                    hideColumnContent: (data) => data?.isBlocked,
                    iconType: 'delete',
                    id: 'Elimina'
                },
            ]
        }
    ]
    columns = columns.filter(c => !(c.field === 'status' || c.field === 'expireDate') || hasShowFeqInfoPermission)

    return(
        <>
            <h1 className="text-xl font-semibold text-gray-900">{t('users')}</h1>
            <div className="mt-2 flex flex-col w-full">
                <div className='flex space-x-2'>
                    <div className='w-3/4'>
                        <InputWithLeadingIcon
                            value={filters.txtFilter}
                            setValue={setTxtFilter}
                            icon={<MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
                            placeholder={t('search.forNameEmail')}
                        />
                    </div>
                    <div className='my-1 w-1/4 flex space-x-2'>
                        <Button label={t('manageusers.invite')} size={FULL} onClick={() => setOpenInviteMember(true)}/>
                        {hasShowFeqInfoPermission && (
                            <IconButton 
                                icon={<ArrowDownTrayIcon className="h-5 w-5"/>}
                                onClick={handleExportToExcel}
                                variant="secondary"
                            />
                        )}
                    </div>
                </div>
                <SimpleTable columns={columns} data={members} filters={filters} setFilters={setFilters}/>
            </div>
            <Paginator pageSize={10} currentPage={filters.pagination.currentPage} totalResults={membersCount} onClick={(pagination) => setFilters({...filters, pagination})}/>
            <ModalDeleteMember open={openRemoveMember} setOpen={setOpenRemoveMember} selectedUsername={selectedMember?.name ?? ''} onRemove={onRemoveUser}/>
            <ModalEditRole open={openEditRoles} setOpen={setOpenEditRoles}
                           selectedMember={selectedMember} onConfirm={onChangeRole}
                           allWorkspacesInfo={allWorkspacesInfo} onChangeWorkspace={joinUserToWorkspace} hasWorkspacePermission={hasWorkspacePermission}
            />
            <ModalFeqDetails open={openFeqDetails} setOpen={setOpenFeqDetails}
                           selectedMember={selectedMember}
            />
            <ModalInviteMember open={openInviteMember} setOpen={setOpenInviteMember} onConfirm={onInviteMember} />
        </>
    )
}

export default ManageUsers;
