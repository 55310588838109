import Select from "../../atoms/Select/Select";
import {XMarkIcon} from "@heroicons/react/24/solid";
import Input from "../../atoms/Inputs/Input/Input";
import {useTranslation} from "react-i18next";

const FormInputElement = ({onDelete = () => {}, fieldOptions = [], rule= {}, setRule}) => {
    const {t}=useTranslation()
    const comparisionOptions = [
        { label: '=', value: 'EQ' },
        { label: '≠', value: 'NEQ' },
    ]

    const actionsOptions = [
        { label: t('forms.hidden'), value: 'disable' },
        { label: t('forms.visible'), value: 'enable' }
    ]
    return(
        <div className="max-w-7xl mx-auto px-4 sm:px-6 bg-white rounded-md p-4 shadow-sm flex">
            <div className="grid w-full">
                <p className='text-sm leading-5 font-medium'>{t('forms.ifField')}</p>
                <div className="grid grid-cols-10 gap-x-2">
                    <div className="col-span-4">
                        <Select placeholder={t('forms.selectField')} value={rule.targetField} setValue={(targetField) => setRule({...rule, targetField})} options={fieldOptions}/>
                    </div>
                    <div className="col-span-2">
                        <Select value={rule.comparison} placeholder='=/≠' setValue={(comparison) => setRule({...rule, comparison})} options={comparisionOptions}/>
                    </div>
                    <div className="col-span-4">
                        <Input placeholder={t('forms.addValue')} value={rule.value} setValue={(value) => setRule({...rule, value})} />
                    </div>
                </div>
                <p className='text-sm leading-5 font-medium pt-2'>{t('forms.thenField')}</p>
                <div className="grid grid-cols-10 gap-x-2">
                    <div className="col-span-6">
                            <Select placeholder={t('forms.selectField')} value={rule.dependentField} setValue={(dependentField) => setRule({...rule, dependentField})} options={fieldOptions} />
                        </div>
                    <div className="col-span-4">
                        <Select placeholder={t('forms.selectAction')} value={rule.action} setValue={(action) => setRule({...rule, action})} options={actionsOptions}/>
                    </div>
                </div>
            </div>
            <div className="flex items-center pl-6">
                <XMarkIcon data-testid='delete-icon' className="text-red-600 h-6 w-6 cursor-pointer" onClick={() => onDelete(rule)}/>
            </div>
        </div>
    )
}

export default FormInputElement;
