import SingleCheckbox from "../../atoms/SingleCheckbox/SingleCheckbox";
import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {linkRecipientToInformativa, unlinkRecipientToInformativa} from "../../../features/recipients/recipients.slice";

const SingleInformativaRecipientCheckbox = ({recipient, selectedInformativa, disabled = true}) => {
    const [value, setValue] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        setValue(recipient?.linkedInformative?.[selectedInformativa] ?? false)
    },[selectedInformativa])

    useEffect(() => {
        if(selectedInformativa && recipient) {
            if (value)
                dispatch(linkRecipientToInformativa({
                    recipientId: recipient.id,
                    informativaId: selectedInformativa
                }))
            else
                dispatch(unlinkRecipientToInformativa({
                    recipientId: recipient.id,
                    informativaId: selectedInformativa
                }))
        }
    },[value])

    return <SingleCheckbox label={`${recipient.name}`} value={value} setValue={setValue} disabled={disabled}/>
}

export default SingleInformativaRecipientCheckbox;
