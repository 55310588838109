import ModalGrayFooter from "../Templates/ModalGrayFooter";
import {Dialog} from "@headlessui/react";
import React, {useEffect, useState} from "react";
import Input from "../../../atoms/Inputs/Input/Input";
import {useTranslation} from "react-i18next";

const ModalNewUserList = ({open, setOpen, onConfirm, initialName='', confirmLabel}) => {
    const {t} = useTranslation()
    const [name, setName] = useState(initialName)
    const confirm = () => {
        onConfirm(name)
        setOpen(false)
    }

    useEffect(() => {
        setName(initialName)
    },[open])

    return(
        <ModalGrayFooter open={open} setOpen={setOpen} onConfirm={confirm} confirmLabel={confirmLabel ?? 'Crea'} >
            <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                    {t('settings.listName')}
                </Dialog.Title>
                <div className="mt-4">
                    <Input value={name} setValue={setName}/>
                </div>
            </div>
        </ModalGrayFooter>
    )
}

export default ModalNewUserList;

