import {createAsyncThunk} from "@reduxjs/toolkit";
import {
    addTagCall,
    changeRoleCall, changeSelectedTenantCall,
    checkAccessLinkCall,
    createNewWorkspaceCall, deleteTagCall,
    deleteWorkspaceCall,
    getAllWorkspacesInfoCall,
    getAvailableWorkspacesCall,
    getEcoStatsCall,
    getLanguages,
    getMembersCall,
    downloadMembersCall,
    getModels,
    getTagsList,
    inviteMemberByEmailCall,
    joinUserToWorkspaceCall,
    listNotifications,
    listUserOrganizations,
    registerUserWithAccessLinkCall,
    removeMemberCall,
    removeUserFromWorkspaceCall,
    retrieveHomeActions,
    retrieveLastNotifications, retrieveOrganizationDetailsCall, sendInvitationAgain,
    updateWorkspaceNameCall
} from "../../api/userHome";
import {updateModelAsFavouriteCall} from "../../api/documentalProcess";

export const getUserOrganizations = createAsyncThunk('userHome/organizations', async () => {
    const response = await listUserOrganizations()
    return response.data
});

export const getHomeActions = createAsyncThunk('userHome/actions', async () => {
    const response = await retrieveHomeActions()
    return response.data
});

export const getNotifications = createAsyncThunk('userHome/getNotifications', async (props) => {
    const response = await listNotifications(props);
    return response.data
});

export const getModelsList = createAsyncThunk(
    'userHome/getModelsList',
    async ({type, tags} ) => {
        const response = await getModels(type, tags);
        return response.data
    }
);

export const getLastNotificationsList = createAsyncThunk(
    'userHome/getLastNotificationsList',
    async ( ) => {
        const response = await retrieveLastNotifications();
        return response.data
    }
);

export const getTags = createAsyncThunk(
    'userHome/getTags',
    async () => {
        const response = await getTagsList();
        return response.data
    }
);

export const addTagThunk = createAsyncThunk(
    'userHome/addTagThunk',
    async ({tag} ) => {
        if(tag){
            const response = await addTagCall(tag);
            return response.data
        }
    }
);


export const deleteTagThunk = createAsyncThunk(
    'userHome/deleteTagThunk',
    async ({tag} ) => {
        if(tag){
            const response = await deleteTagCall(tag);
            return response.data
        }
    }
);


export const getLanguagesThunk = createAsyncThunk(
    'userHome/getLanguagesThunk',
    async () => {
        const response = await getLanguages();
        return response.data
    }
);

export const updateModelAsFavouriteThunk = createAsyncThunk(
    'userHome/updateModelAsFavouriteThunk',
    async ({processId, isFavourite} ) => {
        const response = await updateModelAsFavouriteCall(processId, isFavourite);
        return {processId, isFavourite: response.data}
    }
);

export const getEcoStatsThunk = createAsyncThunk(
    'userHome/getEcoStatsThunk',
    async () => {
        const response = await getEcoStatsCall();
        return response.data
    }
);

export const checkAccessLinkThunk = createAsyncThunk(
    'userHome/checkAccessLinkThunk',
    async ({accessLink}) => {
        const response = await checkAccessLinkCall(accessLink);
        return response.data
    }
);

export const registerUserWithAccessLinkThunk = createAsyncThunk(
    'userHome/registerUserWithAccessLinkThunk',
    async ({accessLink, signupData}) => {
        const response = await registerUserWithAccessLinkCall(accessLink, signupData);
        return response.data
    }
);

export const getMembersThunk = createAsyncThunk(
    'userHome/getMembersThunk',
    async ({txtFilter, pagination, sortField, descending}) => {
        const response = await getMembersCall({txtFilter, pagination, sortField, descending});
        return response.data
    }
);

export const downloadMembersThunk = createAsyncThunk(
    'userHome/downloadMembersThunk',
    async ({}) => {
        await downloadMembersCall();
    }
);



export const removeMemberThunk = createAsyncThunk(
    'userHome/removeMemberThunk',
    async ({id, isInvitation}) => {
        const response = await removeMemberCall(id, isInvitation);
        return response.data
    }
);

export const changeRoleThunk = createAsyncThunk(
    'userHome/changeRoleThunk',
    async ({id, role}) => {
        const response = await changeRoleCall(id, role);
        return response.data
    }
);

export const inviteMemberByEmailThunk = createAsyncThunk(
    'userHome/inviteMemberByEmailThunk',
    async ({email, role}) => {
        const response = await inviteMemberByEmailCall(email, role);
        return response.data
    }
);

export const sendInvitationAgainThunk = createAsyncThunk(
    'userHome/sendInvitationAgainThunk',
    async ({id}) => {
        const response = await sendInvitationAgain(id);
        return response.data
    }
);

export const getAllWorkspacesInfoThunk = createAsyncThunk(
    'userHome/getAllWorkspacesInfoCall',
    async () => {
        const response = await getAllWorkspacesInfoCall();
        return response.data
    }
);

export const getAvailableWorkspacesThunk = createAsyncThunk(
    'userHome/getAvailableWorkspacesThunk',
    async () => {
        const response = await getAvailableWorkspacesCall();
        return response.data
    }
);

export const createNewWorkspaceThunk = createAsyncThunk(
    'userHome/createNewWorkspaceThunk',
    async ({name}, {dispatch}) => {
        const response = await createNewWorkspaceCall(name);
        if (response.status >= 200 && response.status<300) await dispatch(getAllWorkspacesInfoThunk())
        return response.data
    }
);

export const deleteWorkspaceThunk = createAsyncThunk(
    'userHome/deleteWorkspaceThunk',
    async ({id}, {dispatch}) => {
        const response = await deleteWorkspaceCall(id);
        if (response.status >= 200 && response.status<300) await dispatch(getAllWorkspacesInfoThunk())
        return response.data
    }
);

export const joinUserToWorkspaceThunk = createAsyncThunk(
    'userHome/joinUserToWorkspaceThunk',
    async ({userId, workspaceId}) => {
        const response = await joinUserToWorkspaceCall(userId, workspaceId);
        return response.data
    }
);

export const removeUserFromWorkspaceThunk = createAsyncThunk(
    'userHome/removeUserFromWorkspaceThunk',
    async ({userId, workspaceId}) => {
        const response = await removeUserFromWorkspaceCall(userId, workspaceId);
        return response.data
    }
);

export const updateWorkspaceNameThunk = createAsyncThunk(
    'userHome/updateWorkspaceNameThunk',
    async ({workspaceId, name}, {dispatch}) => {
        const response = await updateWorkspaceNameCall(workspaceId, name);
        if (response.status >= 200 && response.status<300) await dispatch(getAllWorkspacesInfoThunk())
        return {workspaceId, users: response.data}
    }
);

export const changeSelectedTenantThunk = createAsyncThunk(
    'userHome/changeSelectedTenantThunk',
    async ({userOrganizationRoleId}) => {
        const response = await changeSelectedTenantCall(userOrganizationRoleId);
        //if (response.status >= 200 && response.status<300) await dispatch(changeSelectedTenant({value: userOrganizationRoleId}))
        return {selectedTenantId: userOrganizationRoleId, token: response.data}

    }
);

export const retrieveOrganizationDetailsThunk = createAsyncThunk(
    'userHome/retrieveOrganizationDetailsThunk',
    async ({userOrganizationRoleId}) => {
        const response = await retrieveOrganizationDetailsCall(userOrganizationRoleId);
        return response.data
    }
);

