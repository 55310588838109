import {classNames} from "../../../../utils/helper";
import {CheckIcon, ChevronUpDownIcon} from "@heroicons/react/24/outline";
import { Listbox, Transition } from '@headlessui/react'
import {Fragment} from "react";
import isEmpty from "lodash/isEmpty";

const MultiSelectWorkspacesSummary = ({label, options, selected, setSelected}) => {
    return (
        <Listbox value={selected} onChange={setSelected} multiple>
            {({ open }) => (
                <div className='flex items-center w-full'>
                    <p className="w-1/4 mr-4 flex flex-nowrap">{label}</p>
                    <div className="relative mt-1 overflow-visible">
                        <Listbox.Button className="relative max-w-sm cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 sm:text-sm">
                            <span className="flex items-center">
                                <span className="block truncate">{isEmpty(selected) ? 'Nessuno' : selected.map(s => s.name).join(', ')}</span>
                            </span>
                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </span>
                        </Listbox.Button>

                        <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Listbox.Options className="absolute z-20 mt-1 max-h-40 w-fit overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                {options.map((opt) => (
                                    <Listbox.Option
                                        key={opt.id}
                                        className={({ active }) =>
                                            classNames(
                                                active ? 'text-white bg-blue-600' : 'text-gray-900',
                                                'relative cursor-default select-none py-2 pl-3 pr-9'
                                            )
                                        }
                                        value={opt}
                                    >
                                        {({ selected, active }) => (
                                                <div className="flex items-center">
                                                    <span
                                                        className={classNames(selected ? 'font-semibold' : 'font-normal', 'mx-3 block truncate')}>
                                                        {opt.name}
                                                </span>
                                                    {selected ? (
                                                        <span
                                                            className={classNames(
                                                                active ? 'text-white' : 'text-blue-600',
                                                                'absolute inset-y-0 right-0 flex items-center pr-4'
                                                            )}
                                                        >
                                                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                    </span>
                                                    ) : null}
                                                </div>
                                        )}
                                    </Listbox.Option>
                                ))}
                            </Listbox.Options>
                        </Transition>
                    </div>
                </div>
            )}
        </Listbox>
    )
}

export default MultiSelectWorkspacesSummary;
