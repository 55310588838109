import {Bars2Icon, XMarkIcon} from "@heroicons/react/24/solid";
import Input from "../../../atoms/Inputs/Input/Input";
import React, {useEffect, useState} from "react";
import {Draggable} from "react-beautiful-dnd";
import {classNames} from "../../../../utils/helper";
import SelectSignatureType from "../../../atoms/Select/SelectSignatureType/SelectSignatureType";
import {useTranslation} from "react-i18next";
import InputProgressNumber from "./InputProgressNumber";
import GraphConsent from "../GraphConsent/GraphConsent";
import isEmpty from "lodash/isEmpty";
import {useSelector} from "react-redux";
import {userHomeSelector} from "../../../../features/userHome/userHome.slice";
import Select from "../../../atoms/Select/Select";

const InputRecipientRole = ({ recipient, setRecipient, removeRecipient, signatureTypesList, index, isOrdered, graphTemplatesOptions}) => {
    const [signatureTypesOptions,setSignatureTypesOptions] = useState([])
    const {t}= useTranslation();

    const { selectedTenantId, userOrganizations } = useSelector(userHomeSelector);
    const peopleRoles = userOrganizations?.[selectedTenantId]?.peopleRoles ?? [];
    const peopleRolesOptions = peopleRoles?.map(pr => {return {value: pr.name, label: pr.description}});
    peopleRolesOptions.unshift({value: ' ', label: t('recipients.anyRole')});

    useEffect(() => {
        if(!isEmpty(signatureTypesList)) {
            const newSignatureTypesOptions = (!isOrdered || recipient?.progressNumber === 1) ? signatureTypesList : signatureTypesList.filter(opt => opt.id !== 'GRAPH')
            if (!newSignatureTypesOptions.map(opt => opt.id).includes(recipient.signatureType))
                setRecipient({...recipient, signatureType: '', hasGraphConsent: false, graphConsentModelId: ''})

            setSignatureTypesOptions(newSignatureTypesOptions)
        }
    },[signatureTypesList, recipient.progressNumber, isOrdered])

    return(
        <Draggable draggableId={`draggable-${recipient.id}`} index={index}>
            {(provided) =>
                <div ref={provided.innerRef} key={recipient.id}
                     {...provided.draggableProps} className="mx-auto bg-gray-50 flex justify-between shadow-md rounded-md my-4 w-full" >
                    <div className={classNames(!isOrdered ? 'invisible' : '', "flex items-center p-4 mt-6 mr-4")}>
                        <div {...provided.dragHandleProps}>
                            <Bars2Icon  className="h-8 w-8 m-4" />
                        </div>
                        <div className="w-10 flex items-center justify-center">
                            <InputProgressNumber progressNumber={recipient?.progressNumber} setProgressNumber={(value) => setRecipient({...recipient, progressNumber: value})} />
                        </div>
                    </div>
                    <div className="flex flex-grow grid grid-cols-1 md:grid-cols-2 gap-4 items-center m-4" >
                        <div className="w-full">
                            <Input label={t('recipients.role')} value={recipient.name ?? ''} setValue={(value) => setRecipient({...recipient, name: value})} />
                        </div>
                        <div className="col-span-1 w-full">
                            <SelectSignatureType
                                label={t('recipients.signType')}
                                placeholder={t('select')}
                                options={signatureTypesOptions}
                                value={recipient?.signatureType ?? ''}
                                setValue={(value) => setRecipient({...recipient, signatureType: value})}
                            />
                        </div>
                        {peopleRolesOptions?.length > 2 &&
                            <div className='w-auto'>
                                <Select value={recipient.peopleRole} setValue={(peopleRole) => setRecipient({...recipient, peopleRole})} label={t('recipients.selectPeopleRole')}
                                        options={peopleRolesOptions}/>
                            </div>
                        }
                        {recipient.signatureType === 'GRAPH' &&
                            <div className="col-span-2 w-full">
                                <GraphConsent
                                    isConsentChecked={recipient.hasGraphConsent}
                                    setConsent={(hasGraphConsent, graphConsentModelId) => setRecipient({...recipient, hasGraphConsent, graphConsentModelId})}
                                    templatesOptions={graphTemplatesOptions}
                                    templateChoice={recipient?.graphConsentModelId}
                                />
                            </div>
                        }
                    </div>
                    <div className="flex items-center p-4 pr-8 mt-6" >
                        <button onClick={() => removeRecipient(recipient)} className='focus:outline-none'>
                            <XMarkIcon className="text-red-600 h-5 w-5"/>
                        </button>
                    </div>


                </div>}
        </Draggable>
    )
}

export default InputRecipientRole;
