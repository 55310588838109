import {createEntityAdapter, createSlice} from "@reduxjs/toolkit";
import {
    activateUser,
    askEnable2FaThunk,
    changePasswordThunk,
    changePhoneThunk,
    checkDraftSessionId,
    disable2FaThunk,
    enable2FaThunk,
    forgotPassword,
    getCurrentUser,
    login,
    registration,
    resetPassword, retrieveSignerProfileThunk,
    setLimitUserThunk,
    setPreferredLanguageThunk, setUsersAdvancedFiltersThunk
} from "./auth.actions";
import {toast} from "react-hot-toast";
import {Translation} from "react-i18next";
import Alert from "../../components/molecules/Alerts/Alert";
import {ERROR, SUCCESS} from "../../components/molecules/Alerts/types";
import React from "react";

const authAdapter = createEntityAdapter()

const initialState = authAdapter.getInitialState({
    user: {},
    askTotp: false,
    selected: null,
    isLoggedIn: !!sessionStorage.getItem('token'),
    redirectTo: null,

    loginError: false,
    loginErrorMessage: false,

    forgotPasswordError: false,
    forgotPasswordErrorMessage: null,

    resetPasswordError: false,
    resetPasswordErrorMessage: null,
    resetPasswordSuccess: false,

    registrationError: false,
    registrationSuccess: false,
    registrationErrorMessage: null,

    activateUserError: false,
    activateUserSuccess: false,

    draftSessionIdChecked: false,
    draftSessionIdData: null,
    draftSessionIdError: null,

    remoteSignerProfile: null,
})

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setRedirectTo: (state, action) => {
            state.redirectTo = action.payload
        },
        logout: (state) => {
            state.isLoggedIn = false;
        },
        clearLoginError: (state) => {
            state.loginError = false;
            state.loginErrorMessage = null;
        },
        resetTotp: (state) => {
            state.askTotp = false;
        },
        clearForgotPasswordError: (state) => {
            state.forgotPasswordError = false;
            state.forgotPasswordErrorMessage = null;
        },
        clearResetPasswordError: (state) => {
            state.resetPasswordError = false;
            state.resetPasswordErrorMessage = null;
        },
        clearRegistrationError: (state) => {
            state.registrationError = false;
            state.registrationErrorMessage = null;
        },
        clearRegistrationSuccess: (state) => {
            state.registrationSuccess = false;
        },
        clearActivationError: (state) => {
            state.activateUserError = false;
        },
        resetState: () => {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(
            login.pending, (state) => {
                state.loginError = false
                state.isLoggedIn = false
            })
        builder.addCase(
            login.fulfilled, (state, action) => {
                if (action.payload.askTotp) {
                    state.askTotp = true
                } else {
                    state.isLoggedIn = true
                    state.token = 'Bearer ' + action.payload.token
                    state.askTotp = false
                    state.remoteSignerId= action.payload.userProfile.remoteSignerId
                    state.confirmoIdentifier= action.payload.userProfile.confirmoIdentityID
                    state.userId= action.payload.userProfile.id
                    state.user = action.payload.userProfile
                    state.loginError = false
                    state.language = action.payload.userProfile.preferredLanguage
                    state.usersAdvancedFilters = action.payload.userProfile.usersAdvancedFilters
                    state.phoneNumber = action.payload.userProfile.phoneNumber
                }
            })
        builder.addCase(
            login.rejected, (state, action) => {
                state.loginError = true
                state.loginErrorMessage = action.error.message;
            })
        builder.addCase(
            forgotPassword.pending, (state) => {
                state.forgotPasswordError = false
            })
        builder.addCase(
            forgotPassword.fulfilled, (state) => {
                state.forgotPasswordError = false
            })
        builder.addCase(
            forgotPassword.rejected, (state, action) => {
                state.forgotPasswordError = true
                state.forgotPasswordErrorMessage = action.error.message;
            })
        builder.addCase(
            resetPassword.pending, (state) => {
                state.resetPasswordError = false
                state.resetPasswordSuccess = false
                state.resetPasswordErrorMessage = null;
            })
        builder.addCase(
            resetPassword.fulfilled, (state) => {
                state.resetPasswordError = false
                state.resetPasswordSuccess = true
            })
        builder.addCase(
            resetPassword.rejected, (state, action) => {
                state.resetPasswordError = true
                state.resetPasswordSuccess = false
                state.resetPasswordErrorMessage = action.error.message;
            })
        builder.addCase(
            registration.fulfilled, (state) => {
                state.registrationError = false
                state.registrationSuccess = true
            })
        builder.addCase(
            registration.rejected, (state, action) => {
                state.registrationError = true
                state.registrationSuccess = false
                state.registrationErrorMessage = action.error.message;
            })
        builder.addCase(
            activateUser.fulfilled, (state) => {
                state.activateUserError = false
                state.activateUserSuccess = true
            })
        builder.addCase(
            activateUser.rejected, (state) => {
                state.activateUserError = true
                state.activateUserSuccess = false
            })
        builder.addCase(
            getCurrentUser.fulfilled, (state, action) => {
                state.user = action.payload
            })
        builder.addCase(
            setPreferredLanguageThunk.fulfilled, (state, action) => {
                state.language = action.payload
                sessionStorage.setItem('language', action.payload)
            })
        builder.addCase(
            setUsersAdvancedFiltersThunk.fulfilled, (state, action) => {
                state.usersAdvancedFilters = action.payload
                localStorage.setItem('usersAdvancedFilters', JSON.stringify(action.payload));
            })
        builder.addCase(
            changePhoneThunk.fulfilled, (state, action) => {
                state.phoneNumber = action.payload
            })
        builder.addCase(
            changePasswordThunk.fulfilled, (state) => {
                state.user.changePasswordNeeded = false
            })
        builder.addCase(
            setLimitUserThunk.fulfilled, (state, action) => {
                state.user.isLimited = action.payload.isLimited
            })
        builder.addCase(
            askEnable2FaThunk.fulfilled, (state, action) => {
                state.user.askEnable2FaQrCode = action.payload.qrcode;
                state.user.askEnable2FaQrSessionId = action.payload.sessionId;
            })
        builder.addCase(
            enable2FaThunk.fulfilled, (state, action) => {
                state.user.askEnable2FaQrCode = null;
                state.user.askEnable2FaQrSessionId = null;
                state.user.has2fa = true;
                toast.custom((tst) => ( <Translation>{(t) => <Alert type={SUCCESS} message={t('operationOk')} onClose={() => toast.dismiss(tst.id)}/>}</Translation> ))
            })
        builder.addCase(
            enable2FaThunk.rejected, () => {
                toast.custom((tst) => (<Translation>{(t) => <Alert type={ERROR} message={t('errorOccurred')} onClose={() => toast.dismiss(tst.id)}/>}</Translation>))
            })
        builder.addCase(disable2FaThunk.fulfilled, (state) => {
            state.user.has2fa = false;
            toast.custom((tst) => ( <Translation>{(t) => <Alert type={SUCCESS} message={t('operationOk')} onClose={() => toast.dismiss(tst.id)}/>}</Translation> ))
        })
        builder.addCase(
            retrieveSignerProfileThunk.rejected, (state) => {
                state.remoteSignerProfile = null
            })
        builder.addCase(retrieveSignerProfileThunk.fulfilled, (state, action) => {
            state.remoteSignerProfile = action.payload
        })
        builder.addCase(checkDraftSessionId.fulfilled, (state, action) => {
            state.draftSessionIdError = false
            state.draftSessionIdChecked = true
            state.draftSessionIdData = action.payload;
            sessionStorage.setItem('token', 'Bearer ' + action.payload.token)
            state.isLoggedIn = true
            state.token = 'Bearer ' + action.payload.token
            state.remoteSignerId= action.payload.userProfile.remoteSignerId
            state.confirmoIdentifier= action.payload.userProfile.confirmoIdentityID
            state.userId= action.payload.userProfile.id
            state.user = action.payload.userProfile
            state.loginError = false
            state.language = action.payload.userProfile.preferredLanguage
            state.usersAdvancedFilters = action.payload.userProfile.usersAdvancedFilters
            state.phoneNumber = action.payload.userProfile.phoneNumber

            state.selectedTenantId = Object.keys(state.user.userOrganizationsRoles).find(k => state.user.userOrganizationsRoles[k].organizationId === action.payload.eventParams.tenantId);
            try{
                sessionStorage.setItem('selectedTenantId', state.selectedTenantId)
            }catch (e){
                console.log(e)
            }

            //window.location.href = '/finalizedraft/recipients'
        })
        builder.addCase(checkDraftSessionId.rejected, (state) => {
            state.draftSessionIdError = true
            state.draftSessionIdChecked = false
            state.draftSessionIdData = null;
        })
    }
})

// Action creators are generated for each case reducer function
export const { resetState, setRedirectTo, resetTotp, logout, clearLoginError, clearForgotPasswordError, clearResetPasswordError, clearRegistrationError, clearRegistrationSuccess, clearActivationError } = authSlice.actions;

export const authSelector = (state) => state.auth;

export default authSlice.reducer;
