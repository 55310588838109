import {Dialog} from "@headlessui/react";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import ModalGrayFooter from "../Templates/ModalGrayFooter";
import Input from "../../../atoms/Inputs/Input/Input";
import FileUploadArea from "../../FileUploadArea/FileUploadArea";
import {useDispatch, useSelector} from "react-redux";
import {checkUploadEventFlowTypeThunk, uploadEventFlowTypeThunk} from "../../../../features/library/library.action";
import {librarySelector, resetImportProcessName} from "../../../../features/library/library.slice";
import LoadingSpinner from "../../../atoms/LoadingSpinner/LoadingSpinner";

const ModalImportProcess = ({open, setOpen, onSave, isSubmitting}) => {
    const {t} = useTranslation()

    const [name, setName] = useState('')
    const [uploadFile, setUploadFile] = useState(null)
    const dispatch = useDispatch();

    const {importProcessName} = useSelector(librarySelector);

    useEffect(() => {
        if (open) {
            dispatch(resetImportProcessName());
            setUploadFile(null);
            setName('');
        }
    },[open])

    useEffect(() => {
        if (importProcessName) {
            setName(importProcessName);
        }
    },[importProcessName])

    const handleChangeFile = async (file) => {
        setUploadFile(file[0])
        try{
            await dispatch(checkUploadEventFlowTypeThunk({file: file[0]})).unwrap();
        }catch(e){
            console.log(e.message);
        }
    }

    const onConfirm = () => {onSave(uploadFile, name)}

    return (
        <ModalGrayFooter open={open} setOpen={setOpen} onConfirm={onConfirm} confirmLabel={t('save')} saveDisabled={!name || !uploadFile || isSubmitting}>
            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full ">
                <Dialog.Title as="h1" className="text-xl leading-6 font-medium mb-4">
                    {t('library.importexport.title')}
                </Dialog.Title>
                {!uploadFile ?
                    <div className='space-y-4'>
                        <FileUploadArea onUpload={handleChangeFile} accept={{'application/zip': ['.zip']}} />
                    </div> :
                 <div className='space-y-4'>
                    <Input label={t('library.importexport.processName')} value={name} disabled={false} setValue={setName}/>
                </div>}

            </div>
        </ModalGrayFooter>
    )
}

export default ModalImportProcess;
