import Input from "../../../../atoms/Inputs/Input/Input";
import Textarea from "../../../../atoms/TextArea/Textarea";
import SelectWithBadge from "../../../AdvancedSelect/SelectWithBadge/SelectWithBadge";
import {useTranslation} from "react-i18next";
import {useState} from "react";
import SingleCheckbox from "../../../../atoms/SingleCheckbox/SingleCheckbox";
import ModalNewPathItem from "../ModalNewPathItem";

const ModalNewInformedPath = ({open, setOpen, recipients, onCreate = () => {}, insertTag=() => {}, deleteTag=() => {}}) => {
    const {t} = useTranslation()
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [tags, setTags] = useState();
    const [selectedRecipients, setSelectedRecipients] = useState({})
    const disableConfirm = !Object.keys(selectedRecipients).some(key => selectedRecipients[key]) || !name

    const onConfirm = () => {
        onCreate(name, description, tags, selectedRecipients)
    }

    const setValue = (id) => setSelectedRecipients({...selectedRecipients, [id]:!selectedRecipients[id]})
    return(
        <ModalNewPathItem onConfirm={onConfirm} setOpen={setOpen} open={open} disableConfirm={disableConfirm} title='Nuova informativa'>
            <div className='flex justify-center divide-x w-full flex-grow'>
                <div className='w-full pr-4'>
                    <h1 className="text-lg leading-6 font-medium mb-2">{t('informations')}</h1>
                    <Input label={t('title')} placeholder={t('informedPath.giveTitle')} value={name} setValue={setName}/>
                    <Textarea label={t('description')} value={description} setValue={setDescription}/>
                    <SelectWithBadge label={t('labels')} selectedTags={tags} setSelectedTags={setTags} insertTag={insertTag} deleteTag={deleteTag} hasDelete={true}/>
                </div>
                <div className='w-full pl-4'>
                    <h1 className="text-lg leading-6 font-medium mb-2">{t('informedPath.whoSeeTitle')}</h1>
                    <h1 className="text-sm leading-4 font-normal text-gray-500 mb-2">{t('informedPath.whoSeeMessage')}</h1>
                    <div className='space-y-2'>
                        {recipients && Object.values(recipients).map(rec => <div key={rec.id} className='flex items-center space-x-2'><SingleCheckbox label={`${rec.name}`} value={selectedRecipients[rec.id]} setValue={() => setValue(rec.id)} /> <p className='text-gray-400 text-sm'>{rec.email}</p></div>)}
                    </div>
                </div>
            </div>
        </ModalNewPathItem>
    )
}

export default ModalNewInformedPath
