import client from "./client";
import isArray from "lodash/isArray";
const FileSaver = require('file-saver');

export const uploadDocumentCall = async (documentalProcessId, file, isTemplate, isSubmodels, submodelToUpdateId) => {
    let data = new FormData();
    if (!isTemplate) {
        if (file.length) {
            for (let i = 0; i < file.length; i++) {
                data.append('file', file[i]);
            }
        } else {
            data.append('file', file);
        }
    } else {
        data.append('file', file);
    }
    data.append('isTemplate', isTemplate);
    data.append('authorization', sessionStorage.getItem('token'));
    let url = `/api/doc-api/doc-model/upload/${documentalProcessId}`;
    if (isSubmodels) {
        url += '?isSubmodels=true';
        if (submodelToUpdateId) {
            url += `&submodelToUpdateId=${submodelToUpdateId}`;
        }
    }
    return await client.post(url,
        data, {
            headers: {
                'processData': false,
            }
        });
}

export const getPlaceholdersCall = async (documentalProcessId) => {
    return await client.get(`api/doc-api/doc-model/available-placeholders/${documentalProcessId}`);
}

export const setPlaceholdersCall = async (documentalProcessId, data) => {
    return await client.post(`api/doc-api/doc-model/${documentalProcessId}/set-placeholders`, data);
}

export const setSubmodelsPlaceholdersCall = async (documentalProcessId, data) => {
    return await client.post(`api/doc-api/doc-model/${documentalProcessId}/set-submodels-placeholders`, data);
}

export const getDocumentDetailsCall = async (documentalProcessId) => {
    return await client.get(`api/doc-api/doc-model/${documentalProcessId}`);
}

export const updateDocumentDetailsCall = async (documentalProcessId, data) => {
    return await client.put(`api/doc-api/doc-model/${documentalProcessId}/update`, data);
}

export const deleteDocumentCall = async (documentalProcessId) => {
    return await client.delete(`api/doc-api/doc-model/${documentalProcessId}`);
}

export const previewDocumentCall = async (documentalProcessId, placeholders, submodelId) => {
    var win = null;
    if (!navigator || !navigator.msSaveBlob) {
        win=window.open('/report.html', '_blank');
    }
    let url = `/api/doc-api/doc-model/download-pdf-process-preview/${documentalProcessId}`;
    if (submodelId) {
        url += `?submodelId=${submodelId}`;
    }
    client.downloadFilePost(url, JSON.stringify(placeholders || []), function(blob) {//TODO configurare

        if (navigator && navigator.msSaveBlob) {
            navigator.msSaveBlob(blob, 'informativa.pdf');
        } else {
            win.location = URL.createObjectURL(blob);
        }
    });

}

export const exportDocumentCall = async (documentalProcessId, placeholders) => {
    //var win = window.open('/report.html', '_blank');
    client.downloadFile(`/api/library/event-flow-types/export/${documentalProcessId}`, function(blob) {//TODO configurare
        FileSaver.saveAs(blob, "export_" + new Date().getTime() + ".zip");
        //win.close();
    });
}
