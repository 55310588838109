import React from "react";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {classNames} from "../../../utils/helper";
import {ArrowsUpDownIcon, BoltIcon} from "@heroicons/react/24/solid";
import {reorderList} from "../../../utils/beautiful-dnd-helper";
import {useTranslation} from "react-i18next";
import LoadingSpinner from "../../atoms/LoadingSpinner/LoadingSpinner";
import IconButton from "../../atoms/Buttons/IconButton/IconButton";
import {TrashIcon} from "@heroicons/react/24/outline";

const ReorderElementsBoard = ({selectedItemId, setSelectedItemId, stepsList, setStepsList, loading=false, reorderMessage = '', addButton, onDeleteItem}) => {
    const {t} = useTranslation();
    const onDragEnd = (result) => {
        const orderedSteps = reorderList(result, stepsList)
        if(orderedSteps) setStepsList(orderedSteps)
    }

    return(
        <div className='flex flex-1 flex-col items-center bg-gray-100 w-full h-full border rounded-lg mb-3 py-6 px-8 overflow-hidden'>
            {loading ? <LoadingSpinner />:
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="list">
                        {provided => (
                            <>
                                <p className='text-gray-600 text-base leading-6 font-normal text-center mb-4'>{reorderMessage || t('reorder.message')} &nbsp; {addButton}
                                </p>
                                <div ref={provided.innerRef}
                                     className='w-full overflow-hidden' {...provided.droppableProps}>
                                    {stepsList && stepsList.map((step, index) => (
                                            <div className="w-full" key={step.id}>
                                                <Draggable draggableId={`draggable-${step.id}`} index={index}>
                                                    {(provided) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            className="py-2"
                                                        >
                                                            <div
                                                                className={classNames(
                                                                    selectedItemId === step.id
                                                                        ? "border-4 border-blue-400"
                                                                        : "border-transparent hover:border-gray-400",
                                                                    "relative w-full flex items-center bg-white shadow rounded-lg p-4 space-x-4 border-2"
                                                                )}
                                                                onClick={() => setSelectedItemId(step.id)}
                                                            >
                                                                <div {...provided.dragHandleProps}>
                                                                    <ArrowsUpDownIcon className="h-5 w-5"/>
                                                                </div>
                                                                <div
                                                                    className="flex rounded-full w-6 h-6 border-gray-300 border-2 justify-center items-center text-gray-500 text-xs leading-4 font-normal">
                                                                    {index + 1}
                                                                </div>
                                                                <div className="flex flex-col flex-grow truncate max-w-[calc(100%-80px)]">
                                                                    <p className="text-gray-500 text-xs leading-4 font-normal">
                                                                        {step.itemType === "FORM"
                                                                            ? t("forms.title")
                                                                            : step.itemType === "INFORMED_PATH"
                                                                                ? t("informedPath.title")
                                                                                : ""}
                                                                    </p>
                                                                    <p className="text-xs leading-5 font-medium truncate max-w-[calc(100%-80px)]">
                                                                        {step.filename ?? step.name}
                                                                    </p>
                                                                </div>
                                                                {onDeleteItem && (
                                                                    <div
                                                                        className="absolute right-4 top-1/2 transform -translate-y-1/2">
                                                                        <IconButton
                                                                            icon={
                                                                                <TrashIcon
                                                                                    className="h-4 w-4"
                                                                                    aria-hidden="true"
                                                                                />
                                                                            }
                                                                            variant={"red"}
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                                if (onDeleteItem) onDeleteItem(step.id);
                                                                            }}
                                                                        ></IconButton>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            </div>

                                        )
                                    )}
                                    {provided.placeholder}
                                </div>
                            </>
                        )}
                    </Droppable>
                </DragDropContext>}
        </div>
    )

}

export default ReorderElementsBoard;
