import { Dialog } from "@headlessui/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { userHomeSelector } from "../../../../features/userHome/userHome.slice";
import ModalOnlyClose from "../Templates/ModalOnlyClose";
import { ClipboardIcon, CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/outline";

const ModalFeqDetails = ({ open, setOpen, selectedMember }) => {
    const { t } = useTranslation();
    const { selectedTenantId, userOrganizations } = useSelector(userHomeSelector);

    const tenantRoles = userOrganizations?.[selectedTenantId]?.roles ?? [];
    const [selectedRole, setSelectedRole] = useState();
    const [copied, setCopied] = useState(false);

    useEffect(() => {
        if (tenantRoles.map(r => r.value).includes(selectedMember?.role)) {
            setSelectedRole(selectedMember?.role);
        } else {
            setSelectedRole();
        }
    }, [open]);

    // Funzione per copiare l'ID FEQ
    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
        setCopied(true);
        setTimeout(() => setCopied(false), 1500); // Reset dopo 1.5 secondi
    };

    // Formattazione date
    const enrollDate = selectedMember?.enrollmentDate ? new Date(selectedMember.enrollmentDate).toLocaleDateString() : "";
    const expireDate = selectedMember?.expireDate ? new Date(selectedMember.expireDate).toLocaleDateString() : "";
    const revocationDate = selectedMember?.revocationDate ? new Date(selectedMember.revocationDate).toLocaleDateString() : "";

    // Stato del certificato
    const certificateStatus = selectedMember?.status || '-';
    let realStatus = 'expired';
    if (certificateStatus === 'identificationstatus_enrolled') {
        realStatus = 'active';
    } else if (certificateStatus === 'identificationstatus_0' || certificateStatus === 'identificationstatus_1') {
        realStatus = 'pending';
    } else if (certificateStatus === 'identificationstatus_revoked' || certificateStatus === 'identificationstatus_expired' || certificateStatus === 'identificationstatus_2' || certificateStatus === 'identificationstatus_3' || certificateStatus === 'identificationstatus_4' || certificateStatus === 'identificationstatus_5' || certificateStatus === 'identificationstatus_6') {
        realStatus = 'expired';
    }

    const statusConfig = {
        active: { color: "text-green-600", Icon: CheckCircleIcon },
        expired: { color: "text-red-600", Icon: XCircleIcon },
        pending: { color: "text-yellow-600", Icon: ClipboardIcon }
    };
    const { color, Icon } = statusConfig[realStatus];
    const label = t(certificateStatus);


    return (
        <ModalOnlyClose open={open} setOpen={setOpen} onClose={() => setOpen(false)}>
            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                <Dialog.Title as="h1" className="text-xl leading-6 font-medium mb-4">
                    {selectedMember?.name}
                </Dialog.Title>

                <div className="space-y-4 text-gray-600">
                    {/* ID FEQ con pulsante copia e tooltip */}
                    {selectedMember?.remoteSignerId && (
                        <div className="flex items-center space-x-3">
                            <dd className="text-gray-500 text-xs font-medium">ID FEQ:</dd>
                            <div className="flex items-center border border-gray-300 px-3 py-1 rounded-lg bg-gray-100 relative">
                                <span className="text-sm font-mono text-gray-800">{selectedMember.remoteSignerId}</span>
                                <button
                                    onClick={() => copyToClipboard(selectedMember.remoteSignerId)}
                                    className="p-1 hover:text-blue-500 relative"
                                >
                                    <ClipboardIcon className="w-5 h-5" />
                                    {copied && (
                                        <span className="absolute -top-6 left-1/2 transform -translate-x-1/2 text-xs bg-black text-white px-2 py-1 rounded-md">
                                            Copiato!
                                        </span>
                                    )}
                                </button>
                            </div>
                        </div>
                    )}

                    {/* Stato certificato */}
                    <div className="flex items-center space-x-2">
                        <Icon className={`w-5 h-5 ${color}`} />
                        <span className={`text-sm font-medium ${color}`}>{label}</span>
                    </div>

                    {/* Date di enroll e scadenza */}
                    <div className="text-xs space-y-1">
                        {enrollDate && <p><strong>Data attivazione</strong> {enrollDate}</p>}
                        {expireDate && <p><strong>Data scadenza</strong> {expireDate}</p>}
                        {revocationDate && <p><strong>Data revoca</strong> {revocationDate}</p>}
                    </div>
                </div>
            </div>
        </ModalOnlyClose>
    );
};

export default ModalFeqDetails;
