import {Fragment} from 'react'
import {Listbox, Transition} from '@headlessui/react'
import {classNames} from "../../../../utils/helper";
import {useTranslation} from "react-i18next";
import {ChevronUpDownIcon} from "@heroicons/react/24/solid";

const SelectSignatureType = ({options, label, placeholder = '', value, setValue }) => {
    const {t} = useTranslation();
    const selected = options.find(opt => opt.id === value)
    return (
        <Listbox value={value} onChange={setValue}>
            {({ open }) => (
                <>
                    <Listbox.Label className="block text-sm font-medium text-gray-700">{label}</Listbox.Label>
                    <div className="mt-1 relative">
                        <Listbox.Button className="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                            <span className="block truncate">{selected?.description ?? placeholder}</span>
                            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </span>
                        </Listbox.Button>
                        <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                {options.map((signature) => (
                                    <Listbox.Option
                                        key={signature.id}
                                        className={({ active, disabled }) =>
                                            classNames(
                                                active ? 'text-white bg-blue-600' : disabled ? 'bg-gray-100' : 'text-gray-900',
                                                'cursor-default select-none relative py-2 pl-3 pr-9'
                                            )
                                        }
                                        value={signature.id}
                                        disabled={signature.disabled || signature?.remaining === 0}
                                        as='div'
                                        title={signature?.disabled ? t('signature.disabled') : ''}
                                    >
                                        {({ selected, active }) => (
                                            <>
                                                <span className={classNames(selected ? 'font-semibold' : 'font-normal',
                                                    signature?.disabled && 'text-red-600', 'block truncate')}>
                                                    {signature.description}
                                                </span>
                                                <span className={classNames(active ? 'text-white' :
                                                        signature?.remaining > 5 ? 'text-green-600' : signature?.remaining === 0 ? 'text-red-600' : 'text-yellow-600',
                                                    'absolute inset-y-0 right-0 flex items-center pr-4')}
                                                >
                                                    <p>{signature?.remaining >= 0 ? signature?.remaining : ''}</p>
                                                </span>
                                            </>
                                        )}
                                    </Listbox.Option>
                                ))}
                            </Listbox.Options>
                        </Transition>
                    </div>
                </>
            )}
        </Listbox>
    )
}

export default SelectSignatureType;
