import {useNavigate} from "react-router-dom";
import {FULL} from "../../atoms/Buttons/sizes";
import Button from "../../atoms/Buttons/Button/Button";
import pageNotFoundIllustration from '../../../assets/illustrations/1_Illustrazione_Confirmo_Errore.svg'
import {useTranslation} from "react-i18next";

const PageNotFound = () => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    return(
        <div className='flex w-full h-screen flex-col items-center bg-background bg-no-repeat bg-right-top bg-50% bg-scroll'>
            <div className='flex flex-col justify-center w-full max-w-lg flex-1 my-10'>
                <img src={pageNotFoundIllustration} alt='Page not found'/>
                <h1 className='text-2xl leading-8 font-medium pr-1'>{t('pageNotFound.title')}</h1>
                <p className='text-base leading-6 font-normal text-gray-500'>{t('pageNotFound.message')}</p>
                <div className='flex items-center my-6'>
                    <Button label='Torna alla Home' size={FULL} onClick={() => navigate('/home')}/>
                </div>
            </div>
        </div>
    )
}

export default PageNotFound;
