import StepHeader from "../../../molecules/Headers/StepHeader/StepHeader";
import Footer from "../../../molecules/Footer/Footer";
import {classNames} from "../../../../utils/helper";
import {useTranslation} from "react-i18next";
import {useState} from "react";


const PageWithStepsHeaderAndFooter = ({children, footer = true, header= true, next, previous, background = true, closeAction, disableConfirm = false, steps, confirmLabel, cancelLabel, hidePrevious=false, buildUrl=()=>{}}) => {
    const {t} = useTranslation()
    const [waitNext, setWaitNext] = useState(false)

    const confirm = async () => {
        setWaitNext(true)
        try {
            await next()
        }finally {
            setWaitNext(false)
        }
    }

    return (
        <div className={classNames(background ? 'bg-background bg-no-repeat bg-right-top bg-50% bg-scroll' : '',
            'absolute top-0 left-0 right-0 bottom-0 flex flex-col items-stretch bg-white overflow-hidden')}>
            {header && <div className='sticky top-0 z-10'>
                <StepHeader steps={steps} closeAction={closeAction} buildUrl={buildUrl} />
            </div>}

            <div className='sticky flex flex-1 justify-center overflow-auto'>
                {children}
            </div>
            {footer && <div className='sticky bottom-0 z-10 flex justify-center items-end w-full'>
                <Footer type='confirm' confirm={confirm} cancel={previous} hidePrevious={hidePrevious} disableConfirm={disableConfirm || waitNext} confirmLabel={confirmLabel ?? t('next')} cancelLabel={cancelLabel ?? t('actions.cancel')}/>
            </div> }
        </div>
    )
}

export default PageWithStepsHeaderAndFooter;
