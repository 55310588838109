import {classNames} from "../../../../utils/helper";
import {Disclosure} from "@headlessui/react";
import {useDispatch} from "react-redux";

import {logout} from "../../../../features/auth/auth.slice";
import {Link} from "react-router-dom";
import {BellIcon} from "@heroicons/react/24/outline";

const MobileMenu = ({tabs, selectMenuOption, selectedTabName, user}) => {
    const dispatch = useDispatch();

    const signout = async () => {
        dispatch(logout({}))
    }

    return (
        <Disclosure.Panel className="md:hidden">
            <div className="pt-2 pb-3 space-y-1">
                {/* Current: "bg-indigo-50 border-indigo-500 text-indigo-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" */}

                {tabs && tabs.map(
                    tab => <div
                        key={tab.name}
                        onClick={() => selectMenuOption(tab.name)}
                        className={classNames(tab.name === selectedTabName ?
                            'bg-blue-50 border-blue-500 text-blue-700' :
                            'border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700',
                            `block pl-3 pr-4 py-2 border-l-4 text-base font-medium sm:pl-5 sm:pr-6 cursor-pointer`)}
                    >
                        {tab.name}
                    </div>)}
            </div>
            <div className="pt-4 pb-3 border-t border-gray-200">
                <div className="flex items-center px-4 sm:px-6">
                    <div className="flex-shrink-0">
                        <img
                            className="h-10 w-10 rounded-full"
                            src={user.avatar}
                            alt=""
                        />
                    </div>
                    <div className="ml-3">
                        <div className="text-base font-medium text-gray-800">{user.name}</div>
                        <div className="text-sm font-medium text-gray-500">{user.contactEmail}</div>
                    </div>
                    <button className="ml-auto flex-shrink-0 bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                        <span className="sr-only">View notifications</span>
                        <BellIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                </div>
                <div className="mt-3 space-y-1">
                    {/*<a*/}
                    {/*    href="#"*/}
                    {/*    className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100 sm:px-6"*/}
                    {/*>*/}
                    {/*    Your Profile*/}
                    {/*</a>*/}
                    <Link
                        to="/Settings/PersonalData"
                        className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100 sm:px-6"
                    >
                        Impostazioni
                    </Link>
                    <Link
                        onClick={signout}
                        to="/login"
                        className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100 sm:px-6"
                    >
                        Esci
                    </Link>
                </div>
            </div>
        </Disclosure.Panel>
    )
}

export default MobileMenu;
