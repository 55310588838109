import {useNavigate, useParams} from "react-router-dom";
import {
    documentalProcessSelector,
    nextPage,
    selectWorkspace,
    setDocumentSelectedDevice,
    setExpirationDate,
    setMessageBody,
    setMessageSubject,
    setReminderIntervalDays,
    setSenderReminderComplete,
    setAvoidLtArchive,
    setStepsByProcessType
} from "../../../features/documentalProcess/documentalProcess.slice";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect} from "react";
import SelectRecipients from "../SendProcess/SelectRecipients";
import {
    addRecipient,
    recipientsSelector,
    removeRecipient,
    setRecipient,
    setRecipientsList,
    setRedirectLink,
    setSendNoEmail,
    setSendNoEmailSigned
} from "../../../features/recipients/recipients.slice";
import {addRecipientsThunk, getSignatureTypesListThunk} from "../../../features/recipients/recipients.actions";
import {sendDocumentThunk, upsertProcessThunk} from "../../../features/documentalProcess/documentalProcess.actions";
import DocumentStep from "../ProcessSteps/DocumentStep/DocumentStep";
import Summary from "../ProcessSteps/Summary/Summary";
import EndSendProcess from "../SendProcess/EndSendProcess/EndSendProcess";
import {librarySelector} from "../../../features/library/library.slice";
import {unmapRecipient} from "../../../utils/helper";
import {documentBuilderSelector} from "../../../features/documentBuilder/documentBuilder.slice";
import documentSentIllustration
    from '../../../assets/illustrations/4_Illustrazione_Confirmo_Documento-Inviato-Successo.svg'
import {sendProcessSelector} from "../../../features/sendProcess/sendProcess.slice";
import {useTranslation} from "react-i18next";
import {getDevices} from "../../../features/library/library.action";
import {getGraphConsentsThunk} from "../../../features/docModels/docModels.actions";
import {docModelsSelector} from "../../../features/docModels/docModels.slice";
import {userHomeSelector} from "../../../features/userHome/userHome.slice";
import {getAvailableWorkspacesThunk} from "../../../features/userHome/userHome.actions";
import {WORKSPACES} from "../../../utils/permissions";
import isEmpty from "lodash/isEmpty";

const NewDocument = () => {
    const {t} = useTranslation()
    const {step} = useParams();
    const navigate = useNavigate()
    const dispatch = useDispatch();

    const {steps, messageBody, messageSubject, reminderIntervalDays, selectedDevice, waitingSend, isDocumentSent, expirationDate, selectedWorkspaces, senderReminderComplete, avoidLtArchive} = useSelector(documentalProcessSelector)
    const {recipients, signatureTypesList} = useSelector(recipientsSelector)
    const {documentInfo} = useSelector(documentBuilderSelector)
    const {devices} = useSelector(librarySelector)
    const {graphConsents} = useSelector(docModelsSelector)
    const {loadingRecipients} = useSelector(sendProcessSelector)
    const {availableWorkspaces, userOrganizations, selectedTenantId} = useSelector(userHomeSelector)
    const permissions = userOrganizations?.[selectedTenantId]?.permissions ?? {}

    const getAvailableWorkspaces = () => dispatch(getAvailableWorkspacesThunk())

    const buildUrl = (url) => url

    useEffect(() => {
        dispatch(setStepsByProcessType({type:'senddocument'}))
        dispatch(upsertProcessThunk({name: 'Invio documento', isDocument: true}))
        dispatch(getSignatureTypesListThunk())
        dispatch(getDevices())
        dispatch(getGraphConsentsThunk({onlyActive: true}))
        if (step !== 'recipients') navigate(`/sendprocess/recipients`, {replace: true})
        getAvailableWorkspaces()
    }, [])


    const goBackHome = () => navigate('/home')

    const next = () => {
        const actualId = steps.findIndex(s => s.urlName === step);
        if (actualId < steps.length - 1) {
            navigate(buildUrl(steps[actualId + 1].href))
            dispatch(nextPage({step: step}))
        }
    }

    const previous = () => {
        const actualId = steps.findIndex(s => s.urlName === step);
        if (actualId > 0) {
            navigate(buildUrl(steps[actualId - 1].href))
        } else navigate('/home')
    }

    const deleteRecipient = (recipient) => dispatch(removeRecipient(recipient.id))

    const confirmRecipients = async (isOrdered = false) => {
        await dispatch(addRecipientsThunk({recipients, isOrdered})).unwrap()
        next();
    }

    const setSingleRecipient = async (rec) => await dispatch(setRecipient(unmapRecipient(rec)))

    const setHasGraphConsentToRecipient = (id, hasGraphConsent) => dispatch(setRecipient({...recipients[id], hasGraphConsent}))
    const setNoEmailSent = (id, noEmail) => dispatch(setSendNoEmail({id, value: noEmail}))
    const setNoEmailSignedSent = (id, noEmailSigned) => dispatch(setSendNoEmailSigned({id, value: noEmailSigned}))
    const setSelectedWorkspaces = (workspace) => dispatch(selectWorkspace(workspace))

    const listDevices = Object.values(recipients).some(user => user.signatureType === 'GRAPH') ? devices : []

    const onSetRedirectLink = (recipientId, redirectLink) => dispatch(setRedirectLink({id: recipientId, redirectLink}))

    const sendDocument = async () => {
        await dispatch(sendDocumentThunk()).unwrap()
        next();
    }

    const mapRecipients = [{
        recipients: !isEmpty(recipients) ?
            Object.values(recipients).map(user => {
                return {
                    id: user?.id,
                    role: user?.role,
                    userid: user?.user?.id,
                    givenName: user?.user?.givenName,
                    familyName: user?.user?.familyName,
                    email: user?.user?.email,
                    signatureType: user?.signatureType,
                    peopleRole: user?.peopleRole,
                    redirectLink: user?.redirectLink,
                    hasGraphConsent: user?.hasGraphConsent,
                    graphConsentModelId: user?.graphConsentModelId,
                    lastGraphConsentGivenDate: user?.lastGraphConsentGivenDate,
                    emailDetails: user?.emailDetails,
                    progressNumber: user?.progressNumber,
                    actorType: user?.actorType,
                    isList: user?.isList,
                    listId: user?.listId,
                    listName: user?.listName,
                    users: user?.users ?? [],
                }
            }) : []
    }]

    switch (step) {
        case 'recipients':
            return (
                <SelectRecipients
                    next={confirmRecipients}
                    previous={previous}
                    steps={steps}
                    goBackHome={goBackHome}
                    isEditable={true}
                    process={mapRecipients}
                    setRecipients={(recipientsList) => dispatch(setRecipientsList(recipientsList.map(rec => unmapRecipient(rec))))}
                    addRecipient={() => {dispatch(addRecipient({isUser: true}))}}
                    deleteRecipient={deleteRecipient}
                    setSingleRecipient={setSingleRecipient}
                    signatureTypesList={signatureTypesList}
                    loadingRecipients={loadingRecipients}
                    graphTemplatesOptions={graphConsents?.map(gc=>({value: gc.id, label: gc.name})) ?? []}
                    buildUrl={buildUrl}
                />
            )
        case 'document':
            return (
                <DocumentStep
                    next={next}
                    previous={previous}
                    steps={steps}
                    buildUrl={buildUrl}
                    isProcess={false}
                />
            )
        case 'summary':
            return (
                <Summary
                    next={sendDocument}
                    previous={previous}
                    steps={steps}
                    recipients={Object.values(recipients)}
                    messageBody={messageBody}
                    messageSubject={messageSubject}
                    expirationDate={expirationDate}
                    reminderInterval={reminderIntervalDays}
                    setMessageBody={(value) => dispatch(setMessageBody(value))}
                    setMessageSubject={(value) => dispatch(setMessageSubject(value))}
                    setReminderInterval={(value) => dispatch(setReminderIntervalDays(value))}
                    setSenderReminderComplete={(value) => dispatch(setSenderReminderComplete(value))}
                    senderReminderComplete={senderReminderComplete}
                    avoidLtArchive={avoidLtArchive}
                    editAvoidLtArchiveChoice={true}
                    setAvoidLtArchive={(value) => dispatch(setAvoidLtArchive(value))}
                    setExpirationDate={(value) => dispatch(setExpirationDate(value))}
                    confirmLabel={t('summary.send')}
                    devices={listDevices}
                    selectedDevice={selectedDevice}
                    setSelectedDevice={(value) => dispatch(setDocumentSelectedDevice(value))}
                    filename={documentInfo.filename}
                    setRedirectLink={onSetRedirectLink}
                    setHasGraphConsentToRecipient={setHasGraphConsentToRecipient}
                    setNoEmailSent={setNoEmailSent}
                    setNoEmailSignedSent={setNoEmailSignedSent}
                    availableWorkspaces={availableWorkspaces}
                    selectedWorkspaces={selectedWorkspaces}
                    setSelectedWorkspaces={setSelectedWorkspaces}
                    hasWorkspacesPermissions={permissions[WORKSPACES]}
                    buildUrl={buildUrl}
                />)
        case 'end':
            return (
                <EndSendProcess illustration={documentSentIllustration} isDocumentSent={isDocumentSent} isLoading={waitingSend}/>
            )
        default:
            return <div><p>DEFAULT</p></div>
    }
}

export default NewDocument;
