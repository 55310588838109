import Textarea from "../../atoms/TextArea/Textarea";
import Quiz from "./CustomFields/Quiz";
import Video from "./CustomFields/Video";
import Image from "./CustomFields/Image";
import {useTranslation} from "react-i18next";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import InformedPathPdf from "./CustomFields/InformedPathPdf";

const SidebarInformedPathCustomFields = ({textBody, setTextBody, step, questions=[], setQuestions, handleChangePhoto, errorVideo, iframe, videoLink, setVideoLink, isLoading, wrongAnswerStepId, setWrongAnswerStepId, allSteps }) => {
    const {t}=useTranslation()

    switch (step?.typeSymbol){
        case 'message':
            //return <Textarea value={textBody} setValue={setTextBody} label={t('body')}/>
            return (
            <>
                <ReactQuill
                    value={textBody}
                    onChange={setTextBody}
                    theme='snow'
                />
            </>
        )
        case 'img':
            return <Image contentUrl={step?.contentUrl} handleChangePhoto={handleChangePhoto} imageLoading={isLoading}/>
        case 'pdf':
            return <InformedPathPdf fileName={step?.fileName} contentUrl={step?.contentUrl} handleUploadPDF={handleChangePhoto} pdfLoading={isLoading}/>
        case 'youtube':
            return <Video errorVideo={errorVideo} iframe={iframe} videoLink={videoLink} setVideoLink={setVideoLink}/>
        case 'quiz':
            return <>
                <Textarea value={textBody} setValue={setTextBody} label={t('quiz.body')}/>
                <p className='text-sm leading-5 font-medium my-4'>{t('quiz.answers')}</p>
                <Quiz stepId={step?.id} questions={questions} setQuestions={setQuestions} wrongAnswerStepId={wrongAnswerStepId} setWrongAnswerStepId={setWrongAnswerStepId} steps={allSteps}/>
            </>
        default:
            return <></>
    }
}


export default SidebarInformedPathCustomFields;
