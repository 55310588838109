import client from "./client";
import {encrypt, getFilenameFromContentDisposition} from "../utils/helper";
import axios from "axios";
import {generateEventFlowTypeThunk} from "../features/library/library.action";
import {v4} from "uuid";
const FileSaver = require('file-saver');


//proc doc
export const listEventFlowTypes = async (props) => {
    var url = '/api/library/event-flow-types?';
    if (props.pagination && props.pagination.offset) {
        url += 'offset=' + props.pagination.offset;
    } else {
        url += 'offset=0';
    }
    if (props.pagination && props.pagination.limit) {
        url += '&limit=' + props.pagination.limit;
    }
    if (props.txtFilter) {
        url += '&filter=' + props.txtFilter;
    }
    if (props.isDraft) {
        url += '&isDraft=true';
    }
    if(props.sortField)
        url += '&sortField=' + props.sortField + '&desc=' + props.descending;

    return await client.get(url);
}

export const countListEventFlowTypes = async (props) => {
    var url = '/api/library/event-flow-types/count';
    if (props.txtFilter) {
        url += '?filter=' + props.txtFilter;
    }
    if (props.isDraft) {
        if (url.indexOf('?') >= 0) {
            url += '&';
        } else {
            url += '?';
        }
        url += 'isDraft=true';
    }

    return await client.get(url);
}

export const deleteEventFlowType = async (id) => {
    var url = '/api/library/event-flow-types/delete/' + id;
    return await client.delete(url);
}

export const copyEventFlowType = async (eventFlowTypeId) => {
    var url = '/api/library/event-flow-types/copy';
    return await client.post(url,{eventFlowTypeId});
}

export const copyInformedPath = async (informedPathId) => {
    var url = '/api/library/informed-paths/copy';
    return await client.post(url,{informedPathId});
}

export const copyForm = async (formId) => {
    var url = '/api/library/forms/copy';
    return await client.post(url,{formId});
}

//informative
export const listInformedPaths = async (props) => {
    var url = '/api/library/informed-paths?';
    if (props.pagination && props.pagination.offset) {
        url += 'offset=' + props.pagination.offset;
    } else {
        url += 'offset=0';
    }
    if (props.pagination && props.pagination.limit) {
        url += '&limit=' + props.pagination.limit;
    }
    if (props.txtFilter) {
        url += '&filter=' + props.txtFilter;
    }
    if(props.sortField)
        url += '&sortField=' + props.sortField + '&desc=' + props.descending;

    return await client.get(url);
}

export const countListInformedPaths = async (props) => {
    var url = '/api/library/informed-paths/count';
    if (props.txtFilter) {
        url += '?filter=' + props.txtFilter;
    }

    return await client.get(url);
}

export const deleteInformedPath = async (props) => {
    var url = '/api/library/informed-paths/delete/' + props.id;
    return await client.delete(url);
}

export const updateProcessPublished = async (props) => {
    var url = '/api/library/event-flow-types/publish/' + props.id;
    return await client.patch(url, {published: props.published});
}

export const  uploadEventFlowTypeCall = async (file, name) => {
    let data = new FormData();
    data.append('file', file);
    data.append('name', name);
    return await client.post(`/api/library/event-flow-types/upload-export`, data);
}

export const generateEventFlowTypeCall = async (file, name) => {
    let data = new FormData();
    data.append('file', file);
    return await client.post(`/api/library/event-flow-types/generate-process`, data);
}

export const  checkUploadEventFlowTypeCall = async (file) => {
    let data = new FormData();
    data.append('file', file);
    return await client.post(`/api/library/event-flow-types/check-upload-export`, data);
}


//forms
export const listForms = async (props) => {
    var url = '/api/library/forms?';
    if (props.pagination && props.pagination.offset) {
        url += 'offset=' + props.pagination.offset;
    } else {
        url += 'offset=0';
    }
    if (props.pagination && props.pagination.limit) {
        url += '&limit=' + props.pagination.limit;
    }
    if (props.txtFilter) {
        url += '&filter=' + props.txtFilter;
    }
    if(props.sortField)
        url += '&sortField=' + props.sortField + '&desc=' + props.descending;

    return await client.get(url);
}

export const countListForms = async (props) => {
    var url = '/api/library/forms/count';
    if (props.txtFilter) {
        url += '?filter=' + props.txtFilter;
    }

    return await client.get(url);
}

export const deleteForm = async (props) => {
    var url = '/api/library/forms/delete/' + props.id;
    return await client.delete(url);
}


//modelli
export const listModels = async (props) => {
    var url = '/api/library/models?';
    if (props.pagination && props.pagination.offset) {
        url += 'offset=' + props.pagination.offset;
    } else {
        url += 'offset=0';
    }
    if (props.pagination && props.pagination.limit) {
        url += '&limit=' + props.pagination.limit;
    }
    if (props.txtFilter) {
        url += '&filter=' + props.txtFilter;
    }

    return await client.get(url);
}

export const countListModels = async (props) => {
    var url = '/api/library/models/count';
    if (props.txtFilter) {
        url += '?filter=' + props.txtFilter;
    }

    return await client.get(url);
}

//documenti inviati
export const listSentEvents = async (props={}) => {
    var url = '/api/manage/sent-events?';
    if (props.pagination && props.pagination.offset) {
        url += 'offset=' + props.pagination.offset;
    } else {
        url += 'offset=0';
    }
    if (props.pagination && props.pagination.limit) {
        url += '&limit=' + props.pagination.limit;
    }
    if (props.txtFilter) {
        url += '&filter=' + props.txtFilter;
    }
    if (props.flgIncludiCompletati) {
        url += '&includeCompleted=true';
    }
    if(props.sortField)
        url += '&sortField=' + props.sortField
    if(props.descending)
        url += '&desc=' + props.descending;
    if(props.startDate)
        url += '&startDate=' + props.startDate;
    if(props.endDate)
        url += '&endDate=' + props.endDate;
    return await client.get(url);
}

export const listArchieve = async (props={}) => {
    var url = '/api/manage/archieved-documents?';
    if (props.pagination && props.pagination.offset) {
        url += 'offset=' + props.pagination.offset;
    } else {
        url += 'offset=0';
    }
    if (props.pagination && props.pagination.limit) {
        url += '&limit=' + props.pagination.limit;
    }
    if (props.docId) {
        url += '&docId=' + props.docId;
    }
    if (props.refPerson) {
        url += '&refPerson=' + props.refPerson;
    }
    return await client.get(url);
}

export const downloadArchieveZip = async (documentId) => {
    var win = null;
    if (!navigator || !navigator.msSaveBlob) {
        win=window.open(process.env.PUBLIC_URL + '/report.html', '_blank');
    }
    client.downloadFile('/api/manage/download-archieved-document?docId=' +  documentId, function(blob) {
        const fileName = "archieve_" + documentId + '__' + new Date().getTime() + ".zip";
        if (navigator && navigator.msSaveBlob) {
            navigator.msSaveBlob(blob, fileName);
        } else {
            FileSaver.saveAs(blob, fileName);
            win.close();
        }
    });

}


export const listSentEventsDetails = async (id) => {
    return await client.get(`/api/manage/sent-events/${id}`)
}

export const listOrganizationEventsDetails = async (id) => {
    return await client.get(`/api/manage/organization-events-details/${id}`)
}

//documenti workspace
export const listOrganizationEvents = async (props) => {
    var url = `/api/manage/organization-events${props.workspaceId ? '/'+props.workspaceId : '/4'}?`;

    if (props?.pagination?.offset) url += 'offset=' + props.pagination.offset;
    else url += 'offset=0';

    if (props?.pagination?.limit) url += '&limit=' + props.pagination.limit;
    if (props?.txtFilter) url += '&filter=' + props.txtFilter;
    if (props?.flgIncludiCompletati) url += '&includeCompleted=true';
    if (props?.sortField) url += '&sortField=' + props.sortField
    if (props?.descending) url += '&desc=' + props.descending;
    if (props?.startDate) url += '&startDate=' + props.startDate;
    if (props?.endDate) url += '&endDate=' + props.endDate;
    return await client.get(url);
}

//documenti canceled
export const listCanceledEvents = async (props) => {
    let url = '/api/manage/canceled-events?';
    if (props?.pagination?.offset) {
        url += 'offset=' + props.pagination.offset;
    } else {
        url += 'offset=0';
    }
    if (props?.pagination?.limit) {
        url += '&limit=' + props.pagination.limit;
    }
    if (props.txtFilter) {
        url += '&filter=' + props.txtFilter;
    }
    if(props.sortField)
        url += '&sortField=' + props.sortField
    if(props.descending)
        url += '&desc=' + props.descending;
    return await client.get(url);
}

export const deleteSentEventCall = async(eventId) =>{
    return await client.delete(`/api/manage/sent-events/${eventId}`);
}

export const restoreCanceledEventCall = (eventFlowActivityId, sendEmail=false) =>{
    return client.post(`/api/manage/sent-events/restore/${eventFlowActivityId}`, {sendEmail});
}

//doc in attesa
export const listWaitingEvents = async (props) => {
    var url = '/api/manage/waiting-events?';
    if (props.pagination && props.pagination.offset) {
        url += 'offset=' + props.pagination.offset;
    } else {
        url += 'offset=0';
    }
    if (props.pagination && props.pagination.limit) {
        url += '&limit=' + props.pagination.limit;
    }
    if (props.txtFilter) {
        url += '&filter=' + props.txtFilter;
    }
    if (props.flgIncludiCompletati) {
        url += '&includeCompleted=true';
    }
    if(props.sortField)
        url += '&sortField=' + props.sortField
    if(props.descending)
        url += '&desc=' + props.descending;
    if(props.startDate)
        url += '&startDate=' + props.startDate;
    if(props.endDate)
        url += '&endDate=' + props.endDate;
    return await client.get(url);
}

export const getWaitingEventDetailsCall = async (id) => {
    return await client.get(`/api/manage/waiting-events/${id}`)
}

//doc in attesa
export const listEventsMassiveSignature = async (props) => {
    var url = '/api/manage/massive-signature-events?';
    if (props.pagination && props.pagination.offset) {
        url += 'offset=' + props.pagination.offset;
    } else {
        url += 'offset=0';
    }
    if (props.pagination && props.pagination.limit) {
        url += '&limit=' + props.pagination.limit;
    }
    if (props.txtFilter) {
        url += '&filter=' + props.txtFilter;
    }
    if(props.sortField)
        url += '&sortField=' + props.sortField
    if(props.descending)
        url += '&desc=' + props.descending;
    if(props.startDate)
        url += '&startDate=' + props.startDate;
    if(props.endDate)
        url += '&endDate=' + props.endDate;

    return await client.get(url);
}

export const downloadPDF = async (url) => {
    var win = null;
    if (!navigator || !navigator.msSaveBlob) {
        win=window.open('/report.html', '_blank');
    }
    client.downloadFile(url, function(blob, xhr) {//TODO configurare

        // Controlla il tipo MIME del blob
        const mimeType = blob.type;

        // Estrai il nome del file dall'header Content-Disposition
        const contentDisposition = xhr?.getResponseHeader('content-disposition');
        let filename = getFilenameFromContentDisposition(contentDisposition) || v4() + '.pdf';

        // Se il tipo MIME indica un file p7m, preserva l'estensione
        if (mimeType === 'application/pkcs7-mime' && !filename.endsWith('.p7m')) {
            filename = filename.replace(/(\.[^.]+)?$/, '.p7m'); // aggiunge .p7m se non presente
        }

        if (navigator && navigator.msSaveBlob) {
            navigator.msSaveBlob(blob, filename);
        } else {
            if (mimeType === 'application/pkcs7-mime') {
                FileSaver.saveAs(blob, filename);
                win.close();
            } else {
                win.location = URL.createObjectURL(blob);
            }
        }


    });
}

export const downloadPDFSummary = async (url) => {
    var win = null;
    if (!navigator || !navigator.msSaveBlob) {
        win=window.open('/report.html', '_blank');
    }
    client.downloadFile(url, function(blob) {//TODO configurare

        if (navigator && navigator.msSaveBlob) {
            navigator.msSaveBlob(blob, 'informativa.pdf');
        } else {
            win.location = URL.createObjectURL(blob);
        }
    });
}

//tablet (devices)
export const listDevices = async (offset=0, limit, filter= '') => {
    const url = `/api/library/devices`
    return await client.get(url, {params: {offset, ...{limit}, filter}});
}

export const listUserSignatures = async () => {
    var url = '/api/users-profile/signatures';
    return await client.get(url);
}

//TODO bozza da finire
export const listOtherUserSignatures = async () => {
    var url = '/api/users-profile/other-user-signatures';
    return await client.get(url);
}

export const doEnableSignature = async (props) => {
    var url = '/api/signature/enable-signature/' + props.code ;
    return await client.post(url, props.data);
}

export const doChangeFeqPin = async (props) => {
    var url = '/api/signature/changeFeqPin';
    return await client.post(url, props);
}


export const sendToTabletCall = async (eventId, deviceId) => {
    return await client.post(`/api/manage/open-event-on-tablet/${eventId}/device/${deviceId}`);
}

export const callGetRemoteSignatureUser = async (props) => {
    var url = '/api/signature/getUser';
    return await client.post(url, props);
}


export const doRemoteMassiveSignatureCall = async (eventIds, pin, pIp, otp, deviceId) => {
    return await client.post(`/api/signature/doRemoteMassiveSignature`, {pin: 'ENC:' + encrypt(pin), eventIds, otp, deviceId}, {headers: {'pip': encrypt(pIp)}});
}

export const requestOtpFeqCall = async (pin) => {
    return await client.post(`/api/signature/requestOtpFeq`, {pin: 'ENC:' + encrypt(pin)});
}



export const countListDevices = async (props) => {
    var url = '/api/library/devices/count';
    if (props.txtFilter) {
        url += '?filter=' + props.txtFilter;
    }

    return await client.get(url);
}

export const addDeviceCall = async (label) => {
    return await client.get(`/api/library/devices/qrcode`,{params:{label},responseType: 'arraybuffer'})
}

export const deleteDeviceCall = async (deviceId) => {
    return await client.delete(`/api/library/devices/${deviceId}`)
}

export const updateDeviceCall = async (deviceId, label) => {
    return await client.patch(`/api/library/devices/${deviceId}`, {label})
}

export const addEventFlowActivityToWorkspaceCall = async (workspaceId, eventFlowActivityId) => {
    return await client.post(`/api/workspaces/addEvent`, {workspaceId, eventFlowActivityId})
}

export const removeEventFlowActivityFromWorkspaceCall = async (workspaceId, eventFlowActivityId) => {
    return await client.post(`/api/workspaces/removeEvent`, {workspaceId, eventFlowActivityId})
}

export const resendEmailFromEventCall = async (eventId) => {
    return await client.post(`/api/manage/sent-events/resendEmail`, {eventId})
}

export const retrievePiPCall = async () => {
    return await axios.get('https://api.ipify.org?format=json');
}
