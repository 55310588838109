import {useDispatch, useSelector} from "react-redux";
import React from "react";
import ModalNotificationDetail from "../../../molecules/Modals/ModalNotificationDetail/ModalNotificationDetail";
import {closeNotificationDetail, userHomeSelector} from "../../../../features/userHome/userHome.slice";

const NotificationDetailView = ({data}) => {
    const dispatch = useDispatch();

    const { notificationDetailOpened} = useSelector(userHomeSelector)
    const closeDetail = async (props) => {
        dispatch(closeNotificationDetail({}));
    }

    return(
        <ModalNotificationDetail
            open={notificationDetailOpened}
            setOpen={closeDetail}
        />


    )
}

export default NotificationDetailView;
