import Modal from "./Modal";
import React, {useRef} from "react";
import Button from "../../../atoms/Buttons/Button/Button";
import {useTranslation} from "react-i18next";

const ModalOnlyClose = ({children, open, setOpen, onClose}) => {
    const cancelButtonRef = useRef()
    const {t} = useTranslation()

    const footer = <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
        <div className='space-x-2'>
            <Button label={t('close')} onClick={onClose}/>
        </div>
    </div>

    return(
        <Modal open={open} setOpen={setOpen} cancelButtonRef={cancelButtonRef}
               footer={footer}
        >
            {children}
        </Modal>
    )
}

export default ModalOnlyClose;
