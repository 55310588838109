export const BUTTON = 'button'
export const DROPDOWN = 'dropdown'
export const DROPDOWN_ICON = 'dropdown-icon'
export const ICON_BUTTON = 'icon-button'
export const FAVOURITE = 'favourite'
export const SIGN = 'sign'
export const STATUS = 'status'
export const ARCHIEVE_STATUS = 'archieve-status'
export const REMAINING_SIGNATURES = 'remaining-signatures'
export const PUBLISHING_STATUS = 'publishing-status'
export const CHECKBOX = 'checkbox'
export const ICON_BUTTON_LIST = 'icon-button-list'
export const TAGS = 'tags'
export const DATE = 'date'
export const TIMESTAMP = 'timestamp'
export const CHECK = 'check'
export const TRANSLATELABEL = 'translate-label'


