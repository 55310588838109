import React, {useEffect} from "react";
import InputWithLeadingIcon from "../../atoms/Inputs/InputWithLeadingIcon/InputWithLeadingIcon";
import {MagnifyingGlassIcon} from "@heroicons/react/24/solid";
import Paginator from "../../atoms/Paginator/Paginator";
import {useTranslation} from "react-i18next";
import DatePicker from "../../atoms/DatePicker/DatePicker";

const ListMenu = ({ elementsCount, filters, setFilters, children, sideComponent=null, isDateFilterActive=false}) => {
    const {t} = useTranslation()

    useEffect(() => {
        setFilters({...filters, pagination: {currentPage: 1, offset: 0, limit:10}, txtFilter: ''})
    },[])

    const setTxtFilter = (txtFilter) => setFilters({...filters, pagination: {currentPage: 1, offset: 0, limit:10}, txtFilter})

    const setStartDate = (startDate) => setFilters({...filters, pagination: {currentPage: 1, offset: 0, limit:10}, startDate})
    const setEndDate = (endDate) => setFilters({...filters, pagination: {currentPage: 1, offset: 0, limit:10}, endDate})

    return(
        <div className='flex flex-col w-full'>
            <div className='flex w-full items-center justify-between space-x-2'>
                <div className={sideComponent ? 'w-3/4' : 'w-full'}>
                    <InputWithLeadingIcon
                        value={filters.txtFilter}
                        setValue={setTxtFilter}
                        icon={<MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
                        placeholder={t('search.forNameEmail')}
                    />
                </div>
                {isDateFilterActive && <DatePicker
                    startDate={filters.startDate}
                    setStartDate={setStartDate}
                    endDate={filters.endDate}
                    setEndDate={setEndDate}
                />}
                {sideComponent && <div className='flex w-1/4 my-1 justify-center flex-grow'>
                    {sideComponent}
                </div>}
                {/*{showFlagCompleted && <Toggle
                    value={flagCompleted}
                    setValue={setFlagCompleted}
                    label='Includi completati'
                />}*/}
            </div>

            <div className='flex justify-center'>
                {children}
            </div>
            <div className='flex flex-col flex-1'>
                <Paginator pageSize={10} currentPage={filters.pagination.currentPage} totalResults={elementsCount} onClick={(pagination) => setFilters({...filters, pagination})}/>
            </div>
        </div>
    )
}

export default ListMenu;
