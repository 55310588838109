import {Menu, Transition} from "@headlessui/react";
import {classNames} from "../../../../utils/helper";
import React, {Fragment} from "react";
import {ChevronDownIcon} from "@heroicons/react/24/solid";

const DropdownButton = ({label, options = [], item}) => {
    return (
        <Menu as="div" className="relative inline-block text-left">
            {({ open }) => (
                <>
                    <Menu.Button
                        className='inline-flex justify-between items-center border font-medium focus:outline-none space-x-1 border-transparent shadow-sm text-white
                            bg-green-600 hover:bg-green-700 focus:ring-2 focus:ring-green-500 px-6 py-2 text-base rounded-md h-full w-full'>
                        {label && <div className='text-sm leading-5 font-normal truncate'>{label}</div>}
                        <ChevronDownIcon className="-mr-1 h-5 w-5 text-white" aria-hidden="true" />
                    </Menu.Button>
                    {open &&
                        <Transition
                            show={open}
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <Menu.Items
                                static
                                className="origin-center absolute mt-2 w-full break-words rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10"
                            >
                                <div className="py-1">
                                    {options && options.map((opt, optIdx) => <Menu.Item key={optIdx}>
                                            {({active}) => (
                                                <div
                                                    onClick={() => (opt.onClick ? opt.onClick(item) : {})}
                                                    className={classNames(
                                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                        'block px-4 py-2 text-sm'
                                                    )}
                                                >
                                                <span className={classNames(
                                                    "cursor-pointer",
                                                    opt.labelVariant === 'warn' ? 'text-red-600' : ''
                                                )}>{opt.label}</span>
                                                </div>
                                            )}
                                        </Menu.Item>
                                    )}
                                </div>
                            </Menu.Items>
                        </Transition>
                    }
                </>
            )}
        </Menu>
    )
}

export default DropdownButton;
