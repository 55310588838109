import {UserGroupIcon} from "@heroicons/react/24/outline";
import SingleDataCollectionRecipientCheckbox from "../SingleRecipientCheckbox/SingleDataCollectionRecipientCheckbox";
import DisclosureItem from "../../atoms/Disclosure/DisclosureItem";
import React, {useState} from "react";
import ModalRecipientSelection from "../Modals/ModalRecipientSelection/ModalRecipientSelection";
import {useTranslation} from "react-i18next";

const RecipientsDataCollection = ({recipients, selectedDataCollection, isSenderOwner=false, changeDataCollectionOwner, disabled = false, hasSenderDataCollectionLinked=false}) => {
    const [isOpenModal, setIsOpenModal] = useState(false)
    const {t}=useTranslation()
    return (
        <div>
            {isOpenModal &&<ModalRecipientSelection
                open={isOpenModal}
                setOpen={setIsOpenModal}
                onConfirm={changeDataCollectionOwner}
                recipients={recipients}
            />}
            <DisclosureItem
                head={<div className='flex items-center'>
                    <UserGroupIcon className='h-5 w-5' />
                    <h3 className='px-2 text-sm leading-5 font-medium'>{t('forms.formRecipients')}</h3>
                </div>}>
                {!disabled && <div className='space-y-2'>
                    {!isSenderOwner ?
                        recipients && Object.values(recipients).map(rec =>
                            <SingleDataCollectionRecipientCheckbox
                                key={rec.id} recipient={rec}
                                selectedDataCollectionId={selectedDataCollection}
                                isSelectedDataCollectionLinked={rec?.linkedDataCollections?.[selectedDataCollection]}
                                disabled={disabled}/>
                        ) : <p className='text-sm leading-5 font-normal'>{t('forms.senderOwner')}</p>
                    }
                    <p className='text-sm leading-5 font-medium text-blue-600 cursor-pointer'
                       onClick={() => setIsOpenModal(!disabled && true)}>{t('forms.changeOwner')}</p>
                </div>}
            </DisclosureItem>
        </div>
    )
}

export default RecipientsDataCollection;
