import {createAsyncThunk, createEntityAdapter, createSlice} from "@reduxjs/toolkit";
import {getSummaryDetailsCall} from "../../api/summary";

const summaryAdapter = createEntityAdapter();

const initialState = summaryAdapter.getInitialState({
    details: null
})

export const getSummaryDetailsThunk = createAsyncThunk(
    'summary/getSummaryDetails',
    async (props=null, {getState}) => {
        const { newDocument } = getState().documentalProcess;
        const response = await getSummaryDetailsCall(newDocument.id);
        return response.data
    }
);

const summarySlice = createSlice({
        name: 'summary',
        initialState,
        reducers: {

        },
        extraReducers: (builder) => {
            builder.addCase(getSummaryDetailsThunk.fulfilled, (state, action) => {
                state.details = action.payload;
            });
        }
    }
)

export const summarySelector = (state) => state.summary;

//export const {} = summarySlice.actions;

export default summarySlice.reducer
