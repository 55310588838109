import SimplePage from "../Templates/SimplePage/SimplePage";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {authSelector, clearRegistrationError, clearRegistrationSuccess} from "../../../features/auth/auth.slice";
import confirmoLogo from "../../../assets/logos/confirmo-logo.svg";
import ErrorMessage from "../../atoms/ErrorMessage/ErrorMessage";
import LinkedLabel from "../../atoms/LinkedLabel/LinkedLabel";
import {SUCCESS} from "../../molecules/Alerts/types";
import Alert from "../../molecules/Alerts/Alert";
import {registration} from "../../../features/auth/auth.actions";
import {useTranslation} from "react-i18next";
import RegistrationForm from "../../organisms/RegistrationForm/RegistrationForm";


const RegistrationPage = () => {
    const {t} = useTranslation()
    const [formValues, setFormValues] = useState({
        givenName: '',
        familyName: '',
        email: '',
        newPassword: '',
        confirmPassword: '',
        acceptTerms: false,
        acceptMarketing: false
    })

    const { registrationError, registrationSuccess, registrationErrorMessage } = useSelector(authSelector)
    const dispatch = useDispatch();

    const registrationRequest = async () => {
        dispatch(registration(formValues));
    }

    useEffect(() => {
        dispatch(clearRegistrationError())
        dispatch(clearRegistrationSuccess())
    },[])


    return(
        <SimplePage>
            <div className='flex max-w-screen-2xl w-full h-screen justify-center items-center bg-fi-background bg-no-repeat bg-left-bottom bg-50% bg-scroll'>
                <div className="flex flex-grow justify-center">

                    <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md flex flex-col">
                        <img src={confirmoLogo} alt='Confirmo' className='mb-10 h-12'/>
                        <div className='my-2'>
                            {registrationError ?
                                <ErrorMessage>{registrationErrorMessage}</ErrorMessage>
                                : <></>}
                        </div>
                        {registrationSuccess ?
                            <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                                <Alert type={SUCCESS} message={t('registration.confirmMessage')} />
                                <div className='flex justify-center items-center pt-8'>
                                    <LinkedLabel link={'/login'}>{t('proceed')}</LinkedLabel>
                                </div>
                            </div> :
                            <RegistrationForm
                                onRegister={registrationRequest}
                                formValues={formValues}
                                setFormValues={setFormValues} />
                        }

                    </div>
                </div>
            </div>
        </SimplePage>
    )
}
export default RegistrationPage;
