/* This example requires Tailwind CSS v2.0+ */
import Button from "../../../atoms/Buttons/Button/Button";
import Dropdown from "../../../atoms/Dropdowns/Dropdown/Dropdown";
import React from "react";


const AvatarTable = ({data, actionColumn}) => {
    return (
        <div>
            <ul className="divide-y divide-gray-200">
                {data.map((item) => (
                    <li key={item.id} className="py-4">
                        <div className="flex space-x-3">
                            <img className="h-6 w-6 rounded-full" src={item.sender.avatar} alt="" />
                            <div className="flex-1 space-y-1">
                                <div className="flex items-center justify-between">
                                    <h3 className="text-sm font-medium">{item.displayName}</h3>
                                    <p className="text-sm text-gray-500">
                                        {item.date}
                                    </p>
                                </div>
                                <p className="text-sm text-gray-500">
                                    {item.title}
                                    <span className="">
                                            {(actionColumn && actionColumn.type === 'dropdown')
                                                ?
                                                <Dropdown variant='light' label={actionColumn.buttonLabel}  icon={actionColumn.icon} options={actionColumn.dropdownOptions} item={item} />
                                                :
                                                (actionColumn && actionColumn.type === 'button')
                                                    ?
                                                    <Button variant='light' label={actionColumn.buttonLabel}  icon={actionColumn.icon}
                                                            onClick={(d) => actionColumn.onclick(item)}  item={item} />
                                                    :
                                                    ''}
                                        </span>
                                </p>

                            </div>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
        // <div className="flow-root">
        //     <ul role="list" className="-mb-8">
        //         {data.map((event, eventIdx) => (
        //             <li key={event.id}>
        //                 <div className="relative pb-8">
        //                     {eventIdx !== data.length - 1 ? (
        //                         <span className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
        //                               aria-hidden="true"/>
        //                     ) : null}
        //                     <div className="relative flex space-x-3">
        //                         <div>
        //           <span
        //               className={classNames(
        //                   event.iconBackground,
        //                   'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white'
        //               )}
        //           >
        //             <event.icon className="h-5 w-5 text-white" aria-hidden="true"/>
        //           </span>
        //                         </div>
        //                         <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
        //                             <div>
        //                                 <p className="text-sm text-gray-500">
        //                                     {event.content}{' '}
        //                                     <div className="font-medium text-gray-900">
        //                                         <div dangerouslySetInnerHTML={{__html: event.target}}></div>
        //                                     </div>
        //                                 </p>
        //                             </div>
        //                             <div className="text-right text-sm whitespace-nowrap text-gray-500">
        //                                 <div className="flex items-center">
        //                                     <span>{event.datetime}</span>
        //                                     {(actionColumn && actionColumn.type === 'dropdown')
        //                                         ?
        //                                         <Dropdown variant='light' label={actionColumn.buttonLabel}  icon={actionColumn.icon} options={actionColumn.dropdownOptions} item={event} />
        //                                         :
        //                                         (actionColumn && actionColumn.type === 'button')
        //                                             ?
        //                                             <Button variant='light' label={actionColumn.buttonLabel}  icon={actionColumn.icon}
        //                                                     onClick={(d) => actionColumn.onclick(event)}  item={event} />
        //                                             :
        //                                             ''}
        //                                 </div>
        //
        //                             </div>
        //                         </div>
        //                     </div>
        //                 </div>
        //             </li>
        //         ))}
        //     </ul>
        // </div>
    )
}

export default AvatarTable;

