import React, {useEffect, useState} from 'react'
import GenericTableModal from "../GenericModal/GenericTableModal";
import {useTranslation} from "react-i18next";

const ModalSelectPathItemTemplates = ({open = false, setOpen, onSelect, elements, countElements, getElementsPaginated}) => {
    const [searchTerm, setSearchTerm] = useState('')
    const [currentPage, setCurrentPage] = useState(1)
    const [offset, setOffset] = useState(0)
    const [limit] = useState(10)
    const {t} = useTranslation();

    useEffect(() => {
        const callbackTimer = setTimeout(() => getElementsPaginated(searchTerm, currentPage, offset, limit), 300);
        return () => clearTimeout(callbackTimer);
    },[searchTerm, currentPage, offset, limit])

    useEffect(() => {
        setOffset(0)
        setCurrentPage(1)
    },[searchTerm])

    const selectClose = async (info) => {
        await setOpen(false);
        await onSelect(info.id);
    }

    return (
        <GenericTableModal
            data={elements}
            setOpen={setOpen}
            open={open}
            selectClose={selectClose}
            limit={limit}
            setOffset={setOffset}
            offset={offset}
            count={countElements}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            searchPlaceholder={t('search.forName')}
            fields={[{label: t('name'), value: 'name'}]}
            title={t('informedPath.templates')}
        />
    )
}

export default ModalSelectPathItemTemplates;
