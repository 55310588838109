import React, {useEffect, useRef, useState} from "react";
import {Document, Page, pdfjs} from 'react-pdf';
import DropArea from "../../Drag&Drop/DropArea";
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PdfDocument = ({file, setDragData, draggedData, selectedBlockId, recipients, zoom, onSetBlock, onAddBlock, allBlocks, selectBlock}) => {
    const ref = useRef();
    const [numPages, setNumPages] = useState(null);
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const onBodyClick = (event) => {
            if (ref.current.contains(event.target)) {
                return;
            }
            selectBlock(null)
        };

        document.body.addEventListener('mousedown', onBodyClick);

        return () => {
            document.body.removeEventListener('mousedown', onBodyClick);
            selectBlock(null)
        };
    }, []);

    const onDocumentLoadSuccess = ({numPages}) => {
        setNumPages(numPages);
        setLoading(false)
    }

    const getPages = () => {
        let pages = [];
        for (let i = 1; i < numPages + 1; i++) {
            pages.push(
                <DropArea
                    key={`page-${i}`}
                    draggedData={draggedData}
                    setDragData={(dragData) => setDragData(dragData)}
                    pageNumber={i}
                    zoom={zoom}
                    selectedBlockId={selectedBlockId}
                    recipients={recipients}
                    onSetBlock={onSetBlock}
                    allBlocks={allBlocks}
                    onAddBlock={onAddBlock} selectBlock={selectBlock}
                >
                    <Page pageNumber={i} scale={zoom}/>
                </DropArea>)
            pages.push(<br key={`br-${i}`}/>)
        }
        return pages;
    }

    return(
        <div className={`${loading ? '': 'px-4 pt-4 h-full w-full select-none text-center overflow-auto'}`} ref={ref}>
                <Document file={file}
                          onLoadError={(error) => alert('Error while loading document! ' + error.message)}
                          onLoadSuccess={onDocumentLoadSuccess}
                          renderMode="canvas"
                >
                    {getPages()}
                </Document>
        </div>
    )

}

export default React.memo(PdfDocument);
