import React from "react";

const BackgroundInformativa = ({children}) => {
    return(
        <div className='flex flex-1 flex-col items-center bg-gray-100 w-full h-full border rounded-lg mb-3 py-6 px-8 overflow-y-auto overflow-x-auto'>
            {children}
        </div>
    )
}

export default BackgroundInformativa;
