import Card from "../../molecules/Card/Card";
import React from "react";

const CardsList = ({cards = [], onClick, setFavourite, selectedCards, setSelectedCards, concatenationEnabled=false}) => {
    const selectCard = (cardId, value) => setSelectedCards({...selectedCards, [cardId]: value})

    return(
        <div className='flex flex-wrap'>
            {cards.map(card => <Card
                    key={card.id}
                    title={card.name}
                    isFavourite={card.isFavourite}
                    text={card.description}
                    labels={card.tags}
                    onClick={() => onClick(card.id)}
                    setFavourite={(isFavourite) => setFavourite(card.id, isFavourite)}
                    isChecked={selectedCards[card.id]}
                    setIsChecked={(value) => selectCard(card.id, value)}
                    concatenationEnabled={concatenationEnabled}
                />
            )}
        </div>
    )
}

export default CardsList;
