import PageWithHeader from "../Templates/PageWithHeader/PageWithHeader";
import VerticalNavigation from "../../molecules/VerticalNavigation/VerticalNavigation";
import {Navigate, useParams} from "react-router-dom";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {navigationSelector, setSelectedTabName} from "../../../features/navigation/navigation.slice";
import SettingsBody from "../../organisms/SettingsBody/SettingsBody";
import {userHomeSelector} from "../../../features/userHome/userHome.slice";

const SettingsPage = () => {
    const {section} = useParams()
    const dispatch = useDispatch();
    const {settingsSections} = useSelector(navigationSelector)
    const { selectedTenantId, userOrganizations } = useSelector(userHomeSelector);
    const permissions = userOrganizations?.[selectedTenantId]?.permissions ?? {}
    useEffect(() => {
        dispatch(setSelectedTabName('Settings'))
    },[])

    if(!section)
        return <Navigate to='/Settings/PersonalData' />
    else return(
        <PageWithHeader>
            <div className='flex w-full justify-between py-6'>

                <div className='py-10'>
                    <VerticalNavigation navigation={settingsSections} section={section} baseurl='settings' permissions={permissions} />
                </div>

                <div className='py-10 w-full'>
                    <SettingsBody section={section}  />
                </div>

            </div>
        </PageWithHeader>
    )
}

export default SettingsPage;
