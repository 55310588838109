import {createAsyncThunk} from "@reduxjs/toolkit";
import {
    deleteDocumentCall,
    getDocumentDetailsCall,
    getPlaceholdersCall,
    previewDocumentCall,
    setPlaceholdersCall, setSubmodelsPlaceholdersCall,
    updateDocumentDetailsCall,
    uploadDocumentCall
} from "../../api/documentBuilder";
import {
    addPdfInformedPathToDocumentThunkCall,
    deleteSubmodelCall,
    reorderSubmodelsCall,
    setModelSendModeCall
} from "../../api/documentalProcess";

export const uploadDocumentThunk = createAsyncThunk(
    'documentBuilder/uploadDocumentThunk',
    async ({file, isTemplate, isSubmodels, submodelToUpdateId}, { getState }) => {
        const { newDocument } = getState().documentalProcess;
        const response = await uploadDocumentCall(newDocument.id, file, isTemplate, isSubmodels, submodelToUpdateId);
        return {data: response.data};
    }
);

export const getPlaceholdersThunk = createAsyncThunk(
    'documentBuilder/getPlaceholdersThunk',
    async (props, { getState }) => {
        const { newDocument } = getState().documentalProcess;
        const response = await getPlaceholdersCall(newDocument.id);
        return response.data;
    }
);

export const setPlaceholdersThunk = createAsyncThunk(
    'documentBuilder/setPlaceholdersThunk',
    async (props, { getState }) => {
        const { newDocument } = getState().documentalProcess;
        const {blocks} = getState().documentBuilder;
        const idDocument = props?.document?.eventFlowTypeId ?? newDocument.id;
        const response = await setPlaceholdersCall(idDocument, blocks);
        return response.data;
    }
);

export const reorderSubmodelsThunk = createAsyncThunk(
    'documentalProcess/reorderSubmodelsThunk',
    async (props,{getState}) => {
        //const { newDocument } = getState().documentalProcess;
        const response = await reorderSubmodelsCall(props.processId, props.ids);
        return response.data
    }
)

export const deleteSubmodelThunk = createAsyncThunk(
    'documentalProcess/deleteSubmodelThunk',
    async (props,{getState}) => {
        const response = await deleteSubmodelCall(props.processId, props.submodelId);
        return response.data
    }
)

export const setModelSendModeThunk = createAsyncThunk(
    'documentalProcess/setModelSendModeThunk',
    async (props,{getState}) => {
        const response = await setModelSendModeCall(props.processId, props.sendMode);
        return response.data
    }
)


export const addPdfInformedPathToDocumentThunk = createAsyncThunk(
    'documentalProcess/addPdfInformedPathToDocumentThunk',
    async (props,{getState}) => {
        const response = await addPdfInformedPathToDocumentThunkCall(props.processId, props.detailId);
        return response.data
    }
)

export const setSubmodelsPlaceholdersThunk = createAsyncThunk(
    'documentBuilder/setSubmodelsPlaceholdersThunk',
    async (props, { getState }) => {
        const { newDocument } = getState().documentalProcess;
        const {submodels} = getState().documentBuilder;
        const idDocument = props?.document?.eventFlowTypeId ?? newDocument.id;
        const payload = submodels.map(submodel => {
            return {
                id: submodel.id,
                placeholders: submodel.placeholders
            }
        });
        const response = await setSubmodelsPlaceholdersCall(idDocument, payload);
        return response.data;
    }
);


export const getDocumentDetailsThunk = createAsyncThunk(
    'documentBuilder/getDocumentDetailsThunk',
    async (props, { getState }) => {
        const { newDocument } = getState().documentalProcess;
        const response = await getDocumentDetailsCall(newDocument.id);
        return response.data;
    }
);

export const updateDocumentDetailsThunk = createAsyncThunk(
    'documentBuilder/updateDocumentDetailsThunk',
    async ({name,description,tags}, { getState }) => {
        const { documentInfo } = getState().documentBuilder;
        const response = await updateDocumentDetailsCall(documentInfo.id, {name, description});
        return response.data;
    }
);

export const deleteDocumentThunk = createAsyncThunk(
    'documentBuilder/deleteDocumentThunk',
    async (props, { getState }) => {
        const { newDocument } = getState().documentalProcess;
        const response = await deleteDocumentCall(newDocument.id);
        return response.data;
    }
);

export const previewDocumentThunk = createAsyncThunk(
    'documentBuilder/previewDocumentThunk',
    async (props, { getState }) => {
        const { blocks } = getState().documentBuilder;
        const { newDocument } = getState().documentalProcess;
        const response = await previewDocumentCall(newDocument.id, blocks, props.submodelId);
        return response.data;
    }
);
