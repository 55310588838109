import EventsList from "../../../../organisms/EventsList/EventsList";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {v4} from "uuid";
import {ICON_BUTTON, ICON_BUTTON_LIST} from "../../../../atoms/SimpleTableElement/SimpleTable.types";
import {useNavigate} from "react-router-dom";
import {deleteGraphConsentModelThunk, getGraphConsentsThunk} from "../../../../../features/docModels/docModels.actions";
import {docModelsSelector} from "../../../../../features/docModels/docModels.slice";
import IconButton from "../../../../atoms/Buttons/IconButton/IconButton";
import Icon from "../../../../atoms/Icon/Icon";
import {useTranslation} from "react-i18next";

const GraphConsentsList = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const {t} = useTranslation()
    const [opendeleteModal, setOpenDeleteModal] = useState(false)
    const [templateToDelete, setTemplateToDelete] = useState()
    const {waitingGraphConsents, graphConsentsCount, graphConsents} = useSelector(docModelsSelector)
    const initialFilters = {pagination: {currentPage: 1, offset: 0, limit:10}, txtFilter: '', sortField: 'referenceDate', descending: true}
    const [filters, setFilters] = useState(initialFilters)

    useEffect(() => {
        dispatch(getGraphConsentsThunk({onlyActive: false}))
    },[])

    const editTemplate = async (row) => {
        navigate(`/builder/${row.id}`)
    }

    const onClickDeleteSentTemplate = (data) => {
        setTemplateToDelete(data);
        setOpenDeleteModal(true);
    }

    const deleteTemplate = () => dispatch(deleteGraphConsentModelThunk({modelId: templateToDelete?.id}))

    const onClickCreateNew = () => {
        navigate(`/builder`)
    }

    const columns = [
        {field: 'name', label: 'title', hidden: false, id: v4()},
        {field: 'description', label: 'description', hidden: false, id: v4()},
        {field: 'buttonsList', type: ICON_BUTTON_LIST, hidden: true, id: v4(), buttons: [
                {
                    field: 'Visualizza',
                    label: 'tableColumns.view',
                    hidden: true,
                    type: ICON_BUTTON,
                    onClick: (d) => editTemplate(d),
                    iconType: 'view', id: v4()
                },{
                    field: 'Delete',
                    label: 'tableColumns.delete',
                    hidden: true,
                    type: ICON_BUTTON,
                    onClick: (d) => onClickDeleteSentTemplate(d),
                    iconType: 'delete', id: v4()
                }
            ]
        }
    ]

    return <EventsList
        data={graphConsents}
        filters={filters}
        setFilters={setFilters}
        elementsCount={graphConsentsCount}
        columns={columns}
        loading={waitingGraphConsents}
        sideComponent={<IconButton icon={<Icon type='plus'/>} label={t('createNew')} onClick={onClickCreateNew}/>}
        openDeleteModal={opendeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        onDeleteEvent={deleteTemplate} toDeleteRecordName={templateToDelete?.name}
    />
}

export default GraphConsentsList;
