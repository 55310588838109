import React, {Fragment} from "react";
import {Menu, Transition} from "@headlessui/react";
import {classNames} from "../../../../utils/helper";
import Icon from "../../Icon/Icon";

const DropdownIconButton = ({options=[]}) => {
    return (
        <Menu as="div" className="relative inline-block text-left">
            {({ open }) => (
                <>
                    <div>
                        {/* eslint-disable-next-line react/jsx-no-undef */}
                        <Menu.Button
                            className="flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-2 py-2 text-sm font-medium focus:outline-none focus:ring-0 bg-white text-gray-700">
                            <Icon />
                        </Menu.Button>
                    </div>

                    <Transition
                        show={open}
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <Menu.Items
                            static
                            className="origin-top-right absolute mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10 min-w-max"
                        >
                            <div className="py-1">
                                {options && options.map(opt => <Menu.Item key={opt.label}>
                                        {({ active }) => (
                                            <span
                                                onClick={() => opt?.onClick?.()}
                                                className={classNames(
                                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                    'block px-4 py-2 text-sm cursor-pointer'
                                                )} >
                                                {opt.label}
                                            </span>
                                        )}
                                    </Menu.Item>
                                )}
                            </div>
                        </Menu.Items>
                    </Transition>
                </>
            )}
        </Menu>
    )
}

export default DropdownIconButton;
