import isObject from "lodash/isObject";

const checkDisableRules = (rules, items, answers) => {
    let tempDisable = {}

    rules.forEach(rule => {
        const targetName = items.find(item => item.name === rule.targetField)?.name
        const dependentName = items.find(item => item.name === rule.dependentField)?.name
        tempDisable[dependentName] = tempDisable[dependentName] ?? false

        if (rule.comparison === 'EQ' &&
            ((!isObject(answers?.[targetName]) && answers?.[targetName] === rule.value) || answers?.[targetName]?.[rule.value] === true))
            tempDisable[dependentName] = rule.action === "disable"

        else if (rule.comparison === 'NEQ' &&
            ((!isObject(answers?.[targetName]) && answers?.[targetName] !== rule.value) || answers?.[targetName]?.[rule.value] === false))
            tempDisable[dependentName] = rule.action === "disable"

    })

    return tempDisable
}

const validate = (items, disabled, answers) => {
    let isValidAll= true;
    items.every(step => {
        let isValid= true;
        if(!disabled?.[step.name]) {
            if (step?.options?.allRequired) {
                if (step.type === 'checklist') {
                    isValid = !Object.values(answers?.[step.name] ?? []).includes(false);
                }
                if (!isValid)
                    console.log('Tutte le checkbox devono essere spuntate');
            }
            if (step?.options?.required && isValid) {
                if (step.type === 'checklist') {
                    isValid = Object.values(answers?.[step.name] ?? [false]).includes(true);
                } else {
                    isValid = !!answers[step.name];
                }
                /*if (!isValid)
                    console.log('Campo obbligatorio');*/
            }
        }
        isValidAll = isValidAll && isValid
        return isValidAll
    })
    return isValidAll;
}

export {checkDisableRules, validate}
