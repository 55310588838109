import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    addDataCollectionRule,
    deleteDataCollectionRule,
    documentalProcessSelector,
    resetState,
    setDataCollectionRule
} from "../../../../../../features/documentalProcess/documentalProcess.slice";
import ErrorMessage from "../../../../../atoms/ErrorMessage/ErrorMessage";
import TabsWithUnderline from "../../../../../molecules/Tabs/TabsWithUnderline/TabsWithUnderline";
import ListDataCollectionsFields from "../../../../../organisms/ListDataCollectionsFields/ListDataCollectionsFields";
import InformativaInfo from "../../../../../molecules/InformativaInfo/InformativaInfo";
import SidebarDataCollection from "../../../../../molecules/SidebarDataCollection/SidebarDataCollection";
import PageWithTitleAndFooter from "../../../../Templates/PageWithTitleAndFooter/PageWithTitleAndFooter";
import {useTranslation} from "react-i18next";
import {
    addDataCollectFormFieldThunk,
    createUnlinkedDataCollectionThunk,
    deleteDataCollectFormFieldThunk,
    getDataCollectFormDetailsThunk,
    getStepsTypes,
    reorderDataCollectFormFieldsThunk,
    updateDataCollectFormFieldThunk,
    updateDataCollectFormThunk
} from "../../../../../../features/documentalProcess/documentalProcess.actions";
import {addTagThunk, deleteTagThunk} from "../../../../../../features/userHome/userHome.actions";

const FormDetail = () => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const {id} = useParams();
    const {dataCollections, updateFormError} = useSelector(documentalProcessSelector)
    const [selectedCardId, setSelectedCardId] = useState();
    const [selectedCard, setSelectedCard] = useState();
    const [dataCollectionId, setDataCollectionId] = useState(id)
    const [dataCollectionName, setDataCollectionName] = useState('')
    const [dataCollectionDescription, setDataCollectionDescription] = useState('')
    const [showFields, setShowFields] = useState(true)
    const [currentTab, setCurrentTab] = useState('form-fields')
    const [tags, setTags] = useState([]);
    const fields = dataCollections[dataCollectionId]?.template?.items ?? [];
    const rules = dataCollections[dataCollectionId]?.template?.rules ?? [];

    const tabs = [
        { name: t('forms.formFields'), id: 'form-fields', onClick: () => {
                setCurrentTab('form-fields')
                setShowFields(true)
            }, current: currentTab === 'form-fields' },
        { name: t('forms.rules'), id: 'form-rules', onClick: () => {
                setCurrentTab('form-rules')
                setShowFields(false)
            }, current: currentTab === 'form-rules' },
    ]

    const dataCollectionsTypes = [
        {label: t('forms.fields.checklist'), type: 'checklist' },
        {label: t('forms.fields.date'), type: 'date' },
        {label: t('forms.fields.caption'), type: 'label'},
        {label: t('forms.fields.number'), type: 'number' },
        {label: t('forms.fields.radio'), type: 'radio' },
        {label: t('forms.fields.select'), type: 'select' },
        {label: t('forms.fields.text'), type: 'text' },
        {label: t('forms.fields.textarea'), type: 'textarea' },
    ]

    useEffect(() => {
        dispatch(resetState())
        if (id) dispatch(getDataCollectFormDetailsThunk({dataCollectId: dataCollectionId}))
        else dispatch(createUnlinkedDataCollectionThunk({setDataCollectionId}))
        dispatch(getStepsTypes())
    },[id])

    useEffect(() => {
        if (dataCollections[dataCollectionId]) {
            setDataCollectionName(dataCollections[dataCollectionId].name);
            setDataCollectionDescription(dataCollections[dataCollectionId].description);
            setTags(dataCollections[dataCollectionId].tags)
        }
    },[dataCollections, dataCollectionId])

    useEffect(() => {
        if(Object.keys(fields).length !== 0 && selectedCardId)
            setSelectedCard(fields.find(card => card.name === selectedCardId));
        else
            setSelectedCard(null);
    }, [fields, selectedCardId]);

    const stepsTypesFormatted = dataCollectionsTypes ?
        dataCollectionsTypes.map(dataCollect => {
            return {
                label: dataCollect.label,
                onclick: () => dispatch(addDataCollectFormFieldThunk({dataCollectId: dataCollectionId, type: dataCollect.type, name: dataCollect.label}))
            }
        }) : []


    const updateFormField = (item) => {
        dispatch(updateDataCollectFormFieldThunk({dataCollectId: dataCollectionId, item}))
    }

    const updateForm = () => {
        dispatch(updateDataCollectFormThunk({dataCollectId: dataCollectionId, name: dataCollectionName, description: dataCollectionDescription, tags, alertUser: true}))
    }

    const onDeleteFormField= (item) => {
        dispatch(deleteDataCollectFormFieldThunk({dataCollectId: dataCollectionId, item}))
    }

    const reorderFormFields = (orderedFormFieldsList) => {
        const orderedItemsIdsList = orderedFormFieldsList.map(field => field.name)
        dispatch(reorderDataCollectFormFieldsThunk({dataCollectId: dataCollectionId, orderedItemsIdsList}))
    }

    const createRule = () => {
        dispatch(addDataCollectionRule({dataCollectId: dataCollectionId}))
    }

    const setRule = (rule) => {
        dispatch(setDataCollectionRule({dataCollectId: dataCollectionId, rule}))
    }

    const deleteRule = (rule) => {
        dispatch(deleteDataCollectionRule({dataCollectId: dataCollectionId, rule}))
    }

    const goBack= () => {
        window.history.back()
    }

    const insertTag = (tag) => dispatch(addTagThunk({tag}))
    const deleteTag = (tag) => dispatch(deleteTagThunk({tag}))

    return (
        <PageWithTitleAndFooter title='Dettaglio scheda raccolta dati' closeAction={goBack} footer={true} hasBackground={false} confirmLabel={t('save')} next={updateForm} previous={goBack}>
            <div className='flex max-w-7xl w-full h-full pt-8 items-start space-x-8'>
                <div className='flex flex-col w-2/3 h-full items-start'>
                    <TabsWithUnderline tabs={tabs} />
                    <ListDataCollectionsFields
                        fieldTypesFormatted={stepsTypesFormatted}
                        fields={fields}
                        rules={rules}
                        onDeleteFormField={onDeleteFormField}
                        reorderFormFields={reorderFormFields}
                        showFields={showFields}
                        onCardClick={setSelectedCardId}
                        createRule={createRule}
                        setRule={setRule}
                        deleteRule={deleteRule}
                    />
                </div>
                <div className='flex flex-col w-1/3 justify-end'>
                    {/*<div className='flex justify-end items-center my-3'>*/}
                    {/*    <DropdownLanguage />*/}
                    {/*</div>*/}
                    <div className='my-2'>
                        {updateFormError ?
                            <ErrorMessage>{t('errorOccurred')}</ErrorMessage>
                            : <></>}
                    </div>

                    <div className='flex flex-1 flex-col w-full h-full'>
                        {selectedCardId ?
                            <div className='mt-12'>
                                <SidebarDataCollection
                                    field={selectedCard}
                                    updateField={updateFormField}
                                    onClose={() => setSelectedCardId(null)}
                                />
                            </div>:
                            <>
                                <InformativaInfo
                                    name={dataCollectionName}
                                    setName={setDataCollectionName}
                                    description={dataCollectionDescription}
                                    setDescription={setDataCollectionDescription}
                                    tags={tags}
                                    setTags={setTags}
                                    insertTag={insertTag}
                                    deleteTag={deleteTag}
                                    hasDelete={true}
                                />
                            </>
                        }


                    </div>
                </div>
            </div>
        </PageWithTitleAndFooter>)
}

export default FormDetail;
