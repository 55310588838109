import {createEntityAdapter, createSlice} from "@reduxjs/toolkit";
import {toast} from "react-hot-toast";
import {Translation} from "react-i18next";
import Alert from "../../components/molecules/Alerts/Alert";
import {ERROR, SUCCESS} from "../../components/molecules/Alerts/types";
import {
    addDeviceThunk,
    addEventFlowActivityToWorkspaceThunk,
    changeFeqPin, checkUploadEventFlowTypeThunk,
    countDevices,
    countEventFlowTypes,
    countForms,
    countInformedPaths,
    countModels,
    deleteSentEventThunk,
    delEventFlowType,
    delForm,
    delInformedPath,
    doRemoteMassiveSignatureThunk,
    enableSignature, generateEventFlowTypeThunk,
    getArchieve,
    getCanceledEvents,
    getCanceledEventsDetails, getCanceledEventsDetailsThunk,
    getDevices,
    getEventFlowTypes,
    getEventsMassiveSignature,
    getForms,
    getInformedPaths,
    getMassiveSignatureDetails,
    getModels,
    getOrganizationEvents,
    getOrganizationEventsDetails,
    getOtherUserSignatures, getRemoteSignatureUser,
    getSentEvents,
    getSentEventsDetailsThunk,
    getUserSignatures,
    getWaitingEventDetailsThunk,
    getWaitingEvents,
    removeEventFlowActivityFromWorkspaceThunk, requestOtpFeqThunk,
    resendEmailFromEventThunk, retrievePiPThunk,
    updateDeviceThunk,
    uploadEventFlowTypeThunk
} from "./library.action";
import React from "react";
import {navigate} from "@storybook/addon-links";

const libraryAdapter = createEntityAdapter()

const initialState = libraryAdapter.getInitialState({

    currentEventId: null,
    currentEvent: null,
    detailOpened: false,

    eventFlowTypes: [],
    eventFlowTypesCount: 0,
    listLoadedEventFlows: false,

    informedPaths: [],
    informedPathsCount: 0,
    listLoadedInformedPaths: false,

    forms: [],
    formsCount: 0,
    listLoadedForms: false,

    models: [],
    modelsCount: 0,
    listLoadedModels: false,

    sentEvents: [],
    sentEventsCount: 0,
    listLoadedSentEvents: false,

    listLoadedOrganizationEvents: false,
    organizationEvents: [],
    organizationEventsCount: 0,

    canceledEvents: [],
    canceledEventsCount: 0,
    listLoadedCanceledEvents: false,

    waitingEvents: [],
    waitingEventsCount: 0,

    eventsMassiveSignature: [],
    eventsCountMassiveSignature: 0,
    listLoadedEventsMassiveSignature: false,

    devices: [],
    devicesCount: 0,

    signatures: [],
    listLoadedSignatures: false,
    enableSignatureParams: null,

    otherUserSignatures: [],
    listOtherLoadedSignatures: false,

    completedEvents: [],
    completedEventsCount: 0,
    listLoadedCompletedEvents: false,

    archieveList: [],
    archieveCount: 0,
    listArchieveLoaded: false,
    loadingArchieve: false,

    qrCode: null,
    loadingEvents: true,

    waitingGraphConsents: false,
    graphConsents: [],
    graphConsentsCount: 0,

    importProcessName: null,
    generatedProcessId: null,
    pIp: null,
    pIpConfirmo: null,

    otpRequestPending: false,
    otpFeqVisible: false,

    remoteSignatureUser: null,

})

const librarySlice = createSlice({
    name: 'library',
    initialState,
    reducers: {
        resetLibrary: (state) => {
            state.listLoadedEventFlows = false
            state.eventFlowTypes = [];
            state.eventFlowTypesCount = 0;

            state.listLoadedInformedPaths = false
            state.informedPaths = [];
            state.informedPathsCount = 0;

            state.listLoadedForms = false
            state.forms = [];
            state.formsCount = 0;

            state.listLoadedModels = false
            state.models = [];
            state.modelsCount = 0;

            state.listLoadedSentEvents = false
            state.sentEvents = [];
            state.sentEventsCount = 0;

            state.listLoadedOrganizationEvents = false
            state.organizationEvents = []
            state.organizationEventsCount = 0

            state.loadingArchieve = false
            state.listArchieveLoaded = false
            state.archieveList = []
            state.archieveCount = 0

            state.devices = [];
            state.devicesCount = 0;

            state.importProcessName = null;
            state.generatedProcessId = null;
            state.pIp = null;
            state.pIpConfirmo = null;

            state.otpRequestPending = null;
            state.otpFeqVisible = null;

            state.remoteSignatureUser = null;

        },
        openEventDetail: (state, action) => {
            state.currentEventId = action.payload.eventId
            state.detailOpened = true
        },
        closeEventDetail: (state) => {
            state.currentEventId = null
            state.detailOpened = false
        },
        redirectToSignUrl: (state, action) => {
            const selectedEvent = state.waitingEvents?.find(e => e.id === action.payload.eventId)
            if(selectedEvent?.signUrl) window.location.href = selectedEvent?.signUrl
        },
        resetState: () => {
            return initialState;
        },
        resetEnableSignature: (state) => {
            state.enableSignatureParams = null;
        },
        resetImportProcessName: (state) => {
            state.importProcessName = null;
        },
        resetGeneratedProcessId: (state) => {
            state.generatedProcessId = null;
        },
        resetFirmaMassivaOtpPin: (state) => {
            state.otpFeqVisible = false;
            state.otpRequestPending = false;
        },
        setQrCode: (state, action) => {
            state.qrCode = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getEventFlowTypes.pending, (state) => {
                state.loadingLibrary = true;
            })
            .addCase(getEventFlowTypes.fulfilled, (state, action) => {
                state.eventFlowTypes = action.payload;
                state.listLoadedEventFlows = true;
                state.loadingLibrary = false;
            })
            .addCase(getEventFlowTypes.rejected, (state) => {
                state.eventFlowTypes = [];
                state.listLoadedEventFlows = false;
                state.loadingLibrary = false;
            })
            .addCase(countEventFlowTypes.fulfilled, (state, action) => {
                state.eventFlowTypesCount = action.payload;
            })
            .addCase(countEventFlowTypes.rejected, (state) => {
                state.eventFlowTypesCount = 0;
            })
            .addCase(delEventFlowType.fulfilled, (state) => {
                state.listLoadedEventFlows = false;
            })
            .addCase(getInformedPaths.pending, (state) => {
                state.loadingLibrary = true;
            })
            .addCase(getInformedPaths.fulfilled, (state, action) => {
                state.informedPaths = action.payload;
                state.listLoadedInformedPaths = true;
                state.loadingLibrary = false;
            })
            .addCase(getInformedPaths.rejected, (state) => {
                state.informedPaths = [];
                state.listLoadedInformedPaths = false;
                state.loadingLibrary = false;
            })
            .addCase(countInformedPaths.fulfilled, (state, action) => {
                state.informedPathsCount = action.payload;
            })
            .addCase(countInformedPaths.rejected, (state) => {
                state.informedPathsCount = 0;
            })
            .addCase(delInformedPath.fulfilled, (state) => {
                state.listLoadedInformedPaths = false;
            })
            .addCase(getForms.pending, (state) => {
                state.loadingLibrary = true;
            })
            .addCase(getForms.fulfilled, (state, action) => {
                state.forms = action.payload;
                state.listLoadedForms = true;
                state.loadingLibrary = false;
            })
            .addCase(getForms.rejected, (state) => {
                state.forms = [];
                state.listLoadedForms = false;
                state.loadingLibrary = false;
            })
            .addCase(countForms.fulfilled, (state, action) => {
                state.formsCount = action.payload;
            })
            .addCase(countForms.rejected, (state) => {
                state.formsCount = 0;
            })
            .addCase(delForm.fulfilled, (state) => {
                state.listLoadedForms = false;
            })
            .addCase(getModels.fulfilled, (state, action) => {
                state.models = action.payload;
                state.listLoadedModels = true;
            })
            .addCase(getModels.rejected, (state) => {
                state.models = [];
                state.listLoadedModels = true;
            })
            .addCase(countModels.fulfilled, (state, action) => {
                state.modelsCount = action.payload;
            })
            .addCase(countModels.rejected, (state) => {
                state.modelsCount = 0;
            })
            .addCase(getSentEvents.pending, (state) => {
                state.loadingEvents = true;
            })
            .addCase(getSentEvents.fulfilled, (state, action) => {
                state.sentEvents = action.payload.events;
                state.listLoadedSentEvents = true;
                state.loadingEvents = false;
                state.sentEventsCount = action.payload.count;
            })
            .addCase(getSentEvents.rejected, (state) => {
                state.sentEvents = [];
                state.listLoadedSentEvents = true;
                state.loadingEvents = false;
                state.sentEventsCount = 0;
            })
            .addCase(getSentEventsDetailsThunk.fulfilled, (state, action) => {
                state.sentEvents = state.sentEvents?.map(se => se.id === action.payload.id ? action.payload.data : se);
            })
            .addCase(getCanceledEventsDetailsThunk.fulfilled, (state, action) => {
                state.canceledEvents = state.canceledEvents?.map(se => se.id === action.payload.id ? action.payload.data : se);
            })
            .addCase(getOrganizationEvents.pending, (state) => {
                state.loadingEvents = true;
            })
            .addCase(getOrganizationEvents.fulfilled, (state, action) => {
                state.organizationEvents = action.payload.events;
                state.listLoadedOrganizationEvents = true;
                state.loadingEvents = false;
                state.organizationEventsCount = action.payload.count;
            })
            .addCase(getOrganizationEvents.rejected, (state) => {
                state.organizationEvents = [];
                state.listLoadedOrganizationEvents = true;
                state.loadingEvents = false;
                state.organizationEventsCount = 0;
            })
            .addCase(getOrganizationEventsDetails.fulfilled, (state, action) => {
                state.organizationEvents = state.organizationEvents?.map(se => se.id === action.payload.id ? action.payload.data : se);
            })
            .addCase(getCanceledEvents.pending, (state) => {
                state.loadingEvents = true;
            })
            .addCase(getCanceledEvents.fulfilled, (state, action) => {
                state.canceledEvents = action.payload.events;
                state.listLoadedCanceledEvents = true;
                state.loadingEvents = false;
                state.canceledEventsCount = action.payload.count;
            })
            .addCase(getCanceledEvents.rejected, (state) => {
                state.canceledEvents = [];
                state.listLoadedCanceledEvents = true;
                state.loadingEvents = false;
                state.canceledEventsCount = 0;
            })
            .addCase(getCanceledEventsDetails.fulfilled, (state, action) => {
                state.canceledEvents = state.canceledEvents?.map(se => se.id === action.payload.id ? action.payload.data : se);
            })
            .addCase(deleteSentEventThunk.fulfilled, (state) => {
                state.listLoadedSentEvents = false;
            })
            .addCase(getWaitingEvents.pending, (state) => {
                state.loadingEvents = true;
            })
            .addCase(getWaitingEvents.fulfilled, (state, action) => {
                state.waitingEvents = action.payload.events;
                state.waitingEventsCount = action.payload.count;
                state.loadingEvents = false;
            })
            .addCase(getWaitingEvents.rejected, (state) => {
                state.waitingEvents = [];
                state.waitingEventsCount = 0;
                state.loadingEvents = false;
            })
            .addCase(getWaitingEventDetailsThunk.fulfilled, (state, action) => {
                state.waitingEvents = state.waitingEvents?.map(se => se.id === action.payload.id ? action.payload.data : se);
            })
            .addCase(getEventsMassiveSignature.pending, (state) => {
                state.loadingEvents = true;
            })
            .addCase(getEventsMassiveSignature.fulfilled, (state, action) => {
                state.eventsMassiveSignature = action.payload.events;
                state.listLoadedEventsMassiveSignature = true;
                state.loadingEvents = false;
                state.eventsCountMassiveSignature = action.payload.count;
            })
            .addCase(getEventsMassiveSignature.rejected, (state) => {
                state.eventsMassiveSignature = [];
                state.listLoadedEventsMassiveSignature = true;
                state.loadingEvents = false;
                state.eventsCountMassiveSignature = 0;
            })
            .addCase(getMassiveSignatureDetails.fulfilled, (state, action) => {
                state.eventsMassiveSignature = state.eventsMassiveSignature?.map(se => se.id === action.payload.id ? action.payload.data : se);
            })
            .addCase(getDevices.fulfilled, (state, action) => {
                state.devices = action.payload;
            })
            .addCase(getDevices.rejected, (state) => {
                state.devices = [];
            })
            .addCase(countDevices.fulfilled, (state, action) => {
                state.devicesCount = action.payload;
            })
            .addCase(countDevices.rejected, (state) => {
                state.devicesCount = 0;
            })
            .addCase(getArchieve.pending, (state) => {
                state.loadingArchieve = true;
            })
            .addCase(getArchieve.fulfilled, (state, action) => {
                state.archieveList = action.payload.events;
                state.listArchieveLoaded = true;
                state.loadingArchieve = false;
                state.archieveCount = action.payload.count;
            })
            .addCase(getArchieve.rejected, (state) => {
                state.archieveList = [];
                state.listArchieveLoaded = true;
                state.loadingArchieve = false;
                state.archieveCount = 0;
            })
            .addCase(getUserSignatures.fulfilled, (state, action) => {
                state.signatures = action.payload;
                state.listLoadedSignatures = true;
                state.enableSignatureParams = null;
            })
            .addCase(getUserSignatures.rejected, (state) => {
                state.signatures = [];
                state.listLoadedSignatures = false;
            })
            .addCase(getOtherUserSignatures.fulfilled, (state, action) => {
                state.otherUserSignatures = action.payload;
                state.listOtherLoadedSignatures = false;
            })
            .addCase(getOtherUserSignatures.rejected, (state) => {
                state.otherUserSignatures = [];
                state.listOtherLoadedSignatures = false;
            })
            .addCase(enableSignature.fulfilled, (state, action) => {
                state.listLoadedSignatures = false;
                if (action.payload === true) {
                    toast.custom((tst) => (<Translation>{(t) => <Alert type={SUCCESS} message={t('success')} onClose={() => toast.dismiss(tst.id)}/>}</Translation>));
                    window.location.reload();
                } else {
                    state.enableSignatureParams = action.payload.result;
                }
            })
            .addCase(enableSignature.rejected, (state) => {
                state.enableSignatureParams = null;
                toast.custom((tst) => (<Translation>{(t) => <Alert type={ERROR} message={t('errorOccurred')} onClose={() => toast.dismiss(tst.id)}/>}</Translation>));
            })
            .addCase(changeFeqPin.fulfilled, () => {
                toast.custom((tst) => (<Translation>{(t) => <Alert type={SUCCESS} message={t('success')} onClose={() => toast.dismiss(tst.id)}/>}</Translation>));
            })
            .addCase(changeFeqPin.rejected, (state, action) => {
                toast.custom((tst) => (<Translation>{(t) => <Alert type={ERROR} message={action.error.message ?? t('errorOccurred')} onClose={() => toast.dismiss(tst.id)}/>}</Translation>));
            })
            .addCase(doRemoteMassiveSignatureThunk.fulfilled, () => {
                toast.custom((tst) => (<Translation>{(t) => <Alert type={SUCCESS} message={t('massiveSign.success')} onClose={() => toast.dismiss(tst.id)}/>}</Translation>));
            })
            .addCase(doRemoteMassiveSignatureThunk.rejected, (state, action) => {
                toast.custom((tst) => (<Translation>{(t) => <Alert type={ERROR} message={action.error.message ?? t('errorOccurred')} onClose={() => toast.dismiss(tst.id)}/>}</Translation>));
            })
            .addCase(requestOtpFeqThunk.fulfilled, (state) => {
                state.otpRequestPending = false;
                state.otpFeqVisible = true;
            })
            .addCase(requestOtpFeqThunk.pending, (state) => {
                state.otpRequestPending = true;
                state.otpFeqVisible = false;
            })
            .addCase(requestOtpFeqThunk.rejected, (state, action) => {
                toast.custom((tst) => (<Translation>{(t) => <Alert type={ERROR} message={action.error.message ?? t('errorOccurred')} onClose={() => toast.dismiss(tst.id)}/>}</Translation>));
                state.otpRequestPending = false;
                state.otpFeqVisible = false;
            })
            .addCase(addDeviceThunk.pending, (state) => {
                state.qrCode = null;
            })
            .addCase(addDeviceThunk.fulfilled, (state, action) => {
                state.qrCode = action.payload;
            })
            .addCase(addDeviceThunk.rejected, (state) => {
                state.qrCode = null;
            })
            .addCase(resendEmailFromEventThunk.fulfilled, () => {
                toast.custom((tst) => (<Translation>{(t) => <Alert type={SUCCESS} message={t('manageusers.alertInvitationSentAgainSuccess')} onClose={() => toast.dismiss(tst.id)}/>}</Translation>));
            })
            .addCase(resendEmailFromEventThunk.rejected, () => {
                toast.custom((tst) => (<Translation>{(t) => <Alert type={ERROR} message={t('manageusers.errorSendEmail')} onClose={() => toast.dismiss(tst.id)}/>}</Translation>));
            })
            .addCase(updateDeviceThunk.fulfilled, (state, action) => {
                state.devices = state.devices.map(dev => {
                    if (dev.device_id === action.payload.device_id) return action.payload;
                    else return dev;
                });
            })
            .addCase(removeEventFlowActivityFromWorkspaceThunk.fulfilled, (state, action) => {
                if (action?.payload?.workspaceId && action?.payload?.eventFlowActivityId) {
                    const sentEvent = state?.sentEvents.find(se => se.id === action?.payload?.eventFlowActivityId);
                    if (sentEvent?.workspaces) sentEvent.workspaces[action?.payload?.workspaceId] = false;
                    const waitingEvent = state?.waitingEvents.find(we => we.eventFlowActivityId === action?.payload?.eventFlowActivityId);
                    if (waitingEvent?.workspaces) waitingEvent.workspaces[action?.payload?.workspaceId] = false;
                    const organizationEvent = state?.organizationEvents.find(oe => oe.eventFlowActivityId === action?.payload?.eventFlowActivityId);
                    if (organizationEvent?.workspaces) organizationEvent.workspaces[action?.payload?.workspaceId] = false;
                }
            })
            .addCase(addEventFlowActivityToWorkspaceThunk.fulfilled, (state, action) => {
                if (action?.payload?.workspaceId && action?.payload?.eventFlowActivityId) {
                    const sentEvent = state?.sentEvents?.find(se => se?.id === action?.payload?.eventFlowActivityId);
                    if (sentEvent?.workspaces) sentEvent.workspaces[action?.payload?.workspaceId] = true;
                    const waitingEvent = state?.waitingEvents?.find(we => we?.eventFlowActivityId === action?.payload?.eventFlowActivityId);
                    if (waitingEvent?.workspaces) waitingEvent.workspaces[action.payload.workspaceId] = true;
                    const organizationEvent = state?.organizationEvents.find(oe => oe.eventFlowActivityId === action?.payload?.eventFlowActivityId);
                    if (organizationEvent?.workspaces) organizationEvent.workspaces[action?.payload?.workspaceId] = true;
                }
            })
            .addCase(uploadEventFlowTypeThunk.fulfilled, () => {
                toast.custom((tst) => (<Translation>{(t) => <Alert type={SUCCESS} message={t('library.importexport.alertSuccess')} onClose={() => toast.dismiss(tst.id)}/>}</Translation>));
            })
            .addCase(uploadEventFlowTypeThunk.rejected, () => {
                toast.custom((tst) => (<Translation>{(t) => <Alert type={ERROR} message={t('library.importexport.alertFailure')} onClose={() => toast.dismiss(tst.id)}/>}</Translation>));
            })
            .addCase(generateEventFlowTypeThunk.fulfilled, (state, action) => {
                toast.custom((tst) => (<Translation>{(t) => <Alert type={SUCCESS} message={t('library.importexport.alertSuccess')} onClose={() => toast.dismiss(tst.id)}/>}</Translation>));
                state.generatedProcessId = action.payload;
            })
            .addCase(generateEventFlowTypeThunk.rejected, () => {
                toast.custom((tst) => (<Translation>{(t) => <Alert type={ERROR} message={t('library.importexport.alertFailure')} onClose={() => toast.dismiss(tst.id)}/>}</Translation>));
            })
            .addCase(checkUploadEventFlowTypeThunk.fulfilled, (state, action) => {
                state.importProcessName = action.payload;
            })
            .addCase(checkUploadEventFlowTypeThunk.rejected, () => {
                toast.custom((tst) => (<Translation>{(t) => <Alert type={ERROR} message={t('library.importexport.alertFailure')} onClose={() => toast.dismiss(tst.id)}/>}</Translation>));
            })
            .addCase(retrievePiPThunk.fulfilled, (state, action) => {
                state.pIp = action.payload?.ip;
            })
            .addCase(retrievePiPThunk.rejected, (state) => {
                state.pIp = null;
            })
            .addCase(getRemoteSignatureUser.fulfilled, (state, action) => {
                state.remoteSignatureUser = action.payload;
            })
            .addCase(getRemoteSignatureUser.rejected, (state) => {
                state.remoteSignatureUser = null;
            });

    }
})

// Action creators are generated for each case reducer function
export const { resetState, resetLibrary, resetEnableSignature, resetFirmaMassivaOtpPin, openEventDetail, closeEventDetail, redirectToSignUrl, setQrCode, resetImportProcessName, resetGeneratedProcessId } = librarySlice.actions;

export const librarySelector = (state) => state.library;

export default librarySlice.reducer;
