import {useTranslation} from "react-i18next";

const ReorderDetails = ({title, elements = []}) => {
    const {t} = useTranslation();
    return(
        <div className='flex flex-col flex-grow'>
            <div className='space-y-1 mb-4'>
                <h1 className='text-lg leading-6 font-medium'>{t('reorder.preview')}</h1>
                <h3 className='text-sm leading-5 font-normal text-gray-500'>{title}</h3>
            </div>
            <div className='divide-y-2'>
                {elements.map(element => <>
                    <div className='py-3' key={element.id}>
                        <h3 className='text-sm leading-5 font-medium text-gray-500 capitalize'>{element.type}</h3>
                        <h4 className='text-sm leading-5 font-medium'>{element.name}</h4>
                    </div>
                    </>
                )}
            </div>
        </div>
    )
}

export default ReorderDetails;
