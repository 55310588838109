import Select from "../../atoms/Select/Select";
import DraggableBlocks from "../../Drag&Drop/DraggableBlocks";
import PdfDocument from "../../molecules/PdfDocument/PdfDocument";
import Sidebar from "../../molecules/Sidebar/Sidebar";
import Input from "../../atoms/Inputs/Input/Input";
import Button from "../../atoms/Buttons/Button/Button";
import {FULL, SM} from "../../atoms/Buttons/sizes";
import PageWithStepsHeaderAndFooter from "../Templates/PageWithStepsHeaderAndFooter/PageWithStepsHeaderAndFooter";
import React, {useEffect, useMemo, useRef, useState} from "react";
import DropdownSelect from "../../atoms/Dropdowns/DropdownSelect/DropdownSelect";
import DropdownIconButton from "../../atoms/Dropdowns/DropdownIconButton/DropdownIconButton";
import {checkPermissions, classNames} from "../../../utils/helper";
import {useTranslation} from "react-i18next";
import SingleCheckbox from "../../atoms/SingleCheckbox/SingleCheckbox";
import fonts from "../../../assets/Lists/fonts-list.json"
import LoadingSpinner from "../../atoms/LoadingSpinner/LoadingSpinner";
import Modal from "../../molecules/Modals/Templates/Modal";
import DisclosureItem from "../../atoms/Disclosure/DisclosureItem";
import {
    CheckCircleIcon,
    DocumentCheckIcon,
    DocumentDuplicateIcon,
    DocumentTextIcon,
    PaperClipIcon
} from "@heroicons/react/24/outline";
import Textarea from "../../atoms/TextArea/Textarea";
import {
    downloadPdfToDisplayThunk,
} from "../../../features/documentalProcess/documentalProcess.actions";
import {useDispatch, useSelector} from "react-redux";
import {documentalProcessSelector} from "../../../features/documentalProcess/documentalProcess.slice";
import TabletSelector from "../../molecules/TabletSelector/TabletSelector";
import TabManager from "../../atoms/TabManager/TabManager";
import {
    documentBuilderSelector,
    setCurrentSubmodel,
    setModelsSendMode,
    setSubmodels, updateCurrentSubmodelPlaceholders
} from "../../../features/documentBuilder/documentBuilder.slice";
import ReorderElementsBoard from "../../molecules/ReorderElementsBoard/ReorderElementsBoard";
import RadioGroup from "../../atoms/RadioGroups/RadioGroup";
import {PlusIcon} from "@heroicons/react/20/solid";
import IconButton from "../../atoms/Buttons/IconButton/IconButton";
import {
    addPdfInformedPathToDocumentThunk,
    deleteSubmodelThunk,
    reorderSubmodelsThunk,
    setModelSendModeThunk, setPlaceholdersThunk, setSubmodelsPlaceholdersThunk
} from "../../../features/documentBuilder/documentBuilder.actions";
import RoundButtons from "../../atoms/Buttons/RoundButtons/RoundButtons";
import {DOWNLOAD_PROCESS_SUMMARY, MANAGE_DOC_MULTIFILE, MANAGE_PROCESS_MULTIFILE} from "../../../utils/permissions";
import {userHomeSelector} from "../../../features/userHome/userHome.slice";

const DocumentBuilder = ({nextPage, previous, goBackHome, steps, recipientsOptions, selectedRecipient, setSelectedRecipient, recipientsFields, recipients, selectedBlockId, selectedBlock, handleAddSubmodelTemplate,
                             fields, documentInfo, submodelSendMode, submodels = [], resetSelectedBlock, deleteBlock, recipientBlocksColor, handleChangeFile, handleAddSubmodel, deleteDocumentFile, previewDocumentFile, header=true, hidePrevious=false,
                             onSetBlock, onAddBlock, allBlocks, selectBlock, showInfoMenu, title, setTitle, description, setDescription, confirmLabel, buildUrl=()=>{}, showTabletChoice=false,
                         listDevices, selectedDevice, setSelectedDevice, processContextType = 'NONE'}) => {
    const dispatch = useDispatch();
    const [draggedData, setDragData] = useState(null);
    const hiddenFileInput = useRef(null);
    const hiddenSubmodelInput = useRef(null);
    const hiddenSubmodelTemplateInput = useRef(null);
    const hiddenTemplateInput = useRef(null);
    const [zoom, setZoom] = useState({label: '100%', value: 1})
    const [loading, setLoading] = useState(false)
    const [sendMode, setSendMode] = useState(    submodelSendMode ?? 'merge');
    const { t } = useTranslation();
    const {pdfFile} = useSelector(documentalProcessSelector)
    const [selectedTab, setSelectedTab] = useState(null);
    const {newDocument, informative} = useSelector(documentalProcessSelector)
    const [currentPreviewUrl, setCurrentPreviewUrl] = useState(null);

    const { selectedTenantId, userOrganizations } = useSelector(userHomeSelector);
    const permissions = userOrganizations?.[selectedTenantId]?.permissions ?? {}
    const isMultifilePermittedInDocs = useMemo(() => checkPermissions(permissions, MANAGE_DOC_MULTIFILE), [permissions])
    const isMultifilePermittedInProcesses = useMemo(() => checkPermissions(permissions, MANAGE_PROCESS_MULTIFILE), [permissions])

    const handleFileUploadClickButton = () => {
        if(documentInfo?.isTemplate) hiddenTemplateInput.current.click()
        else hiddenFileInput.current.click()
    }

    const dropdownOptions = [
        {label: t('documentBuilder.previewDocument'), onClick: previewDocumentFile},
        {label: t('documentBuilder.downloadDocument'), onClick: () => window.open(documentInfo?.downloadUrl, '_blank')},
        {label: t('documentBuilder.replaceDocument'), onClick: handleFileUploadClickButton},
    ]

    if (!submodels || submodels.length === 0) {
        dropdownOptions.push({label: t('documentBuilder.deleteDocument'), onClick: deleteDocumentFile})
    }

    const onClickNext = async () => {
        setLoading(true)
        await dispatch(setCurrentSubmodel(selectedTab));
        await nextPage();
        setLoading(false)
        await dispatch(setCurrentSubmodel(submodels?.[0]?.id));
    }

    const onClickPrev = async () => {
        setLoading(true)
        await dispatch(setCurrentSubmodel(selectedTab));
        await previous();
        setLoading(false)
        await dispatch(setCurrentSubmodel(submodels?.[0]?.id));
    }

    useEffect(() => {
        const aPreviewUrl = documentInfo?.previewUrl;
        if (aPreviewUrl && aPreviewUrl !== currentPreviewUrl) {
            setCurrentPreviewUrl(aPreviewUrl);
            dispatch(downloadPdfToDisplayThunk({url: aPreviewUrl}));
        }
        if (!selectedTab) {
            if (submodels?.length > 0) {
                const found = submodels.find(s => s?.id === documentInfo?.id);
                if (found) {
                    setSelectedTab(found.id);
                }
                //setSelectedTab(documentInfo?.submodels[0]?.id);
            }
            //setSelectedTab(submodels?.[0]?.id);
        }
    },[documentInfo])



    const reorderSubmodels = (orderedSteps) => {
        const processId = newDocument?.id;
        dispatch(setSubmodels(orderedSteps));
        if (processId) {
            dispatch(reorderSubmodelsThunk({processId: processId, ids: (orderedSteps || []).map(s => s.id)}));
        }
    }

    const onDeleteSubmodel = async (id) => {
        setLoading(true)
        const processId = newDocument?.id;
        await dispatch(setCurrentSubmodel(selectedTab));
        if (submodels?.length > 0) {
            await dispatch(setSubmodelsPlaceholdersThunk());
        } else {
            await dispatch(setPlaceholdersThunk());
        }
        const newSubmodels = submodels.filter(s => s.id !== id);
        await dispatch(setSubmodels(newSubmodels));
        if (processId) {
            await dispatch(deleteSubmodelThunk({processId: processId, submodelId: id}));
        }
        setLoading(false)
    }

    const isDocumentAlreadyAdded = (id) => {
        return submodels?.some(submodel => submodel?.informedDetailRef === id)
    }

    const addPdfToDocument = async (id) => {
        if (id) {
            setLoading(true)
            const processId = newDocument?.id;
            await dispatch(setCurrentSubmodel(selectedTab));
            await dispatch(addPdfInformedPathToDocumentThunk({processId: processId, detailId: id}));
            setLoading(false)
        }
    }

    const onChangeSendMode = async (val) => {
        const processId = newDocument?.id;
        setSendMode(val)
        await dispatch(updateCurrentSubmodelPlaceholders());
        //salvo i ph
        if (submodels?.length > 0) {
            await dispatch(setSubmodelsPlaceholdersThunk());
        } else {
            await dispatch(setPlaceholdersThunk());
        }
        if (processId) {
            await dispatch(setModelSendModeThunk({processId: processId, sendMode: val}));
        }
    }

    const disableConfirm = useMemo(() => {
        return loading || (showTabletChoice && !selectedDevice)
    },[loading, showTabletChoice, selectedDevice])

    const onSubmodelSelection = async (value) => {
        console.log(value)
        setLoading(true)
        await dispatch(updateCurrentSubmodelPlaceholders());
        //salvo i ph
        if (submodels?.length > 0) {
            await dispatch(setSubmodelsPlaceholdersThunk());
        } else {
            await dispatch(setPlaceholdersThunk());
        }
        await dispatch(setCurrentSubmodel(value));
        setSelectedTab(value)
        setLoading(false)

    }

    const _handleAddSubmodel = async (event) => {
        await dispatch(updateCurrentSubmodelPlaceholders());
        //salvo i ph
        if (submodels?.length > 0) {
            await dispatch(setSubmodelsPlaceholdersThunk());
        } else {
            await dispatch(setPlaceholdersThunk());
        }
        await reorderSubmodels(submodels);
        handleAddSubmodel(event);
    }

    const _handleAddSubmodelTemplate = async (event) => {
        await dispatch(updateCurrentSubmodelPlaceholders());
        //salvo i ph
        if (submodels?.length > 0) {
            await dispatch(setSubmodelsPlaceholdersThunk());
        } else {
            await dispatch(setPlaceholdersThunk());
        }
        await reorderSubmodels(submodels);
        handleAddSubmodelTemplate(event);
    }

    return(
        <PageWithStepsHeaderAndFooter background={false} footer={true} next={onClickNext} previous={onClickPrev} closeAction={goBackHome} steps={steps} disableConfirm={disableConfirm} confirmLabel={confirmLabel} header={header} hidePrevious={hidePrevious} buildUrl={buildUrl}>
            {loading && <Modal open={loading} setOpen={() => {}}><LoadingSpinner /></Modal>}
            <div className='flex flex-1 justify-center flex-grow w-full mt-10 px-4 max-w-screen-2xl'>
                <div
                    className={classNames(documentInfo?.isTemplate ? 'invisible' : '', 'flex w-1/5 flex-col justify-start h-full mx-4')}>
                    {recipientsOptions &&
                        <>
                            <div className='border-b border-gray-200 py-4'>
                                <Select options={recipientsOptions} value={selectedRecipient}
                                        setValue={setSelectedRecipient}/>
                            </div>
                        </>
                    }
                    <h3 className='text-sm leading-5 font-medium'>
                        {t('documentBuilder.availablePlaceholders')}
                    </h3>
                    <p className='text-xs leading-4 font-normal text-gray-500 my-3'>{t('documentBuilder.placeholdersMessage')}</p>
                    <div className='overflow-auto'>
                        {recipientsFields && <DraggableBlocks setDragData={(dragData) => setDragData(dragData)}
                                                              blocks={recipientsFields ?? []}
                                                              color={recipientBlocksColor}/>}
                        {fields && <DraggableBlocks setDragData={(dragData) => setDragData(dragData)}
                                                    blocks={fields?.['global'] ?? []} color='blue'/>}
                    </div>
                </div>
                <div className='flex flex-col w-3/5 flex-2 p-2 h-full justify-center'>
                    <div
                        className='relative flex flex-col items-center bg-gray-100 h-full border rounded-lg mb-3 overflow-hidden'>
                        <div
                            className='flex items-stretch justify-end space-x-1 w-auto absolute top-3 right-5 z-20'>
                            <input type="file" className='hidden' ref={hiddenFileInput} onChange={handleChangeFile}
                                   accept={".docx,.odt,.pdf"}/>
                            <input type="file" className='hidden' ref={hiddenSubmodelInput} onChange={_handleAddSubmodel}
                                   accept={".docx,.odt,.pdf"}/>
                            <input type="file" className='hidden' ref={hiddenSubmodelTemplateInput}
                                   onChange={_handleAddSubmodelTemplate}
                                   accept={".docx"}/>
                            <input type="file" className='hidden' ref={hiddenTemplateInput}
                                   onChange={handleChangeFile}
                                   accept={".docx"}/>
                            <DropdownIconButton options={dropdownOptions.filter(opt => !!opt.onClick)}/>
                            {/*<DropdownLanguage border={true}/>*/}
                            <DropdownSelect selectedValue={zoom} setSelectedValue={setZoom} options={[
                                {label: '200%', value: 2},
                                {label: '150%', value: 1.5},
                                {label: '100%', value: 1},
                                {label: '80%', value: 0.8},
                                {label: '50%', value: 0.5}]}/>
                        </div>
                        <PdfDocument
                            draggedData={draggedData}
                            setDragData={setDragData}
                            file={pdfFile}
                            selectedBlockId={selectedBlockId}
                            recipientBlocksColor={recipientBlocksColor}
                            recipients={recipients}
                            zoom={zoom.value}
                            setZoom={setZoom}
                            onAddBlock={onAddBlock}
                            allBlocks={allBlocks}
                            onSetBlock={onSetBlock}
                            selectBlock={selectBlock}
                        />
                    </div>
                </div>
            </div>

            <div className='flex w-1/5 flex-col justify-start h-full mx-4'>
                {selectedBlock ?
                    <Sidebar subtitle={t('properties')} title={selectedBlock.label}
                             onClose={resetSelectedBlock}>
                        <div className='grid grid-cols-2 gap-x-2'>
                            <Input type='Number' label='x' value={selectedBlock.x}
                                       setValue={(value) => onSetBlock({...selectedBlock, x: value})}/>
                                <Input type='Number' label='y' value={selectedBlock.y}
                                       setValue={(value) => onSetBlock({...selectedBlock, y: value})}/>
                                <Input type='Number' label={t('width')} value={selectedBlock.width}
                                       setValue={(value) => onSetBlock({...selectedBlock, width: value})}/>
                                <Input type='Number' label={t('height')} value={selectedBlock.height}
                                       setValue={(value) => onSetBlock({...selectedBlock, height: value})}/>
                                <Input type='Number' label={t('sizes')} value={selectedBlock.fontSize}
                                       setValue={(value) => onSetBlock({...selectedBlock, fontSize: value})} min={6}
                                       max={60}/>
                                <div className='flex flex-col justify-center'>
                                    <Select label={t('font')} value={selectedBlock.fontFamily}
                                            setValue={(value) => onSetBlock({...selectedBlock, fontFamily: value})}
                                            options={fonts}/>
                                </div>
                            </div>
                            {(selectedBlock.fieldId && selectedBlock.fieldId.startsWith('signature___')) ?
                                <div className='flex space-x-2'>
                                    <SingleCheckbox
                                        value={selectedBlock?.required ?? true}
                                        setValue={(value) => onSetBlock({...selectedBlock, required: value})}
                                        label={t('forms.required')}
                                    />
                                </div> : <></>}
                            {(selectedBlock.fieldId && (selectedBlock.fieldId.endsWith('____complete') || selectedBlock.fieldId.endsWith('____checklist'))) ?
                                <div className='flex space-x-2'>
                                    <SingleCheckbox
                                        value={selectedBlock.fieldLabelStyle !== 'HIDDEN'}
                                        setValue={(value) => onSetBlock({
                                            ...selectedBlock,
                                            fieldLabelStyle: value ? 'NORMAL' : 'HIDDEN'
                                        })}
                                        label={t('forms.showFieldLabel')}
                                    />
                                </div> : <></>}
                            <div className='flex space-x-2 py-2'>
                                <Button label={t('delete')} variant='red' size={FULL} onClick={deleteBlock}/>
                            </div>
                        </Sidebar> :
                        showInfoMenu ?
                            <div>
                                <DisclosureItem
                                    head={<div className='flex items-center'>
                                        <DocumentTextIcon className='h-5 w-5'/>
                                        <h3 className='px-2 text-sm leading-5 font-medium'>{t('informations')}</h3>
                                    </div>}>
                                    <div className='space-y-2'>
                                        <div className='flex flex-col justify-center items-stretch'>
                                            <form className='flex flex-col space-y-2'>
                                                <Input label={t('title')} value={title} setValue={setTitle}/>
                                                <Textarea label={t('description')} value={description}
                                                          setValue={setDescription}/>
                                            </form>
                                        </div>
                                    </div>
                                </DisclosureItem>
                            </div> :
                            showTabletChoice ?
                                <TabletSelector
                                    title='selectTablet'
                                    devices={listDevices}
                                    selectedDevice={selectedDevice}
                                    setSelectedDevice={setSelectedDevice}
                                /> :
                                (((processContextType === 'PROCESS' && isMultifilePermittedInProcesses) || (processContextType === 'DOCUMENT' && isMultifilePermittedInDocs)) //abilitazione
                                    && !(documentInfo?.isTemplate  && (!submodels || submodels.length === 0))) ? //non template docx
                                    <div className='w-full mt-10'>
                                        <p
                                            className="text-sm leading-5 font-medium text-blue-600 cursor-pointer flex items-center"
                                            onClick={() => hiddenSubmodelInput.current.click()}
                                        >
                                            <PlusIcon className="w-5 h-5 mr-2"/>
                                            {t('docs.addDoc')}
                                        </p>
                                        {processContextType === 'PROCESS' && <p
                                            className="text-sm leading-5 font-medium text-blue-600 cursor-pointer flex items-center"
                                            onClick={() => hiddenSubmodelTemplateInput.current.click()}
                                        >
                                            <PlusIcon className="w-5 h-5 mr-2"/>
                                            {t('docs.addTemplate')}
                                        </p>}
                                        {(submodels?.length > 0) && <DisclosureItem
                                            head={<div className='flex items-center'>
                                                <h3 className='px-2 text-sm leading-5 font-medium flex'>
                                                    <DocumentDuplicateIcon className='h-5 w-5'/>{t('docs.docsSorting')}</h3>
                                            </div>}>
                                            {(submodels?.length > 0) &&
                                                <ReorderElementsBoard selectedItemId={selectedTab}
                                                                      setSelectedItemId={onSubmodelSelection}
                                                                      stepsList={submodels}
                                                                      setStepsList={reorderSubmodels}
                                                                      loading={loading}
                                                                      reorderMessage={t('docs.dragDropOrder')}
                                                                      onDeleteItem={submodels?.length > 1 ? onDeleteSubmodel : null}
                                                />}
                                        </DisclosureItem>}
                                        {(submodels?.length > 0) && <div className='my-4'>
                                            {processContextType === 'PROCESS' ? <p>
                                                    {t('docs.willSendOneFile')}
                                                </p> :
                                                <DisclosureItem
                                                    head={<div className='flex items-center'>
                                                        <DocumentCheckIcon className='h-5 w-5'/>
                                                        <h3 className='px-2 text-sm leading-5 font-medium'>{t('docs.manageSignatures')}</h3>
                                                    </div>}>
                                                    <RadioGroup options={[{
                                                        name: t('docs.manageSignaturesMerge'),
                                                        description: t('docs.manageSignaturesMergeHint'),
                                                        value: 'merge',
                                                    }, {
                                                        name: t('docs.manageSignaturesSplit'),
                                                        description: t('docs.manageSignaturesSplitHint'),
                                                        value: 'split',
                                                    }]} value={sendMode} setValue={onChangeSendMode}/>
                                                </DisclosureItem>}
                                        </div>}
                                        {processContextType === 'PROCESS' && (
                                            <DisclosureItem
                                                head={
                                                    <div className="flex items-center">
                                                        <h3 className="px-2 text-sm leading-5 font-medium flex">
                                                            <PaperClipIcon className="h-5 w-5"/> {t('docs.attachInformetedPaths')}
                                                        </h3>
                                                    </div>
                                                }
                                            >
                                                <div className="space-y-4">
                                                    {/* Calcolo se esistono file PDF */}
                                                    {Object.keys(informative ?? {}).flatMap((infKey) =>
                                                        (informative[infKey]?.details || []).filter(
                                                            (detail) => detail?.typeSymbol === "pdf" && detail?.fileName
                                                        )
                                                    ).length === 0 ? (
                                                        <p className="text-sm text-gray-500">{t('docs.noPdfInInformedPaths')}</p>
                                                    ) : (
                                                        Object.keys(informative ?? {}).map((infKey) =>
                                                            (informative[infKey]?.details || []).map((detail, index) =>
                                                                detail?.typeSymbol === "pdf" && detail?.fileName ? (
                                                                    <div
                                                                        key={`${infKey}-${index}`}
                                                                        className="flex items-start gap-4 p-4 border rounded-md shadow-sm bg-gray-50"
                                                                    >
                                                                        <div className="flex-1">
                                                                            <p className="font-medium text-sm text-gray-700">{detail?.title ?? 'Titolo non disponibile'}</p>
                                                                            <p className="text-xs text-gray-500">{detail?.fileName}</p>
                                                                        </div>
                                                                        <div>
                                                                            {/* Controllo se il documento è presente */}
                                                                            {isDocumentAlreadyAdded(detail?.id) ? (
                                                                                <div
                                                                                    className="flex items-center gap-2 text-green-800">
                                                                                    <CheckCircleIcon
                                                                                        className="h-5 w-5"/>
                                                                                    <span className="text-xs">{t('docs.docAlreadyAdded')}</span>
                                                                                </div>
                                                                            ) : (
                                                                                <RoundButtons
                                                                                    label="Aggiungi al documento"
                                                                                    onClick={() => addPdfToDocument(detail?.id)}
                                                                                />
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                ) : null
                                                            )
                                                        )
                                                    )}
                                                </div>
                                            </DisclosureItem>


                                        )}
                                    </div> :
                                    <></>

                }
            </div>
        </PageWithStepsHeaderAndFooter>
    )
}
export default DocumentBuilder;
