import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {authSelector, logout} from "../../../features/auth/auth.slice";
import {checkDraftSessionId} from "../../../features/auth/auth.actions";
import ErrorMessage from "../../atoms/ErrorMessage/ErrorMessage";
import LoadingSpinner from "../../atoms/LoadingSpinner/LoadingSpinner"; //"../../../atoms/ErrorMessage/ErrorMessage";


const SendDraftDispatcher = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {t} = useTranslation()
    const {draftSessionId} = useParams()
    const { draftSessionIdChecked, draftSessionIdError, isLoggedIn } = useSelector(authSelector)

    useEffect(() => {
        if(draftSessionIdChecked) {
            navigate(`/finalizedraft/document`)
        }
    }, [isLoggedIn, draftSessionIdChecked])

    const checkLinkAndDispatch = async () => {
        await dispatch(logout({}))
        if (draftSessionId) {
            sessionStorage.clear();
            dispatch(checkDraftSessionId({sessionId: draftSessionId}));
        } else {
            alert('Si è verificato un errore')
        }
    }

    useEffect(() => {
        checkLinkAndDispatch();
    },[])

    return(
        <div>
            <div className="flex flex-grow justify-center items-start h-1/2">
                <div className="w-150">
                    {draftSessionIdError ?
                        <ErrorMessage>{t('general.notValidLink')}</ErrorMessage>
                        : <LoadingSpinner />}
                </div>
            </div>
        </div>
    )
}
export default SendDraftDispatcher;
