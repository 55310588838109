import {useState} from "react";
import Input from "../../atoms/Inputs/Input/Input";
import SingleCheckbox from "../../atoms/SingleCheckbox/SingleCheckbox";
import RadioGroup from "../../atoms/RadioGroups/RadioGroup";
import Select from "../../atoms/Select/Select";
import Textarea from "../../atoms/TextArea/Textarea";
import isEmpty from "lodash/isEmpty";
import {classNames} from "../../../utils/helper";
import {useTranslation} from "react-i18next";

const DataCollectionField = ({field}) => {
    const [value, setValue] = useState()
    const {t}=useTranslation()

    switch (field.type) {
        case 'text':
            return (
                <div className='pt-2'>
                    <Input
                        type='text'
                        label={t('forms.fields.addText')}
                        disabled={true}
                    />
                </div>
            )
        case 'textarea':
            return (
                <div className='pt-2'>
                    <Textarea label={t('forms.fields.addLongText')} disabled={true}/>
                </div>
            )
        case 'number':
            return (
                <div className='pt-2'>
                    <Input
                        type='text'
                        label={t('forms.fields.addNumber')}
                        disabled={true}
                    />
                </div>
            )
        case 'checklist':
            return(
                <div className={classNames(!isEmpty(field?.choices) ? 'pt-2' : '')}>
                    {!isEmpty(field?.choices) && field.choices.map(choice => <SingleCheckbox key={choice.value} label={choice.caption} value={value?.[choice.value]} setValue={(check) => setValue({...value, [choice.value]: check})} />)}
                </div>
            )
        case 'date':
            return(
                <div className='pt-2'>
                    <Input
                        type='text'
                        label={t('forms.fields.date')}
                        disabled={true}
                    />
                </div>
            )
        case 'radio':
            const optionsRadio = !isEmpty(field?.choices) ? field.choices.map(choice => {return{name: choice.caption, value: choice.value}}) : []
            return(
                <div className={classNames(!isEmpty(field?.choices) ? 'pt-2' : '')}>
                    <RadioGroup options={optionsRadio} value={value} setValue={setValue}/>
                </div>
            )
        case 'select':
            const optionsSelect = !isEmpty(field?.choices) ? field.choices.map(choice => {return{label: choice.caption, value: choice.value}}) : []
            return(
                <div className={classNames(!isEmpty(field?.choices) ? 'pt-2' : '')}>
                    <Select options={optionsSelect} value={value ?? ''} setValue={setValue} placeholder=' '/>
                </div>
            )
        default:
            return <></>
    }
}

export default DataCollectionField;
