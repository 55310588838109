import {v4 as uuid} from 'uuid'
import React from "react";
import {classNames} from "../../../../utils/helper";

const sizes = {
    'sm': 'px-2.5 py-1.5 text-xs rounded',
    'md': 'px-3 py-2 text-sm leading-4 rounded-md',
    'lg': 'px-4 py-2 text-sm leading-5 font-medium rounded-md',
    'xl': 'px-4 py-2 text-base rounded-md',
    '2xl': 'px-6 py-3 text-base rounded-md',
    'full': 'px-6 py-2 text-base rounded-md h-full w-full'
}

const variants = {
    primary: `border-transparent shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:ring-2 focus:ring-indigo-500`,
    blue: `border-transparent shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:ring-2 focus:ring-blue-500`,
    green: `border-transparent shadow-sm text-white bg-green-600 hover:bg-green-700 focus:ring-2 focus:ring-green-500`,
    red: `border-transparent shadow-sm text-white bg-red-600 hover:bg-red-700 focus:ring-2 focus:ring-red-500`,
    secondary: `border-transparent text-xs rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:ring-2`,
    white: `border-gray-300 shadow-sm text-xs rounded text-gray-700 bg-white hover:bg-gray-50 focus:ring-2 focus:ring-blue-500`,
    disabled: 'text-xs rounded text-gray-400 bg-gray-200 hover:bg-gray-300 focus:ring-0 focus:outline-none',
    light: `border-none	shadow-none	text-xs rounded text-blue-700 bg-white hover:bg-gray-50 focus:ring-0 focus:outline-none`,
    trasparent: `border-none shadow-none text-xs text-blue-700 bg-transparent hover:bg-gray-50`,

}

const IconButton = ({size = 'sm', onClick, label, icon,  variant = 'blue'}) => {
    const id = uuid();

    return (
        <button
            id={id}
            type="button"
            className={classNames('inline-flex items-center border border-transparent shadow-sm font-medium rounded-md focus:outline-none space-x-1',
                sizes[size] ? sizes[size] : sizes['2xl'], variants[variant] ? variants[variant] : variants['primary']
            )}
            onClick={onClick}
        >
            {icon && <div>{icon}</div>}
            {label && <div className='ml-0.5 text-sm leading-5 font-normal truncate'>{label}</div>}
        </button>
    )
}
export default IconButton;
