import Badge from "../../atoms/Badges/Badge/Badge";
import Title from "../../atoms/Title/Title";
import Button from "../../atoms/Buttons/Button/Button";
import React from "react";
import {useTranslation} from "react-i18next";

const PremiumMessage = ({next, title, message}) => {
    const {t}=useTranslation()
    return (
        <div className='flex flex-col justify-center items-start'>
            <div className='flex flex-col justify-center items-center max-w-sm'>
                <Badge label={t('premiumFeature')} color='yellow' />
                <Title>{title}</Title>
                <div className='text-base leading-6 font-normal text-gray-500 text-justify my-2'>
                    {message}
                </div>
                <div className='w-56'>
                    <div className='m-2'>
                        <Button label={t('tryFeature')} size='full' variant='green' onClick={next}/>
                    </div>
                    <div className='m-2'>
                        <Button label={t('skip')} size='full' variant='white'/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PremiumMessage;
