import {createEntityAdapter, createSlice} from "@reduxjs/toolkit";
import {
    addPdfInformedPathToDocumentThunk,
    deleteDocumentThunk, deleteSubmodelThunk,
    getDocumentDetailsThunk,
    getPlaceholdersThunk, reorderSubmodelsThunk, setModelSendModeThunk,
    setPlaceholdersThunk, setSubmodelsPlaceholdersThunk,
    updateDocumentDetailsThunk,
    uploadDocumentThunk
} from "./documentBuilder.actions";
import {toast} from "react-hot-toast";
import Alert from "../../components/molecules/Alerts/Alert";
import {WARNING} from "../../components/molecules/Alerts/types";

const documentBuilder = createEntityAdapter();

const initialState = documentBuilder.getInitialState({
    blocks: [],
    selectedBlockId: null,
    waitingFileUpload: false
})

const documentBuilderSlice = createSlice({
        name: 'documentBuilder',
        initialState,
        reducers: {
            addBlock: (state, action) => {
                state.blocks.push(...action.payload)
            },
            setBlock: (state, action) => {
                state.blocks = [...state.blocks.map((block) => {
                    return block.id === action.payload.id ? {...action.payload} : block
                })]
            },
            deleteBlock: (state, action) => {
                state.blocks= state.blocks.filter(block => block.id !== action.payload.id)
            },
            selectBlock: (state, action) => {
                state.selectedBlockId = action.payload
            },
            resetBlocks: (state) => {
                state.blocks = []
            },
            setBlocks: (state, action) => {
                state.blocks = action.payload
            },
            setSubmodels: (state, action) => {
                state.submodels = action.payload
            },
            setModelsSendMode: (state, action) => {
                if (state.documentInfo) {
                    state.documentInfo.submodelSendMode = action.payload;
                }
            },
            setUpdateDocumentInfo: (state, action) => {
                const infos = action.payload;
                state.submodelSendMode = infos?.submodelSendMode;
                if (infos.submodels && infos.submodels.length > 0) {
                    //devo ordiinare infos.submodels per weight ASC
                    infos.submodels.sort((a, b) => {
                        const weightA = a.weight ?? 0; // Se weight è null o undefined, usa 0
                        const weightB = b.weight ?? 0;
                        return weightA - weightB; // Ordinamento crescente
                    });
                    //controllo eventuale id corrente
                    state.submodels = infos.submodels;
                    if (state.documentInfo?.id) {
                        const currentSubmodel = infos.submodels.find(submodel => submodel.id === state.documentInfo.id);
                        if (currentSubmodel) {
                            state.documentInfo = currentSubmodel;
                            if (currentSubmodel.placeholders) {
                                state.blocks = currentSubmodel.placeholders;
                            }
                            return;
                        }
                    }
                    infos.submodels[0].cached = true;
                    state.documentInfo = infos.submodels[0];
                    if (infos.submodels[0].placeholders) {
                        state.blocks = infos.submodels[0].placeholders;
                    }
                } else {
                    state.submodels = [];
                    state.documentInfo = action.payload;
                    if (action.payload.placeholders && action.payload.placeholders.length > 0) {
                        state.blocks = action.payload.placeholders;
                    }
                }
            },
            resetState: () => {
                return initialState;
            },
            updateCurrentSubmodelPlaceholders: (state, action) => {
                //prendo il documentInfo e lo salvo nel submodel con lo stesso id
                if (state.submodels && state.submodels.length > 0 && state.documentInfo) {
                    for (let i = 0; i < state.submodels.length; i++) {
                        if (state.submodels[i].id === state.documentInfo.id) {
                            state.submodels[i] = state.documentInfo;
                            state.submodels[i].cached = true;
                            state.submodels[i].placeholders = state.blocks;
                            break;
                        }
                    }
                }
            },
            setCurrentSubmodel: (state, action) => {
                //prendo il documentInfo e lo salvo nel submodel con lo stesso id
                if (state.submodels && state.submodels.length > 0 && state.documentInfo) {
                    for (let i = 0; i < state.submodels.length; i++) {
                        if (state.submodels[i].id === state.documentInfo.id) {
                            state.submodels[i] = state.documentInfo;
                            state.submodels[i].cached = true;
                            state.submodels[i].placeholders = state.blocks;
                            break;
                        }
                    }
                }
                //poi prendo il submodel con id passato e lo metto in document info
                if (state.submodels && state.submodels.length > 0) {
                    const docinfo = state.submodels.find(submodel => submodel.id === action.payload);
                    if (docinfo) {
                        state.documentInfo = docinfo;
                        if (state.documentInfo?.placeholders) {
                            state.blocks = state.documentInfo.placeholders;
                        }
                    }
                }
            }
        },
        extraReducers: (builder) => {
            builder
                .addCase(uploadDocumentThunk.pending, (state) => {
                    state.waitingFileUpload = true;
                })
                .addCase(uploadDocumentThunk.fulfilled, (state, action) => {
                    //state.documentInfo = action.payload.data;
                    const infos = action.payload.data;
                    if (infos.submodels && infos.submodels.length > 0) {
                        //devo ordiinare infos.submodels per weight ASC
                        state.submodels = infos.submodels;
                        state.documentInfo = infos.submodels[infos.submodels.length - 1];
                    } else {
                        state.documentInfo = infos;
                    }
                    state.waitingFileUpload = false;
                })
                .addCase(uploadDocumentThunk.rejected, (state, action) => {
                    toast.custom((t) => (<Alert type={WARNING} message={action?.error?.message ?? "C'è stato un errore nell'upload del documento"} onClose={() => toast.dismiss(t.id)} />));
                    state.waitingFileUpload = false;
                })
                .addCase(getPlaceholdersThunk.fulfilled, (state, action) => {
                    state.fields = action.payload;
                })
                .addCase(setPlaceholdersThunk.fulfilled, (state, action) => {
                    state.documentInfo = action.payload;
                })
                .addCase(setPlaceholdersThunk.rejected, (state, action) => {
                    console.log(action);
                })
                .addCase(setSubmodelsPlaceholdersThunk.fulfilled, (state, action) => {
                    documentBuilderSlice.caseReducers.setUpdateDocumentInfo(state, action);
                })
                .addCase(setSubmodelsPlaceholdersThunk.rejected, (state, action) => {
                    console.log(action);
                })
                .addCase(reorderSubmodelsThunk.fulfilled, (state, action) => {
                    documentBuilderSlice.caseReducers.setUpdateDocumentInfo(state, action);
                })
                .addCase(reorderSubmodelsThunk.rejected, (state, action) => {
                    console.log(action);
                })
                .addCase(deleteSubmodelThunk.fulfilled, (state, action) => {
                    documentBuilderSlice.caseReducers.setUpdateDocumentInfo(state, action);
                })
                .addCase(deleteSubmodelThunk.rejected, (state, action) => {
                    console.log(action);
                })
                .addCase(addPdfInformedPathToDocumentThunk.fulfilled, (state, action) => {
                    documentBuilderSlice.caseReducers.setUpdateDocumentInfo(state, action);
                })
                .addCase(addPdfInformedPathToDocumentThunk.rejected, (state, action) => {
                    console.log(action);
                })
                .addCase(setModelSendModeThunk.fulfilled, (state, action) => {
                    const infos = action.payload;
                    state.submodelSendMode = infos?.submodelSendMode;
                })
                .addCase(setModelSendModeThunk.rejected, (state, action) => {
                    console.log(action);
                })
                .addCase(getDocumentDetailsThunk.fulfilled, (state, action) => {
                    documentBuilderSlice.caseReducers.setUpdateDocumentInfo(state, action);
                })
                .addCase(updateDocumentDetailsThunk.fulfilled, (state, action) => {
                    state.documentInfo = action.payload;
                })
                .addCase(deleteDocumentThunk.fulfilled, (state) => {
                    state.blocks = [];
                    state.documentInfo = {};
                });
        }
    }
)

export const documentBuilderSelector = (state) => state.documentBuilder;

export const {resetState, addBlock, setBlock, selectBlock, deleteBlock, resetBlocks, setBlocks, setCurrentSubmodel, updateCurrentSubmodelPlaceholders ,setUpdateDocumentInfo, setSubmodels , setModelsSendMode} = documentBuilderSlice.actions;

export default documentBuilderSlice.reducer
