import {v4 as uuid} from 'uuid'
import {classNames} from "../../../../utils/helper";
import {useState} from "react";

const Input = ({label, name, placeholder, value = '', setValue, type = 'text',autofocus = false, minLength=null, maxLength=null, onBlur = () => {}, disabled=false, autoComplete='on', min, max}) => {
    const [id] = useState(uuid())

    const onChange = (e) => {
        const value = e.target.value
        if (type.toLowerCase() === 'number' && maxLength > 0) setValue(value.slice(0, maxLength));
        else setValue(value)
    }

    return (
        <div className="my-1">
            <label htmlFor={`input-${id}`} className="block text-sm font-medium text-gray-700">
                {label}
            </label>
            <div className="mt-1">
                <input
                    type={type}
                    name={name}
                    id={`input-${id}`}
                    value={value}
                    onChange={onChange}
                    className={classNames(disabled? 'bg-gray-200 text-gray-600' : '', "shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md")}
                    placeholder={placeholder}
                    autoFocus={autofocus}
                    onBlur={onBlur}
                    disabled={disabled}
                    autoComplete={autoComplete}
                    min={min}
                    max={max}
                    minLength={minLength}
                    maxLength={maxLength}
                />
            </div>
        </div>
    )
}

export default Input;
