import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {librarySelector, resetEnableSignature} from "../../../../../../features/library/library.slice";
import Button from "../../../../../atoms/Buttons/Button/Button";
import LoadingSpinner from "../../../../../atoms/LoadingSpinner/LoadingSpinner";
import {enableSignature} from "../../../../../../features/library/library.action";

const EnableFEA = ({backAction}) => {

    const dispatch = useDispatch();
    const {enableSignatureParams} = useSelector(librarySelector);
    const [enabling, setEnabling] = useState(false);

    const callEnableSignature = () => {
        dispatch(enableSignature({code: 'FEA_ONE_SHOT'}))
        setEnabling(true);
    }

    useEffect(() => {
        if(enableSignatureParams) {
            dispatch(resetEnableSignature({}));
            window.location.href = enableSignatureParams.link;
        }
    }, [enableSignatureParams])

    return(
        <div className="relative h-full text-sm max-w-prose mx-auto" aria-hidden="true">
            {enabling ?
                <LoadingSpinner></LoadingSpinner> :

                <>
                    <div>Firma Elettronica Avanzata</div>
                    <div className="mt-6 prose text-gray-700 mx-auto">
                        <p>
                            Nelle prossime schermate le verrà chiesto di controllare e completare i suoi dati personali.
                            Le chiediamo di porre attenzione alla correttezza delle informazioni inserite.
                        </p>
                        <br/>
                        <h2>Successivamente sarà necessario effettuare un selfie e foto fronte-retro di un documento di identità valido, utilizzando la webcam del suo dispositivo.</h2>
                        <br/>
                        <h2>Per ulteriori informazioni la invitiamo a consultare i <span className="text-blue-900 underline"><a href="https://www.confirmo.it/termini-e-condizioni/" target="_blank" rel="noopener noreferrer">Temini e le condizioni</a></span> di utilizzo</h2>
                    </div>
                    <div className="w-full grid px-4 items-end">
                        <div className="grid grid-cols-1 md:grid-cols-4 gap-4 items-end px-4 pt-4 pb-2">
                            <Button variant="white" onClick={backAction} label="Annulla"></Button>
                            <Button onClick={callEnableSignature} label="Procedi"></Button>
                        </div>
                    </div>
                </>
            }
        </div>
    )
}

export default EnableFEA;
