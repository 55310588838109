import {Dialog} from "@headlessui/react";
import React from "react";
import {ExclamationTriangleIcon} from "@heroicons/react/24/outline";
import {useTranslation} from "react-i18next";
import ModalGrayFooter from "../Templates/ModalGrayFooter";

const ModalDeleteMember = ({open, setOpen, onRemove, selectedUsername}) => {
    const {t} = useTranslation()

    const onRemoveUser = () => {
        onRemove()
        setOpen(false)
    }

    return (
        <ModalGrayFooter open={open} setOpen={setOpen} onConfirm={onRemoveUser} confirmLabel={t('delete')}>
            <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
            </div>
            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                    {t('manageusers.removeMember')}
                </Dialog.Title>
                <div className="mt-2">
                    <p className="text-sm text-gray-500">
                        {t('manageusers.confirmRemoveMember', {selectedUsername: selectedUsername ? ' '+selectedUsername : ''})}
                    </p>
                </div>
            </div>
        </ModalGrayFooter>
    )
}

export default ModalDeleteMember;
