export const BILLING = "BILLING"
export const MANAGE = "MANAGE"
export const READ_LIBRARY = "READ_LIBRARY"
export const WRITE_LIBRARY = "WRITE_LIBRARY"
export const SEND = "SEND"
export const SIGN = "SIGN"
export const READ_RUBRICA = "READ_RUBRICA"
export const WRITE_RUBRICA = "WRITE_RUBRICA"
export const READ_DEVICES = "READ_DEVICES"
export const WRITE_DEVICES = "WRITE_DEVICES"
export const PROFILE = "PROFILE"
export const USERS_MANAGE = "USERS_MANAGE"
export const PROCESS_START = "PROCESS_START"
export const WORKSPACES = "WORKSPACES"
export const READ_ARCHIEVE = "READ_ARCHIEVE"
export const SHOWFEQINFO = "SHOWFEQINFO"
export const CONCATENATION = "CONCATENATION"
export const START_DRAFT_PROCESS = "START_DRAFT_PROCESS"
export const IMPORT_EXPORT_PROCESS = "IMPORT_EXPORT_PROCESS"
export const SIGNATURE_BALANCE = "SIGNATURE_BALANCE"
export const DOWNLOAD_PROCESS_SUMMARY = "DOWNLOAD_PROCESS_SUMMARY"
export const MANAGE_DOC_MULTIFILE = "MANAGE_DOC_MULTIFILE"
export const MANAGE_PROCESS_MULTIFILE = "MANAGE_PROCESS_MULTIFILE"
