import success from "../../../assets/illustrations/2_Illustrazione_Confirmo_Successo.svg";
import Button from "../../atoms/Buttons/Button/Button";
import {FULL} from "../../atoms/Buttons/sizes";
import React from "react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import SimplePageWithBackground from "../Templates/SimplePageWithBackground/SimplePageWithBackground";

const SuccessPage = ({title, subtitle, message}) => {
    const navigate = useNavigate();
    const {t} = useTranslation()
    return <SimplePageWithBackground>
        <div className='flex flex-col justify-center max-w-xl flex-1 my-10'>
            <img alt='success' src={success}/>
            <div className='flex items-center mb-3 justify-center text-3xl leading-8 font-medium pr-1'>
                {title}
            </div>
            {(subtitle || message) && <div className='my-3'>
                {subtitle}
                {message}
            </div>}
            <div className='my-3 mx-6'>
                <Button label={t('backHome')} size={FULL} onClick={() => navigate('/home')}/>
            </div>
        </div>
    </SimplePageWithBackground>
}

export default SuccessPage
