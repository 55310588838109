import SingleCheckbox from "../../atoms/SingleCheckbox/SingleCheckbox";
import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {
    linkRecipientToDataCollection,
    unlinkRecipientToDataCollection
} from "../../../features/recipients/recipients.slice";

const SingleDataCollectionRecipientCheckbox = ({recipient, selectedDataCollectionId, isSelectedDataCollectionLinked = false, disabled = false}) => {
    const [value, setValue] = useState(isSelectedDataCollectionLinked);
    const dispatch = useDispatch();

    useEffect(() => {
        setValue(isSelectedDataCollectionLinked)
    },[isSelectedDataCollectionLinked])

    const changeValue = (newValue) => {
        if (newValue) dispatch(linkRecipientToDataCollection({
                recipientId: recipient.id,
                dataCollectionId: selectedDataCollectionId
            }))
        else dispatch(unlinkRecipientToDataCollection({
                recipientId: recipient.id,
                dataCollectionId: selectedDataCollectionId
            }))
        setValue(newValue)
    }

    return <SingleCheckbox label={`${recipient.name}`} value={value} setValue={changeValue} disabled={disabled}/>
}

export default SingleDataCollectionRecipientCheckbox;
