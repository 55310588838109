import DropdownCircular from "../../atoms/Dropdowns/DropdownCircular/DropdownCircular";
import CardInformativa from "../../molecules/CardInformativa/CardInformativa";
import BackgroundInformativa from "../../atoms/BackgroundInformativa/BackgroundInformativa";
import React, {useEffect, useRef, useState} from "react";
import {DragDropContext, Droppable} from "react-beautiful-dnd";
import {useDispatch} from "react-redux";
import {reorderStepsInformativaThunk} from "../../../features/documentalProcess/documentalProcess.actions";
import {reorderList} from "../../../utils/beautiful-dnd-helper";
import {useTranslation} from "react-i18next";

const ListInformative = ({stepsTypesFormatted, informativa, selectedInfoId, onCardClick, disabled = false}) => {
    const dispatch = useDispatch();
    const ref = useRef();
    const [stepsList, setStepsList] = useState([])
    const {t}=useTranslation()

    /*useEffect(() => {
        const onBodyClick = (event) => {
            if (ref['current'].contains(event.target)) return;
            //onCardClick(null)
        };
        document.body.addEventListener('mousedown', onBodyClick);
        return () => {
            document.body.removeEventListener('mousedown', onBodyClick);
            //onCardClick(null)
        };
    }, []);*/

    useEffect(() => {
        setStepsList(informativa?.details)
    },[informativa?.details])

    const onDragEnd = (result) => {
        const orderedStepsList = reorderList(result, stepsList)
        if(orderedStepsList) {
            setStepsList(orderedStepsList);
            dispatch(reorderStepsInformativaThunk({informativaId: selectedInfoId, orderedStepsList}))
        }
    }

    return(
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="list">
                {provided => (
                    <BackgroundInformativa>
                        <div className='mb-3'>
                            <DropdownCircular
                                label={t('addElement')}
                                options={stepsTypesFormatted}
                                disabled={disabled}
                            />
                        </div>
                        <div ref={provided.innerRef} className='w-full' {...provided.droppableProps}>
                            <div ref={ref}>
                                {stepsList && stepsList.map((step, index) => (
                                        <div className='w-full' key={step.id}>
                                            <CardInformativa step={step} informativaId={selectedInfoId} index={index} onCardClick={onCardClick}/>
                                        </div>
                                    )
                                )}
                            </div>
                            {provided.placeholder}
                        </div>
                    </BackgroundInformativa>
                )}
            </Droppable>
        </DragDropContext>
    )
}

export default ListInformative;
