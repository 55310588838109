import React, {useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Navigate, useNavigate} from "react-router-dom";
import LibraryBodyTable from "./LibraryBodyTable";
import {
    copyEventFlowTypeThunk,
    copyFormThunk,
    copyInformedPathThunk,
    countEventFlowTypes,
    countForms,
    countInformedPaths,
    delEventFlowType,
    delForm,
    delInformedPath,
    getEventFlowTypes,
    getForms,
    getInformedPaths,
    updateProcessPublishedThunk
} from "../../../features/library/library.action";
import {librarySelector} from "../../../features/library/library.slice";
import {ICON_BUTTON} from "../../atoms/SimpleTableElement/SimpleTable.types";
import {exportDocumentCall, previewDocumentCall} from "../../../api/documentBuilder";
import {useTranslation} from "react-i18next";
import {userHomeSelector} from "../../../features/userHome/userHome.slice";
import {checkPermissions} from "../../../utils/helper";
import {IMPORT_EXPORT_PROCESS} from "../../../utils/permissions";

const LibraryBody = ({section}) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const {
        eventFlowTypes,
        eventFlowTypesCount,
        listLoadedEventFlows,
        informedPaths,
        informedPathsCount,
        listLoadedInformedPaths,
        forms,
        formsCount,
        listLoadedForms,
        loadingLibrary
    } = useSelector(librarySelector)
    const {selectedTenantId, userOrganizations} = useSelector(userHomeSelector)
    const [openConfirmationCopyModal, setOpenConfirmationCopyModal] = useState(false);
    const [idToCopy, setIdToCopy] = useState();

    const hasImportExport = useMemo(() => {
        const permissions = userOrganizations?.[selectedTenantId]?.permissions ?? {}
        return checkPermissions(permissions, IMPORT_EXPORT_PROCESS)
    }, [selectedTenantId, userOrganizations])

    const handleCopy = async (id) => {
        setIdToCopy(id)
        // Mostra il modale di conferma
        setOpenConfirmationCopyModal(true);
    };

    const handleConfirmation = async () => {
        switch (section) {
            case 'eventflows':
                await dispatch(copyEventFlowTypeThunk({id: idToCopy})).unwrap();
                break
            case 'informative':
                await dispatch(copyInformedPathThunk({id: idToCopy})).unwrap()
                break
            case 'formsTemplate':
                await dispatch(copyFormThunk({id: idToCopy})).unwrap()
                break
            default:
                break
        }
        setIdToCopy(null)
        // Chiudi il modale di conferma
        setOpenConfirmationCopyModal(false);
    }

    const onProcessPublish = async (id, published) => {
        await dispatch(updateProcessPublishedThunk({id, published})).unwrap();
    }

    const showDoc = (data) => {
        previewDocumentCall(data.id, []);
    }

    const exportDoc = (data) => {
        exportDocumentCall(data.id, []);
    }

    switch (section) {
        case 'eventflows':
            return <LibraryBodyTable
                section={section}
                onRedirect={(data) => navigate(`/newprocess/intro/${data.id}`)}
                onDelete={async (id) => await dispatch(delEventFlowType({id}))}
                onCopy={handleCopy}
                data={eventFlowTypes}
                dataCount={eventFlowTypesCount}
                getData={(filters) => dispatch(getEventFlowTypes(filters))}
                getDataCount={(txtFilter) => dispatch(countEventFlowTypes({txtFilter}))}
                onClickNewButton={() => navigate('/newprocess')}
                loading={loadingLibrary}
                openCopyModal={openConfirmationCopyModal}
                setOpenCopyModal={setOpenConfirmationCopyModal}
                onConfirmCopyModal={handleConfirmation}
                onProcessPublish={onProcessPublish}
                customColumnActions={[{
                    field: 'Visualizza',
                    label: t('tableColumns.view'),
                    hidden: true,
                    type: ICON_BUTTON,
                    onClick: showDoc,
                    iconType: 'view', id: 'visualizza'
                }]}
                customColumnDropdownActions={hasImportExport ? [{
                    field: 'Esporta',
                    label: t('tableColumns.export'),
                    onClick: exportDoc,
                    iconType: 'download', id: 'esporta'
                }] : []}
                listLoaded={listLoadedEventFlows}
            />
        case 'informative':
            return <LibraryBodyTable
                section={section}
                onRedirect={(data) => navigate('/Informativa/' + data.id)}
                onDelete={async (id) => await dispatch(delInformedPath({id}))}
                onCopy={handleCopy}
                data={informedPaths}
                dataCount={informedPathsCount}
                getData={(filters) => dispatch(getInformedPaths(filters))}
                getDataCount={(txtFilter) => dispatch(countInformedPaths({txtFilter}))}
                onClickNewButton={() => navigate('/Informativa')}
                loading={loadingLibrary}
                openCopyModal={openConfirmationCopyModal}
                setOpenCopyModal={setOpenConfirmationCopyModal}
                onConfirmCopyModal={handleConfirmation}
                listLoaded={listLoadedInformedPaths}
            />
        case 'formsTemplate':
            return <LibraryBodyTable
                section={section}
                onRedirect={(data) => navigate('/Form/' + data.id)}
                onDelete={async (id) => await dispatch(delForm({id}))}
                onCopy={handleCopy}
                data={forms}
                dataCount={formsCount}
                getData={(filters) => dispatch(getForms(filters))}
                getDataCount={(txtFilter) => dispatch(countForms({txtFilter}))}
                onClickNewButton={() => navigate('/Form')}
                loading={loadingLibrary}
                openCopyModal={openConfirmationCopyModal}
                setOpenCopyModal={setOpenConfirmationCopyModal}
                onConfirmCopyModal={handleConfirmation}
                listLoaded={listLoadedForms}
            />
        default:
            return <Navigate to='/Libreria/eventflows' />
    }
}

export default LibraryBody;
