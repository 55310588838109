import {UserGroupIcon} from "@heroicons/react/24/outline";
import SingleInformativaRecipientCheckbox from "../SingleRecipientCheckbox/SingleInformativaRecipientCheckbox";
import DisclosureItem from "../../atoms/Disclosure/DisclosureItem";
import {useTranslation} from "react-i18next";

const RecipientsInformativa = ({recipients, selectedInformativa, disabled = false}) => {
    const {t} = useTranslation()
    return (
        <div>
            <DisclosureItem
                head={<div className='flex items-center'>
                    <UserGroupIcon className='h-5 w-5' />
                    <h3 className='px-2 text-sm leading-5 font-medium'>{t('informedPath.infoRecipients')}</h3>
                </div>} >
                <div className='space-y-2'>
                    {recipients && Object.values(recipients).map(rec => <SingleInformativaRecipientCheckbox key={rec.id} recipient={rec} selectedInformativa={selectedInformativa} disabled={disabled} />)}
                </div>
            </DisclosureItem>
        </div>
    )
}

export default RecipientsInformativa;
