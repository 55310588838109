import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import isEmpty from "lodash/isEmpty";
import {
    copyConcatenatedProcessRecipient,
    nextStep,
    previousStep,
    sendProcessSelector,
    setConcatenatedProcessRecipient, setCurrentStep,
    setExpirationDate,
    setMessageBody,
    setMessageSubject,
    setOrderedConcatenatedProcess,
    setProcessSelectedDevice,
    setReminderIntervalDays,
    startConcatenationThunk
} from "../../../features/sendProcess/sendProcess.slice";
import Summary from "../ProcessSteps/Summary/Summary";
import EndSendProcess from "../SendProcess/EndSendProcess/EndSendProcess";
import SelectRecipients from "../SendProcess/SelectRecipients";
import SenderFormModal from "../../molecules/Modals/SenderFormModal/SenderFormModal";
import PageWithStepsHeaderAndFooter from "../Templates/PageWithStepsHeaderAndFooter/PageWithStepsHeaderAndFooter";
import ReorderElementsBoard from "../../molecules/ReorderElementsBoard/ReorderElementsBoard";
import {librarySelector} from "../../../features/library/library.slice";
import {getDevices} from "../../../features/library/library.action";
import {setDocumentSelectedDevice} from "../../../features/documentalProcess/documentalProcess.slice";

const NewConcatenatedProcesses = () => {
    const {t} = useTranslation()
    const {step} = useParams();
    const navigate = useNavigate()
    const dispatch = useDispatch();

    const [openModal, setOpenModal] = useState(false)
    const [answers, setAnswers] = useState({})
    const [senderFormIndex, setSenderFormIndex] = useState(0)
    const {devices} = useSelector(librarySelector)

    const {concatenatedProcess, messageBody, messageSubject, reminderIntervalDays, expirationDate, selectedDevice, loadingRecipients, waitingSend, isDocumentSent, currentStepIdx, steps} = useSelector(sendProcessSelector)

    const senderForms = useMemo(() => concatenatedProcess?.flatMap(p => p?.senderForms), [concatenatedProcess])

    const graphPresent = !!(concatenatedProcess.find(p => p.recipients.some(r => r.signatureType === 'GRAPH')));


    useEffect(() => {
        dispatch(setCurrentStep(step))
        dispatch(getDevices())
    }, [step]);

    const buildUrl = (stepName) => `/sendconcatenatedprocess/${stepName}`
    const goBackHome = () => navigate('/home')

    const next = () => {
        dispatch(nextStep())
        navigate(buildUrl(steps[currentStepIdx+1].href))
    }

    const previous = () => {
        dispatch(previousStep())
        if(currentStepIdx > 0) navigate(buildUrl(steps[currentStepIdx-1].href))
    }

    const mapSetRecipient = (recipient, process) => {
        dispatch(setConcatenatedProcessRecipient({
            processId: process.id,
            actorId: recipient.id,
            email: recipient.email,
            userId: recipient.userid,
            givenName: recipient.givenName,
            familyName: recipient.familyName
        }))
    }

    const deleteRecipient = (recipient, process) => {
        dispatch(setConcatenatedProcessRecipient({
            processId: process.id,
            actorId: recipient.id,
            user: {email: '', givenName: '', familyName: '', userId: null}
        }))
    }

    const copyRecipient = (recipient, index) => {
        dispatch(copyConcatenatedProcessRecipient({
            index: index,
            email: recipient.email,
            userId: recipient.userId,
            givenName: recipient.givenName,
            familyName: recipient.familyName
        }))

    }

    const onClickSend = async () => {
        if(!isEmpty(senderForms)) setOpenModal(true)
        else await send()
    }

    const onClickPrevious = async () => {
        if(senderFormIndex > 0) setSenderFormIndex(senderFormIndex - 1)
        else setOpenModal(false)
    }

    const onConfirmSenderForm = async () => {
        if(senderFormIndex < senderForms.length - 1) setSenderFormIndex(senderFormIndex + 1)
        else await send()
    }

    const send = async () => {
        await dispatch(startConcatenationThunk({senderFormData: answers})).unwrap()
        navigate(buildUrl('end'))
    }

    switch (step) {
        case 'recipients':
            return (
                <SelectRecipients
                    process={concatenatedProcess}
                    isConcatenation={true}
                    setSingleRecipient={mapSetRecipient}
                    goBackHome={goBackHome}
                    setRecipients={() => {}}
                    next={next}
                    previous={previous}
                    deleteRecipient={deleteRecipient}
                    steps={steps}
                    loadingRecipients={loadingRecipients}
                    onClickCopy={copyRecipient}
                    buildUrl={buildUrl}
                />
            )
        case 'order':
            return <PageWithStepsHeaderAndFooter
                background={false}
                previous={previous}
                next={next}
                closeAction={goBackHome}
                steps={steps}
                buildUrl={buildUrl}
            >
                <div className='flex w-1/2 m-4'>
                    <ReorderElementsBoard setSelectedItemId={()=> {}} stepsList={concatenatedProcess} setStepsList={(processes) => dispatch(setOrderedConcatenatedProcess(processes))} loading={false}/>
                </div>
            </PageWithStepsHeaderAndFooter>
        case 'summary':
            return(<>
                    <Summary
                        next={onClickSend}
                        previous={previous}
                        steps={steps}
                        messageBody={messageBody}
                        messageSubject={messageSubject}
                        reminderInterval={reminderIntervalDays}
                        expirationDate={expirationDate}
                        setMessageBody={(value) => dispatch(setMessageBody(value))}
                        setMessageSubject={(value) => dispatch(setMessageSubject(value))}
                        setReminderInterval={(value) => dispatch(setReminderIntervalDays(value))}
                        setExpirationDate={(value) => dispatch(setExpirationDate(value))}
                        confirmLabel={t('summary.send')}
                        devices={graphPresent ? devices : []}
                        selectedDevice={selectedDevice}
                        setSelectedDevice={(value) => dispatch(setProcessSelectedDevice(value))}
                        processList={concatenatedProcess.map((p) => p.name)}
                        isConcatenation={true}
                        buildUrl={buildUrl}
                    />
                    <SenderFormModal
                        open={openModal}
                        setOpen={(value) => {
                            setOpenModal(value)
                            setSenderFormIndex(0)
                        }}
                        title={t('send.senderFormTitle')}
                        items={senderForms?.[senderFormIndex]?.template?.items ?? []}
                        rules={senderForms?.[senderFormIndex]?.template?.rules ?? []}
                        answers={answers?.[senderForms[senderFormIndex]?.id] ?? []}
                        setAnswers={(value) => {
                            if (senderForms[senderFormIndex]?.id) setAnswers({
                                ...answers,
                                [senderForms[senderFormIndex]?.id]: value
                            })
                        }}
                        onSend={onConfirmSenderForm}
                        onClickPrevious={onClickPrevious}
                        stepHasNext={senderFormIndex < senderForms.length - 1}
                        stepHasPrevious={senderFormIndex > 0}
                        stepIndicator={senderForms.length > 1 ? `Step ${senderFormIndex + 1} di ${senderForms.length}` : ''}
                    />
                </>
            )
        case 'end':
            return(
                <EndSendProcess isLoading={waitingSend}
                                isDocumentSent={isDocumentSent}
                />
            )
        default:
            return <div><p>DEFAULT</p></div>
    }

}

export default NewConcatenatedProcesses;
