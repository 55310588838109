import React, {useEffect} from "react";
import CardHeading from "../../molecules/CardHeading/CardHeading";
import EnvironmentStats from "../../molecules/Cards/EnvironmentStats/EnvironmentStats";
import PageWithHeader from "../Templates/PageWithHeader/PageWithHeader";
import {setSelectedTabName} from "../../../features/navigation/navigation.slice";
import {useDispatch, useSelector} from "react-redux";

import {resetState as resetDocumentalProcess} from "../../../features/documentalProcess/documentalProcess.slice";
import {resetState as resetDocumentalBuilder} from "../../../features/documentBuilder/documentBuilder.slice";
import {resetState as recipientsReset} from "../../../features/recipients/recipients.slice";
import {resetState as resetSendProcess} from "../../../features/sendProcess/sendProcess.slice";
import HomeActionsButtons from "../../atoms/HomeActionsButtons/HomeActionsButtons";
import illustrazioneProcessoDoc from "../../../assets/illustrations/15_Illustrazione_Confirmo_Log-In__v2.png";
import illustrazioneSelfSign from "../../../assets/illustrations/3_Illustrazione_Confirmo_Firma-Confermata__v2.png";
import illustrazioneDocumento
    from "../../../assets/illustrations/4_Illustrazione_Confirmo_Documento-Inviato-Successo__v2.png";
import {useNavigate} from "react-router-dom";
import {userHomeSelector} from "../../../features/userHome/userHome.slice";
import {getEcoStatsThunk} from "../../../features/userHome/userHome.actions";
import {PROCESS_START} from "../../../utils/permissions";
import OrganizationSelector from "../OrganizationSelector/OrganizationSelector";

const Homepage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const {ecoStats, selectedTenantId, userOrganizations} = useSelector(userHomeSelector)
    const permissions = userOrganizations?.[selectedTenantId]?.permissions ?? {}

    useEffect(() => {
        dispatch(setSelectedTabName('Home'))
        dispatch(resetDocumentalProcess());
        dispatch(resetDocumentalBuilder());
        dispatch(recipientsReset());
        dispatch(resetSendProcess());
    },[])

    useEffect(() => {
        if(selectedTenantId) dispatch(getEcoStatsThunk())
    },[selectedTenantId])


    if(!selectedTenantId) return <OrganizationSelector />
    return(
        <PageWithHeader>
            <div className='space-y-5 w-full'>
                <div className='flex space-x-4 px-4 py-3 w-full'>
                    <div className='w-2/5 flex-grow flex'>
                        <HomeActionsButtons primary
                                            illustration={illustrazioneDocumento}
                                            title='actions.sendDocument'
                                            description='actions.sendDocumentDescription'
                                            action='actions.sendDocumentAction'
                                            onRedirect={() => navigate('/senddocument/recipients')}
                        />
                    </div>
                    <div className='flex-grow flex w-3/5 space-x-4'>
                        {permissions[PROCESS_START] ?
                            <HomeActionsButtons illustration={illustrazioneProcessoDoc}
                                                title='actions.documentalProcess'
                                                description='actions.documentalProcessDescription'
                                                action='actions.documentalProcessAction'
                                                onRedirect={() => navigate('/Document')}
                            /> : <></>}
                        <HomeActionsButtons illustration={illustrazioneSelfSign}
                                            title='actions.selfSignDocument'
                                            description='actions.selfSignDocumentDescription'
                                            action='actions.selfSignDocumentAction'
                                            onRedirect={() => navigate('/selfsign/upload')}
                        />
                    </div>

                </div>
                <div className='px-4 py-3 pb-5 w-full'>
                    <CardHeading />
                </div>
                <div className='px-4 py-3 flex flex-grow w-full'>
                    <EnvironmentStats ecoStats={ecoStats}/>
                </div>
            </div>
        </PageWithHeader>
    )
}

export default Homepage;
