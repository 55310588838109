import {useTranslation} from "react-i18next";
import IconButton from "../../atoms/Buttons/IconButton/IconButton";
import {FULL} from "../../atoms/Buttons/sizes";
import Icon from "../../atoms/Icon/Icon";
import React, {useEffect, useRef, useState} from "react";
import ModalGrayFooter from "../Modals/Templates/ModalGrayFooter";
import {Dialog} from "@headlessui/react";
import Input from "../../atoms/Inputs/Input/Input";
import SingleCheckbox from "../../atoms/SingleCheckbox/SingleCheckbox";
import Badge from "../../atoms/Badges/Badge/Badge";
import RadioGroup from "../../atoms/RadioGroups/RadioGroup";
import Select from "../../atoms/Select/Select";
import {forceReloadPeopleList} from "../../../features/people/people.slice";
import {useDispatch, useSelector} from "react-redux";
import {setPreferredLanguageThunk, setUsersAdvancedFiltersThunk} from "../../../features/auth/auth.actions";
import {authSelector} from "../../../features/auth/auth.slice";


const UsersAdvancedFilters = ({ts, setTs}) => {
    const { t } = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });
    const dispatch = useDispatch();
    const { usersAdvancedFilters } = useSelector(authSelector);

    const DEFAULT_FILTER = {
        fields: {
            givenName: true,
            familyName: true,
            contactEmail: true,
            taxCode: true,
            externalID: true,
        },
        likeType: 'contains'
    }

    const [currentFilter, setCurrentFilter] = useState(DEFAULT_FILTER);

    useEffect(() => {
        if (isModalOpen) {
            console.log('Called UsersAdvancedFilters');
            try {
                if (usersAdvancedFilters) {
                    localStorage.setItem('usersAdvancedFilters', JSON.stringify(usersAdvancedFilters));
                    setCurrentFilter(usersAdvancedFilters);
                } else {
                    setCurrentFilter(DEFAULT_FILTER);
                }
            } catch (exx) {
                setCurrentFilter(DEFAULT_FILTER)
            }
        }
    },[isModalOpen])


    const likeTypeOptions = [
        {label: t('search.filters.contains'), value: 'contains'},
        {label: t('search.filters.startsWith'), value: 'startsWith'},
        {label: t('search.filters.endsWith'), value: 'endsWith'},
        {label: t('search.filters.equals'), value: 'equals'},
    ];

    const saveCurrentFilter = async () => {
        localStorage.setItem('usersAdvancedFilters', JSON.stringify(currentFilter));
        await dispatch(setUsersAdvancedFiltersThunk({usersAdvancedFilters: currentFilter}));
        setIsModalOpen(false);
        if (setTs) {
            setTs(Date.now());
        } else {
            dispatch(forceReloadPeopleList({}));
        }
    }

    const handleButtonClick = () => {
            setIsModalOpen(!isModalOpen);
    };

    return (
        <>
            <div className='my-1'>
                <IconButton  variant='white' size={FULL}
                             icon={<Icon size='4' type='adjustments'/>}
                             onClick={() => handleButtonClick()}/>
            </div>
            {isModalOpen && (
                <ModalGrayFooter open={isModalOpen} setOpen={setIsModalOpen} onConfirm={() => saveCurrentFilter()} confirmLabel={t('save')} cancelLabel={t('cancel')}>
                    <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                        <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                            {t('search.filters')}
                        </Dialog.Title>
                        <div className="mt-4">
                            {t('search.filters.fields')}
                            <div className='flex flex-col truncate -ml-1'>
                                <SingleCheckbox label={t('name')} variant='green' value={currentFilter?.fields['givenName'] ?? true} setValue={(val) => setCurrentFilter({...currentFilter, fields: {...currentFilter.fields, givenName: val}})}/>
                                <SingleCheckbox label={t('surname')} variant='green' value={currentFilter?.fields['familyName'] ?? true} setValue={(val) => setCurrentFilter({...currentFilter, fields: {...currentFilter.fields, familyName: val}})}/>
                                <SingleCheckbox label={'Email'} variant='green' value={currentFilter?.fields['contactEmail'] ?? true} setValue={(val) => setCurrentFilter({...currentFilter, fields: {...currentFilter.fields, contactEmail: val}})}/>
                                <SingleCheckbox label={t('taxCode')} variant='green' value={currentFilter?.fields['taxCode'] ?? true} setValue={(val) => setCurrentFilter({...currentFilter, fields: {...currentFilter.fields, taxCode: val}})}/>
                                <SingleCheckbox label={t('general.userExternalID')} variant='green' value={currentFilter?.fields['externalID'] ?? true} setValue={(val) => setCurrentFilter({...currentFilter, fields: {...currentFilter.fields, externalID: val}})}/>
                            </div>
                            <br/>
                            {t('search.filters.matchType')}
                            <Select options={likeTypeOptions}  value={currentFilter?.likeType ?? 'contains'} setValue={(val) => setCurrentFilter({...currentFilter, likeType: val})} placeholder={''}/>
                        </div>
                    </div>
                </ModalGrayFooter>
            )}
        </>
    )
}

export default UsersAdvancedFilters;
