import Input from "../../atoms/Inputs/Input/Input";
import Button from "../../atoms/Buttons/Button/Button";
import LinkedLabel from "../../atoms/LinkedLabel/LinkedLabel";
import confirmoLogo from '../../../assets/logos/confirmo-logo.svg'
import ErrorMessage from "../../atoms/ErrorMessage/ErrorMessage";
import {useTranslation} from "react-i18next";

const ResetPasswordForm = ({ password, setPassword, passwordConfirm, setPasswordConfirm, resetPasswordRequest, resetPasswordError= null, resetPasswordErrorMessage }) => {
    const {t} = useTranslation()
    return(
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md flex flex-col">
            <img src={confirmoLogo} alt='Confirmo' className='mb-10 h-12'/>
            <div className='my-2'>
                {resetPasswordError ?
                    <ErrorMessage>{resetPasswordErrorMessage}</ErrorMessage>
                    : <></>}
            </div>
            <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                <form className="space-y-6">
                    <Input
                        name='password'
                        type='password'
                        label={t('password')}
                        value={password}
                        setValue={setPassword}
                    />

                    <Input
                        name='passwordConfirm'
                        type='password'
                        label={t('confirmPassword')}
                        value={passwordConfirm}
                        setValue={setPasswordConfirm}
                    />

                    <div className="h-10">
                        <Button
                            variant='blue'
                            label={t('setPassword')}
                            size='full'
                            onClick={resetPasswordRequest}
                        />
                    </div>
                </form>
                <div className='flex justify-center items-center pt-8'>
                    <LinkedLabel link={'/login'}>{t('cancel')}</LinkedLabel>
                </div>
            </div>

        </div>
    )
}

export default ResetPasswordForm;
